import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetProductCalendarPropsType,
  GetProductCalendarResponseType,
} from './productCalendarApiService.types';

import { createProductCalendarUrl } from './utils';

const productCalendarApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getProductCalendar: builder.query<GetProductCalendarResponseType, GetProductCalendarPropsType>({
      query: (params) => ({
        url: createProductCalendarUrl(prepareQueryParams(params)),
      }),
    }),
  }),
});

export const { useGetProductCalendarQuery } = productCalendarApiService;
