import { t } from 'tools/i18n';

import { Card, Divider, Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './PosterEmptyList.module.scss';

const PosterListEmpty = () => {
  return (
    <Card>
      <Flex vertical gap="16">
        <Typography.Title level={4}>{t('guide_poster_title_empty')}</Typography.Title>
        <Divider />
        <Flex vertical gap="16" align="center" className={s.container}>
          <div className={s.iconWrapper}>
            <Icon name="notFound" size={38} />
          </div>
          <Flex vertical gap="4" align="center">
            <Typography.Title level={4}>{t('home_news_not_found')}</Typography.Title>
            <Typography.Text size="16" type="secondary">
              {t('guide_poster_description_empty')}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default PosterListEmpty;
