import { FC, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE, getTopManagementByStageRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';
import useCertificate from 'utils/useCertificate.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetRenewEmployeeFormQuery } from 'services/top-management/topManagementApiService';

import { Button, Card, Icon, ModalDefault, Typography } from 'gazprom-ui-lib';

import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import s from './Contract.module.scss';

interface ContractProps {
  date?: string | null;
}

const Contract: FC<ContractProps> = (props) => {
  const { date } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isActive: isActiveCertificate } = useCertificate();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data } = useGetRenewEmployeeFormQuery({ myEmployeeId: selectedEmployee?.id! });

  const handleOpenModal = () => setIsModalOpen((prevState) => !prevState);
  const goToDocuments = () => navigate(DOCUMENTS_ROUTE);
  const onClickHandler = () => {
    if (!isActiveCertificate) {
      handleOpenModal();

      return;
    }

    const lastRoute = data?.formStep as TOP_MANAGEMENT_STAGE_ENUM;
    const defaultRoute = TOP_MANAGEMENT_STAGE_ENUM.COMMON;
    const route = getTopManagementByStageRoute(lastRoute ?? defaultRoute);

    navigate(route);
  };

  return (
    <>
      <Card className={s.contractContainer}>
        <div className={s.contractContainerText}>
          <Icon name="time" />
          <Typography.Text size="14" type="danger">
            {t('home_contract_text', {
              date: dayjs(date).format('DD.MM.YYYY'),
            })}
          </Typography.Text>
        </div>
        <Button size="large" rightIcon="arrowRight" onClick={onClickHandler}>
          {t('home_contract_button')}
        </Button>
      </Card>
      <ModalDefault open={isModalOpen} onCancel={handleOpenModal}>
        <ModalDefault.Icon name="signature" type="primary" />
        <ModalDefault.Title>{t('documents_certificate_not_found')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_certificate_social_program')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            type="primary"
            onClick={goToDocuments}
            fullWidth
            size="large"
            rightIcon="arrowRight">
            {t('common_go_to_section')}
          </Button>
          <Button type="secondary" onClick={handleOpenModal} fullWidth size="large">
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default Contract;
