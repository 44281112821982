import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

export const SEGMENT_IN_PROCESS = 'SEGMENT_IN_PROCESS';
export const SEGMENT_ARCHIVE = 'SEGMENT_ARCHIVE';
export const SEGMENT_OPTIONS: { value: string; label: ReactNode }[] = [
  {
    label: <Typography.Title level={5}>{t('documents_status_in_progress_label')}</Typography.Title>,
    value: SEGMENT_IN_PROCESS,
  },
  {
    label: <Typography.Title level={5}>{t('common_archive')}</Typography.Title>,
    value: SEGMENT_ARCHIVE,
  },
];
export const DEFAULT_PAGINATION_CONFIG = {
  page: 1,
  size: 10,
};
