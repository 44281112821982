import React, { useMemo, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetBusinessTripsQuery } from 'services/me/work-tour/workTourApiService';
import { useGetBusinessTripStatusesQuery } from 'services/work-tour/workTourApiService';

import { Flex } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import DocumentsStatusesAndCategories from 'pages/documents/documents-statuses-and-categories';
import { DEFAULT_ALL_STATUS } from 'pages/documents/documents.utils';

import { TASK_STATUS_ENUM } from 'types/documents.types';

import s from './DocumentsBusinessTrip.module.scss';
import List from './containers/list';
import SectionDescription from './containers/section-description';

const DocumentsBusinessTrip = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const [activeStatus, setActiveStatus] = useState<string>(TASK_STATUS_ENUM.ALL);

  const {
    data: statusesData = [],
    isLoading: statusesIsLoading,
    refetch: refetchStatuses,
  } = useGetBusinessTripStatusesQuery();

  const {
    data: businessTrips,
    refetch: refetchBusinessTrips,
    isLoading: businessTripsIsLoading,
  } = useGetBusinessTripsQuery(
    selectedEmployee ? { page: 1, size: 500, myEmployeeId: selectedEmployee.id } : skipToken,
  );

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t('documents_business_trips')} />
    </>
  );

  const handlePageRefresh = async () => {
    await refetchBusinessTrips();
    await refetchStatuses();
  };

  const statuses = [DEFAULT_ALL_STATUS, ...statusesData];

  const filteredBusinessTrips = useMemo(() => {
    return (
      businessTrips?.workTours?.filter((task) => {
        return (
          activeStatus === TASK_STATUS_ENUM.ALL ||
          activeStatus.includes(task.currentKedoStatus.status)
        );
      }) ?? []
    );
  }, [activeStatus, businessTrips?.workTours]);

  const isLoading = statusesIsLoading || businessTripsIsLoading;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={handlePageRefresh}>
          <WithBackground>
            <SectionDescription />
            <Card withPadding={false}>
              <WithLoader isLoading={isLoading}>
                <Flex vertical>
                  <DocumentsStatusesAndCategories
                    statuses={statuses}
                    setActiveStatus={setActiveStatus}
                    activeStatus={activeStatus}
                    showCategories={false}
                    count={businessTrips?.totalCount ?? 0}
                    countTranslateKey="documents_business_trips_found_count"
                  />
                  <List filteredBusinessTrips={filteredBusinessTrips} />
                </Flex>
              </WithLoader>
            </Card>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsBusinessTrip;
