import React, { useCallback, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { MORE_FEEDBACK_CREATE_ROUTE, getProfileFeedbackDetailsRoute } from 'routes/more/list';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import {
  useMyReceivedFeedbacksQuery,
  useMySentFeedbacksQuery,
} from 'services/feedback/feedbackApiService';

import { Button, Icon, Segmented, SegmentedProps, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { FeedbackType } from 'types/feedback.types';

import s from './ProfileFeedback.module.scss';
import FeedbackCreateNotificationModal from './feedback-create-notification-modal';
import ProfileFeedbackReceived from './profile-feedback-received';
import ProfileFeedbackSent from './profile-feedback-sent';
import { MAX_FEEDBACK_LENGTH, formatDate, getTagConfig } from './profileFeedback.utils';

const ProfileFeedback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: sentFeedbackData } = useMySentFeedbacksQuery({ page: 1, size: 1 });
  const { data: receivedFeedbackData } = useMyReceivedFeedbacksQuery({ page: 1, size: 1 });

  const SEGMENT_OPTIONS = useMemo(
    () => [
      {
        label: (
          <div className={s.segmentOption}>
            <Typography.Title level={5}>{t('common_received')}</Typography.Title>
            <Typography.Text size="10">{receivedFeedbackData?.totalCount}</Typography.Text>
          </div>
        ),
        value: 'common_received',
      },
      {
        label: (
          <div className={s.segmentOption}>
            <Typography.Title level={5}>{t('common_sent')}</Typography.Title>
            <Typography.Text size="10">{sentFeedbackData?.totalCount}</Typography.Text>
          </div>
        ),
        value: 'common_sent',
      },
    ],
    [receivedFeedbackData?.totalCount, sentFeedbackData?.totalCount],
  );

  const [selectedSegment, setSelectedSegment] = useState<string>(
    location.state?.selectedSegment || SEGMENT_OPTIONS[0].value,
  );

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} useDefaultGoBack={true} />
      <Header.Label label={t('common_thanks_for_work')} />
    </>
  );

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSelectedSegment(segment.toString());

  const renderFeedback = useCallback(
    (key: 'recipient' | 'author') => (feedback: FeedbackType) => {
      const recipientOrAuthorPerson = feedback[`${key}Person`];
      const position = feedback[`${key}Employee`]?.position;
      const showStatus = key === 'recipient';

      const tagConfig = getTagConfig(feedback.status);

      return (
        <button
          onClick={() =>
            navigate(getProfileFeedbackDetailsRoute(feedback.id), {
              state: {
                selectedSegment,
                isOwnFeedback: !feedback.status,
              },
            })
          }
          className={s.thanksListElement}
          key={`thanks__list__${feedback.id}`}>
          <div>
            <Typography.Text size="12" type="secondary">
              {formatDate(dayjs(feedback.createDate))}
            </Typography.Text>
            <Typography.Text size="14" className={s.text}>
              {feedback.text.length > MAX_FEEDBACK_LENGTH
                ? `${feedback.text.slice(0, MAX_FEEDBACK_LENGTH)}...`
                : feedback.text}
            </Typography.Text>
            <Bio staffName={position} {...recipientOrAuthorPerson} />
            {showStatus && (
              <Tag
                className={s.tag}
                type={tagConfig.type}
                children={
                  <>
                    <Icon name={tagConfig.icon} /> <span>{tagConfig.children}</span>
                  </>
                }
              />
            )}
          </div>
          <Icon name="forward" />
        </button>
      );
    },
    [navigate, selectedSegment],
  );

  const handleCreateFeedbackNavigate = () =>
    navigate(MORE_FEEDBACK_CREATE_ROUTE, {
      state: {
        selectedSegment,
      },
    });

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground id="feedbackModalMount" className={s.relative}>
            <Card className={s.thanksHeadCard}>
              <Card.Default
                title={t('common_thanks_for_work')}
                description={t('feedback_write_thanks_description')}
                icon="gratitudeFilled"
              />
              <Button
                leftIcon="plus"
                fullWidth
                size="large"
                onClick={handleCreateFeedbackNavigate}
                className={s.smallButton}>
                {t('feedback_write_thanks')}
              </Button>
            </Card>
            <Card withPadding={false}>
              <div className={s.yourThanks}>
                <div>
                  <Typography.Title level={4}>{t('feedback_your_feedbacks')}</Typography.Title>
                  <button onClick={handleCreateFeedbackNavigate}>
                    <Icon name="plus" />
                  </button>
                </div>
                <Segmented
                  options={SEGMENT_OPTIONS}
                  value={selectedSegment}
                  onChange={handleSegmentChange}
                  fullWidth
                />
              </div>
              {selectedSegment === 'common_received' ? (
                <ProfileFeedbackReceived renderFeedback={renderFeedback('author')} />
              ) : (
                <ProfileFeedbackSent renderFeedback={renderFeedback('recipient')} />
              )}
            </Card>
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <FeedbackCreateNotificationModal />
    </>
  );
};

export default ProfileFeedback;
