import { Dispatch, SetStateAction } from 'react';

import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';

import { GetScheduleResponseType } from 'services/me/meApiService.types';

import { Icon, Typography } from 'gazprom-ui-lib';

import { MONTH_ENUM } from 'constants/dates';
import { SCHEDULE_AVAILABLE_DAY_TYPES, SCHEDULE_AVAILABLE_TYPES } from 'types/schedule.types';

import s from './ProfileSchedule.module.scss';

interface UseRenderersProps {
  calendarValue: Dayjs;
  scheduleData?: GetScheduleResponseType;
  setCalendarValue: Dispatch<SetStateAction<Dayjs>>;
  selectedCalendarValue: Dayjs | null;
}

const useRenderers = (props: UseRenderersProps) => {
  const { calendarValue, scheduleData, selectedCalendarValue, setCalendarValue } = props;

  const handleLeftClick = (date: { month: number; year: number }) => {
    const year = date.month === 0 ? date.year - 1 : date.year;
    const month = date.month === 0 ? 11 : date.month - 1;
    let day = calendarValue.get('D');

    if (dayjs(new Date(year, month, day)).get('month') !== month) {
      day = dayjs(new Date(year, month)).endOf('month').get('D');
    }

    setCalendarValue(dayjs(new Date(year, month, day)));
  };

  const handleRightClick = (date: { month: number; year: number }) => {
    const year = date.month === 11 ? date.year + 1 : date.year;
    const month = date.month === 11 ? 0 : date.month + 1;
    let day = calendarValue.get('D');

    if (dayjs(new Date(year, month, day)).get('month') !== month) {
      day = dayjs(new Date(year, month)).endOf('month').get('D');
    }

    setCalendarValue(dayjs(new Date(year, month, day)));
  };

  const headerRenderer = (rendererProps: { value: Dayjs; onChange: (date: Dayjs) => void }) => {
    const { value } = rendererProps;
    const month = value.month();
    const year = value.year();
    const selectedMonth = AVAILABLE_MONTH.find((e) => e[1] === month)?.[0]?.toLowerCase();
    const selectedMonthLabel = t(`common_enum_${selectedMonth}`);

    return (
      <div className={s.month}>
        <button onClick={() => handleLeftClick({ month, year })}>
          <Icon name="back" />
        </button>
        <Typography.Title level={5}>{`${selectedMonthLabel} ${year}`}</Typography.Title>
        <button onClick={() => handleRightClick({ month, year })}>
          <Icon name="forward" />
        </button>
      </div>
    );
  };
  const cellRenderer = (cellDate: Dayjs) => {
    const date = cellDate.date();
    const month = cellDate.month();
    const isCurrentMonth = calendarValue.month() === month;

    const isToday = cellDate.isToday();
    const isSelectedDate = selectedCalendarValue?.startOf('day').isSame(cellDate?.startOf('day'));
    const isAvailableTypes =
      !!scheduleData?.[date]?.type && AVAILABLE_TYPES.includes(scheduleData?.[date]?.type);

    const type = scheduleData?.[date]?.type?.toLowerCase() ?? '';
    const dayType = scheduleData?.[date]?.dayType ?? '';

    const useTypeStyles = !isSelectedDate && isAvailableTypes;
    const isWeekend = dayjs(cellDate).day() === 6 || dayjs(cellDate).day() === 0;
    const isDayOff = dayType === SCHEDULE_AVAILABLE_DAY_TYPES.DAYOFF;

    return (
      <div className={s.cellWrapper}>
        <div
          className={cn(s.cell, {
            [s.today]: isToday,
            [s.selectedCell]: isSelectedDate,
            [s[type]]: isCurrentMonth && useTypeStyles,
            [s.workDay]: isCurrentMonth && !isWeekend && !isDayOff,
            [s[dayType]]: isCurrentMonth,
          })}>
          {date}
        </div>
      </div>
    );
  };

  return {
    cellRenderer,
    headerRenderer,
  };
};

const AVAILABLE_MONTH = Object.entries(MONTH_ENUM).slice(12, 24);
const AVAILABLE_TYPES = Object.values(SCHEDULE_AVAILABLE_TYPES);
export default useRenderers;
