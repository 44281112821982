import { filterFieldsBySubstring, parseBySeparator } from '../../documentsTopManagement.utils';

export enum OTHER_INFORMATION_FORM_ENUM {
  FINANCIAL_OBLIGATIONS = 'financialObligations',
  FINANCIAL_OBLIGATIONS_TYPE = 'financialObligationsType',
  FSPP_OBLIGATIONS = 'fsppObligations',
  FSPP_OBLIGATIONS_NUM = 'fsppObligationsNum',
  FSPP_OBLIGATIONS_DATE = 'fsppObligationsDate',
  ADDITIONAL_FINANCE = 'additionalFinance',
  ADDITIONAL_FINANCE_TYPE = 'additionalFinanceType',
  OTHER_LEGAL_ENTITY = 'othersLegalEntity',
  OTHER_LEGAL_ENTITY_DATA = 'othersLegalEntityData',
}

type CommonDataType = Record<string, string>;

export const getFsppObligationsData = (data: CommonDataType) =>
  parseBySeparator(
    filterFieldsBySubstring(
      data,
      OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_NUM,
      OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_DATE,
    ),
  );

export const getAdditionalFinanceData = (data: CommonDataType) =>
  (
    parseBySeparator(
      filterFieldsBySubstring(data, OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE_TYPE),
    ) as unknown as Record<OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE_TYPE, string>[]
  ).map((value) => value.additionalFinanceType);
