import { FC } from 'react';

import { QuestionTypes } from 'types/surveys.types';

import SurveysQuestionsDropdown, {
  SurveysQuestionsDropdownProps,
} from './surveys-questions-dropdown';
import SurveysQuestionsFree, { SurveysQuestionsFreeProps } from './surveys-questions-free';
import SurveysQuestionsHtml, { SurveysQuestionsHtmlProps } from './surveys-questions-html';
import SurveysQuestionsMatrix, { SurveysQuestionsMatrixProps } from './surveys-questions-matrix';
import SurveysQuestionsMultiselect, {
  SurveysQuestionsMultiselectProps,
} from './surveys-questions-multiselect';
import SurveysQuestionsScale, { SurveysQuestionsScaleProps } from './surveys-questions-scale';
import SurveysQuestionsSelect, { SurveysQuestionsSelectProps } from './surveys-questions-select';

export interface QuestionProps {
  id: number;
  type: QuestionTypes;
}

type Questions =
  | SurveysQuestionsHtmlProps
  | SurveysQuestionsSelectProps
  | SurveysQuestionsMatrixProps
  | SurveysQuestionsMultiselectProps
  | SurveysQuestionsFreeProps
  | SurveysQuestionsScaleProps
  | SurveysQuestionsDropdownProps;

type SurveysQuestionProps = {
  setIsQuestionNotSupported: (value: boolean) => void;
} & Questions;

const SurveysQuestion: FC<SurveysQuestionProps> = (props) => {
  const { type, setIsQuestionNotSupported } = props;

  switch (type) {
    case 'html':
      return <SurveysQuestionsHtml {...(props as SurveysQuestionsHtmlProps)} />;
    case 'matrix':
      return <SurveysQuestionsMatrix {...(props as SurveysQuestionsMatrixProps)} />;
    case 'select':
      return <SurveysQuestionsSelect {...(props as SurveysQuestionsSelectProps)} />;
    case 'multiselect':
      return <SurveysQuestionsMultiselect {...(props as SurveysQuestionsMultiselectProps)} />;
    case 'free':
      return <SurveysQuestionsFree {...(props as SurveysQuestionsFreeProps)} />;
    case 'scale':
      return <SurveysQuestionsScale {...(props as SurveysQuestionsScaleProps)} />;
    case 'dropdown':
      return <SurveysQuestionsDropdown {...(props as SurveysQuestionsDropdownProps)} />;
    default:
      setIsQuestionNotSupported(true);
      console.info(`В опросе есть неподдерживаемый тип вопроса: ${type}`);
      return null;
  }
};

export default SurveysQuestion;
