import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

const useBuildHero = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const buildHeroId = process.env.dc058434-8d14-496e-9bdb-f6ba65855bb3?.trim();
  const isBuildHeroFlag = !!buildHeroId;
  const currentSettlementId = selectedEmployee?.currentSettlement.id;
  const isBuildHeroCurrentSettlement = isBuildHeroFlag && buildHeroId === currentSettlementId;
  const isBuildHeroNotCurrentSettlement = isBuildHeroFlag && buildHeroId !== currentSettlementId;

  return {
    buildHeroId,
    isBuildHeroFlag,
    isBuildHeroCurrentSettlement,
    isBuildHeroNotCurrentSettlement,
  };
};

export default useBuildHero;
