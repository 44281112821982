export enum SCHEDULE_AVAILABLE_TYPES {
  WORK = 'WORK',
  HOLIDAY = 'HOLIDAY',
  VACATION = 'VACATION',
}

export enum SCHEDULE_AVAILABLE_DAY_TYPES {
  DAYOFF = 'dayoff',
  WORKDAY = 'workDay',
}

export type ProductCalendarDayType = {
  date: string;
  typeDay: string;
  stateDay: string;
};

export type ProductCalendarType = ProductCalendarDayType[];
