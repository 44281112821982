import React, { FC, ReactElement, memo, useEffect } from 'react';

import { useAuth } from 'react-oidc-context';
import { Route, Routes } from 'react-router-dom';
import { RouteConfig, initRoutes } from 'routes/routes';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetIsPreUserQuery } from 'services/me/meApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { PRE_USER_ROLE, UserProfileType } from 'types/roles.types';

import { userManager } from '../utils/keycloak';

export const renderRoutes = ({ path, element, children, id = '' }: RouteConfig): ReactElement => (
  <Route
    path={path}
    element={element}
    children={children?.map(renderRoutes)}
    key={`route__${path}__${id}`}
  />
);

const Pages: FC = () => {
  const { isAuthenticated, user } = useAuth();
  const userProfile = user?.profile as UserProfileType;
  const isPreUser = !!userProfile?.roles?.includes(PRE_USER_ROLE);
  const [getPreUser] = useLazyGetIsPreUserQuery();

  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  useEffect(() => {
    if (isPreUser) {
      const interval = setInterval(() => {
        getPreUser().then(
          handleRequest({
            onSuccess: async (data) => {
              if (data && !data.isPre) {
                if (selectedEmployee) {
                  setSelectedEmployee('');
                }

                await userManager.signinSilent();
                document.location.href = '/';
              }
            },
          }),
        );
      }, 15 * 1000);

      return () => clearInterval(interval);
    }
  }, [getPreUser, isPreUser, selectedEmployee, setSelectedEmployee]);

  return <Routes>{initRoutes(isAuthenticated, isPreUser).map(renderRoutes)}</Routes>;
};

export default memo(Pages);
