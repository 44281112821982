import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getTopManagementByStageRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import {
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  TextArea,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import {
  InternationalPassportType,
  RenewEmployeeInfoType,
  TOP_MANAGEMENT_STAGE_ENUM,
} from 'types/topManagement.types';

import { formatDatesInObject } from '../../documentsTopManagement.utils';
import DatePicker from '../date-picker';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import { INTERNATIONAL_PASSPORT_FORM_ENUM } from './internationalPassportForm.utils';

const InternationalPassportForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isEmpty = Form.useWatch(INTERNATIONAL_PASSPORT_FORM_ENUM.EMPTY, form);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData, isLoading: renewEmployeeDataIsLoading } =
    useGetRenewEmployeeFormQuery({ myEmployeeId: selectedEmployee?.id! });
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const nextStep = () =>
    navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.DRIVER_LICENSE));

  const handleSubmit = (form: InternationalPassportType) => {
    const body: Partial<RenewEmployeeInfoType> = {
      formStep: TOP_MANAGEMENT_STAGE_ENUM.DRIVER_LICENSE,
      noInternationalPassport: !!isEmpty,
    };

    if (!isEmpty) {
      body.internationalPassport = formatDatesInObject(form) as InternationalPassportType;
    }

    updateFormData({ id: renewEmployeeData?.id!, myEmployeeId: selectedEmployee?.id!, body }).then(
      handleRequest({
        onSuccess: nextStep,
      }),
    );
  };

  const { issueDate, issuingAuthority, name, number, surname, tillDate } =
    renewEmployeeData?.internationalPassport ?? {};

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form form={form} onFinish={handleSubmit}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Flex gap="16" vertical>
              <Title
                title={t('common_international_passport')}
                subtitle={t('top_management_international_description')}
              />
              <Flex gap="24" vertical>
                <Form.Item
                  name={INTERNATIONAL_PASSPORT_FORM_ENUM.EMPTY}
                  valuePropName="checked"
                  initialValue={renewEmployeeData?.noInternationalPassport}>
                  <Checkbox>{t('common_document_missing')}</Checkbox>
                </Form.Item>
                {!isEmpty && (
                  <>
                    <Form.Item
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.SURNAME}
                      label={
                        <Typography.Title level={5}>
                          {t('top_management_last_name')}
                        </Typography.Title>
                      }
                      initialValue={surname}
                      rules={[formRules.required]}>
                      <Input size="large" placeholder={t('common_enter_text')} />
                    </Form.Item>
                    <Form.Item
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.NAME}
                      label={
                        <Typography.Title level={5}>
                          {t('top_management_first_name')}
                        </Typography.Title>
                      }
                      initialValue={name}
                      rules={[formRules.required]}>
                      <Input size="large" placeholder={t('common_enter_text')} />
                    </Form.Item>
                    <Form.Item
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.NUMBER}
                      label={<Typography.Title level={5}>{t('common_number')}</Typography.Title>}
                      initialValue={number}
                      rules={[formRules.required]}>
                      <Input size="large" placeholder={t('common_enter_number')} />
                    </Form.Item>
                    <Form.Item
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.ISSUING_AUTHORITY}
                      label={<Typography.Title level={5}>{t('common_issued_by')}</Typography.Title>}
                      initialValue={issuingAuthority}
                      rules={[formRules.required]}>
                      <TextArea size="large" placeholder={t('common_enter_text')} />
                    </Form.Item>

                    <DatePicker
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.ISSUE_DATE}
                      label={t('common_issue_date')}
                      initialValue={issueDate}
                      required
                      yearConfig={{
                        initialMinYear: 1950,
                        initialMaxYear: dayjs().year(),
                      }}
                    />
                    <DatePicker
                      name={INTERNATIONAL_PASSPORT_FORM_ENUM.TILL_DATE}
                      label={t('common_validity_document')}
                      initialValue={tillDate}
                      required
                      yearConfig={{
                        initialMinYear: dayjs().year(),
                        initialMaxYear: 2099,
                      }}
                    />
                  </>
                )}
              </Flex>
            </Flex>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default InternationalPassportForm;
