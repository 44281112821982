import { GUIDE_SOCIAL_POLICY_ROUTE } from 'routes/guide/list';
import { MORE_EDUCATION_ROUTE, MORE_SURVEYS_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import { Icon } from 'gazprom-ui-lib';

import { ReactComponent as Dzen } from 'assets/icons/dzen.svg';
import socialPolicy from 'assets/icons/socialPolicy.png';
import survey from 'assets/icons/survey.png';
import { ReactComponent as TG } from 'assets/icons/telegram.svg';
import training from 'assets/icons/training.png';
import { ReactComponent as VK } from 'assets/icons/vk.svg';
import { ReactComponent as Youtube } from 'assets/icons/youtube.svg';

export const SOCIAL_NETWORKS = [
  {
    link: 'https://gsprom.ru',
    icon: <Icon name="web" size={26} />,
  },
  {
    link: 'https://vk.com/gazstroyprom',
    icon: <VK />,
  },
  {
    link: 'https://vk.com/video/@gazstroyprom',
    icon: <Youtube />,
  },
  {
    link: 'https://t.me/gspromru',
    icon: <TG />,
  },
  {
    link: 'https://dzen.ru/gsprom',
    icon: <Dzen />,
  },
];

export const INNER_LINKS = [
  {
    link: MORE_EDUCATION_ROUTE,
    icon: training,
    label: t('home_training'),
  },
  {
    link: MORE_SURVEYS_ROUTE,
    icon: survey,
    label: t('common_surveys'),
  },
  {
    link: GUIDE_SOCIAL_POLICY_ROUTE,
    icon: socialPolicy,
    label: t('home_social_help'),
  },
];
