import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_ASSIGNMENT_SIGN_LIST_ROUTE } from 'routes/documents/list';

import { useInfoKedoReviewTaskAssignmentMutation } from 'services/v2/me/task-assignment/kedo-review-task/kedoReviewTaskApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { AssignmentType } from 'types/assignment.types';

import { MODAL_TYPE } from '../utils';
import { ACTIONS_SIGN_REQUIRE_STATUSES } from './actionsKedoReviewTask.utils';
import { ASSIGNMENT_SIGN_TYPE } from 'constants/assignments';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
  assignment?: AssignmentType;
  setModalType: Dispatch<SetStateAction<MODAL_TYPE.FAMILIARIZED | ''>>;
}

const useActions = (props: Props) => {
  const { setModalType, handleCertificateCheck, assignment } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [info, { isLoading: infoIsLoading }] = useInfoKedoReviewTaskAssignmentMutation();

  const handleFamiliarizeWithSign = () => {
    if (assignment) {
      const state = {
        cameFrom: location.pathname,
        type: ASSIGNMENT_SIGN_TYPE.V2_KEDO_REVIEW_TYPE_SIGN,
        modalType: MODAL_TYPE.FAMILIARIZED,
        assignment,
      };

      handleCertificateCheck(() => {
        navigate(DOCUMENTS_ASSIGNMENT_SIGN_LIST_ROUTE, { state });
      });
    }
  };

  const handleFamiliarizeWithoutSign = () => {
    if (assignment) {
      const onSuccess = () => {
        setModalType(MODAL_TYPE.FAMILIARIZED);
      };

      info({
        assignmentId: assignment.id,
      }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleFamiliarize = () => {
    if (assignment?.type) {
      if (ACTIONS_SIGN_REQUIRE_STATUSES.includes(assignment.type)) {
        handleFamiliarizeWithSign();
      } else {
        handleFamiliarizeWithoutSign();
      }
    }
  };

  const isLoading = infoIsLoading;

  return {
    handleFamiliarize,
    isLoading,
  };
};

export default useActions;
