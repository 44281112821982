import React, { FC } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getCreatedDate } from 'utils/article.utils';

import { Icon, Tag, Typography } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';

import { SuggestionPreviewType } from 'types/suggestion.types';

import s from './List.module.scss';
import { getSuggestionTagConfig } from './list.utils';

interface SuggestionsListProps {
  suggestions?: SuggestionPreviewType[];
  getNavigateRoute: (id: string) => string;
}

const SuggestionsList: FC<SuggestionsListProps> = (props) => {
  const { suggestions, getNavigateRoute } = props;
  const navigate = useNavigate();

  const hasData = !!suggestions?.length;

  const renderTask = (suggestion: SuggestionPreviewType) => {
    const tagConfig = getSuggestionTagConfig(suggestion.curStatus);

    const handleClick = () => {
      navigate(getNavigateRoute(suggestion.id));
    };

    return (
      <button
        onClick={handleClick}
        className={s.taskElement}
        key={`suggestion__list__${suggestion.id}`}>
        <div>
          <Typography.Text size="12" type="secondary">
            {getCreatedDate(suggestion?.createdAt)}
          </Typography.Text>
          <Typography.Text size="14" className={s.text} weight="500">
            {suggestion.name}
          </Typography.Text>
          <Tag
            className={cn(s.tag, s[tagConfig.type])}
            type={tagConfig.type}
            children={
              <>
                <Icon name={tagConfig.icon} />
                <span>{tagConfig.children}</span>
              </>
            }
          />
        </div>
        <Icon name="forward" />
      </button>
    );
  };

  return (
    <>
      {!hasData && (
        <Empty title="innovations_not_found" description="innovations_not_found_description" />
      )}
      {hasData && suggestions?.map(renderTask)}
    </>
  );
};

export default SuggestionsList;
