import React, { useContext } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';

import s from '../ProfileFeedbackCreate.module.scss';
import ProfileFeedbackCreateViewWrapper from '../profile-feedback-create-view-wrapper';
import { CONFIG_STATE, FeedbackCreateContext } from '../profileFeedbackCreate.utils';

const ProfileFeedbackCreateAddFeedbackDescription = () => {
  const { feedbackTarget, feedbackCategory, setConfigStep } = useContext(FeedbackCreateContext);

  if (!feedbackTarget || !setConfigStep) {
    return <></>;
  }

  return (
    <ProfileFeedbackCreateViewWrapper>
      <Card>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_TARGET,
              previous: CONFIG_STATE.ADD_FEEDBACK_TEXT,
            })
          }
          className={s.defaultCard}>
          <Bio
            firstName={feedbackTarget?.person?.firstName}
            lastName={feedbackTarget?.person?.lastName}
          />
          <Icon name="edit" />
        </button>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_CATEGORY,
              previous: CONFIG_STATE.ADD_FEEDBACK_TEXT,
            })
          }
          className={cn(s.defaultCard, s.category)}>
          <div>
            <Typography.Text>{t('feedback_category')}</Typography.Text>
            <Typography.Title level={4}>{feedbackCategory?.name}</Typography.Title>
          </div>
          <Icon name="edit" />
        </button>
      </Card>
      <Card.Button
        onClick={() =>
          setConfigStep({
            current: CONFIG_STATE.STAND_ALONE_FEEDBACK_TEXT,
            previous: CONFIG_STATE.ADD_FEEDBACK_TEXT,
          })
        }>
        <div className={s.defaultCard}>
          <Typography.Title level={4}>{t('feedback_text')}</Typography.Title>
          <Icon name="edit" />
        </div>
        <div className={s.inputMask}>
          <Typography.Title level={5}>{t('common_enter_text')}</Typography.Title>
        </div>
      </Card.Button>
    </ProfileFeedbackCreateViewWrapper>
  );
};

export default ProfileFeedbackCreateAddFeedbackDescription;
