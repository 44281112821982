import React from 'react';

import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './DocumentsNotFound.module.scss';

const DocumentsNotFound = () => {
  return (
    <div className={s.wrapper}>
      <div>
        <Icon name="notFound" />
      </div>
      <Typography.Title level={4}>{t('documents_not_found')}</Typography.Title>
      <Typography.Title level={5} type="secondary" strong={false}>
        {t('documents_not_found_description')}
      </Typography.Title>
    </div>
  );
};

export default DocumentsNotFound;
