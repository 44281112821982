import { DivisionType, EmployerType, PersonType, PositionType, StaffType } from './employee.types';
import { SignType } from './sign.types';

export type SuggestionCurrentStatusType = {
  code: string;
  name: string;
  state: string;
  description: string;
  comment: string;
};

export type SuggestionStatusHistoryItemType = {
  state: 'error' | 'disabled' | 'done' | 'pending' | 'continue';
  date: string;
  title: string;
  description?: string;
  createdAt?: string;
  status: string;
};

export type SuggestionFileType = {
  fileKey: string;
  fileName: string;
  signs: null | SignType[];
  description: string | null;
};

export enum SUGGESTION_NEED_EXPENSES_ENUM {
  NEED_MONEY = 'NEED_MONEY',
  NO_NEED_MONEY = 'NO_NEED_MONEY',
  UNKNOWN_NEED_MONEY = 'UNKNOWN_NEED_MONEY',
}

export type SuggestionType = {
  id: string;
  createdAt: string;
  category: {
    id: string;
    name: string;
  };
  name: string;
  curStatus: SuggestionCurrentStatusType;
  statusHistory: SuggestionStatusHistoryItemType[];
  problem: string;
  suggestion: string;
  expectation: string;
  problemFiles: SuggestionFileType[];
  suggestionFiles: SuggestionFileType[];
  needExpenses: SUGGESTION_NEED_EXPENSES_ENUM;
  personalParticipation: boolean;
};

export type SuggestionPreviewType = {
  id: string;
  createdAt: string;
  name: string;
  curStatus: SuggestionCurrentStatusType;
};

export type SuggestionStatus = {
  code: string;
  name: string;
};

export type DecisionType = 'WAITING_RELEASE_REPORT' | 'TAKE_FOR_RELEASE' | 'FIRST_LEADER';

export type SuggestionDecisionEmployeeType = {
  id: string;
  employmentType: string;
  person: PersonType;
  employer: EmployerType & {
    hasChildren: true;
  };
  division: DivisionType & {
    nameGenitive: string;
  };
  position: PositionType;
  staff: StaffType;
  currentSettlement: {
    id: string;
    name: string;
  };
  partTime: number;
  endWorkDate: string;
  needContractRenewal: boolean;
};

export type SuggestionDecisionType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  status: {
    id: string | null;
    name: string;
    state: string;
    description: string | null;
    code: string;
  };
  executor: SuggestionDecisionEmployeeType;
  employee: SuggestionDecisionEmployeeType;
  type: DecisionType;
};

export type SuggestionDecisionPerformerType = {
  id: string;
  position: PositionType;
  division: DivisionType;
  employer: EmployerType;
  person: PersonType;
  staff: StaffType;
  employmentType: string;
};
