import { useState } from 'react';

import { Segmented, SegmentedProps } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import ProfileScheduleEmployee from 'pages/profile/profile-schedule/profile-schedule-employee';

import MySalarySegmentMonth from './my-salary-segment-month';
import MySalarySegmentYear from './my-salary-segment-year';
import { SEGMENT_OPTIONS, headerChildren } from './mySalary.utils';

const MySalary = () => {
  const [selectedSegment, setSelectedSegment] = useState(SEGMENT_OPTIONS[0].value);

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSelectedSegment(segment.toString());

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <ProfileScheduleEmployee />
          <Card withPadding>
            <Segmented
              options={SEGMENT_OPTIONS}
              value={selectedSegment}
              onChange={handleSegmentChange}
              fullWidth
            />
            {selectedSegment === 'month' ? <MySalarySegmentMonth /> : <MySalarySegmentYear />}
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MySalary;
