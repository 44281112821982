import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';

import { Icon } from 'gazprom-ui-lib';

import CodeVerification from 'containers/code-verification';
import Header from 'containers/header';

import s from './VerificationForm.module.scss';
import usePersonalData from './usePersonalData.hook';

const VerificationForm = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(HOME_ROUTE);

  const {
    isConfirmLoading,
    isVerificationPageShow,
    closeCodeVerification,
    onConfirm,
    handleTimeUp,
  } = usePersonalData();

  if (!isVerificationPageShow) {
    return null;
  }

  return (
    <CodeVerification
      successConfig={{
        title: t('my_personal_data_success_request_title'),
        description: t('my_personal_data_visible_for', { time: '30' }),
        onConfirm,
        closeCodeVerification,
        handleTimeUp,
        isConfirmLoading,
        icon: 'eye',
      }}>
      <button onClick={handleBack} className={s.button}>
        <Icon name="arrowLeft" />
      </button>
      <Header.Label label={t('my_personal_data')} />
    </CodeVerification>
  );
};

export default VerificationForm;
