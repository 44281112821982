import { useContext } from 'react';

import DOMPurify from 'dompurify';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { KedoTaskFile } from 'types/documents.types';

import { AssignmentContext } from '../../documentsAssignment';
import s from './Attributes.module.scss';

const Attributes = () => {
  const assignment = useContext(AssignmentContext);

  const filledAttributes = assignment?.kedoTask?.filledAttributes ?? [];
  const { handleDownload, loadingKey } = useDownloadKedoFile();

  if (!filledAttributes?.length) {
    return null;
  }

  const renderAttribute = (attribute: {
    attributeCode: string;
    attributeId: string;
    attributeName: string;
    num: number;
    userText: string;
  }) => {
    const { attributeId, attributeName, userText } = attribute;

    if (assignment) {
      const fileAttribute = assignment?.kedoTask.files?.find(getFileByAttribute(attributeId));

      if (fileAttribute) {
        return (
          <button
            disabled={loadingKey === fileAttribute.fileKey}
            key={attributeId}
            className={s.attribute}
            onClick={handleDownload({
              fileKey: fileAttribute.fileKey,
              taskId: assignment.kedoTask.id,
              isArchive: false,
              taskAssignmentId: assignment.id,
            })}>
            <Typography.Text size="12" type="secondary">
              {attributeName}
            </Typography.Text>
            <div>
              <Flex align="center" gap="8">
                <Icon name="documents" />
                <Typography.Title level={5}>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(userText) }} />
                </Typography.Title>
              </Flex>
              <Icon name="download" />
            </div>
          </button>
        );
      }

      return (
        <div key={attributeId}>
          <Typography.Text size="12" type="secondary">
            {attributeName}
          </Typography.Text>
          <Typography.Title level={5}>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(userText) }} />
          </Typography.Title>
        </div>
      );
    }
  };

  return <div className={s.wrapper}>{filledAttributes?.map(renderAttribute)}</div>;
};

const getFileByAttribute = (attributeId: string) => (file: KedoTaskFile) =>
  file.attributeId === attributeId;

export default Attributes;
