import React from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

const Bonuses = () => {
  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('common_bonuses')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>Bonuses</WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Bonuses;
