import { FC, SetStateAction, useCallback, useEffect } from 'react';

import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GUIDE_QR_CODE_ROUTE, GUIDE_SELECT_SETTLEMENT_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { locationGuideSelector } from 'selectors/guide.selector';
import {
  useActivateSettlementMutation,
  useLazyGetMySettlementsQuery,
} from 'services/guide/guideApiService';
import { GetMySettlementResponse } from 'services/guide/guideApiService.types';
import { setLocation } from 'slices/guideSlice';
import { useAppSelector } from 'store';

import { Button, ModalDefault, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { MODALS_QR_ENUM } from 'types/guide.types';

interface GuideModalsLocationProps {
  modal: MODALS_QR_ENUM;
  setModal: (value: SetStateAction<MODALS_QR_ENUM>) => void;
}

const GuideModalsLocation: FC<GuideModalsLocationProps> = (props) => {
  const { modal, setModal } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedEmployee] = useFullSelectedEmployee();
  const [mutation] = useActivateSettlementMutation();
  const location = useAppSelector(locationGuideSelector);
  const [findSettlement, { data, isLoading }] = useLazyGetMySettlementsQuery();

  const handleModalState = useCallback(
    (res?: GetMySettlementResponse) => {
      if (res && res?.items.length > 1) {
        setModal(MODALS_QR_ENUM.FOUND_SEVERAL);
      } else if (res?.items.length === 1) {
        setModal(MODALS_QR_ENUM.FOUND_GEO);
      } else if (!res?.items.length) {
        setModal(MODALS_QR_ENUM.NOT_FOUND_GEO);
      }
    },
    [setModal],
  );

  useEffect(() => {
    location &&
      findSettlement({ x: location.x, y: location.y, page: 1, size: 500 }).then(
        handleRequest({
          onSuccess: handleModalState,
        }),
      );
  }, [findSettlement, handleModalState, location]);

  const onCancel = () => {
    setModal(MODALS_QR_ENUM.EMPTY);
    dispatch(setLocation(null));
  };
  const onActivate = () => {
    mutation({ myEmployeeId: selectedEmployee?.id!, settlementId: data?.items[0].id! }).then(() =>
      onCancel(),
    );
  };

  const onOpenLocationSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.Location,
      optionIOS: IOSSettings.App,
    });
  };

  return (
    <>
      <ModalDefault open={modal === MODALS_QR_ENUM.NOT_FOUND_GEO} onCancel={onCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="attention" type="warning" />
          <ModalDefault.Title>{t('guide_not_found_settlement_title')}</ModalDefault.Title>
          <ModalDefault.Description>
            {t('guide_not_found_by_location_subtitle')}
          </ModalDefault.Description>
          <ModalDefault.Actions>
            <Button
              type="primary"
              fullWidth
              size="large"
              leftIcon={'camera'}
              onClick={() => {
                onCancel();
                navigate(GUIDE_QR_CODE_ROUTE);
              }}>
              {t('guide_select_button_qr')}
            </Button>
            <Button type="secondary" fullWidth size="large" onClick={onCancel}>
              {t('common_close')}
            </Button>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>

      <ModalDefault open={modal === MODALS_QR_ENUM.FOUND_GEO} onCancel={onCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="location" type="success" />
          <ModalDefault.Title>{data?.items[0]?.name ?? ''}</ModalDefault.Title>
          <ModalDefault.Description>{t('guide_is_your_location_now')}</ModalDefault.Description>
          <ModalDefault.Actions>
            <Button type="primary" fullWidth size="large" leftIcon={'ok'} onClick={onActivate}>
              {t('guide_qr_my_location_defined')}
            </Button>
            <Button
              type="secondary"
              fullWidth
              size="large"
              leftIcon={'camera'}
              onClick={() => {
                onCancel();
                navigate(GUIDE_QR_CODE_ROUTE);
              }}>
              {t('guide_select_button_qr')}
            </Button>
            <Button type="secondary" fullWidth size="large" onClick={onCancel}>
              {t('common_close')}
            </Button>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>

      <ModalDefault open={modal === MODALS_QR_ENUM.FOUND_SEVERAL} onCancel={onCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="location" type="success" />
          <ModalDefault.Title>{t('guide_definition_settlement')}</ModalDefault.Title>
          <ModalDefault.Description>{data?.items[0]?.name ?? ''}</ModalDefault.Description>
          <ModalDefault.Actions>
            <Button type="primary" fullWidth size="large" leftIcon={'ok'} onClick={onActivate}>
              {t('guide_qr_my_location_defined')}
            </Button>
            <Button
              type="secondary"
              fullWidth
              size="large"
              leftIcon="location"
              onClick={() => {
                navigate(GUIDE_SELECT_SETTLEMENT_ROUTE);
              }}>
              {t('guide_choose_another_settlement')}
            </Button>
            <Button type="secondary" fullWidth size="large" onClick={onCancel}>
              {t('common_close')}
            </Button>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>

      <ModalDefault open={modal === MODALS_QR_ENUM.NO_ACCESS_GEO} onCancel={onCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="attention" type="warning" />
          <ModalDefault.Title>{t('guide_no_access_location_title')}</ModalDefault.Title>
          <ModalDefault.Description>
            {t('guide_no_access_location_subtitle')}
          </ModalDefault.Description>
          <ModalDefault.Actions>
            <Button
              type="primary"
              fullWidth
              size="large"
              leftIcon="settings"
              onClick={() => {
                onCancel();
                onOpenLocationSettings();
              }}>
              {t('common_open_settings')}
            </Button>
            <Button type="secondary" fullWidth size="large" onClick={onCancel}>
              {t('common_close')}
            </Button>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>
    </>
  );
};

export default GuideModalsLocation;
