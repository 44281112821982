import React, { FC, useEffect, useState } from 'react';

import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { useNavigate } from 'react-router-dom';
import { GUIDE_QR_CODE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import AUDIT_EVENTS from 'constants/auditEvents';

import s from './GuideButtonQR.module.scss';
import { NO_PERMISSIONS_STATUSES } from './guideButtonQR.utils';

interface GuideButtonQRProps {
  text: string;
  primary?: boolean;
}

const GuideButtonQR: FC<GuideButtonQRProps> = (props) => {
  const { text, primary } = props;

  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const { auditSuccess, auditError } = useAudit();

  const handleScan = async () => {
    try {
      let permissionsStatus = await BarcodeScanner.checkPermissions();

      if (NO_PERMISSIONS_STATUSES.includes(permissionsStatus.camera)) {
        const requestPermissionsResult = await BarcodeScanner.requestPermissions();
        permissionsStatus = requestPermissionsResult;
      }

      if (permissionsStatus.camera === 'granted') {
        auditSuccess(AUDIT_EVENTS.PERMISSIONS.CAMERA);
        navigate(GUIDE_QR_CODE_ROUTE);
      }

      if (permissionsStatus.camera === 'denied') {
        auditError(AUDIT_EVENTS.PERMISSIONS.CAMERA);
        setIsModalActive(true);
      }
    } catch (error) {
      setIsModalActive(true);
    }
  };

  const onCancel = () => setIsModalActive(false);

  const onOpenCameraSettings = () => {
    NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App,
    });
  };

  useEffect(() => {
    return () => {
      onCancel();
    };
  }, []);

  return (
    <>
      <Button
        type={primary ? 'primary' : 'secondary'}
        fullWidth
        size="large"
        leftIcon="camera"
        onClick={handleScan}
        className={s.button}>
        {text}
      </Button>

      <ModalDefault open={isModalActive} onCancel={onCancel}>
        <ModalDefault.Icon name="attention" type="warning" />
        <ModalDefault.Title>{t('guide_no_access_qr_title')}</ModalDefault.Title>
        <ModalDefault.Description>{t('guide_no_access_qr_subtitle')}</ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            type="primary"
            fullWidth
            size="large"
            leftIcon="settings"
            onClick={() => {
              onCancel();
              onOpenCameraSettings();
            }}>
            {t('common_open_settings')}
          </Button>
          <Button type="secondary" fullWidth size="large" onClick={onCancel}>
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default GuideButtonQR;
