import React, { FC } from 'react';

import { Flex, Typography } from 'gazprom-ui-lib';

interface TitleProps {
  title: string;
  subtitle: string;
}

const Title: FC<TitleProps> = (props) => {
  const { title, subtitle } = props;

  return (
    <Flex gap="4" vertical>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Typography.Text size="14" type="secondary">
        {subtitle}
      </Typography.Text>
    </Flex>
  );
};

export default Title;
