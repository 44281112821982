import React, { FC, memo, useEffect } from 'react';

import { formRules } from 'utils/formRules.utils';

import { Form, Icon, Input } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeStringProps extends AttributeDefaultProps {}

const AttributeString: FC<AttributeStringProps> = (props) => {
  const { value, id, isMandatory, placeholder, isReadonly } = props;

  const form = Form.useFormInstance();

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, value);
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item name={id} rules={isMandatory ? [formRules.required] : []}>
        <Input
          allowClear={{
            clearIcon: <Icon name="deleteFilled" />,
          }}
          size="large"
          placeholder={placeholder}
          maxLength={250}
          disabled={isReadonly}
        />
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeString);
