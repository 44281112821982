import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { useGetRenewFamilyTypeQuery } from 'services/top-management/topManagementApiService';

import { Button, Card, Checkbox, Flex, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import { FamilyType } from 'types/topManagement.types';

import Collapse from '../../../collapse';
import DataPresenter from '../../../data-presenter';
import { FAMILY_FORM_ENUM } from '../../familyForm.utils';
import FormHeader from '../form-header';

interface FamilyCardProps {
  data?: FamilyType;
  index: number | string;
  onClick?: () => void;
  isCustom?: boolean;
}

const FamilyCard: FC<FamilyCardProps> = (props) => {
  const { index, data, isCustom, onClick } = props;
  const { data: familyRelationDegreeOptions } = useGetRenewFamilyTypeQuery();

  const {
    birthDate,
    birthPlace,
    familyType,
    jobInGsp,
    jobInGspDetails,
    name,
    patronymic,
    phone,
    placeOfWork,
    registrationAddress,
    residentialAddress,
    surname,
    isEnteredByUser,
  } = data || {};

  const form = Form.useFormInstance();
  const jobInGspWatcher = Form.useWatch(`${FAMILY_FORM_ENUM.JOB_IN_GSP}_${index}`, form);
  const surnameFieldValue = Form.useWatch(`${FAMILY_FORM_ENUM.SURNAME}_${index}`, form);
  const nameFielValue = Form.useWatch(`${FAMILY_FORM_ENUM.NAME}_${index}`, form);
  const patronymicFielValue = Form.useWatch(`${FAMILY_FORM_ENUM.PATRONYMIC}_${index}`, form);

  const isDraft = isCustom || isEnteredByUser;
  const fullName = `${surnameFieldValue ?? ''} ${nameFielValue ?? ''} ${patronymicFielValue ?? ''}`;
  const draftDisplayedName = fullName.trim().length === 0 ? t('common_full_name') : fullName;
  const familyTypeTranslated = familyRelationDegreeOptions?.find((el) => el.value === familyType);

  return (
    <Card>
      <Collapse
        noPadding
        defaultActiveKey={index}
        items={[
          {
            key: index,
            label: <Typography.Title level={5}>{draftDisplayedName}</Typography.Title>,
            children: (
              <>
                {!isDraft && (
                  <>
                    <DataPresenter
                      title={t('top_management_relationship_type')}
                      text={familyTypeTranslated?.label}
                    />
                    <DataPresenter
                      title={t('common_birthdate')}
                      text={dayjs(birthDate).format('DD.MM.YYYY')}
                    />
                    <Form.Item
                      name={`${FAMILY_FORM_ENUM.NAME}_${index}`}
                      hidden
                      initialValue={name}
                    />
                    <Form.Item
                      name={`${FAMILY_FORM_ENUM.SURNAME}_${index}`}
                      hidden
                      initialValue={surname}
                    />
                    <Form.Item
                      name={`${FAMILY_FORM_ENUM.PATRONYMIC}_${index}`}
                      hidden
                      initialValue={patronymic}
                    />
                    <Form.Item
                      name={`${FAMILY_FORM_ENUM.BIRTH_DATE}_${index}`}
                      hidden
                      initialValue={birthDate}
                    />
                  </>
                )}
                <Flex gap="24" vertical>
                  {isDraft && (
                    <FormHeader
                      index={index}
                      surname={surname}
                      name={name}
                      patronymic={patronymic}
                      familyType={familyType}
                      birthDate={birthDate}
                    />
                  )}
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.BIRTH_PLACE}_${index}`}
                    label={<Typography.Title level={5}>{t('common_birthplace')}</Typography.Title>}
                    rules={[formRules.required]}
                    initialValue={birthPlace}>
                    <TextArea size="large" placeholder={t('common_enter_text')} />
                  </Form.Item>
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.WORK_PLACE}_${index}`}
                    label={
                      <Typography.Title level={5}>
                        {t('top_management_work_place')}
                      </Typography.Title>
                    }
                    initialValue={placeOfWork}>
                    <TextArea size="large" placeholder={t('common_enter_text')} />
                  </Form.Item>
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.JOB_IN_GSP}_${index}`}
                    valuePropName="checked"
                    label={
                      <Typography.Title level={5}>
                        {t('top_management_previously_worked')}
                      </Typography.Title>
                    }
                    initialValue={jobInGsp}>
                    <Checkbox>{t('common_yes')}</Checkbox>
                  </Form.Item>
                  {jobInGspWatcher && (
                    <Form.Item
                      name={`${FAMILY_FORM_ENUM.JOB_IN_GSP_DETAILS}_${index}`}
                      label={
                        <Typography.Title level={5}>
                          {t('top_management_dismissal_reason')}
                        </Typography.Title>
                      }
                      rules={[formRules.required]}
                      initialValue={jobInGspDetails}>
                      <TextArea size="large" placeholder={t('common_enter_text')} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.REGISTRATION_ADDRESS}_${index}`}
                    label={
                      <Typography.Title level={5}>
                        {t('my_personal_data_registration_address')}
                      </Typography.Title>
                    }
                    rules={[formRules.required]}
                    initialValue={registrationAddress}>
                    <TextArea size="large" placeholder={t('common_enter_text')} />
                  </Form.Item>
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.RESIDENTIAL_ADDRESS}_${index}`}
                    label={
                      <Typography.Title level={5}>
                        {t('top_management_actual_residence')}
                      </Typography.Title>
                    }
                    rules={[formRules.required]}
                    initialValue={residentialAddress}>
                    <TextArea size="large" placeholder={t('common_enter_text')} />
                  </Form.Item>
                  <Form.Item
                    name={`${FAMILY_FORM_ENUM.PHONE}_${index}`}
                    label={<Typography.Title level={5}>{t('common_phone')}</Typography.Title>}
                    initialValue={phone}>
                    <Input size="large" placeholder={t('common_enter_number')} />
                  </Form.Item>
                  {onClick && (
                    <Button type="secondary" danger fullWidth size="large" onClick={onClick}>
                      {t('common_delete')}
                    </Button>
                  )}
                </Flex>
              </>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default FamilyCard;
