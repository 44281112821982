import React, { FC } from 'react';

import cn from 'classnames';
import { useMatch, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SOCIAL_POLICY_ROUTE } from 'routes/documents/list';
import { getCreatedDate } from 'utils/article.utils';

import { Icon, Tag, Typography } from 'gazprom-ui-lib';

import DocumentsNotFound from 'pages/documents/documents-not-found';
import { getKedoTagConfig } from 'pages/documents/documents.utils';

import { KedoTask, KedoTaskDraft } from 'types/documents.types';

import s from './DocumentsList.module.scss';
import { KEDO_TASK_DRAFT_STATUS } from './documentList.utils';

interface DocumentProps {
  isLoading?: boolean;
  isDraft?: boolean;
  filteredTasks: KedoTask[] | KedoTaskDraft[];
  getNavigateRoute: (id: string, socialProgramId?: string) => string;
}

const DocumentsList: FC<DocumentProps> = (props) => {
  const { isLoading, isDraft, filteredTasks, getNavigateRoute } = props;
  const navigate = useNavigate();

  const isSocialPolicy = !!useMatch(DOCUMENTS_SOCIAL_POLICY_ROUTE);

  const showNoDataFound = !filteredTasks?.length && !isLoading;
  if (showNoDataFound) {
    return <DocumentsNotFound />;
  }

  const renderTask = (task: KedoTask | KedoTaskDraft) => {
    const currentStatus =
      'currentKedoStatus' in task ? task?.currentKedoStatus : KEDO_TASK_DRAFT_STATUS;
    const tagConfig = getKedoTagConfig(currentStatus);

    const handleClick = () => {
      const id = isDraft ? task.statement.id : task.id;

      let targetRoute = getNavigateRoute(id);

      if (isSocialPolicy && isDraft) {
        targetRoute = getNavigateRoute(task.statement.categoryId, id);
      }

      navigate(targetRoute);
    };

    let displayDate = task?.updatedAt;

    if (!task?.updatedAt && 'createdAt' in task) {
      displayDate = task.createdAt;
    }

    return (
      <button onClick={handleClick} className={s.taskElement} key={`documents__list__${task.id}`}>
        <div>
          <Typography.Text size="12" type="secondary">
            {getCreatedDate(displayDate)}
          </Typography.Text>
          <Typography.Text size="14" className={s.text} weight="500">
            {task.statement.name}
          </Typography.Text>
          <Tag
            className={cn(s.tag, s[tagConfig.type])}
            type={tagConfig.type}
            children={
              <>
                <Icon name={tagConfig.icon} />
                <span>{tagConfig.children}</span>
              </>
            }
          />
        </div>
        <Icon name="forward" />
      </button>
    );
  };

  return <div className={s.tasks}>{filteredTasks.map(renderTask)}</div>;
};

export default DocumentsList;
