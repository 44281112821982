import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  getSocialPolicyCategoryWithIdRoute,
  getSocialPolicyProgramApplyRoute,
} from 'routes/guide/list';
import { t } from 'tools/i18n';
import useCertificate from 'utils/useCertificate.hook';
import useIsSignEnabled from 'utils/useIsSignEnabled.hook';

import { useGetSocialProgramByIdQuery } from 'services/social-program/socialProgramApiService';
import { setActiveKedoFormTask } from 'slices/kedo';
import { useAppDispatch } from 'store';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import NoCertificateModal from 'containers/modals/no-certificate-modal';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './GuideSocialPolicyCategoryProgram.module.scss';

const GuideSocialPolicyCategoryProgram = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useParams<{ socialCategoryId: string; socialProgramId: string }>();
  const { isActive: isActiveCertificate } = useCertificate();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const { data: socialProgramDetailsData, isLoading: socialProgramDetailsIsLoading } =
    useGetSocialProgramByIdQuery(params.socialProgramId!);

  const headerChildren = (
    <>
      <Header.GoBack to={getSocialPolicyCategoryWithIdRoute(params.socialCategoryId!)} />
      <Header.Label label={t('guide_social_policy_program_view')} />
    </>
  );

  const handleModalOpenClose = () => setIsModalOpened((prevState) => !prevState);

  const handleApply = () => {
    if (isActiveCertificate) {
      dispatch(setActiveKedoFormTask(null));
      navigate(
        getSocialPolicyProgramApplyRoute(
          params.socialCategoryId!,
          params.socialProgramId!,
          socialProgramDetailsData?.kedoStatementId!,
        ),
      );
    } else {
      handleModalOpenClose();
    }
  };

  const signEnabled = useIsSignEnabled();
  const canBeApplied = !!socialProgramDetailsData?.kedoStatementId;
  const showApplyButton = signEnabled && canBeApplied;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={false}>
        <WithBackground id="socialPolicyDetailsView" className={s.wrapper}>
          <WithLoader isLoading={socialProgramDetailsIsLoading}>
            <Card>
              <Typography.Title level={4}>{socialProgramDetailsData?.nameProgram}</Typography.Title>
            </Card>
            {!!socialProgramDetailsData?.payoutAmounts?.length && (
              <Card>
                <Typography.Title level={4}>
                  {t('guide_social_policy_payout_amount')}
                </Typography.Title>
                {socialProgramDetailsData?.payoutAmounts?.map(renderPayout)}
              </Card>
            )}
            {!!socialProgramDetailsData?.paymentTerm && (
              <Card>
                <Typography.Title level={4}>
                  {t('guide_social_policy_payment_term')}
                </Typography.Title>
                <div className={s.row}>
                  <Icon name="forward" />
                  <Typography.Text size="14">
                    {socialProgramDetailsData.paymentTerm}
                  </Typography.Text>
                </div>
              </Card>
            )}
            {!!socialProgramDetailsData?.socialProgramEmployeeRequirements?.length && (
              <Card>
                <Typography.Title level={4}>{t('common_requirements')}</Typography.Title>
                {socialProgramDetailsData?.socialProgramEmployeeRequirements?.map(
                  renderRequirement,
                )}
              </Card>
            )}
            {!!socialProgramDetailsData?.socialProgramDocuments?.length && (
              <Card>
                <Typography.Title level={4}>{t('common_documents_list')}</Typography.Title>
                {socialProgramDetailsData?.socialProgramDocuments?.map(
                  renderSocialProgramDocuments,
                )}
              </Card>
            )}
            {!!socialProgramDetailsData?.deadlineSendDocs && (
              <Card>
                <Typography.Title level={4}>{t('common_documents_deadline')}</Typography.Title>
                <div className={s.row}>
                  <Icon name="ok" />
                  <Typography.Text size="14">
                    {socialProgramDetailsData?.deadlineSendDocs}
                  </Typography.Text>
                </div>
              </Card>
            )}
            {showApplyButton && (
              <div className={s.fixedCard}>
                <Button
                  htmlType="submit"
                  fullWidth
                  onClick={handleApply}
                  disabled={false}
                  loading={false}
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t('guide_social_policy_apply')}
                </Button>
              </div>
            )}
          </WithLoader>
        </WithBackground>
        <NoCertificateModal open={isModalOpened} handleClose={handleModalOpenClose} />
      </WithNavigation>
    </WithStatusBar>
  );
};

const renderSocialProgramDocuments = (document: { nameDocument: string }) => {
  return (
    <div className={s.row} key={document.nameDocument}>
      <Icon name="documents" />
      <Typography.Text size="14" weight="500" type="primary">
        {document.nameDocument}
      </Typography.Text>
    </div>
  );
};

const renderRequirement = (document: { nameEmployeeRequirements: string }) => {
  return (
    <div className={s.row} key={document.nameEmployeeRequirements}>
      <Icon name="ok" />
      <Typography.Text size="14">{document.nameEmployeeRequirements}</Typography.Text>
    </div>
  );
};

const renderPayout = (document: { namePayoutAmount: string }) => {
  return (
    <div className={s.row} key={document.namePayoutAmount}>
      <Icon name="forward" />
      <Typography.Text size="14">{document.namePayoutAmount}</Typography.Text>
    </div>
  );
};

export default GuideSocialPolicyCategoryProgram;
