import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetLeadersQuery } from 'services/me/meApiService';

import { KedoStatement, TASK_TYPE_ENUM } from 'types/documents.types';
import { LeaderType } from 'types/employee.types';

const useIsStatementUnavailable = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leadersData, isFetching: leadersIsFetching } = useGetLeadersQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee },
  );

  const leaderIsNotRegistered = leadersData?.find(findEmployeeLeaderWithSso);

  return {
    leadersIsFetching,
    getIsStatementUnavailable: (statement: KedoStatement) => {
      if (statement.type === TASK_TYPE_ENUM.BPMN) {
        const firstApprovalIsRequired = !!statement.params?.some(getApprovalFromLeaderRequired);

        return firstApprovalIsRequired && leaderIsNotRegistered;
      }

      return false;
    },
  };
};
const getApprovalFromLeaderRequired = (param: { code: string; value: string }) =>
  param.code === 'needFirstApprove' && param.value === 'true';

const findEmployeeLeaderWithSso = (leader: LeaderType) =>
  // Do not touch ssoRegistered === false it should be strictly equal !!false!!
  leader.num === 0 && leader.person.ssoRegistered === false;

export default useIsStatementUnavailable;
