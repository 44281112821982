import { FC } from 'react';

import { DOCUMENTS_BUSINESS_TRIP_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Card, Typography } from 'gazprom-ui-lib';

import BusinessTripForm from 'containers/business-trip-form/businessTripForm';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './DocumentsBusinessTripCreate.module.scss';
import useHandleSubmit from './useHandleSubmit.hook';

const DocumentsBusinessTripCreate: FC = () => {
  const { isLoading, handleSubmit } = useHandleSubmit();

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_BUSINESS_TRIP_ROUTE} />
      <Header.Label label={t('documents_business_trip_arrangement')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground className={s.wrapper}>
          <Card>
            <Typography.Title level={4}>
              {t('documents_business_trip_arrangement_long')}
            </Typography.Title>
          </Card>
          <BusinessTripForm handleSubmit={handleSubmit} isLoading={isLoading} type="create" />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsBusinessTripCreate;
