import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

export const getStatusTitle = (
  status: 'WAITING' | 'REJECTED' | 'APPROVED' | 'REVISION',
  finishDate: string,
) => {
  if (status === 'WAITING' || !status || !finishDate) return '';

  const finishDateText = dayjs(finishDate).format('DD.MM.YYYY, HH:mm');

  let statusTitle: string;

  switch (status) {
    case 'APPROVED':
      statusTitle = t('common_approved');
      break;
    case 'REVISION':
      statusTitle = t('common_declined');
      break;
    case 'REJECTED':
      statusTitle = t('documents_status_rejected_label');
      break;
    default:
      statusTitle = '';
  }

  return `${statusTitle}: ${finishDateText}`;
};

export const getAvatarStatusIcon = (status: 'WAITING' | 'REJECTED' | 'APPROVED' | 'REVISION') => {
  let icon: TIconsLiteral;

  switch (status) {
    case 'APPROVED':
      icon = 'ok';
      break;
    case 'REJECTED':
      icon = 'error';
      break;
    case 'REVISION':
      icon = 'delete';
      break;
    default:
      icon = 'time';
  }

  return <Icon name={icon} />;
};
