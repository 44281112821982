import { FC } from 'react';

import { t } from 'tools/i18n';

import { Form, Icon, Modal, Radio, Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from './SelectModal.module.scss';

type SelectOptionType = { label: string; value: string | number };

interface SelectModalProps {
  name: string;
  options: SelectOptionType[];
  isModalOpen: boolean;
  submitHandler: (value: string | number) => void;
  setIsModalOpen: (value: boolean) => void;
}

const SelectModal: FC<SelectModalProps> = (props) => {
  const { name, isModalOpen, options, submitHandler, setIsModalOpen } = props;
  const [form] = Form.useForm();

  const header = (
    <>
      <Header.GoBackButton onClick={() => setIsModalOpen(!isModalOpen)} />
      <Header.Label label={t('documents_select_drop_header_label')} />
    </>
  );

  const renderOptions = (params: SelectOptionType) => {
    const { label, value } = params;

    return (
      <Radio value={value} key={value}>
        <Typography.Title level={5}>{label}</Typography.Title>
        <Icon name="forward" size={13} />
      </Radio>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      className={s.dropdownModal}
      wrapClassName={s.wrapClassName}
      transitionName=""
      mask={false}>
      <WithNavigation headerChildren={header}>
        <WithBackground className={s.background}>
          <Form
            form={form}
            className={s.content}
            onFinish={(value: Record<string, string | number>) => submitHandler(value[name])}>
            <Form.Item name={name}>
              <Radio.Group onChange={form.submit}>{options.map(renderOptions)}</Radio.Group>
            </Form.Item>
          </Form>
        </WithBackground>
      </WithNavigation>
    </Modal>
  );
};

export default SelectModal;
