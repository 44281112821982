import { PropsWithChildren } from 'react';

import s from './Content.module.scss';

interface Props extends PropsWithChildren {}

const Content = (props: Props) => {
  const { children } = props;

  return <div className={s.wrapper}>{children}</div>;
};

export default Content;
