import React, { FC, useEffect, useState } from 'react';

import { Geolocation } from '@capacitor/geolocation';
import { useDispatch } from 'react-redux';
import { useAudit } from 'utils/useAudit.hook';

import { setLocation } from 'slices/guideSlice';

import { Button } from 'gazprom-ui-lib';

import AUDIT_EVENTS from 'constants/auditEvents';
import { MODALS_QR_ENUM } from 'types/guide.types';

import GuideModalsLocation from '../guide-modals-location';
import GuideSelectQRModals from '../guide-modals-qr';
import s from './GuideButtonLocation.module.scss';

interface GuideButtonLocationProps {
  text: string;
}

const GuideButtonLocation: FC<GuideButtonLocationProps> = (props) => {
  const { text } = props;

  const [modal, setModal] = useState<MODALS_QR_ENUM>(MODALS_QR_ENUM.EMPTY);
  const dispatch = useDispatch();
  const { auditSuccess, auditError } = useAudit();

  const handleLocation = async () => {
    try {
      let permissionsStatus = await Geolocation.checkPermissions();

      if (
        permissionsStatus.location === 'prompt' ||
        permissionsStatus.location === 'prompt-with-rationale'
      ) {
        const requestPermissionsResult = await Geolocation.requestPermissions({
          permissions: ['location'],
        });
        permissionsStatus = requestPermissionsResult;
      }

      if (permissionsStatus.location === 'granted') {
        auditSuccess(AUDIT_EVENTS.PERMISSIONS.LOCATION);
        const { coords } = await Geolocation.getCurrentPosition();
        dispatch(setLocation({ x: coords.latitude, y: coords.longitude }));
        auditSuccess(AUDIT_EVENTS.GEOLOCATION.DEFINED);
      }

      if (permissionsStatus.location === 'denied') {
        auditError(AUDIT_EVENTS.PERMISSIONS.LOCATION);
        setModal(MODALS_QR_ENUM.NO_ACCESS_GEO);
      }
    } catch (error) {
      auditError(AUDIT_EVENTS.PERMISSIONS.LOCATION);
      setModal(MODALS_QR_ENUM.NO_ACCESS_GEO);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setLocation(null));
      setModal(MODALS_QR_ENUM.EMPTY);
    };
  }, [dispatch]);

  return (
    <>
      <Button
        type="primary"
        fullWidth
        size="large"
        leftIcon="location"
        onClick={handleLocation}
        className={s.locationButton}>
        {text}
      </Button>

      <GuideModalsLocation modal={modal} setModal={setModal} />
      <GuideSelectQRModals handleLocation={handleLocation} />
    </>
  );
};

export default GuideButtonLocation;
