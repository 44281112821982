import React, { useCallback, useEffect, useState } from 'react';

import { Style } from '@capacitor/status-bar';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE, HOME_SELECT_COMPANY } from 'routes/home/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Icon } from 'gazprom-ui-lib';

import Story from 'components/story';
import Swiper from 'components/swiper';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import slider2Workers from 'assets/icons/slider2Workers.png';
import sliderMap from 'assets/icons/sliderMap.png';
import sliderWelder from 'assets/icons/sliderWelder.png';
import sliderWinter from 'assets/icons/sliderWinter.png';
import sliderWorkTogether from 'assets/icons/sliderWorkTogether.png';
import { ReactComponent as LogoBlue } from 'assets/logo/logo-short-name-row-blue.svg';
import { ReactComponent as LogoWhite } from 'assets/logo/logo-short-name-row-white.svg';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import s from './HomeSwiper.module.scss';

type StoryBackground = 'white' | 'blue';
type StoryType = {
  background: StoryBackground;
  title: string;
  src: string;
  alt: string;
  className?: string;
};

const HomeSwiper = () => {
  const navigate = useNavigate();

  const [color, setColor] = useState<StoryBackground | ''>('');

  const [hideSwiper, setHideSwiper] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_HOME_SWIPER,
    false,
  );
  const [selectedEmployee] = useFullSelectedEmployee();

  const handleStoriesEnd = useCallback(() => {
    setHideSwiper(true);
  }, [setHideSwiper]);

  useEffect(() => {
    if (hideSwiper) {
      navigate(selectedEmployee ? HOME_ROUTE : HOME_SELECT_COMPANY);
    }
  }, [navigate, selectedEmployee, hideSwiper]);

  const storyContentRenderer = useCallback(
    (contentProps: StoryType) => {
      const { className, background, title, src, alt } = contentProps;

      return (
        <Story className={cn(s[background], className)}>
          <button className={s.close} onClick={handleStoriesEnd}>
            <Icon name="delete" />
          </button>
          <Story.Header>{color === 'white' ? <LogoBlue /> : <LogoWhite />}</Story.Header>
          <Story.Title className={background === 'white' ? '' : s.neutralTitle}>
            {title}
          </Story.Title>
          <Story.Img alt={alt} src={src} />
        </Story>
      );
    },
    [color, handleStoriesEnd],
  );

  const renderStory = (story: StoryType) => ({
    content: () => storyContentRenderer(story),
  });

  return (
    <WithStatusBar style={color === 'white' ? Style.Light : Style.Dark}>
      <div className={cn(s.wrapper, s[color])}>
        <Swiper
          stories={STORIES.map(renderStory)}
          onAllStoriesEnd={handleStoriesEnd}
          onStoryStart={(storyIndex: number) => setColor(STORIES[storyIndex].background)}
        />
      </div>
    </WithStatusBar>
  );
};

const STORIES: StoryType[] = [
  {
    background: 'blue',
    title: 'Газстройпром – 6 лет работаем вместе на проектах Газпрома',
    src: sliderWorkTogether,
    alt: 'работаем вместе',
  },
  {
    background: 'white',
    title: 'Газстройпром – ведущая российская строительная компания',
    src: sliderWinter,
    alt: 'работаем вместе',
  },
  {
    background: 'blue',
    title: 'Газстройпром сегодня – это более 90 000 человек',
    src: slider2Workers,
    alt: 'работаем вместе',
  },
  {
    background: 'blue',
    title: 'Проекты по всей России',
    src: sliderMap,
    alt: 'работаем вместе',
  },
  {
    background: 'blue',
    title: 'Каждый вахтовый работник получает страховку от несчастных случаев 1 000 000 рублей',
    src: sliderWelder,
    alt: 'работаем вместе',
  },
];

export default HomeSwiper;
