import { t } from 'tools/i18n';

export type StepsType = 'old' | 'new' | 'newRepeat' | 'success';

export const getLabel = (step: StepsType) => {
  switch (step) {
    case 'old': {
      return t('profile_auth_enter_old_pin');
    }
    case 'new': {
      return t('profile_auth_enter_new_pin');
    }
    case 'newRepeat': {
      return t('profile_auth_repeat_new_pin');
    }
    case 'success': {
      return t('identifier_passcode_set');
    }
  }
};
