import { useContext } from 'react';

import { AssignmentContext } from '../../documentsAssignment';
import useAssignmentConfig from '../../useAssignmentConfig.hook';
import s from './Employee.module.scss';

const Employee = () => {
  const assignment = useContext(AssignmentContext);

  const getAssignmentConfig = useAssignmentConfig({ isList: false, detailed: true });

  const { employee } = getAssignmentConfig(assignment);

  if (!employee) {
    return null;
  }

  return <div className={s.wrapper}>{employee}</div>;
};

export default Employee;
