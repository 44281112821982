import React, { FC } from 'react';

import cn from 'classnames';

import s from './StoryImg.module.scss';

interface StoryImgProps {
  src: string;
  alt: string;
  className?: string;
}

const StoryImg: FC<StoryImgProps> = (props) => {
  const { className, alt, src } = props;

  return <img className={cn(s.wrapper, className)} alt={alt} src={src} />;
};

export default StoryImg;
