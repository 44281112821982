import { useEffect } from 'react';

import { t } from 'tools/i18n';

import { FormInstance } from 'gazprom-ui-lib';

import { FORM_STATEMENT_ENUM } from 'containers/attribute/attribute.utils';

import { DELIVER_OPTIONS_VALUE } from 'pages/documents/documents.utils';

interface Props {
  form: FormInstance;
  draftValue?: string | null;
  hasDigitalForm?: boolean;
}

const useSetDraftValueHook = (props: Props) => {
  const { form, draftValue, hasDigitalForm } = props;

  useEffect(() => {
    if (draftValue) {
      let draftDeliveryValue = [];

      if (hasDigitalForm) {
        draftDeliveryValue.push(DELIVER_OPTIONS_VALUE.ELECTRONIC);
      }

      if (draftValue.includes(t(DELIVER_OPTIONS_VALUE.PERSONALLY))) {
        draftDeliveryValue.push(DELIVER_OPTIONS_VALUE.PERSONALLY);
      }

      if (draftValue.includes(t(DELIVER_OPTIONS_VALUE.EMAIL))) {
        draftDeliveryValue.push(DELIVER_OPTIONS_VALUE.EMAIL);
      }

      if (draftValue.includes(t(DELIVER_OPTIONS_VALUE.MAIL))) {
        draftDeliveryValue.push(DELIVER_OPTIONS_VALUE.MAIL);
      }

      if (!!draftDeliveryValue.length) {
        form.setFieldValue(FORM_STATEMENT_ENUM.DELIVER, draftDeliveryValue);

        if (draftDeliveryValue.includes(DELIVER_OPTIONS_VALUE.MAIL)) {
          const shippingAddressIndex = draftValue.indexOf(':') + 2;
          const shippingAddress = draftValue.substring(shippingAddressIndex);
          form.setFieldValue(FORM_STATEMENT_ENUM.SHIPPING_ADDRESS, shippingAddress);
        }
      }
    }
  }, [draftValue, hasDigitalForm, form]);
};

export default useSetDraftValueHook;
