import { useContext, useState } from 'react';

import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';

import { Button, Form, ModalDefault } from 'gazprom-ui-lib';

import AttributeMultifile from 'containers/attribute/attribute-multifile';
import Card from 'containers/card';
import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';

import useSetModalFromLocationState from '../useSetModalFromLocationState.hook';
import { MODAL_TYPE } from '../utils';
import s from './ActionsWorkTourDraft.module.scss';
import { ModalType } from './actionsWorkTourDraft.utils';
import useActions from './useActions.hook';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
}

const ActionsWorkTourDraft = (props: Props) => {
  const { handleCertificateCheck } = props;
  const assignment = useContext(AssignmentContext);

  const navigate = useNavigate();

  const [modalType, setModalType] = useState<ModalType>('');

  const [form] = Form.useForm();

  const handleModalClose = () => {
    navigate(DOCUMENTS_ROUTE);
  };

  const handleOpenDoneModal = () => {
    setModalType(MODAL_TYPE.DONE);
  };
  const { isLoading, handleFinish } = useActions({
    handleOpenDoneModal,
    handleCertificateCheck,
  });

  useSetModalFromLocationState({ setModalType });
  const areActionsAvailable = assignment?.status?.code === 'WAITING';

  let content = null;

  if (areActionsAvailable) {
    content = (
      <Card>
        <Form form={form} className={s.form} onFinish={handleFinish}>
          <AttributeMultifile
            name={t('documents_business_trip_tickets')}
            id="files"
            type={''}
            placeholder={''}
            tooltip={''}
            isMandatory={true}
            isPublish={true}
            code={''}
            createdAt={''}
            creator={''}
            editor={null}
            num={0}
            statementId={''}
            updatedAt={null}
            value={''}
          />
          <Button
            fullWidth
            htmlType="submit"
            size="large"
            rightIcon="arrowRight"
            type="primary"
            loading={isLoading}>
            {t('common_send')}
          </Button>
        </Form>
      </Card>
    );
  }

  return (
    <>
      {content}
      <ModalDefault
        key="correctionTaskModal"
        open={modalType === MODAL_TYPE.DONE}
        onCancel={handleModalClose}
        width={300}>
        <ModalDefault.Icon name="ok" type="success" />
        <ModalDefault.Title>{t('documents_assignment_completed')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_assignment_docs_attached')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button size="large" fullWidth onClick={handleModalClose}>
            {t('common_ok')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default ActionsWorkTourDraft;
