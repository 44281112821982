import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { locationGuideSelector } from 'selectors/guide.selector';
import {
  useActivateSettlementMutation,
  useGetMySettlementsQuery,
} from 'services/guide/guideApiService';
import { setLocation } from 'slices/guideSlice';
import { useAppSelector } from 'store';

import { Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SettlementType } from 'types/employee.types';

import GuideButtonQR from '../guide-button-qr';
import GuideSelectButton from '../guide-select-button';
import GuideSelect from '../guide-select/guideSelect';
import s from './GuideSelectSettlement.module.scss';

const GuideSelectSettlement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();
  const [activateSettlement] = useActivateSettlementMutation();
  const location = useAppSelector(locationGuideSelector);
  const { data, isLoading } = useGetMySettlementsQuery(
    location
      ? {
          x: location.x,
          y: location.y,
          page: 1,
          size: 500,
        }
      : skipToken,
  );

  const handleChangeSettlement = (id: string) => {
    activateSettlement({ myEmployeeId: selectedEmployee?.id!, settlementId: id }).then(() =>
      navigate(GUIDE_ROUTE),
    );
  };

  const headerChildren = (
    <>
      <Header.GoBackButton
        onClick={() => {
          dispatch(setLocation(null));
          navigate(-1);
        }}
      />
      <Header.Label label={t('guide_select_your_current_location')} />
    </>
  );

  const renderButtons = (params: SettlementType) => {
    const { name, id } = params;

    return <GuideSelectButton name={name} id={id} key={id} onClick={handleChangeSettlement} />;
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false} className={s.contaiener}>
              <Typography.Title level={4}>{t('guide_found_settlements')}</Typography.Title>
              {data?.items.map(renderButtons)}
            </Card>
            <GuideSelect
              icon="home"
              title={t('guide_Identify_by_qr')}
              subtitle={t('guide_not_found_in_list')}
              buttons={<GuideButtonQR text={t('guide_select_button_qr')} primary />}
            />
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideSelectSettlement;
