import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  MORE_ROUTE,
  getSuggestionDecisionRoute,
  getSuggestionRequestRoute,
} from 'routes/more/list';
import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';

import { Card, Flex, Segmented, Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { PaginationPropsType } from 'types/pagination.types';
import { SuggestionPreviewType } from 'types/suggestion.types';

import s from './MoreSuggestions.module.scss';
import SuggestionDecisionsList from './containers/decisions-list';
import IdeasStatuses from './containers/ideas-statuses';
import { DEFAULT_ALL_STATUS } from './containers/ideas-statuses/ideasStatuses.utils';
import SuggestionsList from './containers/list';
import PageDescription from './containers/page-description';
import {
  DEFAULT_PAGINATION_CONFIG,
  SEGMENT_OPTIONS,
  SegmentedValueType,
} from './moreSuggestions.utils';
import useGetDataHook from './useGetData.hook';

const MoreSuggestions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [pagination, setPagination] = useState<PaginationPropsType>(DEFAULT_PAGINATION_CONFIG);
  const [selectedSegment, setSelectedSegment] = useState<SegmentedValueType>(
    SEGMENT_OPTIONS[0].value,
  );

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [activeStatus, setActiveStatus] = useState<string>(DEFAULT_ALL_STATUS.code);
  const [suggestionsToShow, setSuggestionsToShow] = useState<SuggestionPreviewType[]>([]);

  useEffect(() => {
    if (location?.state?.activeTab === 'employees') {
      setSelectedSegment(SEGMENT_OPTIONS[1].value);

      navigate(location.pathname, { replace: true });
    }
  }, [location.pathname, location?.state?.activeTab, navigate]);

  const isSuggestionDecisions = selectedSegment === 'employees';

  const {
    suggestionDecisions,
    suggestions,
    isLoading,
    isFetching,
    isUninitialized,
    handleSetActiveStatus,
    handlePageRefresh,
    handleSegmentChange,
  } = useGetDataHook({
    activeStatus,
    setSuggestionsToShow,
    pagination,
    setPagination,
    isRefreshing,
    setIsRefreshing,
    setActiveStatus,
    isSuggestionDecisions,
    selectedSegment,
    setSelectedSegment,
  });

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_ROUTE} useDefaultGoBack />
      <Header.Label label={t('common_innovations')} />
    </>
  );

  const { totalCount } = suggestions ?? {};

  const isFetchMoreAvailable = pagination.page * pagination.size < (totalCount ?? 0);
  const infiniteScrollIsFetching = isFetching || isUninitialized;

  const mySuggestionsContent = (
    <WithInfiniteScroll
      fetchMoreData={fetchMoreData(setPagination)}
      isFetching={infiniteScrollIsFetching}
      isFetchMoreAvailable={isFetchMoreAvailable}>
      <SuggestionsList
        suggestions={suggestionsToShow}
        getNavigateRoute={getSuggestionRequestRoute}
      />
    </WithInfiniteScroll>
  );

  const employeeDecisionsContent = (
    <SuggestionDecisionsList
      getNavigateRoute={getSuggestionDecisionRoute}
      decisions={suggestionDecisions}
    />
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={handlePageRefresh}>
          <WithBackground>
            <PageDescription />
            <Card withPadding={false}>
              <Flex className={s.title} vertical gap="16">
                <Typography.Title level={4}>{t('common_suggested_ideas')}</Typography.Title>
                <Segmented
                  className={s.segmented}
                  options={SEGMENT_OPTIONS}
                  value={selectedSegment}
                  onChange={handleSegmentChange}
                  fullWidth
                />
              </Flex>
              <IdeasStatuses
                isSuggestionDecisions={isSuggestionDecisions}
                count={isSuggestionDecisions ? suggestionDecisions?.length : totalCount}
                activeStatus={activeStatus}
                setActiveStatus={handleSetActiveStatus}
              />
              <WithLoader isLoading={isLoading}>
                {isSuggestionDecisions ? employeeDecisionsContent : mySuggestionsContent}
              </WithLoader>
            </Card>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestions;
