import React, { FC } from 'react';

import { MilitaryDutyType } from 'types/employee.types';

import PersonalDataDetailsItem from '../personal-data-details-item';
import s from './profilePersonalDataDetailsMilitary.module.scss';
import { getMilitaryDataItems } from './profilePersonalDataDetailsMilitary.utils';

type ProfilePersonalDataDetailsMilitaryProps = {
  militaryData: MilitaryDutyType;
};

const ProfilePersonalDataDetailsMilitary: FC<ProfilePersonalDataDetailsMilitaryProps> = (props) => {
  const { militaryData } = props;

  return (
    <div className={s.militaryWrapper}>
      {getMilitaryDataItems(militaryData).map((item) => (
        <PersonalDataDetailsItem key={item.title} {...item} />
      ))}
    </div>
  );
};

export default ProfilePersonalDataDetailsMilitary;
