import React, { FC } from 'react';

import cn from 'classnames';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import CoverNoData from 'components/cover-no-data';

import s from './Cover.module.scss';

interface CoverProps {
  alt: string;
  src?: string;
  className?: string;
  showNoData?: boolean;
  clipped?: boolean;
}

const Cover: FC<CoverProps> = (props) => {
  const { className, src, alt, showNoData = false, clipped = true } = props;

  if (!src) {
    if (showNoData) {
      return <CoverNoData className={className} />;
    }

    return <></>;
  }

  const resolution = clipped ? '/1280x760' : '';
  const imageSrc = `${createMediaDownloadUrl(src)}${resolution}`;

  return <img src={imageSrc} alt={alt} className={cn(s.wrapper, className)} />;
};

export default Cover;
