import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { getDocumentsBusinessTripDetailsRoute } from 'routes/documents/list';

import { Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import { transformBackendDataIntoFront } from 'pages/documents/documents-business-trip/documentsBusinessTrip.utils';
import DocumentsNotFound from 'pages/documents/documents-not-found';
import { getKedoTagConfig } from 'pages/documents/documents.utils';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './List.module.scss';

interface ListProps {
  filteredBusinessTrips?: BusinessTripType[];
}

const List: FC<ListProps> = (props) => {
  const { filteredBusinessTrips } = props;
  const navigate = useNavigate();

  const contentLength = filteredBusinessTrips?.length;

  const handleNavigate = (id: string) => () => {
    navigate(getDocumentsBusinessTripDetailsRoute(id));
  };

  const renderBusinessTrip = (trip: BusinessTripType) => {
    const { dateFrom, dateTo, country, town, destinationOrg, id, currentKedoStatus } = trip;
    const tagConfig = getKedoTagConfig(currentKedoStatus);

    return (
      <button className={s.card} onClick={handleNavigate(id)}>
        <Flex vertical align="flex-start" gap="8">
          <Flex vertical>
            <Typography.Text weight="400" size="12" type="secondary" className={s.destination}>
              {`${country}, ${town}, ${destinationOrg}`}
            </Typography.Text>
            <Typography.Text weight="500" size="14">
              {`${transformBackendDataIntoFront(dateFrom).format(
                'DD.MM.YYYY',
              )} - ${transformBackendDataIntoFront(dateTo).format('DD.MM.YYYY')}`}
            </Typography.Text>
          </Flex>
          <Tag type={tagConfig.type} className={s.tag}>
            <Icon name={tagConfig.icon} />
            <span>{tagConfig.children}</span>
          </Tag>
        </Flex>
        <Icon name="forward" />
      </button>
    );
  };

  return (
    <div>
      {contentLength ? (
        <Flex vertical>{filteredBusinessTrips?.map(renderBusinessTrip)}</Flex>
      ) : (
        <DocumentsNotFound />
      )}
    </div>
  );
};

export default List;
