import { Dispatch, FC, MouseEvent, SetStateAction, useState } from 'react';

import type { RadioChangeEvent } from 'antd';
import { t } from 'tools/i18n';

import { Button, Flex, Icon, ModalDefault, Radio, Typography } from 'gazprom-ui-lib';

import TagButton from 'components/tag-button';

import { FilterItemType } from 'types/filter.types';

import s from './Filter.module.scss';
import { DEFAULT_OPTION_VALUE } from './filter.utils';

interface Props {
  id: string;
  label: string;
  appliedFilter?: string;
  setAppliedFilter?: Dispatch<SetStateAction<string>>;
  options: FilterItemType[];
}

const Filter: FC<Props> = (props) => {
  const { id, label, appliedFilter, options, setAppliedFilter } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(appliedFilter ?? DEFAULT_OPTION_VALUE);

  const handleOpenClose = () => {
    setIsOpen((prev) => !prev);
  };

  const onChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  const handleIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isActive && setAppliedFilter) {
      setAppliedFilter(DEFAULT_OPTION_VALUE);
      setSelectedValue(DEFAULT_OPTION_VALUE);
    } else {
      handleOpenClose();
    }
  };

  const handleConfirm = () => {
    if (selectedValue && setAppliedFilter) {
      setAppliedFilter(selectedValue);
    }
    handleOpenClose();
  };

  const buttonLabel =
    appliedFilter === DEFAULT_OPTION_VALUE
      ? label
      : options.find((option) => option.id === appliedFilter)?.name ?? '';

  const isActive = !!appliedFilter && appliedFilter !== DEFAULT_OPTION_VALUE;

  const renderOption = (option: FilterItemType) => (
    <Radio key={option.id} value={option.id}>
      <Typography.Text size="16">{option.name}</Typography.Text>
    </Radio>
  );

  const modalActions = (
    <Button leftIcon="ok" size="large" onClick={handleConfirm}>
      {t('common_confirm')}
    </Button>
  );

  const tagButtonSuffix = (
    <Flex onClick={handleIconClick}>
      {isActive ? (
        <Icon name="deleteFilled" color="var(--color-neutral-1)" />
      ) : (
        <Icon name="filter" color="var(--color-character-secondary-45)" />
      )}
    </Flex>
  );

  return (
    <>
      <TagButton
        toggleIsActive={handleOpenClose}
        id={id}
        name={buttonLabel}
        isActive={isActive}
        suffix={tagButtonSuffix}
      />
      <ModalDefault open={isOpen} closable onCancel={handleOpenClose}>
        <ModalDefault.Title level={3}>{label}</ModalDefault.Title>
        <Radio.Group onChange={onChange} value={selectedValue} className={s.options}>
          {options.map(renderOption)}
        </Radio.Group>
        <ModalDefault.Actions>{modalActions}</ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default Filter;
