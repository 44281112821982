import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';

import { activeKedoTaskSelector } from 'selectors/kedoSlice.selector';
import { useSignMutation } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { prepareRequestBody } from './documentsSignList.utils';

interface UseHandleSignProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

const useHandleSign = (props: UseHandleSignProps) => {
  const { setIsModalVisible } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const activeKedoTask = useAppSelector(activeKedoTaskSelector);

  const [sign, { isLoading: signIsLoading }] = useSignMutation();

  const handleSign = () => {
    const onSuccess = (data?: Record<string, unknown>) => {
      if (data) {
        const state = { ...data, ...(location.state || {}) };

        navigate(DOCUMENTS_SIGN_CODE_ROUTE, {
          state,
        });
      }
    };

    const onError = () => {
      setIsModalVisible(true);
    };

    const { taskType, taskId, workTourId } = location?.state ?? {};

    const fileKeys = activeKedoTask?.filesToSign.map((file: { fileKey: string }) => file.fileKey);

    const requestBody = prepareRequestBody(fileKeys, taskId, workTourId, taskType);

    sign(requestBody)
      .then(
        handleRequest({
          onSuccess,
          onError,
        }),
      )
      .catch(() => {
        setIsModalVisible(true);
      });
  };

  return {
    handleSign,
    isLoading: signIsLoading,
  };
};

export default useHandleSign;
