import { Fragment } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetHotContactsQuery } from 'services/guide/guideApiService';

import { Divider, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { HotContactsType } from 'types/guide.types';

import GuideSelected from '../guide-selected';
import s from './GuideImportantContacts.module.scss';
import GuideImportantContactsContact from './guide-important-contacts-contact';

const GuideImportantContacts = () => {
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: contacts, isLoading } = useGetHotContactsQuery({
    myEmployeeId: selectedEmployee?.id!,
  });

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('guide_selected_important_contacts_title')} />
    </>
  );

  const renderHotContactInfo = (props: HotContactsType) => {
    const { name, contacts, id } = props;

    return (
      <Fragment key={id}>
        <Divider />
        <div className={s.mainContent}>
          <GuideImportantContactsContact title={name} contacts={contacts} />
        </div>
      </Fragment>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <GuideSelected text={selectedEmployee?.currentSettlement.name!} />
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false} className={s.container}>
              <Typography.Title level={4}>
                {t('guide_selected_important_contacts_title')}
              </Typography.Title>
              {contacts?.map(renderHotContactInfo)}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideImportantContacts;
