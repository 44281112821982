import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationsQuery } from 'services/notification/notificationApiService';

import { Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import { FirstTimeVisit } from 'components/first-time-visit';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { NotificationType } from 'types/notifications.types';

import NotificationsCard from './notifications-card';
import s from './notifications.module.scss';

const PAGE_SIZE = 20;

export default function Notifications() {
  const navigate = useNavigate();
  const [items, setItems] = useState<NotificationType[]>([]);

  const [page, setPage] = useState(1);
  const [getNotifications, { data: notificationsData, isFetching }] =
    useLazyGetNotificationsQuery();

  const loadMoreRef = useRef<boolean>(false);

  const { debouncedValue: debouncedPage, isDebouncing: pageIsDebouncing } = useDebounce(page, 600);

  const SEGMENT_OPTIONS = [
    {
      label: (
        <div className={s.segmentOption}>
          <Typography.Title level={5}>{t('notifications_lst_all')}</Typography.Title>
          <Typography.Text size="10">{notificationsData?.totalCount}</Typography.Text>
        </div>
      ),
      value: 'all',
    },
    {
      label: (
        <div className={s.segmentOption}>
          <Typography.Title level={5}>{t('notifications_lst_adressed')}</Typography.Title>
          <Typography.Text size="10">0</Typography.Text>
        </div>
      ),
      value: 'adresed',
    },
  ];
  const [allTab] = SEGMENT_OPTIONS;
  const [selectedSegment, setSelectedSegment] = useState(allTab.value);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('common_notifications')} />
    </>
  );

  useEffect(() => {
    getNotifications({
      page: debouncedPage,
      size: PAGE_SIZE,
    })
      .then((res) => {
        if (res?.data && 'items' in res.data) {
          setItems((prevState) => [...prevState, ...(res.data?.items || [])]);
        }
      })
      .finally(() => (loadMoreRef.current = true));
  }, [debouncedPage, getNotifications]);

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSelectedSegment(segment.toString());

  const renderNotificationsCard = (
    params: NotificationType,
    index: number,
    arr: NotificationType[],
  ) => {
    const { body, createdAt, id, title } = params;

    const isRenderDivider = index < arr.length - 1;
    return (
      <NotificationsCard
        id={id}
        date={createdAt}
        title={title}
        text={body}
        isRenderDivider={isRenderDivider}
        key={id}
      />
    );
  };

  const handleFetchMoreData = () => {
    loadMoreRef.current = false;
    setPage(page + 1);
  };

  const isFetchMoreAvailable =
    page * PAGE_SIZE < (notificationsData?.totalCount ?? 0) && loadMoreRef.current;

  const infiniteScrollIsFetchingProp = isFetching || pageIsDebouncing;

  const renderNotificationsBySegment = () => {
    if (selectedSegment === 'all') {
      return items.length > 0 ? (
        <WithInfiniteScroll
          fetchMoreData={handleFetchMoreData}
          isFetching={infiniteScrollIsFetchingProp}
          isFetchMoreAvailable={isFetchMoreAvailable}>
          {items.map(renderNotificationsCard)}
        </WithInfiniteScroll>
      ) : (
        <Empty title="notifications_empty_title" description="notifications_empty_description" />
      );
    }

    return (
      <Empty title="notifications_empty_title" description="notifications_empty_description" />
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <FirstTimeVisit
            title="common_notifications"
            description="notifications_here_you_can_read"
            icon={{ name: 'notification' }}
            storageKey={SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NOTIFICATIONS_FIRST_TIME_VISIT}
          />
          <Card withPadding>
            <Segmented
              options={SEGMENT_OPTIONS}
              value={selectedSegment}
              onChange={handleSegmentChange}
              fullWidth
            />
            <WithLoader isLoading={isFetching && items.length === 0}>
              {renderNotificationsBySegment()}
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
}
