import { t } from 'tools/i18n';

import { FamilyType } from 'types/employee.types';

import { PersonalDataItemType } from '../profile-personal-data-details-general/profilePersonalDataDetailsGeneral.utils';

export const getPersonalDataGeneralItems = (familyData: FamilyType): PersonalDataItemType[] => [
  {
    title: t('common_full_name'),
    value: familyData?.surname + ' ' + familyData?.name + ' ' + familyData?.patronymic,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_relation_degree'),
    value: familyData?.familyType,
  },
  {
    title: t('common_birthdate'),
    value: familyData?.familyBirthDate,
    date: true,
  },
];
