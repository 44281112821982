import { t } from 'tools/i18n';

import { PersonalDataType } from 'types/employee.types';

export type PersonalDataItemType = {
  title: string;
  value: string;
  fullWidth?: boolean;
  date?: boolean;
};

export const getPersonalDataGeneralItems = (
  personalData: PersonalDataType,
): PersonalDataItemType[] => [
  {
    title: t('my_personal_data_gender'),
    value: personalData?.gender,
  },
  {
    title: t('common_birthdate'),
    value: personalData?.birthDate,
    date: true,
  },
  {
    title: t('common_birthplace'),
    value: personalData?.birthPlace,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_document_type'),
    value: personalData?.identityDocs[0]?.type,
  },
  {
    title: t('my_personal_data_citizenship'),
    value: personalData?.citizenship,
  },
  {
    title: t('common_passport_serial_and_number'),
    value: !!personalData?.identityDocs[0]?.series
      ? `${personalData?.identityDocs[0]?.series} ${personalData?.identityDocs[0]?.number}`
      : personalData?.identityDocs[0]?.number,
  },
  {
    title: t('common_issue_date'),
    value: personalData?.identityDocs[0]?.issueDate,
    date: true,
  },
  {
    title: t('common_issued'),
    value: personalData?.identityDocs[0]?.issuingAuthority,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_inn'),
    value: personalData?.inn,
  },
  {
    title: t('my_personal_data_snils'),
    value: personalData?.snils,
  },
  {
    title: t('my_personal_data_registration_address'),
    value: personalData?.registrationAddress,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_residence_address'),
    value: personalData?.residentialAddress,
    fullWidth: true,
  },
];
