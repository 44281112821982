import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useActivateSettlementMutation } from 'services/guide/guideApiService';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

const ActivateSettlementById = () => {
  const navigate = useNavigate();
  const { settlementId } = useParams<{ settlementId: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();
  const [mutation, { isLoading }] = useActivateSettlementMutation();

  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  useEffect(() => {
    if (settlementId && selectedEmployee?.currentSettlement.id !== settlementId) {
      mutation({ myEmployeeId: selectedEmployee?.id!, settlementId }).then(() =>
        navigate(GUIDE_ROUTE),
      );
    } else {
      navigate(GUIDE_ROUTE);
    }
  }, [
    mutation,
    navigate,
    selectedEmployee?.currentSettlement.id,
    selectedEmployee?.id,
    settlementId,
  ]);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default ActivateSettlementById;
