import { FC, useEffect } from 'react';

import { SelectAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form, Radio } from 'gazprom-ui-lib';

import SurveyTitle from 'pages/surveys/surveys-question-title';

import { Options } from 'types/surveys.types';

import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsSelect.module.scss';

export interface SurveysQuestionsSelectProps extends QuestionProps {
  no: number;
  is_required: boolean;
  title: string;
  options: Options[];
}

const SurveysQuestionsSelect: FC<SurveysQuestionsSelectProps> = (props) => {
  const { id, type, no, is_required, title, options } = props;
  const form = Form.useFormInstance();
  const { isCompleted, answers } = useAppSelector((store) => store.surveySlice);
  const findedQuestion = answers?.answer.find((question) => question.question_id === id) as
    | SelectAnswerType
    | undefined;
  const answer = findedQuestion?.question_answer.answer?.options.find(
    (option) => option.answer_value === true,
  );
  const name = `${type}.${id}`;

  useEffect(() => {
    if (answer) {
      form.setFieldValue(name, answer?.option_id);
    }
  }, [answer, form, name]);

  return (
    <div className={s.select_container}>
      <SurveyTitle level={5} number={no} required={is_required}>
        {title}
      </SurveyTitle>
      <Form.Item name={name}>
        <Radio.Group className={s.radioGroup} disabled={isCompleted}>
          {options.map(({ id, title, image_id }) => (
            <Radio value={id} key={id}>
              {image_id && <img src={image_id.file_url} alt="radio alt" className={s.image} />}
              {title}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default SurveysQuestionsSelect;
