import Notifications from 'pages/notifications';
import NotificationsSingle from 'pages/notifications/notifications-single';

import { RouteConfig } from '../routes';
import { NOTIFICATIONS_ROUTE, NOTIFICATION_ROUTE } from './list';

export const notificationsRoutes: RouteConfig[] = [
  {
    path: NOTIFICATIONS_ROUTE,
    element: <Notifications />,
  },
  {
    path: NOTIFICATION_ROUTE,
    element: <NotificationsSingle />,
  },
];
