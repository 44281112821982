import React, { memo } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import {
  useConfirmCertificateMutation,
  useDeclineCertificateMutation,
} from 'services/me/meApiService';

import { Button, Typography, handleRequest } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { ReactComponent as GosuslugiIcon } from 'assets/icons/gosuslugiIcon.svg';

import s from './DocumentsCreateCertificateActions.module.scss';

interface DocumentsCreateCertificateActionsProps {
  certificateId: string;
}

const DocumentsCreateCertificateActions = (props: DocumentsCreateCertificateActionsProps) => {
  const { certificateId } = props;

  const navigate = useNavigate();

  const [declineCertificate, { isLoading: declineCertificateIsFetching }] =
    useDeclineCertificateMutation();
  const [confirmCertificate, { isLoading: confirmCertificateIsFetching }] =
    useConfirmCertificateMutation();

  const handleGosuslugiCertificateConfirm = () => {
    confirmCertificate({ id: certificateId, type: 'ESIA' }).then(
      handleRequest({
        onSuccess: () => navigate(DOCUMENTS_ROUTE, { state: { certificateState: 'gosuslugi' } }),
      }),
    );
  };

  const handleSmsCertificateConfirm = () => {
    confirmCertificate({ id: certificateId, type: 'SMS' }).then(
      handleRequest({
        onSuccess: () =>
          navigate(DOCUMENTS_ROUTE, {
            state: { operationId: certificateId },
          }),
      }),
    );
  };

  const handleCertificateDecline = () => {
    declineCertificate(certificateId).then(
      handleRequest({
        onSuccess: () => navigate(DOCUMENTS_ROUTE),
      }),
    );
  };

  return (
    <>
      <Card.Default
        icon={<GosuslugiIcon />}
        title="Подтвердить личность"
        description="Вы будете перенаправлены на Единый портал государственных услуг"
      />
      <div className={s.actions}>
        <Button
          onClick={handleGosuslugiCertificateConfirm}
          loading={confirmCertificateIsFetching}
          rightIcon="arrowRight"
          fullWidth
          size="large">
          {t('Подтвердить на Госуслугах')}
        </Button>
        <Button
          onClick={handleCertificateDecline}
          loading={declineCertificateIsFetching}
          size="large"
          fullWidth
          type="secondary"
          className={s.cancelButton}>
          {t('common_cancel')}
        </Button>
      </div>
      <div className={s.additionalActions}>
        <Typography.Text weight="500" size="14" type="secondary">
          У вас нет аккаунта на Госуслугах?
        </Typography.Text>
        <button onClick={handleSmsCertificateConfirm}>
          <Typography.Text weight="500" size="14" type="primary">
            Подтвердить через УЦ ГСП
          </Typography.Text>
        </button>
      </div>
    </>
  );
};

export default memo(DocumentsCreateCertificateActions);
