import { FileType } from 'types/file.types';
import { SuggestionFileType } from 'types/suggestion.types';

export const getAttributesFilesToSet = (
  attributeFiles: SuggestionFileType[],
  attributeId: string,
): (FileType & { key: string })[] => {
  if (!attributeFiles) {
    return [];
  }

  return attributeFiles.map((file) => ({
    ...file,
    key: file.fileKey,
    attributeId,
    fileKeyWithStamp: null,
    num: null,
  }));
};
