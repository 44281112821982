import React, { FC, PropsWithChildren } from 'react';

import { AvailableResult } from 'capacitor-native-biometric';

import { Flex } from 'gazprom-ui-lib';

import Header from 'containers/header';

import Identifier from 'pages/identifier';

import s from './PasscodeVerification.module.scss';

export type PasscodeVerificationProps = PropsWithChildren & {
  biometricStatus: AvailableResult | null;
  onVerified: () => void;
  isLoading?: boolean;
};

const PasscodeVerification: FC<PasscodeVerificationProps> = (props) => {
  const { biometricStatus, children, onVerified } = props;

  return (
    <Flex vertical align="center" justify="center" className={s.content}>
      <Header className={s.header}>{children}</Header>
      <Identifier biometricStatus={biometricStatus!} onVerified={onVerified} />
    </Flex>
  );
};

export default PasscodeVerification;
