import { GetQuestionsBySurveyIdResponse, PageType } from './surveyApiService.types';
import { FreeQuestion, SurvyesQuestionsType } from 'types/surveys.types';

export const anketaUrl = (url: string) => `me/anketa${url}`;
export const personUrl = () => `me/anketa-person`;
export const anketologUrl = (url: string) => `anketolog/survey/${url}`;

const mapQuestions = (array: SurvyesQuestionsType[]) => {
  const freeQuestionsForScale = array.filter(
    (question) => question.type === 'free' && question.visiblerules.length > 0,
  ) as FreeQuestion[];

  return array
    .filter((question) => !(question.type === 'free' && question.visiblerules.length > 0))
    .map((question) => {
      if (question.type === 'scale') {
        const index = freeQuestionsForScale.findIndex(
          (el) => question.id === el.visiblerules[0].events[0].question_id,
        );
        const [element] = freeQuestionsForScale.splice(index, 1);
        return { ...question, additional_question: element };
      }
      return question;
    });
};

export const mapSurveyPages = (params: GetQuestionsBySurveyIdResponse) => {
  const mappedPages = params.struct.pages.reduce((acc, current, index) => {
    if (index === 0) {
      return { ...current };
    }

    return { ...acc, questions: [...acc.questions, ...current.questions] };
  }, {} as PageType);

  const questions = mapQuestions(mappedPages.questions);

  return {
    ...params,
    struct: { pages: [{ ...mappedPages, questions }] },
  };
};
