import { Dispatch, SetStateAction, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetArticleByIdQuery } from 'services/article/articleApiService';
import { useLazyGetEventByIdQuery } from 'services/me/meApiService';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { ArticleFullType } from 'types/article.types';
import { EventType } from 'types/event.types';

interface UseGetDataProps {
  isNewsDetails: boolean;
  setIsLiked: Dispatch<SetStateAction<boolean>>;
}

const useGetData = (props: UseGetDataProps) => {
  const { isNewsDetails, setIsLiked } = props;
  const params = useParams<{ newsCode: string }>();

  const dispatch = useAppDispatch();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [getArticle, { data: articleData, isFetching: articleDataIsFetching }] =
    useLazyGetArticleByIdQuery();
  const [getMeet, { data: meetingData, isFetching: meetIsFetching }] = useLazyGetEventByIdQuery();

  useEffect(() => {
    if (params.newsCode) {
      if (isNewsDetails) {
        const onArticleSuccess = (data?: ArticleFullType) => {
          if (data) {
            setIsLiked(data.liked);
          }
        };

        getArticle(params.newsCode).then(
          handleRequest({
            onSuccess: onArticleSuccess,
          }),
        );
      } else if (selectedEmployee) {
        const onEventSuccess = (data?: EventType) => {
          if (data) {
            setIsLiked(data.article.liked);
          }
        };
        getMeet({ id: params.newsCode, myEmployeeId: selectedEmployee.id }).then(
          handleRequest({
            onSuccess: onEventSuccess,
          }),
        );
      }
    }
  }, [dispatch, getArticle, getMeet, isNewsDetails, params.newsCode, selectedEmployee, setIsLiked]);

  const isFetching = articleDataIsFetching || meetIsFetching;

  return {
    data: isNewsDetails ? articleData : meetingData,
    isFetching,
  };
};

export default useGetData;
