import React, { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_NEWS_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';
import { useDebounce } from 'utils/useDebounce.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { useLazyGetNewsCategoriesQuery } from 'services/category/categoryApiService';
import {
  resetEvents,
  resetPagination as resetPaginationEvents,
  resetPaginationTagsAndEvents,
} from 'slices/eventsSlice';
import { resetNews, resetPagination, resetPaginationTagsAndNews } from 'slices/newsSlice';
import { useAppDispatch } from 'store';

import { Button, Icon, Input, InputProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import HomeNewsTagsActions from 'containers/news-tags-actions';
import WithBackground from 'containers/wrappers/with-background';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SEGMENT_NEWS_TYPE } from 'pages/home/home-news/home-news-list/homeNewsList.utils';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { NewsCategoryType } from 'types/news.types';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

import s from './HomeNewsTags.module.scss';

const DEFAULT_PAGINATION_CONFIG = {
  page: 1,
  size: 20,
};

const HomeNewsTags = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const nameTagRef = useRef('');

  const dispatch = useAppDispatch();

  const [showBottomFilter, setShowBottomFilter] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationPropsType>(DEFAULT_PAGINATION_CONFIG);
  const [nameTag, setNameTag] = useState<string>('');
  const [tagsToShow, setTagsToShow] = useState<NewsCategoryType[]>([]);

  const isNews = location?.state?.type === 'news';

  const [activeNewsTags, setNewsActiveTags] = useSecureStorage(
    isNews
      ? SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_TAGS
      : SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_EVENTS_TAGS,
    [] as string[],
  );
  const [localActiveNewsTags, setLocalNewsActiveTags] = useState<string[]>(activeNewsTags);

  const { debouncedValue: debouncedNameTag } = useDebounce(nameTag);
  const { debouncedValue: debouncedPagination, isDebouncing: paginationIsDebouncing } =
    useDebounce(pagination);

  const [getNewsCategory, { data, isFetching, isUninitialized }] = useLazyGetNewsCategoriesQuery();

  const headerChildren = (
    <>
      <Header.GoBack to={HOME_NEWS_ROUTE} state={location?.state} />
      <Header.Label label={t('home_news_tags_list')} />
    </>
  );

  useEffect(() => {
    if (location?.state?.type) {
      getNewsCategory({
        ...debouncedPagination,
        name: debouncedNameTag || undefined,
        type: location.state.type,
        sort: [{ property: 'name', order: SORT_ENUM.DESC }],
      }).then((res) => {
        if (res?.data && 'items' in res.data) {
          setTagsToShow((prevState) => {
            if (debouncedNameTag !== nameTagRef.current) {
              nameTagRef.current = debouncedNameTag;
              return res.data?.items!;
            }

            return prevState.concat(res.data?.items!);
          });
        }
      });
    }
  }, [
    debouncedNameTag,
    getNewsCategory,
    debouncedPagination,
    setTagsToShow,
    location?.state?.type,
  ]);

  const handleAddRemoveNewsActiveTags = (newsCode: string) => {
    setShowBottomFilter(true);
    const isAlreadyActive = localActiveNewsTags.includes(newsCode);
    const tagsToSet = isAlreadyActive
      ? localActiveNewsTags.filter((tag) => tag !== newsCode)
      : localActiveNewsTags.concat([newsCode]);

    setLocalNewsActiveTags(tagsToSet);
  };

  const handleFilter = () => {
    setNewsActiveTags(localActiveNewsTags);
    if (location?.state?.type === SEGMENT_NEWS_TYPE) {
      dispatch(resetNews());
      dispatch(resetPagination());
    } else {
      dispatch(resetEvents());
      dispatch(resetPaginationEvents());
    }
    navigate(HOME_NEWS_ROUTE, { state: location?.state });
  };

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setNameTag(e.target.value);
    if (pagination.page !== DEFAULT_PAGINATION_CONFIG.page) {
      setPagination(DEFAULT_PAGINATION_CONFIG);
    }
  };

  const renderNewsTag = (tag: NewsCategoryType) => {
    const isActive = localActiveNewsTags.includes(tag.id);

    return (
      <button
        key={tag.id}
        className={cn({ [s.activeTag]: isActive })}
        onClick={() => handleAddRemoveNewsActiveTags(tag.id)}>
        <div>
          <Typography.Title level={5}>{tag.name}</Typography.Title>
          {!!tag.countArticles && (
            <Typography.Text type="secondary">
              {t('home_news_materials_per_tag', { count: tag.countArticles })}
            </Typography.Text>
          )}
        </div>
        <Icon name={isActive ? 'ok' : 'plus'} />
      </button>
    );
  };

  const handleNewsTagsReset = useCallback(() => {
    setLocalNewsActiveTags([]);
    setNewsActiveTags([]);
    if (location?.state?.type === SEGMENT_NEWS_TYPE) {
      dispatch(resetPaginationTagsAndNews());
    } else {
      dispatch(resetPaginationTagsAndEvents());
    }
  }, [dispatch, location?.state?.type, setNewsActiveTags]);

  const isFetchMoreAvailable = pagination.page * pagination.size < (data?.totalCount ?? 0);
  const infiniteScrollIsFetching = isFetching || isUninitialized || paginationIsDebouncing;

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <Card withPadding={false} className={cn({ [s.cardWithFilter]: showBottomFilter })}>
              <div className={s.header}>
                <Typography.Title level={4}>
                  {t(isNews ? 'home_news_filter' : 'home_events_filter')}
                </Typography.Title>
                <Input
                  allowClear={{
                    clearIcon: <Icon name="deleteFilled" size={20} />,
                  }}
                  size="large"
                  prefix={<Icon name="search" />}
                  placeholder={`${t('home_news_filter_search')}...`}
                  value={nameTag}
                  onChange={handleSearchChange}
                />
                {!!localActiveNewsTags?.length && (
                  <HomeNewsTagsActions
                    tagsLength={localActiveNewsTags?.length}
                    handleReset={handleNewsTagsReset}
                  />
                )}
              </div>
              <WithInfiniteScroll
                fetchMoreData={fetchMoreData(setPagination)}
                className={s.infiniteScroll}
                isFetching={infiniteScrollIsFetching}
                isFetchMoreAvailable={isFetchMoreAvailable}>
                {tagsToShow?.map(renderNewsTag)}
              </WithInfiniteScroll>
            </Card>
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <div className={cn(s.bottomFilter, { [s.show]: showBottomFilter })}>
        <Button onClick={handleFilter} fullWidth size="large">
          {t('common_filters_selected')}
          <Typography.Text size="14">{localActiveNewsTags?.length}</Typography.Text>
        </Button>
      </div>
    </>
  );
};

export default HomeNewsTags;
