import { FC, useEffect, useState } from 'react';

import { Sunburst } from '@ant-design/charts';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import {
  AccruedChildrens,
  AccruedDataType,
  ComponentsType,
  PayslipDataType,
  SALARY_FILED_NAMES_ENUM,
} from 'types/mySalary.types';

import MySalaryBadge from '../../my-salary-badge';
import { MY_SALARY_COLORS, convertToRub, createSunBurstConfig } from '../../mySalary.utils';
import MySalaryDetailed from '../my-salary-detailed';
import { colorFunction } from '../mySalarySegmentMonth.utils';
import s from './MySalaryAccrued.module.scss';
import { mapAccruedData } from './mySalaryAccrued.utils';

interface MySalaryAccruedProps {
  data: PayslipDataType;
}

const renderDetailedInfo = (
  { amount, component }: ComponentsType,
  ind: number,
  className: string,
) => {
  return <MySalaryDetailed type={className} text={component.name} value={amount} key={ind} />;
};

const MySalaryAccrued: FC<MySalaryAccruedProps> = (props) => {
  const [active, setActive] = useState('');
  const [selectedSegment, setSelectedSegment] = useState<AccruedDataType | undefined | null>(null);
  const { data } = props;

  const { childrens, sum } = mapAccruedData(data.components);

  const configData = {
    name: 'data',
    children: childrens,
  };

  const config = createSunBurstConfig(
    configData,
    colorFunction(
      MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.DEFAULT].colorHex,
      MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.RESERVE].colorHex,
    ),
    0.8,
  );

  useEffect(() => {
    setSelectedSegment(null);
    setActive('');
  }, [data]);

  const renderBadges = (props: AccruedChildrens, ind: number) => {
    return (
      <MySalaryBadge
        text={props.name}
        number={props.value}
        className={props.className}
        key={ind}
        withInfo
        active={active === props.name}
        onClick={(text) => {
          setSelectedSegment(childrens.find((data) => data.name === text));
          setActive(text);
        }}
      />
    );
  };

  return (
    <>
      <div className={s.container}>
        <div className={s.textContainer}>
          <Typography.Text size="14">{t('my_salary_accrued')}</Typography.Text>
          <Typography.Title level={4} className={s.textValue}>
            {convertToRub(sum)}
          </Typography.Title>
        </div>
        <Sunburst {...config} />
      </div>
      <div className={s.badgeContainer}>{childrens.map(renderBadges)}</div>
      {!!selectedSegment && (
        <div className={s.detailedContainer}>
          {selectedSegment.detailed.map((props, ind) =>
            renderDetailedInfo(props, ind, selectedSegment.className),
          )}
        </div>
      )}
    </>
  );
};

export default MySalaryAccrued;
