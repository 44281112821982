import cn from 'classnames';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import { KedoTask, TASK_TYPE_ENUM } from 'types/documents.types';

import { DefaultTypographyTypes, getKedoTagConfig } from '../documents.utils';
import s from './DocumentsRequestsDetails.module.scss';

interface UseGetDescriptionProps {
  task?: KedoTask;
}
const useGetDescription = (props: UseGetDescriptionProps) => {
  const { task } = props;

  let description,
    tagConfig = null;
  let isError = false;
  let isResent = false;
  let resentDescription = null;

  if (task) {
    const { type, currentStatus, currentKedoStatus } = task;
    const isKedo = type === TASK_TYPE_ENUM.KEDO;
    const isBpmn = type === TASK_TYPE_ENUM.BPMN;
    const isSocial = type === TASK_TYPE_ENUM.SOCIAL;

    if (isKedo) {
      const findHistoryElement = (h: { status: string }) => h.status === currentStatus;
      const statusDescription = task.history.find(findHistoryElement)?.description;

      if (['KEDO_CORRECTION_1C', 'KEDO_DECLINED_1C'].includes(currentStatus)) {
        isError = true;
      }

      tagConfig = getKedoTagConfig(currentKedoStatus);
      const type = DEFAULT_COLORS.includes(tagConfig.type)
        ? undefined
        : (tagConfig.type as DefaultTypographyTypes);

      description = (
        <Typography.Text size="14" type={type} className={s.description}>
          {statusDescription}
        </Typography.Text>
      );
    } else {
      const { status, description: statusDescription } = currentKedoStatus;

      if (isSocial && status === 'NEED_CORRECTION') {
        isError = true;
      }
      if (isBpmn && status === 'BPMN_CORRECTION_1C') {
        isError = true;
      }

      if (isSocial && status === 'SOCIAL_RESEND') {
        isResent = true;
        resentDescription = (
          <Typography.Text size="14">
            {t('documents_social_resent_status_description')}
          </Typography.Text>
        );
      }
      if (isBpmn && status === 'BPMN_RESEND') {
        isResent = true;
        resentDescription = (
          <Typography.Text size="14">
            {t('documents_statement_resent_status_description')}
          </Typography.Text>
        );
      }

      tagConfig = getKedoTagConfig(currentKedoStatus);
      const type = DEFAULT_COLORS.includes(tagConfig.type)
        ? undefined
        : (tagConfig.type as DefaultTypographyTypes);

      description = (
        <Typography.Text size="14" type={type} className={cn(s.description, s[tagConfig.type])}>
          {statusDescription}
        </Typography.Text>
      );
    }
  }

  return { description, tagConfig, isError, isResent, resentDescription };
};

const DEFAULT_COLORS: string[] = ['outline', 'primary'];

export default useGetDescription;
