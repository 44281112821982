import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetSocialProgramByIdPropsType,
  GetSocialProgramByIdResponseType,
  GetSocialProgramsPropsType,
  GetSocialProgramsResponseType,
} from './socialProgramApiService.types';

import { socialProgramUrl } from './utils';

const socialProgramApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSocialPrograms: builder.query<GetSocialProgramsResponseType, GetSocialProgramsPropsType>({
      query: (params) => ({
        url: socialProgramUrl(prepareQueryParams(params)),
      }),
    }),
    getSocialProgramById: builder.query<
      GetSocialProgramByIdResponseType,
      GetSocialProgramByIdPropsType
    >({
      query: (id) => ({
        url: socialProgramUrl(`/full/${id}`),
      }),
    }),
  }),
});

export const { useGetSocialProgramsQuery, useGetSocialProgramByIdQuery } = socialProgramApiService;
