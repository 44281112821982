export enum SALARY_FILED_NAMES_ENUM {
  DEFAULT = 'default',
  ACCRUALS = 'accruals',
  DEDUCTIONS = 'deductions',
  PAYOUTS = 'payouts',
  TARIFF = 'Тариф/Оклад',
  AWARDS = 'Премии',
  ADDITIONAL_PAYMENTS = 'Доплаты и надбавки',
  OTHER_ACCRUALS = 'Прочие начисления',
  UNWORKED_TIME = 'Неотработанное время',
  SOCIAL_PAYMENTS = 'Социальные выплаты',
  NDFL = 'НДФЛ',
  OTHER_DEDUCTIONS = 'Прочие удержания',
  RESERVE = 'reserve',
}

export type DateTypeYear = {
  year: number;
  max: {
    year: number;
  };
  min: {
    year: number;
  };
};

export type DateTypeMonth = {
  year: number;
  month: number;
  max: {
    year: number;
    month: number;
  };
  min: {
    year: number;
    month: number;
  };
};

export type PayslipDataType = {
  id: string;
  components: ComponentsType[];
  employeeId: string;
  isFirstHalf: boolean;
  month: number;
  year: number;
  payslipFile?: {
    fileKey: string;
    fileName: string;
    size: number;
  };
};

export type ComponentsType = {
  amount: number;
  component: ComponentType;
};

export type ComponentType = {
  category: string;
  id: string;
  name: string;
  priority: number;
  section: string;
};

export type SalaryYearDataType = {
  monthKey: number;
  month: string;
  value: number;
  type: string;
  sumValue?: number;
};

export type AccruedChildrens = { name: string; value: number; className: string };

export type BadgeProps = {
  name: string;
  text: string;
  value: number;
};

export type BadgeWithWithClassName = Omit<BadgeProps, 'text'> & { className: string };

export type AccruedDataType = { name: string; value: number; className: string; detailed: any[] };
