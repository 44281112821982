import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import useIsSignEnabled from 'utils/useIsSignEnabled.hook';

import initialApiService from 'services/initialApiService';
import { useAppDispatch } from 'store';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import CardsSlider from 'components/cards-slider';
import { CardsSliderType } from 'components/cards-slider/cardsSlider.utils';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import Documents1cErrorModal from 'pages/documents/documents-1c-error-modal';
import DocumentsSmsConfirm from 'pages/documents/documents-sms-confirm/documentsSmsConfirm';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { PRE_USER_ROLE, UserProfileType } from 'types/roles.types';

import s from './Documents.module.scss';
import Certificate from './containers/certificate';
import MyAssignments from './containers/my-assignments';
import { MyAssignmentsRef } from './containers/my-assignments/myAssignments';
import DocumentsNavigateGosuslugiModal from './documents-navigate-gosuslugi-modal';
import { INNER_LINKS } from './documents.utils';

type ModalType = '' | 'gosuslugi' | '1cError';

const Documents = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const userProfile = user?.profile as UserProfileType;
  const isPreUser = !!userProfile?.roles?.includes(PRE_USER_ROLE);

  const dispatch = useAppDispatch();

  const modalRef = useRef<boolean>(true);
  const modalSmsRef = useRef<boolean>(true);

  const [openedModalType, setOpenedModalType] = useState<ModalType>('');
  const [smsModalOperationId, setSmsModalOperationId] = useState<string>('');

  const refreshAssignmentsRef = useRef<MyAssignmentsRef>(null);

  const handleModalOpenClose = (type?: ModalType | MouseEvent<HTMLButtonElement>) => {
    if (typeof type === 'string') {
      setOpenedModalType(type ?? '');
    } else {
      setOpenedModalType('');
    }
  };

  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  useEffect(() => {
    if (location?.state?.certificateState && modalRef.current) {
      setOpenedModalType(location?.state?.certificateState);
      modalRef.current = false;
      navigate(location.pathname);
    }
  }, [location.pathname, location?.state?.certificateState, navigate]);

  useEffect(() => {
    if (location?.state?.operationId && modalSmsRef.current) {
      setSmsModalOperationId(location?.state?.operationId);
      modalRef.current = false;
      navigate(location.pathname);
    }
  }, [location.pathname, location?.state?.operationId, navigate]);

  const signEnabled = useIsSignEnabled();

  const handlePageRefresh = () => {
    refreshAssignmentsRef?.current?.handleRefresh();
    dispatch(initialApiService.util.invalidateTags([SERVICE_TAGS.GET_CERTIFICATES]));
  };

  const filterCards = (card: CardsSliderType & { isSignRequired: boolean }) => {
    const { isSignRequired } = card;
    if (isSignRequired) {
      return isSignRequired === signEnabled;
    }

    return true;
  };

  const cardsToShow = INNER_LINKS.filter(filterCards);

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithPullToRefresh onRefresh={handlePageRefresh}>
            <WithBackground className={s.wrapper}>
              <Certificate setSmsModalOperationId={setSmsModalOperationId} />
              {!isPreUser ? (
                <CardsSlider cards={cardsToShow} />
              ) : (
                <Card className={s.unavailable}>
                  <Flex gap="8">
                    <Icon name="info" color="primary6" size={20} />
                    <Typography.Text size="14">
                      {t('documents_unavailable_sections_description')}
                    </Typography.Text>
                  </Flex>
                </Card>
              )}
              {!signEnabled && (
                <Card className={s.unavailable}>
                  <Flex gap="8">
                    <Icon name="info" color="primary6" size={20} />
                    <Typography.Text size="14">
                      {t('documents_certificate_unavailable_tooltip')}
                    </Typography.Text>
                  </Flex>
                </Card>
              )}
              {signEnabled && <MyAssignments ref={refreshAssignmentsRef} />}
            </WithBackground>
          </WithPullToRefresh>
        </WithNavigation>
      </WithStatusBar>
      <DocumentsNavigateGosuslugiModal
        handleClose={handleModalOpenClose}
        isOpen={openedModalType === 'gosuslugi'}
      />
      <Documents1cErrorModal
        handleClose={handleModalOpenClose}
        isOpen={openedModalType === '1cError'}
      />
      {smsModalOperationId && (
        <DocumentsSmsConfirm
          operationId={smsModalOperationId}
          handleModalClose={() => setSmsModalOperationId('')}
        />
      )}
    </>
  );
};

export default Documents;
