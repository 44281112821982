import Guide from 'pages/guide';
import ActivateSettlementById from 'pages/guide/containers/activate-settlement-by-id';
import GuideImportantContacts from 'pages/guide/guide-important-contacts';
import GuideManagement from 'pages/guide/guide-management';
import GuideMap from 'pages/guide/guide-map';
import GuideMemo from 'pages/guide/guide-memo';
import GuideMyCamp from 'pages/guide/guide-my-camp';
import GuideMySettlements from 'pages/guide/guide-my-settlements';
import GuideQR from 'pages/guide/guide-qr';
import GuideSelectSettlement from 'pages/guide/guide-select-settlement';
import GuideSettlementPosterDetails from 'pages/guide/guide-settlement/guide-settlement-poster-details';
import GuideSocialPolicy from 'pages/guide/guide-social-policy';
import GuideSocialPolicyApply from 'pages/guide/guide-social-policy/guide-social-policy-apply';
import GuideSocialPolicyCategory from 'pages/guide/guide-social-policy/guide-social-policy-category';
import GuideSocialPolicyCategoryProgram from 'pages/guide/guide-social-policy/guide-social-policy-category/guide-social-policy-category-program';

import { RouteConfig } from '../routes';
import {
  GUIDE_ACTIVATE_SETTLEMENT_BY_ID,
  GUIDE_BY_ID_ROUTE,
  GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE,
  GUIDE_IMPORTANT_CONTACTS_ROUTE,
  GUIDE_MANAGEMENT_ROUTE,
  GUIDE_MAP_ROUTE,
  GUIDE_MEMO_ROUTE,
  GUIDE_MY_SETTLEMENTS_ROUTE,
  GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE,
  GUIDE_QR_CODE_ROUTE,
  GUIDE_ROUTE,
  GUIDE_SELECT_SETTLEMENT_ROUTE,
  GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
  GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
  GUIDE_SOCIAL_POLICY_ROUTE,
} from './list';

export const guideRoutes: RouteConfig[] = [
  {
    path: GUIDE_ROUTE,
    element: <Guide />,
  },
  {
    path: GUIDE_BY_ID_ROUTE,
    element: <Guide />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_ROUTE,
    element: <GuideSocialPolicy />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_CATEGORY_DETAILS_ROUTE,
    element: <GuideSocialPolicyCategory />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_DETAILS_ROUTE,
    element: <GuideSocialPolicyCategoryProgram />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_ROUTE,
    element: <GuideSocialPolicyApply />,
  },
  {
    path: GUIDE_SOCIAL_POLICY_PROGRAM_APPLY_FROM_DRAFT_ROUTE,
    element: <GuideSocialPolicyApply />,
  },
  {
    path: GUIDE_MANAGEMENT_ROUTE,
    element: <GuideManagement />,
  },
  {
    path: GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE,
    element: <GuideMyCamp />,
  },
  {
    path: GUIDE_MEMO_ROUTE,
    element: <GuideMemo />,
  },
  {
    path: GUIDE_MAP_ROUTE,
    element: <GuideMap />,
  },
  {
    path: GUIDE_IMPORTANT_CONTACTS_ROUTE,
    element: <GuideImportantContacts />,
  },
  {
    path: GUIDE_MY_SETTLEMENTS_ROUTE,
    element: <GuideMySettlements />,
  },
  {
    path: GUIDE_QR_CODE_ROUTE,
    element: <GuideQR />,
  },
  {
    path: GUIDE_SELECT_SETTLEMENT_ROUTE,
    element: <GuideSelectSettlement />,
  },
  {
    path: GUIDE_GUIDE_SETTLEMENT_POSTER_DETAILS_ROUTE,
    element: <GuideSettlementPosterDetails />,
  },
  {
    path: GUIDE_ACTIVATE_SETTLEMENT_BY_ID,
    element: <ActivateSettlementById />,
  },
];
