import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

export const SEGMENT_MY = 'SEGMENT_MY';
export const SEGMENT_RELATIVES = 'SEGMENT_RELATIVES';

export const SEGMENT_OPTIONS: { value: string; label: ReactNode }[] = [
  {
    label: <Typography.Title level={5}>{t('common_my')}</Typography.Title>,
    value: SEGMENT_MY,
  },
  {
    label: <Typography.Title level={5}>{t('common_relatives')}</Typography.Title>,
    value: SEGMENT_RELATIVES,
  },
];
