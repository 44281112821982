import { FC } from 'react';

import { Sunburst } from '@ant-design/charts';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import {
  BadgeWithWithClassName,
  PayslipDataType,
  SALARY_FILED_NAMES_ENUM,
} from 'types/mySalary.types';

import MySalaryBadge from '../../my-salary-badge';
import { MY_SALARY_COLORS, convertToRub, createSunBurstConfig } from '../../mySalary.utils';
import { colorFunction } from '../mySalarySegmentMonth.utils';
import s from './MySalaryWithheld.module.scss';
import { mapWithheldData } from './mySalaryWithheld.utils';

interface MySalaryWithheldProps {
  data: PayslipDataType;
}

const renderBadge = (props: BadgeWithWithClassName) => (
  <MySalaryBadge
    text={props.name}
    number={props.value}
    className={props.className}
    key={props.className}
  />
);

const MySalaryWithheld: FC<MySalaryWithheldProps> = (props) => {
  const { data } = props;
  const { childrens, sum } = mapWithheldData(data.components);
  const configData = {
    name: 'data',
    children: childrens,
  };

  const config = createSunBurstConfig(
    configData,
    colorFunction(
      MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.DEFAULT].colorHex,
      MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS].colorHex,
    ),
    0.8,
  );

  return (
    <>
      <div className={s.container}>
        <div className={s.textContainer}>
          <Typography.Text size="14">{t('my_salary_held')}</Typography.Text>
          <Typography.Title level={4} className={s.textValue}>
            {convertToRub(sum)}
          </Typography.Title>
        </div>
        <Sunburst {...config} />
      </div>
      <div className={s.badgeContainer}>{childrens.map(renderBadge)}</div>
    </>
  );
};

export default MySalaryWithheld;
