import React from 'react';

import { useParams } from 'react-router-dom';
import { GUIDE_SOCIAL_POLICY_ROUTE, getSocialPolicyProgramWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetKedoCategoryByIdQuery } from 'services/kedo/kedoApiService';
import { useGetSocialProgramsQuery } from 'services/social-program/socialProgramApiService';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SocialProgramType } from 'types/socialProgram.types';

import s from './GuideSocialPolicyCategory.module.scss';

const GuideSocialPolicyCategory = () => {
  const params = useParams<{ socialCategoryId: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: socialProgramCategoryData, isLoading: socialProgramCategoryIsLoading } =
    useGetKedoCategoryByIdQuery(params.socialCategoryId!);
  const { data: socialProgramsData, isLoading: socialProgramsDataIsLoading } =
    useGetSocialProgramsQuery({
      categoryId: params.socialCategoryId!,
      myEmployeeId: selectedEmployee?.id!,
      page: 1,
      size: 100,
    });

  const headerChildren = (
    <>
      <Header.GoBack to={GUIDE_SOCIAL_POLICY_ROUTE} />
      <Header.Label label={t('guide_social_policy_programs_list')} />
    </>
  );

  const renderCategory = (program: SocialProgramType) => {
    return (
      <Card.Navigate
        key={program.id}
        to={getSocialPolicyProgramWithIdRoute(params.socialCategoryId!, program.id)}
        className={s.card}>
        <Typography.Text size="14">{program.nameProgram}</Typography.Text>
        <Icon name="forward" />
      </Card.Navigate>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <WithLoader isLoading={socialProgramCategoryIsLoading}>
              <Card.Default
                title={socialProgramCategoryData?.name ?? ''}
                icon={socialProgramCategoryData?.icon as TIconsLiteral}
                fallBackIcon="social"
              />
            </WithLoader>
          </Card>

          <Card withPadding={false}>
            <Typography.Title level={4} className={s.categoryTitle}>
              {t('guide_social_policy_available_programs')}
            </Typography.Title>
            <WithLoader isLoading={socialProgramsDataIsLoading}>
              {socialProgramsData?.items.map(renderCategory)}
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};
export default GuideSocialPolicyCategory;
