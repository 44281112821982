import RegularArrangement from 'pages/regular-arrangement';

import { RouteConfig } from '../routes';
import { REGULAR_ARRANGEMENT_ROUTE } from './list';

export const regularArrangementRoutes: RouteConfig[] = [
  {
    path: REGULAR_ARRANGEMENT_ROUTE,
    element: <RegularArrangement />,
  },
];
