import React, { FC } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATIONS_ROUTE } from 'routes/notifications/list';

import { Icon } from 'gazprom-ui-lib';

import s from './HeaderNotification.module.scss';

interface HeaderNotificationProps {
  className?: string;
}

const HeaderNotification: FC<HeaderNotificationProps> = (props) => {
  const { className } = props;
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(NOTIFICATIONS_ROUTE)} className={s.container}>
      <Icon name="notification" className={cn(s.wrapper, className)} />
    </div>
  );
};

export default HeaderNotification;
