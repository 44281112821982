import { AccruedDataType, ComponentsType, SALARY_FILED_NAMES_ENUM } from 'types/mySalary.types';

import { MY_SALARY_COLORS } from '../../mySalary.utils';

export const mapAccruedData = (components: ComponentsType[]) => {
  const filteredData = components.filter(
    (component) => component.component.section === SALARY_FILED_NAMES_ENUM.ACCRUALS,
  );
  const { childrens, sum } = filteredData.reduce(
    (acc, current) => {
      if (!current.component.category) {
        const res = acc.childrens.find(
          (item) => item.name === SALARY_FILED_NAMES_ENUM.OTHER_ACCRUALS,
        );
        if (res) {
          res.value += current.amount;
          res.detailed.push(current);
        } else {
          acc.childrens.push({
            name: SALARY_FILED_NAMES_ENUM.OTHER_ACCRUALS,
            value: current.amount,
            className: MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.OTHER_ACCRUALS].codeName,
            detailed: [current],
          });
        }
        acc.sum += current.amount;
        return acc;
      }

      const existingCategory = acc.childrens.find(
        (item) => item.name === current.component.category,
      );

      if (existingCategory) {
        existingCategory.value += current.amount;
        existingCategory.detailed.push(current);
      } else {
        const colorClassName =
          MY_SALARY_COLORS[current.component.category as keyof typeof MY_SALARY_COLORS]?.codeName ??
          MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.RESERVE].codeName;

        acc.childrens.push({
          name: current.component.category,
          value: current.amount,
          className: colorClassName,
          detailed: [current],
        });
      }

      acc.sum += current.amount;
      return acc;
    },
    {
      sum: 0,
      childrens: [] as AccruedDataType[],
    },
  );

  return { childrens, sum };
};
