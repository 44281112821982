import React, { FC } from 'react';

import { EducationType } from 'types/employee.types';

import PersonalDataDetailsItem from '../personal-data-details-item';
import s from './ProfilePersonalDataDetailsEducation.module.scss';
import { getEducationItems } from './profilePersonalDataDetailsEducation.utils';

type ProfilePersonalDataDetailsEducationProps = {
  educationData: EducationType[];
};

const ProfilePersonalDataDetailsEducation: FC<ProfilePersonalDataDetailsEducationProps> = (
  props,
) => {
  const { educationData } = props;

  const renderEducationCard = (educationData: EducationType) => (
    <div className={s.educationCard}>
      {getEducationItems(educationData).map((item) => (
        <PersonalDataDetailsItem key={item.title} {...item} />
      ))}
    </div>
  );

  return <>{educationData.map(renderEducationCard)}</>;
};

export default ProfilePersonalDataDetailsEducation;
