import React, { FC } from 'react';

import cn from 'classnames';
import { Link, LinkProps, useNavigate } from 'react-router-dom';

import { Icon } from 'gazprom-ui-lib';

import s from './HeaderGoBack.module.scss';

interface HeaderGoBackProps {
  to: string;
  className?: string;
  state?: LinkProps['state'];
  useDefaultGoBack?: boolean;
}

const HeaderGoBack: FC<HeaderGoBackProps> = (props) => {
  const { useDefaultGoBack = false, to, className, state } = props;
  const navigate = useNavigate();
  const handleClick = (e: { preventDefault: () => void }) => {
    if (useDefaultGoBack) return;

    e.preventDefault();
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(to, { state });
    }
  };

  return (
    <Link onClick={handleClick} to={to} state={state} className={cn(s.wrapper, className)}>
      <Icon name="arrowLeft" />
    </Link>
  );
};

export default HeaderGoBack;
