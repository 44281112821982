export type CertificateRemarkType = { code: string; target: string; message: string };

export enum CERTIFICATE_STATE_UNUM {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export type CurrentCertificateType = {
  latestKcr: {
    id: string;
    createdAt: string;
    updatedAt: string;
    personId: string;
    issueId: string;
    state: CERTIFICATE_STATE_UNUM;
    validFrom: string;
    validTill: string;
    serialNumber: string;
    cert: string;
    certDetails: {
      subject: string;
      issuer: string;
      keyUsage: boolean[];
      extendedKeyUsage: string[];
    };
  };
  latestIssue: {
    id: string;
    createdAt: string;
    updatedAt: string;
    statusIssueKontur:
      | 'DECLINED'
      | 'REGISTERED'
      | 'DOCS_UPLOADED'
      | 'SCANCOPY_UPLOADED'
      | 'RELEASED_STATEMENT'
      | 'VALIDATE_SENT'
      | 'EPGU_SENT'
      | 'EPGU_APPROVED'
      | 'CORRECTION_REQUESTED'
      | 'CONFIRMATION_REQUESTED'
      | 'RELEASED';
    fileKey: string;
    personId: string;
    confirmationType?: 'SMS' | 'HZ_CHTO';
  };
  correctionInfo: {
    comment: string;
    remarks: CertificateRemarkType[];
  };
};

export type CertificateType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  personId: string;
  issueId: string;
  state: string;
  validFrom: string;
  validTill: string;
  serialNumber: string;
  cert: string;
  certDetails: {
    subject: 'string';
    issuer: 'string';
    keyUsage: [true];
    extendedKeyUsage: ['string'];
  };
};
