import { useState } from 'react';

import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import {
  Directory,
  DownloadFileOptions,
  DownloadFileResult,
  Filesystem,
} from '@capacitor/filesystem';
import mime from 'mime';

import { userManager } from './keycloak';

const useOpenFileByUrl = () => {
  const [loadingKey, setLoadingKey] = useState<string>('');

  const handleOpenFile = async (handleOpenParams: {
    fileKey?: string;
    url: string;
    withAuth?: boolean;
    myEmployeeId?: string;
    defaultFileName?: string;
  }) => {
    const { fileKey = '', url, withAuth, myEmployeeId, defaultFileName } = handleOpenParams;

    setLoadingKey(fileKey);
    let fileName = url.substring(url.lastIndexOf('/') + 1);

    const progress = await Filesystem.addListener('progress', (progress) => {
      console.log('progress', Math.round((progress.bytes / progress.contentLength) * 100));
    });

    const headers: { Authorization?: string; 'X-My-Employee-Id'?: string } = {};

    if (withAuth) {
      const user = await userManager.getUser();
      const token = user?.access_token as string;

      headers['Authorization'] = `Bearer ${token}`;
    }

    if (myEmployeeId) {
      headers['X-My-Employee-Id'] = myEmployeeId;
    }

    if (!defaultFileName && !fileName.includes('.')) {
      const fileMeta = await fetch(url.replace('download', 'meta'), {
        headers,
      }).then((r) => r.json());
      fileName = fileMeta.fileName;
    }

    const options: DownloadFileOptions = {
      url: url,
      path: fileName,
      progress: true,
      directory: Directory.Data,
      responseType: 'blob',
      headers,
    };

    try {
      if (Capacitor.getPlatform() === 'web') {
        return fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: options.headers,
        })
          .then((res) => res.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = defaultFileName || fileName;
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          });
      } else {
        const response: DownloadFileResult = await Filesystem.downloadFile(options);
        const path = response.path;
        const mimeType = mime.getType(defaultFileName || fileName);

        if (mimeType)
          FileOpener.open({
            filePath: path!,
            contentType: mimeType,
          })
            .then(() => console.log('File is opened'))
            .catch((e) => console.log('Error opening file', e))
            .finally(() => {
              progress.remove();
              console.log('clear download');
            });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingKey('');
    }
  };

  return {
    loadingKey,
    handleOpenFile,
  };
};

export default useOpenFileByUrl;
