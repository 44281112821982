import { FC, ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLeaveCampMutation } from 'services/me/meApiService';

import { Icon, TIconsLiteral, Typography, handleRequest } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './GuideSelect.module.scss';

interface GuideSelectProps {
  icon: TIconsLiteral;
  title: string;
  subtitle: string;
  change?: boolean;
  buttons?: ReactNode;
}

const GuideSelect: FC<GuideSelectProps> = (props) => {
  const { icon, subtitle, title, change, buttons } = props;
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [leaveCamp, { isLoading: leaveIsLoading }] = useLeaveCampMutation();

  const handleLeaveCamp = () => {
    const onSuccess = () => navigate(GUIDE_ROUTE);

    if (selectedEmployee)
      leaveCamp(selectedEmployee.id).then(
        handleRequest({
          onSuccess,
        }),
      );
  };

  return (
    <Card withPadding className={s.container}>
      <div className={s.topContainer}>
        <div className={s.textContainer}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Typography.Text type="secondary" size="14" weight="400">
            {subtitle}
          </Typography.Text>
        </div>
        <div className={s.icon}>
          <Icon name={icon} size={30} />
        </div>
      </div>
      <div className={s.buttonsContainer}>
        {buttons}
        {change && (
          <div className={s.leave}>
            <Typography.Text type="secondary" size="14" weight="500">
              {t('guide_my_camp_no_in_camp')}
            </Typography.Text>
            <button type="button" onClick={handleLeaveCamp} disabled={leaveIsLoading}>
              <Typography.Text type="primary" size="14" weight="500">
                {t('guide_my_camp_leave')}
              </Typography.Text>
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default GuideSelect;
