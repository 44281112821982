import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';

import { useSignKedoDocumentAssignmentMutation } from 'services/v2/me/task-assignment/kedo-document/kedoDocumentApiService';
import { useSignKedoReviewTaskAssignmentMutation } from 'services/v2/me/task-assignment/kedo-review-task/kedoReviewTaskApiService';
import { useSignKedoTaskAssignmentMutation } from 'services/v2/me/task-assignment/kedo-task/kedoTaskApiService';
import { useSignWorkTourAssignmentMutation } from 'services/v2/me/task-assignment/work-tour/workTourApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { getFileConfig, getFilesKeys } from '../documents.utils';
import { ASSIGNMENT_SIGN_TYPE } from 'constants/assignments';

interface UseHandleSignProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

const useHandleSign = (props: UseHandleSignProps) => {
  const { setIsModalVisible } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [signKedoReviewTask, { isLoading: signKedoReviewTaskIsLoading }] =
    useSignKedoReviewTaskAssignmentMutation();
  const [signKedoDocument, { isLoading: signKedoDocumentIsLoading }] =
    useSignKedoDocumentAssignmentMutation();
  const [signKedoTask, { isLoading: signKedoTaskIsLoading }] = useSignKedoTaskAssignmentMutation();
  const [signWorkTour, { isLoading: signWorkTourIsLoading }] = useSignWorkTourAssignmentMutation();

  const onError = () => {
    setIsModalVisible(true);
  };

  const onSuccess = (data?: Record<string, unknown>) => {
    if (data) {
      const state = { ...data, ...(location.state || {}) };

      navigate(DOCUMENTS_SIGN_CODE_ROUTE, {
        state,
      });
    }
  };

  const handleSign = () => {
    if (location.state) {
      const { assignment, type } = location.state;
      const { files } = getFileConfig(assignment);
      const fileKeys = getFilesKeys(files ?? [])


      switch (type) {
        case ASSIGNMENT_SIGN_TYPE.V2_KEDO_REVIEW_TYPE_SIGN: {
          signKedoReviewTask({
            assignmentId: assignment.id,
            reviewTaskId: assignment.kedoReviewTask.id,
            fileKeys,
          }).then(
            handleRequest({
              onSuccess,
              onError,
            }),
          );
          break;
        }
        case ASSIGNMENT_SIGN_TYPE.V2_KEDO_DOCUMENT_TYPE_SIGN: {
          signKedoDocument({
            assignmentId: assignment.id,
            documentId: assignment.kedoDocument?.id,
            fileKeys,
          }).then(
            handleRequest({
              onSuccess,
              onError,
            }),
          );
          break;
        }
        case ASSIGNMENT_SIGN_TYPE.V2_KEDO_TASK_TYPE_SIGN: {
          signKedoTask({
            assignmentId: assignment.id,
            taskId: assignment.kedoTask?.id,
            fileKeys,
          }).then(
            handleRequest({
              onSuccess,
              onError,
            }),
          );
          break;
        }
        case ASSIGNMENT_SIGN_TYPE.V2_WORK_TOUR_TYPE_SIGN: {
          signWorkTour({
            assignmentId: assignment.id,
            workTourId: assignment.workTour.id,
            fileKeys,
          }).then(
            handleRequest({
              onSuccess,
              onError,
            }),
          );
          break;
        }
        default:
          break;
      }
    }
  };

  const isLoading =
    signKedoReviewTaskIsLoading ||
    signKedoDocumentIsLoading ||
    signKedoTaskIsLoading ||
    signWorkTourIsLoading;

  return {
    handleSign,
    isLoading,
  };
};

export default useHandleSign;
