import React from 'react';

import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { HOME_NEWS_ROUTE, HOME_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';

import Header from 'containers/header';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './HomeNews.module.scss';
import HomeNewsDetails from './home-news-details';
import HomeNewsList from './home-news-list';

const HomeNews = () => {
  const location = useLocation();
  const params = useParams<{ newsCode: string }>();

  const isNewsOrEventDetails = !!params.newsCode;

  const getGoBackRoute = () => {
    if (location?.state?.cameFrom) {
      return location.state.cameFrom;
    }

    return isNewsOrEventDetails ? HOME_NEWS_ROUTE : HOME_ROUTE;
  };

  const getHeaderLabel = () => {
    if (isNewsOrEventDetails) {
      return location?.state?.type === 'meet' ? 'home_event_view' : 'home_news_view';
    }

    return 'common_newsline';
  };

  const headerChildren = (
    <>
      <Header.GoBack to={getGoBackRoute()} useDefaultGoBack state={location?.state} />
      <Header.Label label={t(getHeaderLabel())} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation
        headerChildren={headerChildren}
        contentClassName={cn(isNewsOrEventDetails && s.detailsVisible)}>
        <HomeNewsDetails />
        <HomeNewsList />
      </WithNavigation>
    </WithStatusBar>
  );
};

export default HomeNews;
