import React, { FC, memo, useEffect } from 'react';

import cn from 'classnames';

import { activeKedoFormSelector } from 'selectors/kedoSlice.selector';
import { useAppSelector } from 'store';

import { Form, FormInstance, FormProps } from 'gazprom-ui-lib';

import s from './Attribute.module.scss';

export interface AttributeProps extends FormProps {
  onSaveDraft?: (form: FormInstance) => void;
}

const Attribute: FC<AttributeProps> = (props) => {
  const { children, form: formFromProps, className, onSaveDraft, ...otherProps } = props;

  const activeKedoForm = useAppSelector(activeKedoFormSelector);

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useEffect(() => {
    if (form && activeKedoForm) {
      form.setFieldsValue(activeKedoForm);
    }
  }, [form, activeKedoForm]);

  const handleBlur = () => {
    if (onSaveDraft) {
      onSaveDraft(formToUse);
    }
  };

  return (
    <Form onBlur={handleBlur} form={formToUse} className={cn(s.wrapper, className)} {...otherProps}>
      {children}
    </Form>
  );
};

export default memo(Attribute);
