import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateSuggestionPropsType,
  CreateSuggestionResponseType,
  GetSuggestionByIdPropsType,
  GetSuggestionByIdResponseType,
  GetSuggestionCategoriesPropsType,
  GetSuggestionCategoriesResponseType,
  GetSuggestionDecisionByIdPropsType,
  GetSuggestionDecisionByIdResponseType,
  GetSuggestionDecisionPerformersByIdPropsType,
  GetSuggestionDecisionPerformersByIdResponseType,
  GetSuggestionDecisionStatusesPropsType,
  GetSuggestionDecisionStatusesResponseType,
  GetSuggestionDecisionsPropsType,
  GetSuggestionDecisionsResponseType,
  GetSuggestionStatusesPropsType,
  GetSuggestionStatusesResponseType,
  GetSuggestionsPropsType,
  GetSuggestionsResponseType,
  HeadApproveSuggestionDecisionByIdPropsType,
  HeadApproveSuggestionDecisionByIdResponseType,
  HeadRejectSuggestionDecisionByIdPropsType,
  HeadRejectSuggestionDecisionByIdResponseType,
  HeadRevisionSuggestionDecisionByIdPropsType,
  HeadRevisionSuggestionDecisionByIdResponseType,
  RedirectSuggestionDecisionByIdPropsType,
  RedirectSuggestionDecisionByIdResponseType,
  SubmitResultSuggestionDecisionByIdPropsType,
  SubmitResultSuggestionDecisionByIdResponseType,
  TakeForReleaseSuggestionDecisionByIdPropsType,
  TakeForReleaseSuggestionDecisionByIdResponseType,
  UpdateSuggestionByIdPropsType,
  UpdateSuggestionByIdResponseType,
} from './suggestionApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { suggestionUrl } from './utils';

const suggestionApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestionById: builder.query<GetSuggestionByIdResponseType, GetSuggestionByIdPropsType>({
      query: ({ id, ...params }) => ({
        url: suggestionUrl(`/${id}${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SUGGESTION_BY_ID],
    }),
    updateSuggestionById: builder.mutation<
      UpdateSuggestionByIdResponseType,
      UpdateSuggestionByIdPropsType
    >({
      query: ({ id, body, ...params }) => ({
        url: suggestionUrl(`/${id}${prepareQueryParams(params)}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_BY_ID, id },
      ],
    }),
    getSuggestions: builder.query<GetSuggestionsResponseType, GetSuggestionsPropsType>({
      query: (params) => ({
        url: suggestionUrl(prepareQueryParams(params)),
      }),
      providesTags: [SERVICE_TAGS.GET_SUGGESTIONS],
    }),
    createSuggestion: builder.mutation<CreateSuggestionResponseType, CreateSuggestionPropsType>({
      query: (body) => ({
        url: suggestionUrl(''),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_SUGGESTIONS],
    }),
    getSuggestionStatuses: builder.query<
      GetSuggestionStatusesResponseType,
      GetSuggestionStatusesPropsType
    >({
      query: (params) => ({
        url: suggestionUrl(`-status${prepareQueryParams(params)}`),
      }),
    }),
    getSuggestionDecisionStatuses: builder.query<
      GetSuggestionDecisionStatusesResponseType,
      GetSuggestionDecisionStatusesPropsType
    >({
      query: () => ({
        url: 'suggestion-decisions-status',
      }),
    }),
    getSuggestionCategories: builder.query<
      GetSuggestionCategoriesResponseType,
      GetSuggestionCategoriesPropsType
    >({
      query: () => ({
        url: suggestionUrl('-category'),
      }),
    }),
    getSuggestionDecisions: builder.query<
      GetSuggestionDecisionsResponseType,
      GetSuggestionDecisionsPropsType
    >({
      query: (params) => ({
        url: suggestionUrl(`-decisions${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_SUGGESTION_DECISIONS],
    }),
    getSuggestionDecisionById: builder.query<
      GetSuggestionDecisionByIdResponseType,
      GetSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...params }) => ({
        url: suggestionUrl(`-decisions/${id}${prepareQueryParams(params)}`),
      }),
      providesTags: (_a, _b, { id }) => [{ type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id }],
    }),
    getSuggestionDecisionPerformersById: builder.query<
      GetSuggestionDecisionPerformersByIdResponseType,
      GetSuggestionDecisionPerformersByIdPropsType
    >({
      query: ({ id, ...params }) => ({
        url: suggestionUrl(`/${id}/performers${prepareQueryParams(params)}`),
      }),
    }),
    headApproveSuggestionDecisionById: builder.mutation<
      HeadApproveSuggestionDecisionByIdResponseType,
      HeadApproveSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/head-approve`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
    headRevisionSuggestionDecisionById: builder.mutation<
      HeadRevisionSuggestionDecisionByIdResponseType,
      HeadRevisionSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/head-revision`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
    headRejectSuggestionDecisionById: builder.mutation<
      HeadRejectSuggestionDecisionByIdResponseType,
      HeadRejectSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/head_reject`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
    redirectSuggestionDecisionById: builder.mutation<
      RedirectSuggestionDecisionByIdResponseType,
      RedirectSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/redirect`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
    takeForReleaseSuggestionDecisionById: builder.mutation<
      TakeForReleaseSuggestionDecisionByIdResponseType,
      TakeForReleaseSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/take-for-release`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
    submitResultSuggestionDecisionById: builder.mutation<
      SubmitResultSuggestionDecisionByIdResponseType,
      SubmitResultSuggestionDecisionByIdPropsType
    >({
      query: ({ id, ...body }) => ({
        url: suggestionUrl(`-decisions/${id}/result`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISIONS },
        { type: SERVICE_TAGS.GET_SUGGESTION_DECISION_BY_ID, id },
      ],
    }),
  }),
});

export const {
  useCreateSuggestionMutation,
  useLazyGetSuggestionsQuery,
  useLazyGetSuggestionDecisionsQuery,
  useGetSuggestionStatusesQuery,
  useGetSuggestionDecisionStatusesQuery,
  useGetSuggestionCategoriesQuery,
  useLazyGetSuggestionByIdQuery,
  useUpdateSuggestionByIdMutation,
  useGetSuggestionDecisionByIdQuery,
  useLazyGetSuggestionDecisionPerformersByIdQuery,
  useHeadApproveSuggestionDecisionByIdMutation,
  useHeadRevisionSuggestionDecisionByIdMutation,
  useHeadRejectSuggestionDecisionByIdMutation,
  useRedirectSuggestionDecisionByIdMutation,
  useTakeForReleaseSuggestionDecisionByIdMutation,
  useSubmitResultSuggestionDecisionByIdMutation,
} = suggestionApiService;
