import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './GuideSelectButton.module.scss';

interface GuideSelectButtonProps {
  id: string;
  name: string;
  isCurrent?: boolean;
  onClick?: (id: string) => void;
}

const GuideSelectButton: FC<GuideSelectButtonProps> = (props) => {
  const { id, name, isCurrent = false, onClick } = props;

  return (
    <button
      className={cn(s.container, isCurrent ? s.colored : '')}
      onClick={() => onClick && onClick(id)}>
      <div className={s.text}>
        {isCurrent && (
          <Typography.Text size="12" type="secondary">
            {t('guide_is_current_settlement')}
          </Typography.Text>
        )}
        <Typography.Title level={5}>{name}</Typography.Title>
      </div>
      {isCurrent ? <Icon name="ok" size={20} /> : <Icon name="forward" size={14} />}
    </button>
  );
};

export default GuideSelectButton;
