import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetSettlementByIdQuery } from 'services/guide/guideApiService';
import { getEmployeesSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { Flex } from 'gazprom-ui-lib';

import CardsSlider from 'components/cards-slider';
import WithLoader from 'containers/wrappers/with-loader';

import GuideSettlementPosterList from 'pages/guide/guide-settlement/guide-settlement-poster-list';

import useBuildHero from '../../../utils/useBuildHero';
import PosterListEmpty from '../containers/poster-list-empty/posterListEmpty';
import GuideCall from '../guide-call';
import GuideSelected from '../guide-selected';
import { getBuildHeroCards, getCards } from './guideSettlement.utils';

const GuideSettlement = () => {
  const { isLoading: isGetEmployeesLoading } = useAppSelector(getEmployeesSelector);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { isBuildHeroCurrentSettlement } = useBuildHero();

  const currentSettlementId = selectedEmployee?.currentSettlement.id;

  const { data: settlementData, isLoading: settlementDataLoading } = useGetSettlementByIdQuery({
    id: currentSettlementId!,
  });

  const arePostersAvailable =
    settlementData?.telegramId && typeof settlementData?.telegramId === 'string';
  const isShowMap = Boolean(settlementData?.fileMap?.link);
  const isLoading = isGetEmployeesLoading || settlementDataLoading;

  const sliderCards = isBuildHeroCurrentSettlement ? getBuildHeroCards() : getCards(isShowMap);

  return (
    <WithLoader isLoading={isLoading}>
      <Flex gap="16" vertical>
        <GuideSelected text={selectedEmployee?.currentSettlement.name ?? ''} />
        <CardsSlider cards={sliderCards} />
        <GuideCall />
        {arePostersAvailable ? (
          <GuideSettlementPosterList telegramChannelId={settlementData.telegramId as string} />
        ) : (
          <PosterListEmpty />
        )}
      </Flex>
    </WithLoader>
  );
};

export default GuideSettlement;
