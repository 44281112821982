import { START_YEAR } from 'constants/dates';

export const getYearsValues = (): number[] => {
  let values: number[] = [];
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= START_YEAR; year--) {
    values.push(year);
  }

  return values;
};
