import { Radio } from 'gazprom-ui-lib';

export type SelectOptionType = {
  value: string | number;
  label: string;
};

export const renderOption = (option: SelectOptionType) => {
  const { label, value } = option;

  return (
    <Radio.Button key={value} value={value}>
      {label}
    </Radio.Button>
  );
};
