import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onCorrection: () => void;
  onDecline: () => void;
}

const CancelModal = (props: Props) => {
  const { isOpen, onCancel, onCorrection, onDecline } = props;

  return (
    <ModalDefault open={isOpen} onCancel={onCancel}>
      <ModalDefault.Icon name="deleteFilled" type="danger" />
      <ModalDefault.Title>{t('suggestion_decision_decline_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestion_decision_decline_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button leftIcon="return" type="primary" size="large" fullWidth onClick={onCorrection}>
          {t('common_send_to_modify')}
        </Button>
        <Button danger type="secondary" size="large" fullWidth onClick={onDecline}>
          {t('common_decline')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CancelModal;
