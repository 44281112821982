import React, { useState } from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
  getDocumentsSocialPolicyDetailsDetailsRoute,
  getDocumentsStatementsDetailsRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useCancelRequestMutation,
  useCreateKedoFilesArchiveMutation,
  useSendKedoFilesEmailMutation,
} from 'services/kedo/kedoApiService';
import { CancelRequestPropsType } from 'services/kedo/kedoApiService.types';

import {
  Button,
  Flex,
  Form,
  Icon,
  Input,
  InputProps,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import {
  KEDO_TASK_FILE_TYPES,
  KedoTaskFile,
  KedoTaskFilledAttribute,
  TASK_HISTORY_STATUS_ENUM,
} from 'types/documents.types';

import CancellationCommentModal from '../../containers/cancellation-comment-modal';
import CancellationConfirmModal from '../../containers/cancellation-confirm-modal';
import CancellationModal from '../../containers/cancellation-modal';
import DownloadErrorModal from '../containers/download-error-modal';
import DownloadSuccessModal from '../containers/download-success-modal';
import EmailErrorModal from '../containers/email-error-modal';
import EmailSuccessModal from '../containers/email-success-modal';
import { getFixRequestErrorRoute } from '../documentsRequestsDetails.utils';
import useGetDescription from '../useGetDescription.hook';
import useTasks from '../useTask.hook';
import s from './DocumentsRequestsDetailsAttributes.module.scss';
import {
  ACTIVE_MODAL_TYPE,
  getFilledAttributesWithFiles,
} from './documentsRequestsDetailsAttributes.utils';
import useDownloadKedoArchiveHook from './useDownloadKedoArchive.hook';

const DocumentsRequestsDetailsAttributes = () => {
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [form] = Form.useForm();
  const comment = Form.useWatch('comment', form);

  const isStatementRoute = !!useMatch(DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE);
  const isSocialPolicyRoute = !!useMatch(DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE);
  const isInquiriesRoute = !!useMatch(DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE);

  const { data: task, isFetching: taskIsFetching } = useTasks();

  let headerLabel = 'documents_request_details';
  let resentToNewTitle = 'documents_social_resent_to_new';

  if (isStatementRoute) {
    headerLabel = 'documents_statement_details';
    resentToNewTitle = 'documents_statement_resent_to_new';
  }

  const [cancelRequest, { isLoading }] = useCancelRequestMutation();

  const [activeModal, setActiveModal] = useState<ACTIVE_MODAL_TYPE>('');
  const [cancellationModalOpen, setCancellationModalOpen] = useState<boolean>(false);
  const [cancellationConfirmModalOpen, setCancellationConfirmModalOpen] = useState<boolean>(false);
  const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
  const [emailToSend, setEmailToSend] = useState<string>('');

  const [sendKedoFilesEmail, { isLoading: isSendingFiles }] = useSendKedoFilesEmailMutation();

  const [createKedoFilesArchive, { isLoading: isArchiveCreating }] =
    useCreateKedoFilesArchiveMutation();

  const { downloadKedoFilesArchive, isLoading: isDownloading } = useDownloadKedoArchiveHook();

  const isArchiveDownloading = isArchiveCreating || isDownloading;

  const handleCloseModal = () => {
    setActiveModal('');
  };

  const handleCancellationModalOpenClose = () => {
    setCancellationModalOpen(!cancellationModalOpen);
  };

  const handleCancellationConfirmModalOpenClose = () => {
    setCancellationConfirmModalOpen(!cancellationConfirmModalOpen);
  };

  const handleResetCancellation = () => {
    if (task) {
      setActiveModal('');
      setCancellationModalOpen(false);
      setCancellationConfirmModalOpen(false);
      navigate(getDocumentsStatementsDetailsRoute(task.id));
    }
  };

  const { isError, isResent, description, resentDescription } = useGetDescription({ task });

  const handleFixErrors = () => {
    if (task) {
      const route = getFixRequestErrorRoute(task, isSocialPolicyRoute, isStatementRoute);

      navigate(route, { state: { fromId: task.id } });
    }
  };

  const handleToNewRequest = () => {
    if (task) {
      const { newId } = task || {};

      if (newId) {
        if (isStatementRoute) {
          navigate(getDocumentsStatementsDetailsRoute(newId));
        } else {
          navigate(getDocumentsSocialPolicyDetailsDetailsRoute(newId));
        }
      }
    }
  };

  const handleCancellationCommentOpenClose = () => {
    if (activeModal === 'cancellationComment') {
      setActiveModal('');
    } else {
      setActiveModal('cancellationComment');
    }
  };

  const handleCancellationSubmit = () => {
    const isCancelAvailable = !!comment && !!task && !!selectedEmployee;

    if (isCancelAvailable) {
      const requestBody: CancelRequestPropsType = {
        myEmployeeId: selectedEmployee.id,
        taskId: task.id,
        comment,
      };

      const onSuccess = () => {
        handleCancellationConfirmModalOpenClose();
      };

      cancelRequest(requestBody).then(
        handleRequest({
          onError: onSuccess,
        }),
      );
    }
  };

  const handleEmailChange: InputProps['onChange'] = (e) => {
    setEmailToSend(e.target.value);
  };

  const handleSentToEmail = () => {
    const isSendAvailable =
      showEmailInput && emailToSend.length && selectedEmployee?.id && task?.id;

    const onSuccess = () => {
      setActiveModal('emailSent');
      setEmailToSend('');
      setShowEmailInput(false);
    };

    const onError = () => {
      setActiveModal('emailSentError');
    };

    if (isSendAvailable) {
      sendKedoFilesEmail({ id: task.id, myEmployeeId: selectedEmployee.id, full: true }).then(
        handleRequest({ onSuccess, onError }),
      );
    }

    setShowEmailInput(true);
  };

  const handleDownloadMobile = () => {
    if (task?.id && selectedEmployee?.id) {
      const onSuccess = (res?: { fileKey: string }) => {
        setActiveModal('downloadSuccess');

        if (res?.fileKey) {
          downloadKedoFilesArchive(res.fileKey);
        }
      };

      const onError = () => {
        setActiveModal('downloadError');
      };

      createKedoFilesArchive({ id: task.id, myEmployeeId: selectedEmployee.id, full: true }).then(
        handleRequest({ onSuccess, onError }),
      );
    }
  };

  const renderAttribute = (attribute: KedoTaskFilledAttribute) => {
    const hasFiles = !!attribute?.files?.length;

    return (
      <div key={attribute.attributeId} className={s.attribute}>
        <Typography.Text size="12" type="secondary">
          {attribute.attributeName}
        </Typography.Text>
        {hasFiles ? (
          attribute.files!.map(renderFile(hasFiles))
        ) : (
          <Typography.Title level={5}>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(attribute.userText) }} />
          </Typography.Title>
        )}
      </div>
    );
  };

  const { handleDownload, loadingKey } = useDownloadKedoFile();

  const renderFile = (isAttributeFile?: boolean) => (file: KedoTaskFile) => {
    const { fileKey, type, fileKeyWithStamp } = file;

    if (!task) {
      return null;
    }

    let fileKeyToUse = fileKey;

    const inquiriesFilesLogic =
      isInquiriesRoute &&
      (type === KEDO_TASK_FILE_TYPES.FILE || type === KEDO_TASK_FILE_TYPES.FILE_FOR_SIGN);

    const useStampFile = (inquiriesFilesLogic || isStatementRoute) && fileKeyWithStamp;

    if (useStampFile) {
      fileKeyToUse = fileKeyWithStamp;
    }

    return (
      <KedoFile
        isAttributeFile={isAttributeFile}
        file={file}
        key={fileKeyToUse}
        onClick={handleDownload({ fileKey: fileKeyToUse, taskId: task.id })}
        loading={loadingKey === fileKey}
      />
    );
  };

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t(headerLabel)} />
    </>
  );

  const {
    category,
    statement,
    filledAttributes,
    files,
    isCancellable,
    cancelHistory,
    currentKedoStatus,
  } = task ?? {};

  let attributesToRender = filledAttributes;
  let filesToRender = files;

  if (isStatementRoute) {
    const [filledAttributesWithFiles, unlinedFiles] = getFilledAttributesWithFiles(
      filledAttributes,
      files,
    );

    attributesToRender = filledAttributesWithFiles as KedoTaskFilledAttribute[];
    filesToRender = unlinedFiles as KedoTaskFile[];
  }

  const canFixErrors = !isInquiriesRoute && isError;
  const isTaskCancellable =
    isStatementRoute &&
    currentKedoStatus?.status === TASK_HISTORY_STATUS_ENUM.BPMN_READY &&
    !!isCancellable &&
    !cancelHistory?.length;

  const isDownloadAvailable = isStatementRoute && !!filesToRender?.length;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={!canFixErrors && !isResent}>
        <WithBackground>
          <Card className={s.title}>
            <WithLoader isLoading={taskIsFetching}>
              <Typography.Text size="12" type="secondary">
                {category?.name}
              </Typography.Text>
              <Typography.Title level={4} className={s.title}>
                {statement?.name}
              </Typography.Title>
            </WithLoader>
          </Card>
          <Card withPadding={false}>
            <WithLoader isLoading={taskIsFetching}>
              {!!attributesToRender?.length && (
                <div className={s.attributes}>{attributesToRender?.map(renderAttribute)}</div>
              )}
              {!!filesToRender?.length && (
                <div className={s.files}>{filesToRender?.map(renderFile())}</div>
              )}
              {isTaskCancellable && (
                <Flex className={s.cancelButtonWrapper}>
                  <Button
                    onClick={handleCancellationModalOpenClose}
                    fullWidth
                    size="large"
                    leftIcon="unpublish"
                    type="primary"
                    ghost>
                    {t('documents_cancel_order')}
                  </Button>
                </Flex>
              )}
            </WithLoader>
          </Card>

          {isResent && resentDescription && (
            <>
              <Card className={s.resentDescription}>
                <Icon name="info" />
                {resentDescription}
              </Card>
              <div className={s.fixedCard}>
                <Button
                  onClick={handleToNewRequest}
                  fullWidth
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t(resentToNewTitle)}
                </Button>
              </div>
            </>
          )}

          {canFixErrors && (
            <>
              <Card className={s.description}>
                <Icon name="info" />
                {description}
              </Card>
              <div className={s.fixedCard}>
                <Button
                  onClick={handleFixErrors}
                  fullWidth
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t('common_fix_errors')}
                </Button>
              </div>
            </>
          )}

          {isDownloadAvailable && (
            <Card>
              <Card.Default
                className={s.downloadCard}
                title={t('documents_statements_files_download_title')}
                description={t('documents_statements_files_download_description')}
                icon="downloadableFilled"
              />
              <Flex vertical gap="12">
                {showEmailInput && (
                  <Flex vertical gap="8">
                    <Typography.Title>{t('common_check_email')}</Typography.Title>
                    <Input
                      placeholder={`${t('common_enter_text')}...`}
                      size="large"
                      onChange={handleEmailChange}
                      value={emailToSend}
                      autoFocus
                    />
                  </Flex>
                )}
                <Button
                  loading={isSendingFiles}
                  onClick={handleSentToEmail}
                  fullWidth
                  leftIcon="mail"
                  size="large"
                  type="primary">
                  {t('common_send_email')}
                </Button>
                <Button
                  loading={isArchiveDownloading}
                  onClick={handleDownloadMobile}
                  fullWidth
                  leftIcon="download"
                  size="large"
                  ghost>
                  {t('common_download_mobile')}
                </Button>
              </Flex>
            </Card>
          )}

          <CancellationModal
            isOpen={cancellationModalOpen}
            onClickHandler={handleCancellationCommentOpenClose}
            onCancelHandler={handleCancellationModalOpenClose}
          />

          <CancellationConfirmModal
            isOpen={cancellationConfirmModalOpen}
            onClickHandler={handleResetCancellation}
            onCancelHandler={handleCancellationConfirmModalOpenClose}
          />

          <CancellationCommentModal
            isOpen={activeModal === 'cancellationComment'}
            isLoading={isLoading}
            form={form}
            handleOpenClose={handleCancellationCommentOpenClose}
            onSubmit={handleCancellationSubmit}
          />

          <DownloadSuccessModal
            isOpened={activeModal === 'downloadSuccess'}
            onCancel={handleCloseModal}
          />
          <DownloadErrorModal
            isOpened={activeModal === 'downloadError'}
            onCancel={handleCloseModal}
          />
          <EmailSuccessModal isOpened={activeModal === 'emailSent'} onCancel={handleCloseModal} />
          <EmailErrorModal
            isOpened={activeModal === 'emailSentError'}
            onCancel={handleCloseModal}
          />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsRequestsDetailsAttributes;
