import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import { EducationType } from 'types/topManagement.types';

import Collapse from '../../../collapse';
import DatePicker from '../../../date-picker';
import { EDUCATION_FORM_ENUM } from '../../educationInfoForm.utils';

interface EducationCardProps {
  id: string | number;
  onClick: () => void;
  educationInitialData?: Partial<EducationType>;
}

const EducationCard: FC<EducationCardProps> = (props) => {
  const { id, educationInitialData, onClick } = props;

  const key = `${EDUCATION_FORM_ENUM.EDUCATION_NAME}_${id}`;

  const form = Form.useFormInstance();
  const educationTitleName = Form.useWatch(key, form);

  const {
    documentIssueDate,
    documentNumber,
    documentSeries,
    educationDate,
    educationName,
    qualification,
  } = educationInitialData || {};

  const [displayedName, setDisplayedName] = useState(
    educationName || t('top_management_add_education_name'),
  );

  useEffect(() => {
    const isTouched = form.isFieldTouched(key);

    if (isTouched) {
      setDisplayedName(educationTitleName);
    }
  }, [educationTitleName, form, key]);

  return (
    <Card key={id}>
      <Collapse
        noPadding
        defaultActiveKey={id}
        items={[
          {
            key: id,
            label: <Typography.Title level={5}>{displayedName}</Typography.Title>,
            children: (
              <Flex gap="24" vertical>
                <Form.Item
                  name={`${EDUCATION_FORM_ENUM.IS_ENTERED_BY_USER}_${id}`}
                  hidden
                  initialValue={true}
                />
                <Form.Item
                  name={`${EDUCATION_FORM_ENUM.EDUCATION_NAME}_${id}`}
                  rules={[formRules.required]}
                  initialValue={educationName}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <DatePicker
                  name={`${EDUCATION_FORM_ENUM.EDUCATION_DATE}_${id}`}
                  label={t('top_management_add_education_date')}
                  required
                  yearConfig={{
                    initialMinYear: 1950,
                    initialMaxYear: 2099,
                  }}
                  initialValue={educationDate}
                />
                <Form.Item
                  name={`${EDUCATION_FORM_ENUM.QUALIFICATION}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_add_education_cualification')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={qualification}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${EDUCATION_FORM_ENUM.SERIES}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_add_education_series')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={documentSeries}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${EDUCATION_FORM_ENUM.NUMBER}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_add_education_number')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={documentNumber}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <DatePicker
                  name={`${EDUCATION_FORM_ENUM.ISSUE_DATE}_${id}`}
                  label={t('common_issue_date')}
                  required
                  yearConfig={{
                    initialMinYear: 1950,
                    initialMaxYear: 2099,
                  }}
                  initialValue={documentIssueDate}
                />
                <Button type="secondary" fullWidth size="large" danger onClick={onClick}>
                  {t('common_delete')}
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default EducationCard;
