import { ReactNode, useContext, useState } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';

import ApproveModal from '../approve-modal';
import useSetModalFromLocationState from '../useSetModalFromLocationState.hook';
import { MODAL_TYPE } from '../utils';
import { ACTIONS_AVAILABLE_STATUSES } from './actionsKedoReviewTask.utils';
import useActions from './useActions.hook';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
}

const ActionsKedoReviewTask = (props: Props) => {
  const { handleCertificateCheck } = props;
  const assignment = useContext(AssignmentContext);

  const [modalType, setModalType] = useState<MODAL_TYPE.FAMILIARIZED | ''>('');

  const handleModalClose = () => {
    setModalType('');
  };

  useSetModalFromLocationState({ setModalType });

  const areActionsAvailable =
    assignment?.status?.code === 'WAITING' && ACTIONS_AVAILABLE_STATUSES.includes(assignment?.type);

  const { handleFamiliarize, isLoading } = useActions({
    assignment,
    handleCertificateCheck,
    setModalType,
  });

  let buttons: ReactNode;

  if (assignment && areActionsAvailable) {
    buttons = (
      <Button size="large" leftIcon="ok" fullWidth onClick={handleFamiliarize} loading={isLoading}>
        {t('common_familiarize')}
      </Button>
    );
  }

  return (
    <>
      {buttons}
      <ApproveModal
        isApprove={false}
        isOpen={modalType === MODAL_TYPE.FAMILIARIZED}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default ActionsKedoReviewTask;
