import React, { FC, ReactNode, memo, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';

import { useLazyMyReceivedFeedbacksQuery } from 'services/feedback/feedbackApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import { FeedbackType } from 'types/feedback.types';
import { PaginationPropsType } from 'types/pagination.types';

import s from './ProfileFeedbackReceived.module.scss';

interface FeedbackReceivedProps {
  renderFeedback: (feedback: FeedbackType) => ReactNode;
}

const ProfileFeedbackReceived: FC<FeedbackReceivedProps> = (props) => {
  const { renderFeedback } = props;

  const [pagination, setPagination] = useState<PaginationPropsType>({ page: 1, size: 10 });
  const [feedbacksToShow, setFeedbacksToShow] = useState<FeedbackType[]>([]);

  const [
    getApprovedFeedback,
    {
      data: receivedFeedbacks,
      isFetching: receivedFeedbacksIsFetching,
      isLoading: receivedFeedbacksIsLoading,
      isUninitialized,
    },
  ] = useLazyMyReceivedFeedbacksQuery();

  useEffect(() => {
    getApprovedFeedback({ ...pagination }).then((res) => {
      if (res?.data && 'feedbacks' in res.data) {
        setFeedbacksToShow((prevState) => {
          return prevState.concat(res.data?.feedbacks!);
        });
      }
    });
  }, [getApprovedFeedback, pagination]);

  const showFeedbackContent =
    isUninitialized || feedbacksToShow.length || receivedFeedbacksIsFetching;
  const content = showFeedbackContent ? (
    feedbacksToShow.map(renderFeedback)
  ) : (
    <Card className={s.noDataWrapper}>
      <div>
        <Icon name="notFound" />
      </div>
      <Typography.Title level={4}>{t('feedback_you_have_no_feedbacks')}</Typography.Title>
      <Typography.Title level={5}>
        {t('feedback_you_have_no_feedbacks_description')}
      </Typography.Title>
    </Card>
  );

  const isFetchMoreAvailable =
    pagination.page * pagination.size < (receivedFeedbacks?.totalCount ?? 0);

  return (
    <WithLoader isLoading={receivedFeedbacksIsLoading}>
      <WithInfiniteScroll
        fetchMoreData={fetchMoreData(setPagination)}
        isFetchMoreAvailable={isFetchMoreAvailable}
        isFetching={receivedFeedbacksIsFetching}>
        {content}
      </WithInfiniteScroll>
    </WithLoader>
  );
};

export default memo(ProfileFeedbackReceived);
