import { ComponentsType, SALARY_FILED_NAMES_ENUM } from 'types/mySalary.types';

import { MY_SALARY_COLORS } from '../../mySalary.utils';

export const mapWithheldData = (components: ComponentsType[]) => {
  return components
    .filter((component) => component.component.section === SALARY_FILED_NAMES_ENUM.DEDUCTIONS)
    .reduce(
      (acc, current) => {
        if (!current.component.category) {
          const res = acc.childrens.find(
            (item) => item.name === SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS,
          );
          res
            ? (res.value += current.amount)
            : acc.childrens.push({
                name: SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS,
                value: current.amount,
                className: MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS].codeName,
              });
          acc.sum += current.amount;
          return acc;
        }

        const existingCategory = acc.childrens.find(
          (item) => item.name === current.component.category,
        );

        if (existingCategory) {
          existingCategory.value += current.amount;
        } else {
          const className =
            MY_SALARY_COLORS?.[current.component.category as keyof typeof MY_SALARY_COLORS]
              ?.codeName || MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS].codeName;

          acc.childrens.push({
            name: current.component.category,
            value: current.amount,
            className: className,
          });
        }

        acc.sum += current.amount;
        return acc;
      },
      {
        sum: 0,
        childrens: [] as { name: string; value: number; className: string }[],
      },
    );
};
