import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import WithBackground from 'containers/wrappers/with-background';

import { ReactComponent as LogoBlue } from 'assets/logo/logo-short-name-row-blue.svg';

import s from './WithGzpTopLogo.module.scss';

interface WithGzpTopLogoProps extends PropsWithChildren {
  className?: string;
}

const WithGzpTopLogo: FC<WithGzpTopLogoProps> = (props) => {
  const { children, className } = props;

  return (
    <WithBackground className={cn(s.wrapper, className)}>
      <LogoBlue className={s.logoBlue} />
      {children}
    </WithBackground>
  );
};

export default WithGzpTopLogo;
