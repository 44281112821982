import React from 'react';

import { PROFILE_COMPANY_ROUTE } from 'routes/profile/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import companyPlug from 'assets/icons/companyPlug.png';

import s from './ProfileScheduleEmployee.module.scss';

const ProfileScheduleEmployee = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  return (
    <Card.Navigate to={PROFILE_COMPANY_ROUTE} className={s.wrapper}>
      <div>
        <div>
          <img src={companyPlug} alt="company logo" />
          <div>
            <Typography.Text size="12">{selectedEmployee?.employer.name}</Typography.Text>
            <Typography.Title level={5}>{`${
              selectedEmployee?.person?.firstName
            } ${selectedEmployee?.person?.lastName?.slice(0, 1)}.`}</Typography.Title>
            <Typography.Text size="12" type="secondary">
              {selectedEmployee?.position?.name}
            </Typography.Text>
          </div>
        </div>
        <Icon name="forward" />
      </div>
    </Card.Navigate>
  );
};

export default ProfileScheduleEmployee;
