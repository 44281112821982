import React, { useCallback } from 'react';

import { Style } from '@capacitor/status-bar';
import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';

import { Button, Typography } from 'gazprom-ui-lib';

import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ReactComponent as GosuslugiLogo } from 'assets/icons/gosuslugiLogo.svg';
import { ReactComponent as Line2 } from 'assets/icons/line2.svg';
import { ReactComponent as LogoGsp5 } from 'assets/logo/logo-gsp-5.svg';
import { ReactComponent as LogoSquare } from 'assets/logo/logo-short-name-square-blue.svg';

import s from './LoginGosuslugi.module.scss';

const LoginGosuslugi = () => {
  const { signinRedirect } = useAuth();

  const onLogin = useCallback(() => {
    signinRedirect();
  }, [signinRedirect]);

  return (
    <WithStatusBar style={Style.Dark}>
      <div className={s.wrapper}>
        <div>
          <LogoGsp5 className={s.gsp5} />
          <div className={s.logoWithLine}>
            <Line2 />
            <div>
              <LogoSquare />
            </div>
          </div>
          <Typography.Title level={3}>
            {t('access_log_in_to_app_through_gosuslugi')}
          </Typography.Title>
        </div>
        <div className={s.actionWrapper}>
          <GosuslugiLogo />
          <Button rightIcon="arrowRight" size="large" fullWidth type="primary" onClick={onLogin}>
            {t('access_log_in_with_gosuslugi')}
          </Button>
        </div>
      </div>
    </WithStatusBar>
  );
};

export default LoginGosuslugi;
