import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getSuggestionDecisionRoute } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useSubmitResultSuggestionDecisionByIdMutation } from 'services/me/suggestion/suggestionApiService';

import { Button, handleRequest } from 'gazprom-ui-lib';

import Attribute, {
  AttributeCompoundedComponents,
  getAttributeComponentType,
} from 'containers/attribute';
import { AttributeDefaultProps } from 'containers/attribute/attribute.utils';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './MoreSuggestionDecisionResult.module.scss';
import SentModal from './containers/sent-modal';
import {
  CreateDecisionResultFormResponseType,
  DECISION_RESULT_ATTRIBUTES,
  ModalType,
  prepareFilesRequestBody,
} from './moreSuggestionDecisionResult.utils';

const MoreSuggestionDecisionResult = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [modalType, setModalType] = useState<ModalType>('');

  const headerChildren = (
    <>
      <Header.GoBack to={getSuggestionDecisionRoute(params.id)} />
      <Header.Label label={t('suggestion_decision_result_title')} />
    </>
  );

  const [submitResult, { isLoading: isResultSubmitLoading }] =
    useSubmitResultSuggestionDecisionByIdMutation();

  const onFinish = (values: CreateDecisionResultFormResponseType) => {
    const { comment, files } = values ?? {};

    const onSuccess = () => {
      setModalType('sent');
    };

    const preparedFiles = prepareFilesRequestBody(files);

    if (params?.id) {
      submitResult({ id: params.id, comment, files: preparedFiles }).then(
        handleRequest({ onSuccess }),
      );
    }
  };

  const handleCloseModal = () => {
    setModalType('');
    navigate(getSuggestionDecisionRoute(params.id));
  };

  return (
    <WithStatusBar>
      <WithNavigation showNavbar={false} headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding>
            <Attribute onFinish={onFinish}>
              {DECISION_RESULT_ATTRIBUTES.map((attr) => {
                const type = getAttributeComponentType(
                  attr.type,
                ) as keyof AttributeCompoundedComponents;

                const Component = Attribute[type] as AttributeCompoundedComponents;

                const defaultProps: Partial<AttributeDefaultProps> = { ...attr, isPublish: true };

                return <Component key={attr.id} {...defaultProps} />;
              })}

              <div className={s.fixedCard}>
                <Button
                  loading={isResultSubmitLoading}
                  htmlType="submit"
                  fullWidth
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t('common_send')}
                </Button>
              </div>
            </Attribute>
          </Card>

          <SentModal isOpen={modalType === 'sent'} onCancel={handleCloseModal} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionDecisionResult;
