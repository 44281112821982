import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault, TIconsLiteral } from 'gazprom-ui-lib';

interface SurveySuccessProps {
  isActive: boolean;
  onClickHandler: () => void;
  icon: TIconsLiteral;
  title: string;
  description: string;
}

const SurveysSent: FC<SurveySuccessProps> = (props) => {
  const { isActive, onClickHandler, icon, title, description } = props;

  return (
    <ModalDefault open={isActive}>
      <ModalDefault.Icon name={icon} type="success" />
      <ModalDefault.Title>{t(title)}</ModalDefault.Title>
      <ModalDefault.Description>{t(description)}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" size="large" fullWidth onClick={onClickHandler}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default SurveysSent;
