import React, { FC } from 'react';

import { Browser } from '@capacitor/browser';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import AUDIT_EVENTS from 'constants/auditEvents';

interface DocumentsNavigateGosuslugiModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DocumentsNavigateGosuslugiModal: FC<DocumentsNavigateGosuslugiModalProps> = (props) => {
  const { isOpen, handleClose } = props;

  const { auditSuccess } = useAudit();

  return (
    <ModalDefault open={isOpen} onCancel={handleClose} closable>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('common_request_sent')}</ModalDefault.Title>
      <ModalDefault.Description>{t('documents_certificate_request_sent')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          fullWidth
          size="large"
          onClick={() => {
            handleClose();
            auditSuccess(AUDIT_EVENTS.CLICK.ESIA);
            Browser.open({ url: 'https://esia.gosuslugi.ru/login/' });
          }}>
          {t('common_go_to_gosuslugi')}
        </Button>
        <Button type="secondary" onClick={handleClose} fullWidth size="large">
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DocumentsNavigateGosuslugiModal;
