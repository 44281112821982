import React, { useEffect } from 'react';

import cn from 'classnames';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  MORE_SUGGESTION_DECISION_DETAILS_ROUTE,
  MORE_SUGGESTION_ROUTE,
  getSuggestionCorrectionRoute,
} from 'routes/more/list';
import { t } from 'tools/i18n';

import { useLazyGetSuggestionByIdQuery } from 'services/me/suggestion/suggestionApiService';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import {
  SUGGESTION_ATTRIBUTES,
  SuggestionAttributeType,
} from 'pages/more/more-suggestion/more-suggestion-create/moreSuggestionCreate.utils';

import { CORRECTION_STATUS_CODES } from '../moreSuggestionDecision.utils';
import s from './MoreSuggestionDecisionDetails.module.scss';
import SuggestionAttribute from './containers';

const MoreSuggestionDecisionDetails = () => {
  const navigate = useNavigate();

  const params = useParams<{ id: string; decisionId: string }>();

  const isSuggestionDecisionDetails = !!useMatch(MORE_SUGGESTION_DECISION_DETAILS_ROUTE);

  const [getSuggestion, { data: suggestion, isLoading }] = useLazyGetSuggestionByIdQuery();

  useEffect(() => {
    if (params?.id) {
      getSuggestion({
        id: params.id,
        decisionId: isSuggestionDecisionDetails ? params?.decisionId : undefined,
      });
    }
  }, [getSuggestion, isSuggestionDecisionDetails, params]);

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_SUGGESTION_ROUTE} />
      <Header.Label label={t('innovations_offer_details')} />
    </>
  );

  const { name, category, curStatus, ...attributes } = suggestion ?? {};

  const needCorrection =
    !isSuggestionDecisionDetails &&
    !!curStatus?.code &&
    CORRECTION_STATUS_CODES.includes(curStatus?.code);

  const handleRequestCorrection = () => {
    if (category && suggestion) {
      navigate(getSuggestionCorrectionRoute(category.id, suggestion.id));
    }
  };

  const renderAttribute = (attribute: SuggestionAttributeType) => {
    const value = attributes[attribute.id as keyof typeof attributes];

    if (value === undefined) return null;

    return (
      <Flex vertical key={attribute.id}>
        <Typography.Text size="12" type="secondary">
          {attribute.name}
        </Typography.Text>
        <SuggestionAttribute
          attributeId={attribute.id}
          value={value}
          suggestionId={suggestion?.id ?? ''}
        />
      </Flex>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={!needCorrection}>
        <WithBackground className={cn({ [s.backgroundContainer]: needCorrection })}>
          <Card className={s.title}>
            <WithLoader isLoading={isLoading}>
              <Typography.Text size="12" type="secondary">
                {category?.name}
              </Typography.Text>
              <Typography.Title level={4} className={s.title}>
                {name}
              </Typography.Title>
            </WithLoader>
          </Card>
          <Card withPadding={false}>
            <WithLoader isLoading={isLoading}>
              <Flex vertical gap="24" className={s.attributes}>
                {!!attributes && SUGGESTION_ATTRIBUTES?.map(renderAttribute)}
              </Flex>
            </WithLoader>
          </Card>

          {!!curStatus?.comment && (
            <Card className={s.commentCard}>
              <Icon name="info" className={s.icon} />
              <Typography.Text size="14" type="danger">
                {curStatus.comment}
              </Typography.Text>
            </Card>
          )}

          {needCorrection && (
            <Card.Fixed>
              <Button
                onClick={handleRequestCorrection}
                fullWidth
                rightIcon="arrowRight"
                size="large"
                type="primary">
                {t('innovations_correct_offer')}
              </Button>
            </Card.Fixed>
          )}
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionDecisionDetails;
