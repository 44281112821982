import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateFeedbackPropsType,
  GetApprovedFeedbacksPropsType,
  GetApprovedFeedbacksResponseType,
  GetFeedbackByIdPropsType,
  GetFeedbackByIdResponseType,
  GetMySentFeedbacksPropsType,
  GetMySentFeedbacksResponseType,
} from './feedbackApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createMeUrl } from './utils';

const feedbackApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    mySentFeedbacks: builder.query<GetMySentFeedbacksResponseType, GetMySentFeedbacksPropsType>({
      query: (params) => ({
        url: createMeUrl(`/my-sent-feedback${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_FEEDBACKS],
    }),
    myReceivedFeedbacks: builder.query<
      GetApprovedFeedbacksResponseType,
      GetApprovedFeedbacksPropsType
    >({
      query: (params) => ({
        url: createMeUrl(`/my-received-feedback${prepareQueryParams(params)}`),
      }),
    }),
    getFeedbackById: builder.query<GetFeedbackByIdResponseType, GetFeedbackByIdPropsType>({
      query: (id) => ({
        url: createMeUrl(`/item/${id}`),
      }),
    }),
    createFeedback: builder.mutation<void, CreateFeedbackPropsType>({
      query: ({ employeeId, ...body }) => ({
        url: createMeUrl(`/item${prepareQueryParams({ employeeId })}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_FEEDBACKS],
    }),
  }),
});

export const {
  useLazyGetFeedbackByIdQuery,
  useLazyMySentFeedbacksQuery,
  useCreateFeedbackMutation,
  useMySentFeedbacksQuery,
  useLazyMyReceivedFeedbacksQuery,
  useMyReceivedFeedbacksQuery,
} = feedbackApiService;
