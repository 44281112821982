import React, { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  getDocumentsBusinessTripDetailsRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetBusinessTripByIdQuery } from 'services/me/work-tour/workTourApiService';

import { Button, Form, ModalDefault } from 'gazprom-ui-lib';

import BusinessTripForm from 'containers/business-trip-form/businessTripForm';
import { FORM_ENUM } from 'containers/business-trip-form/businessTripForm.utils';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { transformBackendDataIntoFront } from 'pages/documents/documents-business-trip/documentsBusinessTrip.utils';

import useHandleSubmit from './useHandleSubmit.hook';

const DocumentsBusinessTripDetailsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const isFetchAvailable = selectedEmployee && id;

  const { data: businessTrip, isFetching: businessTripIsFetching } = useGetBusinessTripByIdQuery(
    isFetchAvailable ? { id, myEmployeeId: selectedEmployee.id } : skipToken,
  );
  const [form] = Form.useForm();

  const handleModalOpenClose = () => {
    setShowSuccessModal((prevState) => !prevState);
  };
  const handleModalClose = () => {
    handleModalOpenClose();
    navigate(DOCUMENTS_BUSINESS_TRIP_ROUTE);
  };
  const headerChildren = (
    <>
      <Header.GoBack to={getDocumentsBusinessTripDetailsRoute(id!)} />
      <Header.Label label={t('documents_business_trip_change')} />
    </>
  );

  const formattedStartDate = transformBackendDataIntoFront(businessTrip?.dateFrom);

  const hideStartDate = formattedStartDate.isToday() || formattedStartDate.isBefore(dayjs());

  const { isLoading, handleSubmit } = useHandleSubmit({
    handleModalOpenClose,
    from: formattedStartDate,
  });

  useEffect(() => {
    if (businessTrip) {
      form.setFieldValue(FORM_ENUM.COUNTRY, businessTrip.country);
      form.setFieldValue(FORM_ENUM.TOWN, businessTrip.town);
      form.setFieldValue(FORM_ENUM.DESTINATION_ORG, businessTrip.destinationOrg);
    }
  }, [businessTrip, form]);

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <BusinessTripForm
              hideStartDate={hideStartDate}
              handleSubmit={handleSubmit}
              isLoading={isLoading || businessTripIsFetching}
              type="edit"
              form={form}
            />
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <ModalDefault open={showSuccessModal} onCancel={handleModalClose} width={300}>
        <ModalDefault.Icon name="ok" type="success" />
        <ModalDefault.Title>{t('common_request_sent')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('documents_business_trip_edit_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button fullWidth size="large" rightIcon="arrowRight" onClick={handleModalClose}>
            {t('common_ok')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default DocumentsBusinessTripDetailsEdit;
