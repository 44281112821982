import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Checkbox, Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { OTHER_INFORMATION_FORM_ENUM } from '../../otherInfoForm.utils';
import s from './AdditionalFinance.module.scss';

interface AdditionalFinanceProps {
  additionalFinance?: boolean;
  additionalFinanceType?: string[];
}

const AdditionalFinance: FC<AdditionalFinanceProps> = (props) => {
  const { additionalFinance, additionalFinanceType } = props;
  const [nextId, setNextId] = useState<number>(additionalFinanceType?.length ?? 0);
  const [sections, setSections] = useState<number[]>(
    additionalFinanceType?.map((_, index) => index) ?? [],
  );
  const form = Form.useFormInstance();
  const additional = Form.useWatch(OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE, form);

  const addCustomSection = () => {
    setNextId((prev) => prev + 1);
    setSections((prev) => [...prev, nextId + 1]);
  };
  const removeSection = (id: number) => () => {
    form.resetFields([`${OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE_TYPE}_${id}`]);
    return setSections((prev) => prev.filter((elId) => elId !== id));
  };

  const renderCustomSection = (id: number) => {
    return (
      <Form.Item
        key={id}
        name={`${OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE_TYPE}_${id}`}
        className={s.titleContainer}
        label={
          <>
            <Typography.Title level={5}>{t('top_management_other_income_type')}</Typography.Title>
            <Button leftIcon="bin" type="text" onClick={removeSection(id)} />
          </>
        }
        rules={[formRules.required]}
        initialValue={additionalFinanceType?.[id]}>
        <Input size="large" placeholder={t('common_enter_text')} />
      </Form.Item>
    );
  };

  const handleEmpty = () => {
    if (!sections.length) {
      addCustomSection();
    }
  };

  useEffect(() => {
    const condition = !sections.length && additional;

    if (condition) {
      form.setFieldValue(OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE, false);
    }
  }, [additional, form, sections.length]);

  return (
    <Flex gap="24" vertical>
      <Form.Item
        name={OTHER_INFORMATION_FORM_ENUM.ADDITIONAL_FINANCE}
        valuePropName="checked"
        initialValue={additionalFinance}
        label={
          <Typography.Title level={5}>
            {t('top_management_other_additional_title')}
          </Typography.Title>
        }>
        <Checkbox onClick={handleEmpty}>{t('common_yes_have')}</Checkbox>
      </Form.Item>
      {additional && (
        <>
          {sections.map(renderCustomSection)}
          <Button
            type="link"
            size="small"
            htmlType="button"
            leftIcon="plus"
            onClick={addCustomSection}
            className={s.button}>
            {t('common_add_more')}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default AdditionalFinance;
