import React, { FC, MouseEvent, useEffect } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { useLazyGetSuperiorsQuery } from 'services/me/meApiService';

import { Button, Divider, Flex, Icon, Modal, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './EmploymentStructureModal.module.scss';
import {
  EmploymentStructureItemType,
  getEmploymentStructureItems,
} from './employeeStructureModal.utils';

interface EmploymentStructureModalProps {
  open: boolean;
  handleClose: (event: MouseEvent<HTMLButtonElement>) => void;
  employeeId?: string;
  employeePosition?: string;
}

const EmploymentStructureModal: FC<EmploymentStructureModalProps> = (props) => {
  const { open, handleClose, employeeId, employeePosition } = props;

  const [
    getEmploymentStructure,
    { data: employmentStructure, isLoading: isEmploymentStructureLoading },
  ] = useLazyGetSuperiorsQuery();

  const employmentStructureItems = getEmploymentStructureItems(employmentStructure);

  useEffect(() => {
    if (employeeId) {
      getEmploymentStructure({ myEmployeeId: employeeId });
    }
  }, [employeeId, getEmploymentStructure]);

  const renderEmploymentStructureItem = (item: EmploymentStructureItemType, index: number) => {
    const isLastItem = index === employmentStructureItems.length - 1;

    return (
      <div key={item.divisionId}>
        <Divider />
        <div
          className={cn(s.employmentStructureItem, {
            [s.employmentStructureItemLast]: isLastItem,
          })}>
          <Typography.Text size={isLastItem ? '14' : '12'} weight={isLastItem ? '500' : '400'}>
            {item.name}
          </Typography.Text>
        </div>
      </div>
    );
  };

  return (
    <Modal open={open} onCancel={handleClose} wrapClassName={s.wrapClassName}>
      <Flex align="center" gap="8" className={s.header}>
        <div className={s.headerIcon}>
          <Icon name="human" />
        </div>
        <Typography.Text size="14" weight="500">
          {employeePosition}
        </Typography.Text>
      </Flex>
      <WithLoader isLoading={isEmploymentStructureLoading}>
        <div className={s.employmentStructureContainer}>
          {employmentStructureItems.map(renderEmploymentStructureItem)}
        </div>
      </WithLoader>
      <div className={s.actions}>
        <Button size="large" onClick={handleClose}>
          {t('common_ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default EmploymentStructureModal;
