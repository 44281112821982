export enum COMMENT_MODALS_ENUM {
  EDIT = 'EDIT',
  ADD = 'ADD',
  EMPTY = '',
}

export type CurrentCommentDataType = {
  initialComment: string;
  commentId: string;
};
