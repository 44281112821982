import { t } from 'tools/i18n';

import { TASK_HISTORY_STATUS_ENUM } from 'types/documents.types';

export const KEDO_TASK_DRAFT_STATUS = {
  description: '',
  state: 'draft',
  title: t('common_draft'),
  comment: '',
  createdAt: '',
  status: TASK_HISTORY_STATUS_ENUM.DRAFT,
  titleAlt: '',
};
