import React from 'react';

import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './SupportCard.module.scss';

const SupportCard = () => {
  return (
    <Card>
      <a className={s.linkWrapper} href="tel:+78126650535">
        <div>
          <Typography.Text type="secondary">{t('common_tech_support')}</Typography.Text>
          <Typography.Title level={4}>8 (812) 665 05 35</Typography.Title>
          <Typography.Text weight="500" type="primary">
            {`${t('common_inner_number')} – 9000`}
          </Typography.Text>
        </div>
        <Flex align="center" justify="center" className={s.iconWrapper}>
          <Icon name="phoneFilled" width={48} height={48} />
        </Flex>
      </a>
    </Card>
  );
};

export default SupportCard;
