import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  MORE_SUGGESTION_DECISION_ROUTE,
  MORE_SUGGESTION_ROUTE,
  getSuggestionDecisionResultRoute,
} from 'routes/more/list';
import { t } from 'tools/i18n';
import { getCreatedDate } from 'utils/article.utils';

import {
  useGetSuggestionDecisionByIdQuery,
  useLazyGetSuggestionByIdQuery,
} from 'services/me/suggestion/suggestionApiService';

import {
  Button,
  Divider,
  Flex,
  Form,
  Icon,
  Steps,
  Tag,
  Typography,
  TypographyType,
} from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { getSuggestionTagConfig } from '../containers/list/list.utils';
import { SuggestionAttributeType } from '../more-suggestion-create/moreSuggestionCreate.utils';
import s from './MoreSuggestionDecision.module.scss';
import CancelModal from './containers/cancel-modal';
import CorrectionCommentModal from './containers/correction-comment-modal';
import CorrectionTaskModal from './containers/correction-task-modal';
import DeclineCommentModal from './containers/decline-comment-modal';
import DeclinedModal from './containers/declined-modal';
import RedirectErrorModal from './containers/redirect-error-modal';
import RedirectSuccessModal from './containers/redirect-success-modal';
import SuggestionAttribute from './more-suggestion-decision-details/containers';
import { SUGGESTION_DECISION_ATTRIBUTES } from './more-suggestion-decision-details/moreSuggestionDecision.utils';
import {
  CORRECTION_STATUS_CODES,
  DECISION_STATUS_CODE_ENUM,
  DECISION_TYPE_ENUM,
  ModalType,
} from './moreSuggestionDecision.utils';
import useActions from './useActions.hook';

const MoreSuggestionDecision = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const isDecision = !!useMatch(MORE_SUGGESTION_DECISION_ROUTE);

  const [modalType, setModalType] = useState<ModalType>('');

  const [form] = Form.useForm();

  const [getSuggestion, { data: suggestion, isLoading: isSuggestionLoading }] =
    useLazyGetSuggestionByIdQuery();

  const handleGetSuggestion = useCallback(() => {
    if (params?.id && !isDecision) {
      getSuggestion({ id: params.id });
    }
  }, [getSuggestion, params.id, isDecision]);

  useEffect(() => {
    handleGetSuggestion();
  }, [handleGetSuggestion]);

  const {
    data: decision,
    isLoading,
    refetch,
  } = useGetSuggestionDecisionByIdQuery(
    {
      id: params?.id!,
    },
    { skip: !params?.id || !isDecision },
  );

  const goBackState = isDecision ? { activeTab: 'employees' } : {};

  const headerChildren = (
    <>
      <Header.GoBack state={goBackState} to={MORE_SUGGESTION_ROUTE} useDefaultGoBack />
      <Header.Label label={t('innovations_offer_view')} />
    </>
  );

  const suggestionToUse = isDecision ? decision?.suggestion : suggestion;

  const {
    id: suggestionId,
    createdAt,
    name,
    curStatus,
    statusHistory,
    ...attributes
  } = suggestionToUse ?? {};

  const {
    employer: executorEmployer,
    person: executorPerson,
    position: executorPosition,
  } = decision?.executor ?? {};

  const { code: decisionStatusCode } = decision?.status ?? {};

  const isWaitingStatus = decisionStatusCode === DECISION_STATUS_CODE_ENUM.WAITING;
  const isWaitingTakingStatus = decisionStatusCode === DECISION_STATUS_CODE_ENUM.WAITING_TAKING;
  const isWaitingReportStatus = decisionStatusCode === DECISION_STATUS_CODE_ENUM.WAITING_REPORT;
  const isFirstLeaderApprove =
    isWaitingStatus && decision?.type === DECISION_TYPE_ENUM.FIRST_LEADER;
  const isTakeForRelease =
    isWaitingTakingStatus && decision?.type === DECISION_TYPE_ENUM.TAKE_FOR_RELEASE;
  const isWaitingReleaseReport =
    isWaitingReportStatus && decision?.type === DECISION_TYPE_ENUM.WAITING_RELEASE_REPORT;

  const currentStatus = isDecision ? decision?.status : curStatus;
  const tagConfig = getSuggestionTagConfig(currentStatus);

  const needCorrection =
    !isDecision && !!curStatus?.code && CORRECTION_STATUS_CODES.includes(curStatus?.code);

  const {
    handleConfirmDeclineSuggestion,
    handleRedirectSuggestion,
    handleDeclineSuggestionWithComment,
    handleOpenSuggestionDetails,
    handleChoosePerformer,
    handleDeclineSuggestion,
    handleSubmitForCorrection,
    handleSentToCorrection,
    handleTakeForRelease,
    isTakeForReleaseLoading,
    isRedirectingLoading,
    isHeadRevisionLoading,
    isHeadRejectLoading,
  } = useActions({ form, decision, isDecision, setModalType, suggestionId });

  const renderAttribute = (attribute: SuggestionAttributeType) => {
    const value = attributes[attribute.id as keyof typeof attributes];

    if (value === undefined) return null;

    return (
      <Flex fullWidth vertical key={attribute.id} align="flex-start" justify="flex-start">
        <Typography.Text size="12" type="secondary">
          {attribute.name}
        </Typography.Text>
        <SuggestionAttribute
          attributeId={attribute.id}
          value={value}
          suggestionId={suggestionId ?? ''}
        />
      </Flex>
    );
  };

  const handleCloseModal = () => {
    setModalType('');
  };

  const handleReport = () => {
    if (params?.id) {
      navigate(getSuggestionDecisionResultRoute(params.id));
    }
  };

  const firstLeaderActions = (
    <>
      <Button onClick={handleChoosePerformer} size="large" fullWidth leftIcon="ok">
        {t('suggestion_decision_implement')}
      </Button>
      <Button
        onClick={handleRedirectSuggestion}
        size="large"
        fullWidth
        type="secondary"
        leftIcon="groupsFilled"
        loading={isRedirectingLoading}>
        {t('suggestion_decision_transfer_ecp')}
      </Button>
      <Button onClick={handleDeclineSuggestion} size="large" fullWidth danger type="secondary">
        {t('common_decline')}
      </Button>
    </>
  );

  const takeForReleaseActions = (
    <Button
      onClick={handleTakeForRelease}
      loading={isTakeForReleaseLoading}
      size="large"
      fullWidth
      leftIcon="ok">
      {t('suggestion_decision_implement')}
    </Button>
  );

  const releaseReportActions = (
    <Button onClick={handleReport} size="large" fullWidth leftIcon="ok">
      {t('suggestion_decision_report')}
    </Button>
  );

  const loading = isLoading || isSuggestionLoading;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={!needCorrection}>
        <WithPullToRefresh onRefresh={refetch}>
          <WithBackground>
            <Card>
              <WithLoader isLoading={loading}>
                <Flex vertical gap="12">
                  <Flex
                    justify="space-between"
                    align="center"
                    onClick={handleOpenSuggestionDetails}>
                    <Flex vertical className={s.inner}>
                      <Typography.Text size="12" type="secondary">
                        {getCreatedDate(createdAt)}
                      </Typography.Text>
                      <Typography.Title level={4}>{name}</Typography.Title>
                      {tagConfig && (
                        <Tag
                          className={cn(s.tag, s[tagConfig.type])}
                          type={tagConfig.type}
                          children={
                            <>
                              <Icon name={tagConfig.icon} /> <span>{tagConfig.children}</span>
                            </>
                          }
                        />
                      )}
                      {curStatus?.description && (
                        <Typography.Text size="14" type={tagConfig.type as TypographyType}>
                          {curStatus?.description}
                        </Typography.Text>
                      )}
                    </Flex>
                    <Icon name="forward" className={s.forwardIcon} />
                  </Flex>
                  {isDecision && <Divider />}
                  {isDecision && (
                    <Flex vertical gap="16" align="flex-start" justify="flex-start">
                      <Bio
                        employerName={executorEmployer?.name}
                        firstName={executorPerson?.firstName}
                        lastName={executorPerson?.lastName}
                        patronymic={executorPerson?.patronymic}
                        staffName={executorPosition?.name}
                      />
                      {SUGGESTION_DECISION_ATTRIBUTES?.map(renderAttribute)}
                      {isFirstLeaderApprove && firstLeaderActions}
                      {isTakeForRelease && takeForReleaseActions}
                      {isWaitingReleaseReport && releaseReportActions}
                    </Flex>
                  )}
                </Flex>
              </WithLoader>
            </Card>

            {!!curStatus?.comment && (
              <Card className={s.commentCard}>
                <Icon name="info" className={s.icon} />
                <Typography.Text size="14" type="danger">
                  {curStatus.comment}
                </Typography.Text>
              </Card>
            )}

            {!!statusHistory?.length && (
              <Card>
                <WithLoader isLoading={isLoading}>
                  <Typography.Title level={5}>
                    {t('documents_consideration_route')}
                  </Typography.Title>
                  <Steps className={s.steps} items={statusHistory ?? []} />
                </WithLoader>
              </Card>
            )}

            {needCorrection && (
              <Card.Fixed>
                <Button
                  onClick={handleOpenSuggestionDetails}
                  fullWidth
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t('innovations_correct_offer')}
                </Button>
              </Card.Fixed>
            )}

            <CancelModal
              isOpen={modalType === 'decline'}
              onCancel={handleCloseModal}
              onCorrection={handleSentToCorrection}
              onDecline={handleConfirmDeclineSuggestion}
            />

            <CorrectionCommentModal
              isOpen={modalType === 'correction'}
              isLoading={isHeadRevisionLoading}
              handleOpenClose={handleCloseModal}
              onSubmit={handleSubmitForCorrection}
              form={form}
            />

            <CorrectionTaskModal
              isOpen={modalType === 'correctionSent'}
              handleClose={handleCloseModal}
            />

            <RedirectErrorModal
              isOpen={modalType === 'redirectError'}
              onCancel={handleCloseModal}
            />

            <RedirectSuccessModal
              isOpen={modalType === 'redirectSuccess'}
              onCancel={handleCloseModal}
            />

            <DeclineCommentModal
              isOpen={modalType === 'declineComment'}
              isLoading={isHeadRejectLoading}
              handleOpenClose={handleCloseModal}
              onSubmit={handleDeclineSuggestionWithComment}
              form={form}
            />

            <DeclinedModal isOpen={modalType === 'declined'} onCancel={handleCloseModal} />
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionDecision;
