import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';
import { SubmitMoodPropsType, SubmitMoodResponseType } from 'services/mood/moodApiService.types';
import { createMoodUrl } from 'services/mood/utils';

const moodApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    submitMood: builder.mutation<SubmitMoodResponseType, SubmitMoodPropsType>({
      query: ({ myEmployeeId, ...body }) => ({
        url: createMoodUrl(prepareQueryParams({ myEmployeeId })),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSubmitMoodMutation } = moodApiService;
