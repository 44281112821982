import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getTopManagementByStageRoute } from 'routes/documents/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { LanguagesType, TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import { parseBySeparator } from '../../documentsTopManagement.utils';
import { MappedLanguagesType } from './languagesForm.utils';

const useLanguageForm = () => {
  const navigate = useNavigate();
  const [, setSectionId] = useState(0);
  const [languageData, setLanguageData] = useState<LanguagesType[]>([]);
  const [customSection, setCustomSection] = useState<Map<number, MappedLanguagesType>>(new Map());

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData, isLoading: renewEmployeeDataIsLoading } =
    useGetRenewEmployeeFormQuery({ myEmployeeId: selectedEmployee?.id! });
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const { languages: initialLanguages } = renewEmployeeData || {};
  const customSections = Array.from(customSection.values());

  const addCustomSection = () => {
    setSectionId((prev) => {
      const newSectionId = prev + 1;
      setCustomSection((prevCustomSection) => {
        const newCustomSection = new Map(prevCustomSection);
        newCustomSection.set(newSectionId, { id: newSectionId });
        return newCustomSection;
      });

      return newSectionId;
    });
  };
  const removeCustomSection = (id: number) => () =>
    setCustomSection((prevCustomSection) => {
      const newCustomSection = new Map(prevCustomSection);
      newCustomSection.delete(id);
      return newCustomSection;
    });

  const nextStep = () => navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.SOFTWARE));

  const handleForm = (formData: Record<string, string>) => {
    const newLanguagesData = [...parseBySeparator(formData)] as unknown as LanguagesType[];

    const body = {
      languages: newLanguagesData,
      formStep: TOP_MANAGEMENT_STAGE_ENUM.SOFTWARE,
    };

    updateFormData({ id: renewEmployeeData?.id!, myEmployeeId: selectedEmployee?.id!, body }).then(
      handleRequest({
        onSuccess: nextStep,
      }),
    );
  };

  useEffect(() => {
    if (initialLanguages?.length) {
      let lastIndex = 0;
      const form1cData: LanguagesType[] = [];
      const userData = new Map<number, MappedLanguagesType>();

      initialLanguages.forEach((educationData, index) => {
        if (educationData.isEnteredByUser) {
          userData.set(index, { ...educationData, id: index });
        } else {
          form1cData.push(educationData);
        }
        lastIndex = index;
      });

      setLanguageData(form1cData);
      setCustomSection(userData);
      setSectionId(lastIndex);
    }
  }, [initialLanguages]);

  return {
    languageData,
    customSections,
    updateFormDataIsLoading,
    renewEmployeeDataIsLoading,
    handleForm,
    addCustomSection,
    removeCustomSection,
  };
};

export default useLanguageForm;
