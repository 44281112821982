import React, { FC, memo, useEffect, useState } from 'react';

import Picker from 'react-mobile-picker';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Form, Icon, Input, Modal, Typography } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';
import s from './AttributeSchedule.module.scss';
import {
  FormScheduleType,
  INITIAL_VALUE,
  ModalConfigType,
  SCHEDULE_NAMES,
  SELECTIONS,
} from './attributeSchedule.utils';

export interface AttributeScheduleProps extends AttributeDefaultProps {}

const AttributeSchedule: FC<AttributeScheduleProps> = (props) => {
  const { id, isMandatory, isReadonly, value } = props;

  const [modalConfig, setModalConfig] = useState<ModalConfigType | null>(null);

  const form = Form.useFormInstance();
  const formItemValue: FormScheduleType[] = Form.useWatch(props.id) ?? [];

  const handleModalClose = () => {
    setModalConfig(null);
  };

  const handleSetDate = () => {
    if (modalConfig) {
      const valueIndex = formItemValue.findIndex((e) => {
        return modalConfig?.day === e.day;
      });

      const time = `${modalConfig.hours}:${modalConfig.minutes}`;
      const modifiedProperties =
        modalConfig?.type === 'from'
          ? { day: modalConfig.day, from: time }
          : { day: modalConfig.day, to: time };

      if (valueIndex === -1) {
        form.setFieldValue(id, formItemValue.concat([modifiedProperties]));
      } else {
        const copiedFormValue = [...formItemValue];
        copiedFormValue[valueIndex] = { ...copiedFormValue[valueIndex], ...modifiedProperties };

        form.setFieldValue(id, copiedFormValue);
      }

      handleModalClose();
    }
  };

  const handleResetDate = () => {
    if (modalConfig) {
      const valueIndex = formItemValue.findIndex((e) => modalConfig?.day === e.day);

      const modifiedProperties =
        modalConfig?.type === 'from'
          ? { day: modalConfig.day, from: undefined }
          : { day: modalConfig.day, to: undefined };

      if (valueIndex !== -1) {
        const copiedFormValue = [...formItemValue];
        copiedFormValue[valueIndex] = { ...copiedFormValue[valueIndex], ...modifiedProperties };

        form.setFieldValue(id, copiedFormValue);
      }

      handleModalClose();
    }
  };

  const renderSchedule = (name: string, indexDay: number) => {
    const day = indexDay.toString();
    const dayValue = formItemValue.find((e) => e.day === day);
    const [fromHours = SELECTIONS.hours[0], fromMinutes = SELECTIONS.minutes[1]] = (
      dayValue?.from || `${SELECTIONS.hours[9]}:${SELECTIONS.minutes[0]}`
    ).split(':');
    const [toHours = SELECTIONS.hours[0], toMinutes = SELECTIONS.minutes[0]] = (
      dayValue?.to || `${SELECTIONS.hours[18]}:${SELECTIONS.minutes[0]}`
    ).split(':');

    return (
      <div className={s.row} key={name}>
        <Typography.Title level={5} strong={false}>
          {name}
        </Typography.Title>
        <Input
          disabled={isReadonly}
          value={dayValue?.from}
          readOnly
          size="large"
          placeholder="09:00"
          prefix={<Icon name="time" />}
          onClick={() =>
            setModalConfig({
              day,
              hours: fromHours,
              minutes: fromMinutes,
              type: 'from',
            })
          }
        />
        <Typography.Title level={5} strong={false}>
          {t('common_to')}
        </Typography.Title>
        <Input
          disabled={isReadonly}
          value={dayValue?.to}
          readOnly
          size="large"
          placeholder="18:00"
          prefix={<Icon name="time" />}
          onClick={() =>
            setModalConfig({
              day,
              hours: toHours,
              minutes: toMinutes,
              type: 'to',
            })
          }
        />
      </div>
    );
  };

  const scheduleValidator = (_: unknown, value: FormScheduleType[]) => {
    if (isMandatory && (!value || !value?.length)) {
      return Promise.reject(formRules.required.message);
    }

    const someValuesAreIncorrect = value
      .filter((e) => e.from || e.to)
      .some((e) => !e.to || !e.from);

    if (someValuesAreIncorrect) {
      return Promise.reject(t('validation_check_data'));
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (value && typeof value === 'string') {
      const initialValues = value.split('\n').reduce((previousValue, currentValue, index) => {
        const value = currentValue.split(': ').pop();
        if (value) {
          const [from, to = ''] = value.split('-');
          const transformedFrom = from === t('common_day_off') ? '' : from;
          previousValue.push({ day: index.toString(), from: transformedFrom, to });
        }

        return previousValue;
      }, [] as FormScheduleType[]);

      form.setFieldValue(id, initialValues);
    }
  }, [form, id, value]);

  return (
    <>
      <AttributeItemWrapper {...props}>
        <Form.Item
          initialValue={INITIAL_VALUE}
          name={id}
          rules={[() => ({ validator: scheduleValidator })]}
          className={s.formItem}>
          {SCHEDULE_NAMES.map(renderSchedule)}
        </Form.Item>
      </AttributeItemWrapper>
      <Modal open={!!modalConfig} onCancel={handleModalClose} closable>
        <div className={s.title}>
          <Typography.Title level={5} className={s.title}>
            {t('common_select_time')}
          </Typography.Title>
        </div>
        <div className={s.pickerWrapper}>
          <div className={s.borderImitation} />
          <Picker
            className={s.picker}
            value={modalConfig as ModalConfigType}
            onChange={setModalConfig}
            itemHeight={48}
            height={144}>
            {Object.keys(SELECTIONS).map((name: string) => (
              <Picker.Column key={name} name={name}>
                {SELECTIONS[name as keyof typeof SELECTIONS].map((option) => (
                  <Picker.Item key={option} value={option}>
                    <Typography.Title
                      strong={false}
                      level={4}
                      disabled={modalConfig?.[name as keyof ModalConfigType] !== option}>
                      {option}
                    </Typography.Title>
                  </Picker.Item>
                ))}
              </Picker.Column>
            ))}
          </Picker>
        </div>
        <div className={s.actions}>
          <Button fullWidth size="large" leftIcon="ok" onClick={handleSetDate}>
            {t('common_confirm')}
          </Button>
          <Button fullWidth size="large" leftIcon="delete" ghost onClick={handleResetDate}>
            {t('common_reset')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(AttributeSchedule);
