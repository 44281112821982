import { FC } from 'react';

import DOMPurify from 'dompurify';

import { TitleProps, Typography } from 'gazprom-ui-lib';

import s from './surveysQuestionTitle.module.scss';

interface TitleWithNumberProps extends TitleProps {
  number: string | number;
  required?: boolean;
}

const SurveysQuestionTitle: FC<TitleWithNumberProps> = (props) => {
  const { level, number, children, required, ...rest } = props;

  return (
    <span className={s.container}>
      <span className={s.number}>
        <Typography.Title level={level}>{number}&#46;</Typography.Title>
      </span>
      <Typography.Title level={level} {...rest}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(children as string),
          }}
        />
        {required && <span className={s.required}>&nbsp;*</span>}
      </Typography.Title>
    </span>
  );
};

export default SurveysQuestionTitle;
