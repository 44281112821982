import { FC, SetStateAction } from 'react';

import { Icon, Typography } from 'gazprom-ui-lib';

import { monthMap } from 'pages/my-salary/mySalary.utils';

import { DateTypeMonth } from 'types/mySalary.types';

import s from './MySalaryNavigatorMonth.module.scss';

interface MySalaryNavigatorMonthProps {
  date: DateTypeMonth;
  setDate: (value: SetStateAction<DateTypeMonth | null>) => void;
}

const MySalaryNavigatorMonth: FC<MySalaryNavigatorMonthProps> = (props) => {
  const { date, setDate } = props;
  const { year, min, month, max } = date;
  const currentMonth = monthMap[date.month as keyof typeof monthMap]?.long;
  const decreaseVisible = min.month < month || min.year !== year;
  const increaseVisible = max.month > month || max.year !== year;

  const decrease = () => {
    const { year, month } = date;
    const newYear = month === 1 ? year - 1 : year;
    const newMonth = month === 1 ? 12 : month! - 1;

    setDate({
      year: newYear,
      month: newMonth,
      max,
      min,
    });
  };

  const increase = () => {
    const { year, month } = date;
    const newYear = month === 12 ? year + 1 : year;
    const newMonth = month === 12 ? 1 : month! + 1;

    setDate({
      year: newYear,
      month: newMonth,
      max,
      min,
    });
  };

  return (
    <div className={s.container}>
      <button onClick={decrease} className={decreaseVisible ? s.visible : s.hidden}>
        <Icon name="back" size={15} />
      </button>
      <Typography.Title level={5} className={s.text}>
        {currentMonth} {date.year}
      </Typography.Title>
      <button onClick={increase} className={increaseVisible ? s.visible : s.hidden}>
        <Icon name="forward" size={15} />
      </button>
    </div>
  );
};

export default MySalaryNavigatorMonth;
