import React, { useContext } from 'react';

import { Icon } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';

import s from '../ProfileFeedbackCreate.module.scss';
import ProfileFeedbackCreateCategory from '../profile-feedback-create-category';
import ProfileFeedbackCreateViewWrapper from '../profile-feedback-create-view-wrapper';
import { CONFIG_STATE, FeedbackCreateContext } from '../profileFeedbackCreate.utils';

const ProfileFeedbackCreateAddFeedbackCategory = () => {
  const { setConfigStep, feedbackTarget } = useContext(FeedbackCreateContext);

  if (!setConfigStep || !feedbackTarget) {
    return <></>;
  }

  return (
    <ProfileFeedbackCreateViewWrapper>
      <Card>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_TARGET,
              previous: CONFIG_STATE.ADD_FEEDBACK_CATEGORY,
            })
          }
          className={s.defaultCard}>
          <Bio
            firstName={feedbackTarget?.person?.firstName}
            lastName={feedbackTarget?.person?.lastName}
          />
          <Icon name="edit" />
        </button>
      </Card>
      <ProfileFeedbackCreateCategory
        nextStep={CONFIG_STATE.ADD_FEEDBACK_TEXT}
        showWrapper={false}
      />
    </ProfileFeedbackCreateViewWrapper>
  );
};

export default ProfileFeedbackCreateAddFeedbackCategory;
