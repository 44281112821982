import React, { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  getDocumentsBusinessTripApproversListRoute,
  getDocumentsBusinessTripDetailsRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetBusinessTripByIdQuery } from 'services/me/work-tour/workTourApiService';

import { Button, Flex, Icon, Steps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './DocumentsBusinessTripDetails.module.scss';
import Actions from './containers/actions';
import Attributes from './containers/attributes';
import CardHeader from './containers/card-header';
import CreationSuccessModal from './containers/creation-success-modal';
import Files from './containers/files';

const DocumentsBusinessTripDetails = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [modalType, setModalType] = useState<'' | 'created'>('');

  const isFetchAvailable = selectedEmployee && id;
  const {
    data: businessTrip,
    isFetching: businessTripIsFetching,
    refetch: refetchBusinessTrip,
  } = useGetBusinessTripByIdQuery(
    isFetchAvailable ? { id, myEmployeeId: selectedEmployee.id } : skipToken,
    {
      refetchOnMountOrArgChange: !!process.env.true?.trim(),
    },
  );

  const handleModalCancel = () => {
    setModalType('');
  };

  const handlePageRefresh = async () => {
    await refetchBusinessTrip();
  };

  const handleNavigateNewTrip = () => {
    if (businessTrip) {
      navigate(getDocumentsBusinessTripDetailsRoute(businessTrip.newId));
    }
  };

  const showToNewTripSection = !!businessTrip?.newId;

  const showApproveList =
    id && businessTrip?.approvalMap && !!Object.values(businessTrip.approvalMap).length;

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_BUSINESS_TRIP_ROUTE} useDefaultGoBack />
      <Header.Label label={t('documents_business_trip_details')} />
    </>
  );

  useEffect(() => {
    if (location.state?.modalType) {
      setModalType(location.state.modalType);

      navigate(location.pathname, { replace: true });
    }
  }, [location.state?.modalType, location.pathname, navigate]);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={handlePageRefresh}>
          <WithBackground>
            <Card withPadding={false}>
              <WithLoader isLoading={businessTripIsFetching}>
                <Flex vertical>
                  <Flex
                    vertical
                    gap="16"
                    align="flex-start"
                    className={cn(s.padding, s.bottomBorder)}>
                    <CardHeader businessTrip={businessTrip} />
                  </Flex>
                  <Flex vertical gap="16" className={cn(s.padding, s.bottomBorder)}>
                    <Attributes businessTrip={businessTrip} />
                  </Flex>
                  <Files files={businessTrip?.files} />
                  <Actions businessTrip={businessTrip} />
                </Flex>
              </WithLoader>
            </Card>

            {!!showApproveList && (
              <Card.Navigate to={getDocumentsBusinessTripApproversListRoute(id)}>
                <Flex justify="space-between" align="center" fullWidth>
                  <Flex vertical align="flex-start" gap="4">
                    <Typography.Title level={4}>
                      {t('documents_business_trip_approvers_list')}
                    </Typography.Title>
                    <Typography.Text type="secondary" size="14">
                      {t('documents_leaders_list')}
                    </Typography.Text>
                  </Flex>
                  <Flex align="center" justify="center" className={s.icon}>
                    <Icon size={32} name="groupsFilled" />
                  </Flex>
                </Flex>
              </Card.Navigate>
            )}

            {showToNewTripSection && (
              <>
                <Card.Info className={s.newTripDescription}>
                  <Typography.Text size="14">
                    {t('documents_statement_changed_and_resent')}
                  </Typography.Text>
                </Card.Info>
                <Card.Fixed>
                  <Button fullWidth size="large" onClick={handleNavigateNewTrip}>
                    {t('documents_statement_resent_to_new')}
                  </Button>
                </Card.Fixed>
              </>
            )}

            <Card>
              <Flex vertical gap="16">
                <Typography.Title level={4}>
                  {t('documents_business_trip_history')}
                </Typography.Title>
                <Steps className={s.steps} items={businessTrip?.history ?? []} />
              </Flex>
            </Card>
          </WithBackground>
        </WithPullToRefresh>
        <CreationSuccessModal isVisible={modalType === 'created'} onCancel={handleModalCancel} />
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsBusinessTripDetails;
