import React from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import noData from 'assets/icons/noData.svg';

import s from './ProfilePersonalDataHidden.module.scss';

const ProfilePersonalDataHidden = () => {
  return (
    <div className={s.hiddenContainer}>
      <img src={noData} alt={'Personal Data Hidden'} />
      <div className={s.hiddenContainerText}>
        <Typography.Title level={4}>{t('my_personal_data_hidden')}</Typography.Title>
        <Typography.Text size="16">{t('my_personal_data_hidden_description')}</Typography.Text>
      </div>
    </div>
  );
};

export default ProfilePersonalDataHidden;
