import React, { useContext, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, TextArea, TextAreaProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from '../ProfileFeedbackCreate.module.scss';
import { CONFIG_STATE, FeedbackCreateContext } from '../profileFeedbackCreate.utils';

const ProfileFeedbackCreateDescription = () => {
  const { feedbackText, setFeedbackText, setConfigStep } = useContext(FeedbackCreateContext);

  const [text, setText] = useState<string>(feedbackText);

  const handleChange: TextAreaProps['onChange'] = (e) => {
    setText(e.target.value);
  };

  const noLength = !!text.length;

  const handleSubmit = () => {
    if (setFeedbackText) {
      setFeedbackText(text);
    }
    if (setConfigStep) {
      setConfigStep({
        current: CONFIG_STATE.ADD_FEEDBACK_CONFIRM,
      });
    }
  };

  const handleGoBack = () => {
    if (setConfigStep) {
      setConfigStep((prevState) => ({
        current: prevState.previous!,
      }));
    }
  };

  const header = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label label={t('feedback_category')} />
      {noLength && <Header.RightIcon onClick={handleSubmit} iconName="ok" />}
    </>
  );

  return (
    <WithNavigation headerChildren={header}>
      <WithBackground>
        <Card className={s.textAreaWrapper}>
          <Typography.Title level={4}>{t('feedback_text')}</Typography.Title>
          <Typography.Text>
            {t('common_symbols_count', { currentCount: text.length, maxCount: 360 })}
          </Typography.Text>
          <TextArea
            value={text}
            className={s.textAreaWithoutPadding}
            autoFocus
            rows={10}
            placeholder={t('common_enter_text')}
            onChange={handleChange}
            maxLength={360}
          />
          {noLength && (
            <Button rightIcon="arrowRight" fullWidth onClick={handleSubmit}>
              {t('common_add_text')}
            </Button>
          )}
        </Card>
      </WithBackground>
    </WithNavigation>
  );
};

export default ProfileFeedbackCreateDescription;
