import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_SOCIAL_POLICY_ROUTE } from 'routes/documents/list';
import { GUIDE_ROUTE, getSocialPolicyCategoryWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetKedoCategoriesQuery, useGetKedoTasksQuery } from 'services/kedo/kedoApiService';

import { Button, Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { KedoCategory, TASK_TYPE_ENUM } from 'types/documents.types';

import s from './GuideSocialPolicy.module.scss';

const GuideSocialPolicy = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const navigate = useNavigate();
  const { data: tasks } = useGetKedoTasksQuery({
    myEmployeeId: selectedEmployee?.id ?? '',
    type: TASK_TYPE_ENUM.SOCIAL,
    page: 1,
    size: 1,
  });

  const { data: categories, isLoading: isCategoriesLoading } = useGetKedoCategoriesQuery({
    type: TASK_TYPE_ENUM.SOCIAL,
  });

  const headerChildren = (
    <>
      <Header.GoBack to={GUIDE_ROUTE} />
      <Header.Label label={t('common_social_policy')} />
    </>
  );

  const renderCategory = (category: KedoCategory) => {
    const fallbackIcon = <Icon name="social" />;

    return (
      <Card.Navigate
        to={getSocialPolicyCategoryWithIdRoute(category.id)}
        className={s.card}
        key={category.id}>
        <div>
          <div>{Icon({ name: category.icon as TIconsLiteral }) ?? fallbackIcon}</div>
          <Typography.Text size="14" weight="500">
            {category.name}
          </Typography.Text>
        </div>
        <Icon name="forward" />
      </Card.Navigate>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <Card.Default
              title={t('common_social_policy')}
              description={t('guide_social_policy_select_category')}
              icon="social"
            />
            {!!tasks?.content?.length && (
              <Button
                size="large"
                fullWidth
                className={s.button}
                onClick={() => {
                  navigate(DOCUMENTS_SOCIAL_POLICY_ROUTE);
                }}>
                {t('common_sent_applications')}
                <Typography.Text size="14">{tasks?.totalCount}</Typography.Text>
              </Button>
            )}
          </Card>
          <Card withPadding={false}>
            <Typography.Title level={4} className={s.categoryTitle}>
              {t('guide_social_policy_available_categories')}
            </Typography.Title>
            <WithLoader isLoading={isCategoriesLoading}>
              {categories?.map(renderCategory)}
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};
export default GuideSocialPolicy;
