import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Radio, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { SoftwareSkillsType } from 'types/topManagement.types';

import Collapse from '../collapse';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import s from './SoftwareForm.module.scss';
import SoftwareCard from './containers/software-card';
import { MappedSoftwareSkillsType, SOFTWARE_FORM_ENUM, radioOptions } from './softwareForm.utils';
import useSoftwareForm from './useSoftwareForm.hook';

const SoftwareForm = () => {
  const {
    customSections,
    renewEmployeeDataIsLoading,
    softwareData,
    updateFormDataIsLoading,
    addCustomSection,
    handleForm,
    removeCustomSection,
  } = useSoftwareForm();

  const renderSoftWareCards = (data: SoftwareSkillsType, index: number) => {
    const { softwareName, level } = data;
    const key = `${softwareName}_${index}_initial`;

    return (
      <Card key={key}>
        <Collapse
          noPadding
          defaultActiveKey={key}
          items={[
            {
              key,
              label: <Typography.Title level={5}>{softwareName}</Typography.Title>,
              children: (
                <>
                  <Form.Item
                    name={`${SOFTWARE_FORM_ENUM.SOFTWARE_NAME}_${index}`}
                    initialValue={softwareName}
                    hidden
                  />
                  <Form.Item
                    name={`${SOFTWARE_FORM_ENUM.LEVEL}_${index}`}
                    label={
                      <Typography.Title level={5}>
                        {t('top_management_languages_level')}
                      </Typography.Title>
                    }
                    initialValue={level}
                    rules={[formRules.required]}>
                    <Radio.Group options={radioOptions} className={s.radioGroup} />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />
      </Card>
    );
  };

  const renderCustomSections = (element: MappedSoftwareSkillsType) => {
    const { id, ...rest } = element || {};

    return <SoftwareCard id={id} onClick={removeCustomSection(id)} key={id} initialData={rest} />;
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_software_long')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>
          {softwareData?.map(renderSoftWareCards)}

          <Flex gap="16" vertical>
            {customSections.map(renderCustomSections)}
          </Flex>

          <Card>
            <Button
              type="secondary"
              fullWidth
              size="large"
              htmlType="button"
              leftIcon="plus"
              onClick={addCustomSection}>
              {t('top_management_software_add')}
            </Button>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default SoftwareForm;
