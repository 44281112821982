import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetAssignedToMeStatementByIdQuery } from 'services/kedo/kedoApiService';

import DocumentsAssignment from 'containers/document-assignment';
import WithLoader from 'containers/wrappers/with-loader';

import { getFileConfig } from '../documents.utils';
import WorkTourDetails from './containers/work-tour-details';
import WorkTourDraftDetails from './containers/work-tour-draft-details';

const DocumentsAssignmentDetails = () => {
  const params = useParams<{ taskId: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const {
    data: assignment,
    isLoading: isAssignmentLoading,
    isFetching: isAssignmentFetching,
  } = useGetAssignedToMeStatementByIdQuery(
    {
      taskId: params?.taskId!,
    },
    {
      skip: !params?.taskId || !selectedEmployee?.id,
      refetchOnMountOrArgChange: !!process.env.true?.trim(),
    },
  );

  const isLoading = isAssignmentLoading || isAssignmentFetching;

  const isCancellationAvailable = assignment?.type === 'CANCEL_ORG_REQUEST';

  const showCancellationComment = isCancellationAvailable && !!assignment?.comment;

  if (!assignment && !isLoading) return null;

  const fileConfig = assignment ? getFileConfig(assignment) : {};

  switch (assignment?.entityType) {
    case 'DOCUMENT': {
      return (
        <WithLoader isLoading={isLoading}>
          <DocumentsAssignment>
            <DocumentsAssignment.DetailsWrapper>
              <DocumentsAssignment.DetailsHeader />
              <DocumentsAssignment.Content>
                <DocumentsAssignment.Employee />
                <DocumentsAssignment.Attributes />
                <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
                <DocumentsAssignment.Files {...fileConfig} />
              </DocumentsAssignment.Content>
              <DocumentsAssignment.Actions />
            </DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.CancelHistory />
            <DocumentsAssignment.History />
          </DocumentsAssignment>
        </WithLoader>
      );
    }
    case 'REVIEW_TASK': {
      return (
        <WithLoader isLoading={isLoading}>
          <DocumentsAssignment>
            <DocumentsAssignment.DetailsWrapper>
              <DocumentsAssignment.DetailsHeader />
              <DocumentsAssignment.Content>
                <DocumentsAssignment.Employee />
                <DocumentsAssignment.Attributes />
                <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
                <DocumentsAssignment.Files {...fileConfig} />
              </DocumentsAssignment.Content>
              <DocumentsAssignment.Actions />
            </DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.CancelHistory />
            <DocumentsAssignment.History />
          </DocumentsAssignment>
        </WithLoader>
      );
    }
    case 'WORK_TOUR': {
      if (assignment.type === 'WORKTOUR_DRAFT_APPROVE') {
        return (
          <WithLoader isLoading={isLoading}>
            <DocumentsAssignment>
              <DocumentsAssignment.DetailsWrapper>
                <DocumentsAssignment.DetailsHeader />
                <DocumentsAssignment.Content>
                  <WorkTourDraftDetails workTour={assignment.workTour} />
                  <DocumentsAssignment.Files {...fileConfig} />
                </DocumentsAssignment.Content>
              </DocumentsAssignment.DetailsWrapper>
              <DocumentsAssignment.Actions />
            </DocumentsAssignment>
          </WithLoader>
        );
      }

      return (
        <WithLoader isLoading={isLoading}>
          <DocumentsAssignment>
            <DocumentsAssignment.DetailsWrapper>
              <DocumentsAssignment.DetailsHeader />
              <DocumentsAssignment.Content>
                <DocumentsAssignment.Employee />
                <WorkTourDetails workTour={assignment.workTour} />
                <DocumentsAssignment.Files {...fileConfig} />
                <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
              </DocumentsAssignment.Content>
              <DocumentsAssignment.Actions />
            </DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.CancelHistory />
            <DocumentsAssignment.History history={assignment?.workTour?.history} />
          </DocumentsAssignment>
        </WithLoader>
      );
    }
    case 'KEDO_TASK':
    default: {
      return (
        <WithLoader isLoading={isLoading}>
          <DocumentsAssignment>
            <DocumentsAssignment.DetailsWrapper>
              <DocumentsAssignment.DetailsHeader />
              <DocumentsAssignment.Content>
                <DocumentsAssignment.Employee />
                <DocumentsAssignment.Attributes />
                <DocumentsAssignment.Files {...fileConfig} />
                <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
              </DocumentsAssignment.Content>
              <DocumentsAssignment.Actions />
            </DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.CancelHistory />
            <DocumentsAssignment.History />
          </DocumentsAssignment>
        </WithLoader>
      );
    }
  }
};

export default DocumentsAssignmentDetails;
