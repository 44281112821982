import React from 'react';

import { t } from 'tools/i18n';

import { Radio } from 'gazprom-ui-lib';

export const renderHoliday = (vacation: string) => {
  const labelAndValue = t(`common_enum_holidays_kid_${vacation.toLowerCase()}`);

  return (
    <Radio.Button key={labelAndValue} value={labelAndValue}>
      {labelAndValue}
    </Radio.Button>
  );
};

export enum HOLIDAY_TYPE_ENUM {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
