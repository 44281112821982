import React, { memo } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './SectionDescription.module.scss';

const SectionDescription = () => {
  const navigate = useNavigate();

  const handleRequest = () => {
    navigate(DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE);
  };
  return (
    <Card>
      <Card.Default
        title={t('documents_official_travels')}
        description={t('documents_official_travels_description')}
        icon="plane"
      />
      <Button leftIcon="plus" fullWidth size="large" onClick={handleRequest} className={s.button}>
        {t('documents_business_trip_arrange')}
      </Button>
    </Card>
  );
};

export default memo(SectionDescription);
