import { MODAL_TYPE } from '../utils';

export type ModalType =
  | MODAL_TYPE.SIGNED
  | MODAL_TYPE.CORRECTION
  | MODAL_TYPE.REJECTED
  | MODAL_TYPE.REJECT_COMMENT
  | MODAL_TYPE.REVISION_COMMENT
  | '';

export const ACTIONS_AVAILABLE_STATUSES = [
  'EMPLOYMENT_SIGN_EMPLOYEE',
  'EMPLOYMENT_SIGN_PERSON_CONTRACT',
  'EMPLOYMENT_SIGN_PERSON_ORDER',
];

export const REJECT_AVAILABLE_STATUSES = ['EMPLOYMENT_SIGN_PERSON_CONTRACT'];
