import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import secureStorage from './secureStorage';

const keycloakConfig: AuthProviderProps = {
  authority: process.env.https://sso.dev.tockl.ru/realms/tockl as string,
  client_id: process.env.public as string,
  redirect_uri: `${window.location.protocol}//${window.location.host}/keycloak.html`,
  userStore: new WebStorageStateStore({ store: secureStorage }),
  stateStore: new WebStorageStateStore({ store: secureStorage }),
};

const userManager = new UserManager(keycloakConfig) as UserManager & { signupRedirect: () => void };

export { userManager };
