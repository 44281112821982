import { useEffect } from 'react';

import dayjs from 'dayjs';
import useSecureStorage from 'utils/useSecureStorage.hook';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

const useHomeMoodHook = () => {
  const [isMoodSubmitted, setIsMoodSubmitted] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_MOOD_SUBMITTED,
    false,
  );
  const [isMoodClosed, setIsMoodClosed] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_MOOD_CLOSED,
    false,
  );
  const [isMoodClosedTwice, setIsMoodClosedTwice] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_MOOD_CLOSED_TWICE,
    false,
  );
  const [isMoodSwiped, setIsMoodSwiped] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_MOOD_SWIPED,
    false,
  );
  const [nextMoodDailyShowDate, setNextMoodDailyShownDate] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_DAILY_NEXT_DATE_MOOD,
    '',
  );
  const [nextMoodWeeklyShowDate, setNextMoodWeeklyShowDate] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_WEEKLY_NEXT_DATE_MOOD,
    '',
  );

  const showMoodAsModal = !isMoodSubmitted && !isMoodSwiped;
  const showMoodFirstTime = !isMoodSubmitted && isMoodSwiped && !isMoodClosed;
  const showMoodLastTime = !isMoodSubmitted && isMoodSwiped && isMoodClosed && !isMoodClosedTwice;

  useEffect(() => {
    const shouldShowMoodTrackerDaily =
      nextMoodDailyShowDate && dayjs().isAfter(dayjs(nextMoodDailyShowDate));

    if (shouldShowMoodTrackerDaily) {
      setIsMoodClosed(false);
      setIsMoodClosedTwice(false);
      setIsMoodSubmitted(false);
    }
  }, [nextMoodDailyShowDate, setIsMoodClosed, setIsMoodClosedTwice, setIsMoodSubmitted]);

  useEffect(() => {
    const shouldShowMoodTrackerWeekly =
      nextMoodWeeklyShowDate && dayjs().isAfter(dayjs(nextMoodWeeklyShowDate));

    if (shouldShowMoodTrackerWeekly) {
      setIsMoodSubmitted(false);
      setIsMoodSwiped(false);
    }
  }, [nextMoodWeeklyShowDate, setIsMoodSubmitted, setIsMoodSwiped]);

  const handleCloseMoodFirst = () => {
    setIsMoodClosed(true);
  };

  const handleCloseMoodLast = () => {
    setIsMoodClosedTwice(true);
    setNextMoodDailyShownDate(dayjs().add(1, 'day').format());
  };

  const handleMoodSubmit = () => {
    setIsMoodSubmitted(true);
    setNextMoodDailyShownDate(dayjs().add(1, 'day').format());
    setNextMoodWeeklyShowDate(dayjs().add(1, 'week').format());
  };

  const handleSwipe = () => {
    setNextMoodWeeklyShowDate(dayjs().add(1, 'week').format());
    setIsMoodSwiped(true);
  };

  return {
    showMoodAsModal,
    showMoodFirstTime,
    showMoodLastTime,
    handleCloseMoodFirst,
    handleCloseMoodLast,
    handleMoodSubmit,
    handleSwipe,
  };
};

export default useHomeMoodHook;
