import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import MobileNumberKeyboard from 'components/mobile-number-keyboard';
import Passcode from 'components/passcode';
import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './ProfileAuthChangePin.module.scss';
import { StepsType, getLabel } from './profileAuthChangePin.utils';
import useActions from './useActions.hook';

const ProfileAuthChangePin = () => {
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const [step, setStep] = useState<StepsType>('old');
  const [pass, setPass] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const { handleElementClick, handleGoBack, handleRemoveElement } = useActions({
    step,
    setPass,
    setError,
    isSuccess,
    setIsSuccess,
    setStep,
  });

  const specialLeftElement = {
    label: (
      <Typography.Text size="12" type="secondary">
        {t('common_exit')}
      </Typography.Text>
    ),
    onClick: handleGoBack,
  };

  const specialRightBackspaceElement = {
    label: pass ? <Icon name="backspace" className={s.backspace} /> : <></>,
    onClick: handleRemoveElement,
  };

  useEffect(() => {
    if (error) {
      timer.current = setTimeout(() => {
        setPass('');
        setError('');
      }, 2000);
    } else if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [error]);

  return (
    <WithStatusBar>
      <WithGzpTopLogo className={s.wrapper}>
        <div className={s.codeWrapper}>
          <Typography.Title level={3}>{getLabel(step)}</Typography.Title>
          <Passcode
            selectedCount={pass.length}
            selectedClassName={cn({
              [s.passwordsAreWrong]: !!error,
              [s.passwordsAreMatched]: isSuccess,
            })}
          />
          <Typography.Title level={5} type="danger" className={cn(s.error, error && s.showElement)}>
            {error}
          </Typography.Title>
          <Icon
            name="okFilled"
            className={cn(s.successIcon, step === 'success' && s.showElement)}
          />
        </div>
        <div className={s.keyboard}>
          <MobileNumberKeyboard
            onElementClick={handleElementClick}
            specialLeftElement={specialLeftElement}
            specialRightElement={specialRightBackspaceElement}
          />
        </div>
      </WithGzpTopLogo>
    </WithStatusBar>
  );
};

export default ProfileAuthChangePin;
