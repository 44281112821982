import initialApiService from 'services/initialApiService';

import { GetFeedbackCategoriesResponseType } from './feedbackCategoriesApiService.types';

import { createFeedbackCategoryUrl } from './utils';

const feedbackCategoriesApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackCategories: builder.query<GetFeedbackCategoriesResponseType, void>({
      query: () => ({
        url: createFeedbackCategoryUrl(''),
      }),
    }),
  }),
});

export const { useGetFeedbackCategoriesQuery } = feedbackCategoriesApiService;
