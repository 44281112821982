import React, { ReactElement } from 'react';

import ProfileFeedbackCreateAddFeedbackCategory from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-add-feedback-category';
import ProfileFeedbackCreateAddFeedbackDescription from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-add-feedback-description';
import ProfileFeedbackCreateCategory from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-category';
import ProfileFeedbackCreateConfirm from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-confirm';
import ProfileFeedbackCreateDescription from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-description';
import ProfileFeedbackCreateInitial from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-initial';
import ProfileFeedbackCreateSelectFeedbackTarget from 'pages/more/more-feedback/profile-feedback-create/profile-feedback-create-select-feedback-target';

import { CONFIG_STATE, ConfigStepType } from './profileFeedbackCreate.utils';

interface UseContentProps {
  configStep: ConfigStepType;
}

const useContent = (props: UseContentProps) => {
  const { configStep } = props;

  const CONFIG: Record<CONFIG_STATE, ReactElement> = {
    [CONFIG_STATE.INITIAL]: <ProfileFeedbackCreateInitial />,
    [CONFIG_STATE.ADD_FEEDBACK_CATEGORY]: <ProfileFeedbackCreateAddFeedbackCategory />,
    [CONFIG_STATE.ADD_FEEDBACK_TEXT]: <ProfileFeedbackCreateAddFeedbackDescription />,
    [CONFIG_STATE.ADD_FEEDBACK_CONFIRM]: <ProfileFeedbackCreateConfirm />,

    // ISOLATED VIEWS
    [CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_TARGET]: (
      <ProfileFeedbackCreateSelectFeedbackTarget />
    ),
    [CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_CATEGORY]: <ProfileFeedbackCreateCategory />,
    [CONFIG_STATE.STAND_ALONE_FEEDBACK_TEXT]: <ProfileFeedbackCreateDescription />,
  };

  return CONFIG[configStep?.current];
};

export default useContent;
