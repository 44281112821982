import React, { FC } from 'react';

import { PersonalDataType } from 'types/employee.types';

import PersonalDataDetailsItem from '../personal-data-details-item';
import s from './profilePersonalDataDetailsGeneral.module.scss';
import { getPersonalDataGeneralItems } from './profilePersonalDataDetailsGeneral.utils';

type ProfilePersonalDataDetailsGeneralProps = {
  personalData: PersonalDataType;
};

const ProfilePersonalDataDetailsGeneral: FC<ProfilePersonalDataDetailsGeneralProps> = (props) => {
  const { personalData } = props;

  return (
    <div className={s.generalWrapper}>
      {getPersonalDataGeneralItems(personalData).map((item) => (
        <PersonalDataDetailsItem {...item} key={item.title} />
      ))}
    </div>
  );
};

export default ProfilePersonalDataDetailsGeneral;
