import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  ActivateSettlementRequestType,
  DeactivateSettlementRequestType,
  GetHotContactsRequest,
  GetHotContactsResponse,
  GetMySettlementHistoryRequest,
  GetMySettlementHistoryResponse,
  GetMySettlementRequest,
  GetMySettlementResponse,
  GetSettlementByIdRequest,
  GetSettlementByIdResponse,
  GetSettlementInstructionsRequest,
  GetSettlementInstructionsResponse,
  GetSettlementRequest,
  GetSettlementResponse,
} from './guideApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

const guideApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    activateSettlement: builder.mutation<void, ActivateSettlementRequestType>({
      query: ({ settlementId, myEmployeeId }) => ({
        url: `me/settlement/${settlementId}/activate${prepareQueryParams({
          myEmployeeId: myEmployeeId,
        })}`,
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_GUIDE_HISTORY, SERVICE_TAGS.GET_EMPLOYEES],
    }),
    deactivateSettlement: builder.mutation<void, DeactivateSettlementRequestType>({
      query: ({ settlementId, myEmployeeId }) => ({
        url: `me/settlement/${settlementId}/deactivate${prepareQueryParams({
          myEmployeeId: myEmployeeId,
        })}`,
        method: 'POST',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EMPLOYEES],
    }),
    getMySettlements: builder.query<GetMySettlementResponse, GetMySettlementRequest>({
      query: (params) => ({
        url: `me/settlement${prepareQueryParams(params)}`,
      }),
    }),
    getSettlement: builder.query<GetSettlementResponse, GetSettlementRequest>({
      query: ({ id }) => ({
        url: `me/settlement/${id}`,
      }),
    }),
    getHotContacts: builder.query<GetHotContactsResponse, GetHotContactsRequest>({
      query: ({ myEmployeeId }) => ({
        url: `me/hot-contact${prepareQueryParams({
          myEmployeeId: myEmployeeId,
        })}`,
      }),
    }),
    getSettlementInstructions: builder.query<
      GetSettlementInstructionsResponse[],
      GetSettlementInstructionsRequest
    >({
      query: ({ settlementId }) => ({
        url: `settlement-instruction/by-settlement-id/${settlementId}`,
      }),
    }),
    getMySettlementHistory: builder.query<
      GetMySettlementHistoryResponse,
      GetMySettlementHistoryRequest
    >({
      query: (params) => ({
        url: `me/settlement-history${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HISTORY],
    }),
    getSettlementById: builder.query<GetSettlementByIdResponse, GetSettlementByIdRequest>({
      query: ({ id }) => ({
        url: `me/settlement/${id}`,
      }),
      providesTags: [SERVICE_TAGS.GET_GUIDE_HISTORY],
    }),
  }),
});

export const {
  useGetMySettlementsQuery,
  useGetSettlementQuery,
  useLazyGetSettlementQuery,
  useActivateSettlementMutation,
  useGetHotContactsQuery,
  useGetSettlementInstructionsQuery,
  useGetMySettlementHistoryQuery,
  useDeactivateSettlementMutation,
  useGetSettlementByIdQuery,
  useLazyGetMySettlementsQuery,
} = guideApiService;
