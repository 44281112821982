import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Flex, Form, Select, SelectProps } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { FORM_ENUM } from '../../businessTripForm.utils';
import s from './Approvers.module.scss';
import PopoverTitle from './containers/popover-title';
import SelectWithSearch from './containers/select-with-search';
import useApprovers from './useApprovers.hook';

export type ModalType = FORM_ENUM.APPROVER | FORM_ENUM.CONFIRMER | null;

const Approvers = () => {
  const [modalType, setModalType] = useState<ModalType>(null);

  const {
    initialLeaders,
    isInitialLeadersLoading,
    isParticipantsLoading,
    leadersIsFetching,
    options,
    leadersToShow,
    requestBody,
    handleInputChange,
  } = useApprovers();

  const handleModalOpen =
    (modalType: ModalType): SelectProps['onClick'] =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setModalType(modalType);
    };

  return (
    <WithLoader isLoading={isInitialLeadersLoading}>
      <Flex vertical className={s.wrapper}>
        <Flex vertical gap="12">
          <PopoverTitle
            popoverTitle={t('common_approval')}
            popoverContent={t('documents_leaders_first_leader')}
          />
          <Form.Item
            name={FORM_ENUM.APPROVER}
            rules={[formRules.required]}
            initialValue={initialLeaders.firstLeader}>
            <Select
              onClick={handleModalOpen(FORM_ENUM.APPROVER)}
              open={false}
              disabled={leadersIsFetching}
              options={options}
            />
          </Form.Item>
        </Flex>
        <Flex vertical gap="12">
          <PopoverTitle
            popoverTitle={t('common_confirmation')}
            popoverContent={t('documents_order_approver')}
          />
          <Form.Item name={FORM_ENUM.CONFIRMER} initialValue={initialLeaders.functionalLeader}>
            <Select
              onClick={handleModalOpen(FORM_ENUM.CONFIRMER)}
              open={false}
              disabled={leadersIsFetching}
              options={options}
            />
          </Form.Item>
        </Flex>
      </Flex>
      <SelectWithSearch
        modalType={modalType}
        setModalType={setModalType}
        leadersData={leadersToShow}
        leadersIsFetching={leadersIsFetching}
        requestBody={requestBody}
        handleInputChange={handleInputChange}
        isLeadersLoading={isParticipantsLoading}
      />
    </WithLoader>
  );
};

export default Approvers;
