import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './ProfilePersonalDataHeaderHidden.module.scss';

interface ProfilePersonalDataHeaderHiddenProps {
  personFullName: string;
  handleShowConfirmCodeScreen: () => void;
  isRequestLoading: boolean;
}

const ProfilePersonalDataHeaderHidden: FC<ProfilePersonalDataHeaderHiddenProps> = (props) => {
  const { personFullName, isRequestLoading, handleShowConfirmCodeScreen } = props;

  return (
    <Card>
      <Card.Default
        icon="passport"
        title={personFullName}
        description={t('my_personal_data_view_description')}
      />
      <Button
        loading={isRequestLoading}
        onClick={handleShowConfirmCodeScreen}
        className={s.viewDataButton}
        size="large"
        leftIcon="eye"
        type="primary"
        fullWidth>
        {t('my_personal_data_view_data')}
      </Button>
    </Card>
  );
};

export default ProfilePersonalDataHeaderHidden;
