import { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  getDocumentsBusinessTripCancelRoute,
  getDocumentsBusinessTripEditRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './Actions.module.scss';

interface ActionsProps {
  businessTrip?: BusinessTripType;
}

const Actions: FC<ActionsProps> = (props) => {
  const { businessTrip } = props;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { canCancel, canModify } = businessTrip ?? {};

  const handleEdit = () => {
    if (id) {
      navigate(getDocumentsBusinessTripEditRoute(id));
    }
  };

  const handleCancel = () => {
    if (id) {
      navigate(getDocumentsBusinessTripCancelRoute(id));
    }
  };

  if (!canCancel && !canModify) {
    return <></>;
  }

  return (
    <Flex vertical gap="12" className={s.wrapper}>
      {canModify && (
        <Button fullWidth leftIcon="edit" onClick={handleEdit} size="large">
          {t('common_make_changes')}
        </Button>
      )}
      {canCancel && (
        <Button danger type="secondary" fullWidth onClick={handleCancel} size="large">
          {t('common_cancel')}
        </Button>
      )}
    </Flex>
  );
};

export default Actions;
