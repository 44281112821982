import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useCancelBusinessTripMutation } from 'services/me/work-tour/workTourApiService';
import { CancelBusinessTripsPropsType } from 'services/me/work-tour/workTourApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { BusinessTripFormProps } from 'containers/business-trip-form/businessTripForm';
import {
  FORM_ENUM,
  FormCancelResponse,
} from 'containers/business-trip-form/businessTripForm.utils';

interface UseHandleSubmitProps {
  handleModalOpen: () => void;
}

const useHandleSubmit = (props: UseHandleSubmitProps) => {
  const { handleModalOpen } = props;

  const { id } = useParams<{ id: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();
  const [cancelBusinessTrip, { isLoading }] = useCancelBusinessTripMutation();

  const handleCancel: BusinessTripFormProps['handleSubmit'] = (initialFormResponse) => {
    const formResponse = initialFormResponse as FormCancelResponse;
    const isCancelAvailable = selectedEmployee && id && formResponse[FORM_ENUM.PURPOSE_CANCEL];

    if (isCancelAvailable) {
      const cancelProps: CancelBusinessTripsPropsType = {
        myEmployeeId: selectedEmployee.id,
        id,
        reason: formResponse[FORM_ENUM.PURPOSE_CANCEL]!,
      };

      if (FORM_ENUM.PURPOSE_CANCEL_SPECIFY in formResponse) {
        cancelProps.comment = formResponse[FORM_ENUM.PURPOSE_CANCEL_SPECIFY];
      }

      cancelBusinessTrip(cancelProps).then(
        handleRequest({
          onSuccess: handleModalOpen,
        }),
      );
    }
  };

  return {
    isLoading,
    handleCancel,
  };
};

export default useHandleSubmit;
