import { FC, MouseEvent, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { getLikesCount } from 'utils/getLikesCount';

import {
  useAddLikeMutation,
  useLazyGetArticleByIdQuery,
  useRemoveLikeMutation,
} from 'services/article/articleApiService';
import { addRemoveLike } from 'slices/newsSlice';
import { useAppDispatch } from 'store';

import { Typography, handleRequest } from 'gazprom-ui-lib';

import YoutubePlayer from 'components/youtube-player';
import Card from 'containers/card';
import Header from 'containers/header';
import NewsDetailsContent from 'containers/news-details-content';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ArticleFullType } from 'types/article.types';

const GuideSettlementPosterDetails: FC = () => {
  const params = useParams<{ posterId: string }>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isLiked, setIsLiked] = useState<boolean>(false);

  const [addLike] = useAddLikeMutation();
  const [removeLike] = useRemoveLikeMutation();
  const [getArticle, { isFetching: articleDataIsFetching, data: articleData }] =
    useLazyGetArticleByIdQuery();

  const handleAddArticle = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (articleData?.code) {
      const mutation = articleData?.liked ? removeLike : addLike;
      setIsLiked((prevState) => !prevState);
      dispatch(addRemoveLike(articleData?.code));
      mutation(articleData?.code);
    }
  };

  const renderCategory = (category: { id: string; name: string }) => (
    <Typography.Text type="primary" size="14" key={category.id} weight="500">
      {category.name}
    </Typography.Text>
  );

  const renderMedia = (media: { type: string; link: string; name: string }) => {
    if (media.type === 'youtube') {
      return <YoutubePlayer key={media.name} src={media.link} title={media.name} />;
    }
    return null;
  };

  useEffect(() => {
    if (params.posterId) {
      const onSuccess = (data?: ArticleFullType) => {
        if (data) setIsLiked(data.liked);
      };
      getArticle(params.posterId).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  }, [getArticle, params.posterId]);

  const initialLikesCount = articleData?.likesCnt ?? 0;
  const likesCount = getLikesCount(initialLikesCount, !!articleData?.liked, isLiked);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('home_news_view')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <WithLoader isLoading={articleDataIsFetching}>
              <NewsDetailsContent
                renderMedia={renderMedia}
                renderCategory={renderCategory}
                likesCount={likesCount}
                isLiked={isLiked}
                articleData={articleData}
                handleAddArticle={handleAddArticle}
              />
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideSettlementPosterDetails;
