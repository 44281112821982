import { FC, useEffect } from 'react';

import cn from 'classnames';

import { MatrixAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form, Radio, Typography } from 'gazprom-ui-lib';

import SurveyTitle from 'pages/surveys/surveys-question-title';

import { Options, QuestionTypes, Rows } from 'types/surveys.types';

import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsMatrix.module.scss';

export interface SurveysQuestionsMatrixProps extends QuestionProps {
  no: number;
  is_required: boolean;
  title: string;
  options: Options[];
  rows: Rows[];
}

interface SurveysQuestionsSubMatrixProps {
  matrixId: number;
  id: number;
  title: string;
  options: Options[];
  type: QuestionTypes;
  answer?:
    | {
        row_id: number;
        row_name: string;
        options: {
          option_id: number;
          option_name: string;
          answer_value: boolean;
        }[];
      }[];
}

const SurveysQuestionsSubMatrix: FC<SurveysQuestionsSubMatrixProps> = (props) => {
  const { id, type, options, title, matrixId, answer } = props;
  const form = Form.useFormInstance();
  const { isCompleted } = useAppSelector((store) => store.surveySlice);
  const name = `${type}.${matrixId}.${id}`;

  useEffect(() => {
    if (answer) {
      const currentAnswer = answer
        .find((el) => el.row_name === title)
        ?.options.find((option) => option.answer_value === true);
      currentAnswer && form.setFieldValue(name, currentAnswer.option_id);
    }
  }, [answer, form, name, title]);

  return (
    <div className={s.subMatrixContainer}>
      <Typography.Text>{title}</Typography.Text>
      <Form.Item name={name}>
        <Radio.Group className={s.radio_group} disabled={isCompleted}>
          {options.map(({ id, title }) => (
            <Radio.Button value={id} key={id} className={cn(s.radio_button)}>
              {title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

const SurveysQuestionsMatrix: FC<SurveysQuestionsMatrixProps> = (props) => {
  const { id, no, is_required, title, options, rows, type } = props;
  const { answers } = useAppSelector((store) => store.surveySlice);
  const answer = (
    answers?.answer.find((answer) => answer.question_id === id) as MatrixAnswerType | undefined
  )?.question_answer.answer?.rows;

  return (
    <div className={s.matrixContainer}>
      <SurveyTitle level={5} number={no} required={is_required}>
        {title}
      </SurveyTitle>
      {rows.map((row) => (
        <SurveysQuestionsSubMatrix
          {...row}
          options={options}
          matrixId={id}
          answer={answer}
          type={type}
          key={row.id}
        />
      ))}
    </div>
  );
};

export default SurveysQuestionsMatrix;
