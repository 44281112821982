import { useEffect } from 'react';

import { useLazyGetSuggestionDecisionPerformersByIdQuery } from 'services/me/suggestion/suggestionApiService';
import { GetSuggestionDecisionPerformersByIdPropsType } from 'services/me/suggestion/suggestionApiService.types';

interface Props {
  decisionId: string;
  text?: string;
}

const DEFAULT_PAGE_CONFIG = {
  page: 1,
  size: 500,
};

const useGetData = (props: Props) => {
  const { decisionId, text } = props;

  const [getPerformers, { data: performers, isLoading, isFetching, isUninitialized }] =
    useLazyGetSuggestionDecisionPerformersByIdQuery();

  useEffect(() => {
    if (decisionId) {
      let requestBody: GetSuggestionDecisionPerformersByIdPropsType = {
        ...DEFAULT_PAGE_CONFIG,
        id: decisionId,
      };

      if (text) {
        requestBody.text = text;
      }

      getPerformers(requestBody);
    }
  }, [decisionId, getPerformers, text]);

  return {
    performers: performers?.items ?? [],
    isLoading: isLoading || isUninitialized,
    isFetching,
  };
};

export default useGetData;
