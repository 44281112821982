import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface DocumentsErrorModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DocumentsErrorModal: FC<DocumentsErrorModalProps> = (props) => {
  const { isOpen, handleClose } = props;

  return (
    <ModalDefault open={isOpen} onCancel={handleClose} closable>
      <ModalDefault.Icon name="error" type="warning" />
      <ModalDefault.Title>{t('common_error')}</ModalDefault.Title>
      <ModalDefault.Description>{t('common_error_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" onClick={handleClose} fullWidth size="large">
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DocumentsErrorModal;
