import { FC } from 'react';

import cn from 'classnames';

import { Icon, Typography } from 'gazprom-ui-lib';

import { convertToRub } from '../mySalary.utils';
import s from './MySalaryBadge.module.scss';

interface MySalaryBadgeProps {
  text: string;
  number: number;
  className: string;
  withInfo?: boolean;
  onClick?: (type: string) => void;
  active?: boolean;
}

const MySalaryBadge: FC<MySalaryBadgeProps> = (props) => {
  const { text, number, className, withInfo, active, onClick } = props;

  return (
    <div className={cn(s.container, s[className])}>
      <button
        onClick={() => {
          onClick && onClick(text);
        }}>
        {withInfo && <Icon name="info" size={16} className={s.icon} />}
        <Typography.Text size="14" className={s.text}>
          {text}
        </Typography.Text>
        <Typography.Text size="14" className={cn(s.text, s.textBold)}>
          {convertToRub(number)}
        </Typography.Text>
      </button>
      {active && (
        <button
          onClick={() => {
            onClick && onClick('');
          }}>
          <Icon name="delete" size={13} />
        </button>
      )}
    </div>
  );
};

export default MySalaryBadge;
