import { useNavigate } from 'react-router-dom';
import {
  getDocumentsStatementRequestRoute,
  getTopManagementByStageRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetKedoStatementsQuery } from 'services/kedo/kedoApiService';
import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { Card, Flex, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { TASK_CODE_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';
import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import ApplyStatement from '../apply-statement';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import usePersonalData from '../verification-form/usePersonalData.hook';
import WithBottomButton from '../with-bottom-button';

const ContactInfoForm = () => {
  const navigate = useNavigate();
  const { personalData } = usePersonalData();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData } = useGetRenewEmployeeFormQuery({
    myEmployeeId: selectedEmployee?.id!,
  });
  const [
    requestStatements,
    { data: requestStatementsData, isLoading: requestStatementsDataIsLoading },
  ] = useLazyGetKedoStatementsQuery();
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const statementId = requestStatementsData?.[0]?.id;
  const statementUrl = statementId && getDocumentsStatementRequestRoute(statementId);

  const requestStatement = () =>
    requestStatements({
      type: TASK_TYPE_ENUM.BPMN,
      code: TASK_CODE_ENUM.ZAYAVLENIE_NA_IZMENENIE_PERSONALNYKH_DANNYKH,
    });

  const navigateToNextStep = () =>
    navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.MILITARY));

  const handleNextStep = () => {
    updateFormData({
      id: renewEmployeeData?.id!,
      myEmployeeId: selectedEmployee?.id!,
      body: {
        formStep: TOP_MANAGEMENT_STAGE_ENUM.MILITARY,
      },
    }).then(
      handleRequest({
        onSuccess: navigateToNextStep,
      }),
    );
  };

  const { email, phone } = personalData || {};

  return (
    <WithLoader isLoading={!personalData}>
      <WithBottomButton
        button={
          <NextStepButton
            text={t('common_all_ok_continue')}
            onClick={handleNextStep}
            isLoading={updateFormDataIsLoading}
          />
        }>
        <Card>
          <Flex gap="16" vertical>
            <Title
              title={t('common_contacts')}
              subtitle={t('top_management_general_data_subtitle')}
            />
            <div>
              <DataPresenter title={t('common_phone')} text={phone} />
              <DataPresenter title={t('common_email')} text={email} />
            </div>
          </Flex>
        </Card>

        <ApplyStatement
          to={statementUrl ?? ''}
          onModalOpen={requestStatement}
          loading={requestStatementsDataIsLoading}
        />
      </WithBottomButton>
    </WithLoader>
  );
};

export default ContactInfoForm;
