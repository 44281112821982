import { FC } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';

import { Typography } from 'gazprom-ui-lib';

import { PersonalDataItemType } from '../profile-personal-data-details-general/profilePersonalDataDetailsGeneral.utils';
import s from './PersonalDataDetailsItem.module.scss';

const PersonalDataDetailsItem: FC<PersonalDataItemType> = (props) => {
  const { title, value, fullWidth, date } = props;

  const getFieldValue = () => {
    if (!value) return '-';

    if (value && date) {
      return dayjs(new Date(value)).format('DD.MM.YYYY');
    }

    return value;
  };

  return (
    <div className={cn(s.wrapper, { [s.fullWidth]: fullWidth })}>
      <Typography.Text size="12" type="secondary">
        {title}
      </Typography.Text>
      <Typography.Text weight="500" size="16">
        {getFieldValue()}
      </Typography.Text>
    </div>
  );
};

export default PersonalDataDetailsItem;
