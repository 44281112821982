import initialApiService from 'services/initialApiService';

import {
  GetBusinessTripStatusesPropsType,
  GetBusinessTripStatusesResponseType,
  GetBusinessTripTypesPropsType,
  GetBusinessTripTypesResponseType,
} from './workTourService.types';

import { createWorkTourUrl } from './utils';

const workTourApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessTripTypes: builder.query<
      GetBusinessTripTypesResponseType,
      GetBusinessTripTypesPropsType
    >({
      query: (type) => ({
        url: createWorkTourUrl(`/${type}`),
      }),
    }),
    getBusinessTripStatuses: builder.query<
      GetBusinessTripStatusesResponseType,
      GetBusinessTripStatusesPropsType
    >({
      query: () => ({
        url: createWorkTourUrl(`/status`),
      }),
      transformResponse: (statuses: GetBusinessTripStatusesResponseType) => {
        const kedoStatusesMap = new Map();
        statuses.forEach((status) => {
          if (kedoStatusesMap.has(status.name)) {
            const existedStatus = kedoStatusesMap.get(status.name);

            status.code = `${existedStatus.code}.${status.code}`;
            kedoStatusesMap.set(status.name, status);
          } else {
            kedoStatusesMap.set(status.name, status);
          }
        });
        return Array.from(kedoStatusesMap.values());
      },
    }),
  }),
});

export const { useGetBusinessTripStatusesQuery, useGetBusinessTripTypesQuery } = workTourApiService;
