import React, { useCallback, useMemo, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { HOME_NEWS_TAGS_ROUTE, getNewsWithIdRoute } from 'routes/home/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { newsStateSelector } from 'selectors/newsSlice.selector';
import { activeNewsTagsSelector } from 'selectors/secureStorageSlice.selector';
import {
  addPage,
  addRemoveLike,
  resetNews,
  resetPagination,
  resetPaginationTagsAndNews,
} from 'slices/newsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import NoData from 'components/no-data';
import UniversalLoader from 'components/universal-loader';
import Article from 'containers/article';
import useRequests from 'containers/news-content/useRequests.hook';
import OpeningFilterBar from 'containers/opening-filer-bar';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { ArticleFullType } from 'types/article.types';

import s from './NewsContent.module.scss';

const NewsContent = () => {
  const navigate = useNavigate();
  const loadMoreRef = useRef<boolean>(false);
  const dispatch = useAppDispatch();

  const newsState = useAppSelector(newsStateSelector);
  const activeNewsTagsFromState = useAppSelector(activeNewsTagsSelector);

  const [activeNewsTags, setActiveNewsTags] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_TAGS,
    [] as string[],
  );

  const handleLikeClick = (code: string) => {
    dispatch(addRemoveLike(code));
  };

  const renderArticle = (article: ArticleFullType) => (
    <button
      onClick={() => navigate(getNewsWithIdRoute(article.code), { state: { type: 'news' } })}
      className={s.article}
      key={`news__${article.id}`}>
      <Article article={article} handleLikeClick={handleLikeClick} />
    </button>
  );

  const bodyToDebounce = useMemo(() => {
    return { ...newsState, activeNewsTags };
  }, [activeNewsTags, newsState]);

  const { debouncedValue: debouncedRequestBody, isDebouncing: requestBodyIsDebouncing } =
    useDebounce(bodyToDebounce, 600);

  const { isFetching, isLoading, fullArticlesData, categoriesData } = useRequests({
    debouncedRequestBody,
    loadMoreRef,
  });

  const infiniteScrollIsFetchingProp = isFetching || requestBodyIsDebouncing;

  const isFetchMoreAvailable =
    !!newsState.newsToShow?.length &&
    newsState.page * newsState.size < (fullArticlesData?.totalCount ?? 0) &&
    loadMoreRef.current;

  const handleFetchMoreData = () => {
    loadMoreRef.current = false;
    dispatch(addPage());
  };
  const handleAddRemoveNewsActiveTags = useCallback(
    (newsCode: string) => {
      loadMoreRef.current = false;
      const isAlreadyActive = activeNewsTags.includes(newsCode);
      const tagsToSet = isAlreadyActive
        ? activeNewsTags.filter((tag) => tag !== newsCode)
        : activeNewsTags.concat([newsCode]);
      setActiveNewsTags(tagsToSet);
      dispatch(resetNews());
      dispatch(resetPagination());
    },
    [activeNewsTags, dispatch, setActiveNewsTags],
  );

  const handleShowAll = useCallback(() => {
    navigate(HOME_NEWS_TAGS_ROUTE, { state: { type: 'news' } });
  }, [navigate]);

  const handleTagsReset = useCallback(() => {
    dispatch(resetPaginationTagsAndNews());
    setActiveNewsTags([]);
  }, [dispatch, setActiveNewsTags]);

  const noData = infiniteScrollIsFetchingProp ? (
    <></>
  ) : (
    <NoData description={t('home_news_not_found_description')} header={t('home_news_not_found')} />
  );

  return (
    <>
      <OpeningFilterBar
        handleTagsReset={handleTagsReset}
        handleShowAll={handleShowAll}
        handleAddRemoveNewsActiveTags={handleAddRemoveNewsActiveTags}
        activeTags={activeNewsTagsFromState}
        tagsToRender={categoriesData?.items}
      />
      <WithLoader
        loader={
          <div className={s.loader}>
            <UniversalLoader />
          </div>
        }
        isLoading={isLoading}>
        <WithInfiniteScroll
          fetchMoreData={handleFetchMoreData}
          isFetching={infiniteScrollIsFetchingProp}
          isFetchMoreAvailable={isFetchMoreAvailable}>
          {newsState?.newsToShow?.length ? newsState.newsToShow.map(renderArticle) : noData}
        </WithInfiniteScroll>
      </WithLoader>
    </>
  );
};

export default NewsContent;
