import React, { FC, PropsWithChildren, ReactNode } from 'react';

import s from './WithBottomButton.module.scss';

interface WithBottomButtonProps extends PropsWithChildren {
  button: ReactNode;
}

const WithBottomButton: FC<WithBottomButtonProps> = (props) => {
  const { children, button } = props;

  return (
    <div className={s.container}>
      {children}
      {button}
    </div>
  );
};

export default WithBottomButton;
