import { ReactNode, useContext, useState } from 'react';

import { t } from 'i18next';

import { Button, Form } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';
import TextAreaModal from 'containers/textarea-modal';

import ApproveModal from '../approve-modal';
import CorrectionTaskModal from '../correction-task-modal';
import useSetModalFromLocationState from '../useSetModalFromLocationState.hook';
import { MODAL_TYPE } from '../utils';
import s from './ActionsWorkTour.module.scss';
import { ModalType } from './actionsWorkTour.utils';
import useActions from './useActions.hook';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
}

const ActionsWorkTour = (props: Props) => {
  const { handleCertificateCheck } = props;
  const assignment = useContext(AssignmentContext);
  const [modalType, setModalType] = useState<ModalType>('');

  const [form] = Form.useForm();
  const comment = Form.useWatch('comment', form);

  const handleModalClose = () => {
    setModalType('');
  };

  const handleOpenApproveModal = () => {
    setModalType(MODAL_TYPE.APPROVE);
  };

  const handleOpenCorrectionModal = () => {
    setModalType(MODAL_TYPE.CORRECTION);
  };

  const handleOpenRevisionModal = () => {
    setModalType(MODAL_TYPE.REVISION_COMMENT);
  };

  const { isLoading, handleCorrectionSubmit, handleApprove, handleFamiliarize } = useActions({
    handleOpenApproveModal,
    handleOpenCorrectionModal,
    handleCertificateCheck,
  });

  useSetModalFromLocationState({ setModalType });

  const areActionsAvailable = assignment?.status?.code === 'WAITING';

  let buttons: ReactNode;
  if (assignment && areActionsAvailable) {
    if (assignment.type === 'SIGN_EMPLOYEE') {
      buttons = (
        <Button
          onClick={handleFamiliarize}
          fullWidth
          size="large"
          leftIcon="ok"
          type="primary"
          loading={isLoading}>
          {t('common_familiarize')}
        </Button>
      );
    }

    if (assignment.type === 'APPROVAL_MODIFY') {
      buttons = (
        <>
          <Button onClick={handleApprove} fullWidth size="large" leftIcon="ok" loading={isLoading}>
            {t('common_approve')}
          </Button>
          <Button
            leftIcon="return"
            fullWidth
            size="large"
            ghost
            onClick={handleOpenRevisionModal}
            loading={isLoading}>
            {t('documents_send_to_correction')}
          </Button>
        </>
      );
    }
  }

  return (
    <>
      {buttons}
      <TextAreaModal
        formFromProps={form}
        actions={
          <div className={s.rejectModalActions}>
            <Button size="large" fullWidth ghost htmlType="reset" loading={isLoading}>
              {t('common_cancellation')}
            </Button>
            <Button
              size="large"
              fullWidth
              htmlType="submit"
              rightIcon="arrowRight"
              loading={isLoading}
              disabled={!comment}>
              {t('common_send')}
            </Button>
          </div>
        }
        isRightIconAvailable={false}
        modalHeaderTitle={t('documents_send_to_correction_label')}
        name="comment"
        title={t('documents_send_to_correction_reason')}
        maxLength={360}
        isModalOpen={modalType === MODAL_TYPE.REVISION_COMMENT}
        onSubmitHandler={handleCorrectionSubmit}
        onReset={handleModalClose}
        setIsModalOpen={handleModalClose}
      />
      <ApproveModal
        isOpen={modalType === MODAL_TYPE.FAMILIARIZED}
        handleClose={handleModalClose}
        isApprove={false}
      />
      <ApproveModal
        isOpen={modalType === MODAL_TYPE.APPROVE}
        handleClose={handleModalClose}
        isApprove
      />
      <CorrectionTaskModal
        isOpen={modalType === MODAL_TYPE.CORRECTION}
        handleClose={handleModalClose}
      />
    </>
  );
};

export default ActionsWorkTour;
