import React, { ReactElement, useState } from 'react';

import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/login/list';
import { Trans, t } from 'tools/i18n';
import { userManager } from 'utils/keycloak';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Button, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import APP_URLS from 'constants/appUrls';
import { CODES } from 'constants/errorCodes';

import s from './Error.module.scss';

const DEFAULT_BLOCK_TIME = '30';

const useErrorConfig = (): {
  showSupport?: boolean;
  text: string | ReactElement;
  title: string | ReactElement;
  actions?: ReactElement[];
  icon: TIconsLiteral;
  iconClassName?: 'warning' | 'danger';
} => {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setSelectedEmployee] = useFullSelectedEmployee();

  const code = searchParams.get('code');
  const blockTime = searchParams.get('blockTime');
  const isRegistation = searchParams.get('isRegistration');

  const onGoBack = async () => {
    setIsLoading(true);
    auth.signoutSilent().finally(() => {
      navigate(LOGIN_ROUTE);
      setIsLoading(false);
      setSelectedEmployee('');
    });
  };

  const retryButton = (
    <Button
      onClick={() => userManager.signupRedirect()}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="refresh"
      type="primary">
      {t('common_retry_again')}
    </Button>
  );

  const goBackButton = (
    <Button
      onClick={onGoBack}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="arrowLeft"
      ghost
      type="primary">
      {t('common_back')}
    </Button>
  );

  const goBackIfLoggedInButton = (
    <Button
      onClick={() => navigate(-2)}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="arrowLeft"
      ghost
      type="primary">
      {t('common_back')}
    </Button>
  );

  const retrySigninButton = (
    <Button
      onClick={async () => await auth.signinRedirect()}
      fullWidth
      size="large"
      loading={isLoading}
      leftIcon="refresh"
      type="primary">
      {t('common_retry')}
    </Button>
  );

  const updateButton = (
    <Button
      onClick={() => {
        window.location.href = '/';
      }}
      fullWidth
      size="large"
      leftIcon="refresh"
      type="primary">
      {t('common_update')}
    </Button>
  );

  const downloadUpdateButton = (
    <Button
      onClick={() => {
        const platform = Capacitor.getPlatform();
        Browser.open({
          url: platform === 'ios' ? APP_URLS.IOS : APP_URLS.ANDROID,
        });
      }}
      fullWidth
      size="large"
      leftIcon="download"
      type="primary">
      {t('common_download_update')}
    </Button>
  );

  switch (code) {
    case CODES.VIRTUAL_DEVICE: {
      return {
        title: t('errors_virtual_device'),
        text: t('errors_virtual_device_description'),
        actions: [],
        icon: 'accountClosed',
      };
    }
    case CODES.BROWSER_DEVICE: {
      return {
        title: t('errors_browser_device'),
        text: t('errors_browser_device_description'),
        actions: [],
        icon: 'accountClosed',
      };
    }
    case CODES.ROOTED_DEVICE: {
      return {
        title: t('errors_rooted_device'),
        text: t('errors_rooted_device_description'),
        actions: [],
        icon: 'accountClosed',
      };
    }
    case CODES.USER_NOT_CONFIRMED: {
      return {
        title: (
          <Trans i18nKey="errors_user_not_confirmed">
            <br />
          </Trans>
        ),
        text: t('errors_user_confirm_ecia'),
        actions: [goBackButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.ACCESS_RESTRICTED: {
      return {
        title: t('errors_access_restricted'),
        text: t('errors_access_restricted_description'),
        actions: [goBackButton],
        icon: 'accountClosed',
      };
    }
    case CODES.OUTDATED_WEB_VERSION: {
      return {
        title: t('errors_outdated_version_title'),
        text: t('errors_outdated_version_description'),
        icon: 'swap',
        iconClassName: 'danger',
        actions: [updateButton],
      };
    }
    case CODES.OUTDATED_VERSION: {
      return {
        title: t('errors_outdated_version_title'),
        text: t('errors_outdated_version_description'),
        icon: 'swap',
        iconClassName: 'danger',
        actions: [downloadUpdateButton],
      };
    }
    case CODES.USER_NOT_FOUND: {
      return {
        title: t('errors_user_not_found'),
        text: t('errors_get_help_from_hr'),
        actions: [goBackButton],
        icon: 'notFound',
      };
    }
    case CODES.INCORRECT_EMAIL: {
      return {
        title: t('errors_email_not_found'),
        text: (
          <Trans i18nKey="errors_incorrect_email">
            <br />
          </Trans>
        ),
        actions: [goBackButton],
        icon: 'unsubscribe',
      };
    }
    case CODES.TECHNICAL_ISSUES_BACK: {
      return {
        title: t('errors_service_unavailable'),
        text: t('errors_service_unavailable_description'),
        actions: [goBackButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.TECHNICAL_ISSUES_RETRY: {
      return {
        title: t('errors_service_unavailable'),
        text: t('errors_service_unavailable_description'),
        actions: [retrySigninButton],
        icon: 'verification',
        showSupport: true,
      };
    }
    case CODES.USER_HAS_NO_ACCESS_TO_APP: {
      return {
        title: t('errors_content_do_not_found'),
        text: t('errors_content_do_not_found_description'),
        actions: [auth.isAuthenticated ? goBackButton : goBackIfLoggedInButton],
        icon: 'attention',
        showSupport: true,
      };
    }
    case CODES.INCORRECT_DATA: {
      return {
        title: t('errors_incorrect_data'),
        text: (
          <>
            {t('errors_retry_or_ask_hr')}
            <Typography.Title level={5} className={s.secondText}>
              <Trans i18nKey="errors_already_have_an_account">
                <button onClick={() => auth.signinRedirect()} className={s.loginButton}></button>
              </Trans>
            </Typography.Title>
          </>
        ),
        actions: [retryButton],
        icon: 'attention',
        showSupport: true,
      };
    }
    case CODES.RETRY_REGISTRATION_LIMIT: {
      return {
        title: t(
          isRegistation === 'true'
            ? t('errors_registration_limit_title')
            : t('errors_authorization_limit_title'),
          { time: blockTime || DEFAULT_BLOCK_TIME },
        ),
        text: (
          <>
            {t('errors_register_retry_limit_subtitle')}
            <Typography.Title level={5} className={s.secondText}>
              <Trans i18nKey="errors_already_have_an_account">
                <button onClick={() => auth.signinRedirect()} className={s.loginButton}></button>
              </Trans>
            </Typography.Title>
          </>
        ),
        actions: [],
        icon: 'accountClosed',
        iconClassName: 'danger',
        showSupport: true,
      };
    }
    case CODES.ALREADY_REGISTERED: {
      return {
        title: t('errors_already_registered'),
        text: <>{t('errors_already_registered_description')}</>,
        actions: [
          <Button fullWidth onClick={() => auth.signinRedirect()} key="enter__button">
            {t('common_enter')}
          </Button>,
        ],
        icon: 'accountCircle',
        iconClassName: 'warning',
        showSupport: true,
      };
    }
    default: {
      return {
        title: t('errors_unknown_error'),
        text: t('errors_service_unavailable_description'),
        icon: 'attention',
        showSupport: true,
        actions: [goBackButton],
      };
    }
  }
};

export default useErrorConfig;
