import { BarConfig, Datum, PlotEvent } from '@ant-design/charts';

import { PayslipDataType, SalaryYearDataType } from 'types/mySalary.types';

import { monthMap } from '../../mySalary.utils';

export const createConfig = (
  data: BarConfig['data'],
  maxLimit: string,
  setOnClickData: (
    data: {
      data: SalaryYearDataType;
    }[],
  ) => void,
): BarConfig => {
  return {
    data,
    xField: 'value',
    yField: 'month',
    isStack: true,
    seriesField: 'type',
    maxBarWidth: 14,
    minBarWidth: 14,
    animation: false,
    xAxis: {
      label: {
        formatter(text: string) {
          const currentTitle = Number(text).toFixed(0);
          const roundedValue = Math.round(Number(currentTitle) / 1000) * 1000;
          return roundedValue.toString();
        },
      },
      maxLimit,
      tickCount: 3,
    },
    barStyle: (datum: Datum) => {
      if (datum.type === 'empty') {
        return {
          radius: [6, 6, 6, 6],
          fill: '#00000040',
          fillOpacity: 1,
        };
      }
      if (datum.type === 'accruals') {
        return {
          radius: [0, 0, 6, 6],
          fill: '#17C1B9',
          fillOpacity: 1,
        };
      }

      return { radius: [6, 6, 0, 0], fill: '#21587A', fillOpacity: 1 };
    },
    interactions: [
      {
        type: 'active-region',
        enable: true,
      },
    ],
    legend: false,
    onEvent: (chart, event: PlotEvent) => {
      if (event.type === 'click') {
        const clickedData = chart.chart.controllers.find((data) => data.name === 'tooltip') as
          | { items: { data: SalaryYearDataType }[] }
          | undefined;
        clickedData && setOnClickData(clickedData.items);
      }
    },
  };
};

export const mapYearData = (data: PayslipDataType[]) => {
  let maxValue = 0;

  const mappedData = Object.values(monthMap)
    .reduce((acc, currentMonth) => {
      const monthName = currentMonth.short;
      const monthKey = currentMonth.key;

      const dataByMonth = data
        .find((element) => element.month === monthKey)
        ?.components.reduce(
          (acc, currentComponent) => {
            const section = currentComponent.component.section;
            if (section === 'payouts') {
              acc.accrualsSum += currentComponent.amount;
            }
            if (section === 'deductions') {
              acc.deductionsSum += currentComponent.amount;
            }

            return acc;
          },
          { accrualsSum: 0, deductionsSum: 0 },
        );

      const sumValue = (dataByMonth?.accrualsSum ?? 0) + (dataByMonth?.deductionsSum ?? 0);
      const accrualsData = {
        monthKey: monthKey,
        month: monthName,
        value: dataByMonth?.accrualsSum ?? 0,
        type: 'accruals',
      };

      const deductionsData = {
        monthKey: monthKey,
        month: monthName,
        value: dataByMonth?.deductionsSum ?? 0,
        type: 'deductions',
        sumValue,
      };

      if (accrualsData.value === 0 && deductionsData.value === 0) {
        const empty = {
          monthKey: monthKey,
          month: monthName,
          value: 0,
          type: 'empty',
        };
        acc.push(empty);
      } else {
        acc.push(accrualsData, deductionsData);
        maxValue = maxValue > sumValue ? maxValue : sumValue;
      }

      return acc;
    }, [] as SalaryYearDataType[])
    .map((element) =>
      element.type === 'empty' ? { ...element, value: maxValue || 100 } : element,
    );

  return {
    maxValue: maxValue || 100,
    data: mappedData,
  };
};
