import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

//TODO - rework when StatusTag component ready
export const getSuggestionTagConfig = (suggestion?: { state: string; name: string }) => {
  let icon: TIconsLiteral = 'time';
  let type: TagType = 'outline';

  switch (suggestion?.state) {
    case 'done': {
      icon = 'ok';
      type = 'success';
      break;
    }
    case 'error': {
      icon = 'delete';
      type = 'danger';
      break;
    }
    case 'pending': {
      icon = 'time';
      type = 'warning';
      break;
    }
  }

  return {
    icon,
    children: suggestion?.name,
    type,
  };
};
