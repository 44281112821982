import React, { useEffect } from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetArticleByIdQuery,
  useLazyGetFullArticlesQuery,
} from 'services/article/articleApiService';

import { Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';

import s from './MoreAboutCompany.module.scss';

const MoreAboutCompany = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const { isLoading: mainIsLoading, data: mainData } = useGetArticleByIdQuery('about-gazstroiprom');
  const { isLoading: workTogetherIsLoading, data: workTogetherData } =
    useGetArticleByIdQuery('about-gazstroiprom-2');
  const [getFinalArticle, { isLoading: finalArticleIsLoading, data: finalData }] =
    useLazyGetFullArticlesQuery();

  useEffect(() => {
    if (selectedEmployee) {
      getFinalArticle({
        page: 1,
        size: 1,
        employerId: selectedEmployee?.employer?.id,
        type: [ARTICLE_TYPE_ENUM.ARTICLE],
        categoryName: 'О компании',
      });
    }
  }, [getFinalArticle, selectedEmployee]);

  const headerChildren = (
    <>
      <Header.GoBack to={GUIDE_ROUTE} />
      <Header.Label label={t('common_about_company')} />
    </>
  );

  const finalArticle = finalData?.items?.[0];

  const isLoading = workTogetherIsLoading || mainIsLoading || finalArticleIsLoading;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false}>
              <Cover alt="главная картинка" src={mainData?.cover?.link} className={s.cover} />
              <div className={s.mainContent}>
                <Typography.Title level={4}>{mainData?.title}</Typography.Title>
                <Typography.Text size="14">{mainData?.text}</Typography.Text>
              </div>
            </Card>
            <Card withPadding={false}>
              <Cover
                alt="главная картинка"
                src={workTogetherData?.cover?.link}
                className={s.cover}
              />
              <Typography.Title level={4}>{workTogetherData?.title}</Typography.Title>
              <Typography.Text size="14">
                <span
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(workTogetherData?.text!) }}
                />
              </Typography.Text>
            </Card>
            {finalArticle && (
              <Card withPadding={false}>
                <Cover alt="education cover" src={finalArticle?.cover?.link} className={s.cover} />
                {finalArticle.title && (
                  <Typography.Title level={4} className={s.title}>
                    {finalArticle.title}
                  </Typography.Title>
                )}
                {finalArticle.text && (
                  <Typography.Text size="14">
                    <span
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(finalArticle?.text) }}
                    />
                  </Typography.Text>
                )}
              </Card>
            )}
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreAboutCompany;
