import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CancelBusinessTripsPropsType,
  CancelBusinessTripsResponseType,
  GetBusinessTripByIdPropsType,
  GetBusinessTripByIdResponseType,
  GetBusinessTripsPropsType,
  GetBusinessTripsResponseType,
  PrepareBusinessTripPropsType,
  PrepareBusinessTripResponseType,
  SendBusinessTripPropsType,
  SendBusinessTripResponseType,
  UpdateBusinessTripsPropsType,
  UpdateBusinessTripsResponseType,
} from './workTourApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createWorkTourUrl } from './utils';

const workTourApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessTrips: builder.query<GetBusinessTripsResponseType, GetBusinessTripsPropsType>({
      query: (params) => ({
        url: createWorkTourUrl(`${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_BUSINESS_TRIPS],
    }),
    getBusinessTripById: builder.query<
      GetBusinessTripByIdResponseType,
      GetBusinessTripByIdPropsType
    >({
      query: ({ id, ...params }) => ({
        url: createWorkTourUrl(`/${id}${prepareQueryParams(params)}`),
      }),
      providesTags: (_a, _b, { id }) => [{ type: SERVICE_TAGS.GET_BUSINESS_TRIPS, id }],
    }),
    prepareBusinessTrip: builder.query<
      PrepareBusinessTripResponseType,
      PrepareBusinessTripPropsType
    >({
      query: ({ myEmployeeId, ...body }) => ({
        url: createWorkTourUrl(`/prepare?myEmployeeId=${myEmployeeId}`),
        method: 'POST',
        body,
      }),
    }),
    sendBusinessTrip: builder.mutation<SendBusinessTripResponseType, SendBusinessTripPropsType>({
      query: ({ myEmployeeId, id, ...body }) => ({
        url: createWorkTourUrl(`/${id}/send?myEmployeeId=${myEmployeeId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_BUSINESS_TRIPS],
    }),
    cancelBusinessTrip: builder.mutation<
      CancelBusinessTripsResponseType,
      CancelBusinessTripsPropsType
    >({
      query: ({ id, myEmployeeId, ...body }) => ({
        url: createWorkTourUrl(`/cancel/${id}?myEmployeeId=${myEmployeeId}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_BUSINESS_TRIPS },
        { type: SERVICE_TAGS.GET_BUSINESS_TRIP_BY_ID, id },
      ],
    }),
    updateBusinessTrip: builder.mutation<
      UpdateBusinessTripsResponseType,
      UpdateBusinessTripsPropsType
    >({
      query: ({ id, myEmployeeId, ...body }) => ({
        url: createWorkTourUrl(`/change/${id}?myEmployeeId=${myEmployeeId}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_BUSINESS_TRIPS },
        { type: SERVICE_TAGS.GET_BUSINESS_TRIP_BY_ID, id },
      ],
    }),
  }),
});

export const {
  useUpdateBusinessTripMutation,
  useSendBusinessTripMutation,
  useLazyPrepareBusinessTripQuery,
  useCancelBusinessTripMutation,
  useGetBusinessTripsQuery,
  useGetBusinessTripByIdQuery,
} = workTourApiService;
