import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_LIST_ROUTE } from 'routes/documents/list';
import { PROFILE_ROUTE } from 'routes/profile/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGenerateRenewFilesMutation,
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';
import { GenerateRenewFilesResponseType } from 'services/top-management/topManagementApiService.types';
import { setActiveKedoTask } from 'slices/kedo';
import { useAppDispatch } from 'store';

import { Form, handleRequest } from 'gazprom-ui-lib';

import { KEDO_COPY_FILE_TYPES } from 'types/documents.types';
import { FamilyType } from 'types/topManagement.types';

import { formatDatesInObject, parseBySeparator } from '../../documentsTopManagement.utils';
import { FAMILY_FORM_ENUM } from './familyForm.utils';

const useFamilyForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const criminal = Form.useWatch(FAMILY_FORM_ENUM.CRIMINAL, form);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData, isLoading: renewEmployeeDataIsLoading } =
    useGetRenewEmployeeFormQuery({ myEmployeeId: selectedEmployee?.id! });
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();
  const [generateFiles, { isLoading: generateFilesIsLoading }] = useGenerateRenewFilesMutation();

  const { family, criminalDetail, criminal: initialCriminal } = renewEmployeeData || {};

  const [initialFamily, setInitialFamily] = useState<Map<number, FamilyType & { id: number }>>(
    new Map(),
  );

  const initialFamilySections = Array.from(initialFamily.values());

  const removeInitialSection = (id: number) => () =>
    setInitialFamily((prevInitialSection) => {
      const newCustomSection = new Map(prevInitialSection);
      newCustomSection.delete(id);
      return newCustomSection;
    });

  const nextStep = (data: GenerateRenewFilesResponseType) => {
    const { id, files } = data;
    const kedoTask = {
      taskId: id,
      filesToSign: files,
    };
    const navigateParams = {
      state: {
        cameFrom: PROFILE_ROUTE,
        modalType: 'created',
        type: 'confirmAssignment',
        taskType: KEDO_COPY_FILE_TYPES.RENEW_EMPLOYEE,
      },
    };

    dispatch(setActiveKedoTask(kedoTask));
    navigate(DOCUMENTS_SIGN_LIST_ROUTE, navigateParams);
  };

  const handleForm = (formData: Record<string, string>) => {
    const body = {
      family: parseBySeparator(formatDatesInObject(formData)) as unknown as FamilyType[],
      criminal: form.getFieldValue(FAMILY_FORM_ENUM.CRIMINAL),
      criminalDetail: form.getFieldValue(FAMILY_FORM_ENUM.CRIMINAL_DETAILS),
    };

    const handleGenerateFiles = () => {
      generateFiles({ id: renewEmployeeData?.id!, myEmployeeId: selectedEmployee?.id! }).then(
        handleRequest({
          onSuccess: (data) => nextStep(data!),
        }),
      );
    };

    updateFormData({ id: renewEmployeeData?.id!, myEmployeeId: selectedEmployee?.id!, body }).then(
      handleRequest({
        onSuccess: handleGenerateFiles,
      }),
    );
  };

  useEffect(() => {
    if (family) {
      const familyMap = new Map<number, FamilyType & { id: number }>();
      family?.forEach((familyData, index) => {
        const id = index + 9999;
        familyMap.set(id, { ...familyData, id });
      });

      setInitialFamily(familyMap);
    }
  }, [family]);

  return {
    form,
    initialFamily: initialFamilySections,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    generateFilesIsLoading,
    criminal,
    criminalDetail,
    initialCriminal,
    handleForm,
    removeInitialSection,
  };
};

export default useFamilyForm;
