import { useNavigate } from 'react-router-dom';
import { GUIDE_SOCIAL_POLICY_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { setActiveKedoFormTask } from 'slices/kedo';
import { useAppDispatch } from 'store';

import { Button } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from 'pages/documents/documents-social-policy/containers/page-description/PageDescription.module.scss';

const PageDescription = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRequest = () => {
    dispatch(setActiveKedoFormTask(null));
    navigate(GUIDE_SOCIAL_POLICY_ROUTE);
  };

  return (
    <Card>
      <Card.Default
        title={t('common_social_policy')}
        description={t('guide_social_policy_select_category')}
        icon="social"
        iconPadding="8"
      />
      <Button leftIcon="plus" fullWidth size="large" onClick={handleRequest} className={s.button}>
        {t('guide_social_policy_apply')}
      </Button>
    </Card>
  );
};

export default PageDescription;
