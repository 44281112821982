import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Card } from 'gazprom-ui-lib';

import Attribute, {
  AttributeCompoundedComponents,
  getAttributeComponentType,
} from 'containers/attribute';
import { AttributeDefaultProps } from 'containers/attribute/attribute.utils';

import { SuggestionFileType, SuggestionType } from 'types/suggestion.types';

import { SUGGESTION_ATTRIBUTES } from '../../moreSuggestionCreate.utils';
import { getAttributesFilesToSet } from './attributes.utils';

interface AttributesProps {
  suggestion?: SuggestionType;
}

const Attributes: FC<AttributesProps> = (props) => {
  const { suggestion } = props;

  return (
    <Card>
      {SUGGESTION_ATTRIBUTES.map((attr) => {
        const type = getAttributeComponentType(attr.type) as keyof AttributeCompoundedComponents;

        const Component = Attribute[type] as AttributeCompoundedComponents;

        const defaultProps: Partial<AttributeDefaultProps> = { ...attr, isPublish: true };

        if (suggestion) {
          if (attr.type === 'MULTIFILE') {
            const attributesFiles = suggestion[
              attr.code as keyof typeof suggestion
            ] as SuggestionFileType[];

            const attributeFilesToSet = getAttributesFilesToSet(attributesFiles, attr.id);

            defaultProps.value = attributesFiles.map(({ fileName }) => fileName).join(',');
            defaultProps.files = attributeFilesToSet;
          } else if (attr.type === 'BOOLEAN') {
            const attributeValue = suggestion[attr.code as keyof typeof suggestion] as boolean;

            defaultProps.value = attributeValue ? t('common_yes') : '';
          } else {
            defaultProps.value = suggestion[attr.code as keyof typeof suggestion] as string;
          }
        }

        return <Component key={attr.id} {...defaultProps} />;
      })}
    </Card>
  );
};

export default Attributes;
