import { useNavigate } from 'react-router-dom';
import { getTopManagementByStageRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { Card, Checkbox, Flex, Form, Input, Typography, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { OtherInfoType, TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import { formatDatesInObject } from '../../documentsTopManagement.utils';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import AdditionalFinance from './containers/additional-finance';
import FsppObligations from './containers/fspp-obligations';
import {
  OTHER_INFORMATION_FORM_ENUM,
  getAdditionalFinanceData,
  getFsppObligationsData,
} from './otherInfoForm.utils';

const OtherInfoForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const finObligations = Form.useWatch(OTHER_INFORMATION_FORM_ENUM.FINANCIAL_OBLIGATIONS, form);
  const other = Form.useWatch(OTHER_INFORMATION_FORM_ENUM.OTHER_LEGAL_ENTITY, form);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData, isLoading: renewEmployeeDataIsLoading } =
    useGetRenewEmployeeFormQuery({ myEmployeeId: selectedEmployee?.id! });
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const nextStep = () =>
    navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.LANGUAGES));

  const {
    additionalFinance,
    additionalFinanceType,
    financialObligations,
    financialObligationsType,
    fsppObligationsData,
    othersLegalEntity,
    othersLegalEntityData,
    fsppObligations: fsppObligationsInitial,
  } = renewEmployeeData?.otherInfo || {};

  const handleSubmit = (formData: OtherInfoType) => {
    const {
      financialObligations,
      financialObligationsType,
      othersLegalEntity,
      othersLegalEntityData,
      fsppObligations,
      additionalFinance,
    } = formData;
    const unTypedData = formatDatesInObject(formData as unknown as Record<string, string>);

    const body = {
      otherInfo: {
        financialObligations,
        financialObligationsType,
        othersLegalEntity,
        othersLegalEntityData: [othersLegalEntityData],
        fsppObligations,
        fsppObligationsData: getFsppObligationsData(unTypedData),
        additionalFinance,
        additionalFinanceType: getAdditionalFinanceData(unTypedData),
      } as unknown as OtherInfoType,
    };

    updateFormData({ id: renewEmployeeData?.id!, myEmployeeId: selectedEmployee?.id!, body }).then(
      handleRequest({
        onSuccess: nextStep,
      }),
    );
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form form={form} onFinish={handleSubmit}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_other')}
              subtitle={t('top_management_international_description')}
            />
          </Card>
          <Card>
            <Flex gap="24" vertical>
              <Form.Item
                name={OTHER_INFORMATION_FORM_ENUM.FINANCIAL_OBLIGATIONS}
                valuePropName="checked"
                initialValue={financialObligations}
                label={
                  <Typography.Title level={5}>
                    {t('top_management_other_financial_type_title')}
                  </Typography.Title>
                }>
                <Checkbox>{t('common_yes_have')}</Checkbox>
              </Form.Item>
              {finObligations && (
                <Form.Item
                  name={OTHER_INFORMATION_FORM_ENUM.FINANCIAL_OBLIGATIONS_TYPE}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_other_finansial_type')}
                    </Typography.Title>
                  }
                  initialValue={financialObligationsType}
                  rules={[formRules.required]}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
              )}
            </Flex>
          </Card>
          <Card>
            <FsppObligations
              fsppObligationsData={fsppObligationsData}
              fsppObligationsInitial={fsppObligationsInitial}
            />
          </Card>
          <Card>
            <AdditionalFinance
              additionalFinance={additionalFinance}
              additionalFinanceType={additionalFinanceType}
            />
          </Card>
          <Card>
            <Flex gap="24" vertical>
              <Form.Item
                name={OTHER_INFORMATION_FORM_ENUM.OTHER_LEGAL_ENTITY}
                valuePropName="checked"
                initialValue={othersLegalEntity}
                label={
                  <Typography.Title level={5}>
                    {t('top_management_other_other_title')}
                  </Typography.Title>
                }>
                <Checkbox>{t('common_yes')}</Checkbox>
              </Form.Item>
              {other && (
                <Form.Item
                  name={OTHER_INFORMATION_FORM_ENUM.OTHER_LEGAL_ENTITY_DATA}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_other_participation_type')}
                    </Typography.Title>
                  }
                  initialValue={othersLegalEntityData?.[0]}
                  rules={[formRules.required]}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
              )}
            </Flex>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default OtherInfoForm;
