import Schedule from 'pages/schedule';

import { RouteConfig } from '../routes';
import { SCHEDULE_ROUTE } from './list';

export const scheduleRoutes: RouteConfig[] = [
  {
    path: SCHEDULE_ROUTE,
    element: <Schedule />,
  },
];
