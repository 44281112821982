import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import s from './WithInfiniteScroll.module.scss';

interface WithInfiniteScrollProps extends PropsWithChildren {
  className?: string;
  fetchMoreData: () => Promise<unknown> | void;
  isFetching: boolean;
  isFetchMoreAvailable: boolean;
}

const WithInfiniteScroll: FC<WithInfiniteScrollProps> = (props) => {
  const { children, className, fetchMoreData, isFetchMoreAvailable, isFetching } = props;

  const sentinelRef = useRef<HTMLDivElement>(null);

  const intersectionObserverCallback = useCallback(
    (entries: { isIntersecting: boolean }[]) => {
      if (isFetchMoreAvailable && !isFetching) {
        entries.forEach((entry: { isIntersecting: boolean }) => {
          if (entry.isIntersecting) {
            fetchMoreData();
          }
        });
      }
    },
    [fetchMoreData, isFetchMoreAvailable, isFetching],
  );

  useEffect(() => {
    const currentRef = sentinelRef?.current;
    const observer = new IntersectionObserver(intersectionObserverCallback, { threshold: 1 });

    observer.observe(currentRef!);

    return () => {
      observer.unobserve(currentRef!);
    };
  }, [fetchMoreData, intersectionObserverCallback]);

  return (
    <div className={className}>
      {children}
      <div ref={sentinelRef} />
      {isFetching && (
        <div className={s.loaderWrapper}>
          <div className={s.loader} />
        </div>
      )}
    </div>
  );
};

export default WithInfiniteScroll;
