import { ReactNode } from 'react';

import {
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

import { CardsSliderType } from 'components/cards-slider/cardsSlider.utils';
import { AssignmentFilesProps } from 'containers/document-assignment/containers/files';

import { AssignmentType } from 'types/assignment.types';
import {
  KEDO_COPY_FILE_TYPES,
  KedoStatusType,
  KedoTaskFile,
  TASK_STATUS_ENUM,
} from 'types/documents.types';
import { FileType } from 'types/file.types';

export const getFilesKeys = (files: FileType[]) => files?.map((file) => file.fileKey);

export const CONTINUE_CERTIFICATE_CREATION = 'CONTINUE_CERTIFICATE_CREATION';

export const ALL_CATEGORIES = {
  id: 'ALL',
  name: t('common_all'),
};

export const mapStatus = (status: string) => {
  if (status === TASK_STATUS_ENUM.ALL) {
    return {
      name: t('common_all'),
    };
  } else
    return {
      name: t(`documents_status_${status.toLowerCase()}_label`),
      description: t(`documents_status_${status.toLowerCase()}_description`),
    };
};
export type DefaultTypographyTypes = 'warning' | 'danger' | 'success';

export const getKedoTagConfig = (status?: {
  title: string;
  description: string | null;
  state: string;
}): { type: TagType; children: ReactNode; icon: TIconsLiteral; description: string } => {
  let icon: TIconsLiteral = 'time';
  let type: TagType = 'outline';

  switch (status?.state) {
    case 'done': {
      icon = 'ok';
      type = 'success';
      break;
    }
    case 'error': {
      icon = 'delete';
      type = 'danger';
      break;
    }
    case 'pending': {
      icon = 'time';
      type = 'warning';
      break;
    }
    case 'continue': {
      icon = 'refresh';
      type = 'additional11';
      break;
    }
    case 'draft': {
      icon = 'edit';
      type = 'neutral';
      break;
    }
  }

  return {
    icon,
    children: status?.title ?? '',
    type,
    description: status?.description ?? '',
  };
};

export const DEFAULT_ALL_STATUS: KedoStatusType = {
  description: '',
  isFilter: true,
  nameOwner: '',
  state: '',
  type: '',
  code: TASK_STATUS_ENUM.ALL,
  name: mapStatus(TASK_STATUS_ENUM.ALL).name,
};

export enum DELIVER_OPTIONS_VALUE {
  MAIL = 'documents_certificate_value_mail',
  EMAIL = 'documents_certificate_value_email',
  PERSONALLY = 'documents_certificate_value_personally',
  ELECTRONIC = 'documents_certificate_value_electronic',
}

export const INNER_LINKS: (CardsSliderType & { isSignRequired: boolean })[] = [
  {
    to: DOCUMENTS_INQUIRIES_ROUTE,
    title: t('documents_inquiries'),
    description: t('documents_inquiries_card_description'),
    icon: 'referenceFilled',
    isSignRequired: false,
  },
  {
    to: DOCUMENTS_STATEMENTS_ROUTE,
    title: t('documents_statements'),
    description: t('documents_statements_card_description'),
    icon: 'documentSignFilled',
    isSignRequired: true,
  },
  {
    to: DOCUMENTS_SOCIAL_POLICY_ROUTE,
    title: t('common_social_programs'),
    description: t('documents_social_programs_description'),
    icon: 'socialFilled',
    isSignRequired: true,
  },
  {
    to: DOCUMENTS_BUSINESS_TRIP_ROUTE,
    title: t('documents_business_trips'),
    description: t('documents_business_trips_card_description'),
    icon: 'plane',
    isSignRequired: true,
  },
];

export const getFileConfig = (assignment: AssignmentType): AssignmentFilesProps => {
  const filesToShow = (assignment?.files as KedoTaskFile[]) ?? [];

  const { id: kedoTaskId } = assignment?.kedoTask ?? {};
  const { files: kedoReviewFiles, id: kedoReviewTaskId } = assignment?.kedoReviewTask ?? {};
  const { id: kedoDocumentId } = assignment?.kedoDocument ?? {};
  const { id: workTourId } = assignment?.workTour || {};

  switch (assignment.entityType) {
    case 'DOCUMENT':
      return {
        assignmentId: assignment?.id,
        files: filesToShow,
        id: kedoDocumentId,
        type: KEDO_COPY_FILE_TYPES.KEDO_DOCUMENT,
      };
    case 'REVIEW_TASK':
      return {
        assignmentId: assignment?.id,
        files: kedoReviewFiles,
        id: kedoReviewTaskId,
        type: KEDO_COPY_FILE_TYPES.KEDO_REVIEW_TASK,
      };
    case 'WORK_TOUR':
      let files = filesToShow;

      return {
        assignmentId: assignment?.id,
        files,
        id: workTourId,
        type: KEDO_COPY_FILE_TYPES.WORK_TOUR,
      };
    default:
      return {
        assignmentId: assignment?.id,
        files: filesToShow,
        id: kedoTaskId,
        type: KEDO_COPY_FILE_TYPES.KEDO_TASK,
      };
  }
};
