import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Card, Flex, ModalDefault, Typography } from 'gazprom-ui-lib';

import s from './ApplyStatement.module.scss';

interface ApplyStatementProps {
  to: string;
  loading?: boolean;
  onModalOpen?: () => void;
}

const ApplyStatement: FC<ApplyStatementProps> = (props) => {
  const { to, loading, onModalOpen } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen((prevState) => !prevState);
    onModalOpen && onModalOpen();
  };
  const handleNavigate = () => navigate(to);

  return (
    <>
      <Card>
        <Flex gap="4" align="center" vertical className={s.container}>
          <Typography.Text size="14" weight="500" type="secondary">
            {t('top_management_apply_text')}
          </Typography.Text>
          <button onClick={handleOpenModal} type="button">
            <Typography.Text size="14" weight="500" type="primary">
              {t('top_management_apply_link')}
            </Typography.Text>
          </button>
        </Flex>
      </Card>
      <ModalDefault open={isModalOpen} onCancel={handleOpenModal}>
        <ModalDefault.Icon name="documentSign" type="primary" />
        <ModalDefault.Title>{t('documents_apply')}</ModalDefault.Title>
        <ModalDefault.Description>{t('top_management_apply_description')}</ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            type="primary"
            onClick={handleNavigate}
            fullWidth
            size="large"
            rightIcon="arrowRight"
            loading={loading}>
            {t('top_management_apply_statement')}
          </Button>
          <Button type="secondary" onClick={handleOpenModal} fullWidth size="large">
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default ApplyStatement;
