import { Dispatch, SetStateAction, createContext } from 'react';

import { EmployeeType } from 'types/employee.types';
import { FeedbackCategory } from 'types/feedback.types';

export type ConfigStepType = { current: CONFIG_STATE; previous?: CONFIG_STATE };
export type FeedbackTargetType = EmployeeType | null;
export type FeedbackCategoryType = FeedbackCategory | null;

export enum CONFIG_STATE {
  INITIAL,
  ADD_FEEDBACK_CATEGORY,
  ADD_FEEDBACK_TEXT,
  ADD_FEEDBACK_CONFIRM,

  STAND_ALONE_SELECT_FEEDBACK_TARGET,
  STAND_ALONE_SELECT_FEEDBACK_CATEGORY,
  STAND_ALONE_FEEDBACK_TEXT,
}

export const FEEDBACK_CATEGORIES_LIST: FeedbackCategory[] = [
  { id: '1', name: 'Feedback category 1' },
  { id: '2', name: 'Feedback category 2' },
  { id: '3', name: 'Feedback category 3' },
  { id: '4', name: 'Feedback category 4' },
  { id: '5', name: 'Feedback category 5' },
];

export type FeedbackCreateContextType = {
  feedbackTarget: FeedbackTargetType;
  feedbackCategory: FeedbackCategoryType;
  feedbackText: string;
  configStep: ConfigStepType;
  setFeedbackTarget: Dispatch<SetStateAction<FeedbackTargetType>> | null;
  setFeedbackCategory: Dispatch<SetStateAction<FeedbackCategoryType>> | null;
  setFeedbackText: Dispatch<SetStateAction<string>> | null;
  setConfigStep: Dispatch<SetStateAction<ConfigStepType>> | null;
};
export const FeedbackCreateContext = createContext<FeedbackCreateContextType>({
  feedbackTarget: null,
  feedbackCategory: null,
  feedbackText: '',
  configStep: { current: CONFIG_STATE.INITIAL },
  setFeedbackCategory: null,
  setFeedbackTarget: null,
  setConfigStep: null,
  setFeedbackText: null,
});
