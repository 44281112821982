import React, { Dispatch, FC, SetStateAction } from 'react';

import cn from 'classnames';

import { PersonalDataCategoryType } from '../profilePersonalDataDetails.utils';
import s from './ProfilePersonalDataCategories.module.scss';

type ProfilePersonalDataCategoriesProps = {
  categories: PersonalDataCategoryType[];
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
};

const ProfilePersonalDataCategories: FC<ProfilePersonalDataCategoriesProps> = (props) => {
  const { categories, selectedCategory, setSelectedCategory } = props;

  return (
    <div className={s.wrapper}>
      {categories
        .filter((category) => category.visible)
        .map((category) => (
          <button
            className={cn(
              s.categoryButton,
              selectedCategory === category.value ? s.selected : s.default,
            )}
            key={category.value}
            onClick={() => setSelectedCategory(category.value)}>
            {category.title}
          </button>
        ))}
    </div>
  );
};

export default ProfilePersonalDataCategories;
