import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AvailableResult } from 'capacitor-native-biometric';
import cn from 'classnames';
import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';
import useFirebase from 'utils/useFirebase.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Typography } from 'gazprom-ui-lib';

import MobileNumberKeyboard from 'components/mobile-number-keyboard';
import Passcode from 'components/passcode';
import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useSpecialRightElement from 'pages/identifier/useSpecialRightElement.hook';

import AUDIT_EVENTS from 'constants/auditEvents';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import s from './Identifier.module.scss';

interface IdentifierProps {
  onVerified?: () => void;
  setIsPassVerified?: Dispatch<SetStateAction<boolean>>;
  biometricStatus: AvailableResult;
}

const Identifier: FC<IdentifierProps> = (props) => {
  const { onVerified, setIsPassVerified, biometricStatus } = props;
  const { signoutSilent } = useAuth();
  const [, setSelectedEmployee] = useFullSelectedEmployee();

  const { unregister } = useFirebase();

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const [securedPass] = useSecureStorage(SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS, '');

  const [pass, setPass] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { auditSuccess, auditError } = useAudit();

  useEffect(() => {
    if (pass.length === 4) {
      if (securedPass === pass) {
        if (setIsPassVerified) {
          auditSuccess(AUDIT_EVENTS.AUTHORIZATION.PASSCODE);
          setIsSuccess(true);
          setTimeout(() => {
            setIsPassVerified(true);
          }, 1000);
        } else if (onVerified) {
          onVerified();
        }
      } else {
        auditError(AUDIT_EVENTS.AUTHORIZATION.PASSCODE);
        setError(t('common_wrong_code'));
      }
    }
  }, [auditError, auditSuccess, onVerified, pass, securedPass, setIsPassVerified]);

  useEffect(() => {
    if (!!error) {
      timer.current = setTimeout(() => {
        setPass('');
        setError('');
      }, 2000);
    } else if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [error]);

  const handleElementClick = (e?: string) => {
    setPass((prevState) => {
      const shouldReturnPrevState = prevState.length === 4 || !e;

      if (shouldReturnPrevState) {
        return prevState;
      }
      setError('');

      return `${prevState}${e}`;
    });
  };

  const handleRemoveElement = useCallback(() => {
    setError('');
    setIsSuccess(false);

    setPass((prevState) => prevState.slice(0, prevState.length - 1));
  }, []);

  const onBiometrySuccess = useCallback(() => {
    auditSuccess(AUDIT_EVENTS.AUTHORIZATION.BIOMETRY);
    setError('');
    setPass(securedPass);
  }, [auditSuccess, securedPass]);

  const specialLeftElement = {
    label: t('common_exit'),
    className: s.exit,
    onClick: () => {
      unregister();
      signoutSilent();
      setSelectedEmployee('');
    },
  };

  const specialRightElement = useSpecialRightElement({
    biometryType: biometricStatus?.biometryType,
    handleRemoveElement,
    pass,
    setError,
    onBiometrySuccess,
  });

  return (
    <WithStatusBar>
      <WithGzpTopLogo className={s.wrapper}>
        <div className={s.codeWrapper}>
          <Typography.Title level={3}>{t('identifier_enter_code')}</Typography.Title>
          <Passcode
            selectedCount={pass.length}
            selectedClassName={cn({
              [s.passwordsAreWrong]: !!error,
              [s.passwordsAreMatched]: isSuccess,
            })}
          />
          {!!error && (
            <Typography.Title level={5} type="danger">
              {error}
            </Typography.Title>
          )}
        </div>
        <div className={s.keyboard}>
          <MobileNumberKeyboard
            specialLeftElement={specialLeftElement}
            onElementClick={handleElementClick}
            specialRightElement={specialRightElement}
          />
        </div>
      </WithGzpTopLogo>
    </WithStatusBar>
  );
};

export default Identifier;
