import { createSlice } from '@reduxjs/toolkit';

import { ArticleFullType } from 'types/article.types';
import { PaginationPropsType } from 'types/pagination.types';

export const SLICE_NAME = 'guideSettlementPostersSlice';

export type GuideSettlementPostersSliceState = PaginationPropsType & {
  newsToShow: ArticleFullType[];
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: GuideSettlementPostersSliceState = {
  newsToShow: [],
  ...DEFAULT_PAGINATION,
};

export const guideSettlementPostersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationTagsAndNews(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.newsToShow = [];
    },
    addNews(state, action) {
      state.newsToShow = state.newsToShow.concat(action.payload);
    },
    resetNews(state) {
      state.newsToShow = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
    addRemoveLike(state, action) {
      state.newsToShow = state.newsToShow.map((art) => {
        if (art.code === action.payload) {
          const likesCnt = art.liked ? art.likesCnt - 1 : art.likesCnt + 1;

          return { ...art, liked: !art.liked, likesCnt };
        }

        return art;
      });
    },
  },
});

export const {
  addPage,
  addNews,
  resetPaginationTagsAndNews,
  resetNews,
  resetPagination,
  addRemoveLike,
} = guideSettlementPostersSlice.actions;

export default guideSettlementPostersSlice.reducer;
