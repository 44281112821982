import React, { FC } from 'react';

import cn from 'classnames';

import s from './Passcode.module.scss';

const PASSCODE_ARRAY = new Array(4).fill(1);

interface PasscodeProps {
  selectedClassName?: string;
  selectedCount: number;
}

const Passcode: FC<PasscodeProps> = (props) => {
  const { selectedCount, selectedClassName } = props;

  const renderPasscodeElement = (_: unknown, index: number) => {
    const isSelected = selectedCount - 1 >= index;
    const showSelectedClassName = selectedClassName && isSelected;
    return (
      <div
        key={`PASSCODE_ELEMENT_${index}`}
        className={cn(s.element, showSelectedClassName && selectedClassName, {
          [s.selected]: isSelected,
        })}
      />
    );
  };

  return <div className={s.wrapper}>{PASSCODE_ARRAY.map(renderPasscodeElement)}</div>;
};

export default Passcode;
