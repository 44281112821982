import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { FreeAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form, TextArea } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

import SurveyTitle from 'pages/surveys/surveys-question-title';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsFree.module.scss';

export interface SurveysQuestionsFreeProps extends QuestionProps {
  no: number;
  title: string;
  is_required: boolean;
  limit: {
    min: number;
    max: number;
  };
  withoutTitle?: boolean;
}

const SurveysQuestionsFree: FC<SurveysQuestionsFreeProps> = (props) => {
  const { anketologId, revisionId, anketaLinkId } = useParams<{
    anketologId: string;
    revisionId: string;
    anketaLinkId: string;
  }>();
  const [, setStorageValue] = useSecureStorage(
    `${SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SURVEY_PROGRESS_ID}_${anketologId}_${revisionId}_${anketaLinkId}`,
    '',
  );
  const { id, no, title, limit, is_required, withoutTitle, type } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = Form.useFormInstance();
  const { isCompleted, answers } = useAppSelector((store) => store.surveySlice);
  const answer = (
    answers?.answer.find((answer) => answer.question_id === id) as FreeAnswerType | undefined
  )?.question_answer.answer?.answer_text;
  const name = `${type}.${id}`;

  useEffect(() => {
    if (answer) {
      form.setFieldValue(name, answer);
    }
  }, [answer, form, name]);

  const onSubmitHandler = useCallback(
    (value: Record<string, string>) => {
      const fieldValue = value[name];

      setIsModalOpen(!isModalOpen);
      form.setFieldValue(name, fieldValue);
      setTimeout(() => {
        setStorageValue(JSON.stringify(form.getFieldsValue()));
      }, 0);
    },
    [form, isModalOpen, name, setStorageValue],
  );

  return (
    <div className={cn(s.freeContainer, withoutTitle && s.withoutTitle)}>
      {!withoutTitle && (
        <SurveyTitle level={5} number={no} required={is_required}>
          {title}
        </SurveyTitle>
      )}
      <Form.Item name={name}>
        <TextArea
          rows={10}
          placeholder={isCompleted ? '' : t('common_enter_text')}
          onClick={() => setIsModalOpen(!isModalOpen)}
          disabled={isCompleted}
          onFocus={(e) => e.target.blur()}
        />
      </Form.Item>
      <TextAreaModal
        name={name}
        title={title}
        maxLength={limit.max ?? 250}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmitHandler={onSubmitHandler}
        defaultValue={form.getFieldValue(name)}
      />
    </div>
  );
};

export default SurveysQuestionsFree;
