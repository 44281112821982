import { TitleProps } from 'gazprom-ui-lib';

export type ListType = 'dots' | 'numbers';

export type GazPromBodyDataType = {
  title: string;
  titleLevel?: TitleProps['level'];
  description?: string;
  list?: {
    style: ListType;
    elements: string[];
  };
};

export enum GPB_FORM {
  GPB_NEWSLETTER = 'GPB_NEWSLETTER',
  GPB_PROJECT_TERMS = 'GPB_PROJECT_TERMS',
}

export const gazPromBodyData: GazPromBodyDataType[] = [
  {
    title: 'Зарплатная карта с кэшбэком',
    titleLevel: 4,
    list: {
      style: 'dots',
      elements: [
        'До 5000 бонусных баллов в месяц',
        'Бесплатный выпуск и обслуживание 4-х дополнительных карт для членов семьи',
      ],
    },
  },
  {
    title: 'Можно снимать наличные в любом банкомате без комиссии',
    list: {
      elements: [
        'Банкоматы Газпромбанка: до 1 млн ₽/месяц без комиссии',
        'Банкоматы любых сторонних банков в РФ: до 300 тыс. ₽/месяц без комиссии',
      ],
      style: 'dots',
    },
  },
  {
    title: 'Бесплатные переводы в мобильном приложении',
    list: {
      style: 'dots',
      elements: [
        'По номеру телефона друзьям и родственникам до 100 тыс. ₽/мес.',
        'По реквизитам счета в любой банк – без комиссии 5 млн ₽/мес.',
        'На свои счета в сторонние банки до 30 млн ₽/мес.',
        'Оплата коммунальных услуг, штрафов и налогов без комиссии',
      ],
    },
  },
  {
    title: 'Получи до 3000 ₽ на карту',
    description:
      'При переводе зарплаты на карту ГПБ действует акция «Зарабатывай с Газпромбанком», вот три простых шага:',
    list: {
      style: 'numbers',
      elements: [
        'Получи карту ГПБ',
        'Подпиши заявление на перевод ЗП в ГПБ',
        'Получи от Банка сообщение об участии в акции',
      ],
    },
  },
  {
    title: 'Газпромбанк Привилегии «Стандарт»',
    list: {
      style: 'dots',
      elements: [
        'Больше кэшбэка по карте',
        'Увеличенный лимит на переводы в другие банки',
        'Повышенный лимит на снятие наличных',
        'Процент на остаток по карте',
        'Скидки у партнеров',
      ],
    },
  },
  {
    title: 'Мобильная связь «ГПБ-Мобайл»',
    list: {
      style: 'dots',
      elements: [
        'Скоростной мобильный интернет',
        'Бесплатная раздача WiFi на другие устройства',
        'Стабильное качество связи по России и в роуминге',
        'Безлимитные мессенджеры',
        'Стоимость от 175 ₽/мес.',
      ],
    },
  },
  {
    title: 'Дополнительные возможности для зарплатных клиентов по кредитованию',
    titleLevel: 4,
  },
  {
    title: 'Потребительский кредит до 7 млн ₽',
    description: 'Скидки для ЗП клиентов до -1%',
  },
  {
    title: 'Кредитная карта 180 дней без процентов',
    description: 'Кредитный лимит до 1 млн ₽',
  },
  {
    title: 'Автокредит до 7 млн ₽',
    description: 'Скидки для ЗП клиентов до -4%',
  },
  {
    title: 'Ипотека до 80 млн ₽',
    description: 'Скидки для ЗП клиентов до -1,1%',
  },
  {
    title: 'Вклад «Заоблачный процент» до 20%',
    description: 'Надбавка для ЗП клиентов до +0,3%',
  },
  {
    title: 'Накопительный счет «Ежедневный процент» до 19,5%',
    description: 'Надбавка для ЗП клиентов до +0,3%',
  },
  {
    title: 'Карта, которая работает за границей',
    description: 'Международная карта UnionPay',
    list: {
      style: 'dots',
      elements: [
        'Оплата в ОАЭ, Турции, Таиланде и еще 150 странах, а также дома',
        'Снятие наличных за границей',
        'Без комиссии до 50 000 ₽ в месяц',
      ],
    },
  },
];

export const link = {
  href: 'www.gazprombank.ru/special/pu-zpuniversalniy/',
  text: 'Актуальные тарифы',
};

export const actions = [
  {
    name: [GPB_FORM.GPB_NEWSLETTER],
    text: 'Согласен получать рассылку в мессенджерах',
  },
  {
    name: [GPB_FORM.GPB_PROJECT_TERMS],
    text: 'С условиями зарплатного проекта ознакомлен',
  },
];
