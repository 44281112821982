import { Button, Card, Flex, Form, Icon, Switch, Typography } from 'gazprom-ui-lib';

import InappLink from 'components/inappLink';

import s from './GazPromBank.module.scss';
import {
  GPB_FORM,
  GazPromBodyDataType,
  ListType,
  actions,
  gazPromBodyData,
  link,
} from './gazPromBank.utils';

interface Props {
  isButtonLoading: boolean;
  buttonText: string;
}

const GazPromBank = (props: Props) => {
  const { isButtonLoading, buttonText } = props;

  const newsletterSwitch = Form.useWatch([GPB_FORM.GPB_NEWSLETTER]);
  const projectTermsSwitch = Form.useWatch([GPB_FORM.GPB_PROJECT_TERMS]);
  const isActive = newsletterSwitch && projectTermsSwitch;

  const renderList = (text: string, index: number, type: ListType) => {
    if (type === 'dots') {
      return (
        <Flex key={text} gap="12">
          <div className={s.dot} />
          <Typography.Text type="secondary">{text}</Typography.Text>
        </Flex>
      );
    }

    const num = index + 1;

    return (
      <Flex key={text} gap="8">
        <Typography.Text type="secondary" className={s.num}>
          {`${num}.`}
        </Typography.Text>
        <Typography.Text type="secondary">{text}</Typography.Text>
      </Flex>
    );
  };

  const renderSection = (section: GazPromBodyDataType) => {
    const { title, description, list, titleLevel } = section;
    return (
      <Flex vertical gap="8" key={section.title}>
        <Typography.Title level={titleLevel ?? 5}>{title}</Typography.Title>
        {description && <Typography.Text type="secondary">{description}</Typography.Text>}
        {list && (
          <Flex vertical>
            {list.elements.map((text, index) => renderList(text, index, list.style))}
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <>
      <Card>
        <Flex vertical gap="16">
          <Flex vertical gap="16">
            {gazPromBodyData.map(renderSection)}
          </Flex>

          <Flex className={s.link}>
            <Icon name="arrowLink" />
            <InappLink href={link.href}>{link.text}</InappLink>
          </Flex>

          <Flex vertical gap="8">
            {actions.map((element) => {
              const { name, text } = element;
              return (
                <Flex gap="8" className={s.formItem} key={text}>
                  <Typography.Text size="14" weight="500">
                    {text}
                  </Typography.Text>
                  <Form.Item name={name} valuePropName="checked" initialValue={false}>
                    <Switch />
                  </Form.Item>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Card>

      <div className={s.fixedCard}>
        <Button
          htmlType="submit"
          fullWidth
          loading={isButtonLoading}
          rightIcon="arrowRight"
          size="large"
          type="primary"
          disabled={!isActive}>
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default GazPromBank;
