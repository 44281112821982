import React from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Button, Icon, ModalDefault } from 'gazprom-ui-lib';

import CodeVerification from 'containers/code-verification';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './ProfilePersonalData.module.scss';
import ProfilePersonalDataDetails from './profile-personal-data-details';
import ProfilePersonalDataHeader from './profile-personal-data-header';
import ProfilePersonalDataHeaderHidden from './profile-personal-data-header-hidden';
import ProfilePersonalDataHidden from './profile-personal-data-hidden';
import { useProfilePersonalData } from './useProfilePersonalData.hook';

const ProfilePersonalData = () => {
  const {
    isRequestLoading,
    isConfirmLoading,
    isPersonalDataFetching,
    personalData,
    personalDataHidden,
    showConfirmCode,
    showRequestCodeError,
    setPersonalDataHidden,
    handleRequestPersonalData,
    handleCloseConfirmCodeScreen,
    handleSubmitVerificationCode,
    handleCloseShowRequestCodeError,
  } = useProfilePersonalData();

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} useDefaultGoBack={true} />
      <Header.Label label={t('my_personal_data')} />
      <Header.RightIcon
        className={s.lockIcon}
        onClick={handleRequestPersonalData}
        iconName={personalDataHidden ? 'password' : 'unlocked'}
      />
    </>
  );

  const showHiddenContent = !showConfirmCode && personalDataHidden;

  const showPersonalData =
    !showConfirmCode && !personalDataHidden && !!personalData?.visibilityEndDate;

  const [selectedEmployee] = useFullSelectedEmployee();
  const { lastName, firstName, patronymic } = selectedEmployee?.person || {};

  const personFullName = `${lastName} ${firstName} ${patronymic}`;

  const requestErrorModalContent = (
    <ModalDefault open={showRequestCodeError} onCancel={handleCloseShowRequestCodeError}>
      <ModalDefault.Icon name="error" type="danger" />
      <ModalDefault.Title>{t('common_error')}</ModalDefault.Title>
      <ModalDefault.Description>{t('code_verification_request_error')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" onClick={handleCloseShowRequestCodeError} fullWidth size="large">
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isPersonalDataFetching}>
            {showPersonalData && (
              <ProfilePersonalDataHeader
                personFullName={personFullName}
                visibilityEndDate={personalData?.visibilityEndDate}
                setPersonalDataHidden={setPersonalDataHidden}
              />
            )}
            {showPersonalData && <ProfilePersonalDataDetails personalData={personalData} />}
            {showHiddenContent && (
              <ProfilePersonalDataHeaderHidden
                isRequestLoading={isRequestLoading}
                personFullName={personFullName}
                handleShowConfirmCodeScreen={handleRequestPersonalData}
              />
            )}
            {showHiddenContent && <ProfilePersonalDataHidden />}
          </WithLoader>
          {requestErrorModalContent}
          {showConfirmCode && (
            <CodeVerification
              successConfig={{
                title: t('my_personal_data_success_request_title'),
                description: t('my_personal_data_visible_for', { time: '30' }),
                onConfirm: handleSubmitVerificationCode,
                closeCodeVerification: handleCloseConfirmCodeScreen,
                isConfirmLoading: isConfirmLoading,
                icon: 'eye',
              }}>
              <div onClick={handleCloseConfirmCodeScreen}>
                <Icon name="arrowLeft" />
              </div>
              <Header.Label label={t('my_personal_data')} />
            </CodeVerification>
          )}
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default ProfilePersonalData;
