import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  RejectKedoDocumentAssignmentPropsType,
  RejectKedoDocumentAssignmentResponseType,
  RevisionKedoDocumentAssignmentPropsType,
  RevisionKedoDocumentAssignmentResponseType,
  SignKedoDocumentAssignmentPropsType,
  SignKedoDocumentAssignmentResponseType,
} from './kedoDocumentApiService.types';

import { createKedoDocumentUrl } from './utils';

const kedoDocumentApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    signKedoDocumentAssignment: builder.mutation<
      SignKedoDocumentAssignmentResponseType,
      SignKedoDocumentAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoDocumentUrl(`/${assignmentId}/sign`),
        method: 'POST',
        body,
      }),
    }),
    rejectKedoDocumentAssignment: builder.mutation<
      RejectKedoDocumentAssignmentResponseType,
      RejectKedoDocumentAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoDocumentUrl(`/${assignmentId}/reject`),
        method: 'POST',
        body,
      }),
    }),
    revisionKedoDocumentAssignment: builder.mutation<
      RevisionKedoDocumentAssignmentResponseType,
      RevisionKedoDocumentAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoDocumentUrl(`/${assignmentId}/revision`),
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const {
  useSignKedoDocumentAssignmentMutation,
  useRejectKedoDocumentAssignmentMutation,
  useRevisionKedoDocumentAssignmentMutation,
} = kedoDocumentApiService;
