import { t } from 'tools/i18n';

import CardsTile from 'components/cards-tile';
import SupportCard from 'components/support-card';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import WorkTogetherWhiteV2 from 'assets/icons/workTogetherWhiteV2.png';

import s from './More.module.scss';
import { MORE_CARDS } from './more.utils';

const More = () => {
  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false} className={s.workTogether}>
            <img
              className={s.workTogetherImage}
              src={WorkTogetherWhiteV2}
              alt="more-work-together-banner"
            />
          </Card>
          <CardsTile title={t('common_work_together')} cards={MORE_CARDS} />
          <SupportCard />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default More;
