import { useEffect } from 'react';

import { useLazyGetFullArticlesQuery } from 'services/article/articleApiService';
import { useGetNewsCategoriesQuery } from 'services/category/categoryApiService';
import { addNews, resetNews, resetPagination } from 'slices/newsSlice';
import { useAppDispatch } from 'store';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { SORT_ENUM } from 'types/sort.types';

interface UseRequestsProps {
  debouncedRequestBody: {
    page: number;
    size: number;
    activeNewsTags: string[];
  };
  loadMoreRef: { current: boolean };
}

const useRequests = (props: UseRequestsProps) => {
  const { debouncedRequestBody, loadMoreRef } = props;

  const dispatch = useAppDispatch();

  const { data: categoriesData, isFetching: newsCategoriesIsFetching } = useGetNewsCategoriesQuery({
    page: 1,
    size: 30,
    type: 'news',
  });

  const [
    getFullArticles,
    {
      data: fullArticlesData,
      isFetching: getFullArticlesIsFetching,
      isLoading: getFullArticlesIsLoading,
      isUninitialized,
    },
  ] = useLazyGetFullArticlesQuery();

  useEffect(() => {
    return () => {
      dispatch(resetNews());
      dispatch(resetPagination());
    };
  }, [dispatch]);

  useEffect(() => {
    getFullArticles({
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      type: [ARTICLE_TYPE_ENUM.NEWS],
      sort: [
        { order: SORT_ENUM.ASC, property: 'pinNum' },
        { order: SORT_ENUM.DESC, property: 'ARTICLE.publishFrom' },
      ],
      categoryIds: debouncedRequestBody.activeNewsTags?.length
        ? debouncedRequestBody.activeNewsTags
        : undefined,
    })
      .then((res) => {
        if (res?.data && 'items' in res.data) {
          dispatch(addNews(res.data?.items));
        }
      })
      .finally(() => (loadMoreRef.current = true));
  }, [
    debouncedRequestBody.page,
    debouncedRequestBody.size,
    debouncedRequestBody.activeNewsTags,
    dispatch,
    getFullArticles,
    loadMoreRef,
  ]);

  const isFetching =
    newsCategoriesIsFetching ||
    getFullArticlesIsLoading ||
    getFullArticlesIsFetching ||
    isUninitialized;

  return {
    isFetching,
    isLoading: getFullArticlesIsLoading,
    fullArticlesData,
    categoriesData,
  };
};

export default useRequests;
