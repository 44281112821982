import React, { Dispatch, FC, MouseEvent, SetStateAction } from 'react';

import { getLikesCount } from 'utils/getLikesCount';

import { useAddLikeMutation, useRemoveLikeMutation } from 'services/article/articleApiService';
import { addRemoveLike } from 'slices/newsSlice';
import { useAppDispatch } from 'store';

import { Typography } from 'gazprom-ui-lib';

import YoutubePlayer from 'components/youtube-player';
import Card from 'containers/card';
import NewsDetailsContent from 'containers/news-details-content';
import WithLoader from 'containers/wrappers/with-loader';

import { ArticleFullType } from 'types/article.types';
import { EventType } from 'types/event.types';

import HomeNewsDetailsContentEvent from './home-news-details-content-event';

interface HomeNewsDetailsContentProps {
  isLiked: boolean;
  isNewsDetails: boolean;
  setIsLiked: Dispatch<SetStateAction<boolean>>;
  articleData?: ArticleFullType;
  isFetching: boolean;
  isParticipant: boolean;
  meeting?: EventType['meeting'];
}

const HomeNewsDetailsContent: FC<HomeNewsDetailsContentProps> = (props) => {
  const { isFetching, isLiked, isNewsDetails, setIsLiked, articleData, meeting, isParticipant } =
    props;

  const dispatch = useAppDispatch();

  const [addLike] = useAddLikeMutation();
  const [removeLike] = useRemoveLikeMutation();

  const handleAddArticle = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (articleData?.code) {
      const mutation = articleData?.liked ? removeLike : addLike;
      setIsLiked((prevState) => !prevState);
      dispatch(addRemoveLike(articleData?.code));
      mutation(articleData?.code);
    }
  };

  const renderCategory = (category: { id: string; name: string }) => (
    <Typography.Text type="primary" size="14" key={category.id} weight="500">
      {category.name}
    </Typography.Text>
  );

  const renderMedia = (media: { type: string; link: string; name: string }) => {
    if (media.type === 'youtube') {
      return <YoutubePlayer key={media.name} src={media.link} title={media.name} />;
    }
    return null;
  };

  const initialLikesCount = articleData?.likesCnt ?? 0;
  const likesCount = getLikesCount(initialLikesCount, !!articleData?.liked, isLiked);

  return (
    <Card withPadding={false}>
      <WithLoader isLoading={isFetching}>
        {isNewsDetails ? (
          <NewsDetailsContent
            renderMedia={renderMedia}
            renderCategory={renderCategory}
            likesCount={likesCount}
            isLiked={isLiked}
            articleData={articleData}
            handleAddArticle={handleAddArticle}
          />
        ) : (
          <HomeNewsDetailsContentEvent
            renderCategory={renderCategory}
            likesCount={likesCount}
            isLiked={isLiked}
            articleData={articleData}
            handleAddArticle={handleAddArticle}
            meeting={meeting}
            isParticipant={isParticipant}
          />
        )}
      </WithLoader>
    </Card>
  );
};

export default HomeNewsDetailsContent;
