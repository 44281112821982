import React, { useCallback, useState } from 'react';

import { t } from 'tools/i18n';

import { useReplyFeedbackMutation } from 'services/notification/notificationApiService';

import { Button, TextArea, TextAreaProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from './NotificationsSingleReply.module.scss';
import NotificationsSingleReplyModal from './notifications-single-reply-modal';

interface NotificationsSingleReplyProps {
  handleBack: () => void;
  id: string;
}

const NotificationsSingleReply = (props: NotificationsSingleReplyProps) => {
  const { handleBack, id } = props;
  const [text, setText] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [replyFeedback, { isLoading }] = useReplyFeedbackMutation();

  const handleSubmit = useCallback(() => {
    replyFeedback({
      feedback: text,
      id,
    }).finally(() => {
      setIsModalVisible(true);
    });
  }, [id, replyFeedback, text]);

  const handleChange: TextAreaProps['onChange'] = (e) => {
    setText(e.target.value);
  };

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handleBack} />
      <Header.Label label={t('notifications_reply')} />
    </>
  );

  return (
    <WithNavigation headerChildren={headerChildren}>
      <WithBackground>
        <Card className={s.textAreaWrapper}>
          <Typography.Title level={4}>{t('notifications_reply_text')}</Typography.Title>
          <Typography.Text>
            {t('common_symbols_count', { currentCount: text.length, maxCount: 360 })}
          </Typography.Text>
          <TextArea
            value={text}
            className={s.textAreaWithoutPadding}
            autoFocus
            rows={10}
            placeholder={t('common_enter_text')}
            onChange={handleChange}
            maxLength={360}
          />
          {!!text.length && (
            <Button leftIcon="sendFilled" fullWidth onClick={handleSubmit} loading={isLoading}>
              {t('notifications_send_reply')}
            </Button>
          )}
        </Card>
        <NotificationsSingleReplyModal isVisible={isModalVisible} onClickHandler={handleBack} />
      </WithBackground>
    </WithNavigation>
  );
};

export default NotificationsSingleReply;
