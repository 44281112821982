import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GUIDE_MY_SETTLEMENTS_ROUTE,
  GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE,
} from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useActivateSettlementMutation,
  useGetMySettlementHistoryQuery,
} from 'services/guide/guideApiService';
import { getEmployeesSelector } from 'services/me/meApiService';
import { setQRCode } from 'slices/guideSlice';
import { useAppSelector } from 'store';

import { Button } from 'gazprom-ui-lib';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useBuildHero from '../../utils/useBuildHero';
import GuideButtonLocation from './guide-button-location';
import GuideButtonQR from './guide-button-qr';
import GuideSelect from './guide-select';
import GuideSettlement from './guide-settlement';

const Guide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: settlementId } = useParams<{ id: string }>();

  const { isLoading: isEmployeesLoading } = useAppSelector(getEmployeesSelector);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { isBuildHeroNotCurrentSettlement, buildHeroId } = useBuildHero();

  const isCurrentSettlementSelected = Boolean(selectedEmployee?.currentSettlement.id);

  const [activateSettlement, { isLoading: isActivateSettlementLoading }] =
    useActivateSettlementMutation();
  const { data: history, isLoading: isHistoryLoading } = useGetMySettlementHistoryQuery({
    myEmployeeId: selectedEmployee?.id!,
    page: 1,
    size: 500,
  });

  const isLoading = isEmployeesLoading || isHistoryLoading || isActivateSettlementLoading;

  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );

  useEffect(() => {
    if (selectedEmployee && isBuildHeroNotCurrentSettlement && buildHeroId) {
      activateSettlement({ myEmployeeId: selectedEmployee.id, settlementId: buildHeroId });
    }
  }, [activateSettlement, buildHeroId, isBuildHeroNotCurrentSettlement, selectedEmployee]);

  useEffect(() => {
    if (settlementId) {
      dispatch(setQRCode(settlementId));
      if (isCurrentSettlementSelected) {
        navigate(GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE);
      }
    }
  }, [dispatch, isCurrentSettlementSelected, navigate, settlementId]);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            {isCurrentSettlementSelected ? (
              <GuideSettlement />
            ) : (
              <GuideSelect
                icon="home"
                title={t('guide_select_title')}
                subtitle={t('guide_select_subtitle')}
                buttons={
                  <>
                    <GuideButtonLocation text={t('guide_select_button_access_to_geo')} />
                    <GuideButtonQR text={t('guide_select_button_qr')} />
                    {!!history?.items.length && (
                      <Button
                        type="secondary"
                        fullWidth
                        size="large"
                        leftIcon="home"
                        onClick={() => navigate(GUIDE_MY_SETTLEMENTS_ROUTE)}>
                        {t('guide_select_my')}
                      </Button>
                    )}
                  </>
                }
              />
            )}
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Guide;
