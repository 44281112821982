import Home from 'pages/home';
import HomeNews from 'pages/home/home-news';
import HomeNewsTags from 'pages/home/home-news-tags';
import HomeSelectCompany from 'pages/home/home-select-company';
import HomeSwiper from 'pages/home/home-swiper';

import { RouteConfig } from '../routes';
import {
  HOME_NEWS_DETAILS_ROUTE,
  HOME_NEWS_ROUTE,
  HOME_NEWS_TAGS_ROUTE,
  HOME_ROUTE,
  HOME_SELECT_COMPANY,
  HOME_SWIPER_ROUTE,
} from './list';

export const homeRoutes: RouteConfig[] = [
  {
    path: HOME_ROUTE,
    element: <Home />,
  },
  { path: HOME_SWIPER_ROUTE, element: <HomeSwiper /> },
  { path: HOME_SELECT_COMPANY, element: <HomeSelectCompany /> },
  { path: HOME_NEWS_ROUTE, element: <HomeNews /> },
  { path: HOME_NEWS_DETAILS_ROUTE, element: <HomeNews /> },
  { path: HOME_NEWS_TAGS_ROUTE, element: <HomeNewsTags /> },
];
