import { t } from 'tools/i18n';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  handleOpenClose: () => void;
  onSubmit: (e?: Record<string, string>) => void;
  form: FormInstance;
}

const DeclineCommentModal = (props: Props) => {
  const { isOpen, isLoading, form, handleOpenClose, onSubmit } = props;

  const comment = Form.useWatch('comment', form);

  const modalActions = (
    <Flex vertical gap="12">
      <Button size="large" fullWidth ghost htmlType="reset" onClick={handleOpenClose}>
        {t('common_back')}
      </Button>
      <Button
        size="large"
        fullWidth
        htmlType="submit"
        rightIcon="arrowRight"
        loading={isLoading}
        disabled={!comment}>
        {t('common_send')}
      </Button>
    </Flex>
  );

  return (
    <TextAreaModal
      actions={modalActions}
      formFromProps={form}
      name="comment"
      title={t('common_comment')}
      modalHeaderTitle={t('suggestion_decision_declining')}
      maxLength={360}
      isModalOpen={isOpen}
      setIsModalOpen={handleOpenClose}
      onSubmitHandler={onSubmit}
    />
  );
};

export default DeclineCommentModal;
