import { Dispatch, SetStateAction } from 'react';

import { SegmentedProps } from 'gazprom-ui-lib';

import {
  DEFAULT_PAGINATION_CONFIG,
  SegmentedValueType,
} from 'pages/documents/documents-inquiries/documentsInquiries.utils';

import { KedoTask, KedoTaskDraft } from 'types/documents.types';
import { PaginationPropsType } from 'types/pagination.types';

import { ALL_CATEGORIES } from '../documents.utils';

interface UseActionsProps {
  activeStatus: string;
  activeCategory: string;
  isDraft: boolean;
  setPagination: Dispatch<SetStateAction<PaginationPropsType>>;
  setTasksToShow: Dispatch<SetStateAction<KedoTask[]>>;
  setDraftsToShow: Dispatch<SetStateAction<KedoTaskDraft[]>>;
  setSelectedSegment: Dispatch<SetStateAction<SegmentedValueType>>;
  setActiveCategory: Dispatch<SetStateAction<string>>;
  setActiveStatus: Dispatch<SetStateAction<string>>;
  refetchCategories: () => void;
  setIsRefreshing: Dispatch<SetStateAction<boolean>>;
}

const useActions = (props: UseActionsProps) => {
  const {
    activeStatus,
    activeCategory,
    isDraft,
    setPagination,
    setTasksToShow,
    setDraftsToShow,
    refetchCategories,
    setSelectedSegment,
    setActiveCategory,
    setActiveStatus,
    setIsRefreshing,
  } = props;

  const resetState = () => {
    if (isDraft) {
      setDraftsToShow([]);
    } else {
      setTasksToShow([]);
    }

    setPagination(DEFAULT_PAGINATION_CONFIG);
  };

  const handlePageRefresh = async () => {
    setIsRefreshing(true);
    resetState();
    await refetchCategories();
    setIsRefreshing(false);
  };
  const handleSegmentChange: SegmentedProps['onChange'] = (segment) => {
    setSelectedSegment(segment as SegmentedValueType);
    setPagination(DEFAULT_PAGINATION_CONFIG);
    setTasksToShow([]);
    setDraftsToShow([]);
    setActiveCategory(ALL_CATEGORIES.id);
  };
  const handleSetActiveCategory = (category: SetStateAction<string>) => {
    if (activeCategory !== category) {
      resetState();
      setActiveCategory(category);
    }
  };
  const handleSetActiveStatus = (status: SetStateAction<string>) => {
    if (activeStatus !== status) {
      resetState();
      setActiveStatus(status);
    }
  };
  return {
    handlePageRefresh,
    handleSegmentChange,
    handleSetActiveCategory,
    handleSetActiveStatus,
  };
};

export default useActions;
