import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isVisible: boolean;
  onCancel: () => void;
}

const TopManagementSuccessModal: FC<Props> = (props) => {
  const { isVisible, onCancel } = props;

  return (
    <ModalDefault open={isVisible} onCancel={onCancel} width={300}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('top_management_sent_successfully_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('top_management_sent_successfully_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" size="large" fullWidth onClick={onCancel}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default TopManagementSuccessModal;
