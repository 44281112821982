import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useJoinTheMeetingMutation } from 'services/meet/meetApiService';
import { addRemoveParticipation } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { Button, handleRequest } from 'gazprom-ui-lib';

import HomeNewsDetailsContentEventCancelModal from '../home-news-details-content-event-cancel-modal';
import HomeNewsDetailsContentEventParticipateModal from '../home-news-details-content-event-participate-modal';
import s from './HomeNewsDetailsContentEventParticipateButton.module.scss';

interface HomeNewsDetailsContentEventParticipateButtonProps {
  placesLeft: number;
  maxPlacesCount: number;
  meetingId?: string;
  eventIsInFuture: boolean;
  isParticipant: boolean;
  setIsParticipant: Dispatch<SetStateAction<boolean>>;
}

const HomeNewsDetailsContentEventParticipateButton: FC<
  HomeNewsDetailsContentEventParticipateButtonProps
> = (props) => {
  const {
    isParticipant,
    setIsParticipant,
    eventIsInFuture,
    placesLeft,
    meetingId,
    maxPlacesCount,
  } = props;

  const dispatch = useAppDispatch();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [modalType, setModalType] = useState<string>('');

  const [joinTheMeeting, { isLoading: joinIsLoading }] = useJoinTheMeetingMutation();

  const mutationProps = {
    id: meetingId ?? '',
    myEmployeeId: selectedEmployee?.id ?? '',
  };

  const handleParticipation = () => {
    setIsParticipant((prevState) => !prevState);
  };

  const onSuccess = () => {
    dispatch(addRemoveParticipation({ id: meetingId, isParticipant: !isParticipant }));
    handleParticipation();
    setModalType('participate');
  };

  const handleButtonClick = () => {
    if (isParticipant) {
      setModalType('cancel');
    } else if (meetingId && selectedEmployee) {
      joinTheMeeting(mutationProps).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleResetModalType = () => setModalType('');

  const isEventCancelModalOpen = modalType === 'cancel' || modalType === 'cancelSubmit';

  if (eventIsInFuture) {
    if (!isParticipant && placesLeft < 1 && maxPlacesCount !== 0) {
      return (
        <Button disabled fullWidth leftIcon="human" className={s.actionButton}>
          {t('home_event_places_left')}
        </Button>
      );
    }

    return (
      <>
        <Button
          loading={joinIsLoading}
          fullWidth
          leftIcon={isParticipant ? 'eventCancel' : 'plus'}
          className={s.actionButton}
          danger={isParticipant}
          ghost={isParticipant}
          onClick={handleButtonClick}>
          {t(isParticipant ? 'home_event_cancel_appointment' : 'home_event_participate')}
        </Button>
        <HomeNewsDetailsContentEventParticipateModal
          open={modalType === 'participate'}
          handleResetModalType={handleResetModalType}
        />
        <HomeNewsDetailsContentEventCancelModal
          handleParticipation={handleParticipation}
          open={isEventCancelModalOpen}
          isCancelSubmitted={modalType === 'cancelSubmit'}
          handleResetModalType={handleResetModalType}
          setModalType={setModalType}
          mutationProps={mutationProps}
        />
      </>
    );
  }

  return <></>;
};

export default HomeNewsDetailsContentEventParticipateButton;
