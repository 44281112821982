import initialApiService from 'services/initialApiService';

import {
  GetMyLeadersForBusinessTripPropsType,
  GetMyLeadersForBusinessTripResponseType,
} from './myLeadersApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createMyLeadersUrl } from './utils';

const myLeadersApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getMyLeadersForBusinessTrip: builder.query<
      GetMyLeadersForBusinessTripResponseType,
      GetMyLeadersForBusinessTripPropsType
    >({
      query: ({ myEmployeeId }) => ({
        url: createMyLeadersUrl(`/full?myEmployeeId=${myEmployeeId}`),
      }),
      providesTags: [SERVICE_TAGS.GET_BUSINESS_TRIPS],
    }),
  }),
});

export const { useGetMyLeadersForBusinessTripQuery } = myLeadersApiService;
