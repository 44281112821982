import { t } from 'tools/i18n';

import { ComponentsType } from 'types/mySalary.types';

export const mapPaidData = (components: ComponentsType[]) => {
  const { payoutsSum, deductionsSum, accrualsSum } = components.reduce(
    (acc, el) => {
      if (el.component.section === 'payouts') {
        acc.payoutsSum.value += el.amount;
      }
      if (el.component.section === 'deductions') {
        acc.deductionsSum.value += el.amount;
      }
      if (el.component.section === 'accruals') {
        acc.accrualsSum += el.amount;
      }
      return acc;
    },
    {
      accrualsSum: 0,
      payoutsSum: { name: 'accruals', text: t('my_salary_paid'), value: 0 },
      deductionsSum: { name: 'deductions', text: t('my_salary_held'), value: 0 },
    },
  );

  const badgeData = [
    payoutsSum,
    deductionsSum,
    {
      name: 'payouts',
      text: t('my_salary_accrued'),
      value: accrualsSum,
    },
  ];

  const configData = {
    name: 'data',
    children: [
      {
        value: 1,
        children: [payoutsSum, deductionsSum],
      },
      {
        name: 'payouts',
        value: 2,
        children: [
          {
            value: 100,
          },
        ],
      },
    ],
  };

  return {
    badgeData,
    configData,
    payoutsSum,
  };
};
