export type HotContactsType = {
  createdAt: string;
  creator: string;
  editor: string;
  email: string;
  id: string;
  name: string;
  updatedAt: string;
  contacts: HotContactType[];
};

export type HotContactType = {
  email: string;
  internalPhone: string;
  num: number;
  phone: string;
};

export type SettlementType = {
  code: string;
  coordinates: string;
  createdAt: string;
  creator: string;
  editor: string;
  fileInstruction: {
    num: number;
    link: string;
    name: string;
    type: string;
    size: number;
  };
  fileMap: {
    num: number;
    link: string;
    name: string;
    type: string;
    size: number;
  };
  id: string;
  isPublish: boolean;
  name: string;
  telegramId: string | null;
  updatedAt: string;
};

export type HistoryItem = {
  creator: string;
  editor: string;
  id: string;
  isSelectedNow: boolean;
  registerAt: string;
  settlement: { id: string; name: string; code: string };
  unregisterAt: string;
};

export type GuideMemoInstructionPhoneType = {
  num: number;
  phone: string;
  internalPhone: string;
  email: string;
};

export type GuideMemoCategoryType = {
  id: string;
  name: string;
  withEtaps: boolean;
};

export type GuideMemoInstructionType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  schedule: string;
  location: string;
  department: string;
  employer: string;
  num: number;
  contacts: GuideMemoInstructionPhoneType[];
  name: string;
  file: {
    link: string;
    name: string;
  };
};

export enum MODALS_QR_ENUM {
  FOUND = 'found',
  NOT_FOUND = 'notFound',
  FOUND_GEO = 'foundGeo',
  FOUND_SEVERAL = 'foundSeveralGeo',
  NOT_FOUND_GEO = 'notFoundGeo',
  NO_ACCESS_GEO = 'noAccessGeo',
  EMPTY = '',
}
