import React, { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { useLeaveTheMeetingMutation } from 'services/meet/meetApiService';
import { LeaveTheMeetingPropsType } from 'services/meet/meetApiService.types';
import { addRemoveParticipation } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { Button, ModalDefault, handleRequest } from 'gazprom-ui-lib';

interface HomeNewsDetailsContentEventCancelModalProps {
  open: boolean;
  handleResetModalType: () => void;
  mutationProps: LeaveTheMeetingPropsType;
  handleParticipation: () => void;
  setModalType: Dispatch<SetStateAction<string>>;
  isCancelSubmitted?: boolean;
}

const HomeNewsDetailsContentEventCancelModal: FC<HomeNewsDetailsContentEventCancelModalProps> = (
  props,
) => {
  const {
    open,
    handleResetModalType,
    mutationProps,
    handleParticipation,
    setModalType,
    isCancelSubmitted = false,
  } = props;
  const dispatch = useAppDispatch();

  const [leaveTheMeeting, { isLoading: leaveIsLoading }] = useLeaveTheMeetingMutation();

  const onSuccess = () => {
    dispatch(addRemoveParticipation({ id: mutationProps.id, isParticipant: false }));
    handleParticipation();
    setModalType('cancelSubmit');
  };

  const handleClick = () => {
    if (isCancelSubmitted) {
      handleResetModalType();
    } else {
      leaveTheMeeting(mutationProps).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  return (
    <ModalDefault open={open} onCancel={handleResetModalType}>
      <ModalDefault.Icon
        name={isCancelSubmitted ? 'ok' : 'eventCancel'}
        type={isCancelSubmitted ? 'success' : 'warning'}
      />
      <ModalDefault.Title>
        {t(isCancelSubmitted ? 'common_you_cancelled_appointment' : 'common_are_you_sure')}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(
          isCancelSubmitted
            ? 'home_event_you_cancelled_appointment'
            : 'home_event_cancel_description',
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          size="large"
          loading={leaveIsLoading}
          leftIcon={!isCancelSubmitted ? 'ok' : undefined}
          onClick={handleClick}
          fullWidth>
          {t(isCancelSubmitted ? 'common_ok' : 'common_confirm')}
        </Button>
        {!isCancelSubmitted && (
          <Button size="large" type="secondary" onClick={handleResetModalType} fullWidth>
            {t('common_close')}
          </Button>
        )}
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default HomeNewsDetailsContentEventCancelModal;
