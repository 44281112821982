import React, { FC, memo, useCallback, useEffect, useRef } from 'react';

import cn from 'classnames';
import { formRules } from 'utils/formRules.utils';
import { UploadedFileType } from 'utils/useUploadFile.hook';

import { Form } from 'gazprom-ui-lib';

import UploadFile from 'components/upload-file';

import { FileType } from 'types/file.types';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';
import s from './AttributeMultifile.module.scss';

export interface AttributeMultifileProps extends AttributeDefaultProps {}

const MAX_FILES_COUNT = 9;
const DEFAULT_FILES: string[] = [];
const AttributeMultifile: FC<AttributeMultifileProps> = (props) => {
  const { id, isMandatory, isReadonly, files: initialFiles, value, onSaveDraft } = props;

  const form = Form.useFormInstance();
  const files: UploadedFileType[] = Form.useWatch(id);
  const uploadedDocsCount = files?.length;

  const initialValueSet = useRef<boolean>(false);

  const handleSaveDraft = useCallback(() => {
    if (onSaveDraft) {
      onSaveDraft(form);
    }
  }, [form, onSaveDraft]);

  const customFileSet = useCallback(
    (index: number) => (config: UploadedFileType) => {
      const copiedFiles = [...files];
      copiedFiles[index] = config;
      form.setFieldValue(id, copiedFiles);
    },
    [files, form, id],
  );

  const renderUpload = useCallback(
    (_: unknown, index: number) => {
      const writeIsVisible = index === 0 || uploadedDocsCount - index >= 0;
      const readOnlyIsVisible = !!files?.[index];
      const isVisible = isReadonly ? readOnlyIsVisible : writeIsVisible;
      const key = `${id}__multifile__${Math.random().toString(36).slice(2, 11)}`;

      return (
        <UploadFile
          handleSaveDraft={handleSaveDraft}
          disabled={isReadonly}
          key={key}
          name={id}
          className={cn(s.hidden, { [s.visible]: isVisible })}
          customFileSet={customFileSet(index)}
          initialValue={files?.[index]}
          skipMediaPrefix
        />
      );
    },
    [customFileSet, files, handleSaveDraft, id, isReadonly, uploadedDocsCount],
  );

  useEffect(() => {
    const isDefaultValueCanBeSet =
      !!value && form && !!initialFiles?.length && !initialValueSet.current;

    if (isDefaultValueCanBeSet) {
      const fileNames = value.split(',');
      const getCurrentFiles = (file: FileType & { key: string }) => file.attributeId === id;
      const modifyCurrentFiles = (file: FileType & { key: string }, index: number) => {
        const { key } = file;
        const name = fileNames[index];
        return {
          fileName: name,
          key,
          name,
          url: key,
        };
      };

      const modifiedFilesToSet = initialFiles?.filter(getCurrentFiles).map(modifyCurrentFiles);

      form.setFieldValue(id, modifiedFilesToSet);
      initialValueSet.current = true;
    }
  }, [initialValueSet, files, form, id, initialFiles, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item
        name={id}
        rules={isMandatory ? [formRules.required] : []}
        initialValue={DEFAULT_FILES}
        className={s.wrapper}>
        {Array.from({ length: MAX_FILES_COUNT }).map(renderUpload)}
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeMultifile);
