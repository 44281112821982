import { EducationType } from 'types/topManagement.types';

export enum EDUCATION_FORM_ENUM {
  EDUCATION_NAME = 'educationName',
  EDUCATION_DATE = 'educationDate',
  QUALIFICATION = 'qualification',
  SERIES = 'documentSeries',
  NUMBER = 'documentNumber',
  ISSUE_DATE = 'documentIssueDate',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}

export type MappedEducationType = Partial<EducationType> & { id: number };
