import React, { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import WithLoader from 'containers/wrappers/with-loader';

import s from './Certificate.module.scss';
import useCertificationConfig from './useCertificationConfig.hook';

interface CertificateProps {
  setSmsModalOperationId: Dispatch<SetStateAction<string>>;
}

const Certificate: FC<CertificateProps> = (props) => {
  const { setSmsModalOperationId } = props;

  const { button, description, isLoading } = useCertificationConfig({
    setSmsModalOperationId,
  });

  const loader = (
    <Flex fullWidth justify="center" align="center" className={s.certificateLoader}>
      <UniversalLoader />
    </Flex>
  );

  return (
    <Card>
      <Flex fullWidth vertical gap="12">
        <Typography.Title level={3}>{t('common_documents')}</Typography.Title>
        <WithLoader isLoading={isLoading} loader={loader}>
          {description && (
            <Typography.Text type="secondary" size="14" className={s.text}>
              {description}
            </Typography.Text>
          )}
        </WithLoader>
        {!isLoading && button}
      </Flex>
    </Card>
  );
};

export default Certificate;
