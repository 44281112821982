import { FC, useEffect, useMemo } from 'react';

import { Checkbox } from 'antd';

import { MultiSelectAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form } from 'gazprom-ui-lib';

import SurveyTitle from 'pages/surveys/surveys-question-title';

import { Options } from 'types/surveys.types';

import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsMultiselect.module.scss';

export interface SurveysQuestionsMultiselectProps extends QuestionProps {
  no: number;
  is_required: boolean;
  title: string;
  options: Options[];
}

const SurveysQuestionsMultiselect: FC<SurveysQuestionsMultiselectProps> = (props) => {
  const { id, type, no, title, options, is_required } = props;
  const name = `${type}.${id}`;
  const form = Form.useFormInstance();
  const { isCompleted, answers } = useAppSelector((store) => store.surveySlice);
  const answer = (
    answers?.answer.find((answer) => answer.question_id === id) as MultiSelectAnswerType | undefined
  )?.question_answer.answer?.options
    .filter((option) => option.answer_value === true)
    .map((answers) => answers.option_id);

  useEffect(() => {
    if (answer) {
      form.setFieldValue(name, answer);
    }
  }, [answer, form, name]);

  const groupOptions = useMemo(
    () => options.map((option) => ({ label: option.title, value: option.id })),
    [options],
  );

  return (
    <div className={s.multiselectContainer}>
      <SurveyTitle level={5} number={no} required={is_required}>
        {title}
      </SurveyTitle>
      <Form.Item name={name}>
        <Checkbox.Group options={groupOptions} disabled={isCompleted} />
      </Form.Item>
    </div>
  );
};

export default SurveysQuestionsMultiselect;
