import React, { useState } from 'react';

import { getDocumentsSocialPolicyDetailsDetailsRoute } from 'routes/documents/list';
import { getSocialPolicyProgramApplyFromDraftRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  useGetKedoTasksDraftsQuery,
  useGetKedoTasksQuery,
  useGetSocialTasksWithCountersQuery,
} from 'services/kedo/kedoApiService';

import { Flex, Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import DocumentsList from 'pages/documents/documents-list';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { TASK_TYPE_ENUM } from 'types/documents.types';

import s from './DocumentsSocialPolicy.module.scss';
import PageDescription from './containers/page-description';
import Statistics from './containers/statistics';
import { SEGMENT_OPTIONS, SegmentedValueType, headerChildren } from './documentsSocialPolicy.utils';

const DocumentsSocialPolicy = () => {
  const [fullSelectedEmployee] = useFullSelectedEmployee();
  const [selectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );
  const [selectedSegment, setSelectedSegment] = useState<SegmentedValueType>(
    SEGMENT_OPTIONS[0].value,
  );

  const isDraft = selectedSegment === 'draft';

  const {
    data: socialKedoTasks,
    isLoading: socialKedoTasksIsLoading,
    refetch: refetchSocialKedoTasks,
  } = useGetKedoTasksQuery(
    {
      myEmployeeId: fullSelectedEmployee?.id!,
      type: TASK_TYPE_ENUM.SOCIAL,
      page: 1,
      size: 500,
      statuses: isDraft ? ['SOCIAL_DRAFT'] : undefined,
    },
    {
      skip: !fullSelectedEmployee,
    },
  );
  const {
    data: socialKedoTasksDrafts,
    isLoading: socialKedoTasksDraftsIsLoading,
    refetch: refetchSocialKedoDrafts,
  } = useGetKedoTasksDraftsQuery(
    {
      type: TASK_TYPE_ENUM.SOCIAL,
      myEmployeeId: fullSelectedEmployee?.id!,
      page: 1,
      size: 500,
    },
    {
      skip: !fullSelectedEmployee,
    },
  );
  const {
    data: socialTasks,
    isLoading: socialTasksIsLoading,
    refetch: refetchSocialTasks,
  } = useGetSocialTasksWithCountersQuery(selectedEmployee, {
    skip: !selectedEmployee,
  });

  const handleRefresh = () => {
    refetchSocialTasks();

    if (isDraft) {
      refetchSocialKedoDrafts();
    } else {
      refetchSocialKedoTasks();
    }
  };

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSelectedSegment(segment as SegmentedValueType);

  const isLoading =
    socialTasksIsLoading || socialKedoTasksIsLoading || socialKedoTasksDraftsIsLoading;

  const filteredTasks = isDraft ? socialKedoTasksDrafts?.drafts : socialKedoTasks?.content;

  const getNavigateRoute = isDraft
    ? getSocialPolicyProgramApplyFromDraftRoute
    : getDocumentsSocialPolicyDetailsDetailsRoute;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={handleRefresh}>
          <WithBackground>
            <PageDescription />
            <Statistics {...socialTasks} isLoading={isLoading} />
            <Card withPadding={false}>
              <WithLoader isLoading={isLoading}>
                <Flex vertical fullWidth className={s.segmentedWrapper} gap="16">
                  <Segmented
                    options={SEGMENT_OPTIONS}
                    value={selectedSegment}
                    onChange={handleSegmentChange}
                    fullWidth
                  />
                  {!!socialKedoTasks?.totalCount && (
                    <Typography.Text type="primary" weight="700">
                      {t('documents_found_count', { count: socialKedoTasks?.totalCount })}
                    </Typography.Text>
                  )}
                </Flex>
                <DocumentsList
                  isDraft={isDraft}
                  filteredTasks={filteredTasks ?? []}
                  getNavigateRoute={getNavigateRoute}
                />
              </WithLoader>
            </Card>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsSocialPolicy;
