import React, { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Form, Input } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

import { FORM_ENUM } from '../../businessTripForm.utils';

const Source = () => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const form = Form.useFormInstance();

  const [selectedEmployee] = useFullSelectedEmployee();
  const textValue = Form.useWatch(FORM_ENUM.SOURCE);

  const handleTextAreaModalOpenClose = () => {
    setIsModalOpened((prevState) => !prevState);
  };

  const onSubmitHandler = (value: Record<string, string>) => {
    form.setFieldValue(FORM_ENUM.SOURCE, value[FORM_ENUM.SOURCE_TEXTAREA]);
    setIsModalOpened(false);
  };

  useEffect(() => {
    if (selectedEmployee) {
      const { name } = selectedEmployee.employer ?? {};
      form.setFieldValue(FORM_ENUM.SOURCE, name);
    }
  }, [form, selectedEmployee]);

  return (
    <>
      <Form.Item name={FORM_ENUM.SOURCE} label={t('common_source_of_financing')}>
        <Input readOnly onClick={handleTextAreaModalOpenClose} />
      </Form.Item>
      <TextAreaModal
        name={FORM_ENUM.SOURCE_TEXTAREA}
        modalHeaderTitle={t('common_source_of_financing')}
        title={t('common_source_of_financing')}
        maxLength={250}
        defaultValue={textValue}
        isModalOpen={isModalOpened}
        onSubmitHandler={onSubmitHandler}
        setIsModalOpen={handleTextAreaModalOpenClose}
      />
    </>
  );
};

export default Source;
