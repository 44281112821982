import React, { FC, memo } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { HOME_NEWS_TAGS_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './NewsTagsActions.module.scss';

interface HomeNewsTagsActionsProps {
  tagsLength: number;
  handleReset: () => void;
}

const NewsTagsActions: FC<HomeNewsTagsActionsProps> = (props) => {
  const { tagsLength, handleReset } = props;
  const location = useLocation();

  return (
    <div className={s.tagActions}>
      <Link to={HOME_NEWS_TAGS_ROUTE} className={s.selectedTagsLink} state={location?.state}>
        <Typography.Text size="14" type="primary" weight="500">
          {t('home_news_tags_selected', { count: tagsLength })}
        </Typography.Text>
      </Link>
      <button onClick={handleReset} className={s.resetButton}>
        <Typography.Text size="14" type="secondary">
          <Icon name="delete" />
          {t('common_reset_choice')}
        </Typography.Text>
      </button>
    </div>
  );
};

export default memo(NewsTagsActions);
