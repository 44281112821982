import React, { FC, useContext, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import Picker from 'react-mobile-picker';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useDateOptions from 'utils/useDateOptions.hook';

import { Button, Form, Input, Modal, Typography } from 'gazprom-ui-lib';

import { CURRENT_DATE, CURRENT_MONTH, CURRENT_YEAR } from 'constants/dates';

import { BusinessTripContext, FORM_ENUM, formatDate } from '../../businessTripForm.utils';
import s from './Dates.module.scss';
import { ModalConfigType } from './dates.utils';

interface DatesProps {
  hideStartDate?: boolean;
}

const Dates: FC<DatesProps> = (props) => {
  const { hideStartDate } = props;

  const [modalConfig, setModalConfig] = useState<null | ModalConfigType>(null);
  const { type } = useContext(BusinessTripContext);

  const form = Form.useFormInstance();
  const fromValue = Form.useWatch(FORM_ENUM.FROM);
  const toValue = Form.useWatch(FORM_ENUM.TO);

  const handleOpenModal = (fromToDate: Dayjs, type: FORM_ENUM.FROM | FORM_ENUM.TO) => () => {
    setModalConfig(() => {
      const date = dayjs(fromToDate).get('date').toString();
      const month = dayjs(fromToDate).get('month');
      const year = dayjs(fromToDate).get('year');

      return {
        type,
        date,
        month: month!.toString(),
        year: year!.toString(),
      };
    });
  };

  const handleModalClose = () => setModalConfig(null);

  const handleSetDate = () => {
    form.setFields([
      { name: FORM_ENUM.FROM_VISIBLE, errors: [] },
      { name: FORM_ENUM.TO_VISIBLE, errors: [] },
    ]);
    if (modalConfig) {
      const errors: string[] = [];

      const newDate = dayjs(
        new Date(Number(modalConfig.year), Number(modalConfig.month), Number(modalConfig.date)),
      );

      if (modalConfig.type === FORM_ENUM.TO) {
        if (fromValue && dayjs(fromValue).isAfter(newDate)) {
          errors.push(t('errors_incorrect_date'));
        }
      } else {
        if (toValue && dayjs(toValue).isBefore(newDate)) {
          errors.push(t('errors_incorrect_date'));
        }
      }

      form.setFields([
        {
          name: `${modalConfig.type}_VISIBLE`,
          value: newDate.format('DD.MM.YYYY'),
          errors,
        },
        {
          name: modalConfig.type,
          value: newDate,
          errors,
        },
      ]);
      handleModalClose();
    }
  };

  const { yearOptions, monthOptions, dateOptions } = useDateOptions({
    year: Number(modalConfig?.year),
    month: Number(modalConfig?.month),
    maxYear: CURRENT_YEAR + 1,
    minYear: CURRENT_YEAR,
    showOnlyAfterToday: true,
  });

  const handlePickerChange = (e: ModalConfigType) => {
    const preparedDate = { ...e };
    const daysInMonth = dayjs(new Date(Number(e.year), Number(e.month))).daysInMonth();

    const isCurrentYear = Number(e.year) === CURRENT_YEAR;
    let isCurrentMonth = Number(e.month) === CURRENT_MONTH;
    const minMonth = isCurrentYear ? CURRENT_MONTH : 0;

    if (Number(e.month) < minMonth) {
      preparedDate.month = minMonth.toString();
      isCurrentMonth = minMonth === CURRENT_MONTH;
    }

    const minDate = isCurrentMonth && isCurrentYear ? CURRENT_DATE : 0;

    if (Number(e.date) < minDate) {
      preparedDate.date = minDate.toString();
    } else if (Number(preparedDate.date) > daysInMonth) {
      preparedDate.date = daysInMonth.toString();
    }

    setModalConfig(preparedDate);
  };

  const selections = {
    date: dateOptions,
    month: monthOptions,
    year: yearOptions,
  };

  let fromLabel = t('common_start_date');
  let toLabel = t('common_end_date');

  if (type === 'edit') {
    fromLabel = t('common_start_date_new');
    toLabel = t('common_end_date_new');
  }

  return (
    <>
      {!hideStartDate && (
        <Form.Item name={FORM_ENUM.FROM_VISIBLE} label={fromLabel} rules={[formRules.required]}>
          <Input
            onClick={handleOpenModal(fromValue, FORM_ENUM.FROM)}
            value={formatDate(fromValue)}
            readOnly
          />
        </Form.Item>
      )}
      <Form.Item name={FORM_ENUM.TO_VISIBLE} label={toLabel} rules={[formRules.required]}>
        <Input
          onClick={handleOpenModal(toValue, FORM_ENUM.TO)}
          value={formatDate(toValue)}
          readOnly
        />
      </Form.Item>
      {!hideStartDate && <Form.Item name={FORM_ENUM.FROM} noStyle />}
      <Form.Item name={FORM_ENUM.TO} noStyle />
      <Modal open={!!modalConfig} onCancel={handleModalClose} closable>
        <div className={s.title}>
          <Typography.Title level={5} className={s.title}>
            {t('common_select_date')}
          </Typography.Title>
        </div>
        <div className={s.pickerWrapper}>
          <div className={s.borderImitation} />
          <div className={s.secondBorderImitation} />
          <Picker
            className={s.picker}
            value={modalConfig as ModalConfigType}
            onChange={handlePickerChange}
            itemHeight={48}
            height={144}>
            {Object.keys(selections).map((name: string) => (
              <Picker.Column key={`${name}__modal__date__select`} name={name}>
                {selections[name as keyof typeof selections].map((option) => {
                  const isSelected =
                    option.value?.toString() === modalConfig?.[name as keyof typeof selections];

                  return (
                    <Picker.Item key={`${name}__${option.label}`} value={option.value?.toString()!}>
                      <Typography.Title strong={false} level={4} disabled={!isSelected}>
                        {option.label}
                      </Typography.Title>
                    </Picker.Item>
                  );
                })}
              </Picker.Column>
            ))}
          </Picker>
        </div>
        <div className={s.actions}>
          <Button fullWidth size="large" leftIcon="ok" onClick={handleSetDate}>
            {t('common_confirm')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Dates;
