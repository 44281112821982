import React, { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './EventTags.module.scss';

interface EventTagsProps {
  isParticipant?: boolean;
  eventIsInFuture?: boolean;
  city?: string;
  eventDate?: string;
}

const EventTags: FC<EventTagsProps> = (props) => {
  const { isParticipant, eventIsInFuture, city, eventDate } = props;

  return (
    <div className={s.tagsWrapper}>
      <Typography.Text size="12" className={cn(s.tag, s.blueTag)} weight="500">
        <Icon name="eventFilled" />
        <span>{t('common_event')}</span>
      </Typography.Text>
      {isParticipant && (
        <Typography.Text
          size="12"
          className={cn(s.tag, eventIsInFuture ? s.participantTag : s.participatedTag)}
          weight="500">
          <Icon name="ok" />
          <span>
            {t(eventIsInFuture ? 'home_event_you_participate' : 'home_event_you_participated')}
          </span>
        </Typography.Text>
      )}
      <Typography.Text size="12" type="primary" className={cn(s.tag, s.grayTag)} weight="500">
        <Icon name="calendar" />
        <span>{eventDate}</span>
      </Typography.Text>
      <Typography.Text size="12" type="primary" className={cn(s.tag, s.grayTag)} weight="500">
        <Icon name="location" />
        <span>{city}</span>
      </Typography.Text>
    </div>
  );
};

export default EventTags;
