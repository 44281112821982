import dayjs from 'dayjs';
import { getTopManagementByStageRoute } from 'routes/documents/list';
import { HOME_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';

import { PageParamsType, TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import AdditionalEducationInfoForm from './containers/additional-education-info-form';
import CommonInfoForm from './containers/common-info-form';
import ContactInfoForm from './containers/contact-info-form';
import DriverLicenseForm from './containers/driver-license-form';
import EducationInfoForm from './containers/education-info-form';
import FamilyForm from './containers/family-form';
import InternationalPassportForm from './containers/international-passport-form';
import LanguageForm from './containers/languages-form';
import MilitaryInfoForm from './containers/military-info-form';
import OtherInfoForm from './containers/other-info-form';
import SoftwareForm from './containers/software-form';
import WorkExperienceInfoForm from './containers/work-experience-info-form';

export const pageParams: PageParamsType = {
  [TOP_MANAGEMENT_STAGE_ENUM.COMMON]: {
    goBack: HOME_ROUTE,
    label: t('my_personal_data_general'),
    component: <CommonInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.INTERNATIONAL]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.COMMON),
    label: t('common_international_passport'),
    component: <InternationalPassportForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.DRIVER_LICENSE]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.INTERNATIONAL),
    label: t('common_driver_license'),
    component: <DriverLicenseForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.CONTACTS]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.DRIVER_LICENSE),
    label: t('common_contacts_info'),
    component: <ContactInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.MILITARY]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.CONTACTS),
    label: t('top_management_military_info'),
    component: <MilitaryInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.EDUCATION]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.MILITARY),
    label: t('top_management_education_info'),
    component: <EducationInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.ADDITIONAL_EDUCATION]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.EDUCATION),
    label: t('top_management_add_additional_education'),
    component: <AdditionalEducationInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.WORK_EXPERIENCE]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.ADDITIONAL_EDUCATION),
    label: t('top_management_work_experience_label'),
    component: <WorkExperienceInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.OTHER]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.WORK_EXPERIENCE),
    label: t('top_management_other'),
    component: <OtherInfoForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.LANGUAGES]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.OTHER),
    label: t('top_management_languages_short'),
    component: <LanguageForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.SOFTWARE]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.LANGUAGES),
    label: t('top_management_software_short'),
    component: <SoftwareForm />,
  },
  [TOP_MANAGEMENT_STAGE_ENUM.FAMILY]: {
    goBack: getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.SOFTWARE),
    label: t('top_management_family'),
    component: <FamilyForm />,
  },
};

export const parseBySeparator = (data: Record<string, string>): Record<string, string>[] => {
  const groupedByIndex = Object.entries(data).reduce((acc, [key, value]) => {
    const [name, index] = key.split('_');

    if (!index) {
      return acc;
    }

    if (!acc[index]) {
      acc[index] = {};
    }

    acc[index][name] = value;

    return acc;
  }, {} as Record<string, Record<string, string>>);

  return Object.values(groupedByIndex);
};

export const filterFieldsBySubstring = (
  obj: Record<string, string>,
  substringFirst: string,
  substringSecond?: string,
) => {
  const result: Record<string, string> = {};
  for (const key in obj) {
    if (key.includes(substringFirst) || (substringSecond && key.includes(substringSecond))) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const formatDatesInObject = (obj: Record<string, string>) => {
  const result: Record<string, string> = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (key.toLowerCase().includes('date')) {
        result[key] = dayjs(value as string).format('YYYY-MM-DD');
      } else {
        result[key] = value;
      }
    }
  }

  return result;
};
