import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  AddCommentPropsType,
  AddCommentResponseType,
  AddLikePropsType,
  AddLikeResponseType,
  EditCommentPropsType,
  EditCommentResponseType,
  GetArticleByIdCommentsPropsType,
  GetArticleByIdCommentsResponseType,
  GetArticleByIdLikesPropsType,
  GetArticleByIdLikesResponseType,
  GetArticleByIdPropsType,
  GetArticleByIdResponseType,
  GetFullArticlesPropsType,
  GetFullArticlesResponseType,
  GetLatestNewsPropsType,
  GetLatestNewsResponseType,
  RemoveLikePropsType,
  RemoveLikeResponseType,
} from './articleApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { createArticleUrl, createMeUrl } from './utils';

const articleApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFullArticles: builder.query<GetFullArticlesResponseType, GetFullArticlesPropsType>({
      query: (params) => ({
        url: createArticleUrl(`-full${prepareQueryParams(params)}`),
      }),
    }),
    getLatestNews: builder.query<GetLatestNewsResponseType, GetLatestNewsPropsType>({
      query: () => ({
        url: createMeUrl(`latest-news`),
      }),
    }),

    getArticleById: builder.query<GetArticleByIdResponseType, GetArticleByIdPropsType>({
      query: (newsCode) => ({
        url: createArticleUrl(`-full/${newsCode}`),
      }),
      providesTags: [SERVICE_TAGS.GET_ARTICLE_BY_ID],
    }),

    getArticleByIdLikes: builder.query<
      GetArticleByIdLikesResponseType,
      GetArticleByIdLikesPropsType
    >({
      query: (newsCode) => ({
        url: createArticleUrl(`/${newsCode}/like`),
      }),
    }),
    getArticleByIdComments: builder.query<
      GetArticleByIdCommentsResponseType,
      GetArticleByIdCommentsPropsType
    >({
      query: ({ newsCode, ...other }) => ({
        url: createArticleUrl(`/${newsCode}/comments${prepareQueryParams(other)}`),
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        const cacheArticleId = currentCache?.items?.[0]?.articleId;
        const newArticleId = newItems?.items?.[0]?.articleId;

        const isValid = newArticleId && cacheArticleId;
        const idsAreEqual = cacheArticleId === newArticleId;

        if (isValid && idsAreEqual) {
          newItems.items.forEach((item) => {
            const notInCache = !currentCache.items.find((cacheItem) => cacheItem.id === item.id);
            if (notInCache) {
              currentCache.items.push(item);
            }
          });
          currentCache.totalCount = newItems.totalCount;
        } else {
          return newItems;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.newsCode !== previousArg?.newsCode || currentArg?.page !== previousArg?.page
        );
      },
    }),
    addLike: builder.mutation<AddLikeResponseType, AddLikePropsType>({
      query: (newsCode) => ({
        url: createArticleUrl(`/${newsCode}/like`),
        method: 'POST',
      }),
      // invalidatesTags: [SERVICE_TAGS.GET_ARTICLE_BY_ID],
    }),
    removeLike: builder.mutation<RemoveLikeResponseType, RemoveLikePropsType>({
      query: (newsCode) => ({
        url: createArticleUrl(`/${newsCode}/like`),
        method: 'DELETE',
      }),
      // invalidatesTags: [SERVICE_TAGS.GET_ARTICLE_BY_ID],
    }),
    addComment: builder.mutation<AddCommentResponseType, AddCommentPropsType>({
      query: ({ newsCode, comment }) => ({
        url: createArticleUrl(`/${newsCode}/comments`),
        method: 'POST',
        body: comment,
      }),
      async onQueryStarted({ newsCode }, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;

        if ('data' in res) {
          dispatch(
            articleApiService.util.updateQueryData(
              'getArticleByIdComments',
              { newsCode, page: 1, size: 10 },
              (draft: GetArticleByIdCommentsResponseType) => {
                draft.items.unshift(res.data);
                draft.totalCount += 1;
              },
            ),
          );
        }
      },
    }),
    editComment: builder.mutation<EditCommentResponseType, EditCommentPropsType>({
      query: ({ newsCode, commentId, comment }) => ({
        url: createArticleUrl(`/${newsCode}/comments/${commentId}`),
        method: 'PUT',
        body: comment,
      }),
      async onQueryStarted({ newsCode }, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled;

        if ('data' in res) {
          dispatch(
            articleApiService.util.updateQueryData(
              'getArticleByIdComments',
              { newsCode, page: 1, size: 10 },
              (draft: GetArticleByIdCommentsResponseType) => {
                const foundComment = draft.items.find((prev) => prev.id === res.data.id);
                if (foundComment) {
                  foundComment.comment = res.data.comment;
                }
              },
            ),
          );
        }
      },
    }),
  }),
});

export const {
  useGetLatestNewsQuery,
  useLazyGetFullArticlesQuery,
  useGetFullArticlesQuery,
  useGetArticleByIdQuery,
  useGetArticleByIdLikesQuery,
  useLazyGetArticleByIdQuery,
  useGetArticleByIdCommentsQuery,
  useAddCommentMutation,
  useAddLikeMutation,
  useRemoveLikeMutation,
  useEditCommentMutation,
} = articleApiService;
