import { FC, Fragment } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Divider, Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import DatePicker from '../../../date-picker';
import { OTHER_INFORMATION_FORM_ENUM } from '../../otherInfoForm.utils';
import s from './FsppSection.module.scss';

interface FsppSectionProps {
  sectionId: number;
  fsppObligationsNum?: string;
  fsppObligationsDate?: string;
  onRemove: () => void;
}

const FsppSection: FC<FsppSectionProps> = (props) => {
  const { fsppObligationsDate, fsppObligationsNum, sectionId, onRemove } = props;

  return (
    <>
      {!!sectionId && <Divider />}
      <Flex vertical gap="24" key={sectionId} className={s.container}>
        <Form.Item
          className={s.titleContainer}
          name={`${OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_NUM}_${sectionId}`}
          label={
            <>
              <Typography.Title level={5}>{t('top_management_other_fspp_num')}</Typography.Title>
              <Button leftIcon="bin" type="text" onClick={onRemove} />
            </>
          }
          initialValue={fsppObligationsNum}
          rules={[formRules.required]}>
          <Input name="" size="large" placeholder={t('common_enter_text')} />
        </Form.Item>
        <DatePicker
          name={`${OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_DATE}_${sectionId}`}
          label={t('top_management_other_fspp_date')}
          required
          initialValue={fsppObligationsDate}
          yearConfig={{
            initialMinYear: 1950,
            initialMaxYear: 2099,
          }}
        />
      </Flex>
    </>
  );
};

export default FsppSection;
