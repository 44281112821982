import { useState } from 'react';

import {
  getDocumentsStatementRequestRoute,
  getDocumentsStatementsDetailsRoute,
} from 'routes/documents/list';
import { fetchMoreData } from 'utils/fetchMoreData';

import { useGetKedoCategoriesQuery, useGetKedoStatusesQuery } from 'services/kedo/kedoApiService';

import { Segmented } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithBackground from 'containers/wrappers/with-background';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useActions from 'pages/documents/documents-inquiries/useActions.hook';
import PageDescription from 'pages/documents/documents-statements/containers/page-description';
import useGetStatements from 'pages/documents/documents-statements/useGetStatements.hook';

import { KedoTask, KedoTaskDraft, TASK_STATUS_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';
import { PaginationPropsType } from 'types/pagination.types';

import DocumentsList from '../documents-list';
import DocumentsStatusesAndCategories from '../documents-statuses-and-categories';
import { ALL_CATEGORIES, DEFAULT_ALL_STATUS } from '../documents.utils';
import s from './DocumentsStatements.module.scss';
import {
  DEFAULT_PAGINATION_CONFIG,
  SEGMENT_OPTIONS,
  SegmentedValueType,
  headerChildren,
} from './documentsStatements.utils';

const DocumentsStatements = () => {
  const [pagination, setPagination] = useState<PaginationPropsType>(DEFAULT_PAGINATION_CONFIG);
  const [activeCategory, setActiveCategory] = useState<string>(ALL_CATEGORIES.id);
  const [activeStatus, setActiveStatus] = useState<string>(TASK_STATUS_ENUM.ALL);
  const [tasksToShow, setTasksToShow] = useState<KedoTask[]>([]);
  const [draftsToShow, setDraftsToShow] = useState<KedoTaskDraft[]>([]);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [selectedSegment, setSelectedSegment] = useState<SegmentedValueType>(
    SEGMENT_OPTIONS[0].value,
  );
  const isDraft = selectedSegment === 'draft';

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    refetch: refetchCategories,
  } = useGetKedoCategoriesQuery({
    type: TASK_TYPE_ENUM.BPMN,
  });

  const { data: kedoStatuses = [] } = useGetKedoStatusesQuery({ type: TASK_TYPE_ENUM.BPMN });

  const { tasks, drafts, isFetching, isLoading, isUninitialized } = useGetStatements({
    activeStatus,
    activeCategory,
    setTasksToShow,
    setDraftsToShow,
    isDraft,
    pagination,
    isRefreshing,
  });

  const statuses = [DEFAULT_ALL_STATUS, ...kedoStatuses];

  const totalCount = isDraft ? drafts?.totalCount : tasks?.totalCount;

  const isFetchMoreAvailable = pagination.page * pagination.size < (totalCount ?? 0);
  const infiniteScrollIsFetching = isFetching || isUninitialized;

  const filteredTasks = isDraft ? draftsToShow : tasksToShow;

  const navigateRoute = isDraft
    ? getDocumentsStatementRequestRoute
    : getDocumentsStatementsDetailsRoute;

  const { handlePageRefresh, handleSegmentChange, handleSetActiveCategory, handleSetActiveStatus } =
    useActions({
      activeStatus,
      activeCategory,
      setIsRefreshing,
      isDraft,
      setPagination,
      setTasksToShow,
      setDraftsToShow,
      refetchCategories,
      setSelectedSegment,
      setActiveCategory,
      setActiveStatus,
    });

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={handlePageRefresh}>
          <WithBackground>
            <PageDescription />
            <Card withPadding={false}>
              <WithLoader isLoading={isCategoriesLoading || isLoading}>
                <Segmented
                  className={s.segmented}
                  options={SEGMENT_OPTIONS}
                  value={selectedSegment}
                  onChange={handleSegmentChange}
                  fullWidth
                />
                <DocumentsStatusesAndCategories
                  showStatuses={!isDraft}
                  activeCategory={activeCategory}
                  setActiveCategory={handleSetActiveCategory}
                  activeStatus={activeStatus}
                  setActiveStatus={handleSetActiveStatus}
                  categories={categories}
                  count={totalCount ?? 0}
                  statuses={statuses}
                />
                <WithInfiniteScroll
                  fetchMoreData={fetchMoreData(setPagination)}
                  isFetching={infiniteScrollIsFetching}
                  isFetchMoreAvailable={isFetchMoreAvailable}>
                  <DocumentsList
                    isLoading={infiniteScrollIsFetching}
                    isDraft={isDraft}
                    filteredTasks={filteredTasks ?? []}
                    getNavigateRoute={navigateRoute}
                  />
                </WithInfiniteScroll>
              </WithLoader>
            </Card>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsStatements;
