import { Dispatch, SetStateAction, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { PROFILE_AUTH_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useSecureStorage from 'utils/useSecureStorage.hook';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import { StepsType } from './profileAuthChangePin.utils';

interface UseActionsProps {
  setPass: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string>>;
  isSuccess: boolean;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<StepsType>>;
  step: StepsType;
}

const useActions = (props: UseActionsProps) => {
  const { setPass, setError, isSuccess, setIsSuccess, setStep, step } = props;

  const navigate = useNavigate();
  const newPinRef = useRef<string>('');

  const [installedPin, setInstalledPin] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS,
    '',
  );

  const handleGoBack = () => {
    navigate(PROFILE_AUTH_ROUTE);
  };

  const handleOldPin = (newValue: string) => {
    const isError = newValue !== installedPin;

    if (isError) {
      setError(t('identifier_passcodes_mismatch'));
    } else {
      setIsSuccess(true);
      setTimeout(() => {
        setStep('new');
        setPass('');
      }, 1000);
    }
  };

  const handleNewPin = (newValue: string) => {
    newPinRef.current = newValue;
    setTimeout(() => {
      setStep('newRepeat');
      setPass('');
    }, 500);
  };

  const handleNewRepeatPin = (newValue: string) => {
    const isError = newValue !== newPinRef.current;

    if (isError) {
      setError(t('identifier_passcodes_mismatch'));
    } else {
      setInstalledPin(newValue);
      setIsSuccess(true);
      setStep('success');
      setTimeout(handleGoBack, 1000);
    }
  };

  const handleElementClick = (e?: string) => {
    setPass((prevState) => {
      const shouldReturnPrevState = prevState.length === 4 || !e;

      if (shouldReturnPrevState) {
        return prevState;
      }

      setError('');
      setIsSuccess(false);

      const newValue = `${prevState}${e}`;

      if (prevState.length === 3) {
        switch (step) {
          case 'old': {
            handleOldPin(newValue);
            break;
          }
          case 'new': {
            handleNewPin(newValue);
            break;
          }
          case 'newRepeat': {
            handleNewRepeatPin(newValue);
            break;
          }
        }
      }

      return newValue;
    });
  };

  const handleRemoveElement = () => {
    if (isSuccess) return;

    setError('');

    setPass((prevState) => prevState.slice(0, prevState.length - 1));
  };

  return {
    handleElementClick,
    handleRemoveElement,
    handleGoBack,
  };
};

export default useActions;
