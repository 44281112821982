import React, { FC, ReactElement, ReactNode } from 'react';

import cn from 'classnames';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './CardDefault.module.scss';

interface CardDefaultProps {
  className?: string;
  icon?: TIconsLiteral | ReactElement;
  fallBackIcon?: TIconsLiteral;
  title: ReactNode;
  description?: ReactNode;
  iconPadding?: '8' | '12';
}

const CardDefault: FC<CardDefaultProps> = (props) => {
  const { className, icon, title, description, iconPadding = '8', fallBackIcon } = props;

  const getIcon = () => {
    const iconFromLib = typeof icon === 'string';
    if (iconFromLib) {
      return Icon({ name: icon }) ?? Icon({ name: fallBackIcon });
    }

    return icon;
  };

  return (
    <div className={cn(s.wrapper, className)}>
      <div>
        <Typography.Title level={4}>{title}</Typography.Title>
        {description && (
          <Typography.Text size="14" type="secondary">
            {description}
          </Typography.Text>
        )}
      </div>
      <div className={s[`padding${iconPadding}`]}>{getIcon()}</div>
    </div>
  );
};

export default CardDefault;
