import React, { FC, MouseEvent, ReactNode, useEffect, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import DOMPurify from 'isomorphic-dompurify';
import { t } from 'tools/i18n';
import { getCreatedDate } from 'utils/article.utils';
import { transformHtmlMediaSrc } from 'utils/createMediaUrl.utils';
import { getPlacesLeft } from 'utils/getPlacesLeft';

import { Icon, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import EventTags from 'containers/event-tags';

import { ArticleFullType } from 'types/article.types';
import { EventType } from 'types/event.types';

import s from './HomeNewsDetailsContentEvent.module.scss';
import HomeNewsDetailsContentEventParticipateButton from './home-news-details-content-event-participate-button';

interface HomeNewsDetailsContentNewsProps {
  articleData?: ArticleFullType;
  handleAddArticle: (e: MouseEvent<HTMLButtonElement>) => void;
  isLiked: boolean;
  isParticipant?: boolean;
  likesCount: number;
  renderCategory: (category: { id: string; name: string }) => ReactNode;
  meeting?: EventType['meeting'];
}

const HomeNewsDetailsContentEvent: FC<HomeNewsDetailsContentNewsProps> = (props) => {
  const {
    articleData,
    handleAddArticle,
    isLiked,
    renderCategory,
    likesCount,
    meeting,
    isParticipant: initialIsParticipant,
  } = props;

  const [isParticipant, setIsParticipant] = useState<boolean>(false);

  useEffect(() => {
    if (typeof initialIsParticipant === 'boolean') {
      setIsParticipant(initialIsParticipant);
    }
  }, [initialIsParticipant]);

  const eventIsInFuture = dayjs(meeting?.meetDate).isAfter(dayjs());
  const registrationActive = !!meeting?.registrationActive;

  const eventDate = eventIsInFuture
    ? dayjs(meeting?.meetDate).format('DD.MM.YYYY')
    : t('common_completed');

  const placesLeft = getPlacesLeft(
    Number(meeting?.maxEmployees),
    Number(meeting?.countMembers),
    !!initialIsParticipant,
    isParticipant,
  );

  const showEmployeesCounter = !!meeting?.registrationActive && !!meeting?.maxEmployees;

  const meetDateTime = dayjs(meeting?.meetDate).format('HH:mm');

  return (
    <>
      <div className={s.contentWrapper}>
        <Typography.Text type="secondary">
          {getCreatedDate(articleData?.publishFrom)}
        </Typography.Text>
        <Cover alt="news cover" src={articleData?.cover?.link} className={s.cover} showNoData />
        <Typography.Title level={4} className={s.title}>
          {articleData?.title}
        </Typography.Title>
        <EventTags
          eventDate={eventDate}
          eventIsInFuture={eventIsInFuture}
          isParticipant={isParticipant}
          city={meeting?.city}
        />
        {articleData?.categories && (
          <div className={s.categories}>{articleData?.categories.map(renderCategory)}</div>
        )}
        {articleData && (
          <Typography.Paragraph size="14" className={s.text}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(transformHtmlMediaSrc(articleData.text)),
              }}
            />
          </Typography.Paragraph>
        )}
      </div>
      <div className={s.info}>
        <div className={s.detailedInfo}>
          <div>
            <Typography.Title level={5}>{t('common_detailed_info')}</Typography.Title>
          </div>
          <div className={s.row}>
            <Icon size={20} name="calendarFilled" />
            <Typography.Text size="14" weight="500">
              {eventDate}
            </Typography.Text>
          </div>
          <div className={s.row}>
            <Icon size={20} name="time" />
            <Typography.Text size="14">{`${t('common_start_at')} ${meetDateTime}`}</Typography.Text>
          </div>
          <div className={s.row}>
            <Icon size={20} name="locationFilled" />
            <Typography.Text size="14">{`${meeting?.city}, ${meeting?.location}`}</Typography.Text>
          </div>
          {showEmployeesCounter && (
            <div className={s.row}>
              <Icon size={20} name="human" />
              <Typography.Text size="14">
                {t('common_places_available')}:{' '}
                <Typography.Text
                  size="14"
                  weight="500"
                  type={placesLeft > 0 ? 'primary' : 'danger'}>
                  {placesLeft}
                </Typography.Text>
                /<Typography.Text size="14">{meeting?.maxEmployees}</Typography.Text>
              </Typography.Text>
            </div>
          )}
        </div>
        {registrationActive && (
          <HomeNewsDetailsContentEventParticipateButton
            maxPlacesCount={Number(meeting?.maxEmployees)}
            isParticipant={isParticipant}
            setIsParticipant={setIsParticipant}
            eventIsInFuture={eventIsInFuture}
            placesLeft={placesLeft}
            meetingId={meeting?.id}
          />
        )}
        <div className={s.actions}>
          <button className={cn(s.liked)} onClick={handleAddArticle}>
            <Typography.Text type="primary" size="12">
              <Icon name={isLiked ? 'thankingFilled' : 'thanking'} />
              {likesCount}
            </Typography.Text>
          </button>
          <Typography.Text size="12" type="secondary">
            <Icon name="eye" />
            {articleData?.viewsCnt ?? 0}
          </Typography.Text>
        </div>
      </div>
    </>
  );
};

export default HomeNewsDetailsContentEvent;
