import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './PageDescription.module.scss';

const PageDescription: FC = () => {
  const navigate = useNavigate();

  const handleApplyInquiries = () => {
    navigate(DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE);
  };

  return (
    <Card>
      <Card.Default
        title={t('documents_inquiries')}
        description={t('documents_inquiries_description')}
        icon="reference"
        iconPadding="8"
      />
      <Button
        leftIcon="plus"
        fullWidth
        size="large"
        onClick={handleApplyInquiries}
        className={s.button}>
        {t('documents_inquiries_request')}
      </Button>
    </Card>
  );
};

export default PageDescription;
