import React, { FC, ReactNode, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';

import { useLazyMySentFeedbacksQuery } from 'services/feedback/feedbackApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import { FeedbackType } from 'types/feedback.types';
import { PaginationPropsType } from 'types/pagination.types';

import s from './ProfileFeedbackSent.module.scss';

interface FeedbackSentProps {
  renderFeedback: (feedback: FeedbackType) => ReactNode;
}

const ProfileFeedbackSent: FC<FeedbackSentProps> = (props) => {
  const { renderFeedback } = props;

  const [pagination, setPagination] = useState<PaginationPropsType>({ page: 1, size: 10 });
  const [feedbacksToShow, setFeedbacksToShow] = useState<FeedbackType[]>([]);

  const [
    getSentFeedbacks,
    {
      isLoading: sentFeedbacksIsLoading,
      data: sentFeedbackData,
      isFetching: sentFeedbackIsFetching,
      isUninitialized,
    },
  ] = useLazyMySentFeedbacksQuery();

  useEffect(() => {
    getSentFeedbacks({
      ...pagination,
      // sort: [{ order: SORT_ENUM.DESC, property: 'createDate' }],
    }).then((res) => {
      if (res?.data && 'feedbacks' in res.data) {
        setFeedbacksToShow((prevState) => {
          return prevState.concat(res.data?.feedbacks!);
        });
      }
    });
  }, [getSentFeedbacks, pagination]);

  const showFeedbackContent = isUninitialized || feedbacksToShow.length || sentFeedbackIsFetching;
  const content = showFeedbackContent ? (
    feedbacksToShow.map(renderFeedback)
  ) : (
    <Card className={s.noDataWrapper}>
      <div>
        <Icon name="notFound" />
      </div>
      <Typography.Title level={4}>{t('feedback_you_did_not_send_feedbacks')}</Typography.Title>
      <Typography.Title level={5}>
        {t('feedback_you_did_not_send_feedbacks_description')}
      </Typography.Title>
    </Card>
  );

  const isFetchMoreAvailable =
    pagination.page * pagination.size < (sentFeedbackData?.totalCount ?? 0);

  return (
    <WithLoader isLoading={sentFeedbacksIsLoading}>
      <WithInfiniteScroll
        fetchMoreData={fetchMoreData(setPagination)}
        isFetchMoreAvailable={isFetchMoreAvailable}
        isFetching={sentFeedbackIsFetching}>
        {content}
      </WithInfiniteScroll>
    </WithLoader>
  );
};

export default ProfileFeedbackSent;
