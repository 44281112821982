import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';
import { MORE_EDUCATION_ROUTE } from 'routes/more/list';

import { Card, Typography } from 'gazprom-ui-lib';

import YoutubePlayer from 'components/youtube-player';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import {
  EDUCATION_SECTIONS,
  EducationSectionVideoType,
} from '../guide-education-description/guideEducationDescription.utils';
import s from './GuideEducationSection.module.scss';

const GuideEducationSection: FC = () => {
  const { pathname } = useLocation();

  const { sectionTitle, title, description, icon, items } =
    EDUCATION_SECTIONS.find((section) => section.link === pathname) || EDUCATION_SECTIONS[0];

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_EDUCATION_ROUTE} />
      <Header.Label label={sectionTitle ?? title} />
    </>
  );

  const renderVideoItem = ({ title, url }: EducationSectionVideoType) => (
    <Card>
      <Typography.Title level={4}>{title}</Typography.Title>
      <YoutubePlayer src={url} className={s.player} />
    </Card>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <Card.Default title={title} description={description} icon={icon} iconPadding="8" />
          </Card>
          {items.map(renderVideoItem)}
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideEducationSection;
