import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import Picker from 'react-mobile-picker';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useDateOptions from 'utils/useDateOptions.hook';

import { useGetProductCalendarQuery } from 'services/product-calendar/productCalendarApiService';

import { Button, Form, Icon, Input, Modal, Typography } from 'gazprom-ui-lib';

import { CURRENT_YEAR } from 'constants/dates';
import { DATE_VALIDATION_ENUM } from 'types/documents.types';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';
import s from './AttributeDate.module.scss';
import { DEFAULT_REQUEST_BODY, ModalConfigType } from './attributeDate.utils';

export interface AttributeDateProps extends AttributeDefaultProps {
  yearConfig?: {
    initialMinYear: number;
    initialMaxYear: number;
  };
}

const AttributeDate: FC<AttributeDateProps> = (props) => {
  const { id, isMandatory, placeholder, isReadonly, value, name, validation, yearConfig } = props;

  const { data: productCalendar } = useGetProductCalendarQuery(DEFAULT_REQUEST_BODY);

  const [modalConfig, setModalConfig] = useState<null | ModalConfigType>(null);

  const form = Form.useFormInstance();
  const formValue = Form.useWatch(id);

  const date = dayjs(formValue).get('date').toString();
  const month = dayjs(formValue).get('month');
  const year = dayjs(formValue).get('year');

  const handleOpenCloseModal = () => {
    setModalConfig((prevState) => {
      if (prevState) return null;

      return {
        date,
        month: month!.toString(),
        year: year!.toString(),
      };
    });
  };

  const handleSetDate = () => {
    if (modalConfig) {
      form.setFieldValue(
        id,
        dayjs(
          new Date(Number(modalConfig.year), Number(modalConfig.month), Number(modalConfig.date)),
        ),
      );
      handleOpenCloseModal();
    }
  };

  const defaultYear = CURRENT_YEAR + 1;
  const dateOptionsConfig = {
    year: Number(modalConfig?.year),
    month: Number(modalConfig?.month),
    maxYear: yearConfig?.initialMaxYear ?? defaultYear,
    minYear: yearConfig?.initialMinYear,
  };

  const { yearOptions, monthOptions, dateOptions } = useDateOptions(dateOptionsConfig);

  const selections = {
    date: dateOptions,
    month: monthOptions,
    year: yearOptions,
  };

  const handlePickerChange = (e: ModalConfigType) => {
    const preparedDate = { ...e };
    const daysInMonth = dayjs(new Date(Number(e.year), Number(e.month))).daysInMonth();

    if (Number(preparedDate.date) > daysInMonth) {
      preparedDate.date = daysInMonth.toString();
    }

    setModalConfig(preparedDate);
  };

  useEffect(() => {
    if (value && form) {
      const modifiedInitialValue = typeof value === 'string' ? value : '';

      const [date, month, year] = modifiedInitialValue.split('.');
      form.setFieldValue(id, dayjs(new Date(Number(year), Number(month) - 1, Number(date))));
    }
  }, [form, id, value]);

  useEffect(() => {
    if (form && formValue) {
      form.validateFields([id]);
    }
  }, [form, formValue, id]);

  const getAttributeRules = useCallback(() => {
    let attributeRules = [];

    if (isMandatory) {
      attributeRules.push(formRules.required);
    }

    const shouldValidateDate =
      !!validation && Object.values(DATE_VALIDATION_ENUM).includes(validation.code);

    if (shouldValidateDate && productCalendar) {
      attributeRules.push(formRules.validDate(name, validation, productCalendar));
    }

    return attributeRules;
  }, [isMandatory, name, productCalendar, validation]);

  return (
    <>
      <AttributeItemWrapper {...props}>
        <div className={s.wrapper}>
          <Input
            disabled={isReadonly}
            size="large"
            value={formValue ? dayjs(formValue).format('DD.MM.YYYY') : undefined}
            onClick={handleOpenCloseModal}
            readOnly
            placeholder={placeholder}
            prefix={<Icon name="calendar" className={s.icon} />}
          />
          <Form.Item name={id} validateFirst rules={getAttributeRules()} />
        </div>
      </AttributeItemWrapper>
      <Modal open={!!modalConfig} onCancel={handleOpenCloseModal} closable>
        <div className={s.title}>
          <Typography.Title level={5} className={s.title}>
            {t('common_select_date')}
          </Typography.Title>
        </div>
        <div className={s.pickerWrapper}>
          <div className={s.borderImitation} />
          <div className={s.secondBorderImitation} />
          <Picker
            className={s.picker}
            value={modalConfig as ModalConfigType}
            onChange={handlePickerChange}
            itemHeight={48}
            height={144}>
            {Object.keys(selections).map((name: string) => (
              <Picker.Column key={`${name}__modal__date__select`} name={name}>
                {selections[name as keyof typeof selections].map((option) => {
                  const isSelected =
                    option.value?.toString() === modalConfig?.[name as keyof typeof selections];

                  return (
                    <Picker.Item key={`${name}__${option.label}`} value={option.value?.toString()!}>
                      <Typography.Title strong={false} level={4} disabled={!isSelected}>
                        {option.label}
                      </Typography.Title>
                    </Picker.Item>
                  );
                })}
              </Picker.Column>
            ))}
          </Picker>
        </div>
        <div className={s.actions}>
          <Button fullWidth size="large" leftIcon="ok" onClick={handleSetDate}>
            {t('common_confirm')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(AttributeDate);
