import React, { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl, createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import useCertificate from 'utils/useCertificate.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import {
  useCreateCertificateMutation,
  useLazyGetCurrentProcessingCertificatePersonDataQuery,
} from 'services/me/meApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { CONTINUE_CERTIFICATE_CREATION } from '../documents.utils';
import s from './DocumentsCreateCertificate.module.scss';
import DocumentsCreateCertificateActions from './documents-create-certificate-actions';

const DocumentsCreateCertificate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isFetching: isCertificateFetching,
    isUninitialized,
    isActive: isActiveCertificate,
  } = useCertificate();

  const isContinuation = location.state?.type === CONTINUE_CERTIFICATE_CREATION;
  const canCreateCertificate = !isUninitialized && !isCertificateFetching && !isActiveCertificate;

  const [createCertificate, { isLoading: createCertificateIsFetching, data: newCertificateData }] =
    useCreateCertificateMutation();

  const [
    getProcessingCertificatePersonData,
    { isFetching: processingCertificateIsFetching, data: processingCertificatePersonData },
  ] = useLazyGetCurrentProcessingCertificatePersonDataQuery();

  const alreadySelectedCertificateId = location?.state?.certificate?.latestIssue?.id;
  const personInfo = newCertificateData?.personInfo ?? processingCertificatePersonData;
  const certificateId = newCertificateData?.kcrIssue?.id ?? alreadySelectedCertificateId;
  const fileKey =
    newCertificateData?.kcrIssue?.fileKey ?? location?.state?.certificate?.latestIssue?.fileKey;
  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  const handleDownload = () => {
    handleOpenFile({
      url: createMediaDownloadUrl(createMediaUploadUrl(fileKey)),
      fileKey,
      withAuth: true,
    });
  };

  useEffect(() => {
    if (isContinuation && alreadySelectedCertificateId) {
      getProcessingCertificatePersonData(alreadySelectedCertificateId);
    } else if (canCreateCertificate) {
      createCertificate().then((res) => {
        if ('error' in res && 'status' in res.error && res.error.status === 422) {
          navigate(DOCUMENTS_ROUTE, { state: { certificateState: '1cError' } });
        }
      });
    }
  }, [
    canCreateCertificate,
    createCertificate,
    getProcessingCertificatePersonData,
    isContinuation,
    alreadySelectedCertificateId,
    navigate,
  ]);

  const headerChildren = (
    <>
      <Header.GoBack
        to={DOCUMENTS_ROUTE}
        state={{ invalidateCertificate: true }}
        useDefaultGoBack
      />
      <Header.Label label={t('documents_create_certificate')} />
    </>
  );

  const loader = (
    <div className={s.loader}>
      <UniversalLoader />
      <Typography.Text type="secondary" size="14" className={s.description}>
        {t('common_load_data')}...
      </Typography.Text>
    </div>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <div className={s.description}>
              <Typography.Title level={4}>
                {t('documents_certificate_request_registered')}
              </Typography.Title>
              <Typography.Text type="secondary" size="14">
                {t('documents_certificate_request_registered_description')}
              </Typography.Text>
            </div>
            <WithLoader
              loader={loader}
              isLoading={createCertificateIsFetching || processingCertificateIsFetching}>
              <div className={s.column}>
                <div>
                  <Typography.Text size="12" type="secondary">
                    {t('common_last_name')}
                  </Typography.Text>
                  <Typography.Title level={5}>{personInfo?.lastName}</Typography.Title>
                </div>
                <div>
                  <Typography.Text size="12" type="secondary">
                    {t('common_first_name')}
                  </Typography.Text>
                  <Typography.Title level={5}>{personInfo?.firstName}</Typography.Title>
                </div>
                <div className={s.row}>
                  <div>
                    <Typography.Text size="12" type="secondary">
                      {t('common_snils')}
                    </Typography.Text>
                    <Typography.Title level={5}>{personInfo?.snils}</Typography.Title>
                  </div>
                  <div>
                    <Typography.Text size="12" type="secondary">
                      {t('common_birthdate')}
                    </Typography.Text>
                    <Typography.Title level={5}>{personInfo?.birthDate}</Typography.Title>
                  </div>
                </div>
                <div className={s.row}>
                  <div>
                    <Typography.Text size="12" type="secondary">
                      {t('common_passport_serial_and_number')}
                    </Typography.Text>
                    <Typography.Title
                      level={5}>{`${personInfo?.series} ${personInfo?.number}`}</Typography.Title>
                  </div>
                  <div>
                    <Typography.Text size="12" type="secondary">
                      {t('common_issue_date')}
                    </Typography.Text>
                    <Typography.Title level={5}>{personInfo?.issueDate}</Typography.Title>
                  </div>
                </div>
              </div>
            </WithLoader>
          </Card>
          {certificateId && (
            <Card>
              <DocumentsCreateCertificateActions certificateId={certificateId} />
            </Card>
          )}

          {fileKey && (
            <Card>
              <button className={s.download} onClick={handleDownload} disabled={!!loadingKey}>
                <div>
                  <Icon name="documents" size={20} />
                  <Typography.Text size="14" weight="500" type="primary">
                    {t('documents_certificate_download_request')}
                  </Typography.Text>
                </div>
                <div>
                  <WithLoader isLoading={!!loadingKey} loader={<UniversalLoader size="24" />}>
                    <Icon name="download" size={24} />
                  </WithLoader>
                </div>
              </button>
            </Card>
          )}
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsCreateCertificate;
