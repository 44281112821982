import React, { useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_ROUTE,
  DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
  getDocumentsInquiriesRequestRoute,
  getDocumentsStatementRequestRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetKedoStatementsQuery } from 'services/kedo/kedoApiService';

import { Icon, Input, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import StatementUnavailableModal from 'containers/statement-unavailable-modal';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useIsStatementUnavailable from 'pages/documents/useIsStatementUnavailable.hook';

import { KedoStatement, STATEMENT_CREATOR_TYPE_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';

import s from './DocumentsCreateSearchCategory.module.scss';

const DocumentsCreateSearchCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isStatement = location.pathname === DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE;

  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data: statements, isLoading: isStatementsLoading } = useGetKedoStatementsQuery({
    type: isStatement ? TASK_TYPE_ENUM.BPMN : TASK_TYPE_ENUM.KEDO,
    creatorType: STATEMENT_CREATOR_TYPE_ENUM.HUMAN,
  });

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t(isStatement ? 'documents_apply' : 'documents_inquiries_request')} />
    </>
  );
  const handleNavigateCreate = (id: string) => () => {
    navigate(
      isStatement ? getDocumentsStatementRequestRoute(id) : getDocumentsInquiriesRequestRoute(id),
    );
  };

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);
  const { getIsStatementUnavailable, leadersIsFetching } = useIsStatementUnavailable();

  const renderStatementItem = (statement: KedoStatement) => {
    const { id, name } = statement;
    const statementUnavailable = getIsStatementUnavailable(statement);

    return (
      <button
        className={s.statementItem}
        key={`${name}__${id}`}
        onClick={statementUnavailable ? handleModalOpenClose : handleNavigateCreate(id)}>
        <Typography.Title level={5}>{name}</Typography.Title>
        <Icon
          name={statementUnavailable ? 'error' : 'forward'}
          color={statementUnavailable ? 'var(--color-error)' : 'var(--color-primary-6)'}
        />
      </button>
    );
  };

  const filteredStatements = useMemo(() => {
    return (
      statements?.filter((statement) =>
        statement.name.toLowerCase().includes(search.toLowerCase()),
      ) ?? []
    );
  }, [statements, search]);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <WithLoader isLoading={isStatementsLoading || leadersIsFetching}>
              <div className={s.header}>
                <Typography.Title level={4}>
                  {t(isStatement ? 'documents_search_statements' : 'documents_search_inquiries')}
                </Typography.Title>
                <Input
                  allowClear={{
                    clearIcon: <Icon name="deleteFilled" size={20} />,
                  }}
                  size="large"
                  prefix={<Icon name="search" />}
                  placeholder={`${t('documents_search_certificate_placeholder')}...`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div>{filteredStatements.map(renderStatementItem)}</div>
            </WithLoader>
          </Card>
          <StatementUnavailableModal open={isModalOpen} onClose={handleModalOpenClose} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsCreateSearchCategory;
