import { t } from 'tools/i18n';

import { SUGGESTION_NEED_EXPENSES_ENUM } from 'types/suggestion.types';

export const getNeedExpensesTextValue = (value: SUGGESTION_NEED_EXPENSES_ENUM): string => {
  switch (value) {
    case SUGGESTION_NEED_EXPENSES_ENUM.NEED_MONEY:
      return t('common_yes');
    case SUGGESTION_NEED_EXPENSES_ENUM.NO_NEED_MONEY:
      return t('common_no');
    default:
      return t('common_dont_know');
  }
};
