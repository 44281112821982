import { useEffect, useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';

import {
  useConfirmPersonalDataMutation,
  useLazyGetPersonalDataQuery,
  useRequestPersonalDataMutation,
} from 'services/me/meApiService';

import { VERIFICATION_MODALS_ENUM } from 'containers/code-verification/code-verification.utils';

import { REQUEST_PERSONAL_DATA_STATUSES } from './useProfilePersonalData.utils';

export const useProfilePersonalData = () => {
  const [showConfirmCode, setShowConfirmCode] = useState<boolean>(false);
  const [showRequestCodeError, setShowRequestCodeError] = useState<boolean>(false);
  const [personalDataHidden, setPersonalDataHidden] = useState<boolean>(true);

  const [requestPersonalData, { isLoading: isRequestLoading }] = useRequestPersonalDataMutation();
  const [confirmPersonalData, { isLoading: isConfirmLoading }] = useConfirmPersonalDataMutation();

  const handleCloseConfirmCodeScreen = () => setShowConfirmCode(false);
  const handleCloseShowRequestCodeError = () => setShowRequestCodeError(false);

  const handleRequestPersonalData = () => {
    if (!personalDataHidden) {
      return;
    }

    requestPersonalData().then((res) => {
      if ('data' in res) {
        setShowConfirmCode(true);
      } else if ('error' in res) {
        setShowRequestCodeError(true);
      }
    });
  };

  const handleSubmitVerificationCode = async (
    smsCode: string,
  ): Promise<VERIFICATION_MODALS_ENUM> => {
    let statusCode = VERIFICATION_MODALS_ENUM.ERROR;

    const res = await confirmPersonalData({ smsCode });

    if ('error' in res && 'data' in res.error) {
      const errorData: SerializedError = res.error.data!;
      const errorCode = errorData.message;

      switch (errorCode) {
        case REQUEST_PERSONAL_DATA_STATUSES.INVALID_CODE: {
          statusCode = VERIFICATION_MODALS_ENUM.WRONG_CODE;
          break;
        }
        case REQUEST_PERSONAL_DATA_STATUSES.TO_MANY_ATTEMPTS: {
          statusCode = VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS;
          break;
        }
        case REQUEST_PERSONAL_DATA_STATUSES.CONFIRMATION_CODE_EXPIRED: {
          statusCode = VERIFICATION_MODALS_ENUM.TIME_IS_UP;
          break;
        }
        default: {
          statusCode = VERIFICATION_MODALS_ENUM.ERROR;
        }
      }
    } else if ('data' in res) {
      statusCode = VERIFICATION_MODALS_ENUM.COMPLETED;
    }

    return statusCode;
  };

  const [getPersonalData, { data: personalData, isFetching: isPersonalDataFetching, isError }] =
    useLazyGetPersonalDataQuery();

  useEffect(() => {
    getPersonalData();
  }, [getPersonalData]);

  useEffect(() => {
    setPersonalDataHidden(isError);
  }, [isError]);

  return {
    isRequestLoading,
    isConfirmLoading,
    isPersonalDataFetching,
    personalData,
    personalDataHidden,
    showConfirmCode,
    showRequestCodeError,
    setPersonalDataHidden,
    handleRequestPersonalData,
    handleCloseConfirmCodeScreen,
    handleSubmitVerificationCode,
    handleCloseShowRequestCodeError,
  };
};
