export const getLikesCount = (
  initialLikesCount: number,
  initialIsLiked: boolean,
  localIsLiked: boolean,
): number => {
  if (initialIsLiked) {
    return localIsLiked ? initialLikesCount : initialLikesCount - 1;
  } else {
    return localIsLiked ? initialLikesCount + 1 : initialLikesCount;
  }
};
