import { FC, useState } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getProfileVacationAgreedRoute } from 'routes/profile/list';

import { Divider, Flex, Icon, Typography } from 'gazprom-ui-lib';

import { getDivisionNameFontSize } from './vacationEmployeesDivision.types';
import { LeaderSubdivisionType } from 'types/employee.types';

import s from './VacationEmployeesDivision.module.scss';

interface VacationEmployeesDivisionProps {
  division: LeaderSubdivisionType;
  isChild?: boolean;
  level?: number;
}

const VacationEmployeesDivision: FC<VacationEmployeesDivisionProps> = (props) => {
  const navigate = useNavigate();

  const { division, isChild = false, level = 1 } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = () => {
    navigate(getProfileVacationAgreedRoute(division.divisionId));
  };

  const isLastItem = isChild && !division.children.length;

  return (
    <>
      <Divider />
      <>
        <Flex
          fullWidth
          vertical
          onClick={toggleOpen}
          className={cn(isChild && s.subItem, isLastItem && s.lastItem)}>
          <Flex fullWidth className={s.content} align="center" justify="space-between">
            <Typography.Text
              size={getDivisionNameFontSize(level)}
              weight="500"
              className={cn(s[`namePadding-${level}`])}>
              {division.name}
            </Typography.Text>
            <Flex onClick={handleNavigate} className={s.icon}>
              <Icon name="calendar" />
            </Flex>
          </Flex>
        </Flex>
        {isOpen && (
          <>
            {division.children.map((division) => (
              <VacationEmployeesDivision
                isChild
                key={division.divisionId}
                division={division}
                level={level + 1}
              />
            ))}
          </>
        )}
      </>
    </>
  );
};

export default VacationEmployeesDivision;
