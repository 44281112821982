import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs, { Dayjs } from 'dayjs';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetScheduleQuery } from 'services/me/meApiService';

import { Calendar, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useRenderers from 'pages/profile/profile-schedule/useRenderers.hook';

import s from './ProfileSchedule.module.scss';
import ProfileScheduleEmployee from './profile-schedule-employee';

const ProfileSchedule = () => {
  const [calendarValue, setCalendarValue] = useState<Dayjs>(dayjs());
  const [selectedCalendarValue, setSelectedCalendarValue] = useState<Dayjs | null>(null);

  const [selectedEmployee] = useFullSelectedEmployee();

  const year = calendarValue.year();
  const month = calendarValue.month();

  const { data: scheduleData, isLoading: scheduleIsLoading } = useGetScheduleQuery(
    selectedEmployee ? { month: month + 1, year, myEmployeeId: selectedEmployee.id } : skipToken,
  );

  const { cellRenderer, headerRenderer } = useRenderers({
    scheduleData,
    calendarValue,
    setCalendarValue,
    selectedCalendarValue,
  });

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground id="payslipContainer" className={s.relative}>
          <ProfileScheduleEmployee />
          <Card withPadding={false}>
            <WithLoader isLoading={scheduleIsLoading}>
              <Calendar
                onChange={setSelectedCalendarValue}
                value={calendarValue}
                className={s.calendar}
                fullscreen={false}
                fullCellRender={cellRenderer}
                headerRender={headerRenderer}
              />
              <div className={s.legend}>
                <div>
                  <div className={s.todayCircle} />
                  <Typography.Text>{t('common_today')}</Typography.Text>
                </div>
                <div>
                  <div className={s.workCircle} />
                  <Typography.Text>{t('common_work_day')}</Typography.Text>
                </div>
                <div>
                  <div className={s.vacationCircle} />
                  <Typography.Text>{t('common_vacation')}</Typography.Text>
                </div>
              </div>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

const headerChildren = (
  <>
    <Header.GoBack to={PROFILE_ROUTE} useDefaultGoBack={true} />
    <Header.Label label={t('common_schedule')} />
  </>
);

export default ProfileSchedule;
