import DOMPurify from 'isomorphic-dompurify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MORE_FAQ_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetFaqQuestionByIdQuery } from 'services/faq-list/faqListApiService';

import { Button, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './MoreFaqCategoryDetailsQuestion.module.scss';

const MoreFaqCategoryDetailsQuestion = () => {
  const location = useLocation();
  const params = useParams<{ faqId: string; faqQuestionId: string }>();
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: faqQuestionData, isLoading: faqQuestionIsLoading } = useGetFaqQuestionByIdQuery(
    {
      id: params.faqQuestionId!,
      myEmployeeId: selectedEmployee?.id!,
    },
    {
      skip: !params.faqQuestionId || !selectedEmployee?.id,
    },
  );

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_FAQ_ROUTE} state={location.state} />
      <Header.Label label={t('home_faq_question_view')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card className={s.wrapper}>
            <WithLoader isLoading={faqQuestionIsLoading}>
              <Typography.Title level={3}>{faqQuestionData?.title}</Typography.Title>
              <Typography.Title level={5} className={s.text}>
                <span
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqQuestionData?.answer!) }}
                />
              </Typography.Title>
              <Button type="secondary" fullWidth onClick={() => navigate(-1)}>
                {t('common_close')}
              </Button>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreFaqCategoryDetailsQuestion;
