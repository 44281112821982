import React, { useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { MORE_ROUTE, getFaqCategoryDetailsRoute } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useGetFaqCategoryQuery } from 'services/faq-category/faqCategoryApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { FaqCategoryType } from 'types/faq.types';

import s from './MoreFaq.module.scss';
import MoreFaqButtonOptions from './more-faq-button-options';

const MoreFaq = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<string>(location?.state?.category || '');

  const { data: faqCategoryData, isLoading: faqCategoryIsLoading } = useGetFaqCategoryQuery({
    page: 1,
    size: 100,
    isPublishFilterParam: true,
  });

  const categoriesWithSubCategories = useMemo(() => {
    if (!faqCategoryData) {
      return {};
    }
    const availableCategories: string[] = [];

    const modifyCategories = (
      prevState: Record<string, FaqCategoryType[]>,
      currentState: FaqCategoryType,
    ) => {
      if (!currentState.parentCategory.nameCategory) {
        return prevState;
      }
      if (prevState[currentState.parentCategory.nameCategory]) {
        prevState[currentState.parentCategory.nameCategory].push(currentState);
      } else {
        prevState[currentState.parentCategory.nameCategory] = [currentState];
        availableCategories.push(currentState.parentCategory.nameCategory);
      }
      return prevState;
    };

    const categoriesObject = faqCategoryData.categories.reduce(
      modifyCategories,
      {} as Record<string, FaqCategoryType[]>,
    );

    return {
      categoriesObject,
      availableCategories,
    };
  }, [faqCategoryData]);

  const renderFaqItem = (categoryOrQuestion: FaqCategoryType) => {
    const handleNavigate = () => {
      navigate(getFaqCategoryDetailsRoute(categoryOrQuestion.id), {
        state: {
          category: selectedCategory,
        },
      });
    };

    return (
      <button className={s.faqItem} key={categoryOrQuestion.id} onClick={handleNavigate}>
        <Typography.Title level={5}>{categoryOrQuestion.nameCategory}</Typography.Title>
        <Icon name="forward" />
      </button>
    );
  };

  useEffect(() => {
    if (categoriesWithSubCategories?.availableCategories?.length && !selectedCategory) {
      setSelectedCategory(categoriesWithSubCategories?.availableCategories[0]);
    }
  }, [categoriesWithSubCategories, selectedCategory]);

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_ROUTE} useDefaultGoBack />
      <Header.Label label={t('common_help_and_support')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <Card.Default
              title={t('common_help_and_support')}
              description={t('faq_description')}
              icon="faq"
            />
          </Card>
          <Card className={s.wrapper} withPadding={false}>
            <WithLoader isLoading={faqCategoryIsLoading}>
              <div>
                <Typography.Title level={4} className={s.categoriesHeader}>
                  {t('faq_question_categories')}
                </Typography.Title>
                <MoreFaqButtonOptions
                  categories={categoriesWithSubCategories?.availableCategories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
                <Typography.Text size="14" weight="500" type="primary" className={s.subcategory}>
                  {t('common_subcategories')}
                  <span>
                    {categoriesWithSubCategories?.categoriesObject?.[selectedCategory]?.length}
                  </span>
                </Typography.Text>
              </div>
              <div>
                {categoriesWithSubCategories?.categoriesObject?.[selectedCategory]?.map(
                  renderFaqItem,
                )}
              </div>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreFaq;
