import Bonuses from 'pages/bonuses';

import { RouteConfig } from '../routes';
import { BONUSES_ROUTE } from './list';

export const bonusesRoutes: RouteConfig[] = [
  {
    path: BONUSES_ROUTE,
    element: <Bonuses />,
  },
];
