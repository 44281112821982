export const createMediaDownloadUrl = (fileKey: string) => {
  return `${process.env.https://api.dev.tockl.ru/}${fileKey}`;
};

export const createFileDownloadUrl = (fileKey: string) => {
  return `${process.env.https://api.dev.tockl.ru/api/v1/}me/files/download/${fileKey}`;
};

export const createMediaUploadUrl = (fileKey: string) => {
  return `api/v1/me/files/download/${fileKey}`;
};

export const transformHtmlMediaSrc = (html?: string) => {
  return html?.replace(/src="([^"]+)"/g, `src="${process.env.https://api.dev.tockl.ru/}$1"`) ?? '';
};
