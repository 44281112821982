import React, { FC, useContext, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { useGetBusinessTripTypesQuery } from 'services/work-tour/workTourApiService';
import { GetBusinessTripTypesPropsType } from 'services/work-tour/workTourService.types';

import { Form, Select } from 'gazprom-ui-lib';

import { BusinessTripPurposeType } from 'types/businessTrip.types';

import { BusinessTripContext, FORM_ENUM } from '../../businessTripForm.utils';
import SelectWithSearch from './containers/select-with-search';
import Specify from './containers/specify';

const Purpose: FC = () => {
  const [isTextAreaModalVisible, setIsTextAreaModalVisible] = useState<boolean>(false);
  const [isSelectModalVisible, setIsSelectModalVisible] = useState<boolean>(false);

  const { type } = useContext(BusinessTripContext);

  let queryProp: GetBusinessTripTypesPropsType = 'target';
  let name = FORM_ENUM.PURPOSE;
  let formLabel = t('documents_business_trip_purpose');
  let manualTitle = t('documents_enter_purpose');

  if (type === 'edit') {
    queryProp = 'change-reason';
    name = FORM_ENUM.PURPOSE_EDIT;
    formLabel = t('common_purpose_of_change');
    manualTitle = t('documents_enter_edit_purpose');
  }

  if (type === 'cancel') {
    queryProp = 'cancellation-reason';
    name = FORM_ENUM.PURPOSE_CANCEL;
    formLabel = t('common_decline_reason');
    manualTitle = t('documents_enter_cancel_purpose');
  }

  const { data: purposeTypes, isFetching: purposeTypesIsFetching } =
    useGetBusinessTripTypesQuery(queryProp);

  const handleSelectModalOpenClose = () => {
    setIsSelectModalVisible((prevState) => !prevState);
  };

  const options = purposeTypes?.map(transformPurposeIntoOption);
  return (
    <>
      <Form.Item label={formLabel} name={name} rules={[formRules.required]}>
        <Select open={false} options={options} onClick={handleSelectModalOpenClose} />
      </Form.Item>
      <Specify
        isTextAreaModalVisible={isTextAreaModalVisible}
        setIsTextAreaModalVisible={setIsTextAreaModalVisible}
      />
      <SelectWithSearch
        title={formLabel}
        manualTitle={manualTitle}
        parentName={name}
        purposeTypesIsFetching={purposeTypesIsFetching}
        purposeTypes={purposeTypes}
        isSelectModalVisible={isSelectModalVisible}
        setIsTextAreaModalVisible={setIsTextAreaModalVisible}
        setIsSelectModalVisible={setIsSelectModalVisible}
      />
    </>
  );
};

const transformPurposeIntoOption = (purpose: BusinessTripPurposeType) => ({
  label: purpose.name,
  value: purpose.code,
});

export default Purpose;
