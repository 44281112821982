import { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { Form, Icon, Typography } from 'gazprom-ui-lib';

import { Progress } from 'components/progress';
import SurveyLabel from 'components/survey-label';
import { LABEL_TYPES } from 'components/survey-label/surveyLabel';
import Card from 'containers/card';

import { countAnswers } from '../surveysSingle.utils';
import s from './surveysSingleHeader.module.scss';

interface SurveysSingleHeaderProps {
  title: string;
  completedDate: string;
  isCompleted: boolean;
  storageValue: string;
  allQuestionCount?: number;
}

const SurveysSingleHeader: FC<SurveysSingleHeaderProps> = (props) => {
  const { title, completedDate, isCompleted, storageValue, allQuestionCount } = props;
  const form = Form.useFormInstance();
  const [answersCounter, setAnswersCounter] = useState({
    all: allQuestionCount || 0,
    current: 0,
  });

  const date = dayjs(completedDate).format('DD.MM.YY в HH:mm');

  useEffect(() => {
    setTimeout(() => {
      const allFields = form.getFieldsValue();
      const current = countAnswers(allFields);

      if (current !== answersCounter.current) {
        setAnswersCounter((prev) => ({ ...prev, current }));
      }
    }, 0);
  }, [answersCounter, form, storageValue]);

  return (
    <Card withPadding className={s.container}>
      <div className={s.titleContainer}>
        <Typography.Title level={3}>{title}</Typography.Title>
        {isCompleted && (
          <div className={s.icon}>
            {<Icon name="ok" size={48} color="var(--color-neutral-1)" />}
          </div>
        )}
      </div>
      {isCompleted && <SurveyLabel type={LABEL_TYPES.COMPLETED}>{date}</SurveyLabel>}
      <Progress current={answersCounter.current} all={answersCounter.all} />
    </Card>
  );
};

export default SurveysSingleHeader;
