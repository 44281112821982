import React, { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs, { Dayjs } from 'dayjs';
import Picker from 'react-mobile-picker';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useDateOptions from 'utils/useDateOptions.hook';

import { useGetProductCalendarQuery } from 'services/product-calendar/productCalendarApiService';

import { Button, Flex, Form, Icon, Input, Modal, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { CURRENT_DATE, CURRENT_MONTH, CURRENT_YEAR } from 'constants/dates';
import { SCHEDULE_AVAILABLE_DAY_TYPES } from 'types/schedule.types';

import { FORM_ENUM, formatDate } from '../../businessTripForm.utils';
import s from './DayOff.module.scss';
import { ModalConfigType } from './dayOff.utils';

const DayOff = () => {
  const [modalConfig, setModalConfig] = useState<null | ModalConfigType>(null);

  const form = Form.useFormInstance();

  const fromValue = Form.useWatch(FORM_ENUM.FROM);
  const toValue = Form.useWatch(FORM_ENUM.TO);

  const skip = !fromValue || !toValue;

  const { data } = useGetProductCalendarQuery(
    skip
      ? skipToken
      : {
          startDate: dayjs(fromValue).format('YYYY-MM-DD'),
          endDate: dayjs(toValue).format('YYYY-MM-DD'),
        },
  );

  const { yearOptions, monthOptions, dateOptions } = useDateOptions({
    year: Number(modalConfig?.year),
    month: Number(modalConfig?.month),
    maxYear: CURRENT_YEAR + 1,
    minYear: CURRENT_YEAR,
    showOnlyAfterToday: true,
  });
  const handlePickerChange = (e: ModalConfigType) => {
    const preparedDate = { ...e };
    const daysInMonth = dayjs(new Date(Number(e.year), Number(e.month))).daysInMonth();

    const isCurrentYear = Number(e.year) === CURRENT_YEAR;
    let isCurrentMonth = Number(e.month) === CURRENT_MONTH;
    const minMonth = isCurrentYear ? CURRENT_MONTH : 0;

    if (Number(e.month) < minMonth) {
      preparedDate.month = minMonth.toString();
      isCurrentMonth = minMonth === CURRENT_MONTH;
    }

    const minDate = isCurrentMonth && isCurrentYear ? CURRENT_DATE : 0;

    if (Number(e.date) < minDate) {
      preparedDate.date = minDate.toString();
    } else if (Number(preparedDate.date) > daysInMonth) {
      preparedDate.date = daysInMonth.toString();
    }

    setModalConfig(preparedDate);
  };

  const selections = {
    date: dateOptions,
    month: monthOptions,
    year: yearOptions,
  };

  const fromDateIsHoliday = data?.[0]?.stateDay === SCHEDULE_AVAILABLE_DAY_TYPES.DAYOFF;
  const toDateIsHoliday = data?.at(-1)?.stateDay === SCHEDULE_AVAILABLE_DAY_TYPES.DAYOFF;
  const showToDate = toDateIsHoliday && data?.length > 1;

  const showCard = !skip && (fromDateIsHoliday || toDateIsHoliday);
  const handleOpenModal =
    (fromToDate: Dayjs, type: FORM_ENUM.DAY_OFF_FROM | FORM_ENUM.DAY_OFF_TO) => () => {
      setModalConfig(() => {
        const date = dayjs(fromToDate).get('date').toString();
        const month = dayjs(fromToDate).get('month');
        const year = dayjs(fromToDate).get('year');

        return {
          type,
          date,
          month: month!.toString(),
          year: year!.toString(),
        };
      });
    };

  const handleSetDate = () => {
    if (modalConfig) {
      const newDate = dayjs(
        new Date(Number(modalConfig.year), Number(modalConfig.month), Number(modalConfig.date)),
      );
      const isIncorrectConnectedValue =
        modalConfig.type === FORM_ENUM.DAY_OFF_FROM
          ? dayjs(form.getFieldValue(FORM_ENUM.DAY_OFF_TO)).isSame(newDate)
          : dayjs(form.getFieldValue(FORM_ENUM.DAY_OFF_FROM)).isSame(newDate);

      const fromDate = form.getFieldValue(FORM_ENUM.FROM);
      const toDate = form.getFieldValue(FORM_ENUM.TO);

      const isIncorrect =
        newDate.isSame(fromDate, 'date') ||
        newDate.isSame(toDate, 'date') ||
        isIncorrectConnectedValue;

      form.setFields([
        {
          name: `${modalConfig.type}_VISIBLE`,
          value: newDate.format('DD.MM.YYYY'),
          errors: isIncorrect ? [t('errors_incorrect_date')] : [],
        },
        {
          name: modalConfig.type,
          value: newDate,
        },
      ]);
      handleModalClose();
    }
  };
  const handleModalClose = () => setModalConfig(null);

  if (showCard) {
    let title = t('documents_business_trip_start_description');

    if (fromDateIsHoliday && showToDate) {
      title = t('documents_business_trip_both_description');
    } else if (showToDate) {
      title = t('documents_business_trip_end_description');
    }

    return (
      <Card className={s.wrapper}>
        <Flex vertical gap="16">
          <Flex align="flex-start" className={s.titleContainer} gap="8">
            <Icon name="info" />
            <Typography.Text size="14">{title}</Typography.Text>
          </Flex>
          {fromDateIsHoliday && (
            <>
              <Form.Item
                name={FORM_ENUM.DAY_OFF_FROM_VISIBLE}
                label={t('documents_select_day_off_start')}
                rules={[formRules.required]}>
                <Input
                  onClick={handleOpenModal(fromValue, FORM_ENUM.DAY_OFF_FROM)}
                  value={formatDate(fromValue)}
                />
              </Form.Item>
              <Form.Item name={FORM_ENUM.DAY_OFF_FROM} noStyle />
            </>
          )}
          {showToDate && (
            <>
              <Form.Item
                name={FORM_ENUM.DAY_OFF_TO_VISIBLE}
                label={t('documents_select_day_off_end')}
                rules={[formRules.required]}>
                <Input
                  onClick={handleOpenModal(toValue, FORM_ENUM.DAY_OFF_TO)}
                  value={formatDate(toValue)}
                />
              </Form.Item>
              <Form.Item name={FORM_ENUM.DAY_OFF_TO} noStyle />
            </>
          )}
        </Flex>
        <Modal open={!!modalConfig} onCancel={handleModalClose} closable>
          <div className={s.title}>
            <Typography.Title level={5} className={s.title}>
              {t('common_select_date')}
            </Typography.Title>
          </div>
          <div className={s.pickerWrapper}>
            <div className={s.borderImitation} />
            <div className={s.secondBorderImitation} />
            <Picker
              className={s.picker}
              value={modalConfig as ModalConfigType}
              onChange={handlePickerChange}
              itemHeight={48}
              height={144}>
              {Object.keys(selections).map((name: string) => (
                <Picker.Column key={`${name}__modal__date__select`} name={name}>
                  {selections[name as keyof typeof selections].map((option) => {
                    const isSelected =
                      option.value?.toString() === modalConfig?.[name as keyof typeof selections];

                    return (
                      <Picker.Item
                        key={`${name}__${option.label}`}
                        value={option.value?.toString()!}>
                        <Typography.Title strong={false} level={4} disabled={!isSelected}>
                          {option.label}
                        </Typography.Title>
                      </Picker.Item>
                    );
                  })}
                </Picker.Column>
              ))}
            </Picker>
          </div>
          <div className={s.actions}>
            <Button fullWidth size="large" leftIcon="ok" onClick={handleSetDate}>
              {t('common_confirm')}
            </Button>
          </div>
        </Modal>
      </Card>
    );
  }

  return <></>;
};

export default DayOff;
