import { Dispatch, SetStateAction, useEffect } from 'react';

import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  useLazyGetKedoTasksDraftsQuery,
  useLazyGetKedoTasksQuery,
} from 'services/kedo/kedoApiService';
import {
  GetKedoTasksDraftsResponseType,
  GetKedoTasksResponseType,
} from 'services/kedo/kedoApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { ALL_CATEGORIES } from 'pages/documents/documents.utils';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { KedoTask, KedoTaskDraft, TASK_STATUS_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';
import { PaginationPropsType } from 'types/pagination.types';

interface UseGetInquiriesProps {
  activeStatus: string;
  activeCategory: string;
  setTasksToShow: Dispatch<SetStateAction<KedoTask[]>>;
  setDraftsToShow: Dispatch<SetStateAction<KedoTaskDraft[]>>;
  isDraft: boolean;
  pagination: PaginationPropsType;
  isRefreshing: boolean;
}

const useGetInquiries = (props: UseGetInquiriesProps) => {
  const {
    activeStatus,
    setTasksToShow,
    setDraftsToShow,
    pagination,
    activeCategory,
    isDraft,
    isRefreshing,
  } = props;

  const [selectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );

  const [
    getTasks,
    { data: tasks, isFetching: tasksIsFetching, isLoading: tasksIsLoading, isUninitialized },
  ] = useLazyGetKedoTasksQuery();

  const [getDrafts, { data: drafts, isFetching: draftsIsFetching, isLoading: draftsIsLoading }] =
    useLazyGetKedoTasksDraftsQuery();

  useEffect(() => {
    const onSuccess = (data?: GetKedoTasksDraftsResponseType) => {
      if (data && 'drafts' in data) {
        setDraftsToShow((prevState) => {
          return prevState.concat(data?.drafts!);
        });
      }
    };

    if (isDraft && !isRefreshing) {
      getDrafts({
        ...pagination,
        myEmployeeId: selectedEmployee,
        type: TASK_TYPE_ENUM.KEDO,
        categoryIds: activeCategory !== ALL_CATEGORIES.id ? [activeCategory] : undefined,
      }).then(handleRequest({ onSuccess }));
    }
  }, [
    getDrafts,
    isDraft,
    pagination,
    selectedEmployee,
    setDraftsToShow,
    activeCategory,
    isRefreshing,
  ]);

  useEffect(() => {
    const onSuccess = (data?: GetKedoTasksResponseType) => {
      if (data && 'content' in data) {
        setTasksToShow((prevState) => {
          return prevState.concat(data?.content!);
        });
      }
    };

    let statuses = activeStatus !== TASK_STATUS_ENUM.ALL ? [activeStatus] : undefined;

    if (!isDraft && !isRefreshing) {
      getTasks({
        ...pagination,
        myEmployeeId: selectedEmployee,
        statuses,
        categoryIds: activeCategory !== ALL_CATEGORIES.id ? [activeCategory] : undefined,
      }).then(handleRequest({ onSuccess }));
    }
  }, [
    isDraft,
    activeCategory,
    activeStatus,
    pagination,
    getTasks,
    selectedEmployee,
    setTasksToShow,
    isRefreshing,
  ]);

  return {
    tasks,
    drafts,
    isFetching: tasksIsFetching || draftsIsFetching,
    isLoading: tasksIsLoading || draftsIsLoading,
    isUninitialized,
  };
};

export default useGetInquiries;
