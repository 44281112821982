import React, { FC } from 'react';

import dayjs from 'dayjs';

import { Divider, Flex, Icon } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { EmployeeVacationType } from 'types/vacation.types';

import s from './EmployeeAgreedVacation.module.scss';
import VacationCard from './card';

type EmployeeAgreedVacationProps = EmployeeVacationType & {
  handleClick: () => void;
};

const EmployeeAgreedVacation: FC<EmployeeAgreedVacationProps> = (props) => {
  const { person, position, vacations, handleClick } = props;

  const months = [...Array(12)].map((_, index) => dayjs().month(index).startOf('month'));

  return (
    <>
      <Divider />
      <Flex fullWidth vertical className={s.wrapper} gap="12" onClick={handleClick}>
        <Flex fullWidth justify="space-between">
          <Bio
            avatarSize="48"
            firstName={person?.firstName}
            lastName={person?.lastName}
            staffName={position?.name}
          />
          <Icon size={16} name="forward" />
        </Flex>
        <Flex className={s.vacationsWrapper}>
          {months.map((month, index) => (
            <VacationCard key={index} month={month} vacations={vacations} />
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default EmployeeAgreedVacation;
