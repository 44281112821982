import React from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetLeadersQuery } from 'services/me/meApiService';

import { Avatar, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from 'pages/guide/guide-management/GuideManagement.module.scss';

import { LeaderType } from 'types/employee.types';

const GuideManagement = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leadersData, isFetching: leadersIsFetching } = useGetLeadersQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee },
  );

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('common_manager')} />
    </>
  );

  const renderLeader = (leader: LeaderType) => (
    <Card className={s.cardWrapper} key={'123'}>
      <Avatar size="80" firstName={leader.person.firstName} lastName={leader.person.lastName} />
      <Typography.Title level={4}>{`${leader.person.firstName} ${
        leader.person.lastName?.length === 1 ? leader.person.lastName + '.' : leader.person.lastName
      }`}</Typography.Title>
      <Typography.Text type="secondary" size="14">
        {leader.position.name}
      </Typography.Text>
    </Card>
  );

  return (
    <WithNavigation headerChildren={headerChildren}>
      <WithBackground>
        <WithLoader isLoading={leadersIsFetching}>
          {leadersData?.filter(getLeadersWithDivisions)?.map(renderLeader)}
        </WithLoader>
      </WithBackground>
    </WithNavigation>
  );
};

const getLeadersWithDivisions = (leader: LeaderType) => leader.employeeId;

export default GuideManagement;
