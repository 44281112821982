import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import { transformBackendDataIntoFront } from 'pages/documents/documents-business-trip/documentsBusinessTrip.utils';
import { getKedoTagConfig } from 'pages/documents/documents.utils';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './CardHeader.module.scss';

interface CardHeaderProps {
  businessTrip?: BusinessTripType;
}

const CardHeader: FC<CardHeaderProps> = (props) => {
  const { businessTrip } = props;
  const { dateFrom, dateTo } = businessTrip ?? {};

  const transformedFromToDate = `${transformBackendDataIntoFront(dateFrom).format(
    'DD.MM.YYYY',
  )} - ${transformBackendDataIntoFront(dateTo).format('DD.MM.YYYY')}`;

  const { type, icon, children } = getKedoTagConfig(businessTrip?.currentKedoStatus);
  const description = businessTrip?.currentKedoStatus?.description ?? '';

  return (
    <>
      <Flex vertical gap="4" align="flex-start">
        <Typography.Text weight="400" size="12" type="secondary">
          {t('documents_business_trip_period')}
        </Typography.Text>
        <Typography.Title level={4}>{transformedFromToDate}</Typography.Title>
      </Flex>
      <Flex vertical gap="4" align="flex-start">
        <Tag type={type} className={s.tag}>
          <Icon name={icon} />
          <span>{children}</span>
        </Tag>
        {description && (
          <Typography.Text size="14" className={s[type]}>
            {description}
          </Typography.Text>
        )}
      </Flex>
    </>
  );
};

export default CardHeader;
