import React, { FC, MouseEvent } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getCreatedDate } from 'utils/article.utils';

import { useAddLikeMutation, useRemoveLikeMutation } from 'services/article/articleApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover/cover';
import EventTags from 'containers/event-tags';

import { EventType } from 'types/event.types';

import s from './ArticleEvent.module.scss';

interface ArticleProps {
  event: EventType;
  handleLikeClick?: (code: string) => void;
}

const ArticleEvent: FC<ArticleProps> = (props) => {
  const { event, handleLikeClick } = props;
  const { article, meeting } = event;

  const [addLike] = useAddLikeMutation();
  const [removeLike] = useRemoveLikeMutation();

  const handleAddLike = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const mutation = article.liked ? removeLike : addLike;
    mutation(article.code);

    if (handleLikeClick) {
      handleLikeClick(article.code);
    }
  };

  const renderCategory = (category: { id: string; name: string }) => (
    <Typography.Text type="primary" size="14" key={category.id} weight="500">
      {category.name}
    </Typography.Text>
  );

  const eventIsInFuture = dayjs(meeting.meetDate).isAfter(dayjs());
  const availableMembersCount = meeting.maxEmployees - meeting.countMembers;
  const showAvailablePlaces =
    meeting.registrationActive &&
    eventIsInFuture &&
    !!meeting.maxEmployees &&
    availableMembersCount > 0;
  const eventDate = eventIsInFuture
    ? dayjs(meeting?.meetDate).format('DD.MM.YYYY')
    : t('common_completed');
  return (
    <div className={s.wrapper}>
      <div>
        <Typography.Text type="secondary">{getCreatedDate(article.publishFrom)}</Typography.Text>
      </div>
      <Cover src={article.cover?.link} alt="" className={s.cover} showNoData />
      <Typography.Title level={5} className={s.title}>
        {article.title}
      </Typography.Title>
      <EventTags
        eventDate={eventDate}
        eventIsInFuture={eventIsInFuture}
        isParticipant={event.isParticipant}
        city={meeting?.city}
      />
      {!!article.categories && (
        <div className={s.categories}>{article.categories?.map(renderCategory)}</div>
      )}
      <div className={s.actions}>
        <div>
          <button onClick={handleAddLike}>
            <Typography.Text type="primary" size="12">
              <Icon name={article.liked ? 'thankingFilled' : 'thanking'} />
              {article.likesCnt ?? 0}
            </Typography.Text>
          </button>
          <button>
            <Typography.Text type="primary" size="12">
              <Icon name="comment" />
              {article.commentsCnt ?? 0}
            </Typography.Text>
          </button>
        </div>
        {showAvailablePlaces && (
          <Typography.Text size="12" type="secondary" className={s.positionsCount}>
            <Icon name="human" />
            {t('common_positions_count', { count: availableMembersCount })}
          </Typography.Text>
        )}
        <Typography.Text size="12" type="secondary">
          <Icon name="eye" />
          {article.viewsCnt ?? 0}
        </Typography.Text>
      </div>
    </div>
  );
};

export default ArticleEvent;
