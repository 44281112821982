import { useContext } from 'react';

import { t } from 'tools/i18n';

import { Steps, StepsProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { AssignmentContext } from '../../documentsAssignment';
import s from './CancelHistory.module.scss';

interface Props {
  taskIsLoading?: boolean;
  history?: StepsProps['items'];
}

const CancelHistory = (props: Props) => {
  const { taskIsLoading, history } = props;

  const assignment = useContext(AssignmentContext);
  const cancelHistory = assignment?.kedoTask?.cancelHistory ?? [];

  const itemsToShow = history ?? cancelHistory;

  if (taskIsLoading || !itemsToShow.length) {
    return null;
  }

  return (
    <Card>
      <Typography.Title level={5}>{t('documents_cancel_order_history')}</Typography.Title>
      <Steps className={s.steps} items={itemsToShow} />
    </Card>
  );
};

export default CancelHistory;
