import { Dispatch, SetStateAction } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import {
  getSuggestionDecisionDetailsRoute,
  getSuggestionDecisionPerformerRoute,
  getSuggestionRequestDetailsRoute,
} from 'routes/more/list';

import {
  useHeadRejectSuggestionDecisionByIdMutation,
  useHeadRevisionSuggestionDecisionByIdMutation,
  useRedirectSuggestionDecisionByIdMutation,
  useTakeForReleaseSuggestionDecisionByIdMutation,
} from 'services/me/suggestion/suggestionApiService';

import { Form, FormInstance, handleRequest } from 'gazprom-ui-lib';

import { SuggestionDecisionType, SuggestionType } from 'types/suggestion.types';

import { ModalType } from './moreSuggestionDecision.utils';

export interface Props {
  setModalType: Dispatch<SetStateAction<ModalType>>;
  isDecision?: boolean;
  decision?: SuggestionDecisionType & {
    suggestion: SuggestionType;
  };
  suggestionId?: string;
  form: FormInstance;
}

const useActions = (props: Props) => {
  const params = useParams<{ id: string }>();

  const navigate = useNavigate();

  const { form, setModalType, isDecision, decision, suggestionId } = props;

  const comment = Form.useWatch('comment', form);

  const [headRevisionSuggestion, { isLoading: isHeadRevisionLoading }] =
    useHeadRevisionSuggestionDecisionByIdMutation();

  const [redirectSuggestion, { isLoading: isRedirectingLoading }] =
    useRedirectSuggestionDecisionByIdMutation();

  const [takeForRelease, { isLoading: isTakeForReleaseLoading }] =
    useTakeForReleaseSuggestionDecisionByIdMutation();

  const [headRejectSuggestion, { isLoading: isHeadRejectLoading }] =
    useHeadRejectSuggestionDecisionByIdMutation();

  const handleOpenSuggestionDetails = () => {
    if (suggestionId) {
      if (isDecision && decision) {
        navigate(getSuggestionDecisionDetailsRoute(suggestionId, decision.id));
      } else {
        navigate(getSuggestionRequestDetailsRoute(suggestionId));
      }
    }
  };

  const handleChoosePerformer = () => {
    if (params?.id && suggestionId) {
      navigate(getSuggestionDecisionPerformerRoute(suggestionId, params.id));
    }
  };

  const handleDeclineSuggestion = () => {
    setModalType('decline');
  };

  const handleSentToCorrection = () => {
    setModalType('correction');
  };

  const handleConfirmDeclineSuggestion = () => {
    setModalType('declineComment');
  };

  const handleDeclineSuggestionWithComment = () => {
    const onSuccess = () => {
      setModalType('declined');
    };

    if (params?.id && comment) {
      headRejectSuggestion({ id: params.id, comment }).then(handleRequest({ onSuccess }));
    }
  };

  const handleSubmitForCorrection = () => {
    const onSuccess = () => {
      setModalType('correctionSent');
    };

    if (params?.id && comment) {
      headRevisionSuggestion({ id: params.id, comment }).then(handleRequest({ onSuccess }));
    }
  };

  const handleRedirectSuggestion = () => {
    const onSuccess = () => {
      setModalType('redirectSuccess');
    };

    const onError = () => {
      setModalType('redirectError');
    };

    if (params?.id) {
      redirectSuggestion({ id: params.id, comment: '' }).then(
        handleRequest({ onSuccess, onError }),
      );
    }
  };

  const handleTakeForRelease = () => {
    if (params?.id) {
      takeForRelease({ id: params.id, comment: '' });
    }
  };

  return {
    handleTakeForRelease,
    handleChoosePerformer,
    handleSentToCorrection,
    handleDeclineSuggestion,
    handleRedirectSuggestion,
    handleSubmitForCorrection,
    handleOpenSuggestionDetails,
    handleConfirmDeclineSuggestion,
    handleDeclineSuggestionWithComment,
    isHeadRevisionLoading,
    isRedirectingLoading,
    isTakeForReleaseLoading,
    isHeadRejectLoading,
  };
};

export default useActions;
