import { ReactNode, useContext } from 'react';

import { getCreatedDate } from 'utils/article.utils';

import { Typography } from 'gazprom-ui-lib';

import { AssignmentContext } from '../../documentsAssignment';
import useAssignmentConfig from '../../useAssignmentConfig.hook';
import s from './DetailsHeader.module.scss';

interface Props {
  title?: ReactNode;
}

const DetailsHeader = (props: Props) => {
  const { title: propsTitle } = props;

  const assignment = useContext(AssignmentContext);

  const getAssignmentConfig = useAssignmentConfig({ isList: false, detailed: true });

  const { tag, title, description } = getAssignmentConfig(assignment);

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <Typography.Text size="12" type="secondary">
          {getCreatedDate(assignment?.kedoTask?.updatedAt)}
        </Typography.Text>
        <Typography.Title level={4} className={s.title}>
          {propsTitle || title}
        </Typography.Title>
        {tag}
        {description}
      </div>
    </div>
  );
};

export default DetailsHeader;
