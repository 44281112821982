import { useCallback, useEffect, useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ROUTE } from 'routes/profile/list';

import {
  useConfirmPersonalDataMutation,
  useGetPersonalDataQuery,
  useRequestPersonalDataMutation,
} from 'services/me/meApiService';

import { VERIFICATION_MODALS_ENUM } from 'containers/code-verification/code-verification.utils';

import { REQUEST_PERSONAL_DATA_STATUSES } from 'pages/profile/profile-personal-data/useProfilePersonalData.utils';

const usePersonalData = () => {
  const {
    data: personalData,
    isLoading: personalDataIsLoading,
    error: personalDataError,
  } = useGetPersonalDataQuery();
  const navigate = useNavigate();
  const [requestPersonalData] = useRequestPersonalDataMutation();
  const [confirmPersonalData, { isLoading: isConfirmLoading }] = useConfirmPersonalDataMutation();

  const [isVerificationPageShow, setIsVerificationPageShow] = useState(false);

  const condition = !personalDataIsLoading && personalDataError;

  const showPageAndCallPin = useCallback(() => {
    setIsVerificationPageShow(true);
    requestPersonalData();
  }, [requestPersonalData]);

  useEffect(() => {
    if (condition) {
      showPageAndCallPin();
    }
  }, [condition, showPageAndCallPin]);

  const onConfirm = async (smsCode: string): Promise<VERIFICATION_MODALS_ENUM> => {
    let statusCode = VERIFICATION_MODALS_ENUM.ERROR;

    const res = await confirmPersonalData({ smsCode });

    if ('error' in res && 'data' in res.error) {
      const errorData: SerializedError = res.error.data!;
      const errorCode = errorData.message;

      switch (errorCode) {
        case REQUEST_PERSONAL_DATA_STATUSES.INVALID_CODE: {
          statusCode = VERIFICATION_MODALS_ENUM.WRONG_CODE;
          break;
        }
        case REQUEST_PERSONAL_DATA_STATUSES.TO_MANY_ATTEMPTS: {
          statusCode = VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS;
          break;
        }
        case REQUEST_PERSONAL_DATA_STATUSES.CONFIRMATION_CODE_EXPIRED: {
          statusCode = VERIFICATION_MODALS_ENUM.TIME_IS_UP;
          break;
        }
        default: {
          statusCode = VERIFICATION_MODALS_ENUM.ERROR;
        }
      }
    } else if ('data' in res) {
      statusCode = VERIFICATION_MODALS_ENUM.COMPLETED;
    }

    return statusCode;
  };

  const closeCodeVerification = () => setIsVerificationPageShow(false);
  const handleTimeUp = () => navigate(PROFILE_ROUTE);

  return {
    personalData,
    isConfirmLoading,
    isVerificationPageShow,
    onConfirm,
    closeCodeVerification,
    handleTimeUp,
  };
};

export default usePersonalData;
