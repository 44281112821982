import { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { qrCodeGuideSelector } from 'selectors/guide.selector';
import {
  useActivateSettlementMutation,
  useLazyGetSettlementQuery,
} from 'services/guide/guideApiService';
import { setQRCode } from 'slices/guideSlice';
import { useAppSelector } from 'store';

import { Button, ModalDefault, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { MODALS_QR_ENUM, SettlementType } from 'types/guide.types';

import s from './GuideModalsQR.module.scss';

interface GuideSelectModalsProps {
  handleLocation: () => void;
}

const GuideSelectQRModals: FC<GuideSelectModalsProps> = (props) => {
  const { handleLocation } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState<MODALS_QR_ENUM>(MODALS_QR_ENUM.EMPTY);
  const [selectedEmployee] = useFullSelectedEmployee();
  const QRCode = useAppSelector(qrCodeGuideSelector);
  const [findSettlement, { data: settlement, isLoading }] = useLazyGetSettlementQuery();
  const [activate] = useActivateSettlementMutation();

  const onCancel = () => {
    setModal(MODALS_QR_ENUM.EMPTY);
    dispatch(setQRCode(null));
  };

  const onNotFoundCancel = () => {
    onCancel();
    navigate(GUIDE_ROUTE);
  };

  const onActivate = () => {
    activate({ myEmployeeId: selectedEmployee?.id!, settlementId: QRCode! }).then(
      handleRequest({
        onSuccess: onCancel,
        onError: onCancel,
      }),
    );
  };

  const onScanAgain = () => {
    onCancel();
    navigate(-1);
  };

  const onFindSettlementSuccess = (res?: SettlementType) => res && setModal(MODALS_QR_ENUM.FOUND);

  const cancelModal = () => setModal(MODALS_QR_ENUM.NOT_FOUND);

  useEffect(() => {
    return () => {
      setModal(MODALS_QR_ENUM.EMPTY);
    };
  }, []);

  useEffect(() => {
    QRCode &&
      findSettlement({ id: QRCode }).then(
        handleRequest({
          onSuccess: onFindSettlementSuccess,
          onError: cancelModal,
        }),
      );
  }, [QRCode, findSettlement]);

  return (
    <>
      <ModalDefault open={modal === MODALS_QR_ENUM.FOUND} onCancel={onCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="location" type="success" />
          <ModalDefault.Title>{settlement?.name ?? ''}</ModalDefault.Title>
          <ModalDefault.Description>
            {t('guide_qr_confirm_location_subtitle')}
          </ModalDefault.Description>
          <ModalDefault.Actions>
            <Button type="primary" fullWidth size="large" leftIcon="location" onClick={onActivate}>
              {t('guide_qr_my_location_defined')}
            </Button>
            <Button
              type="secondary"
              fullWidth
              size="large"
              onClick={handleLocation}
              className={s.smallButton}>
              {t('guide_select_button_define_by_geo')}
            </Button>
            <Button type="secondary" fullWidth size="large" onClick={onCancel}>
              {t('common_close')}
            </Button>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>

      <ModalDefault open={modal === MODALS_QR_ENUM.NOT_FOUND} onCancel={onNotFoundCancel}>
        <WithLoader isLoading={isLoading}>
          <ModalDefault.Icon name="attention" type="warning" />
          <ModalDefault.Title>{t('guide_not_found_settlement_title')}</ModalDefault.Title>
          <ModalDefault.Description>
            {t('guide_not_found_settlement_subtitle')}
          </ModalDefault.Description>
          <ModalDefault.Actions>
            <div className={s.notFoundButtonsContainer}>
              <Button type="primary" fullWidth size="large" leftIcon="ok" onClick={onScanAgain}>
                {t('guide_scan_again')}
              </Button>
              <Button
                type="secondary"
                fullWidth
                size="large"
                onClick={handleLocation}
                className={s.smallButton}>
                {t('guide_select_button_define_by_geo')}
              </Button>
              <Button type="secondary" fullWidth size="large" onClick={onNotFoundCancel}>
                {t('common_close')}
              </Button>
            </div>
          </ModalDefault.Actions>
        </WithLoader>
      </ModalDefault>
    </>
  );
};

export default GuideSelectQRModals;
