import { GetMeParticipantsResponseType } from 'services/me/meApiService.types';
import { GetMyLeadersForBusinessTripResponseType } from 'services/my-leaders/myLeadersApiService.types';

import { LeaderType } from 'types/employee.types';

export const DEFAULT_REQUEST_BODY = {
  myEmployeeId: '',
  page: 0,
  size: 500,
};

export const selectFromResult = ({
  data,
  ...other
}: {
  data?: GetMyLeadersForBusinessTripResponseType;
  isLoading: boolean;
}) => {
  const firstLeader = data?.firstLeader?.employeeId;
  const functionalLeader = data?.functionalLeader?.employeeId;

  return {
    data: { firstLeader, functionalLeader },
    ...other,
  };
};

export const participantsSelectFromResult = ({
  data,
  ...other
}: {
  data?: GetMeParticipantsResponseType;
  isLoading: boolean;
  isFetching: boolean;
}) => {
  const result = data?.employees.map((el, index) => ({
    ...el,
    employeeId: el.id,
    num: index,
  }));

  return {
    data: { employees: result },
    ...other,
  };
};

export const transformLeadersIntoOptions = (leader: LeaderType) => {
  const { person } = leader;
  const { firstName, lastName, patronymic } = person;

  return { value: leader.employeeId, label: `${firstName} ${lastName} ${patronymic}` };
};
