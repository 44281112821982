import { useCallback, useEffect } from 'react';

import { BarcodeFormat, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Style } from '@capacitor/status-bar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { setQRCode } from 'slices/guideSlice';

import { Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './GuideQR.module.scss';

const GuideQR = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestScan = useCallback(async () => {
    const listener = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
      await listener.remove();
      await BarcodeScanner.stopScan();

      const parts = result.barcode.rawValue.split('/');
      const uuid = parts[parts.length - 1];
      dispatch(setQRCode(uuid));
      navigate(-1);
    });

    await BarcodeScanner.startScan({
      formats: [BarcodeFormat.QrCode],
    });
  }, [dispatch, navigate]);

  const handleStopBarcode = () => {
    BarcodeScanner.stopScan();
    BarcodeScanner.removeAllListeners();
  };

  const handleBack = () => {
    handleStopBarcode();
    navigate(-1);
  };

  useEffect(() => {
    requestScan();

    return () => {
      handleStopBarcode();
    };
  }, [requestScan]);

  return (
    <WithStatusBar style={Style.Dark}>
      <div className={s.qrWrapper}>
        <Header className={s.header}>
          <Header.GoBackButton onClick={handleBack} />
          <Header.Label label={t('guide_qr_header')} />
        </Header>
        <div className={s.overlay}></div>
        <div className={s.qr}>
          <Typography.Title level={3}>{t('guide_qr_title')}</Typography.Title>
        </div>
      </div>
    </WithStatusBar>
  );
};

export default GuideQR;
