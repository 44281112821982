import React from 'react';

import { t } from 'tools/i18n';

import Card from 'containers/card';

import ProfileFeedbackCreateSelectFeedbackTarget from '../profile-feedback-create-select-feedback-target';
import ProfileFeedbackCreateViewWrapper from '../profile-feedback-create-view-wrapper';
import { CONFIG_STATE } from '../profileFeedbackCreate.utils';

const ProfileFeedbackCreateInitial = () => {
  return (
    <ProfileFeedbackCreateViewWrapper>
      <Card>
        <Card.Default
          title={t('common_thanks_for_work')}
          description={t('feedback_write_gratitude_description')}
          icon="thanking"
        />
      </Card>
      <ProfileFeedbackCreateSelectFeedbackTarget
        nextStep={CONFIG_STATE.ADD_FEEDBACK_CATEGORY}
        showWrapper={false}
      />
    </ProfileFeedbackCreateViewWrapper>
  );
};

export default ProfileFeedbackCreateInitial;
