import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface StatementUnavailableModalProps {
  open: boolean;
  onClose: () => void;
}

const StatementUnavailableModal: FC<StatementUnavailableModalProps> = (props) => {
  const { open, onClose } = props;

  return (
    <ModalDefault open={open} onCancel={onClose}>
      <ModalDefault.Icon name="attention" type="danger" />
      <ModalDefault.Title>{t('documents_statement_approval_impossible')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_statement_leader_is_not_registered')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" fullWidth size="large" onClick={onClose}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default StatementUnavailableModal;
