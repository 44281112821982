import { t } from 'tools/i18n';

import { EmploymentType } from 'types/employee.types';

export const getEmploymentRateTitle = (workRate?: number) => {
  if (workRate === 1) {
    return `${workRate} ${t('common_work_rate')}`;
  }

  return `${workRate} ${t('common_work_part_rate')}`;
};

export const getEmploymentTypeTitle = (employmentType?: EmploymentType) => {
  if (employmentType === 'mainWork') {
    return t('common_work_main');
  }

  return t('common_work_extra');
};
