import { t } from 'tools/i18n';

import { AssignmentWorkTourType } from 'types/assignment.types';

export type WorkTourAttributeType = {
  attributeKey: keyof Pick<
    AssignmentWorkTourType,
    | 'dateFrom'
    | 'dateTo'
    | 'country'
    | 'town'
    | 'destinationOrg'
    | 'divisionName'
    | 'duration'
    | 'target'
    | 'targetComment'
    | 'sourceFinancing'
  >;
  attributeName: string;
  isDate?: boolean;
};

export const WORK_TOUR_ATTRIBUTES: WorkTourAttributeType[] = [
  {
    attributeKey: 'dateFrom',
    attributeName: t('documents_business_trip_date_from'),
    isDate: true,
  },
  {
    attributeKey: 'dateTo',
    attributeName: t('documents_business_trip_date_to'),
    isDate: true,
  },
  {
    attributeKey: 'country',
    attributeName: t('common_country'),
  },
  {
    attributeKey: 'town',
    attributeName: t('common_locality'),
  },
  {
    attributeKey: 'destinationOrg',
    attributeName: t('common_arrangement_appointment'),
  },
  {
    attributeKey: 'target',
    attributeName: t('documents_business_trip_purpose'),
  },
  {
    attributeKey: 'targetComment',
    attributeName: t('documents_business_trip_purpose_clarification'),
  },
  {
    attributeKey: 'sourceFinancing',
    attributeName: t('common_source_of_financing'),
  },
];

export const WORK_TOUR_DRAFT_ATTRIBUTES: WorkTourAttributeType[] = [
  {
    attributeKey: 'dateFrom',
    attributeName: t('documents_business_trip_period'),
    isDate: true,
  },
  {
    attributeKey: 'country',
    attributeName: t('common_country'),
  },
  {
    attributeKey: 'town',
    attributeName: t('common_locality'),
  },
  {
    attributeKey: 'destinationOrg',
    attributeName: t('common_arrangement_appointment'),
  },
];
