import React, { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

export type SegmentedValueType = 'my' | 'employees';

export const SEGMENT_OPTIONS: {
  value: SegmentedValueType;
  label: ReactNode;
}[] = [
  {
    label: <Typography.Title level={5}>{t('common_my')}</Typography.Title>,
    value: 'my',
  },
  {
    label: <Typography.Title level={5}>{t('common_employees')}</Typography.Title>,
    value: 'employees',
  },
];

export const DEFAULT_PAGINATION_CONFIG = {
  page: 1,
  size: 10,
};
