import Salary from 'pages/salary';

import { RouteConfig } from '../routes';
import { SALARY_ROUTE } from './list';

export const salaryRoutes: RouteConfig[] = [
  {
    path: SALARY_ROUTE,
    element: <Salary />,
  },
];
