import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useActivateSettlementMutation,
  useGetMySettlementHistoryQuery,
} from 'services/guide/guideApiService';

import { Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { HistoryItem } from 'types/guide.types';

import GuideSelectButton from '../guide-select-button';
import { getUniqueSettlements } from '../guide.utils';
import s from './GuideMySettlements.module.scss';

const GuideMySettlements = () => {
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();
  const [activateSettlement] = useActivateSettlementMutation();
  const { data, isLoading } = useGetMySettlementHistoryQuery({
    myEmployeeId: selectedEmployee?.id!,
    page: 1,
    size: 500,
  });

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('guide_select_my')} />
    </>
  );

  const unicSettlement = useMemo(() => getUniqueSettlements(data), [data]);

  const handleChangeSettlement = (id: string) => {
    activateSettlement({ myEmployeeId: selectedEmployee?.id!, settlementId: id }).then(() =>
      navigate(GUIDE_ROUTE),
    );
  };

  const renderButtons = (params: HistoryItem) => {
    const { name, id } = params.settlement;

    return <GuideSelectButton name={name} id={id} key={id} onClick={handleChangeSettlement} />;
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false} className={s.contaiener}>
              <Typography.Title level={4}>{t('guide_settlement_list')}</Typography.Title>
              {unicSettlement?.map(renderButtons)}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideMySettlements;
