import React, { FC, PropsWithChildren } from 'react';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './PopoverContent.module.scss';

interface PopoverContentProps extends PropsWithChildren {
  onClose: () => void;
}

const PopoverContent: FC<PopoverContentProps> = (props) => {
  const { children, onClose } = props;

  return (
    <Flex className={s.wrapper} align="center" justify="space-between">
      <Typography.Text weight="500" size="14">
        {children}
      </Typography.Text>
      <button onClick={onClose}>
        <Icon name="delete" />
      </button>
    </Flex>
  );
};

export default PopoverContent;
