import { useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetHeadDivisionLeadersQuery,
  useLazyGetMeParticipantsQuery,
} from 'services/me/meApiService';
import { GetMeParticipantsPropsType } from 'services/me/meApiService.types';
import { useGetMyLeadersForBusinessTripQuery } from 'services/my-leaders/myLeadersApiService';

import { InputProps } from 'gazprom-ui-lib';

import {
  DEFAULT_REQUEST_BODY,
  participantsSelectFromResult,
  selectFromResult,
  transformLeadersIntoOptions,
} from './approvers.utils';

const useApprovers = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leadersData, isFetching: leadersIsFetching } = useGetHeadDivisionLeadersQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee },
  );
  const { data: initialLeaders, isLoading: isInitialLeadersLoading } =
    useGetMyLeadersForBusinessTripQuery(
      { myEmployeeId: selectedEmployee?.id! },
      { skip: !selectedEmployee, selectFromResult },
    );
  const [
    getParticipants,
    {
      data: participantsData,
      isLoading: isParticipantsDataLoading,
      isFetching: isParticipantsDataFetching,
    },
  ] = useLazyGetMeParticipantsQuery({ selectFromResult: participantsSelectFromResult });

  const [requestBody, setRequestBody] = useState<GetMeParticipantsPropsType>(DEFAULT_REQUEST_BODY);
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody, 300);

  const leadersToShow = requestBody.filterParam ? participantsData.employees : leadersData;
  const options = leadersToShow?.map(transformLeadersIntoOptions);
  const isParticipantsLoading = isParticipantsDataLoading || isParticipantsDataFetching;

  useEffect(() => {
    if (selectedEmployee && debouncedRequestBody.filterParam) {
      const requestBody = {
        myEmployeeId: selectedEmployee.id,
        page: 1,
        size: 500,
        filterParam: debouncedRequestBody.filterParam,
      };

      getParticipants(requestBody);
    }
  }, [debouncedRequestBody, debouncedRequestBody.filterParam, getParticipants, selectedEmployee]);

  const handleInputChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;
    setRequestBody((prev) => ({ ...prev, filterParam: value }));
  };

  return {
    options,
    isParticipantsLoading,
    initialLeaders,
    isInitialLeadersLoading,
    leadersIsFetching,
    leadersToShow,
    requestBody,
    handleInputChange,
  };
};

export default useApprovers;
