import React, { FC, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { PROFILE_VACATION_EMPLOYEES_ROUTE, getEmployeeVacationRoute } from 'routes/profile/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetDivisionByIdQuery } from 'services/division/divisionApiService';
import { useGetVacationQuery } from 'services/me/meApiService';

import { Card, Flex, Icon, Input, InputProps, Typography } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import EmployeeAgreedVacation from 'containers/employee-agreed-vacation';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { EmployeeVacationType } from 'types/vacation.types';

import s from './Agreed.module.scss';
import { filteredEmployeesVacation } from './agreed.utils';

const DEFAULT_REQUEST_BODY = { page: 1, size: 500 };

const Agreed: FC = () => {
  const navigate = useNavigate();

  const { divisionId } = useParams<{ divisionId?: string }>();

  const [searchName, setSearchName] = useState<string>('');

  const [selectedEmployee] = useFullSelectedEmployee();
  const employerId = selectedEmployee?.employer?.id;

  const { data: division, isFetching: isDivisionFetching } = useGetDivisionByIdQuery(divisionId!, {
    skip: !divisionId,
  });

  const { debouncedValue: nameToSearch } = useDebounce(searchName, searchName.length ? 300 : 0);

  const { data, isFetching } = useGetVacationQuery(
    {
      employerIds: employerId,
      divisionIds: divisionId,
      text: nameToSearch,
      ...DEFAULT_REQUEST_BODY,
    },
    { skip: !employerId || !divisionId },
  );

  const handleSearchChange: InputProps['onChange'] = (e) => {
    const value = e.target.value;

    setSearchName(value);
  };

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_VACATION_EMPLOYEES_ROUTE} />
      <Header.Label label={t('vacation_agreed')} />
    </>
  );

  const vacationData = filteredEmployeesVacation(data?.content);

  const renderEmployeeVacation = (employeeVacation: EmployeeVacationType) => {
    const { id: employeeId } = employeeVacation?.person || {};
    const { id: positionId } = employeeVacation.position || {};

    const handleClick = () => {
      if (divisionId && positionId) {
        navigate(getEmployeeVacationRoute(divisionId, employeeId, positionId));
      }
    };

    return (
      <EmployeeAgreedVacation key={employeeId} handleClick={handleClick} {...employeeVacation} />
    );
  };

  const searchNotFound = !vacationData?.length && !!searchName.length && !isFetching;
  const noVacationFound = !vacationData?.length && !searchName.length && !isFetching;

  const emptySearchContent = (
    <Flex fullWidth className={s.emptySearch}>
      <Typography.Text size="14" type="secondary">
        {t('common_search_not_found')}
      </Typography.Text>
    </Flex>
  );

  const noVacationFoundContent = (
    <Empty title={'vacation_empty_title'} description={'vacation_empty_description'} />
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <WithLoader isLoading={isDivisionFetching}>
              <Card.Default className={s.header} title={division?.name ?? ''} icon="groupsFilled" />
            </WithLoader>
          </Card>
          <Card withPadding={noVacationFound}>
            {noVacationFound ? (
              noVacationFoundContent
            ) : (
              <>
                <Flex fullWidth className={s.inputWrapper}>
                  <Input
                    allowClear={{
                      clearIcon: <Icon name="deleteFilled" size={20} />,
                    }}
                    size="large"
                    prefix={<Icon name="search" />}
                    placeholder={`${t('vacation_employees_search_placeholder')}...`}
                    value={searchName}
                    onChange={handleSearchChange}
                  />
                </Flex>

                <WithLoader isLoading={isFetching}>
                  {searchNotFound && emptySearchContent}
                  <Flex fullWidth vertical>
                    {vacationData?.map(renderEmployeeVacation)}
                  </Flex>
                </WithLoader>
              </>
            )}
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Agreed;
