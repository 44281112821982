import React, { FC } from 'react';

import { Button } from 'gazprom-ui-lib';

import s from './NextStepButton.module.scss';

interface NextStepButtonProps {
  isLoading?: boolean;
  text: string;
  onClick?: () => void;
}

const NextStepButton: FC<NextStepButtonProps> = (props) => {
  const { isLoading, text, onClick } = props;

  return (
    <div className={s.fixedCard}>
      <Button
        htmlType="submit"
        fullWidth
        loading={isLoading}
        rightIcon="arrowRight"
        size="large"
        type="primary"
        onClick={onClick}>
        {text}
      </Button>
    </div>
  );
};

export default NextStepButton;
