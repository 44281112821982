import React, { FC, MouseEvent } from 'react';

import cn from 'classnames';
import { getCreatedDate } from 'utils/article.utils';

import { useAddLikeMutation, useRemoveLikeMutation } from 'services/article/articleApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover/cover';

import { ArticleFullType } from 'types/article.types';

import s from './Article.module.scss';

interface ArticleProps {
  article: ArticleFullType;
  handleLikeClick?: (code: string) => void;
  showCategories?: boolean;
  showComments?: boolean;
  showNoDataCover?: boolean;
  showPinned?: boolean;
}

const Article: FC<ArticleProps> = (props) => {
  const {
    showCategories = true,
    showNoDataCover = true,
    showComments = true,
    showPinned = true,
    article,
    handleLikeClick,
  } = props;

  const [addLike] = useAddLikeMutation();
  const [removeLike] = useRemoveLikeMutation();

  const handleAddLike = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const mutation = article.liked ? removeLike : addLike;
    mutation(article.code);

    if (handleLikeClick) {
      handleLikeClick(article.code);
    }
  };

  const renderCategory = (category: { id: string; name: string }) => (
    <Typography.Text type="primary" size="14" key={category.id} weight="500">
      {category.name}
    </Typography.Text>
  );

  const areCategoriesAvailable = showCategories && !!article.categories?.length;

  return (
    <div className={s.wrapper}>
      <div>
        <Typography.Text type="secondary">{getCreatedDate(article.publishFrom)}</Typography.Text>
        {showPinned && (
          <Icon name="pinFilled" className={cn(article.pinned ? s.pinned : s.unpinned)} />
        )}
      </div>
      <Typography.Title level={5}>{article.title}</Typography.Title>
      <Cover src={article.cover?.link} alt="" className={s.cover} showNoData={showNoDataCover} />
      {areCategoriesAvailable && (
        <div className={s.categories}>{article.categories?.map(renderCategory)}</div>
      )}
      <div className={s.actions}>
        <div>
          <button onClick={handleAddLike}>
            <Typography.Text type="primary" size="12">
              <Icon name={article.liked ? 'thankingFilled' : 'thanking'} />
              {article.likesCnt ?? 0}
            </Typography.Text>
          </button>
          {showComments && (
            <button>
              <Typography.Text type="primary" size="12">
                <Icon name="comment" />
                {article.commentsCnt ?? 0}
              </Typography.Text>
            </button>
          )}
        </div>

        <Typography.Text size="12" type="secondary">
          <Icon name="eye" />
          {article.viewsCnt ?? 0}
        </Typography.Text>
      </div>
    </div>
  );
};

export default Article;
