import { MORE_SUGGESTION_ROUTE, getSuggestionCreateRoute } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useGetSuggestionCategoriesQuery } from 'services/me/suggestion/suggestionApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SuggestionType } from 'types/suggestion.types';

import s from './MoreSuggestionChooseCategory.module.scss';

const MoreSuggestionChooseCategory = () => {
  const headerChildren = (
    <>
      <Header.GoBack to={MORE_SUGGESTION_ROUTE} />
      <Header.Label label={t('innovations_category_choose')} />
    </>
  );

  const { data: categories, isLoading: isCategoriesLoading } = useGetSuggestionCategoriesQuery();

  const renderCategory = (category: SuggestionType['category']) => {
    return (
      <Card.Navigate
        to={getSuggestionCreateRoute(category.id)}
        className={s.card}
        key={category.id}>
        <Typography.Text size="14" weight="500">
          {category.name}
        </Typography.Text>
        <Icon name="forward" />
      </Card.Navigate>
    );
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <Typography.Title level={4} className={s.categoryTitle}>
              {t('guide_social_policy_available_categories')}
            </Typography.Title>
            <WithLoader isLoading={isCategoriesLoading}>
              {categories?.map(renderCategory)}
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionChooseCategory;
