import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './Comment.module.scss';

interface Props extends PropsWithChildren {
  withPadding?: boolean;
}

const Comment: FC<Props> = (props) => {
  const { children, withPadding = true } = props;

  return <div className={cn(s.wrapper, { [s.withPadding]: withPadding })}>{children}</div>;
};

export default Comment;
