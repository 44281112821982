import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import { AssignmentWorkTourType } from 'types/assignment.types';

import s from './WorkTourDraftDetails.module.scss';

interface Props {
  workTour?: AssignmentWorkTourType;
}

const WorkTourDraftDetails = (props: Props) => {
  const { workTour } = props;

  const formatDate = (date?: string) => {
    return dayjs(date).format('DD.MM.YYYY');
  };

  return (
    <Flex vertical gap="24" className={s.wrapper}>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('documents_business_trip_period')}
        </Typography.Text>
        <Typography.Title level={5}>{`${formatDate(workTour?.dateFrom)} - ${formatDate(
          workTour?.dateTo,
        )}`}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_country')}
        </Typography.Text>
        <Typography.Title level={5}>{workTour?.country}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_locality')}
        </Typography.Text>
        <Typography.Title level={5}>{workTour?.town}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_arrangement_appointment')}
        </Typography.Text>
        <Typography.Title level={5}>{workTour?.destinationOrg}</Typography.Title>
      </Flex>
    </Flex>
  );
};

export default WorkTourDraftDetails;
