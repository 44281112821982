import React, { FC } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { createLabel } from 'utils/createLabel';

import { Icon, Typography } from 'gazprom-ui-lib';

import { VacationType } from 'types/vacation.types';

import s from './SingleVacation.module.scss';

interface SingleVacationProps extends VacationType {}

const SingleVacation: FC<SingleVacationProps> = (props) => {
  const { days, startDate, endDate, type } = props;
  const startDateDayjs = dayjs(startDate).format('DD.MM.YYYY');
  const endDateDayjs = dayjs(endDate).format('DD.MM.YYYY');

  return (
    <div className={s.item}>
      <div className={s.row}>
        <div>
          <Typography.Title level={5}>{type}</Typography.Title>
        </div>
        <div className={s.days}>
          <Typography.Title className={s.daysCount} level={5} type="primary">
            {days} {createLabel(days, [t('count_day_1'), t('count_day_2'), t('count_day_3')])}
          </Typography.Title>
        </div>
      </div>
      <div className={cn(s.row, s.withLine)}>
        <div>
          <Icon className={s.icon} name="calendar" />
          <Typography.Title level={5}>{startDateDayjs}</Typography.Title>
        </div>
        <div>
          <Typography.Title level={5}>{endDateDayjs}</Typography.Title>
        </div>
      </div>
    </div>
  );
};

export default SingleVacation;
