import React, { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getNewsWithIdRoute } from 'routes/home/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  eventsShowMyEventsStateSelector,
  eventsStateSelector,
} from 'selectors/eventsSlice.selector';
import { activeNewsEventsTagsSelector } from 'selectors/secureStorageSlice.selector';
import { setShowMyEvents } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { Switch, Typography } from 'gazprom-ui-lib';

import NoData from 'components/no-data';
import UniversalLoader from 'components/universal-loader';
import ArticleEvent from 'containers/article-event';
import OpeningFilterBar from 'containers/opening-filer-bar';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import { SEGMENT_NEWS_EVENT_TYPE } from 'pages/home/home-news/home-news-list/homeNewsList.utils';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { EventType } from 'types/event.types';

import s from './EventsContent.module.scss';
import useActions from './useActions.hook';
import useRequestsHook from './useRequests.hook';

const EventsContent = () => {
  const params = useParams<{ newsCode: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const loadMoreRef = useRef<boolean>(false);

  const dispatch = useAppDispatch();

  const eventsState = useAppSelector(eventsStateSelector);
  const showMyEvents = useAppSelector(eventsShowMyEventsStateSelector);
  const activeEventsTagsFromState = useAppSelector(activeNewsEventsTagsSelector);

  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  const [activeEventsTags] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_EVENTS_TAGS,
    [] as string[],
  );

  useEffect(() => {
    if (location?.state?.isParticipant && !isInitialised) {
      dispatch(setShowMyEvents(true));
    }
    setIsInitialised(true);
  }, [dispatch, isInitialised, location, navigate]);

  const bodyToDebounce = useMemo(() => {
    return { ...eventsState, activeEventsTags };
  }, [activeEventsTags, eventsState]);

  const { debouncedValue: debouncedRequestBody, isDebouncing: requestBodyIsDebouncing } =
    useDebounce(bodyToDebounce, isInitialised ? 600 : 0);

  const { categoriesData, isFetching, eventsData, isLoading } = useRequestsHook({
    isInitialised,
    loadMoreRef,
    debouncedRequestBody,
  });

  const {
    handleAddRemoveNewsActiveTags,
    handleTagsReset,
    handleShowAll,
    handleLikeClick,
    handleFetchMoreData,
  } = useActions({ loadMoreRef });

  const isFetchMoreAvailable =
    !isFetching &&
    !isLoading &&
    !!eventsData?.items?.length &&
    eventsState.page * eventsState.size < (eventsData?.totalCount ?? 0) &&
    loadMoreRef.current;

  const infiniteScrollIsFetchingProp = isFetching || requestBodyIsDebouncing;

  const renderEvent = (event: EventType) => (
    <button
      onClick={() =>
        navigate(getNewsWithIdRoute(event.meeting.id), {
          state: {
            type: SEGMENT_NEWS_EVENT_TYPE,
          },
        })
      }
      className={s.article}
      key={`events__${event.meeting.id}`}>
      <ArticleEvent event={event} handleLikeClick={handleLikeClick} />
    </button>
  );
  const noData =
    isLoading || infiniteScrollIsFetchingProp ? (
      <></>
    ) : (
      <NoData
        description={t(
          showMyEvents
            ? 'home_my_events_not_found_description'
            : 'home_events_not_found_description',
        )}
        header={t(showMyEvents ? 'home_my_events_not_found' : 'home_events_not_found')}
      />
    );

  const handleSwitchChange = (e: boolean) => dispatch(setShowMyEvents(e));

  return (
    <>
      <div className={cn(s.switch, { [s.switchHidden]: params?.newsCode })}>
        <Typography.Text size="14" weight="500">
          {t('home_my_events_show')}
        </Typography.Text>
        <Switch checked={showMyEvents} onChange={handleSwitchChange} />
      </div>
      <OpeningFilterBar
        handleTagsReset={handleTagsReset}
        handleShowAll={handleShowAll}
        handleAddRemoveNewsActiveTags={handleAddRemoveNewsActiveTags}
        activeTags={activeEventsTagsFromState}
        tagsToRender={categoriesData?.items}
      />
      <WithLoader
        loader={
          <div className={s.loader}>
            <UniversalLoader />
          </div>
        }
        isLoading={isLoading}>
        <WithInfiniteScroll
          fetchMoreData={handleFetchMoreData}
          isFetching={infiniteScrollIsFetchingProp}
          isFetchMoreAvailable={isFetchMoreAvailable}>
          {eventsState?.eventsToShow?.length ? eventsState.eventsToShow.map(renderEvent) : noData}
        </WithInfiniteScroll>
      </WithLoader>
    </>
  );
};

export default EventsContent;
