import React, { FC, useEffect, useMemo, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AvailableResult, NativeBiometric } from 'capacitor-native-biometric';
import { useMatch, useNavigate } from 'react-router-dom';
import { PROFILE_MY_SALARY_PAYSLIP_ROUTE, PROFILE_MY_SALARY_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetPayslipQuery } from 'services/my-salary/mySalaryApiService';

import { Button, Carousel, Icon } from 'gazprom-ui-lib';

import Header from 'containers/header';
import PasscodeVerification from 'containers/passcode-verification';
import WithLoader from 'containers/wrappers/with-loader';

import { KEDO_COPY_FILE_TYPES } from 'types/documents.types';
import { DateTypeMonth } from 'types/mySalary.types';

import MySalaryEmpty from '../my-salary-empty';
import { monthMap } from '../mySalary.utils';
import s from './MySalarySegmentMonth.module.scss';
import MySalaryAccrued from './my-salary-accrued';
import MySalaryModal from './my-salary-modal';
import MySalaryNavigatorMonth from './my-salary-navigator-month';
import MySalaryPaid from './my-salary-paid';
import MySalaryWithheld from './my-salary-withheld';

interface MySalarySegmentYearProps {}

const MySalarySegmentMonth: FC<MySalarySegmentYearProps> = () => {
  const navigate = useNavigate();

  const isPayslipRequested = !!useMatch(PROFILE_MY_SALARY_PAYSLIP_ROUTE);

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: payslipData, isLoading } = useGetPayslipQuery({
    myEmployeeId: selectedEmployee!.id ?? skipToken,
  });

  const [activeModal, setActiveModal] = useState('');
  const [date, setDate] = useState<DateTypeMonth | null>(null);

  const [biometricStatus, setBiometricStatus] = useState<null | AvailableResult>(null);
  const [biometricIsLoading, setBiometricIsLoading] = useState<boolean>(true);

  const { handleDownload, loadingKey } = useDownloadKedoFile();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      NativeBiometric.isAvailable()
        .then(setBiometricStatus)
        .finally(() => setBiometricIsLoading(false));
    } else {
      setBiometricIsLoading(false);
    }
  }, []);

  const handleClosePasswordVerification = () => {
    navigate(PROFILE_MY_SALARY_ROUTE);
  };

  useEffect(() => {
    if (!!payslipData?.length) {
      const currentMonth = payslipData[payslipData.length - 1]?.month;
      const currentYear = payslipData[payslipData.length - 1]?.year;
      const firstMonth = payslipData[0]?.month;
      const firstYear = payslipData[0]?.year;
      setDate({
        month: currentMonth!,
        year: currentYear!,
        max: {
          year: currentYear!,
          month: currentMonth!,
        },
        min: {
          year: firstYear!,
          month: firstMonth!,
        },
      });
    }
  }, [payslipData]);

  const salaryDataByCurrentMonth = useMemo(() => {
    if (!payslipData || !date) {
      return null;
    }

    return payslipData.find((el) => el.month === date.month && el.year === date.year);
  }, [date, payslipData]);

  const handleDownloadPayslip = () => {
    if (selectedEmployee && salaryDataByCurrentMonth) {
      const { payslipFile, id } = salaryDataByCurrentMonth;

      const { fileKey } = payslipFile || {};

      if (fileKey) {
        handleDownload({
          fileKey,
          copyFileType: KEDO_COPY_FILE_TYPES.PAYSLIP,
          taskId: id,
        })();
      }
    }
  };

  const handleVerified = () => {
    handleClosePasswordVerification();
    handleDownloadPayslip();
  };

  const onClick = () => {
    navigate(PROFILE_MY_SALARY_PAYSLIP_ROUTE);
  };

  if (payslipData && !payslipData.length) {
    return (
      <WithLoader isLoading={isLoading}>
        <MySalaryEmpty
          title={t('my_salary_no_data_year_title')}
          description={t('my_salary_no_data_year_description')}
        />
      </WithLoader>
    );
  }

  return (
    <WithLoader isLoading={isLoading || salaryDataByCurrentMonth === null}>
      {isPayslipRequested && !biometricIsLoading && (
        <PasscodeVerification biometricStatus={biometricStatus} onVerified={handleVerified}>
          <div onClick={handleClosePasswordVerification}>
            <Icon name="arrowLeft" />
          </div>
          <Header.Label label={t('my_salary_payslip')} />
        </PasscodeVerification>
      )}
      {!isPayslipRequested && (
        <div className={s.container}>
          {date && <MySalaryNavigatorMonth date={date} setDate={setDate} />}
          {salaryDataByCurrentMonth && !salaryDataByCurrentMonth.isFirstHalf ? (
            <>
              <Carousel
                className={s.carousel}
                infinite={false}
                arrows
                prevArrow={
                  <button>
                    <Icon name="back" size={15} />
                  </button>
                }
                nextArrow={
                  <button>
                    <Icon name="forward" size={15} />
                  </button>
                }>
                <MySalaryPaid data={salaryDataByCurrentMonth} />
                <MySalaryAccrued data={salaryDataByCurrentMonth} />
                <MySalaryWithheld data={salaryDataByCurrentMonth} />
              </Carousel>
              <Button
                disabled={!salaryDataByCurrentMonth?.payslipFile}
                type="primary"
                leftIcon="download"
                fullWidth
                size="large"
                onClick={onClick}
                className={s.button}
                loading={!!loadingKey}>
                {t('my_salary_order_payslip')}
              </Button>
            </>
          ) : (
            <MySalaryEmpty
              title={t('my_salary_no_data_month_title')}
              description={t('my_salary_no_data_month_description')}
            />
          )}
          <MySalaryModal
            open={activeModal === 'success'}
            handleClose={() => setActiveModal('')}
            isSuccess={true}
            selectedMonth={monthMap[(date as DateTypeMonth)?.month as keyof typeof monthMap]?.long}
            selectedYear={date?.year}
          />
          <MySalaryModal open={activeModal === 'fail'} handleClose={() => setActiveModal('')} />
        </div>
      )}
    </WithLoader>
  );
};

export default MySalarySegmentMonth;
