import { useContext } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import { AssignmentContext } from '../../documentsAssignment';
import s from './CancelComment.module.scss';

interface Props {
  showComment?: boolean;
}

const CancelComment = (props: Props) => {
  const { showComment } = props;

  const assignment = useContext(AssignmentContext);

  if (!showComment) {
    return null;
  }

  return (
    <div className={s.attributesTitle}>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('documents_cancel_order_reason')}
        </Typography.Text>
        <Typography.Title level={5}>{assignment?.comment}</Typography.Title>
      </Flex>
    </div>
  );
};

export default CancelComment;
