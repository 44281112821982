export enum FAMILY_FORM_ENUM {
  CRIMINAL = 'criminal',
  CRIMINAL_DETAILS = 'criminalDetail',
  BIRTH_PLACE = 'birthPlace',
  WORK_PLACE = 'placeOfWork',
  JOB_IN_GSP = 'jobInGsp',
  JOB_IN_GSP_DETAILS = 'jobInGspDetails',
  REGISTRATION_ADDRESS = 'registrationAddress',
  RESIDENTIAL_ADDRESS = 'residentialAddress',
  PHONE = 'phone',
  FAMILY_TYPE = 'familyType',
  BIRTH_DATE = 'birthDate',
  NAME = 'name',
  PATRONYMIC = 'patronymic',
  SURNAME = 'surname',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}
