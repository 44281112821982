import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetNewsCategoriesPropsType,
  GetNewsCategoriesResponseType,
} from './categoryApiService.types';

import { createCategoryUrl } from './utils';

const categoryApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getNewsCategories: builder.query<GetNewsCategoriesResponseType, GetNewsCategoriesPropsType>({
      query: (params) => ({
        url: createCategoryUrl(prepareQueryParams(params)),
      }),
    }),
  }),
});

export const { useLazyGetNewsCategoriesQuery, useGetNewsCategoriesQuery } = categoryApiService;
