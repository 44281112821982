import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import AttributeDate from 'containers/attribute/attribute-date';

interface DatePickerProps {
  name: string;
  label: string;
  required?: boolean;
  initialValue?: string;
  yearConfig: {
    initialMaxYear: number;
    initialMinYear: number;
  };
}

const DatePicker: FC<DatePickerProps> = (props) => {
  const { name, label, required = false, initialValue, yearConfig } = props;
  const formattedValue = initialValue ? dayjs(initialValue).format('DD.MM.YYYY') : '';

  return (
    <AttributeDate
      id={name}
      name={label}
      type={'DATE'}
      placeholder={t('common_select_date')}
      isMandatory={required}
      isPublish={true}
      value={formattedValue}
      yearConfig={yearConfig}
      tooltip={''}
      code={''}
      createdAt={''}
      creator={''}
      editor={null}
      num={0}
      statementId={''}
      updatedAt={null}
    />
  );
};

export default DatePicker;
