import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import {
  useLazyGetSuggestionDecisionsQuery,
  useLazyGetSuggestionsQuery,
} from 'services/me/suggestion/suggestionApiService';
import {
  GetSuggestionDecisionsPropsType,
  GetSuggestionsPropsType,
  GetSuggestionsResponseType,
} from 'services/me/suggestion/suggestionApiService.types';

import { SegmentedProps, handleRequest } from 'gazprom-ui-lib';

import { PaginationPropsType } from 'types/pagination.types';
import { SuggestionPreviewType } from 'types/suggestion.types';

import { DEFAULT_ALL_STATUS } from './containers/ideas-statuses/ideasStatuses.utils';
import { DEFAULT_PAGINATION_CONFIG, SegmentedValueType } from './moreSuggestions.utils';

interface Props {
  activeStatus: string;
  setSuggestionsToShow: Dispatch<SetStateAction<SuggestionPreviewType[]>>;
  pagination: PaginationPropsType;
  setPagination: Dispatch<SetStateAction<PaginationPropsType>>;
  isRefreshing: boolean;
  setIsRefreshing: Dispatch<SetStateAction<boolean>>;
  isSuggestionDecisions: boolean;
  setActiveStatus: Dispatch<SetStateAction<string>>;
  selectedSegment: SegmentedValueType;
  setSelectedSegment: Dispatch<SetStateAction<SegmentedValueType>>;
}

const useGetDataHook = (props: Props) => {
  const {
    activeStatus,
    setSuggestionsToShow,
    pagination,
    setPagination,
    isRefreshing,
    setIsRefreshing,
    isSuggestionDecisions,
    setActiveStatus,
    selectedSegment,
    setSelectedSegment,
  } = props;

  const [getSuggestions, { data: suggestions, isFetching, isLoading, isUninitialized }] =
    useLazyGetSuggestionsQuery();
  const [
    getSuggestionDecisions,
    { data: suggestionDecisions, isFetching: isDecisionsFetching, isLoading: isDecisionsLoading },
  ] = useLazyGetSuggestionDecisionsQuery();

  const isStatusApplied = activeStatus && activeStatus !== DEFAULT_ALL_STATUS.code;

  const fetchMySuggestions = useCallback(() => {
    const onSuccess = (data?: GetSuggestionsResponseType) => {
      if (data && 'items' in data) {
        if (data.page === 1) {
          setSuggestionsToShow(data.items);
        } else {
          setSuggestionsToShow((prevState) => prevState.concat(data.items));
        }
      }
    };

    if (!isRefreshing && selectedSegment === 'my') {
      const preparedBody: GetSuggestionsPropsType = { ...pagination };

      if (isStatusApplied) {
        preparedBody.curStatus = activeStatus;
      }

      getSuggestions(preparedBody).then(handleRequest({ onSuccess }));
    }
  }, [
    activeStatus,
    getSuggestions,
    isRefreshing,
    isStatusApplied,
    pagination,
    selectedSegment,
    setSuggestionsToShow,
  ]);

  useEffect(() => {
    fetchMySuggestions();
  }, [fetchMySuggestions]);

  useEffect(() => {
    if (isSuggestionDecisions) {
      const preparedBody: GetSuggestionDecisionsPropsType = {};

      if (isStatusApplied) {
        preparedBody.status = activeStatus;
      }

      getSuggestionDecisions(preparedBody);
    }
  }, [activeStatus, getSuggestionDecisions, isStatusApplied, isSuggestionDecisions]);

  const resetState = () => {
    setSuggestionsToShow([]);
    setPagination(DEFAULT_PAGINATION_CONFIG);
  };

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) => {
    setSelectedSegment(segment as SegmentedValueType);
    setPagination(DEFAULT_PAGINATION_CONFIG);
    setSuggestionsToShow([]);

    setActiveStatus(DEFAULT_ALL_STATUS.code);
  };

  const handlePageRefresh = () => {
    setIsRefreshing(true);
    resetState();
    fetchMySuggestions();
    setIsRefreshing(false);
  };

  const handleSetActiveStatus = (status: SetStateAction<string>) => {
    if (activeStatus !== status) {
      resetState();
      setActiveStatus(status);
    }
  };

  return {
    suggestions,
    suggestionDecisions,
    isFetching: isFetching || isDecisionsFetching,
    isLoading: isLoading || isDecisionsLoading,
    isUninitialized: isUninitialized,
    handleSetActiveStatus,
    handlePageRefresh,
    handleSegmentChange,
  };
};

export default useGetDataHook;
