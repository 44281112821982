import React, { Dispatch, FC, MouseEvent, SetStateAction, useRef, useState } from 'react';

import { t } from 'tools/i18n';

import { useAddCommentMutation, useEditCommentMutation } from 'services/article/articleApiService';

import {
  Button,
  Icon,
  Input,
  InputProps,
  ModalDefault,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { COMMENT_MODALS_ENUM, CurrentCommentDataType } from '../homeDetailsComments.types';

import s from './HomeNewsDetailsCommentsAdd.module.scss';

interface HomeNewsDetailsCommentsAddProps {
  id?: string;
  setShowAddComment: Dispatch<SetStateAction<boolean>>;
  commentType: COMMENT_MODALS_ENUM;
  commentData: CurrentCommentDataType | null;
}

const HomeNewsDetailsCommentsAdd: FC<HomeNewsDetailsCommentsAddProps> = (props) => {
  const { setShowAddComment, id, commentType, commentData } = props;
  const { commentId, initialComment = '' } = commentData || {};
  const containerRef = useRef<HTMLDivElement>(null);

  const [comment, setComment] = useState<string>(initialComment);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const title =
    commentType === COMMENT_MODALS_ENUM.ADD ? t('home_add_comment') : t('home_edit_comment');

  const [addComment, { isLoading: isAddingComment }] = useAddCommentMutation();
  const [editComment, { isLoading: isEditingComment }] = useEditCommentMutation();
  const handleSetComment: InputProps['onChange'] = (e) => {
    setComment(e.target.value);
  };

  const handleAddComment = () => {
    const onSuccess = (text: string) => {
      // setCommentsToShow((prevState) => prevState.concat([res]));
      setModalOpen((prev) => !prev);
      setModalTitle(text);
    };

    if (id) {
      if (commentType === COMMENT_MODALS_ENUM.ADD) {
        addComment({ comment, newsCode: id }).then(
          handleRequest({ onSuccess: () => onSuccess(t('home_comment_sent')) }),
        );

        return;
      }

      if (commentType === COMMENT_MODALS_ENUM.EDIT && commentId) {
        editComment({ comment, newsCode: id, commentId }).then(
          handleRequest({ onSuccess: () => onSuccess(t('home_comment_changed')) }),
        );
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowAddComment(false);
    }
  };

  const handleModalClose = () => {
    setShowAddComment(false);
    setModalTitle('');
  };

  const disableAddComment = modalOpen || isEditingComment || isAddingComment;

  return (
    <>
      <div className={s.wrapper} onClick={handleClick} id="addCommentModalContainer">
        <div className={s.inputWrapper} ref={containerRef}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Input
            placeholder={`${title}...`}
            size="large"
            onChange={handleSetComment}
            value={comment}
            autoFocus
            addonAfter={
              !!comment && (
                <button disabled={disableAddComment} onClick={handleAddComment}>
                  <Icon name="sendFilled" />
                </button>
              )
            }
          />
        </div>
      </div>
      <ModalDefault
        open={modalOpen}
        onCancel={handleModalClose}
        getContainer={'#addCommentModalContainer'}>
        <ModalDefault.Icon name="ok" type="success" />
        <ModalDefault.Title>{modalTitle}</ModalDefault.Title>
        <ModalDefault.Actions>
          <Button onClick={handleModalClose} fullWidth size="large">
            {t('common_ok')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default HomeNewsDetailsCommentsAdd;
