import { FC, useEffect, useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetPayslipQuery } from 'services/my-salary/mySalaryApiService';

import WithLoader from 'containers/wrappers/with-loader';

import { DateTypeYear } from 'types/mySalary.types';

import MySalaryEmpty from '../my-salary-empty';
import MySalaryNavigatorYear from './my-salary-navigator-year';
import MySalaryYear from './my-salary-year';

interface MySalarySegmentYearProps {}

const MySalarySegmentYear: FC<MySalarySegmentYearProps> = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: payslipData, isLoading } = useGetPayslipQuery({
    myEmployeeId: selectedEmployee!.id,
  });
  const [date, setDate] = useState<DateTypeYear | null>(null);

  useEffect(() => {
    if (!!payslipData?.length) {
      const currentYear = payslipData[payslipData.length - 1]?.year;
      const firstYear = payslipData[0]?.year;

      setDate({
        year: currentYear!,
        max: {
          year: currentYear!,
        },
        min: {
          year: firstYear!,
        },
      });
    }
  }, [payslipData]);

  const salaryDataByCurrentYear = useMemo(() => {
    if (!payslipData || !date) {
      return null;
    }

    return payslipData.filter((el) => el.year === date.year);
  }, [date, payslipData]);

  if (payslipData && !payslipData.length) {
    return (
      <WithLoader isLoading={isLoading}>
        <MySalaryEmpty
          title={t('my_salary_no_data_year_title')}
          description={t('my_salary_no_data_year_description')}
        />
      </WithLoader>
    );
  }

  return (
    <WithLoader isLoading={isLoading || salaryDataByCurrentYear === null}>
      {date && <MySalaryNavigatorYear date={date} setDate={setDate} />}
      {salaryDataByCurrentYear && <MySalaryYear data={salaryDataByCurrentYear} year={date?.year} />}
    </WithLoader>
  );
};

export default MySalarySegmentYear;
