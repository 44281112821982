import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isApprove?: boolean;
}

const ApproveModal = (props: Props) => {
  const { isOpen, isApprove, handleClose } = props;

  return (
    <ModalDefault key="approveTaskModal" open={isOpen} onCancel={handleClose} width={300}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>
        {t(isApprove ? 'common_approved' : 'common_familiarized')}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(isApprove ? 'documents_approved' : 'documents_familiarized')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button size="large" fullWidth onClick={handleClose}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ApproveModal;
