import React, { FC, Fragment, useContext, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetEmployeeQuery } from 'services/employee/employeeApiService';

import { Icon, Input, InputProps, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import { EmployeeType } from 'types/employee.types';

import s from '../ProfileFeedbackCreate.module.scss';
import { CONFIG_STATE, FeedbackCreateContext } from '../profileFeedbackCreate.utils';
import {
  DEFAULT_REQUEST_BODY,
  RequestBodyType,
} from './profileFeedbackCreateSelectFeedbackTarget.utils';

interface FeedbackCreateSelectFeedbackTargetProps {
  nextStep?: CONFIG_STATE;
  showWrapper?: boolean;
}
const ProfileFeedbackCreateSelectFeedbackTarget: FC<FeedbackCreateSelectFeedbackTargetProps> = (
  props,
) => {
  const { nextStep, showWrapper = true } = props;

  const [requestBody, setRequestBody] = useState<RequestBodyType>(DEFAULT_REQUEST_BODY);

  const [employeesList, setEmployeeList] = useState<EmployeeType[]>([]);

  const { setFeedbackTarget, setConfigStep } = useContext(FeedbackCreateContext);

  const [
    getEmployee,
    { isLoading: employeeIsLoading, isFetching: employeeIsFetching, data: employeeData },
  ] = useLazyGetEmployeeQuery();

  const renderEmployee = (employee: EmployeeType | null, index: number) => {
    if (!employee || !setFeedbackTarget || !setConfigStep) {
      return <Fragment key={`broken_employee_${index}`}></Fragment>;
    }

    const handleEmployeeClick = () => {
      setConfigStep((prevState) => ({ current: nextStep ?? prevState.previous! }));
      setFeedbackTarget(employee);
    };

    return (
      <button
        onClick={handleEmployeeClick}
        className={cn(s.defaultCard, s.padding)}
        key={employee.id}>
        <Bio
          firstName={employee?.person?.firstName}
          lastName={employee?.person?.lastName}
          staffName={employee?.staff?.name}
        />
        <Icon name="forward" />
      </button>
    );
  };

  const handleGoBack = () => {
    if (setConfigStep) {
      setConfigStep((prevState) => ({
        current: prevState.previous!,
      }));
    }
  };

  const handleChange: InputProps['onChange'] = (e) => {
    setRequestBody({
      ...DEFAULT_REQUEST_BODY,
      filterParam: e.target.value,
    });
    setEmployeeList([]);
  };

  const { debouncedValue: debouncedRequestBody, isDebouncing: employeeIsDebouncing } = useDebounce(
    requestBody,
    300,
  );

  useEffect(() => {
    if (!employeeIsDebouncing) {
      getEmployee(debouncedRequestBody).then((res) => {
        if (res?.data && 'employees' in res.data) {
          setEmployeeList((prevState) => {
            if (res.data?.page !== 1) {
              return prevState.concat(res.data?.employees!);
            } else {
              return res.data?.employees;
            }
          });
        }
      });
    }
  }, [debouncedRequestBody, employeeIsDebouncing, getEmployee]);

  const header = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label label={t('common_to_whom')} />
    </>
  );

  const isFetchMoreAvailable =
    requestBody.page * requestBody.size < (employeeData?.totalCount ?? 0);
  const isFetching = employeeIsDebouncing || employeeIsFetching;

  const content = (
    <Card>
      <Typography.Title level={4} className={s.header}>
        {t('common_to_whom')}
      </Typography.Title>
      <Input
        onChange={handleChange}
        value={requestBody.filterParam}
        allowClear={{
          clearIcon: <Icon name="deleteFilled" size={20} />,
        }}
        size="large"
        prefix={<Icon name="search" />}
        placeholder={`${t('common_fast_search')}...`}
      />
      <WithLoader isLoading={employeeIsLoading}>
        <WithInfiniteScroll
          fetchMoreData={() => {
            setRequestBody((prevState) => ({ ...prevState, page: prevState.page + 1 }));
          }}
          isFetching={isFetching}
          isFetchMoreAvailable={isFetchMoreAvailable}
          className={s.list}>
          {employeesList.map(renderEmployee)}
        </WithInfiniteScroll>
      </WithLoader>
    </Card>
  );

  if (showWrapper) {
    return (
      <WithNavigation headerChildren={header}>
        <WithBackground>{content}</WithBackground>
      </WithNavigation>
    );
  }

  return content;
};

export default ProfileFeedbackCreateSelectFeedbackTarget;
