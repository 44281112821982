import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Input, Radio, Typography } from 'gazprom-ui-lib';

import { SoftwareSkillsType } from 'types/topManagement.types';

import Collapse from '../../../collapse';
import s from '../../SoftwareForm.module.scss';
import { SOFTWARE_FORM_ENUM, radioOptions } from '../../softwareForm.utils';

interface LanguageCardProps {
  id: string | number;
  onClick: () => void;
  initialData: Partial<SoftwareSkillsType>;
}

const SoftwareCard: FC<LanguageCardProps> = (props) => {
  const { id, initialData, onClick } = props;
  const key = `${SOFTWARE_FORM_ENUM.SOFTWARE_NAME}_${id}`;

  const form = Form.useFormInstance();
  const softwareTitleName = Form.useWatch(key, form);

  const { softwareName, level } = initialData || {};
  const [displayedName, setDisplayedName] = useState(
    softwareName || t('top_management_software_name'),
  );

  useEffect(() => {
    const isTouched = form.isFieldTouched(key);

    if (isTouched) {
      setDisplayedName(softwareTitleName);
    }
  }, [softwareTitleName, form, key]);

  return (
    <Card>
      <Collapse
        noPadding
        defaultActiveKey={id}
        items={[
          {
            key: id,
            label: <Typography.Title level={5}>{displayedName}</Typography.Title>,
            children: (
              <Flex gap="24" vertical>
                <Form.Item
                  name={`${SOFTWARE_FORM_ENUM.IS_ENTERED_BY_USER}_${id}`}
                  initialValue={true}
                  hidden
                />
                <Form.Item
                  name={`${SOFTWARE_FORM_ENUM.SOFTWARE_NAME}_${id}`}
                  rules={[formRules.required]}
                  initialValue={softwareName}>
                  <Input size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${SOFTWARE_FORM_ENUM.LEVEL}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_languages_level')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={level}>
                  <Radio.Group options={radioOptions} className={s.radioGroup} />
                </Form.Item>

                <Button type="secondary" fullWidth size="large" danger onClick={onClick}>
                  {t('common_delete')}
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default SoftwareCard;
