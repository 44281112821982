import { useState } from 'react';

import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

type File = {
  fileKey: string;
  suggestionId: string;
  fileName: string;
};

const useDownloadFile = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const [loadingKey, setLoadingKey] = useState<string>('');
  const { handleOpenFile } = useOpenFileByUrl();

  return {
    handleDownload:
      ({ fileKey, suggestionId, fileName }: File) =>
      async () => {
        if (selectedEmployee) {
          setLoadingKey(fileKey);

          try {
            await handleOpenFile({
              url: createDownloadUrl(suggestionId, fileKey),
              withAuth: true,
              fileKey,
              myEmployeeId: selectedEmployee.id,
              defaultFileName: fileName,
            });
          } finally {
            setLoadingKey('');
          }
        }
      },
    loadingKey,
  };
};

export default useDownloadFile;

const createDownloadUrl = (suggestionId: string, fileKey: string) => {
  return `${
    process.env.https://api.dev.tockl.ru/api/v1/
  }suggestion/${suggestionId}/download?fileKey=${encodeURIComponent(fileKey)}`;
};
