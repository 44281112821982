import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { FileType } from 'types/file.types';
import { SettlementType } from 'types/guide.types';

type ParamsType = {
  fileKey?: string;
  url: string;
  withAuth?: boolean;
};

interface Props {
  fileInstruction?: SettlementType['fileInstruction'];
  handleOpenFile: (handleOpenParams: ParamsType) => Promise<void>;
}

const useDownloadFile = (props: Props) => {
  const { fileInstruction, handleOpenFile } = props;

  const fileKey = fileInstruction?.link ?? '';
  const fileName = fileInstruction?.name ?? '';
  const fileLink = fileInstruction?.link ?? '';

  const file: FileType = {
    attributeId: null,
    description: null,
    fileKeyWithStamp: null,
    num: null,
    signs: null,
    fileKey: fileKey,
    fileName: t('guide_download', { name: fileName }),
  };

  const downloadFile = () => {
    if (fileLink) {
      handleOpenFile({ url: createMediaDownloadUrl(fileLink), fileKey: fileLink, withAuth: true });
    }
  };

  return {
    file,
    fileKey,
    fileName,
    downloadFile,
  };
};

export default useDownloadFile;
