import React, { FC, PropsWithChildren, ReactElement, cloneElement, useRef } from 'react';

import { usePullToRefresh } from 'utils/usePullToRefresh.hook';

import UniversalLoader from 'components/universal-loader';

import s from './WithPullToRefresh.module.scss';

export interface WithPullToRefreshProps extends PropsWithChildren {
  onRefresh: () => void | Promise<unknown>;
  distanceToRefresh?: number;
}

export const WithPullToRefresh: FC<WithPullToRefreshProps> = (props) => {
  const { onRefresh, distanceToRefresh, children } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerContentRef = useRef<HTMLDivElement | null>(null);

  const { currentOffset, handleTouchStart, handleTouchMove, handleTouchEnd } = usePullToRefresh({
    innerContentRef,
    distanceToRefresh,
    onRefresh,
  });

  return (
    <div
      className={s.wrapper}
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ transform: `translateY(${currentOffset}px)` }}>
      <div className={s.loaderWrapper}>
        {!!currentOffset && <UniversalLoader className={s.loader} />}
      </div>
      {cloneElement(children as ReactElement, { ref: innerContentRef })}
    </div>
  );
};

export default WithPullToRefresh;
