import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import { AdditionalEducationInfoType } from 'types/topManagement.types';

import Collapse from '../../../collapse';
import { ADDITIONAL_EDUCATION_FORM_ENUM } from '../../additionalEducationInfoForm.utils';

interface AdditionalEducationCardProps {
  id: string | number;
  onClick: () => void;
  educationInitialData: Partial<AdditionalEducationInfoType>;
}

const AdditionalEducationCard: FC<AdditionalEducationCardProps> = (props) => {
  const { id, educationInitialData, onClick } = props;
  const key = `${ADDITIONAL_EDUCATION_FORM_ENUM.EDUCATION_NAME}_${id}`;

  const form = Form.useFormInstance();
  const educationTitleName = Form.useWatch(key, form);

  const { courseName, documentInfo, educationName } = educationInitialData || {};
  const [displayedName, setDisplayedName] = useState(
    educationName || t('top_management_add_education_name'),
  );

  useEffect(() => {
    const isTouched = form.isFieldTouched(key);

    if (isTouched) {
      setDisplayedName(educationTitleName);
    }
  }, [educationTitleName, form, key]);

  return (
    <Card>
      <Collapse
        noPadding
        defaultActiveKey={id}
        items={[
          {
            key: id,
            label: <Typography.Title level={5}>{displayedName}</Typography.Title>,
            children: (
              <Flex gap="24" vertical>
                <Form.Item
                  name={`${ADDITIONAL_EDUCATION_FORM_ENUM.IS_ENTERED_BY_USER}_${id}`}
                  initialValue={true}
                  hidden
                />
                <Form.Item
                  name={`${ADDITIONAL_EDUCATION_FORM_ENUM.EDUCATION_NAME}_${id}`}
                  rules={[formRules.required]}
                  initialValue={educationName}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${ADDITIONAL_EDUCATION_FORM_ENUM.COURSE_NAME}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_add_additional_education_course_name')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={courseName}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${ADDITIONAL_EDUCATION_FORM_ENUM.INFO}_${id}`}
                  label={<Typography.Title level={5}>{t('common_document')}</Typography.Title>}
                  rules={[formRules.required]}
                  initialValue={documentInfo}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Button type="secondary" fullWidth size="large" danger onClick={onClick}>
                  {t('common_delete')}
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default AdditionalEducationCard;
