import React, { FC, PropsWithChildren } from 'react';

import { Modal, ModalProps } from 'gazprom-ui-lib';

import s from './PageModal.module.scss';

interface PageModalProps extends PropsWithChildren, ModalProps {}

const PageModal: FC<PageModalProps> = (props) => {
  const { children, open, ...otherProps } = props;

  return (
    <Modal open={open} wrapClassName={s.wrapClassName} transitionName="" {...otherProps}>
      {children}
    </Modal>
  );
};

export default PageModal;
