import { FC } from 'react';

import { Alert } from 'antd';
import DOMPurify from 'isomorphic-dompurify';

import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsHtml.module.scss';

export interface SurveysQuestionsHtmlProps extends QuestionProps {
  html_code: string;
}

const SurveysQuestionsHtml: FC<SurveysQuestionsHtmlProps> = (props) => {
  const { html_code } = props;

  return (
    <Alert
      message={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html_code) }} />}
      type="info"
      className={s.allert}
    />
  );
};

export default SurveysQuestionsHtml;
