import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GenerateRenewFilesPropsType,
  GenerateRenewFilesResponseType,
  GetRenewEmployeeFormPropsType,
  GetRenewEmployeeFormResponseType,
  GetRenewFamilyTypeResponseType,
  UpdateRenewEmployeeFormPropsType,
} from './topManagementApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { FamilyRelationDegreeType } from 'types/topManagement.types';

import { createRenewMeUrl, createRenewUrl, mapFamilyOptions } from './utils';

export const topManagementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getRenewEmployeeForm: builder.query<
      GetRenewEmployeeFormResponseType,
      GetRenewEmployeeFormPropsType
    >({
      query: (params) => ({
        url: createRenewMeUrl(prepareQueryParams(params)),
      }),
    }),
    updateRenewEmployeeForm: builder.mutation<
      GetRenewEmployeeFormResponseType,
      UpdateRenewEmployeeFormPropsType
    >({
      query: ({ id, myEmployeeId, body }) => ({
        url: createRenewMeUrl(`/${id}${prepareQueryParams({ myEmployeeId })}`),
        method: 'POST',
        body: {
          myEmployeeId,
          ...body,
        },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_EMPLOYEE_PERSONAL_DATA],
      async onQueryStarted({ myEmployeeId }, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            topManagementApiService.util.updateQueryData(
              'getRenewEmployeeForm',
              { myEmployeeId },
              (draft) => {
                Object.assign(draft, data);
              },
            ),
          );
        } catch (error) {
          return;
        }
      },
    }),
    getRenewFamilyType: builder.query<GetRenewFamilyTypeResponseType, void>({
      query: () => ({
        url: createRenewUrl(),
      }),
      transformResponse: (response: FamilyRelationDegreeType[]) => response.map(mapFamilyOptions),
    }),
    generateRenewFiles: builder.mutation<
      GenerateRenewFilesResponseType,
      GenerateRenewFilesPropsType
    >({
      query: ({ id, myEmployeeId }) => ({
        url: createRenewMeUrl(`/${id}/files${prepareQueryParams({ myEmployeeId })}`),
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
  useGetRenewFamilyTypeQuery,
  useGenerateRenewFilesMutation,
} = topManagementApiService;
