import React, { FC, useMemo, useState } from 'react';

import Card from 'containers/card';

import { PersonalDataType } from 'types/employee.types';

import s from './ProfilePersonalDataDetails.module.scss';
import ProfilePersonalDataCategories from './profile-personal-data-categories';
import ProfilePersonalDataDetailsEducation from './profile-personal-data-details-education';
import ProfilePersonalDataDetailsFamily from './profile-personal-data-details-family';
import ProfilePersonalDataDetailsGeneral from './profile-personal-data-details-general';
import ProfilePersonalDataDetailsMilitary from './profile-personal-data-details-military';
import {
  PROFILE_PERSONAL_CATEGORIES,
  getPersonalDataCategories,
} from './profilePersonalDataDetails.utils';

type ProfilePersonalDataDetailsProps = {
  personalData: PersonalDataType;
};

const ProfilePersonalDataDetails: FC<ProfilePersonalDataDetailsProps> = (props) => {
  const { personalData } = props;

  const categories = useMemo(() => getPersonalDataCategories(personalData), [personalData]);
  const [selectedCategory, setSelectedCategory] = useState<string>(categories[0]?.value);

  return (
    <Card withPadding={false}>
      <ProfilePersonalDataCategories
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className={s.detailsWrapper}>
        {selectedCategory === PROFILE_PERSONAL_CATEGORIES.GENERAL && (
          <ProfilePersonalDataDetailsGeneral personalData={personalData} />
        )}
        {selectedCategory === PROFILE_PERSONAL_CATEGORIES.FAMILY && (
          <ProfilePersonalDataDetailsFamily familyData={personalData.family} />
        )}
        {selectedCategory === PROFILE_PERSONAL_CATEGORIES.EDUCATION && (
          <ProfilePersonalDataDetailsEducation educationData={personalData.education} />
        )}
        {selectedCategory === PROFILE_PERSONAL_CATEGORIES.MILITARY && (
          <ProfilePersonalDataDetailsMilitary militaryData={personalData.militaryDuty} />
        )}
      </div>
    </Card>
  );
};

export default ProfilePersonalDataDetails;
