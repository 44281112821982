import { Datum } from '@ant-design/charts';

import { MY_SALARY_COLORS } from '../mySalary.utils';

export const colorFunction = (baseColor: string, reserveColor?: string) => (datum: Datum) => {
  if (datum.name === 'data') {
    return baseColor;
  }

  if (!MY_SALARY_COLORS[datum.name as keyof typeof MY_SALARY_COLORS]) {
    return reserveColor as string;
  }

  return MY_SALARY_COLORS[datum.name as keyof typeof MY_SALARY_COLORS]?.colorHex;
};
