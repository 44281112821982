import { FC, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Card, Flex } from 'gazprom-ui-lib';

import FamilyCard from '../family-card';

let sectionId = 0;

const CustomSection: FC = () => {
  const [customSection, setCustomSection] = useState<number[]>([]);

  const renderCustomSections = (id: number) => {
    return <FamilyCard index={id} key={id} onClick={removeCustomSection(id)} isCustom />;
  };

  const addCustomSection = () => setCustomSection((prev) => [...prev, sectionId++]);

  const removeCustomSection = (id: number) => () => {
    setCustomSection((prev) => prev.filter((elId) => elId !== id));
  };

  return (
    <>
      <Flex gap="16" vertical>
        {customSection.map(renderCustomSections)}
      </Flex>
      <Card>
        <Button
          type="secondary"
          fullWidth
          size="large"
          htmlType="button"
          leftIcon="plus"
          onClick={addCustomSection}>
          {t('top_management_family_add')}
        </Button>
      </Card>
    </>
  );
};

export default CustomSection;
