import React, { FC } from 'react';

import Stories from 'react-insta-stories';
import { ReactInstaStoriesProps } from 'react-insta-stories/dist/interfaces';

export interface SwiperProps extends ReactInstaStoriesProps {}

const Swiper: FC<SwiperProps> = (props) => {
  const { loop = false, defaultInterval = 5000, ...otherProps } = props;

  return <Stories loop={loop} defaultInterval={defaultInterval} {...otherProps} />;
};

export default Swiper;
