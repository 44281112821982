import dayjs from 'dayjs';

export enum MONTH_ENUM {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export const MIN_AVAILABLE_YEAR = 2018;
export const START_YEAR = 2018;

export const CURRENT_YEAR = dayjs().get('year');
export const CURRENT_MONTH = dayjs().get('month');
export const CURRENT_DATE = dayjs().get('date');
