import React, { FC, memo, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import NewsTagsActions from 'containers/news-tags-actions';

import { NewsCategoryType } from 'types/news.types';

import s from './OpeningFilterBar.module.scss';

interface HomeNewsFilterBarProps {
  handleShowAll: () => void;
  handleAddRemoveNewsActiveTags: (id: string) => void;
  activeTags: string[];
  tagsToRender?: NewsCategoryType[];
  handleTagsReset: () => void;
}

const OpeningFilterBar: FC<HomeNewsFilterBarProps> = (props) => {
  const {
    handleAddRemoveNewsActiveTags,
    activeTags,
    tagsToRender,
    handleShowAll,
    handleTagsReset,
  } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleOpenCloseTags = () => setIsOpened((prevState) => !prevState);

  const renderTag = (newsCategory: NewsCategoryType) => {
    const { id, name } = newsCategory;
    const isActiveTag = activeTags?.includes(id);

    return (
      <button
        key={`news__filters__category__${id}`}
        className={cn({ [s.activeTag]: isActiveTag })}
        onClick={() => handleAddRemoveNewsActiveTags(id)}>
        <Typography.Text size="12" className={s.tagOverflow}>
          {name}
        </Typography.Text>
      </button>
    );
  };

  return (
    <div className={s.wrapper}>
      <div className={s.tagsWithIconWrapper}>
        <div className={cn(s.tagsWrapper, { [s.isOpen]: isOpened })}>
          {tagsToRender?.map(renderTag)}
        </div>
        <button onClick={handleOpenCloseTags}>
          <Icon name={isOpened ? 'up' : 'down'} />
        </button>
      </div>
      {isOpened && (
        <Button onClick={handleShowAll} type="secondary" rightIcon="arrowRight">
          {t('common_show_all')}
        </Button>
      )}
      {!!activeTags?.length && (
        <NewsTagsActions tagsLength={activeTags?.length} handleReset={handleTagsReset} />
      )}
    </div>
  );
};

export default memo(OpeningFilterBar);
