import { SunburstConfig } from '@ant-design/charts';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';

import { SALARY_FILED_NAMES_ENUM } from 'types/mySalary.types';

export const SEGMENT_OPTIONS = [
  {
    label: <Typography.Title level={5}>{t('my_salary_month')}</Typography.Title>,
    value: 'month',
  },
  {
    label: <Typography.Title level={5}>{t('my_salary_year')}</Typography.Title>,
    value: 'year',
  },
];

export const headerChildren = (
  <>
    <Header.GoBack to={PROFILE_ROUTE} />
    <Header.Label label={t('my_salary_header_title')} />
  </>
);

export const monthMap = {
  1: { long: 'Январь', short: 'Янв.', key: 1 },
  2: { long: 'Февраль', short: 'Фев.', key: 2 },
  3: { long: 'Март', short: 'Мар.', key: 3 },
  4: { long: 'Апрель', short: 'Апр.', key: 4 },
  5: { long: 'Май', short: 'Май.', key: 5 },
  6: { long: 'Июнь', short: 'Июн.', key: 6 },
  7: { long: 'Июль', short: 'Июл.', key: 7 },
  8: { long: 'Август', short: 'Авг.', key: 8 },
  9: { long: 'Сентябрь', short: 'Сен.', key: 9 },
  10: { long: 'Октябрь', short: 'Окт.', key: 10 },
  11: { long: 'Ноябрь', short: 'Ноя.', key: 11 },
  12: { long: 'Декабрь', short: 'Дек.', key: 12 },
};

export const MY_SALARY_COLORS = {
  [SALARY_FILED_NAMES_ENUM.DEFAULT]: { codeName: '', colorHex: '#ffffff' },
  [SALARY_FILED_NAMES_ENUM.ACCRUALS]: { codeName: 'accruals', colorHex: '#17c1b9' },
  [SALARY_FILED_NAMES_ENUM.DEDUCTIONS]: { codeName: 'deductions', colorHex: '#F87950' },
  [SALARY_FILED_NAMES_ENUM.PAYOUTS]: { codeName: 'payouts', colorHex: '#21587a' },
  [SALARY_FILED_NAMES_ENUM.TARIFF]: { codeName: 'tariff', colorHex: '#27AAE1' },
  [SALARY_FILED_NAMES_ENUM.AWARDS]: { codeName: 'awards', colorHex: '#EEA385' },
  [SALARY_FILED_NAMES_ENUM.ADDITIONAL_PAYMENTS]: {
    codeName: 'additionalPayments',
    colorHex: '#8F330E',
  },
  [SALARY_FILED_NAMES_ENUM.OTHER_ACCRUALS]: { codeName: 'other', colorHex: '#61CCF2' },
  [SALARY_FILED_NAMES_ENUM.UNWORKED_TIME]: { codeName: 'timeNotWorked', colorHex: '#DDB12C' },
  [SALARY_FILED_NAMES_ENUM.SOCIAL_PAYMENTS]: { codeName: 'socialBenefits', colorHex: '#8F6F0E' },
  [SALARY_FILED_NAMES_ENUM.NDFL]: { codeName: 'ndfl', colorHex: '#A65F42' },
  [SALARY_FILED_NAMES_ENUM.OTHER_DEDUCTIONS]: { codeName: 'rest', colorHex: '#A68D42' },
  [SALARY_FILED_NAMES_ENUM.RESERVE]: { codeName: 'reserve', colorHex: '#bfbfbf' },
};

export const createSunBurstConfig = (
  data: SunburstConfig['data'],
  colorFn: SunburstConfig['color'],
  innerRadius: SunburstConfig['innerRadius'] = 0.7,
) => {
  const config: SunburstConfig = {
    data: data,
    innerRadius: innerRadius,
    radius: 1,
    colorField: 'name',
    interactions: [],
    color: colorFn,
    tooltip: false,
    sunburstStyle: {
      stroke: 'white',
      lineWidth: 1.5,
      fillOpacity: 1,
    },
    padding: [0, 0, -25, 0],
    height: 180,
  };

  return config;
};

export const convertToRub = (value: number) =>
  value.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }).replace(',', '.');
