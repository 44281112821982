import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  getDocumentsStatementRequestRoute,
  getTopManagementByStageRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetKedoStatementsQuery } from 'services/kedo/kedoApiService';
import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { Card, Flex, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { TASK_CODE_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';
import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import ApplyStatement from '../apply-statement';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import usePersonalData from '../verification-form/usePersonalData.hook';
import WithBottomButton from '../with-bottom-button';
import s from './MilitaryInfoForm.module.scss';

const MilitaryInfoForm = () => {
  const navigate = useNavigate();

  const { personalData } = usePersonalData();
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData } = useGetRenewEmployeeFormQuery({
    myEmployeeId: selectedEmployee?.id!,
  });
  const [
    requestStatements,
    { data: requestStatementsData, isLoading: requestStatementsDataIsLoading },
  ] = useLazyGetKedoStatementsQuery();
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const statementId = requestStatementsData?.[0]?.id;
  const statementUrl = statementId && getDocumentsStatementRequestRoute(statementId);

  const requestStatement = () =>
    requestStatements({
      type: TASK_TYPE_ENUM.BPMN,
      code: TASK_CODE_ENUM.ZAYAVLENIE_NA_IZMENENIE_PERSONALNYKH_DANNYKH,
    });

  const navigateToNextStep = () =>
    navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.EDUCATION));

  const handleNextStep = () => {
    updateFormData({
      id: renewEmployeeData?.id!,
      myEmployeeId: selectedEmployee?.id!,
      body: {
        formStep: TOP_MANAGEMENT_STAGE_ENUM.EDUCATION,
      },
    }).then(
      handleRequest({
        onSuccess: navigateToNextStep,
      }),
    );
  };

  const {
    vus,
    typeOfDocument,
    numberDocument,
    militaryRank,
    issueDate,
    issuingAuthority,
    militaryRegistration,
  } = personalData?.militaryDuty || {};

  return (
    <WithLoader isLoading={!personalData}>
      <WithBottomButton
        button={
          <NextStepButton
            text={t('common_all_ok_continue')}
            onClick={handleNextStep}
            isLoading={updateFormDataIsLoading}
          />
        }>
        <Card>
          <Flex gap="16" vertical>
            <Title
              title={t('top_management_military_info_title')}
              subtitle={t('top_management_general_data_subtitle')}
            />
            <div>
              <DataPresenter title={t('top_management_attitude_to_military')} text={vus} />
              <div className={s.dataContainer}>
                <DataPresenter title={t('my_personal_data_document_type')} text={typeOfDocument} />
                <DataPresenter
                  title={t('my_personal_data_document_number')}
                  text={numberDocument}
                />
              </div>
              <DataPresenter title={t('my_personal_data_military_rank')} text={militaryRank} />
              <DataPresenter
                title={t('common_when_issued')}
                text={dayjs(issueDate).format('DD.MM.YYYY')}
              />
              <DataPresenter title={t('common_issued_by')} text={issuingAuthority} />
              <DataPresenter title={t('common_on_registered')} text={militaryRegistration} />
            </div>
          </Flex>
        </Card>
        <ApplyStatement
          to={statementUrl ?? ''}
          onModalOpen={requestStatement}
          loading={requestStatementsDataIsLoading}
        />
      </WithBottomButton>
    </WithLoader>
  );
};

export default MilitaryInfoForm;
