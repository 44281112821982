import React, { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

export const SEGMENT_NEWS_TYPE = 'news';
export const SEGMENT_NEWS_EVENT_TYPE = 'meet';

export const SEGMENT_OPTIONS: { value: string; label: ReactNode }[] = [
  {
    label: <Typography.Title level={5}>{t('common_news')}</Typography.Title>,
    value: SEGMENT_NEWS_TYPE,
  },
  {
    label: <Typography.Title level={5}>{t('common_events')}</Typography.Title>,
    value: SEGMENT_NEWS_EVENT_TYPE,
  },
];
