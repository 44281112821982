import { t } from 'tools/i18n';

import { SuggestionAttributeType } from '../../more-suggestion-create/moreSuggestionCreate.utils';

export const DECISION_RESULT_ATTRIBUTES: SuggestionAttributeType[] = [
  {
    code: 'files',
    id: 'files',
    isMandatory: true,
    name: t('suggestion_decision_result_file'),
    type: 'MULTIFILE',
  },
  {
    code: 'comment',
    id: 'comment',
    isMandatory: true,
    name: t('common_comment'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
];

export type CreateDecisionResultFormResponseType = {
  files: { fileName: string; key: string }[];
  comment: string;
};

export type ModalType = '' | 'sent';

export const prepareFilesRequestBody = (files: { fileName: string; key: string }[]) => {
  if (!files) {
    return [];
  }

  return files.map((file) => ({
    fileKey: file.key,
    fileName: file.fileName,
    signs: [],
    description: '',
  }));
};
