import { SignType } from './sign.types';

export enum TOP_MANAGEMENT_STAGE_ENUM {
  COMMON = 'common',
  INTERNATIONAL = 'international',
  DRIVER_LICENSE = `driver-license`,
  CONTACTS = 'contacts',
  MILITARY = 'military',
  EDUCATION = 'education',
  ADDITIONAL_EDUCATION = 'additional-education',
  WORK_EXPERIENCE = 'work-experience',
  OTHER = 'other',
  LANGUAGES = 'languages',
  SOFTWARE = 'software',
  FAMILY = 'family',
}

export type PageParamType = {
  goBack: string;
  label: string;
  component: JSX.Element;
};

export type PageParamsType = Record<TOP_MANAGEMENT_STAGE_ENUM, PageParamType>;

export type DriverLicenseType = {
  series: string;
  number: string;
  issueDate: string;
  issuingAuthority: string;
  experience: string;
  category: string;
};

export type InternationalPassportType = {
  surname: string;
  name: string;
  number: string;
  issueDate: string;
  issuingAuthority: string;
  tillDate: string;
};

export type AdditionalEducationInfoType = {
  educationName: string;
  courseName: string;
  documentInfo: string;
  isEnteredByUser: boolean;
};

export type LanguagesType = {
  language: string;
  level: string;
  isEnteredByUser: boolean;
};

export type SoftwareSkillsType = {
  softwareName: string;
  level: string;
  isEnteredByUser: boolean;
};

export type ExperienceType = {
  name: string;
  address: string;
  phone: string;
  activity: string;
  dateStart: string;
  dateFinish: string;
  position: string;
  functions: string;
  reasonForDismissal: string;
  isEnteredByUser: boolean;
};
export type FamilyType = {
  familyType: string;
  name: string;
  surname: string;
  patronymic: string;
  birthDate: string;
  birthPlace: string;
  placeOfWork: string;
  jobInGsp: boolean;
  jobInGspDetails: string;
  registrationAddress: string;
  residentialAddress: string;
  phone: string;
  isEnteredByUser: boolean;
};

export type FsppObligationsDataType = {
  fsppObligationsNum: string;
  fsppObligationsDate: string;
};

export type OtherInfoType = {
  financialObligations: boolean;
  financialObligationsType: string;
  fsppObligations: boolean;
  fsppObligationsData: FsppObligationsDataType[];
  additionalFinance: boolean;
  additionalFinanceType: [string];
  othersLegalEntity: boolean;
  othersLegalEntityData: [string];
};

export type RenewFilesType = {
  fileKey: string;
  fileName: string;
  signs: SignType[];
  description: string;
  num: number;
  attributeId: string;
};

export type EducationType = {
  educationId: string;
  educationName: string;
  educationType: string;
  educationDate: string;
  qualification: string;
  documentIssueDate: string;
  documentSeries: string;
  documentNumber: string;
  isEnteredByUser: boolean;
};

export type RenewEmployeeInfoType = {
  driverLicense: DriverLicenseType;
  internationalPassport: InternationalPassportType;
  additionalEducationInfo: AdditionalEducationInfoType[];
  languages: LanguagesType[];
  softwareSkills: SoftwareSkillsType[];
  experience: ExperienceType[];
  family: FamilyType[];
  otherInfo: OtherInfoType;
  status: string;
  files: RenewFilesType[];
  criminal: boolean;
  education: EducationType[];
  criminalDetail: string;
  formStep: string;
  noInternationalPassport: boolean;
  noDriversLicense: boolean;
};

export type RenewEmployeeType = {
  id: string;
  employeeId: string;
  personId: string;
  createdAt: string;
  updatedAt: string;
} & RenewEmployeeInfoType;

export type FamilyRelationDegreeType = {
  name: string;
  literal: string;
};
