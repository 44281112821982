import { PropsWithChildren } from 'react';

import { useParams } from 'react-router-dom';

import { useGetAssignedToMeStatementByIdQuery } from 'services/kedo/kedoApiService';

import Card from 'containers/card';
import WithLoader from 'containers/wrappers/with-loader';

interface Props extends PropsWithChildren {}

const DetailsWrapper = (props: Props) => {
  const { children } = props;

  const params = useParams<{ taskId: string }>();

  const { isLoading, currentData } = useGetAssignedToMeStatementByIdQuery(
    {
      taskId: params?.taskId!,
    },
    { skip: !params?.taskId },
  );

  return (
    <Card withPadding={false}>
      <WithLoader isLoading={isLoading && !currentData}>{children}</WithLoader>
    </Card>
  );
};

export default DetailsWrapper;
