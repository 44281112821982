import React from 'react';

import { t } from 'tools/i18n';

import { Radio } from 'gazprom-ui-lib';

export const renderVacation = (vacation: string) => {
  const labelAndValue = t(`common_enum_vacation_${vacation.toLowerCase()}`);

  return (
    <Radio.Button key={labelAndValue} value={labelAndValue}>
      {labelAndValue}
    </Radio.Button>
  );
};

export enum VACATION_TYPE_ENUM {
  MAIN = 'MAIN',
  NORTH = 'NORTH',
  NRD = 'NRD',
  HARMFUL_CONDITIONS = 'HARMFUL_CONDITIONS',
}
