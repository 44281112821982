import React, { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { GetKedoCategoriesResponseType } from 'services/kedo/kedoApiService.types';

import { Flex, Typography } from 'gazprom-ui-lib';

import Filter from 'containers/filter';

import { ALL_CATEGORIES } from 'pages/documents/documents.utils';

import { KedoStatusType } from 'types/documents.types';
import { FilterItemType } from 'types/filter.types';

import s from './DocumentsStatusesAndCategories.module.scss';

interface DocumentsStatusesAndCategoriesProps {
  activeCategory?: string;
  setActiveCategory?: Dispatch<SetStateAction<string>>;
  activeStatus?: string;
  setActiveStatus?: Dispatch<SetStateAction<string>>;
  count: number;
  categories?: GetKedoCategoriesResponseType;
  statuses?: KedoStatusType[];
  showCategories?: boolean;
  showStatuses?: boolean;
  countTranslateKey?: string;
}

const DocumentsStatusesAndCategories: FC<DocumentsStatusesAndCategoriesProps> = (props) => {
  const {
    categories,
    statuses,
    count,
    activeCategory,
    setActiveCategory,
    activeStatus,
    setActiveStatus,
    showCategories = true,
    showStatuses = true,
    countTranslateKey = 'documents_found_count',
  } = props;

  const statusFilterOptions: FilterItemType[] =
    statuses?.map((status) => ({ name: status.name, id: status.code })) ?? [];

  const categoryFilterOptions: FilterItemType[] =
    [ALL_CATEGORIES, ...(categories ?? [])].map((category) => ({
      name: category.name,
      id: category.id,
    })) ?? [];

  return (
    <Flex gap="16" vertical className={s.wrapper}>
      <Flex gap="8" wrap="wrap">
        {showStatuses && (
          <Filter
            id="status-filter"
            label={t('common_status')}
            options={statusFilterOptions}
            appliedFilter={activeStatus}
            setAppliedFilter={setActiveStatus}
          />
        )}
        {showCategories && (
          <Filter
            id="category-filter"
            label={t('common_category')}
            options={categoryFilterOptions}
            appliedFilter={activeCategory}
            setAppliedFilter={setActiveCategory}
          />
        )}
      </Flex>
      {!!count && (
        <Typography.Text weight="700" size="14" type="primary" className={s.counter}>
          {t(countTranslateKey, { count })}
        </Typography.Text>
      )}
    </Flex>
  );
};

export default DocumentsStatusesAndCategories;
