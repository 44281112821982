import React, { MouseEvent, useState } from 'react';

import { PROFILE_COMPANY_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import EmploymentTag from 'components/employment-tag';
import {
  getEmploymentRateTitle,
  getEmploymentTypeTitle,
} from 'components/employment-tag/employmentTag.utils';
import Card from 'containers/card';
import EmploymentStructureModal from 'containers/employment-structure-modal';

import companyPlug from 'assets/icons/companyPlug.png';

import s from './ProfileWithCompany.module.scss';

const ProfileWithCompany = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const [employmentStructureOpen, setEmploymentStructureOpen] = useState<boolean>(false);

  const handleOpenCloseEmploymentStructure = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setEmploymentStructureOpen(!employmentStructureOpen);
  };

  const { person } = selectedEmployee || {};
  const { firstName, lastName, patronymic } = person || {};
  return (
    <Card.Navigate to={PROFILE_COMPANY_ROUTE} className={s.wrapper}>
      <Flex vertical>
        <Typography.Text type="secondary">{t('common_profile')}</Typography.Text>
        <Typography.Title level={4}>{`${firstName} ${lastName} ${patronymic}`}</Typography.Title>
      </Flex>
      <Flex align="center" justify="space-between" fullWidth className={s.company}>
        <Flex align="center">
          <img src={companyPlug} alt="company logo" />
          <div>
            <Typography.Text type="secondary">{t('common_organization')}</Typography.Text>
            <Typography.Title level={4}>{selectedEmployee?.employer.name}</Typography.Title>
          </div>
        </Flex>
        <Icon name="forward" />
      </Flex>
      <Flex gap="4" vertical>
        <Flex gap="8" wrap="wrap" align="center">
          <Typography.Text type="secondary">{t('common_staff_name')}</Typography.Text>
          <Flex gap="4" align="center">
            <EmploymentTag type="outline">
              {getEmploymentTypeTitle(selectedEmployee?.employmentType)}
            </EmploymentTag>
            {!!selectedEmployee?.partTime && (
              <EmploymentTag type="primary" icon="work">
                {getEmploymentRateTitle(selectedEmployee?.partTime)}
              </EmploymentTag>
            )}
          </Flex>
        </Flex>
        <Typography.Title level={5}>{selectedEmployee?.position?.name}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Flex gap="8" align="center">
          <Typography.Text type="secondary">{t('common_department')}</Typography.Text>
          <Button
            size="small"
            className={s.employmentStructureIcon}
            type="link"
            onlyIcon
            leftIcon="info"
            onClick={handleOpenCloseEmploymentStructure}
          />
        </Flex>
        <Typography.Title level={5}>{selectedEmployee?.division?.name}</Typography.Title>
      </Flex>
      <EmploymentStructureModal
        employeeId={selectedEmployee?.id}
        open={employmentStructureOpen}
        handleClose={handleOpenCloseEmploymentStructure}
        employeePosition={selectedEmployee?.position?.name}
      />
    </Card.Navigate>
  );
};

export default ProfileWithCompany;
