import { FC } from 'react';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './NoData.module.scss';

interface NoDataProps {
  header: string;
  description: string;
  icon?: TIconsLiteral;
}

const NoData: FC<NoDataProps> = (props) => {
  const { icon = 'notFound', header, description } = props;

  return (
    <div className={s.wrapper}>
      <div className={s.iconWrapper}>
        <Icon name={icon} />
      </div>
      <div className={s.textWrapper}>
        <Typography.Title level={4} strong>
          {header}
        </Typography.Title>
        <Typography.Title level={5} type="secondary" strong={false}>
          {description}
        </Typography.Title>
      </div>
    </div>
  );
};

export default NoData;
