import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetFaqCategoryByIdPropsType,
  GetFaqCategoryByIdResponseType,
  GetFaqCategoryPropsType,
  GetFaqCategoryResponseType,
} from './faqCategoryApiService.types';

import { createFaqCategoryUrl } from './utils';

const faqCategoryApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFaqCategory: builder.query<GetFaqCategoryResponseType, GetFaqCategoryPropsType>({
      query: (params) => ({
        url: createFaqCategoryUrl(prepareQueryParams(params)),
      }),
    }),
    getFaqCategoryById: builder.query<GetFaqCategoryByIdResponseType, GetFaqCategoryByIdPropsType>({
      query: (id) => ({
        url: createFaqCategoryUrl(`/${id}`),
      }),
    }),
  }),
});

export const { useGetFaqCategoryQuery, useGetFaqCategoryByIdQuery } = faqCategoryApiService;
