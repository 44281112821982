import { FC } from 'react';

import cn from 'classnames';

import { Flex, Typography } from 'gazprom-ui-lib';

import s from './DmsCardItem.module.scss';

export type DmsCardItemProps = {
  title: string;
  value: string;
  link?: boolean;
};

export const DmsCardItem: FC<DmsCardItemProps> = (props) => {
  const { title, value, link } = props;

  return (
    <Flex vertical>
      <Typography.Text type="secondary">{title}</Typography.Text>
      <Typography.Text size="16" weight="500" className={cn(link && s.link)}>
        {value}
      </Typography.Text>
    </Flex>
  );
};

export default DmsCardItem;
