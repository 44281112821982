import { FC, SetStateAction } from 'react';

import { Icon, Typography } from 'gazprom-ui-lib';

import { DateTypeYear } from 'types/mySalary.types';

import s from './MySalaryNavigatorYear.module.scss';

interface MySalaryNavigatorYearProps {
  date: DateTypeYear;
  setDate: (value: SetStateAction<DateTypeYear | null>) => void;
}

const MySalaryNavigatorYear: FC<MySalaryNavigatorYearProps> = (props) => {
  const { date, setDate } = props;
  const { year, min, max } = date;
  const decreaseVisible = min.year < year;
  const increaseVisible = max.year > year;

  const decrease = () => {
    const newYear = date.year - 1;

    setDate({ year: newYear, min, max });
  };

  const increase = () => {
    const newYear = date.year + 1;

    setDate({ year: newYear, min, max });
  };

  return (
    <div className={s.container}>
      <button onClick={decrease} className={decreaseVisible ? s.visible : s.hidden}>
        <Icon name="back" size={15} />
      </button>
      <Typography.Title level={5}>{date.year}</Typography.Title>
      <button onClick={increase} className={increaseVisible ? s.visible : s.hidden}>
        <Icon name="forward" size={15} />
      </button>
    </div>
  );
};

export default MySalaryNavigatorYear;
