import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface NotificationsSingleReplyModalProps {
  isVisible: boolean;
  onClickHandler: () => void;
}

const NotificationsSingleReplyModal: FC<NotificationsSingleReplyModalProps> = (props) => {
  const { isVisible, onClickHandler } = props;

  return (
    <ModalDefault open={isVisible}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('common_thank_you')}</ModalDefault.Title>
      <ModalDefault.Description>{t('notifications_reply_sent')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" size="large" fullWidth onClick={onClickHandler}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default NotificationsSingleReplyModal;
