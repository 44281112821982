import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetNotificationByIdResponseType,
  GetNotificationsPropsType,
  GetNotificationsResponseType,
  ReplyFeedbackPropsType,
  RequestCallPropsType,
} from './notificationApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';

import { notificationUrl } from './utils';

const notificationApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<GetNotificationsResponseType, GetNotificationsPropsType>({
      query: (params) => ({
        url: notificationUrl(prepareQueryParams(params)),
      }),
    }),
    getNotificationById: builder.query<GetNotificationByIdResponseType, string>({
      query: (id) => ({
        url: notificationUrl(`/${id}`),
      }),
      providesTags: [SERVICE_TAGS.GET_NOTIFICATION_BY_ID],
    }),
    requestCall: builder.mutation<void, RequestCallPropsType>({
      query: ({ id, contacts }) => ({
        url: notificationUrl(`/${id}/callback`),
        method: 'POST',
        body: {
          contacts,
        },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_NOTIFICATION_BY_ID],
    }),
    replyFeedback: builder.mutation<void, ReplyFeedbackPropsType>({
      query: ({ id, feedback }) => ({
        url: notificationUrl(`/${id}/feedback`),
        method: 'POST',
        body: {
          feedback,
        },
      }),
      invalidatesTags: [SERVICE_TAGS.GET_NOTIFICATION_BY_ID],
    }),
  }),
});

export const {
  useReplyFeedbackMutation,
  useRequestCallMutation,
  useLazyGetNotificationsQuery,
  useGetNotificationByIdQuery,
} = notificationApiService;
