import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_SOCIAL_POLICY_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Icon } from 'gazprom-ui-lib';

import CodeVerification from 'containers/code-verification';
import Header from 'containers/header';

import useHandleSubmit from './useHandleSubmit.hook';

const DocumentsSignCode = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(DOCUMENTS_SOCIAL_POLICY_ROUTE);
  };

  const { handleSubmit, isLoading } = useHandleSubmit();

  return (
    <CodeVerification
      successConfig={{
        title: t('documents_sign_completed_tile'),
        description: t('documents_sign_completed_description'),
        onConfirm: handleSubmit,
        closeCodeVerification: handleClose,
        isConfirmLoading: isLoading,
      }}>
      <div onClick={() => navigate(-1)}>
        <Icon name="arrowLeft" />
      </div>
      <Header.Label label={t('documents_sign_title')} />
    </CodeVerification>
  );
};

export default DocumentsSignCode;
