import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface DocumentsCreateNoCertificateModalProps {
  isVisible: boolean;
  onClickHandler: () => void;
}

const DocumentsCreateNoCertificateModal: FC<DocumentsCreateNoCertificateModalProps> = (props) => {
  const { isVisible, onClickHandler } = props;

  return (
    <ModalDefault open={isVisible} onCancel={onClickHandler}>
      <ModalDefault.Icon name="signature" type="primary" />
      <ModalDefault.Title>{t('documents_certificate_not_found')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_statement_requested_no_certificate')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          type="primary"
          size="large"
          rightIcon="arrowRight"
          fullWidth
          onClick={onClickHandler}>
          {t('common_go_to_section')}
        </Button>
        <Button type="secondary" size="large" fullWidth onClick={onClickHandler}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DocumentsCreateNoCertificateModal;
