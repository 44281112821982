import React from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getCreatedDate } from 'utils/article.utils';

import { Flex, Icon, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import { Empty } from 'components/empty';

import { SuggestionDecisionType } from 'types/suggestion.types';

import { getSuggestionTagConfig } from '../list/list.utils';
import s from './DecisionsList.module.scss';

export interface Props {
  decisions?: SuggestionDecisionType[];
  getNavigateRoute: (id: string) => string;
}

const SuggestionDecisionsList = (props: Props) => {
  const { decisions, getNavigateRoute } = props;
  const navigate = useNavigate();

  const hasData = !!decisions?.length;

  const renderTask = (decision: SuggestionDecisionType) => {
    const { id, status, createdAt, title, employee } = decision ?? {};
    const { firstName, lastName, patronymic } = employee.person;

    const tagConfig = getSuggestionTagConfig(status);

    const handleClick = () => {
      navigate(getNavigateRoute(id));
    };

    return (
      <button onClick={handleClick} className={s.taskElement} key={`suggestion__list__${id}`}>
        <Flex vertical gap="8">
          <Flex vertical>
            <Typography.Text size="12" type="secondary">
              {getCreatedDate(createdAt)}
            </Typography.Text>
            <Typography.Text size="14" className={s.text} weight="500">
              {title}
            </Typography.Text>
          </Flex>
          <Bio
            firstName={firstName}
            lastName={lastName}
            patronymic={patronymic}
            staffName={employee.position.name}
          />
          <Tag
            className={cn(s.tag, s[tagConfig.type])}
            type={tagConfig.type}
            children={
              <>
                <Icon name={tagConfig.icon} />
                <span>{tagConfig.children}</span>
              </>
            }
          />
        </Flex>
        <Icon name="forward" />
      </button>
    );
  };

  return (
    <>
      {!hasData && (
        <Empty title="innovations_not_found" description="innovations_not_found_description" />
      )}
      {hasData && decisions?.map(renderTask)}
    </>
  );
};

export default SuggestionDecisionsList;
