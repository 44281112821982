import { useGetCurrentCertificateQuery } from 'services/me/meApiService';

import { CERTIFICATE_STATE_UNUM } from 'types/certificates.types';

const useCertificate = () => {
  const { data, isLoading, isFetching, isUninitialized } = useGetCurrentCertificateQuery();

  const { state } = data?.latestKcr || {};

  const isActive = state === CERTIFICATE_STATE_UNUM.ACTIVE;

  return {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    isActive,
  };
};

export default useCertificate;
