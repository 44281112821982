import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getSettlementPosterWithIdWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { guideSettlementPostersStateSelector } from 'selectors/guideSettlementPostersSlice.selector';
import { useLazyGetFullArticlesQuery } from 'services/article/articleApiService';
import {
  addNews,
  addPage,
  addRemoveLike,
  resetNews,
  resetPagination,
} from 'slices/guideSettlementPostersSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { Typography } from 'gazprom-ui-lib';

import Article from 'containers/article';
import Card from 'containers/card';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';

import { ARTICLE_TYPE_ENUM, ArticleFullType } from 'types/article.types';
import { SORT_ENUM } from 'types/sort.types';

import s from './GuideSettlementPosterList.module.scss';

interface GuideSettlementPosterListProps {
  telegramChannelId: string;
}

const GuideSettlementPosterList: FC<GuideSettlementPosterListProps> = (props) => {
  const { telegramChannelId } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const newsState = useAppSelector(guideSettlementPostersStateSelector);

  const [isFetchAvailable, setIsFetchAvailable] = useState<boolean>(false);

  const [
    getFullArticles,
    { data: fullArticlesData, isFetching: getFullArticlesIsFetching, isUninitialized },
  ] = useLazyGetFullArticlesQuery();

  useEffect(() => {
    getFullArticles({
      telegramChannelId,
      page: newsState.page,
      size: newsState.size,
      type: [ARTICLE_TYPE_ENUM.NEWS],
      sort: [
        { order: SORT_ENUM.DESC, property: 'ARTICLE.pinNum' },
        { order: SORT_ENUM.DESC, property: 'ARTICLE.publishFrom' },
      ],
    })
      .then((res) => {
        if (res?.data && 'items' in res.data) {
          dispatch(addNews(res.data?.items));
        }
      })
      .finally(() => {
        setIsFetchAvailable(true);
      });
  }, [telegramChannelId, dispatch, getFullArticles, newsState.page, newsState.size]);

  useEffect(() => {
    return () => {
      dispatch(resetNews());
      dispatch(resetPagination());
    };
  }, [dispatch]);

  const handleLikeClick = (code: string) => {
    dispatch(addRemoveLike(code));
  };
  const handleFetchMoreData = () => {
    setIsFetchAvailable(false);
    dispatch(addPage());
  };

  const renderArticle = (article: ArticleFullType) => (
    <button
      onClick={() => navigate(getSettlementPosterWithIdWithIdRoute(article.code))}
      className={s.article}
      key={`news__${article.id}`}>
      <Article
        article={article}
        handleLikeClick={handleLikeClick}
        showNoDataCover={false}
        showComments={false}
        showCategories={false}
        showPinned={false}
      />
    </button>
  );

  const isFetchMoreAvailable =
    !!newsState.newsToShow?.length &&
    newsState.page * newsState.size < (fullArticlesData?.totalCount ?? 0) &&
    isFetchAvailable;

  if (!newsState.newsToShow.length) {
    return <></>;
  }

  return (
    <Card withPadding={false}>
      <div className={s.title}>
        <Typography.Title level={4}>{t('guide_settlement_posters')}</Typography.Title>
      </div>
      <WithInfiniteScroll
        fetchMoreData={handleFetchMoreData}
        isFetching={getFullArticlesIsFetching || isUninitialized}
        isFetchMoreAvailable={isFetchMoreAvailable}>
        {newsState.newsToShow.map(renderArticle)}
      </WithInfiniteScroll>
    </Card>
  );
};

export default GuideSettlementPosterList;
