import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MORE_FEEDBACK_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useLazyGetFeedbackByIdQuery } from 'services/feedback/feedbackApiService';
import { getEmployeesSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { Button, Icon, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { EmployeeType } from 'types/employee.types';

import { formatDate, getTagConfig } from '../profileFeedback.utils';
import s from './FeedbackDetails.module.scss';

const FeedbackDetails = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const { data } = useAppSelector(getEmployeesSelector);

  const [getFeedbackById, { data: feedbackData, isLoading: feedbackIsLoading }] =
    useLazyGetFeedbackByIdQuery();

  const findFeedbackAuthor = (e: EmployeeType) => e.person.id === feedbackData?.authorPerson?.id;

  const isSent = !!data?.find(findFeedbackAuthor);
  const person = isSent ? feedbackData?.recipientPerson : feedbackData?.authorPerson;
  const position = isSent
    ? feedbackData?.recipientEmployee?.position
    : feedbackData?.authorEmployee?.position;

  const showStatusDescriptions = true;

  useEffect(() => {
    if (params.id) {
      getFeedbackById(params.id);
    }
  }, [getFeedbackById, params.id]);

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_FEEDBACK_ROUTE} state={location.state} useDefaultGoBack />
      <Header.Label
        label={t(location.state?.isOwnFeedback ? 'feedback_sent' : 'feedback_gratitude')}
      />
    </>
  );

  const handleClose = () => {
    navigate(MORE_FEEDBACK_ROUTE, { state: location.state });
  };

  const tagConfig = getTagConfig(feedbackData?.status);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card className={s.wrapper}>
            <WithLoader isLoading={feedbackIsLoading}>
              <Typography.Text className={s.date}>
                {formatDate(dayjs(feedbackData?.createDate))}
              </Typography.Text>
              <Typography.Title level={4}>{t('feedback_gratitude_for_work')}</Typography.Title>
              <Typography.Text className={s.feedback}>{feedbackData?.text}</Typography.Text>
              <Typography.Title level={5} className={s.fromWhom}>
                {isSent ? t('common_to_whom') : t('feedback_from_whom')}
              </Typography.Title>
              <Bio {...person} staffName={position} />
              {isSent && (
                <>
                  <Typography.Title level={5} className={s.status}>
                    {t('common_status')}
                  </Typography.Title>
                  <Tag
                    type={tagConfig.type}
                    children={
                      <>
                        <Icon name={tagConfig.icon} />
                        <span>{tagConfig.children}</span>
                      </>
                    }
                    className={s.tag}
                  />
                </>
              )}
              {showStatusDescriptions && <div></div>}
              <Button fullWidth type="secondary" className={s.closeButton} onClick={handleClose}>
                {t('common_close')}
              </Button>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default FeedbackDetails;
