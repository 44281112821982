import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Icon, ModalDefault } from 'gazprom-ui-lib';

import s from './GuideCall.module.scss';

const GuideCall = () => {
  const [isOpen, setIsOpen] = useState(false);

  const cancelModal = () => setIsOpen(!isOpen);

  return (
    <>
      <button className={s.phone} onClick={() => setIsOpen(!isOpen)}>
        <Icon name="hotlineFilled" size={24} />
      </button>

      <ModalDefault open={isOpen} onCancel={cancelModal}>
        <ModalDefault.Icon name="phoneFilled" type="primary" />
        <ModalDefault.Title>{t('guide_call_modal_title')}</ModalDefault.Title>
        <ModalDefault.Description>{t('guide_call_modal_subtitle')}</ModalDefault.Description>
        <ModalDefault.Actions>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = `tel:${GUIDE_HOT_LINE_PHONE}`;
            }}>
            {t('common_call')}
          </Button>
          <Button type="secondary" onClick={() => setIsOpen(!isOpen)}>
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

const GUIDE_HOT_LINE_PHONE = '88005004400';
export default GuideCall;
