import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { UploadedFileType } from 'utils/useUploadFile.hook';

import { CreateKedoTaskPropsType } from 'services/kedo/kedoApiService.types';

import { FormInstance, FormProps } from 'gazprom-ui-lib';

import { DELIVER_OPTIONS_VALUE } from 'pages/documents/documents.utils';

import { KedoStatementAttribute } from 'types/documents.types';
import { FileType } from 'types/file.types';

import { SCHEDULE_NAMES } from './attribute-schedule/attributeSchedule.utils';

export interface AttributeDefaultProps
  extends Omit<FormProps, 'id' | 'placeholder'>,
  KedoStatementAttribute {
  name: string;
  isReadonly?: boolean;
  initialValue?: {
    attributeCode: string | null;
    attributeId: string | null;
    attributeName: string | null;
    num: number;
    userText: string | null;
  };
  initialFileValue?: {
    attributeId: string | null;
    description: string | null;
    fileKey: string | null;
    fileName: string | null;
    num: number | null;
    signs: string[] | null;
    type: string | null;
  }[];
  files?: (FileType & { key: string })[];
  onSaveDraft?: (form: FormInstance) => void;
}

export const getAttributeComponentType = (type: string) =>
  type
    .split('_')
    .map((str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`)
    .join('');

export enum FORM_STATEMENT_ENUM {
  DELIVER = 'deliver',
  SHIPPING_ADDRESS = 'shippingAddress',
  MODAL_DELIVER = 'modalDeliver',
}

interface TransformRequestProps {
  values: Record<string, any>;
  attributes: KedoStatementAttribute[];
  hasDigitalForm?: boolean;
}
export const transformRequest = (props: TransformRequestProps) => {
  const {
    values,
    attributes,
    hasDigitalForm,
  } = props;

  let request: {
    files: CreateKedoTaskPropsType['body']['files'];
    filledAttributes: CreateKedoTaskPropsType['body']['filledAttributes'];
    shippingAddress?: string;
    needDigitalForm?: boolean;
  } = {
    files: [],
    filledAttributes: [],
  };

  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (key === FORM_STATEMENT_ENUM.DELIVER) {
      const deliver: string[] | undefined = values[FORM_STATEMENT_ENUM.DELIVER];
      if (Array.isArray(deliver)) {
        if (hasDigitalForm) {
          request.needDigitalForm = deliver.includes(DELIVER_OPTIONS_VALUE.ELECTRONIC)
        }

        request.shippingAddress = deliver.reduce((previousValue, currentValue) => {
          const prefix = previousValue.length ? '; ' : ''; {
            previousValue += `${prefix}${t(currentValue)}`;

            if (currentValue === DELIVER_OPTIONS_VALUE.MAIL) {
              previousValue += ` ${values[FORM_STATEMENT_ENUM.SHIPPING_ADDRESS] ?? ''}`;
            }


            return previousValue;
          }
        }, '');
      }
    } else {
      const attributeKey = attributes.find((attribute) => attribute.id === key)?.type;
      if (attributeKey) {
        switch (attributeKey) {
          case 'FILE': {
            if (value) {
              request.files.push({
                num: 0,
                key: value.key,
                attributeId: key,
              });
              request.filledAttributes.push({
                attributeId: key,
                userText: value.fileName,
              });
            }
            break;
          }
          case 'MULTIFILE': {
            type MultiFileInnerType = Record<'key' | 'num' | 'attributeId', string | number>;
            const { text, multiFiles } = (value as UploadedFileType[]).reduce(
              (previousValue, currentValue, currentIndex: number) => {
                if (currentValue.fileName) {
                  if (previousValue.text.length) {
                    previousValue.text += `,${currentValue.fileName}`;
                  } else {
                    previousValue.text += currentValue.fileName;
                  }
                  previousValue.multiFiles.push({
                    key: currentValue.key,
                    num: currentIndex,
                    attributeId: key,
                  });
                }

                return previousValue;
              },
              {
                text: '',
                multiFiles: [] as MultiFileInnerType[],
              },
            );
            request.files = request.files.concat(
              multiFiles as { num: number; key: string; attributeId: string }[],
            );
            request.filledAttributes.push({
              attributeId: key,
              userText: text,
            });
            break;
          }
          case 'BOOLEAN': {
            request.filledAttributes.push({
              attributeId: key,
              userText: value ? t('common_yes') : t('common_no'),
            });
            break;
          }
          case 'DATE': {
            if (value) {
              request.filledAttributes.push({
                attributeId: key,
                userText: dayjs(value).format('DD.MM.YYYY'),
              });
            }
            break;
          }
          case 'SCHEDULE': {
            request.filledAttributes.push({
              attributeId: key,
              userText: value.reduce(
                (prev: string, curr: { from?: string; to?: string }, index: number) => {
                  const fromTo = curr.from ? `${curr.from}-${curr.to}` : t('common_day_off');

                  prev += `${SCHEDULE_NAMES[index]}: ${fromTo}\n`;

                  return prev;
                },
                '',
              ),
            });
            break;
          }
          default: {
            if (value)
              request.filledAttributes.push({
                attributeId: key,
                userText: value.toString(),
              });
          }
        }
      }
    }
  });

  return request;
};
