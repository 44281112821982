import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { HOME_NEWS_TAGS_ROUTE } from 'routes/home/list';
import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  addPage,
  addRemoveLike,
  resetEvents,
  resetPagination,
  resetPaginationTagsAndEvents,
} from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { SEGMENT_NEWS_EVENT_TYPE } from 'pages/home/home-news/home-news-list/homeNewsList.utils';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

interface UseActionsProps {
  loadMoreRef: { current: boolean };
}

const useActions = (props: UseActionsProps) => {
  const { loadMoreRef } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [activeEventsTags, setActiveEventsTags] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_NEWS_EVENTS_TAGS,
    [] as string[],
  );
  const handleLikeClick = (code: string) => {
    dispatch(addRemoveLike(code));
  };
  const handleFetchMoreData = () => {
    loadMoreRef.current = false;
    dispatch(addPage());
  };

  const handleShowAll = useCallback(() => {
    navigate(HOME_NEWS_TAGS_ROUTE, { state: { type: SEGMENT_NEWS_EVENT_TYPE } });
  }, [navigate]);

  const handleAddRemoveNewsActiveTags = useCallback(
    (newsCode: string) => {
      loadMoreRef.current = false;
      const isAlreadyActive = activeEventsTags.includes(newsCode);
      const tagsToSet = isAlreadyActive
        ? activeEventsTags.filter((tag) => tag !== newsCode)
        : activeEventsTags.concat([newsCode]);
      setActiveEventsTags(tagsToSet);
      dispatch(resetEvents());
      dispatch(resetPagination());
    },
    [activeEventsTags, dispatch, loadMoreRef, setActiveEventsTags],
  );

  const handleTagsReset = useCallback(() => {
    dispatch(resetPaginationTagsAndEvents());
    setActiveEventsTags([]);
  }, [dispatch, setActiveEventsTags]);

  return {
    handleAddRemoveNewsActiveTags,
    handleTagsReset,
    handleShowAll,
    handleLikeClick,
    handleFetchMoreData,
  };
};

export default useActions;
