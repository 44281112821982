import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './StoryHeader.module.scss';

interface StoryHeaderProps extends PropsWithChildren {
  className?: string;
}

const StoryHeader: FC<StoryHeaderProps> = (props) => {
  const { className, children } = props;

  return <div className={cn(s.wrapper, className)}>{children}</div>;
};

export default StoryHeader;
