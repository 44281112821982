import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Checkbox, Flex, Form, Typography } from 'gazprom-ui-lib';

import { FsppObligationsDataType } from 'types/topManagement.types';

import { OTHER_INFORMATION_FORM_ENUM } from '../../otherInfoForm.utils';
import FsppSection from '../fspp-section';
import s from './FsppObligations.module.scss';

interface FsppObligationsProps {
  fsppObligationsInitial?: boolean;
  fsppObligationsData?: FsppObligationsDataType[] | null;
}

const FsppObligations: FC<FsppObligationsProps> = (props) => {
  const { fsppObligationsData, fsppObligationsInitial } = props;
  const [nextId, setNextId] = useState<number>(fsppObligationsData?.length ?? 0);
  const [sections, setSections] = useState<number[]>(
    fsppObligationsData?.map((_, index) => index) ?? [],
  );
  const form = Form.useFormInstance();
  const fsppObligations = Form.useWatch(OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS, form);

  const addCustomSection = () => {
    setNextId((prev) => prev + 1);
    setSections((prev) => [...prev, nextId + 1]);
  };

  const removeSection = (id: number) => () => {
    form.resetFields([
      `${OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_NUM}_${id}`,
      `${OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS_DATE}_${id}`,
    ]);
    return setSections((prev) => prev.filter((elId) => elId !== id));
  };

  const renderCustomSections = (id: number) => {
    const { fsppObligationsDate, fsppObligationsNum } = fsppObligationsData?.[id] || {};

    return (
      <FsppSection
        key={id}
        sectionId={id}
        onRemove={removeSection(id)}
        fsppObligationsDate={fsppObligationsDate}
        fsppObligationsNum={fsppObligationsNum}
      />
    );
  };

  const handleEmptyState = () => {
    if (!sections.length) {
      addCustomSection();
    }
  };

  useEffect(() => {
    const condition = !sections.length && fsppObligations;

    if (condition) {
      form.setFieldValue(OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS, false);
    }
  }, [form, fsppObligations, sections.length]);

  return (
    <Flex vertical>
      <Form.Item
        name={OTHER_INFORMATION_FORM_ENUM.FSPP_OBLIGATIONS}
        valuePropName="checked"
        initialValue={fsppObligationsInitial}
        label={
          <Typography.Title level={5}>{t('top_management_other_fspp_title')}</Typography.Title>
        }>
        <Checkbox onClick={handleEmptyState}>{t('common_yes_have')}</Checkbox>
      </Form.Item>
      {fsppObligations && (
        <>
          <div className={s.cardsContainer}>{sections.map(renderCustomSections)}</div>

          <Button
            type="link"
            size="small"
            htmlType="button"
            leftIcon="plus"
            onClick={addCustomSection}
            className={s.button}>
            {t('common_add_more')}
          </Button>
        </>
      )}
    </Flex>
  );
};

export default FsppObligations;
