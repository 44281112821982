import React, { FC, memo, useEffect } from 'react';

import { formRules } from 'utils/formRules.utils';

import { Form, Radio } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';
import { WORKBOOK_TYPE_ENUM, renderWorkbook } from './attributeWorkbookType.utils';

export interface AttributeWorkbookTypeProps extends AttributeDefaultProps {}

const AttributeWorkbookType: FC<AttributeWorkbookTypeProps> = (props) => {
  const { id, isMandatory, value, isReadonly } = props;

  const form = Form.useFormInstance();

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, value);
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item name={id} rules={isMandatory ? [formRules.required] : []}>
        <Radio.Group direction="vertical" disabled={isReadonly}>
          {Object.keys(WORKBOOK_TYPE_ENUM).map(renderWorkbook)}
        </Radio.Group>
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeWorkbookType);
