import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { getEmployeesSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Employee from 'components/employee';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { EmployeeType } from 'types/employee.types';

import s from './HomeSelectCompany.module.scss';

const HomeSelectCompany: FC = () => {
  const navigate = useNavigate();

  const { data } = useAppSelector(getEmployeesSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  const employeePersonalData = data?.[0]?.person;

  useEffect(() => {
    if (selectedEmployee) {
      navigate(HOME_ROUTE);
    } else if (data?.length === 1) {
      const [employee] = data;

      setSelectedEmployee(employee.id);
      navigate(HOME_ROUTE);
    } else {
      setIsLoading(false);
    }
  }, [data, navigate, selectedEmployee, setSelectedEmployee]);

  const renderEmployee = (e: EmployeeType) => (
    <button key={e.id} className={s.employeeWrapper} onClick={() => setSelectedEmployee(e.id)}>
      <Employee employee={e} showDivision={false} isSelected />
      <Icon name="forward" />
    </button>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={<Header.Logo useNavigation={false} />} showNavbar={false}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card>
              <Bio
                firstName={employeePersonalData?.firstName}
                lastName={employeePersonalData?.lastName}
              />
            </Card>
            <Card withPadding={false}>
              <Typography.Title level={4} className={s.title}>
                {t('common_available_for_selection')}
              </Typography.Title>
              {data?.map(renderEmployee)}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default HomeSelectCompany;
