import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  InfoKedoReviewTaskAssignmentPropsType,
  InfoKedoReviewTaskAssignmentResponseType,
  SignKedoReviewTaskAssignmentPropsType,
  SignKedoReviewTaskAssignmentResponseType,
} from './kedoReviewTaskApiService.types';

import { createKedoReviewTaskUrl } from './utils';

const kedoReviewTaskApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    signKedoReviewTaskAssignment: builder.mutation<
      SignKedoReviewTaskAssignmentResponseType,
      SignKedoReviewTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoReviewTaskUrl(`/${assignmentId}/sign`),
        method: 'POST',
        body,
      }),
    }),
    infoKedoReviewTaskAssignment: builder.mutation<
      InfoKedoReviewTaskAssignmentResponseType,
      InfoKedoReviewTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoReviewTaskUrl(`/${assignmentId}/info`),
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const { useSignKedoReviewTaskAssignmentMutation, useInfoKedoReviewTaskAssignmentMutation } =
  kedoReviewTaskApiService;
