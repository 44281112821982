import dayjs from 'dayjs';
import { t } from 'tools/i18n';

export const getCreatedDate = (createdDate?: string): string => {
  if (!createdDate) return '';

  return dayjs(createdDate).local().isToday()
    ? `${t('common_today')} ${dayjs(createdDate).format('HH:mm')}`
    : dayjs(createdDate).format('DD MMMM YYYY в HH:mm');
};
