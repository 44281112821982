import InternalStory from './story';
import StoryHeader from './story-header';
import StoryImg from './story-img';
import StoryTitle from './story-title';

export type {};

type CompoundedComponents = typeof InternalStory & {
  Header: typeof StoryHeader;
  Title: typeof StoryTitle;
  Img: typeof StoryImg;
};

const Typography = InternalStory as CompoundedComponents;

Typography.Header = StoryHeader;
Typography.Title = StoryTitle;
Typography.Img = StoryImg;
export default Typography;
