import { FC } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import InappLink from 'components/inappLink';

import { InsuranceType } from 'types/insurance.types';

import DmsCardItem from '../dms-card-item';
import s from './DmsCard.module.scss';
import { getWebsiteDomain } from './utils';

const DmsCard: FC<InsuranceType> = (props) => {
  const {
    insuranceCompany,
    number,
    validDateFrom,
    validDateTill,
    insuranceCompanySite,
    insuranceCompanyPhone,
    insuranceProgram,
    familyName,
    familySurname,
    familyPatronymic,
  } = props;

  const dateFrom = dayjs.utc(validDateFrom).local().format('DD.MM.YYYY');
  const dateUntil = dayjs.utc(validDateTill).local().format('DD.MM.YYYY');

  const shouldShowName = familyName?.length || familySurname?.length || familyPatronymic?.length;

  return (
    <Flex vertical gap="12" fullWidth className={s.wrapper}>
      <Typography.Title level={4}>{insuranceCompany}</Typography.Title>
      <Flex vertical gap="24" fullWidth>
        {shouldShowName && (
          <DmsCardItem
            title={t('common_full_name')}
            value={`${familySurname} ${familyName} ${familyPatronymic}`}
          />
        )}
        <DmsCardItem title={t('dms_number')} value={number} />
        <DmsCardItem title={t('dms_valid_date')} value={`${dateFrom}-${dateUntil}`} />
        <DmsCardItem title={t('dms_program_name')} value={insuranceProgram} />
        {!!insuranceCompanySite && (
          <Flex justify="space-between" align="center" fullWidth>
            <DmsCardItem
              link
              title={t('common_site')}
              value={getWebsiteDomain(insuranceCompanySite)}
            />
            <InappLink href={insuranceCompanySite}>
              <Flex className={s.iconWrapper} align="center" justify="center">
                <Icon name="web" />
              </Flex>
            </InappLink>
          </Flex>
        )}
        {!!insuranceCompanyPhone && (
          <Flex justify="space-between" align="center" fullWidth>
            <DmsCardItem title={t('common_phone')} value={insuranceCompanyPhone} />
            <a href={`tel:${insuranceCompanyPhone?.trim()}`}>
              <Flex className={s.iconWrapper} align="center" justify="center">
                <Icon name="phoneFilled" />
              </Flex>
            </a>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default DmsCard;
