import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CancellationConfirmModalProps {
  isOpen: boolean;
  onClickHandler: () => void;
  onCancelHandler: () => void;
}

const CancellationConfirmModal: FC<CancellationConfirmModalProps> = (props) => {
  const { isOpen, onClickHandler, onCancelHandler } = props;

  return (
    <ModalDefault open={isOpen} onCancel={onCancelHandler}>
      <ModalDefault.Icon name="ok" type="primary" />
      <ModalDefault.Title>{t('documents_cancel_order_success')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_cancel_order_success_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" size="large" fullWidth onClick={onClickHandler}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CancellationConfirmModal;
