import React, { FC } from 'react';

import { PROFILE_VACATION_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetLeaderSubdivisionsQuery } from 'services/me/meApiService';

import { Card, Flex } from 'gazprom-ui-lib';

import Employee from 'components/employee';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { LeaderSubdivisionType } from 'types/employee.types';

import s from './VacationEmployees.module.scss';
import VacationEmployeesDivision from './containers/vacations-employees-division';

const VacationEmployees: FC = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_VACATION_ROUTE} />
      <Header.Label label={t('vacation_employees_schedule')} />
    </>
  );

  const { data, isLoading } = useGetLeaderSubdivisionsQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee?.id },
  );

  const renderDivisionItem = (item: LeaderSubdivisionType) => {
    return <VacationEmployeesDivision division={item} />;
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false}>
              <Flex vertical fullWidth>
                <Flex className={s.header} fullWidth>
                  <Employee
                    employee={selectedEmployee!}
                    showDivision={false}
                    showEmploymentInfo={false}
                    showStaffName={false}
                  />
                </Flex>
                <Flex fullWidth vertical>
                  {data?.map(renderDivisionItem)}
                </Flex>
              </Flex>
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default VacationEmployees;
