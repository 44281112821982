import React, { FC, ReactNode, memo, useMemo } from 'react';

import cn from 'classnames';

import { Typography } from 'gazprom-ui-lib';

import s from './MobileNumberKeyboard.module.scss';

interface MobileNumberKeyboardProps {
  disabled?: boolean;
  className?: string;
  specialLeftElement?: KeyboardElementType;
  specialRightElement?: KeyboardElementType;
  onElementClick: (v?: string) => void;
}

type KeyboardElementType = {
  label?: ReactNode;
  onClick?: (v?: string) => void;
  value?: string;
  className?: string;
};

const MobileNumberKeyboard: FC<MobileNumberKeyboardProps> = (props) => {
  const { disabled, className, onElementClick, specialLeftElement, specialRightElement } = props;

  const renderElement = (e: KeyboardElementType, i: number) => {
    const { onClick, label, value, className } = e;

    const handleClick = () => {
      const funcToUse = onClick ?? onElementClick;

      funcToUse(value);
    };

    return (
      <button
        onClick={handleClick}
        key={`keyboard__element__${i}__${value}`}
        disabled={disabled}
        className={className}>
        <Typography.Text size="24" weight="500">
          {label ?? value}
        </Typography.Text>
      </button>
    );
  };

  const elementsToRender = useMemo(() => {
    let elements = DEFAULT_ELEMENTS;

    if (specialLeftElement) {
      elements.splice(9, 1, specialLeftElement);
    }
    if (specialRightElement) {
      elements.splice(11, 1, specialRightElement);
    }

    return elements;
  }, [specialLeftElement, specialRightElement]);

  return <div className={cn(s.wrapper, className)}>{elementsToRender.map(renderElement)}</div>;
};

const DEFAULT_ELEMENTS: KeyboardElementType[] = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: 'i0', className: s.invisible },
  { value: '0' },
  { value: 'i1', className: s.invisible },
];

export default memo(MobileNumberKeyboard);
