import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import companyPlug from 'pages/profile/profile-company/companyPlug.png';

import { EmployeeType } from 'types/employee.types';

import EmploymentTag from '../employment-tag';
import {
  getEmploymentRateTitle,
  getEmploymentTypeTitle,
} from '../employment-tag/employmentTag.utils';
import s from './Employee.module.scss';

interface EmployeeSelectProps {
  employee?: EmployeeType;
  showDivision?: boolean;
  showEmploymentInfo?: boolean;
  isSelected?: boolean;
  showStaffName?: boolean;
}

const Employee: FC<EmployeeSelectProps> = (props) => {
  const {
    employee,
    showDivision = true,
    showStaffName = true,
    showEmploymentInfo = true,
    isSelected = false,
  } = props;

  return (
    <div className={s.wrapper}>
      <img src={companyPlug} alt="лого компании" />
      <div>
        {showDivision && <Typography.Text size="12">{employee?.division?.name}</Typography.Text>}
        {showEmploymentInfo && (
          <Flex gap="4" wrap="wrap">
            <EmploymentTag type={isSelected ? 'outline' : 'neutral'}>
              {getEmploymentTypeTitle(employee?.employmentType)}
            </EmploymentTag>
            {!!employee?.partTime && (
              <EmploymentTag type={isSelected ? 'primary' : 'neutral'} icon="work">
                {getEmploymentRateTitle(employee?.partTime)}
              </EmploymentTag>
            )}
          </Flex>
        )}
        <Typography.Title level={5} type={isSelected ? 'primary' : undefined}>
          {employee?.employer?.name}
        </Typography.Title>
        <Typography.Text size="12" type="secondary">
          {showStaffName ? employee?.staff?.name : t('common_organization')}
        </Typography.Text>
      </div>
    </div>
  );
};

export default Employee;
