import { useGetMyLeadersForBusinessTripQuery } from 'services/my-leaders/myLeadersApiService';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

const useIsLeaderRegistered = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leadersData } = useGetMyLeadersForBusinessTripQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee },
  );

  const isLeaderRegistered = Boolean(leadersData?.firstLeader?.person?.ssoRegistered);

  return { isLeaderRegistered };
};

export default useIsLeaderRegistered;
