import React, { FC, useState } from 'react';

import { MORE_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useGetInsuranceQuery } from 'services/me/meApiService';

import { Flex, Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { InsuranceType } from 'types/insurance.types';

import DmsCard from './containers/dms-card';
import { SEGMENT_MY, SEGMENT_OPTIONS, SEGMENT_RELATIVES } from './moreDms.utils';

const MoreDms: FC = () => {
  const headerChildren = (
    <>
      <Header.GoBack to={MORE_ROUTE} />
      <Header.Label label={t('dms_polices')} />
    </>
  );

  const { data: familyDms, isFetching: isRelativesDmsFetching } = useGetInsuranceQuery({
    familyInsurance: true,
  });

  const { data: ownDms, isFetching: isOwnDmsFetching } = useGetInsuranceQuery({
    familyInsurance: false,
  });

  const [segment, setSegment] = useState<string>(SEGMENT_MY);

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) =>
    setSegment(segment.toString());

  const renderDmsItem = (item: InsuranceType) => <DmsCard key={item.id} {...item} />;

  const hasFamilyInsurance = !!familyDms?.length;

  const isLoading = isOwnDmsFetching || isRelativesDmsFetching;
  const dmsToShow = segment === SEGMENT_RELATIVES ? familyDms : ownDms;

  const hasNoDmsPolicies = !dmsToShow?.length;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <Card.Default
              title={t('dms_title')}
              description={t('dms_description')}
              icon="insurance"
              iconPadding="8"
            />
          </Card>
          <Card>
            <Flex vertical gap="16" fullWidth>
              <Typography.Title level={4}>{t('dms_polices')}</Typography.Title>
              <WithLoader isLoading={isLoading}>
                {hasFamilyInsurance && (
                  <Segmented
                    options={SEGMENT_OPTIONS}
                    value={segment}
                    onChange={handleSegmentChange}
                    fullWidth
                  />
                )}
                {hasNoDmsPolicies && (
                  <Empty title="dms_no_data" description="dms_no_data_description" />
                )}
                {dmsToShow?.map(renderDmsItem)}
              </WithLoader>
            </Flex>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreDms;
