import { Link } from 'react-router-dom';
import { MORE_SURVEYS_ROUTE, getMoreSurveyRoute } from 'routes/more/list';
import { t } from 'tools/i18n';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { useGetEmployeeSurveysQuery } from 'services/survey/surveyApiService';

import { Card, Carousel, Icon, Typography } from 'gazprom-ui-lib';

import SurveyLabel from 'components/survey-label';
import { LABEL_TYPES } from 'components/survey-label/surveyLabel';
import WithLoader from 'containers/wrappers/with-loader';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { SurveyType } from 'types/surveys.types';

import s from './HomeSurveys.module.scss';

const HomeSurveys = () => {
  const [selectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );
  const employerId = selectedEmployee;
  const { data: surveys, isLoading } = useGetEmployeeSurveysQuery({
    myEmployeeId: employerId,
    isMain: true,
  });

  const renderCard = (surveyData: SurveyType) => {
    const { id, name, isMandatory, anketologId, revisionId, anketaLinkId } = surveyData;
    const link = getMoreSurveyRoute(anketologId, revisionId, anketaLinkId);

    return (
      <Link to={link} key={id + name}>
        <Card className={s.card}>
          <div className={s.description}>
            <Typography.Title level={5} className={s.cardTitle}>
              {name}
            </Typography.Title>
            {isMandatory && <SurveyLabel type={LABEL_TYPES.MANDATORY} />}
          </div>
          <Icon name="forward" />
        </Card>
      </Link>
    );
  };

  if (!surveys?.length) {
    return null;
  }

  return (
    <WithLoader isLoading={isLoading}>
      <Card>
        <div className={s.titleContainer}>
          <Typography.Title level={4}>{t('survey_your_surveys')}</Typography.Title>
          <Link to={MORE_SURVEYS_ROUTE}>
            <Icon name="arrowRight" />
          </Link>
        </div>
        <Carousel className={s.carousel} slidesToShow={1.2} dots={false} infinite={false}>
          {surveys?.map(renderCard)}
        </Carousel>
      </Card>
    </WithLoader>
  );
};

export default HomeSurveys;
