import { AdditionalEducationInfoType } from 'types/topManagement.types';

export enum ADDITIONAL_EDUCATION_FORM_ENUM {
  EDUCATION_NAME = 'educationName',
  COURSE_NAME = 'courseName',
  INFO = 'documentInfo',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}

export type MappedAdditionalEducationInfoType = Partial<AdditionalEducationInfoType> & {
  id: number;
};
