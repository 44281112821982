import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { getSuggestionDecisionRoute } from 'routes/more/list';
import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useHeadApproveSuggestionDecisionByIdMutation } from 'services/me/suggestion/suggestionApiService';

import { Flex, Icon, Input, InputProps, Typography, handleRequest } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SuggestionDecisionPerformerType } from 'types/suggestion.types';

import s from './MoreSuggestionDecisionPerformer.module.scss';
import SelectedPerformerModal from './containers/selected-performer-modal';
import SentForImplementationModal from './containers/sent-for-implementation-modal';
import useGetData from './useGetData.hook';

export type ModalType = '' | 'sent';

const MoreSuggestionDecisionPerformer = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string; decisionId: string }>();

  const [modalType, setModalType] = useState<ModalType>('');
  const [selectedPerformer, setSelectedPerformer] =
    useState<null | SuggestionDecisionPerformerType>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const { debouncedValue: debouncedSearchValue } = useDebounce(searchValue);

  const [headApproveDecision, { isLoading: isHeadApprovingLoading }] =
    useHeadApproveSuggestionDecisionByIdMutation();

  const { performers, isLoading, isFetching } = useGetData({
    decisionId: params.id!,
    text: debouncedSearchValue,
  });

  const headerChildren = (
    <>
      <Header.GoBack to={getSuggestionDecisionRoute(params.id)} />
      <Header.Label label={t('common_select_responsible')} />
    </>
  );

  const handleInputChange: InputProps['onChange'] = (e) => {
    setSearchValue(e.target.value);
  };

  const renderOption = (performer: SuggestionDecisionPerformerType) => {
    const { id, person, division, position } = performer;

    const handleSelect = () => {
      setSelectedPerformer(performer);
    };

    return (
      <button onClick={handleSelect} className={s.option} type="button" key={id}>
        <Flex align="center" justify="space-between">
          <Bio showAvatar staffName={position.name} employerName={division.name} {...person} />
          <Icon name="forward" />
        </Flex>
      </button>
    );
  };

  const handleConfirmPerformer = () => {
    const onSuccess = () => {
      setSelectedPerformer(null);
      setModalType('sent');
    };

    if (selectedPerformer && params?.decisionId) {
      headApproveDecision({
        id: params?.decisionId,
        performerId: selectedPerformer.id,
      }).then(handleRequest({ onSuccess }));
    }
  };

  const handleReselectPerformer = () => {
    setSelectedPerformer(null);
  };

  const handleCloseDecisionPerformer = () => {
    if (params?.decisionId) {
      navigate(getSuggestionDecisionRoute(params.decisionId));
    }
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <Flex vertical gap="16">
              <Flex vertical className={s.padding} gap="16">
                <Typography.Title level={4}>
                  {t('suggestion_decision_choose_performer')}
                </Typography.Title>
                <Input
                  value={searchValue}
                  onChange={handleInputChange}
                  prefix={<Icon name="search" />}
                  size="large"
                  placeholder={t('common_fast_search')}
                />
              </Flex>

              <WithLoader isLoading={isLoading || isFetching}>
                <Flex vertical>
                  {performers?.length ? (
                    <Flex vertical>{performers?.map(renderOption)}</Flex>
                  ) : (
                    <Flex align="center" justify="center" className={s.paddingBottom}>
                      <Typography.Text size="14" type="secondary">
                        {t('common_no_results_found')}
                      </Typography.Text>
                    </Flex>
                  )}
                </Flex>
              </WithLoader>
            </Flex>
          </Card>

          <SelectedPerformerModal
            performer={selectedPerformer}
            onConfirm={handleConfirmPerformer}
            onCancel={handleReselectPerformer}
            isLoading={isHeadApprovingLoading}
          />

          <SentForImplementationModal
            isOpen={modalType === 'sent'}
            onConfirm={handleCloseDecisionPerformer}
          />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionDecisionPerformer;
