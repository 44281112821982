import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { SelectProps } from 'gazprom-ui-lib';

import {
  CURRENT_MONTH,
  CURRENT_YEAR,
  MIN_AVAILABLE_YEAR,
  MONTH_ENUM,
  START_YEAR,
} from 'constants/dates';

interface UseOptionsProps {
  year?: number;
  month?: number;
  isMinYearAvailable?: boolean;
  minYear?: number;
  maxYear?: number;
  showOnlyAfterToday?: boolean;
}

const useDateOptions = (props: UseOptionsProps) => {
  const {
    year = CURRENT_YEAR,
    month = CURRENT_MONTH,
    isMinYearAvailable = false,
    minYear = MIN_AVAILABLE_YEAR,
    maxYear = CURRENT_YEAR,
    showOnlyAfterToday,
  } = props;

  let minYearToUse = minYear ?? MIN_AVAILABLE_YEAR;
  if (isMinYearAvailable) {
    minYearToUse = START_YEAR;
  }

  const isCurrentYear = year === CURRENT_YEAR;
  const isCurrentMonth = isCurrentYear && month === dayjs().get('month');

  let monthOptions: SelectProps['options'] = [];
  if (showOnlyAfterToday) {
    MONTHS.forEach((en) => {
      const [monthLabel, number] = en;

      const value = {
        label: t(`common_enum_${monthLabel.toLowerCase()}`),
        value: number,
      };
      if (isCurrentYear) {
        if (number >= dayjs().month()) {
          monthOptions!.push(value);
        }
      } else {
        monthOptions!.push(value);
      }
    });
  } else {
    MONTHS.forEach((en) => {
      const [monthLabel, number] = en;

      const disabled = isCurrentYear && CURRENT_MONTH < number;

      monthOptions?.push({
        label: t(`common_enum_${monthLabel.toLowerCase()}`),
        value: number,
        disabled,
      });
    });
  }

  let dateOptions: SelectProps['options'] = [];
  const days = Array.from({
    length: dayjs(new Date(year, month)).daysInMonth(),
  });

  if (showOnlyAfterToday) {
    days.forEach((_: unknown, index: number) => {
      const value = index + 1;

      const dataToSet = {
        label: value,
        value,
      };

      if (isCurrentYear && month === dayjs().month()) {
        if (value >= dayjs().date()) {
          dateOptions?.push(dataToSet);
        }
      } else {
        dateOptions?.push(dataToSet);
      }
    });
  } else {
    dateOptions = days.map((_: unknown, index: number) => {
      const value = index + 1;
      const disabled = isCurrentMonth && value > dayjs().get('date');

      return {
        label: value,
        value,
        disabled,
      };
    });
  }

  const yearOptions: SelectProps['options'] = [];

  for (let year = maxYear; year >= minYearToUse; year--) {
    yearOptions.push({ label: year, value: year });
  }

  return {
    monthOptions,
    yearOptions,
    dateOptions,
  };
};

const MONTHS = Object.entries(MONTH_ENUM).slice(12, 24);

export default useDateOptions;
