import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { FileType } from 'types/file.types';
import { SettlementType } from 'types/guide.types';

type ParamsType = {
  fileKey?: string;
  url: string;
  withAuth?: boolean;
};

interface Props {
  fileMap?: SettlementType['fileMap'];
  handleOpenFile: (handleOpenParams: ParamsType) => Promise<void>;
}

const useDownloadFile = (props: Props) => {
  const { fileMap, handleOpenFile } = props;

  const fileKey = fileMap?.link ?? '';
  const fileName = fileMap?.name ?? '';
  const fileLink = fileMap?.link;

  const file: FileType = {
    attributeId: null,
    description: null,
    fileKeyWithStamp: null,
    num: null,
    signs: null,
    fileKey: fileKey,
    fileName: t('guide_download', { name: fileName }),
  };

  const downloadFile = () =>
    handleOpenFile({ url: createMediaDownloadUrl(fileKey!), fileKey, withAuth: true });

  return {
    file,
    fileKey,
    fileName,
    fileLink,
    downloadFile,
  };
};

export default useDownloadFile;
