import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CorrectionTaskModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const CorrectionTaskModal: FC<CorrectionTaskModalProps> = (props) => {
  const { isOpen, handleClose } = props;

  return (
    <ModalDefault key="correctionTaskModal" open={isOpen} onCancel={handleClose} width={300}>
      <ModalDefault.Icon name="arrowLeft" type="primary" />
      <ModalDefault.Title>{t('documents_send_to_correction_success')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_send_to_correction_success_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="secondary" size="large" fullWidth onClick={handleClose}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CorrectionTaskModal;
