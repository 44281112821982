import React, { FC, memo, useEffect } from 'react';

import { formRules } from 'utils/formRules.utils';

import { Checkbox, Form } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeBooleanProps extends AttributeDefaultProps {}

const AttributeBoolean: FC<AttributeBooleanProps> = (props) => {
  const { id, isMandatory, placeholder, isReadonly, value } = props;

  const form = Form.useFormInstance();

  useEffect(() => {
    if (form && id && value) {
      form.setFieldValue(id, value?.toString()?.toLowerCase() === 'да');
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item
        name={id}
        rules={isMandatory ? [formRules.required] : []}
        valuePropName="checked"
        initialValue={value?.toString()?.toLowerCase() === 'да'}>
        <Checkbox disabled={isReadonly}>{placeholder}</Checkbox>
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeBoolean);
