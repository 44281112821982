import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetFaqQuestionByIdPropsType,
  GetFaqQuestionByIdResponseType,
  GetFaqQuestionsListPropsType,
  GetFaqQuestionsListResponseType,
} from './faqListApiService.types';

import { createFaqListUrl } from './utils';

const faqListApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getFaqQuestionsList: builder.query<
      GetFaqQuestionsListResponseType,
      GetFaqQuestionsListPropsType
    >({
      query: (params) => ({
        url: createFaqListUrl(prepareQueryParams(params)),
      }),
    }),
    getFaqQuestionById: builder.query<GetFaqQuestionByIdResponseType, GetFaqQuestionByIdPropsType>({
      query: ({ id, ...params }) => ({
        url: createFaqListUrl(`/${id}${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useGetFaqQuestionsListQuery, useGetFaqQuestionByIdQuery } = faqListApiService;
