import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useActivateSettlementMutation,
  useGetMySettlementHistoryQuery,
} from 'services/guide/guideApiService';

import { Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { HistoryItem } from 'types/guide.types';

import GuideButtonLocation from '../guide-button-location';
import GuideButtonQR from '../guide-button-qr';
import GuideSelect from '../guide-select';
import GuideSelectButton from '../guide-select-button';
import { getUniqueSettlements } from '../guide.utils';
import s from './GuideMyCamp.module.scss';

const GuideMyCamp = () => {
  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data, isLoading } = useGetMySettlementHistoryQuery({
    myEmployeeId: selectedEmployee?.id!,
    page: 1,
    size: 500,
  });
  const [activateSettlement] = useActivateSettlementMutation();

  const uniqueSettlement = useMemo(() => getUniqueSettlements(data), [data]);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(GUIDE_ROUTE)} />
      <Header.Label label={t('guide_my_camp_header_label')} />
    </>
  );

  const handleChangeSettlement = (id: string) => {
    activateSettlement({ myEmployeeId: selectedEmployee?.id!, settlementId: id }).then(() =>
      navigate(GUIDE_ROUTE),
    );
  };

  const renderButtons = (params: HistoryItem) => {
    const { name, id } = params.settlement;

    return <GuideSelectButton name={name} id={id} key={id} onClick={handleChangeSettlement} />;
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <GuideSelect
            change
            icon="gid"
            title={t('guide_my_camp_text_title')}
            subtitle={t('guide_my_camp_text_subtitle')}
            buttons={
              <>
                <GuideButtonLocation text={t('guide_select_button_define_by_geo')} />
                <GuideButtonQR text={t('guide_select_button_qr')} />
              </>
            }
          />
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false}>
              <Typography.Title level={4} className={s.title}>
                {t('guide_my_camp_previously_tied')}
              </Typography.Title>
              <GuideSelectButton
                name={selectedEmployee?.currentSettlement.name!}
                id={selectedEmployee?.currentSettlement.id!}
                isCurrent
              />
              {uniqueSettlement?.map(renderButtons)}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideMyCamp;
