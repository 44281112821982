import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';
import useIsLeaderRegistered from 'utils/useLeaderIsRegistered';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from './PageDescription.module.scss';

const PageDescription: FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { isLeaderRegistered } = useIsLeaderRegistered();

  const handleClick = () => {
    if (!isLeaderRegistered) {
      handleOpenModal();
      return;
    }

    navigate(MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE);
  };

  const handleOpenModal = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <Card>
        <Card.Default
          title={t('common_innovations')}
          description={t('innovations_offer_idea_description')}
          icon="idea"
        />
        <Button leftIcon="plus" fullWidth size="large" onClick={handleClick} className={s.button}>
          {t('innovations_offer_idea')}
        </Button>
      </Card>

      <ModalDefault open={isModalOpen} onCancel={handleOpenModal} closable>
        <ModalDefault.Icon name="attention" type="danger" />
        <ModalDefault.Title>{t('suggestion_not_available_title')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('suggestion_not_available_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button type="secondary" onClick={handleOpenModal} fullWidth size="large">
            {t('common_close')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </>
  );
};

export default PageDescription;
