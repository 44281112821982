import React, { FC, memo, useEffect, useState } from 'react';

import { App as CapacitorApp } from '@capacitor/app';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetArticleByIdCommentsQuery } from 'services/article/articleApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import { COMMENT_MODALS_ENUM, CurrentCommentDataType } from './homeDetailsComments.types';
import { ArticleCommentType } from 'types/article.types';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

import s from './HomeDetailsComments.module.scss';
import HomeNewsDetailsCommentsAdd from './home-news-details-comments-add';

interface HomeDetailsCommentsProps {
  id: string;
}

const HomeDetailsComments: FC<HomeDetailsCommentsProps> = (props) => {
  const { id } = props;

  const navigate = useNavigate();
  const [selectedEmployee] = useFullSelectedEmployee();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [showAddOrEditComment, setShowAddOrEditComment] = useState<COMMENT_MODALS_ENUM>(
    COMMENT_MODALS_ENUM.EMPTY,
  );
  const [currentCommentData, setCurrentCommentData] = useState<CurrentCommentDataType | null>(null);
  const [pagination, setPagination] = useState<PaginationPropsType>(INITIAL_PAGINATION);

  useEffect(() => {
    const handleBackButton = () => {
      if (showAddOrEditComment !== COMMENT_MODALS_ENUM.EMPTY) {
        handleOpenCloseAddComment();
      } else {
        navigate(-1);
      }
    };

    CapacitorApp.addListener('backButton', handleBackButton);

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [navigate, showAddOrEditComment]);

  const {
    data: comments,
    isLoading: commentsIsLoading,
    isFetching: commentsIsFetching,
  } = useGetArticleByIdCommentsQuery({
    ...pagination,
    newsCode: id,
    sort: [{ order: SORT_ENUM.DESC, property: 'createdAt' }],
  });

  const handleOpenCloseAddComment = () => setShowAddOrEditComment(COMMENT_MODALS_ENUM.ADD);
  const handleOpenCloseEditComment = (value: CurrentCommentDataType) => {
    setShowAddOrEditComment(COMMENT_MODALS_ENUM.EDIT);
    setCurrentCommentData(value);
  };
  const handleCloseComment = () => setShowAddOrEditComment(COMMENT_MODALS_ENUM.EMPTY);

  const renderComment = (comment: ArticleCommentType) => {
    const { id, comment: commentText, createdAt, creator } = comment;
    const isMyComment = creator.id === selectedEmployee?.id;

    return (
      <div className={s.comment} key={id}>
        {isMyComment && (
          <button
            onClick={() =>
              handleOpenCloseEditComment({ commentId: id, initialComment: commentText })
            }>
            <Icon name="edit" />
          </button>
        )}
        <Typography.Text type="secondary">
          {dayjs(createdAt).local().format('DD MMMM YYYY')}
        </Typography.Text>
        <Typography.Paragraph size="14">{commentText}</Typography.Paragraph>
        <Bio
          firstName={creator.person.firstName}
          lastName={creator.person.lastName}
          employerName={creator.employer.name}
        />
      </div>
    );
  };

  const handleShowHideComments = () => {
    setShowComments((prevState) => !prevState);
  };

  const { totalCount = 0, items = [] } = comments ?? {};

  const isFetchMoreAvailable = pagination.page * pagination.size < totalCount;
  const countToShow = commentsIsFetching ? '' : totalCount;

  const showLoader =
    commentsIsLoading || (commentsIsFetching && pagination.page === INITIAL_PAGINATION.page);

  return (
    <>
      <Card withPadding={false}>
        <WithLoader isLoading={showLoader}>
          <div className={s.commentHeader}>
            <div className={s.commentsTitle}>
              <div>
                <Typography.Title level={4}>{t('common_comments')}</Typography.Title>
                <Typography.Text type="primary">{countToShow}</Typography.Text>
              </div>
              <button onClick={handleShowHideComments}>
                <Icon name={showComments ? 'up' : 'down'} />
              </button>
            </div>
            <button className={s.addCommentPlug} onClick={handleOpenCloseAddComment}>
              <Typography.Title level={5} type="secondary" strong={false}>
                {t('home_add_comment')}...
              </Typography.Title>
            </button>
          </div>
          {showComments && (
            <WithLoader isLoading={commentsIsLoading}>
              <WithInfiniteScroll
                fetchMoreData={fetchMoreData(setPagination)}
                isFetching={commentsIsFetching}
                isFetchMoreAvailable={isFetchMoreAvailable}>
                {items?.map(renderComment)}
              </WithInfiniteScroll>
            </WithLoader>
          )}
        </WithLoader>
      </Card>
      {showAddOrEditComment && (
        <HomeNewsDetailsCommentsAdd
          id={id}
          commentType={showAddOrEditComment}
          commentData={currentCommentData}
          setShowAddComment={handleCloseComment}
        />
      )}
    </>
  );
};

const INITIAL_PAGINATION = { page: 1, size: 10 };

export default memo(HomeDetailsComments);
