import React, { useContext } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MORE_FEEDBACK_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useCreateFeedbackMutation } from 'services/feedback/feedbackApiService';

import { Button, Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import WithLoader from 'containers/wrappers/with-loader';

import s from '../ProfileFeedbackCreate.module.scss';
import ProfileFeedbackCreateViewWrapper from '../profile-feedback-create-view-wrapper';
import { CONFIG_STATE, FeedbackCreateContext } from '../profileFeedbackCreate.utils';

const ProfileFeedbackCreateConfirm = () => {
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const { feedbackTarget, feedbackCategory, setConfigStep, feedbackText } =
    useContext(FeedbackCreateContext);

  const [createFeedback, { isLoading: createFeedbackIsFetching }] = useCreateFeedbackMutation();

  if (!feedbackTarget || !setConfigStep || !feedbackCategory) {
    return <></>;
  }

  const onSuccess = () => {
    navigate(MORE_FEEDBACK_ROUTE, {
      state: {
        selectedSegment: 'common_sent',
        showConfirmationModal: true,
      },
    });
  };

  const handleSendFeedback = () => {
    createFeedback({
      employeeId: selectedEmployee?.id!,
      recipientEmployeeId: feedbackTarget.id,
      category: feedbackCategory?.id,
      text: feedbackText,
    }).then(
      handleRequest({
        onSuccess,
      }),
    );
  };

  return (
    <ProfileFeedbackCreateViewWrapper>
      <Card>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_TARGET,
              previous: CONFIG_STATE.ADD_FEEDBACK_CONFIRM,
            })
          }
          className={s.defaultCard}>
          <Bio
            firstName={feedbackTarget?.person?.firstName}
            lastName={feedbackTarget?.person?.lastName}
          />
          <Icon name="edit" />
        </button>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_SELECT_FEEDBACK_CATEGORY,
              previous: CONFIG_STATE.ADD_FEEDBACK_CONFIRM,
            })
          }
          className={cn(s.defaultCard, s.category)}>
          <div>
            <Typography.Text>{t('feedback_category')}</Typography.Text>
            <Typography.Title level={4}>{feedbackCategory?.name}</Typography.Title>
          </div>
          <Icon name="edit" />
        </button>
        <button
          onClick={() =>
            setConfigStep({
              current: CONFIG_STATE.STAND_ALONE_FEEDBACK_TEXT,
              previous: CONFIG_STATE.ADD_FEEDBACK_CONFIRM,
            })
          }
          className={cn(s.defaultCard, s.text)}>
          <div>
            <Typography.Title level={4}>{t('feedback_text')}</Typography.Title>
            <Typography.Paragraph>{feedbackText}</Typography.Paragraph>
          </div>
          <Icon name="edit" />
        </button>
        <WithLoader
          isLoading={createFeedbackIsFetching}
          loader={
            <div className={s.loader}>
              <UniversalLoader />
            </div>
          }>
          <Button fullWidth rightIcon="arrowRight" onClick={handleSendFeedback}>
            {t('common_send_to_check')}
          </Button>
        </WithLoader>
      </Card>
    </ProfileFeedbackCreateViewWrapper>
  );
};

export default ProfileFeedbackCreateConfirm;
