import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Button, Card, Flex, Form, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { EducationType } from 'types/topManagement.types';

import Collapse from '../collapse';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import EducationCard from './containers/education-card';
import { MappedEducationType } from './educationInfoForm.utils';
import useEducationForm from './useEducationForm.hook';

const EducationInfoForm = () => {
  const {
    customSection,
    educationData,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    addCustomSection,
    handleForm,
    removeCustomSection,
  } = useEducationForm();

  const renderEducationCards = (data: EducationType) => {
    const {
      educationName,
      educationDate,
      qualification,
      documentSeries,
      documentNumber,
      documentIssueDate,
    } = data;
    const key = `${educationName}${qualification}${documentSeries}${documentNumber}`;

    return (
      <Card key={key}>
        <Collapse
          noPadding
          defaultActiveKey={key}
          items={[
            {
              key: key,
              label: <Typography.Title level={5}>{educationName}</Typography.Title>,
              children: (
                <>
                  <DataPresenter
                    title={t('top_management_add_education_date')}
                    text={dayjs(educationDate).format('DD.MM.YYYY')}
                  />
                  <DataPresenter
                    title={t('top_management_add_education_cualification_title')}
                    text={qualification}
                  />
                  <DataPresenter
                    title={t('top_management_add_education_series')}
                    text={documentSeries}
                  />
                  <DataPresenter
                    title={t('top_management_add_education_number')}
                    text={documentNumber}
                  />
                  <DataPresenter
                    title={t('common_issue_date')}
                    text={dayjs(documentIssueDate).format('DD.MM.YYYY')}
                  />
                </>
              ),
            },
          ]}
        />
      </Card>
    );
  };

  const renderCustomSections = (element: MappedEducationType) => {
    const { id, ...rest } = element || {};

    return (
      <EducationCard
        id={id}
        onClick={removeCustomSection(id)}
        key={id}
        educationInitialData={rest}
      />
    );
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_education_info')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>
          {educationData?.map(renderEducationCards)}

          <Flex gap="16" vertical>
            {customSection.map(renderCustomSections)}
          </Flex>

          <Card>
            <Button
              type="secondary"
              fullWidth
              size="large"
              htmlType="button"
              leftIcon="plus"
              onClick={addCustomSection}>
              {t('top_management_add_education_info')}
            </Button>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default EducationInfoForm;
