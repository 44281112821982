import cn from 'classnames';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Icon, Tag, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { getKedoTagConfig } from 'pages/documents/documents.utils';

import { AssignmentType } from 'types/assignment.types';

import s from './DocumentsAssignment.module.scss';

interface Props {
  isList: boolean;
  detailed?: boolean;
}

const useAssignmentConfig = (props: Props) => {
  const { isList, detailed = false } = props;

  const [selectedEmployee] = useFullSelectedEmployee();

  const getEmployee = (assignment: AssignmentType | undefined, isMyOwnTask: boolean) => {
    const showEmployee = !isMyOwnTask && !!assignment?.employee?.id;

    if (!showEmployee || !assignment) {
      return null;
    }

    const { employee } = assignment;
    const { person, employer, position } = employee;
    const { firstName, lastName } = person ?? {};

    const employerName = employer?.name;
    const staffName = position?.name;

    return (
      <Bio
        employerName={detailed ? employerName : ''}
        firstName={firstName}
        lastName={lastName}
        staffName={staffName}
      />
    );
  };

  const getDescription = (assignment?: AssignmentType) => {
    if (!assignment) {
      return null;
    }

    const { description, type } = getKedoTagConfig({
      ...assignment.status,
      title: assignment.status.name,
    });

    return (
      <Typography.Text size="14" className={s[`${type}Description`]}>
        {description}
      </Typography.Text>
    );
  };

  const getTag = (assignment?: AssignmentType) => {
    if (!assignment) {
      return null;
    }
    const { type, icon, children } = getKedoTagConfig({
      ...assignment.status,
      title: assignment.status.name,
    });

    return (
      <Tag type={type} className={cn(s.tag, isList ? s.tagList : s.tagDetails)}>
        <Icon name={icon} />
        <span>{children}</span>
      </Tag>
    );
  };

  return (assignment?: AssignmentType) => {
    const isMyOwnTask =
      selectedEmployee?.id === assignment?.executorId &&
      assignment?.executorId === assignment?.employee?.id;

    return {
      description: getDescription(assignment),
      tag: getTag(assignment),
      title: assignment?.title,
      employee: getEmployee(assignment, isMyOwnTask),
    };
  };
};

export default useAssignmentConfig;
