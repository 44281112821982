import React, { FC } from 'react';

import { useParams } from 'react-router-dom';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';

import { Flex } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';

import { BusinessTripType } from 'types/businessTrip.types';
import { KEDO_COPY_FILE_TYPES } from 'types/documents.types';
import { FileType } from 'types/file.types';

interface FilesProps {
  files?: BusinessTripType['files'];
}

const Files: FC<FilesProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const { files = [] } = props;

  const { handleDownload, loadingKey } = useDownloadKedoFile();

  const renderFile = (file: FileType) => {
    const { fileKeyWithStamp, fileKey } = file;

    const fKey = fileKeyWithStamp ?? fileKey;

    return (
      <KedoFile
        isAttributeFile={false}
        file={file}
        key={fKey}
        onClick={handleDownload({
          fileKey: fKey,
          isArchive: false,
          taskId: id!,
          copyFileType: KEDO_COPY_FILE_TYPES.WORK_TOUR,
        })}
        loading={loadingKey === fKey}
      />
    );
  };

  return <Flex vertical>{files.map(renderFile)}</Flex>;
};

export default Files;
