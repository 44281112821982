import { createSlice } from '@reduxjs/toolkit';

import { EventType } from 'types/event.types';
import { PaginationPropsType } from 'types/pagination.types';

export const SLICE_NAME = 'eventsSlice';

export type EventsSliceState = PaginationPropsType & {
  eventsToShow: EventType[];
  showMyEvents: boolean;
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: EventsSliceState = {
  showMyEvents: false,
  eventsToShow: [],
  ...DEFAULT_PAGINATION,
};

export const eventsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationTagsAndEvents(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.eventsToShow = [];
    },
    setShowMyEvents(state, action) {
      state.showMyEvents = action.payload;
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.eventsToShow = [];
    },
    addNews(state, action) {
      state.eventsToShow = state.eventsToShow.concat(action.payload);
    },
    resetEvents(state) {
      state.eventsToShow = [];
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
    addRemoveLike(state, action) {
      state.eventsToShow = state.eventsToShow.map((art): EventType => {
        if (art.article.code === action.payload) {
          const likesCnt = art.article.liked ? art.article.likesCnt - 1 : art.article.likesCnt + 1;

          return {
            ...art,
            article: { ...art.article, liked: !art.article.liked, likesCnt },
          };
        }

        return art;
      });
    },
    addRemoveParticipation(state, action) {
      const { isParticipant, id } = action.payload;

      if (state.showMyEvents) {
        state.eventsToShow = state.eventsToShow.filter((art) => art.meeting.id !== id);
      } else {
        state.eventsToShow = state.eventsToShow.map((art): EventType => {
          if (art.meeting.id === id) {
            art.isParticipant = isParticipant;
            isParticipant ? (art.meeting.countMembers += 1) : (art.meeting.countMembers -= 1);
          }

          return art;
        });
      }
    },
  },
});

export const {
  addRemoveParticipation,
  addPage,
  addNews,
  resetPaginationTagsAndEvents,
  resetEvents,
  resetPagination,
  addRemoveLike,
  setShowMyEvents,
} = eventsSlice.actions;

export default eventsSlice.reducer;
