import React, { FC, MouseEvent, ReactNode } from 'react';

import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import { getCreatedDate } from 'utils/article.utils';
import { transformHtmlMediaSrc } from 'utils/createMediaUrl.utils';

import { Icon, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';

import { ArticleFullType } from 'types/article.types';

import s from './NewsDetailsContent.module.scss';

interface NewsDetailsContentProps {
  articleData?: ArticleFullType;
  handleAddArticle: (e: MouseEvent<HTMLButtonElement>) => void;
  isLiked: boolean;
  likesCount: number;
  renderCategory: (category: { id: string; name: string }) => ReactNode;
  renderMedia: (media: { type: string; link: string; name: string }) => ReactNode;
}

const NewsDetailsContent: FC<NewsDetailsContentProps> = (props) => {
  const { articleData, handleAddArticle, isLiked, renderCategory, renderMedia, likesCount } = props;

  return (
    <div className={s.wrapper}>
      <Typography.Text type="secondary">{getCreatedDate(articleData?.publishFrom)}</Typography.Text>
      <Typography.Title level={4} className={s.title}>
        {articleData?.title}
      </Typography.Title>
      <Cover alt="news cover" src={articleData?.cover?.link} className={s.cover} showNoData />
      <Typography.Paragraph size="14" className={s.text}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(transformHtmlMediaSrc(articleData?.text)),
          }}
        />
      </Typography.Paragraph>
      {articleData?.media && <div className={s.media}>{articleData.media.map(renderMedia)}</div>}
      {articleData?.categories && (
        <div className={s.categories}>{articleData?.categories.map(renderCategory)}</div>
      )}
      <div className={s.actions}>
        <button className={cn(s.liked)} onClick={handleAddArticle}>
          <Typography.Text type="primary" size="12">
            <Icon name={isLiked ? 'thankingFilled' : 'thanking'} />
            {likesCount}
          </Typography.Text>
        </button>
        <Typography.Text size="12" type="secondary">
          <Icon name="eye" />
          {articleData?.viewsCnt ?? 0}
        </Typography.Text>
      </div>
    </div>
  );
};

export default NewsDetailsContent;
