import { t } from 'tools/i18n';

export const SCHEDULE_NAMES = [
  t('common_enum_monday_short'),
  t('common_enum_tuesday_short'),
  t('common_enum_wednesday_short'),
  t('common_enum_thursday_short'),
  t('common_enum_friday_short'),
  t('common_enum_saturday_short'),
  t('common_enum_sunday_short'),
];
export const SELECTIONS = {
  hours: [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ],
  minutes: ['00', '15', '30', '45'],
};

export type ModalConfigType = {
  day: string;
  hours: string;
  minutes: string;
  type: 'from' | 'to';
};
export type FormScheduleType = { day: string; from?: string; to?: string };

export const INITIAL_VALUE: FormScheduleType[] = Array.from({ length: 7 }).map(
  (_: unknown, index: number) => ({ day: index.toString(), from: undefined, to: undefined }),
);
