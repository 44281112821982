import React, { FC, memo, useEffect } from 'react';

import { formRules } from 'utils/formRules.utils';

import { Form, Radio } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';
import { WORKDAY_TYPE_ENUM, renderWorkday } from './attributeWorkdayType.utils';

export interface AttributeWorkdayTypeProps extends AttributeDefaultProps {}

const AttributeWorkdayType: FC<AttributeWorkdayTypeProps> = (props) => {
  const { id, isMandatory, value, isReadonly } = props;

  const form = Form.useFormInstance();

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, value);
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item name={id} rules={isMandatory ? [formRules.required] : []}>
        <Radio.Group direction="vertical" disabled={isReadonly}>
          {Object.keys(WORKDAY_TYPE_ENUM).map(renderWorkday)}
        </Radio.Group>
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeWorkdayType);
