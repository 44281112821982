import { useNavigate } from 'react-router-dom';
import { MORE_SURVEYS_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

const SurveysQuestionsNotSupported = () => {
  const navigate = useNavigate();

  return (
    <ModalDefault open={true}>
      <ModalDefault.Icon name="attention" type="danger" />
      <ModalDefault.Title>{t('surveys_not_supported_title')}</ModalDefault.Title>
      <ModalDefault.Description>{t('surveys_not_supported_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" fullWidth onClick={() => navigate(MORE_SURVEYS_ROUTE)} size="large">
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default SurveysQuestionsNotSupported;
