import { FC, useEffect } from 'react';

import { ScaleAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form, Radio } from 'gazprom-ui-lib';

import SurveyTitle from 'pages/surveys/surveys-question-title';

import { FreeQuestion, Options, QuestionTypes } from 'types/surveys.types';

import SurveysQuestionsFree from '../surveys-questions-free';
import { QuestionProps } from '../surveysQuestion';
import s from './surveysQuestionsScale.module.scss';

export interface SurveysQuestionsScaleProps extends QuestionProps {
  title: string;
  no: number;
  is_required: boolean;
  options: Options[];
  ranges: {
    id: number;
    min_title: string;
    max_title: string;
  }[];
  additional_question?: FreeQuestion;
}

const SurveysQuestionsScale: FC<SurveysQuestionsScaleProps> = (props) => {
  const { id, type, is_required, no, options, title, additional_question, ranges } = props;
  const lowOptionId = additional_question?.visiblerules[0].events[0].option_id;
  const name = `${type}.${id}.${ranges[0].id}`;

  const form = Form.useFormInstance();
  const optionId = Form.useWatch(name);
  const { isCompleted, answers } = useAppSelector((store) => store.surveySlice);

  const answer = (
    answers?.answer.find((answer) => answer.question_id === id) as ScaleAnswerType | undefined
  )?.question_answer.answer?.ranges[0].options.find((option) => option.answer_value === true);
  const isRequired = (lowOptionId === optionId && additional_question?.is_required) || is_required;

  useEffect(() => {
    if (answer) {
      form.setFieldValue(name, answer.option_id);
    }
  }, [answer, form, name]);

  return (
    <div className={s.container}>
      <SurveyTitle level={5} number={no} required={isRequired}>
        {title}
      </SurveyTitle>
      <Form.Item name={name}>
        <Radio.Group disabled={isCompleted}>
          {options.map(({ id, title }) => (
            <Radio.Button value={id} key={id}>
              {title}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      {additional_question && lowOptionId === optionId && (
        <SurveysQuestionsFree
          {...additional_question}
          type={`${additional_question.type}_additional` as QuestionTypes}
          withoutTitle
        />
      )}
    </div>
  );
};

export default SurveysQuestionsScale;
