import happyMood from 'assets/icons/happyMood.png';
import neutralMood from 'assets/icons/neutralMood.png';
import sadMood from 'assets/icons/sadMood.png';

import { MoodTypes } from 'types/mood.types';

export type MoodItemType = {
  type: MoodTypes;
  src: string;
};

export const MOOD_OPTIONS: MoodItemType[] = [
  {
    type: 'SAD',
    src: sadMood,
  },
  {
    type: 'NEUTRAL',
    src: neutralMood,
  },
  {
    type: 'HAPPY',
    src: happyMood,
  },
];
