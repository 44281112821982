import { t } from 'tools/i18n';

import { CreateSuggestionPropsType } from 'services/me/suggestion/suggestionApiService.types';

import { SelectOptionType } from 'containers/attribute/attribute-options/attributeOptions.utils';

import { SUGGESTION_NEED_EXPENSES_ENUM, SuggestionType } from 'types/suggestion.types';

type AttributeType = 'STRING' | 'TEXT_AREA' | 'MULTIFILE' | 'OPTIONS' | 'BOOLEAN';

export type SuggestionAttributeType = {
  code: string;
  id: string;
  isMandatory?: boolean;
  name?: string;
  type: AttributeType;
  placeholder?: string;
  options?: SelectOptionType[];
};

export const SUGGESTION_ATTRIBUTES: SuggestionAttributeType[] = [
  {
    code: 'name',
    id: 'name',
    isMandatory: true,
    name: t('innovations_offer_name'),
    type: 'STRING',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'problem',
    id: 'problem',
    isMandatory: true,
    name: t('innovations_offer_problem'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'problemFiles',
    id: 'problemFiles',
    name: t('innovations_offer_problem_files'),
    type: 'MULTIFILE',
  },
  {
    code: 'suggestion',
    id: 'suggestion',
    isMandatory: true,
    name: t('innovations_offer_suggestion'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'suggestionFiles',
    id: 'suggestionFiles',
    name: t('innovations_offer_suggestion_files'),
    type: 'MULTIFILE',
  },
  {
    code: 'expectation',
    id: 'expectation',
    isMandatory: true,
    name: t('innovations_offer_result'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'needExpenses',
    id: 'needExpenses',
    type: 'OPTIONS',
    name: t('innovations_offer_need_expenses'),
    isMandatory: true,
    options: [
      { value: SUGGESTION_NEED_EXPENSES_ENUM.NEED_MONEY, label: t('common_yes') },
      { value: SUGGESTION_NEED_EXPENSES_ENUM.NO_NEED_MONEY, label: t('common_no') },
      { value: SUGGESTION_NEED_EXPENSES_ENUM.UNKNOWN_NEED_MONEY, label: t('common_dont_know') },
    ],
  },
  {
    code: 'personalParticipation',
    id: 'personalParticipation',
    type: 'BOOLEAN',
    placeholder: t('innovations_offer_personal_participation'),
  },
];

export type SuggestionFileFormResponseType = {
  key: string;
  fileName: string;
};

export type CreateSuggestionFormResponse = Omit<
  CreateSuggestionPropsType,
  'needExpenses' | 'suggestionFiles' | 'problemFiles'
> & {
  needExpenses: SuggestionType['needExpenses'];
  suggestionFiles: SuggestionFileFormResponseType[];
  problemFiles: SuggestionFileFormResponseType[];
};

export const prepareCreatePayload = (
  values: CreateSuggestionFormResponse,
  categoryId: string,
  employeeId: string,
): CreateSuggestionPropsType => {
  const problemFiles = values?.problemFiles.map(({ key, fileName }) => ({
    fileKey: key,
    fileName,
    signs: null,
    description: null,
  }));

  const suggestionFiles = values?.suggestionFiles.map(({ key, fileName }) => ({
    fileKey: key,
    fileName,
    signs: null,
    description: null,
  }));

  return { ...values, problemFiles, suggestionFiles, categoryId, employeeId };
};
