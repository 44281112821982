import React, { FC } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Avatar, Flex, Typography } from 'gazprom-ui-lib';

import Comment from 'components/comment';

import s from './ApprovalItem.module.scss';
import { getAvatarStatusIcon, getStatusTitle } from './approvalItem.utils';

export interface Props {
  withPadding?: boolean;
  firstName?: string;
  lastName?: string;
  division?: string;
  position: string;
  status?: 'WAITING' | 'REJECTED' | 'APPROVED' | 'REVISION';
  finishDate: string;
  dateRequest?: string;
  comment?: string | null;
}

const ApprovalItem: FC<Props> = (props) => {
  const {
    withPadding = false,
    firstName,
    lastName,
    position,
    status = 'WAITING',
    finishDate,
    division,
    dateRequest,
    comment,
  } = props;

  if (!status) return null;

  const showComment = !!comment && (status === 'REJECTED' || status === 'REVISION');

  return (
    <Flex vertical gap="16" className={cn(withPadding && s.wrapper)}>
      <Flex vertical>
        {division && <Typography.Title level={5}>{division}</Typography.Title>}
        {dateRequest && (
          <Typography.Text size="12" type="secondary">
            {`${t('documents_business_trip_approval_request')}: ${dayjs(dateRequest).format(
              'DD.MM.YYYY, HH:mm',
            )}`}
          </Typography.Text>
        )}
      </Flex>

      <Flex vertical>
        <Flex gap="12">
          <div className={s.avatarWithStatus}>
            <div className={cn(s.avatarWithStatusIcon, s[status.toLowerCase()])}>
              {getAvatarStatusIcon(status)}
            </div>
            <Avatar
              className={s.avatar}
              size="40"
              firstName={firstName ?? ''}
              lastName={lastName ?? ''}
            />
          </div>
          <Flex vertical>
            <Typography.Title level={5}>{`${firstName} ${lastName}`}</Typography.Title>
            <Typography.Text type="secondary">{position}</Typography.Text>
          </Flex>
        </Flex>

        {finishDate && (
          <Typography.Text type={status === 'APPROVED' ? 'success' : 'danger'}>
            {getStatusTitle(status, finishDate)}
          </Typography.Text>
        )}
      </Flex>

      {showComment && (
        <Comment>
          <Typography.Text>{comment}</Typography.Text>
        </Comment>
      )}
    </Flex>
  );
};

export default ApprovalItem;
