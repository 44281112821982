import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { formatLeftSeconds } from 'utils/formatLeftSeconds';

import Card from 'containers/card';

interface ProfilePersonalDataHeaderProps {
  personFullName: string;
  visibilityEndDate: string;
  setPersonalDataHidden: Dispatch<SetStateAction<boolean>>;
}

const ProfilePersonalDataHeader: FC<ProfilePersonalDataHeaderProps> = (props) => {
  const { personFullName, setPersonalDataHidden, visibilityEndDate } = props;

  const [leftSeconds, setLeftSeconds] = useState<number>(
    dayjs(visibilityEndDate).diff(dayjs(), 'second'),
  );

  useEffect(() => {
    setTimeout(() => {
      if (leftSeconds > 0) {
        setLeftSeconds(leftSeconds - 1);
      } else {
        setPersonalDataHidden(true);
      }
    }, 1000);
  }, [leftSeconds, setPersonalDataHidden]);

  return (
    <Card>
      <Card.Default
        icon="passport"
        title={personFullName}
        description={t('my_personal_data_visible_for', { time: formatLeftSeconds(leftSeconds) })}
      />
    </Card>
  );
};

export default ProfilePersonalDataHeader;
