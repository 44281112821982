import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { useConfirmCertificateSmsMutation } from 'services/me/meApiService';
import { ConfirmCertificateSmsResponseType } from 'services/me/meApiService.types';

import { Icon } from 'gazprom-ui-lib';

import CodeVerification from 'containers/code-verification';
import { VERIFICATION_MODALS_ENUM } from 'containers/code-verification/code-verification.utils';
import Header from 'containers/header';

interface DocumentsSmsConfirmProps {
  handleModalClose: () => void;
  operationId: string;
}

const DocumentsSmsConfirm: FC<DocumentsSmsConfirmProps> = (props) => {
  const { operationId, handleModalClose } = props;
  const [signConfirm, { isLoading }] = useConfirmCertificateSmsMutation();

  const handleSubmitVerificationCode = async (
    smsCode: string,
  ): Promise<VERIFICATION_MODALS_ENUM> => {
    let statusCode: VERIFICATION_MODALS_ENUM;

    const res = await signConfirm({
      smsCode,
      id: operationId,
    });

    if ('error' in res && 'data' in res.error) {
      // WrongConfirmationCode
      // TooManyAttempts
      // ConfirmationCodeExpired
      const errorCode = (res.error as ConfirmCertificateSmsResponseType).data.code;

      switch (errorCode) {
        case 'WrongConfirmationCode': {
          statusCode = VERIFICATION_MODALS_ENUM.WRONG_CODE;
          break;
        }
        case 'TooManyAttempts': {
          statusCode = VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS;
          break;
        }
        case 'ConfirmationCodeExpired': {
          statusCode = VERIFICATION_MODALS_ENUM.TIME_IS_UP;
          break;
        }
        default: {
          statusCode = VERIFICATION_MODALS_ENUM.ERROR;
        }
      }
    } else {
      statusCode = VERIFICATION_MODALS_ENUM.COMPLETED;
    }

    return statusCode;
  };
  return (
    <CodeVerification
      successConfig={{
        title: t('documents_create_certificate_success_request_title'),
        description: t('documents_create_certificate_success_request_description'),
        onConfirm: handleSubmitVerificationCode,
        closeCodeVerification: handleModalClose,
        isConfirmLoading: isLoading,
      }}>
      <div onClick={handleModalClose}>
        <Icon name="arrowLeft" />
      </div>
      <Header.Label label={t('documents_create_certificate')} />
    </CodeVerification>
  );
};

export default DocumentsSmsConfirm;
