import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Icon, TIconsLiteral, Tag, Typography } from 'gazprom-ui-lib';

import s from './EmploymentTag.module.scss';

interface ProfileEmploymentTagProps extends PropsWithChildren {
  type: 'primary' | 'outline' | 'neutral';
  icon?: TIconsLiteral;
}

const EmploymentTag: FC<ProfileEmploymentTagProps> = (props) => {
  const { type, icon, children } = props;

  return (
    <Tag
      type={type}
      className={cn(s.employmentInfoTag, {
        [s.neutral]: type === 'neutral',
        [s.outline]: type === 'outline',
      })}>
      {icon && <Icon name={icon} />}
      <Typography.Text weight="500" type={type === 'outline' ? 'primary' : undefined}>
        {children}
      </Typography.Text>
    </Tag>
  );
};

export default EmploymentTag;
