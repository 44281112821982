import { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { useGetRenewFamilyTypeQuery } from 'services/top-management/topManagementApiService';

import { Form, Input, Select, Typography } from 'gazprom-ui-lib';

import SelectModal from 'containers/select-modal/selectModal';

import DatePicker from '../../../date-picker';
import { FAMILY_FORM_ENUM } from '../../familyForm.utils';

interface FormHeaderProps {
  index: number | string;
  familyType?: string;
  birthDate?: string;
  surname?: string;
  name?: string;
  patronymic?: string;
}

const FormHeader: FC<FormHeaderProps> = (props) => {
  const { index, birthDate, familyType, name, patronymic, surname } = props;

  const form = Form.useFormInstance();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data: familyRelationDegreeOptions } = useGetRenewFamilyTypeQuery();

  const handleOpenModal = () => setIsModalOpen((prevState) => !prevState);
  const handleSelect = (value: string | number) => {
    form.setFieldValue(`${FAMILY_FORM_ENUM.FAMILY_TYPE}_${index}`, value);
    handleOpenModal();
  };

  return (
    <>
      <Form.Item
        name={`${FAMILY_FORM_ENUM.IS_ENTERED_BY_USER}_${index}`}
        initialValue={true}
        hidden
      />
      <Form.Item
        name={`${FAMILY_FORM_ENUM.SURNAME}_${index}`}
        rules={[formRules.required]}
        initialValue={surname}
        label={<Typography.Title level={5}>{t('common_last_name')}</Typography.Title>}>
        <Input size="large" placeholder={t('common_enter_text')} />
      </Form.Item>
      <Form.Item
        name={`${FAMILY_FORM_ENUM.NAME}_${index}`}
        rules={[formRules.required]}
        initialValue={name}
        label={<Typography.Title level={5}>{t('common_first_name')}</Typography.Title>}>
        <Input size="large" placeholder={t('common_enter_text')} />
      </Form.Item>
      <Form.Item
        name={`${FAMILY_FORM_ENUM.PATRONYMIC}_${index}`}
        rules={[formRules.required]}
        initialValue={patronymic}
        label={<Typography.Title level={5}>{t('common_patronymic')}</Typography.Title>}>
        <Input size="large" placeholder={t('common_enter_text')} />
      </Form.Item>
      <Form.Item
        name={`${FAMILY_FORM_ENUM.FAMILY_TYPE}_${index}`}
        label={
          <Typography.Title level={5}>{t('top_management_relationship_degree')}</Typography.Title>
        }
        rules={[formRules.required]}
        initialValue={familyType}>
        <Select
          open={false}
          placeholder={t('common_select_value')}
          onClick={handleOpenModal}
          options={familyRelationDegreeOptions}
          optionLabelProp="label"
          optionFilterProp="name"
        />
      </Form.Item>
      <DatePicker
        name={`${FAMILY_FORM_ENUM.BIRTH_DATE}_${index}`}
        label={t('common_birthdate')}
        required
        yearConfig={{
          initialMinYear: 1950,
          initialMaxYear: dayjs().year(),
        }}
        initialValue={birthDate}
      />
      <SelectModal
        isModalOpen={isModalOpen}
        name={`${FAMILY_FORM_ENUM.FAMILY_TYPE}_${index}`}
        options={familyRelationDegreeOptions ?? []}
        setIsModalOpen={handleOpenModal}
        submitHandler={handleSelect}
      />
    </>
  );
};

export default FormHeader;
