import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';

import { BiometryType, NativeBiometric } from 'capacitor-native-biometric';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Icon } from 'gazprom-ui-lib';

import { ReactComponent as IconFaceId } from 'assets/icons/faceId.svg';
import { ReactComponent as IconTouchId } from 'assets/icons/touchId.svg';

import AUDIT_EVENTS from 'constants/auditEvents';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import s from './Identifier.module.scss';

interface UseSpecialRightElement {
  biometryType: BiometryType;
  handleRemoveElement: () => void;
  pass: string;
  setError: Dispatch<SetStateAction<string>>;
  onBiometrySuccess: () => void;
}

const useSpecialRightElement = (props: UseSpecialRightElement) => {
  const { handleRemoveElement, biometryType, pass, setError, onBiometrySuccess } = props;
  const showIdentifier = useRef<boolean>(true);

  const { auditError } = useAudit();

  const [isTouchIdInstalled] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_IS_TOUCH_INSTALLED,
    false,
  );
  const [isFaceIdIsInstalled] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_IS_FACE_INSTALLED,
    false,
  );

  const handleIdentifyWithFaceId = useCallback(() => {
    showIdentifier.current = false;
    NativeBiometric.verifyIdentity({
      title: 'Face ID',
    })
      .then(onBiometrySuccess)
      .catch(() => {
        auditError(AUDIT_EVENTS.AUTHORIZATION.BIOMETRY);
        setError(t('identifier_incorrect_face_id'));
      });
  }, [auditError, onBiometrySuccess, setError]);

  const handleIdentifyWithTouchId = useCallback(() => {
    showIdentifier.current = false;
    NativeBiometric.verifyIdentity({
      title: 'Touch ID',
    })
      .then(onBiometrySuccess)
      .catch(() => {
        auditError(AUDIT_EVENTS.AUTHORIZATION.BIOMETRY);
        setError(t('identifier_incorrect_touch_id'));
      });
  }, [auditError, onBiometrySuccess, setError]);

  const specialRightBackspaceElement = {
    label: <Icon name="backspace" className={s.backspace} />,
    onClick: handleRemoveElement,
  };

  const specialRightTouchIdElement = {
    label: <IconTouchId className={s.touchId} />,
    onClick: handleIdentifyWithTouchId,
  };
  const specialRightFaceIdElement = {
    label: <IconFaceId className={s.faceId} />,
    onClick: handleIdentifyWithFaceId,
  };

  const isTouchIdAvailable =
    (biometryType === BiometryType.TOUCH_ID ||
      biometryType === BiometryType.FINGERPRINT ||
      biometryType === BiometryType.MULTIPLE) &&
    isTouchIdInstalled;

  const isFaceIdAvailable =
    (biometryType === BiometryType.FACE_ID || biometryType === BiometryType.FACE_AUTHENTICATION) &&
    isFaceIdIsInstalled;

  useEffect(() => {
    if (showIdentifier.current) {
      if (isTouchIdAvailable) {
        handleIdentifyWithTouchId();
      } else if (isFaceIdAvailable) {
        handleIdentifyWithFaceId();
      }
    }
  }, [handleIdentifyWithFaceId, handleIdentifyWithTouchId, isFaceIdAvailable, isTouchIdAvailable]);

  if (pass.length) {
    return specialRightBackspaceElement;
  }

  if (isTouchIdAvailable) {
    return specialRightTouchIdElement;
  }

  if (isFaceIdAvailable) {
    return specialRightFaceIdElement;
  }
};

export default useSpecialRightElement;
