import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import Card from 'containers/card';

import s from 'pages/documents/documents-statements/containers/page-description/PageDescription.module.scss';

const PageDescription: FC = () => {
  const navigate = useNavigate();

  const handleApplyDocuments = () => {
    navigate(DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE);
  };

  return (
    <Card>
      <Card.Default
        title={t('documents_statements')}
        description={t('documents_statements_description')}
        icon="documentSign"
        iconPadding="8"
      />
      <Button
        leftIcon="plus"
        fullWidth
        size="large"
        onClick={handleApplyDocuments}
        className={s.button}>
        {t('documents_apply')}
      </Button>
    </Card>
  );
};

export default PageDescription;
