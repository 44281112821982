import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useSecureStorage from 'utils/useSecureStorage.hook';

import {
  useGetQuestionsBySurveyIdQuery,
  useGetSurveyByIdQuery,
  useGetSurveyStatusQuery,
  useGetSurveysAnswersMutation,
  useSendSurveyAnswerMutation,
} from 'services/survey/surveyApiService';
import { setSurveysValues } from 'slices/surveySlice';
import { useAppDispatch } from 'store';

import { Button, ButtonProps, Form } from 'gazprom-ui-lib';

import { Divider } from 'components/divider';
import Card from 'containers/card';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';
import { ValuesType } from 'types/surveys.types';

import SurveysQuestionsNotSupported from '../surveys-questions/surveys-questions-not-supported';
import SurveysQuestion from '../surveys-questions/surveysQuestion';
import SurveysSent from '../surveys-sent';
import { OriginalObject, deserializeAnswers } from '../surveys.utils';
import SurveysSingleHeader from './surveys-single-header';
import {
  countAnswers,
  countFilledRequiredFields,
  countRequiredQuestions,
  headerChildren,
} from './surveysSingle.utils';

const SurveysSingle = () => {
  const [form] = Form.useForm();
  const allFormValues = Form.useWatch([], form);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { anketologId, revisionId, anketaLinkId } = useParams<{
    anketologId: string;
    revisionId: string;
    anketaLinkId: string;
  }>();
  const [storageValue, setStorageValue] = useSecureStorage(
    `${SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SURVEY_PROGRESS_ID}_${anketologId}_${revisionId}_${anketaLinkId}`,
    '',
  );
  const [selectedEmployee] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE,
    '',
  );

  const [isQuestionNotSupported, setIsQuestionNotSupported] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);

  const { data: surveyStatusData, isLoading: surveyStatusIsLoading } = useGetSurveyStatusQuery({
    surveyId: anketologId!,
    revisionId: revisionId!,
    anketaLinkId: anketaLinkId!,
  });
  const { data: surveyResponse, isError: surveyResponseLoading } = useGetSurveyByIdQuery({
    survey_id: anketologId!,
  });
  const { data: questionsResponse, isLoading: questionsResponseLoading } =
    useGetQuestionsBySurveyIdQuery({ revision_id: revisionId! });
  const [sendAnswer, sendAnswerResponse] = useSendSurveyAnswerMutation();
  const [getAnswers, getAnswersResponse] = useGetSurveysAnswersMutation();

  const questions = questionsResponse?.struct.pages[0].questions;
  const initialValues = storageValue ? JSON.parse(storageValue) : {};
  const requiredQuestions = countRequiredQuestions(questions ?? []);
  const isSurveyCompleted = surveyStatusData?.isCompleted;
  const isLoading =
    questionsResponseLoading ||
    surveyResponseLoading ||
    surveyStatusIsLoading ||
    getAnswersResponse.isLoading;
  let questionNumber = 0;

  const filledRequiredCount = useMemo(
    () => countFilledRequiredFields(allFormValues, requiredQuestions ?? []),
    [allFormValues, requiredQuestions],
  );
  const isRequiredFieldsFilled = useMemo(() => {
    //* проверяем заполнены ли все поля для разблокировки кнопки
    return requiredQuestions.length === filledRequiredCount;
  }, [filledRequiredCount, requiredQuestions.length]);

  useEffect(() => {
    if (isSurveyCompleted && !surveyStatusIsLoading) {
      getAnswers({ survey_id: anketologId!, ap_name: 'link_id', ap_value: anketaLinkId! })
        .unwrap()
        .then((res) => dispatch(setSurveysValues({ isCompleted: true, answers: res[0] })));
    }

    return () => {
      dispatch(setSurveysValues({ isCompleted: false, answers: null }));
    };
  }, [
    storageValue,
    isSurveyCompleted,
    surveyStatusIsLoading,
    anketologId,
    anketaLinkId,
    getAnswers,
    dispatch,
  ]);

  const handleFormChange = (_: ValuesType, allValues: ValuesType) => {
    setTimeout(() => {
      setStorageValue(JSON.stringify(allValues));
    }, 0);
  };

  const onFinish = (values: OriginalObject) => {
    const allFields = form.getFieldsValue();
    const completedAnswerCount = countAnswers(allFields);

    const responseObject = deserializeAnswers(values, {
      survey_id: surveyResponse!.id.toString(),
      params: {
        ap_employee_id: selectedEmployee,
        ap_anketa_link_id: anketaLinkId!,
        ap_link_id: anketaLinkId!,
        completedAnswerCount,
      },
    });

    sendAnswer(responseObject)
      .unwrap()
      .then(() => setIsModalActive(true));
  };

  const clickHandler = useCallback(() => {
    setIsModalActive(!isModalActive);
    navigate(-1);
  }, [setIsModalActive, isModalActive, navigate]);

  const close = () => navigate(-1);

  const buttonProps: ButtonProps = {
    type: isSurveyCompleted ? 'secondary' : 'primary',
    htmlType: isSurveyCompleted ? 'button' : 'submit',
    disabled: !isSurveyCompleted && !isRequiredFieldsFilled,
    loading: sendAnswerResponse.isLoading,
    rightIcon: isSurveyCompleted ? undefined : 'arrowRight',
    children: isSurveyCompleted ? t('common_close') : t('common_send'),
    onClick: isSurveyCompleted ? close : undefined,
  };

  if (isQuestionNotSupported) {
    return <SurveysQuestionsNotSupported />;
  }

  return (
    <WithNavigation headerChildren={headerChildren}>
      <WithBackground>
        <WithLoader isLoading={isLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={handleFormChange}
            initialValues={initialValues}>
            <SurveysSingleHeader
              title={surveyResponse?.settings.name!}
              completedDate={surveyStatusData?.completedDate!}
              isCompleted={isSurveyCompleted!}
              storageValue={storageValue}
              allQuestionCount={surveyStatusData?.questionsCount}
            />
            <Card withPadding>
              {questions?.map((question, ind, arr) => {
                const isDividerVisible = ind < arr.length - 1;
                questionNumber = question.type !== 'html' ? questionNumber + 1 : questionNumber;

                return (
                  <Fragment key={question.id}>
                    <SurveysQuestion
                      {...question}
                      no={questionNumber}
                      key={question.id}
                      setIsQuestionNotSupported={setIsQuestionNotSupported}
                    />
                    {isDividerVisible && <Divider />}
                  </Fragment>
                );
              })}
              <Form.Item>
                <Button {...buttonProps} size="large" fullWidth />
              </Form.Item>
            </Card>
          </Form>
          <SurveysSent
            isActive={isModalActive}
            onClickHandler={clickHandler}
            title="survey_answers_sent"
            description="survey_thank_you"
            icon="ok"
          />
        </WithLoader>
      </WithBackground>
    </WithNavigation>
  );
};

export default SurveysSingle;
