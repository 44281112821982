import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface MySalaryModalProps {
  open: boolean;
  handleClose: () => void;
  isSuccess?: boolean;
  selectedMonth?: string;
  selectedYear?: number;
}

const MySalaryModal: FC<MySalaryModalProps> = (props) => {
  const { open, handleClose, isSuccess, selectedYear, selectedMonth } = props;

  return (
    <ModalDefault open={open} onCancel={handleClose} width={300}>
      <ModalDefault.Icon
        name={isSuccess ? 'ok' : 'attentionFilled'}
        type={isSuccess ? 'success' : 'danger'}
      />
      <ModalDefault.Title>
        {t(isSuccess ? 'payslips_requested' : 'payslips_request_failed')}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(isSuccess ? 'payslips_requested_description' : 'payslips_request_failed_description', {
          year: selectedYear,
          month: selectedMonth,
        })}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button onClick={handleClose} fullWidth size="large">
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default MySalaryModal;
