import MySalary from 'pages/my-salary';
import Profile from 'pages/profile';
import ProfileAuth from 'pages/profile/profile-auth';
import ProfileAuthChangePin from 'pages/profile/profile-auth/profile-auth-change-pin';
import ProfileCompany from 'pages/profile/profile-company';
import ProfilePersonalData from 'pages/profile/profile-personal-data';
import ProfileSchedule from 'pages/profile/profile-schedule';
import Vacation from 'pages/vacation';
import Agreed from 'pages/vacation/containers/agreed';
import VacationEmployee from 'pages/vacation/vacation-employee';
import VacationEmployees from 'pages/vacation/vacation-employees';

import { RouteConfig } from '../routes';
import {
  PROFILE_AUTH_CHANGE_PIN_ROUTE,
  PROFILE_AUTH_ROUTE,
  PROFILE_COMPANY_ROUTE,
  PROFILE_EMPLOYEE_VACATION_ROUTE,
  PROFILE_MY_SALARY_PAYSLIP_ROUTE,
  PROFILE_MY_SALARY_ROUTE,
  PROFILE_PERSONAL_DATA_ROUTE,
  PROFILE_ROUTE,
  PROFILE_SCHEDULE_ROUTE,
  PROFILE_VACATION_AGREED_WITH_ID_ROUTE,
  PROFILE_VACATION_EMPLOYEES_ROUTE,
  PROFILE_VACATION_ROUTE,
} from './list';

export const profileRoutes: RouteConfig[] = [
  {
    path: PROFILE_ROUTE,
    element: <Profile />,
  },
  {
    path: PROFILE_COMPANY_ROUTE,
    element: <ProfileCompany />,
  },
  {
    path: PROFILE_SCHEDULE_ROUTE,
    element: <ProfileSchedule />,
  },
  {
    path: PROFILE_MY_SALARY_ROUTE,
    element: <MySalary />,
  },
  {
    path: PROFILE_MY_SALARY_PAYSLIP_ROUTE,
    element: <MySalary />,
  },
  {
    path: PROFILE_PERSONAL_DATA_ROUTE,
    element: <ProfilePersonalData />,
  },
  {
    path: PROFILE_VACATION_ROUTE,
    element: <Vacation />,
  },
  {
    path: PROFILE_AUTH_ROUTE,
    element: <ProfileAuth />,
  },
  {
    path: PROFILE_AUTH_CHANGE_PIN_ROUTE,
    element: <ProfileAuthChangePin />,
  },
  {
    path: PROFILE_VACATION_EMPLOYEES_ROUTE,
    element: <VacationEmployees />,
  },
  {
    path: PROFILE_VACATION_AGREED_WITH_ID_ROUTE,
    element: <Agreed />,
  },
  {
    path: PROFILE_EMPLOYEE_VACATION_ROUTE,
    element: <VacationEmployee />,
  },
];
