import React, { FC, Fragment, memo } from 'react';

import { Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import { AttributeDefaultProps } from '../attribute.utils';
import s from './AttributeItemWrapper.module.scss';

export interface AttributeItemWrapperProps extends AttributeDefaultProps {}

const AttributeItemWrapper: FC<AttributeItemWrapperProps> = (props) => {
  const { id, name, tooltip, children, isPublish } = props;

  if (isPublish) {
    return (
      <div key={id}>
        <div className={s.top}>
          {name && (
            <Typography.Title level={5} className={s.title}>
              {name}
            </Typography.Title>
          )}
          {!!tooltip && (
            <Tooltip title={tooltip}>
              <div>
                <Icon name="info" />
              </div>
            </Tooltip>
          )}
        </div>
        {children}
      </div>
    );
  }

  return <Fragment key={id}></Fragment>;
};

export default memo(AttributeItemWrapper);
