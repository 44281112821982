import React, { useState } from 'react';

import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import WithBackground from 'containers/wrappers/with-background';

import s from './HomeNewsDetails.module.scss';
import HomeDetailsComments from './home-news-details-comments';
import HomeNewsDetailsContent from './home-news-details-content';
import useGetData from './useGetData.hook';

const HomeNewsDetails = () => {
  const location = useLocation();
  const params = useParams<{ newsCode: string }>();

  const [isLiked, setIsLiked] = useState<boolean>(false);

  const isNewsDetails = !!params.newsCode && location?.state?.type === 'news';

  const { data, isFetching } = useGetData({ setIsLiked, isNewsDetails });

  const articleData = data && 'article' in data ? data.article : data;
  const meeting = data && 'meeting' in data ? data.meeting : undefined;
  const commentsForId = data && 'article' in data ? data.article.code : data?.code;
  const isParticipant = data && 'isParticipant' in data ? data.isParticipant : false;

  const showComments = !!commentsForId && !isFetching;

  return (
    <WithBackground className={cn(s.wrapper, { [s.hidden]: !params.newsCode })}>
      <HomeNewsDetailsContent
        isNewsDetails={isNewsDetails}
        setIsLiked={setIsLiked}
        isLiked={isLiked}
        isFetching={isFetching}
        articleData={articleData}
        meeting={meeting}
        isParticipant={isParticipant}
      />
      {showComments && <HomeDetailsComments id={commentsForId} />}
    </WithBackground>
  );
};

export default HomeNewsDetails;
