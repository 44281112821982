import { SignPropsType } from 'services/me/meApiService.types';

import { KEDO_COPY_FILE_TYPES } from 'types/documents.types';

export type DocumentsSignListsFlowType = 'businessStrip' | '';

export const prepareRequestBody = (
  fileKeys: string[],
  taskId?: string,
  workTourId?: string,
  taskType?: string,
): SignPropsType => {
  let requestBody: SignPropsType = { fileKeys };

  if (taskType) {
    requestBody.type = taskType;
  }

  if (taskId && taskType === KEDO_COPY_FILE_TYPES.KEDO_TASK) {
    requestBody.taskId = taskId;
  }

  if (workTourId && taskType === KEDO_COPY_FILE_TYPES.WORK_TOUR) {
    requestBody.workTourId = workTourId;
  }

  return requestBody;
};
