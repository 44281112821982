import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import { formatLeftSeconds } from 'utils/formatLeftSeconds';

import { Button, Flex, Form, Icon, Input, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import Header from '../header';
import s from './CodeVerification.module.scss';
import CodeVerificationCompletedModal from './code-verification-completed';
import CodeVerificationErrorModal from './code-verification-error';
import CodeVerificationExceededAttemptsModal from './code-verification-exceeded-attempts';
import CodeVerificationTimeIsUpModal from './code-verification-time-is-up';
import { INITIAL_TIME_TO_CONFIRM, VERIFICATION_MODALS_ENUM } from './code-verification.utils';

export type CodeVerificationSuccessModalProps = PropsWithChildren & {
  successConfig: {
    title: string;
    description: string;
    onConfirm: (smsCode: string) => Promise<VERIFICATION_MODALS_ENUM>;
    closeCodeVerification: () => void;
    handleTimeUp?: () => void;
    isConfirmLoading: boolean;
    icon?: TIconsLiteral;
  };
};

const CodeVerification: FC<CodeVerificationSuccessModalProps> = (props) => {
  const { successConfig, children } = props;

  const {
    title,
    description,
    onConfirm,
    closeCodeVerification,
    handleTimeUp,
    isConfirmLoading,
    icon,
  } = successConfig;

  const { user } = useAuth();

  const [isError, setIsError] = useState<boolean>(false);
  const [codeValue, setCodeValue] = useState<string>('');

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length <= 6) {
      setCodeValue(e.target.value.replace(/\D/g, ''));
    }
  };

  const [activeModal, setActiveModal] = useState<null | VERIFICATION_MODALS_ENUM>(null);
  const [leftSeconds, setLeftSeconds] = useState<number>(INITIAL_TIME_TO_CONFIRM);

  useEffect(() => {
    setTimeout(() => {
      if (leftSeconds > 0) {
        setLeftSeconds(leftSeconds - 1);
      } else {
        setActiveModal(VERIFICATION_MODALS_ENUM.TIME_IS_UP);
      }
    }, 1000);
  }, [leftSeconds]);

  const handleModalClose = () => {
    setActiveModal(null);
  };

  const handleConfirm = async () => {
    const statusCode = await onConfirm(codeValue);

    switch (statusCode) {
      case VERIFICATION_MODALS_ENUM.WRONG_CODE: {
        setIsError(true);
        return;
      }
      case VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS: {
        setActiveModal(VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS);
        return;
      }
      case VERIFICATION_MODALS_ENUM.TIME_IS_UP: {
        setActiveModal(VERIFICATION_MODALS_ENUM.TIME_IS_UP);
        return;
      }
      case VERIFICATION_MODALS_ENUM.COMPLETED: {
        setActiveModal(VERIFICATION_MODALS_ENUM.COMPLETED);
        return;
      }
      default: {
        setActiveModal(VERIFICATION_MODALS_ENUM.ERROR);
        return;
      }
    }
  };

  return (
    <>
      <Form className={s.form} onFinish={handleConfirm}>
        <Header className={s.formHeader}>{children}</Header>
        <Flex className={s.formContent}>
          <div className={s.icon}>
            <Icon name="key" />
          </div>
          <Typography.Title level={3}>{t('documents_sign_code_title')}</Typography.Title>
          <Input
            placeholder="000 000"
            size="large"
            value={codeValue.replace(/(\d{3})(\d{3})/, '$1 $2')}
            className={s.input}
            onChange={handleCodeChange}
          />
          {isError && (
            <Typography.Text type="danger" weight="500">
              {t('common_wrong_code')}
            </Typography.Text>
          )}
          <Flex vertical align="center">
            <Flex gap="4">
              <Typography.Text type="secondary" weight="500">
                {t('documents_sign_code_sent')}
              </Typography.Text>
              <Typography.Text type="primary" weight="500">
                +7 *** *** {user?.profile?.preferred_username?.slice(-4)?.trim()}
              </Typography.Text>
            </Flex>
            <Flex gap="4">
              <Typography.Text type="secondary" weight="500">
                {t('documents_sign_code_expiration')}
              </Typography.Text>
              <Typography.Text weight="500">{formatLeftSeconds(leftSeconds)}</Typography.Text>
            </Flex>
          </Flex>
        </Flex>
        <Button
          htmlType="submit"
          leftIcon="ok"
          className={s.button}
          size="large"
          loading={isConfirmLoading}
          disabled={codeValue.length !== 6}>
          {t('common_confirm')}
        </Button>
      </Form>
      <CodeVerificationTimeIsUpModal
        isOpen={activeModal === VERIFICATION_MODALS_ENUM.TIME_IS_UP}
        handleClose={() => {
          handleModalClose();
          closeCodeVerification();

          if (handleTimeUp) {
            handleTimeUp();
          }
        }}
      />
      <CodeVerificationExceededAttemptsModal
        isOpen={activeModal === VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS}
        handleClose={handleModalClose}
      />
      <CodeVerificationErrorModal
        isOpen={activeModal === VERIFICATION_MODALS_ENUM.ERROR}
        handleClose={handleModalClose}
      />
      <CodeVerificationCompletedModal
        icon={icon}
        isOpen={activeModal === VERIFICATION_MODALS_ENUM.COMPLETED}
        handleClose={closeCodeVerification}
        title={title}
        description={description}
      />
    </>
  );
};

export default CodeVerification;
