export const getPlacesLeft = (
  maxEmployees: number,
  countMembers: number,
  initialIsParticipant: boolean,
  isParticipant: boolean,
): number => {
  const placesLeft = maxEmployees - countMembers;

  if (initialIsParticipant === isParticipant) return placesLeft;

  if (initialIsParticipant) {
    return isParticipant ? placesLeft : placesLeft + 1;
  }
  return isParticipant ? placesLeft - 1 : placesLeft;
};
