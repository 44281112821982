import React, { FC } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { getProfileVacationAgreedRoute } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { useGetVacationQuery } from 'services/me/meApiService';

import { Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { VacationType } from 'types/vacation.types';

import SingleVacation from '../containers/single-vacation';
import { getEmployeesVacations } from '../vacation-employees/vacationEmployees.utils';
import s from './VacationEmployee.module.scss';

const DEFAULT_REQUEST_BODY = { page: 1, size: 500 };

const VacationEmployee: FC = () => {
  const { employeeId, divisionId, positionId } = useParams<{
    divisionId: string;
    employeeId: string;
    positionId: string;
  }>();
  const location = useLocation();

  const { data, isFetching } = useGetVacationQuery(
    {
      personIds: employeeId,
      divisionIds: divisionId,
      positionIds: positionId,
      ...DEFAULT_REQUEST_BODY,
    },
    { skip: !employeeId || !divisionId },
  );

  const headerChildren = (
    <>
      <Header.GoBack to={getProfileVacationAgreedRoute(location?.state?.divisionId)} />
      <Header.Label label={t('vacation_employee_title')} />
    </>
  );

  const renderItem = (item: VacationType, index: number) => (
    <SingleVacation key={`${item.endDate}-${index}`} {...item} />
  );

  const { person, position } = data?.content[0] || {};

  const vacations = getEmployeesVacations(data?.content);

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isFetching}>
            <Card>
              <Bio
                avatarSize="48"
                firstName={person?.firstName}
                lastName={person?.lastName}
                staffName={position?.name}
              />
            </Card>
            <Card withPadding={false}>
              <div className={s.title}>
                <Typography.Title level={4}>{t('vacation_agreed')}</Typography.Title>
              </div>
              <div>{vacations?.map(renderItem)}</div>
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default VacationEmployee;
