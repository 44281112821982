import React, { FC, memo, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Form, Input } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeIntegerProps extends AttributeDefaultProps {}

const AttributeInteger: FC<AttributeIntegerProps> = (props) => {
  const { id, isMandatory, placeholder, value, isReadonly } = props;

  const form = Form.useFormInstance();

  const handleInputChange = (value: string) => {
    return value.replace(/\D/g, '');
  };

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, Number(value));
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item
        name={id}
        rules={isMandatory ? [formRules.required] : []}
        normalize={handleInputChange}>
        <Input
          size="large"
          placeholder={placeholder ?? t('common_enter_number')}
          disabled={isReadonly}
        />
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeInteger);
