import { t } from 'tools/i18n';

import { LanguagesType } from 'types/topManagement.types';

export enum LANGUAGE_LEVELS {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

export enum LANGUAGES_FORM_ENUM {
  LANGUAGE = 'language',
  LEVEL = 'level',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}

export const radioOptions = [
  {
    label: t('top_management_languages_level_free'),
    value: LANGUAGE_LEVELS.BEGINNER,
  },
  {
    label: t('top_management_languages_level_can_speak'),
    value: LANGUAGE_LEVELS.INTERMEDIATE,
  },
  {
    label: t('top_management_languages_level_with_dictionary'),
    value: LANGUAGE_LEVELS.ADVANCED,
  },
];

export type MappedLanguagesType = Partial<LanguagesType> & { id: number };
