import React, { FC } from 'react';

import { t } from 'tools/i18n';
import useUploadFile, { UploadedFileType } from 'utils/useUploadFile.hook';

import { Form, Icon, RcFile, Typography, Upload } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import WithLoader from 'containers/wrappers/with-loader';

import s from './UploadFile.module.scss';

interface UploadFileProps {
  name: string;
  customFileSet?: (props: UploadedFileType) => void;
  className?: string;
  skipMediaPrefix?: boolean;
  initialValue?: UploadedFileType;
  disabled?: boolean;
  handleSaveDraft?: () => void;
}

const AVAILABLE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

const UploadFile: FC<UploadFileProps> = (props) => {
  const {
    handleSaveDraft,
    name,
    customFileSet,
    className,
    skipMediaPrefix = false,
    initialValue,
    disabled,
  } = props;

  const form = Form.useFormInstance();

  const setErrors = (errors: string[]) =>
    form.setFields([
      {
        name,
        errors,
      },
    ]);

  const handleFileSet = (params: UploadedFileType) => {
    if (customFileSet) {
      customFileSet(params);
    } else {
      form.setFieldValue(name, { ...params, type: 'FILE' });
    }

    if (handleSaveDraft) {
      handleSaveDraft();
    }
  };

  const { isLoading, fileData, customRequest } = useUploadFile({
    skipMediaPrefix,
    setErrors,
    handleFileSet,
    initialValue,
  });

  const beforeUpload = (file: RcFile) => {
    const isCorrectFormat = AVAILABLE_TYPES.includes(file.type);
    const moreThanLimit = file.size / 1024 / 1024 > 5;

    if (!isCorrectFormat) {
      setErrors([t('validation_file_incorrect_extension')]);
      return false;
    } else if (moreThanLimit) {
      setErrors([t('validation_file_max_size', { size: '5 MB' })]);
      return false;
    } else {
      setErrors([]);
      return file;
    }
  };

  const resetHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => {
    event.stopPropagation();
    const files: UploadedFileType | UploadedFileType[] = form.getFieldValue(name);
    if (Array.isArray(files)) {
      const filteredByKey = files.filter((file) => file.key !== key);

      form.setFieldValue(name, filteredByKey);
      return;
    }

    form.resetFields([name]);

    if (handleSaveDraft) {
      handleSaveDraft();
    }
  };

  return (
    <div>
      <Upload
        className={className}
        disabled={disabled || isLoading}
        customRequest={customRequest}
        showUploadList={false}
        beforeUpload={beforeUpload}>
        {fileData.url ? (
          <div className={s.preview}>
            <button
              type="button"
              className={s.reset}
              onClick={(event) => resetHandler(event, fileData.key as UploadedFileType['key'])}>
              <Icon name="delete" />
            </button>
            <Icon name="image" />
            <Typography.Text size="10" type="primary">
              {fileData?.fileName}
            </Typography.Text>
          </div>
        ) : (
          <WithLoader
            isLoading={isLoading}
            loader={
              <div className={s.preview}>
                <UniversalLoader className={s.loader} />
              </div>
            }>
            <div className={s.uploadButton}>
              <Icon name="plus" size={24} />
              <Typography.Text size="14" weight="500" type="primary">
                {t('common_upload')}
              </Typography.Text>
            </div>
          </WithLoader>
        )}
      </Upload>
    </div>
  );
};

export default UploadFile;
