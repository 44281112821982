import { ExperienceType } from 'types/topManagement.types';

export enum WORK_EXPERIENCE_FORM_ENUM {
  NAME = 'name',
  ADDRESS = 'address',
  PHONE = 'phone',
  ACTIVITY = 'activity',
  DATE_START = 'dateStart',
  DATE_FINISH = 'dateFinish',
  POSITION = 'position',
  FUNCTIONS = 'functions',
  REASON = 'reasonForDismissal',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}

export type MappedExperienceType = Partial<ExperienceType> & { id: number };
