import React, { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './RegisterSuccess.module.scss';

const RegisterSuccess = () => {
  const [, setSearchParams] = useSearchParams();

  const handleButton = useCallback(() => {
    setSearchParams('');
  }, [setSearchParams]);

  return (
    <WithStatusBar>
      <WithGzpTopLogo className={s.wrapper}>
        <div className={s.inner}>
          <div className={s.check}>
            <Icon name="ok" />
          </div>
          <Typography.Title level={3}>{t('register_success_title')}</Typography.Title>
          <Typography.Text size="16" type="secondary">
            {t('register_success_description')}
          </Typography.Text>
        </div>
        <Button
          rightIcon="arrowRight"
          fullWidth
          onClick={handleButton}
          size="large"
          className={s.button}>
          {t('identifier_set_pin_code')}
        </Button>
      </WithGzpTopLogo>
    </WithStatusBar>
  );
};
export default RegisterSuccess;
