import React from 'react';

import dayjs from 'dayjs';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetCurrentCertificateQuery } from 'services/me/meApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './DocumentsCertificate.module.scss';

const DocumentsCertificate = () => {
  const { data: certificate, isFetching: isCertificateFetching } = useGetCurrentCertificateQuery();

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t('documents_viewing_certificate')} />
    </>
  );

  const loader = (
    <div className={s.loader}>
      <UniversalLoader />
    </div>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <WithLoader loader={loader} isLoading={isCertificateFetching}>
              <Typography.Title level={4}>{t('documents_electronic_certificate')}</Typography.Title>
              {certificate?.latestKcr?.state === 'ACTIVE' && (
                <div className={s.tag}>
                  <Icon name="ok" size={16} />
                  <Typography.Text>{t('common_active')}</Typography.Text>
                </div>
              )}
              <div className={s.content}>
                <div>
                  <Typography.Text type="secondary">{t('common_validity')}</Typography.Text>
                  <Typography.Title level={5}>
                    {t('common_not_earlier', {
                      date: dayjs(certificate?.latestKcr?.validFrom).format(
                        'DD MMMM YYYY, в HH:mm',
                      ),
                    })}
                  </Typography.Title>
                  <Typography.Title level={5}>
                    {t('common_not_later', {
                      date: dayjs(certificate?.latestKcr?.validTill).format(
                        'DD MMMM YYYY, в HH:mm',
                      ),
                    })}
                  </Typography.Title>
                </div>
                <div>
                  <Typography.Text type="secondary">{t('common_owner')}</Typography.Text>
                  <Typography.Title level={5}>
                    {certificate?.latestKcr?.certDetails?.subject}
                  </Typography.Title>
                </div>
                <div>
                  <Typography.Text type="secondary">{t('common_publisher')}</Typography.Text>
                  <Typography.Title level={5}>
                    {certificate?.latestKcr?.certDetails?.issuer}
                  </Typography.Title>
                </div>
                <div>
                  <Typography.Text type="secondary">
                    {t('documents_certificate_key_usage')}
                  </Typography.Text>
                  <Typography.Title level={5}>
                    {certificate?.latestKcr?.certDetails?.keyUsage?.join(', ')}
                  </Typography.Title>
                </div>
                <div>
                  <Typography.Text type="secondary">
                    {t('documents_certificate_key_extend_usage')}
                  </Typography.Text>
                  <Typography.Title level={5}>
                    {certificate?.latestKcr?.certDetails?.extendedKeyUsage?.join(', ')}
                  </Typography.Title>
                </div>
                <div>
                  <Typography.Text type="secondary">{t('common_version')}</Typography.Text>
                  <Typography.Title level={5}>
                    {certificate?.latestKcr?.serialNumber}
                  </Typography.Title>
                </div>
              </div>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsCertificate;
