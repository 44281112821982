import React, { useEffect, useState } from 'react';

import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE,
  MORE_SUGGESTION_CORRECTION_ROUTE,
  MORE_SUGGESTION_ROUTE,
} from 'routes/more/list';
import { Trans, t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useCreateSuggestionMutation,
  useGetSuggestionCategoriesQuery,
  useLazyGetSuggestionByIdQuery,
  useUpdateSuggestionByIdMutation,
} from 'services/me/suggestion/suggestionApiService';

import { Button, ModalDefault, Typography, handleRequest } from 'gazprom-ui-lib';

import Attribute from 'containers/attribute';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './MoreSuggestionCreate.module.scss';
import Attributes from './containers/attributes';
import { CreateSuggestionFormResponse, prepareCreatePayload } from './moreSuggestionCreate.utils';

const MoreSuggestionCreate = () => {
  const navigate = useNavigate();
  const params = useParams<{ categoryId: string; suggestionId?: string }>();

  const isCorrection = !!useMatch(MORE_SUGGESTION_CORRECTION_ROUTE);

  const [selectedEmployee] = useFullSelectedEmployee();

  const [getSuggestion, { data: suggestion, isLoading: isSuggestionLoading }] =
    useLazyGetSuggestionByIdQuery();

  useEffect(() => {
    const shouldGetSuggestion = isCorrection && !!params?.suggestionId;

    if (shouldGetSuggestion) {
      getSuggestion({ id: params.suggestionId });
    }
  }, [isCorrection, getSuggestion, params.suggestionId]);

  const { data: categories } = useGetSuggestionCategoriesQuery();
  const { name, id: categoryId } =
    categories?.find((category) => category.id === params.categoryId) ?? {};

  const [createSuggestion, { isLoading }] = useCreateSuggestionMutation();
  const [updateSuggestion, { isLoading: isSuggestionUpdating }] = useUpdateSuggestionByIdMutation();

  const [modalType, setModalType] = useState<'' | 'created' | 'error'>('');

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE} />
      <Header.Label label={t('innovations_offer_idea')} />
    </>
  );

  const onFinish = (values: CreateSuggestionFormResponse) => {
    const onSuccess = () => {
      setModalType('created');
    };

    if (selectedEmployee?.id) {
      const preparedData = prepareCreatePayload(values, categoryId!, selectedEmployee?.id!);

      if (isCorrection && suggestion?.id) {
        const { employeeId, ...body } = preparedData;

        updateSuggestion({ body, id: suggestion.id }).then(handleRequest({ onSuccess }));
      } else {
        createSuggestion(preparedData).then(handleRequest({ onSuccess }));
      }
    }
  };

  const handleModalClose = () => {
    setModalType('');
    navigate(MORE_SUGGESTION_ROUTE);
  };

  return (
    <WithStatusBar>
      <WithNavigation showNavbar={false} headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isSuggestionLoading}>
            <Card withPadding>
              <Typography.Title level={4}>{name}</Typography.Title>
              <Typography.Text size="14" type="secondary">
                {t('innovations_create_description')}
              </Typography.Text>
            </Card>

            <Attribute onFinish={onFinish}>
              <Attributes suggestion={suggestion} />

              <div className={s.fixedCard}>
                <Button
                  loading={isLoading || isSuggestionUpdating}
                  htmlType="submit"
                  fullWidth
                  rightIcon="arrowRight"
                  size="large"
                  type="primary">
                  {t('common_send')}
                </Button>
              </div>
            </Attribute>

            <ModalDefault open={modalType === 'created'} onCancel={handleModalClose}>
              <ModalDefault.Icon name="ok" type="success" />
              <ModalDefault.Title>{t('innovation_created_title')}</ModalDefault.Title>
              <ModalDefault.Description>
                <Trans i18nKey="innovation_created_description">
                  <Typography.Text type="secondary" size="16" weight="500"></Typography.Text>
                </Trans>
              </ModalDefault.Description>
              <ModalDefault.Actions>
                <Button size="large" fullWidth onClick={handleModalClose}>
                  {t('common_ok')}
                </Button>
              </ModalDefault.Actions>
            </ModalDefault>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreSuggestionCreate;
