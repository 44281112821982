import React, { FC, memo, useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import { normalizeFile } from 'utils/normalizeFile';

import { Form } from 'gazprom-ui-lib';

import UploadFile from 'components/upload-file';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeFileProps extends AttributeDefaultProps {}

const AttributeFile: FC<AttributeFileProps> = (props) => {
  const { id, isMandatory, isReadonly, value: initialFileValue, name, files, onSaveDraft } = props;

  const form = Form.useFormInstance();
  const value = Form.useWatch(id);

  const handleSaveDraft = () => {
    if (onSaveDraft) {
      onSaveDraft(form);
    }
  };

  useEffect(() => {
    const isDefaultValueCanBeSet = !!initialFileValue && form && !!files?.length;
    if (isDefaultValueCanBeSet) {
      const initialFile = files?.find((file) => file.attributeId === id);

      if (initialFile) {
        form.setFieldValue(id, {
          fileName: initialFileValue,
          key: initialFile.key,
          name: initialFileValue,
          type: 'FILE',
          url: initialFile.key,
        });
      }
    }
  }, [files, form, id, initialFileValue]);

  return (
    <AttributeItemWrapper
      {...props}
      // TODO узнать откуда логика с PETITION, судя по всему это надо выпилить
      // @ts-ignore
      name={initialFileValue?.[0]?.type === 'PETITION' ? t('common_statement') : name}>
      <Form.Item
        name={id}
        rules={isMandatory ? [formRules.required] : []}
        valuePropName="fileList"
        getValueFromEvent={normalizeFile}>
        <UploadFile
          handleSaveDraft={handleSaveDraft}
          name={id}
          skipMediaPrefix
          initialValue={value}
          disabled={isReadonly}
        />
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeFile);
