import React, { Dispatch, FC, SetStateAction } from 'react';

import cn from 'classnames';

import s from './MoreFaqButtonOptions.module.scss';

interface FaqButtonOptionsProps {
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  categories?: string[];
}

const MoreFaqButtonOptions: FC<FaqButtonOptionsProps> = (props) => {
  const { categories = [], setSelectedCategory, selectedCategory } = props;

  const renderButton = (category: string) => {
    return (
      <button
        className={cn(s.categoryButton, selectedCategory === category ? s.selected : s.default)}
        key={category}
        onClick={() => setSelectedCategory(category)}>
        {category}
      </button>
    );
  };

  return <div className={s.buttonOptionsWrapper}>{categories?.map(renderButton)}</div>;
};

export default MoreFaqButtonOptions;
