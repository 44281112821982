export const getPlayerHeight = (width: number) => (width / 16) * 9;

const youtubeVideoIdRegExp =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const getYoutubeVideoId = (url: string) => {
  const match = url.match(youtubeVideoIdRegExp);

  const videoId = match?.[7];

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&origin=${url}`;
  }
};
