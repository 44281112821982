import React, { FC, PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';
import { MORE_FEEDBACK_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

const ProfileFeedbackCreateViewWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const location = useLocation();

  const header = (
    <>
      <Header.GoBack to={MORE_FEEDBACK_ROUTE} state={location.state} />
      <Header.Label label={t('feedback_write_gratitude')} />
    </>
  );

  return (
    <WithNavigation headerChildren={header}>
      <WithBackground>{children}</WithBackground>
    </WithNavigation>
  );
};

export default ProfileFeedbackCreateViewWrapper;
