import { GetMySettlementHistoryResponse } from 'services/guide/guideApiService.types';

export const getUniqueSettlements = (data: GetMySettlementHistoryResponse | undefined) => {
  const seenIds = new Set();

  return data?.items.filter((item) => {
    if (!seenIds.has(item.settlement.id) && !item.isSelectedNow) {
      seenIds.add(item.settlement.id);
      return true;
    }
    return false;
  });
};

export const removeFileExtension = (filename: string) => {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1) return filename;

  return filename.substring(0, lastDotIndex);
};
