export enum MODAL_TYPE {
  SIGNED = 'SIGNED',
  FAMILIARIZED = 'FAMILIARIZED',
  REJECTED = 'REJECTED',
  APPROVE = 'APPROVE',
  CORRECTION = 'CORRECTION',
  REVISION_COMMENT = 'REVISION_COMMENT',
  REJECT_COMMENT = 'REJECT_COMMENT',
  DONE = 'DONE',
}
