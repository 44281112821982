import { t } from 'tools/i18n';

import { MilitaryDutyType } from 'types/employee.types';

import { PersonalDataItemType } from '../profile-personal-data-details-general/profilePersonalDataDetailsGeneral.utils';

export const getMilitaryDataItems = (militaryData: MilitaryDutyType): PersonalDataItemType[] => [
  {
    title: t('my_personal_data_document_type'),
    value: militaryData?.typeOfDocument,
  },
  {
    title: t('my_personal_data_document_number'),
    value: militaryData?.numberDocument,
  },
  {
    title: t('my_personal_data_military_category'),
    value: militaryData?.suitabilityToDuty,
    fullWidth: true,
  },
  {
    title: t('my_personal_data_military_rank'),
    value: militaryData?.militaryRank,
    fullWidth: true,
  },
];
