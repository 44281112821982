import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CreationSuccessModalProps {
  isVisible: boolean;
  onCancel: () => void;
}

const CreationSuccessModal: FC<CreationSuccessModalProps> = (props) => {
  const { isVisible, onCancel } = props;

  return (
    <ModalDefault open={isVisible} onCancel={onCancel} width={300}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('documents_statement_requested')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_business_trip_requested_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" size="large" fullWidth onClick={onCancel}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CreationSuccessModal;
