import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Form, TextArea } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeTextAreaProps extends AttributeDefaultProps {}

const AttributeTextArea: FC<AttributeTextAreaProps> = (props) => {
  const { id, isMandatory, name, placeholder, value, isReadonly } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const form = Form.useFormInstance();
  const textValue = Form.useWatch(id, form);
  const modalName = id + name;

  const onSubmitHandler = useCallback(
    (value: Record<string, string>) => {
      const fieldValue = value[modalName];
      setIsModalVisible(!isModalVisible);
      form.setFieldValue(id, fieldValue);
    },
    [form, id, isModalVisible, modalName],
  );

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, value);
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <TextAreaModal
        name={modalName}
        title={name}
        maxLength={250}
        defaultValue={textValue}
        isModalOpen={isModalVisible}
        onSubmitHandler={onSubmitHandler}
        setIsModalOpen={() => setIsModalVisible(false)}
      />
      <Form.Item name={id} rules={isMandatory ? [formRules.required] : []}>
        <TextArea
          disabled={isReadonly}
          onClick={() => {
            setIsModalVisible(true);
          }}
          rows={6}
          placeholder={placeholder ?? t('common_enter_text')}
          maxLength={250}
          onFocus={(e) => e.target.blur()}
        />
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeTextArea);
