import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { getTopManagementByStageRoute } from 'routes/documents/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { AdditionalEducationInfoType, TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import { parseBySeparator } from '../../documentsTopManagement.utils';
import { MappedAdditionalEducationInfoType } from './additionalEducationInfoForm.utils';

const useAdditionalEducationInfoForm = () => {
  const navigate = useNavigate();
  const [, setSectionId] = useState(0);
  const [educationData, setEducationData] = useState<AdditionalEducationInfoType[]>([]);
  const [customSection, setCustomSection] = useState<
    Map<number, MappedAdditionalEducationInfoType>
  >(new Map());

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData, isLoading: renewEmployeeDataIsLoading } =
    useGetRenewEmployeeFormQuery({
      myEmployeeId: selectedEmployee?.id!,
    });
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const { additionalEducationInfo: initialAdditionalEducationInfo, id } = renewEmployeeData || {};
  const customSections = Array.from(customSection.values());

  const addCustomSection = () => {
    setSectionId((prev) => {
      const newSectionId = prev + 1;
      setCustomSection((prevCustomSection) => {
        const newCustomSection = new Map(prevCustomSection);
        newCustomSection.set(newSectionId, { id: newSectionId });
        return newCustomSection;
      });

      return newSectionId;
    });
  };
  const removeCustomSection = (id: number) => () =>
    setCustomSection((prevCustomSection) => {
      const newCustomSection = new Map(prevCustomSection);
      newCustomSection.delete(id);
      return newCustomSection;
    });

  const nextStep = () =>
    navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.WORK_EXPERIENCE));

  const handleForm = (formData: Record<string, string>) => {
    const additionalEducationData = [...(educationData ?? []), ...parseBySeparator(formData)];

    const body = {
      additionalEducationInfo: additionalEducationData as AdditionalEducationInfoType[],
      formStep: TOP_MANAGEMENT_STAGE_ENUM.WORK_EXPERIENCE,
    };

    updateFormData({ id: id!, myEmployeeId: selectedEmployee?.id!, body }).then(
      handleRequest({
        onSuccess: nextStep,
      }),
    );
  };

  useEffect(() => {
    if (initialAdditionalEducationInfo?.length) {
      let lastIndex = 0;
      const form1cData: AdditionalEducationInfoType[] = [];
      const userData = new Map<number, MappedAdditionalEducationInfoType>();

      initialAdditionalEducationInfo.forEach((educationData, index) => {
        if (educationData.isEnteredByUser) {
          userData.set(index, { ...educationData, id: index });
        } else {
          form1cData.push(educationData);
        }
        lastIndex = index;
      });

      setEducationData(form1cData);
      setCustomSection(userData);
      setSectionId(lastIndex);
    }
  }, [initialAdditionalEducationInfo]);

  return {
    educationData,
    customSections,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    handleForm,
    addCustomSection,
    removeCustomSection,
  };
};

export default useAdditionalEducationInfoForm;
