import { t } from 'tools/i18n';

import { SuggestionAttributeType } from '../../more-suggestion-create/moreSuggestionCreate.utils';

export const SUGGESTION_DECISION_ATTRIBUTES: SuggestionAttributeType[] = [
  {
    code: 'problem',
    id: 'problem',
    isMandatory: true,
    name: t('innovations_offer_problem'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'suggestion',
    id: 'suggestion',
    isMandatory: true,
    name: t('innovations_offer_suggestion'),
    type: 'TEXT_AREA',
    placeholder: t('common_enter_text'),
  },
  {
    code: 'suggestionFiles',
    id: 'suggestionFiles',
    name: t('innovations_offer_suggestion_files'),
    type: 'MULTIFILE',
  },
];
