import { useContext } from 'react';

import initialApiService from 'services/initialApiService';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { useDraftApproveWorkTourAssignmentMutation } from 'services/v2/me/task-assignment/work-tour/workTourApiService';


interface Props {
  handleCertificateCheck: (callback: () => void) => void;
  handleOpenDoneModal: () => void;
}

const useActions = (props: Props) => {
  const { handleCertificateCheck, handleOpenDoneModal } = props;

  const assignment = useContext(AssignmentContext);

  const dispatch = useAppDispatch();

  const [draftApprove, { isLoading: draftApproveIsLoading }] = useDraftApproveWorkTourAssignmentMutation();

  const handleInvalidate = () => {
    dispatch(
      initialApiService?.util?.invalidateTags([
        SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID,
        SERVICE_TAGS.GET_ASSIGNED_TASKS,
      ]),
    );
  };

  const handleFinish = (form: {
    files: {
      fileName: string
      key: string
      name: string
      url: string
    }[]
  }) => {
    const { files } = form;

    const modifiedFiles = files.map(file => ({ fileKey: file.key, fileName: file.fileName }));

    if (assignment?.kedoDraft) {
      handleCertificateCheck(() => draftApprove({
        assignmentId: assignment.id,
        draftId: assignment.kedoDraft?.id!,
        files: modifiedFiles,
      }).then(
        handleRequest({
          onSuccess: () => {
            handleOpenDoneModal()
            handleInvalidate()
          }
        })
      ))

    }

  }


  const isLoading = draftApproveIsLoading;

  return {
    isLoading,
    handleFinish
  };
};

export default useActions;
