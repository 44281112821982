import initialApiService from 'services/initialApiService';

import { GetDivisionByIdPropsType, GetDivisionByIdResponseType } from './divisionApiService.types';

import { createDivisionUrl } from './utils';

const divisionApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getDivisionById: builder.query<GetDivisionByIdResponseType, GetDivisionByIdPropsType>({
      query: (id) => ({
        url: createDivisionUrl(`/${id}`),
      }),
    }),
  }),
});

export const { useGetDivisionByIdQuery } = divisionApiService;
