import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import { GetEmployeePropsType, GetEmployeeResponseType } from './employeeApiService.types';

import { createEmployeeUrl } from './utils';

const employeeApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEmployee: builder.query<GetEmployeeResponseType, GetEmployeePropsType>({
      query: (params) => ({
        url: createEmployeeUrl(`/find-text${prepareQueryParams(params)}`),
      }),
    }),
  }),
});

export const { useLazyGetEmployeeQuery } = employeeApiService;
