import { t } from 'tools/i18n';

import { Button, Card, Flex, Form, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { AdditionalEducationInfoType } from 'types/topManagement.types';

import Collapse from '../collapse';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import { MappedAdditionalEducationInfoType } from './additionalEducationInfoForm.utils';
import AdditionalEducationCard from './containers/additional-education-card';
import useAdditionalEducationInfoForm from './useAdditionalEducationInfoForm.hook';

const AdditionalEducationInfoForm = () => {
  const {
    customSections,
    educationData,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    addCustomSection,
    handleForm,
    removeCustomSection,
  } = useAdditionalEducationInfoForm();

  const renderEducationCards = (data: AdditionalEducationInfoType) => {
    const { educationName, courseName, documentInfo } = data;
    const key = `${educationName}${documentInfo}`;

    return (
      <Card>
        <Collapse
          noPadding
          defaultActiveKey={key}
          items={[
            {
              key: key,
              label: <Typography.Title level={5}>{educationName}</Typography.Title>,
              children: (
                <>
                  <DataPresenter
                    title={t('top_management_add_additional_education_course_name')}
                    text={courseName}
                  />
                  <DataPresenter title={t('common_document')} text={documentInfo} />
                </>
              ),
            },
          ]}
        />
      </Card>
    );
  };

  const renderCustomSections = (element: MappedAdditionalEducationInfoType) => {
    const { id, ...rest } = element || {};

    return (
      <AdditionalEducationCard
        id={id}
        key={id}
        onClick={removeCustomSection(id)}
        educationInitialData={rest}
      />
    );
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_add_additional_education')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>
          {educationData?.map(renderEducationCards)}

          <Flex gap="16" vertical>
            {customSections.map(renderCustomSections)}
          </Flex>

          <Card>
            <Button
              type="secondary"
              fullWidth
              size="large"
              htmlType="button"
              leftIcon="plus"
              onClick={addCustomSection}>
              {t('top_management_add_additional_education_add')}
            </Button>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default AdditionalEducationInfoForm;
