import React, { Dispatch, FC, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import {
  useGetSuggestionDecisionStatusesQuery,
  useGetSuggestionStatusesQuery,
} from 'services/me/suggestion/suggestionApiService';

import { Flex, Typography } from 'gazprom-ui-lib';

import Filter from 'containers/filter';

import { FilterItemType } from 'types/filter.types';

import s from './IdeasStatuses.module.scss';
import { DEFAULT_ALL_STATUS } from './ideasStatuses.utils';

interface IdeasStatusesProps {
  activeStatus: string;
  setActiveStatus: Dispatch<SetStateAction<string>>;
  count?: number;
  isSuggestionDecisions?: boolean;
}

const IdeasStatuses: FC<IdeasStatusesProps> = (props) => {
  const { activeStatus, setActiveStatus, count, isSuggestionDecisions } = props;

  const { data: suggestionStatuses = [] } = useGetSuggestionStatusesQuery(
    {
      isFilter: true,
    },
    { skip: isSuggestionDecisions },
  );

  const { data: suggestionDecisionStatuses = [] } = useGetSuggestionDecisionStatusesQuery(
    {},
    { skip: !isSuggestionDecisions },
  );

  const statusesToUse = isSuggestionDecisions ? suggestionDecisionStatuses : suggestionStatuses;

  const statuses: FilterItemType[] = [DEFAULT_ALL_STATUS, ...statusesToUse].map(
    ({ code, name }) => ({
      id: code,
      name,
    }),
  );

  return (
    <Flex gap="16" fullWidth vertical className={s.wrapper}>
      <Flex gap="8" wrap="wrap">
        <Filter
          id="suggestion-status-filter"
          label={t('common_status')}
          options={statuses}
          appliedFilter={activeStatus}
          setAppliedFilter={setActiveStatus}
        />
      </Flex>
      {!!count && (
        <Typography.Text weight="700" size="14" type="primary" className={s.counter}>
          {t('innovations_found_offers', { count })}
        </Typography.Text>
      )}
    </Flex>
  );
};

export default IdeasStatuses;
