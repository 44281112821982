import React, { FC } from 'react';

import { Avatar, Flex, TitleProps, Typography } from 'gazprom-ui-lib';

import s from './Bio.module.scss';

interface BioProps {
  showAvatar?: boolean;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  staffName?: string;
  avatarSize?: string;
  titleLevel?: TitleProps['level'];
  employerName?: string;
}

const Bio: FC<BioProps> = (props) => {
  const {
    avatarSize = '40',
    showAvatar = true,
    firstName = '',
    lastName = '',
    patronymic = '',
    staffName = '',
    titleLevel = 5,
    employerName = '',
  } = props;

  const lastNameToShow = lastName ? `${lastName.length === 1 ? `${lastName}.` : lastName}` : '';
  const patronymicToShow = patronymic
    ? `${patronymic.length === 1 ? `${patronymic}.` : patronymic}`
    : '';

  return (
    <Flex gap="12" align="center" className={s.wrapper}>
      {showAvatar && (
        <Avatar
          firstName={firstName}
          lastName={lastName}
          alt="аватар пользователя"
          size={avatarSize}
        />
      )}
      <div>
        {employerName && (
          <Typography.Text size="12" type="secondary">
            {employerName}
          </Typography.Text>
        )}
        <Typography.Title
          level={
            titleLevel
          }>{`${firstName} ${patronymicToShow} ${lastNameToShow}`}</Typography.Title>
        {staffName && (
          <Typography.Text size="12" type="secondary">
            {staffName}
          </Typography.Text>
        )}
      </div>
    </Flex>
  );
};

export default Bio;
