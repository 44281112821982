import { FC } from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { useNavigate } from 'react-router-dom';

import { Icon, Typography } from 'gazprom-ui-lib';

import { Divider } from 'components/divider';

import { formatDate } from '../notifications.utils';
import s from './notifications-card.module.scss';

interface NotificationCardProps {
  id: string;
  title: string;
  date: string;
  text: string;
  isRenderDivider: boolean;
}

const NotificationsCard: FC<NotificationCardProps> = (props) => {
  const { id, title, date, isRenderDivider, text } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className={s.container} onClick={() => navigate(id)}>
        <div className={s.header}>
          <div className={s.icon}>
            <Icon name="notification" />
          </div>
          <div className={s.title}>
            <Typography.Text size="14" weight="500">
              {title}
            </Typography.Text>
            <Typography.Text size="12" type="secondary">
              {formatDate(date)}
            </Typography.Text>
          </div>
        </div>
        <Typography.Text size="14" weight="500">
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text, {
                FORBID_TAGS: ['button'],
              }),
            }}
          />
        </Typography.Text>
      </div>
      {isRenderDivider && <Divider className={s.dividerNotification} />}
    </>
  );
};

export default NotificationsCard;
