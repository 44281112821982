import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Card, Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './CardsTile.module.scss';
import { CardType } from './cardsTile.utils';

interface CardsTileProps {
  title: string;
  cards: CardType[];
}

const CardsTile: FC<CardsTileProps> = (props) => {
  const { title, cards } = props;
  const navigate = useNavigate();

  const renderCard = (card: CardType) => {
    if (card.hidden) {
      return null;
    }

    const { title, icon, description, to, navigationState } = card;

    const onClick = () => {
      navigate(to, { state: navigationState });
    };

    const content = (
      <button onClick={onClick} className={s.item} key={`card__tile__${title}`}>
        <Flex vertical gap="4" fullHeight>
          <Icon name={icon} className={s.itemIcon} />
          <Typography.Text size="14" weight="500">
            {t(title)}
          </Typography.Text>
          <Typography.Text size="12" type="secondary">
            {t(description)}
          </Typography.Text>
        </Flex>
      </button>
    );

    if (card.showDot) {
      return <Card.Dot key={`card__tile__dot__${card.title}`}>{content}</Card.Dot>;
    }

    return content;
  };

  return (
    <Card>
      <Typography.Title level={4}>{title}</Typography.Title>
      <div className={s.cards}>{cards.map(renderCard)}</div>
    </Card>
  );
};

export default CardsTile;
