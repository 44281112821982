import React, { ReactNode } from 'react';

import cn from 'classnames';

import { Typography } from 'gazprom-ui-lib';

import s from './TagButton.module.scss';

interface Props {
  name: string;
  id: string;
  toggleIsActive?: (id: string) => void;
  isActive: boolean;
  suffix?: ReactNode;
}

const TagButton = (props: Props) => {
  const { name, isActive, id, toggleIsActive, suffix } = props;
  return (
    <button
      className={cn(s.tag, { [s.active]: isActive })}
      onClick={() => toggleIsActive && toggleIsActive(id)}>
      <Typography.Text size="14" weight="500">
        {name}
      </Typography.Text>
      {!!suffix && suffix}
    </button>
  );
};

export default TagButton;
