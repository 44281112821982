import { FC, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Input, TextArea, Typography } from 'gazprom-ui-lib';

import { ExperienceType } from 'types/topManagement.types';

import Collapse from '../../../collapse';
import DatePicker from '../../../date-picker';
import { WORK_EXPERIENCE_FORM_ENUM } from '../../workExperienceInfoForm.utils';

interface ExperienceCardProps {
  id: string | number;
  onClick: () => void;
  experienceInitialData: Partial<ExperienceType>;
}

const ExperienceCard: FC<ExperienceCardProps> = (props) => {
  const { id, experienceInitialData, onClick } = props;
  const key = `${WORK_EXPERIENCE_FORM_ENUM.NAME}_${id}`;

  const form = Form.useFormInstance();
  const experienceTitleName = Form.useWatch(key, form);

  const {
    activity,
    address,
    dateFinish,
    dateStart,
    functions,
    name,
    phone,
    position,
    reasonForDismissal,
  } = experienceInitialData || {};

  const [displayedName, setDisplayedName] = useState(name || t('common_organization_name'));

  useEffect(() => {
    const isTouched = form.isFieldTouched(key);

    if (isTouched) {
      setDisplayedName(experienceTitleName);
    }
  }, [experienceTitleName, form, key]);

  return (
    <Card>
      <Collapse
        noPadding
        defaultActiveKey={id}
        items={[
          {
            key: id,
            label: <Typography.Title level={5}>{displayedName}</Typography.Title>,
            children: (
              <Flex gap="24" vertical>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.IS_ENTERED_BY_USER}_${id}`}
                  initialValue={true}
                  hidden
                />
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.NAME}_${id}`}
                  rules={[formRules.required]}
                  initialValue={name}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.ADDRESS}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('common_organization_address')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={address}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.PHONE}_${id}`}
                  label={<Typography.Title level={5}>{t('common_phone')}</Typography.Title>}
                  rules={[formRules.required]}
                  initialValue={phone}>
                  <Input name="" size="large" placeholder={t('common_enter_number')} />
                </Form.Item>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.ACTIVITY}_${id}`}
                  label={
                    <Typography.Title level={5}>{t('common_activity_field')}</Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={activity}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <DatePicker
                  name={`${WORK_EXPERIENCE_FORM_ENUM.DATE_START}_${id}`}
                  label={t('top_management_work_experience_start')}
                  required
                  yearConfig={{
                    initialMinYear: 1950,
                    initialMaxYear: 2099,
                  }}
                  initialValue={dateStart}
                />
                <DatePicker
                  name={`${WORK_EXPERIENCE_FORM_ENUM.DATE_FINISH}_${id}`}
                  label={t('top_management_work_experience_finish')}
                  required
                  yearConfig={{
                    initialMinYear: 1950,
                    initialMaxYear: 2099,
                  }}
                  initialValue={dateFinish}
                />
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.POSITION}_${id}`}
                  label={<Typography.Title level={5}>{t('common_staff_name')}</Typography.Title>}
                  rules={[formRules.required]}
                  initialValue={position}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.FUNCTIONS}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_work_experience_functions')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={functions}>
                  <Input name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Form.Item
                  name={`${WORK_EXPERIENCE_FORM_ENUM.REASON}_${id}`}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_work_experience_reason')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={reasonForDismissal}>
                  <TextArea name="" size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
                <Button type="secondary" fullWidth size="large" danger onClick={onClick}>
                  {t('common_delete')}
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default ExperienceCard;
