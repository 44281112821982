import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MORE_FAQ_ROUTE, getFaqQuestionDetailsRoute } from 'routes/more/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetFaqCategoryByIdQuery } from 'services/faq-category/faqCategoryApiService';
import { useGetFaqQuestionsListQuery } from 'services/faq-list/faqListApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { FaqQuestionType } from 'types/faq.types';

import s from './MoreFaqCategoryDetails.module.scss';

const MoreFaqCategoryDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ faqCategoryId: string }>();
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: faqCategoryData, isLoading: faqCategoryDataIsLoading } = useGetFaqCategoryByIdQuery(
    params.faqCategoryId!,
  );
  const { data: faqQuestionsData } = useGetFaqQuestionsListQuery({
    idCategoryFilterParam: params.faqCategoryId!,
    size: 100,
    page: 1,
    myEmployeeId: selectedEmployee?.id,
  });

  const renderQuestion = (question: FaqQuestionType) => {
    return (
      <button
        key={question.id}
        className={s.question}
        onClick={() =>
          navigate(getFaqQuestionDetailsRoute(question.id), {
            state: location.state,
          })
        }>
        <Typography.Title level={5}>{question.title}</Typography.Title>
        <Icon name="forward" />
      </button>
    );
  };

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_FAQ_ROUTE} state={location.state} useDefaultGoBack />
      <Header.Label label={t('common_help_and_support')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <WithLoader isLoading={faqCategoryDataIsLoading}>
              <Card.Default
                title={faqCategoryData?.nameCategory ?? ''}
                icon="faq"
                className={s.header}
              />
            </WithLoader>
          </Card>
          <Card withPadding={false}>
            <WithLoader isLoading={faqCategoryDataIsLoading}>
              <div className={s.questionsListHeader}>
                <Typography.Title level={4}>{t('common_questions_list')}</Typography.Title>
                <Typography.Text size="12" type="primary">
                  {faqQuestionsData?.faqLists?.length}
                </Typography.Text>
              </div>
              <div>{faqQuestionsData?.faqLists?.map(renderQuestion)}</div>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default MoreFaqCategoryDetails;
