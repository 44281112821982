import React, { useCallback, useState } from 'react';

import { t } from 'tools/i18n';

import { useRequestCallMutation } from 'services/notification/notificationApiService';

import { Button, TextArea, TextAreaProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from './NotificationsRequestCall.module.scss';
import NotificationsSingleRequestCallModal from './notifications-single-request-call-modal';

interface NotificationsSingleRequestCallProps {
  handleBack: () => void;
  id: string;
}

const NotificationsSingleRequestCall = (props: NotificationsSingleRequestCallProps) => {
  const { handleBack, id } = props;
  const [text, setText] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [requestCall, { isLoading }] = useRequestCallMutation();

  const handleChange: TextAreaProps['onChange'] = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = useCallback(() => {
    requestCall({
      contacts: text,
      id,
    }).finally(() => {
      setIsModalVisible(true);
    });
  }, [id, requestCall, text]);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handleBack} />
      <Header.Label label={t('notifications_request_call')} />
    </>
  );

  return (
    <WithNavigation headerChildren={headerChildren}>
      <WithBackground>
        <Card className={s.textAreaWrapper}>
          <Typography.Title level={4}>{t('notifications_how_to_contact_you')}</Typography.Title>
          <Typography.Text>
            {t('common_symbols_count', { currentCount: text.length, maxCount: 360 })}
          </Typography.Text>
          <TextArea
            value={text}
            className={s.textAreaWithoutPadding}
            autoFocus
            rows={10}
            placeholder={t('common_enter_text')}
            onChange={handleChange}
            maxLength={360}
          />
          {!!text.length && (
            <Button leftIcon="phoneFilled" fullWidth onClick={handleSubmit} loading={isLoading}>
              {t('notifications_request_call')}
            </Button>
          )}
        </Card>
        <NotificationsSingleRequestCallModal
          isVisible={isModalVisible}
          onClickHandler={handleBack}
        />
      </WithBackground>
    </WithNavigation>
  );
};

export default NotificationsSingleRequestCall;
