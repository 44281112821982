import React, { FC } from 'react';

import { Trans, t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import WithLoader from 'containers/wrappers/with-loader';

import s from './Statistics.module.scss';

interface StatisticsProps {
  socAmountProvidedTotalCount?: number;
  totalSocAmountProvidedSumBySocFactYear?: number;
  totalSocAmountProvidedSum?: number;
  isLoading: boolean;
}

const Statistics: FC<StatisticsProps> = (props) => {
  const {
    socAmountProvidedTotalCount,
    totalSocAmountProvidedSumBySocFactYear,
    totalSocAmountProvidedSum,
    isLoading,
  } = props;

  return (
    <Card>
      <WithLoader isLoading={isLoading}>
        <Card.Default
          title={t('guide_social_policy_my_requests')}
          description={
            <Trans
              i18nKey="guide_social_policy_applications_description"
              count={socAmountProvidedTotalCount}>
              <Typography.Text type="primary" size="14" weight="500"></Typography.Text>
            </Trans>
          }
          icon="paymentFilled"
          iconPadding="12"
        />
        <div className={s.summary}>
          <div>
            <Typography.Text size="12" weight="500">
              {t('documents_social_policy_paid_this_year')}
            </Typography.Text>
            <Typography.Title level={4} type="primary">
              {`${totalSocAmountProvidedSumBySocFactYear} ₽`}
            </Typography.Title>
          </div>
          <div>
            <Typography.Text size="12" weight="500">
              {t('documents_social_policy_paid_total')}
            </Typography.Text>
            <Typography.Title level={4} type="primary">
              {`${totalSocAmountProvidedSum} ₽`}
            </Typography.Title>
          </div>
        </div>
      </WithLoader>
    </Card>
  );
};

export default Statistics;
