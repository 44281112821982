import React, { useMemo, useState } from 'react';

import WithStatusBar from 'containers/wrappers/with-status-bar';

import {
  CONFIG_STATE,
  ConfigStepType,
  FeedbackCategoryType,
  FeedbackCreateContext,
  FeedbackTargetType,
} from './profileFeedbackCreate.utils';
import useContent from './useContent.hook';

const ProfileFeedbackCreate = () => {
  const [configStep, setConfigStep] = useState<ConfigStepType>({
    current: CONFIG_STATE.INITIAL,
  });
  const [feedbackTarget, setFeedbackTarget] = useState<FeedbackTargetType>(null);
  const [feedbackCategory, setFeedbackCategory] = useState<FeedbackCategoryType>(null);
  const [feedbackText, setFeedbackText] = useState<string>('');

  const content = useContent({
    configStep,
  });

  const memoizedProvider = useMemo(
    () => ({
      feedbackCategory,
      feedbackTarget,
      feedbackText,
      configStep,
      setConfigStep,
      setFeedbackCategory,
      setFeedbackTarget,
      setFeedbackText,
    }),
    [configStep, feedbackCategory, feedbackTarget, feedbackText],
  );

  return (
    <WithStatusBar>
      <FeedbackCreateContext.Provider value={memoizedProvider}>
        {content}
      </FeedbackCreateContext.Provider>
    </WithStatusBar>
  );
};

export default ProfileFeedbackCreate;
