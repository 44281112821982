import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { SuggestionDecisionPerformerType } from 'types/suggestion.types';

interface Props {
  performer?: null | SuggestionDecisionPerformerType;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

const SelectedPerformerModal = (props: Props) => {
  const { performer, onCancel, onConfirm, isLoading } = props;

  const { firstName, lastName } = performer?.person ?? {};

  return (
    <ModalDefault open={!!performer} onCancel={onCancel}>
      <ModalDefault.Icon name="accountCircle" type="primary" />
      <ModalDefault.Title>{t('suggestion_decision_confirm_performer_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('suggestion_decision_confirm_performer_description', {
          name: `${firstName} ${lastName}`,
        })}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          loading={isLoading}
          leftIcon="ok"
          type="primary"
          size="large"
          fullWidth
          onClick={onConfirm}>
          {t('common_confirm')}
        </Button>
        <Button type="secondary" size="large" fullWidth onClick={onCancel}>
          {t('suggestion_decision_choose_another')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default SelectedPerformerModal;
