import { FC } from 'react';

import { Sunburst } from '@ant-design/charts';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import { BadgeProps, PayslipDataType, SALARY_FILED_NAMES_ENUM } from 'types/mySalary.types';

import MySalaryBadge from '../../my-salary-badge';
import { MY_SALARY_COLORS, convertToRub, createSunBurstConfig } from '../../mySalary.utils';
import { colorFunction } from '../mySalarySegmentMonth.utils';
import s from './MySalaryPaid.module.scss';
import { mapPaidData } from './mySalaryPaid.utils';

interface MySalaryPaidProps {
  data: PayslipDataType;
}

const renderBadge = (props: BadgeProps) => (
  <MySalaryBadge text={props.text} number={props.value} className={props.name} key={props.name} />
);

const MySalaryPaid: FC<MySalaryPaidProps> = (props) => {
  const { data } = props;
  const { badgeData, configData, payoutsSum } = mapPaidData(data.components);
  const config = createSunBurstConfig(
    configData,
    colorFunction(MY_SALARY_COLORS[SALARY_FILED_NAMES_ENUM.PAYOUTS].colorHex),
  );

  return (
    <>
      <div className={s.container}>
        <div className={s.textContainer}>
          <Typography.Text size="14">{t('my_salary_paid')}</Typography.Text>
          <Typography.Title level={4} className={s.textValue}>
            {convertToRub(payoutsSum.value)}
          </Typography.Title>
        </div>
        <Sunburst {...config} />
      </div>
      <div className={s.badgeContainer}>{badgeData.map(renderBadge)}</div>
    </>
  );
};

export default MySalaryPaid;
