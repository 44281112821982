import { DOCUMENTS_BUSINESS_TRIP_ROUTE } from 'routes/documents/list';
import { HOME_NEWS_ROUTE } from 'routes/home/list';
import { MORE_DMS_ROUTE } from 'routes/more/list';
import {
  PROFILE_AUTH_ROUTE,
  PROFILE_MY_SALARY_ROUTE,
  PROFILE_PERSONAL_DATA_ROUTE,
  PROFILE_ROUTE,
  PROFILE_SCHEDULE_ROUTE,
  PROFILE_VACATION_ROUTE,
} from 'routes/profile/list';

import { TIconsLiteral } from 'gazprom-ui-lib';

import { SEGMENT_NEWS_EVENT_TYPE } from 'pages/home/home-news/home-news-list/homeNewsList.utils';

export type CardType = {
  to: string;
  title: string;
  description: string;
  icon: TIconsLiteral;
  hidden?: boolean;
  showDot?: boolean;
  navigationState?: Record<string, unknown>;
};

export const PROFILE_CARDS: CardType[] = [
  {
    to: PROFILE_PERSONAL_DATA_ROUTE,
    title: 'my_personal_data_short',
    description: 'my_personal_data_view',
    icon: 'passport',
    hidden: process.env.true?.trim() !== 'true',
  },
  {
    to: PROFILE_MY_SALARY_ROUTE,
    title: 'my_salary_header',
    description: 'my_salary_header_description',
    icon: 'financeFilled',
  },
  {
    to: MORE_DMS_ROUTE,
    title: 'dms_polices',
    description: 'dms_info_and_contacts',
    icon: 'insuranceFilled',
  },
  {
    to: PROFILE_AUTH_ROUTE,
    title: 'profile_auth_settings_short',
    description: 'profile_auth_settings_description_short',
    icon: 'authSettingsFilled',
  },
];

export const CALENDAR_CARDS: CardType[] = [
  {
    to: PROFILE_SCHEDULE_ROUTE,
    title: 'common_schedule',
    description: 'schedule_enumeration',
    icon: 'calendarFilled',
  },
  {
    to: PROFILE_VACATION_ROUTE,
    title: 'common_vacation_schedule',
    description: 'profile_vacation_description',
    icon: 'vacationCalendarFilled',
  },
  {
    to: HOME_NEWS_ROUTE,
    title: 'profile_events',
    description: 'profile_events_description',
    icon: 'eventFilled',
    navigationState: {
      type: SEGMENT_NEWS_EVENT_TYPE,
      cameFrom: PROFILE_ROUTE,
      isParticipant: true,
    },
  },
  {
    to: DOCUMENTS_BUSINESS_TRIP_ROUTE,
    title: 'documents_my_business_trips',
    description: 'documents_business_trips_registration_and_list',
    icon: 'plane',
  },
];

export type AVAILABLE_MODAL_TYPES = '' | 'created';
