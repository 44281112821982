import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  ApproveCancelKedoTaskAssignmentPropsType,
  ApproveCancelKedoTaskAssignmentResponseType,
  ApproveKedoTaskAssignmentPropsType,
  ApproveKedoTaskAssignmentResponseType,
  InfoKedoTaskAssignmentPropsType,
  InfoKedoTaskAssignmentResponseType,
  RejectCancelKedoTaskAssignmentPropsType,
  RejectCancelKedoTaskAssignmentResponseType,
  RejectKedoTaskAssignmentPropsType,
  RejectKedoTaskAssignmentResponseType,
  SignKedoTaskAssignmentPropsType,
  SignKedoTaskAssignmentResponseType,
} from './kedoTaskApiService.types';

import { createKedoTaskUrl } from './utils';

const kedoTaskApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    signKedoTaskAssignment: builder.mutation<
      SignKedoTaskAssignmentResponseType,
      SignKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/sign`),
        method: 'POST',
        body,
      }),
    }),
    rejectKedoTaskAssignment: builder.mutation<
      RejectKedoTaskAssignmentResponseType,
      RejectKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/reject`),
        method: 'POST',
        body,
      }),
    }),
    rejectCancelKedoTaskAssignment: builder.mutation<
      RejectCancelKedoTaskAssignmentResponseType,
      RejectCancelKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/reject-cancel`),
        method: 'POST',
        body,
      }),
    }),
    infoKedoTaskAssignment: builder.mutation<
      InfoKedoTaskAssignmentResponseType,
      InfoKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/info`),
        method: 'POST',
        body,
      }),
    }),
    approveKedoTaskAssignment: builder.mutation<
      ApproveKedoTaskAssignmentResponseType,
      ApproveKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/approve`),
        method: 'POST',
        body,
      }),
    }),
    approveCancelKedoTaskAssignment: builder.mutation<
      ApproveCancelKedoTaskAssignmentResponseType,
      ApproveCancelKedoTaskAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createKedoTaskUrl(`/${assignmentId}/approve-cancel`),
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const {
  useSignKedoTaskAssignmentMutation,
  useRejectKedoTaskAssignmentMutation,
  useRejectCancelKedoTaskAssignmentMutation,
  useInfoKedoTaskAssignmentMutation,
  useApproveKedoTaskAssignmentMutation,
  useApproveCancelKedoTaskAssignmentMutation,
} = kedoTaskApiService;
