import { FC } from 'react';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './MySalaryEmpty.module.scss';

interface MySalaryEmptyProps {
  title: string;
  description: string;
}

const MySalaryEmpty: FC<MySalaryEmptyProps> = (props) => {
  const { title, description } = props;

  return (
    <div className={s.empyContainer}>
      <div className={s.icon}>
        <Icon name="notFound" size={35} />
      </div>
      <div className={s.textContainer}>
        <Typography.Title level={4}>{title}</Typography.Title>
        <Typography.Text size="16" type="secondary">
          {description}
        </Typography.Text>
      </div>
    </div>
  );
};

export default MySalaryEmpty;
