import { useState } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { useGetSettlementByIdQuery } from 'services/guide/guideApiService';

import { Typography } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';
import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import GuideSelected from '../guide-selected';
import { removeFileExtension } from '../guide.utils';
import s from './GuideMap.module.scss';
import useDownloadFile from './useDownloadFile';

const GuideMap = () => {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data, isLoading } = useGetSettlementByIdQuery({
    id: selectedEmployee?.currentSettlement.id!,
  });

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('guide_selected_map_title')} />
    </>
  );

  const handleImageLoad = () => setImageLoading(false);

  const handleImageError = () => setImageLoading(false);

  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  const { file, fileKey, fileLink, fileName, downloadFile } = useDownloadFile({
    fileMap: data?.fileMap,
    handleOpenFile,
  });

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <GuideSelected text={data?.name ?? ''} />
          <WithLoader isLoading={isLoading}>
            <Card withPadding className={s.container}>
              <div className={s.headContainer}>
                <Typography.Title level={4}>{t('guide_selected_map_title')}</Typography.Title>
              </div>
              {fileLink && (
                <img
                  src={createMediaDownloadUrl(fileLink)}
                  alt={fileName}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  className={cn(s.image, imageLoading ? s.imageLoading : '')}
                />
              )}
              {imageLoading && (
                <div className={s.loaderContainer}>
                  <UniversalLoader />
                </div>
              )}
              {!!fileName && (
                <KedoFile
                  file={file}
                  key={fileKey}
                  onClick={downloadFile}
                  loading={!!loadingKey}
                  className={s.downloadFile}
                  fileName={removeFileExtension(file.fileName)}
                />
              )}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideMap;
