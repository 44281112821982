import { EmployeeType } from 'types/employee.types';

export enum ARTICLE_TYPE_ENUM {
  DOESNT_EXIST,
  ARTICLE,
  NEWS,
  EVENT,
}

export type ArticleType = {
  id: string;
  code: string;
  title: string;
  text: string;
  type: number;
  status: number;
  creator: EmployeeType;
  editor: EmployeeType | null;
  location: string;
  pinned: boolean;
  cover?: {
    num: number;
    link: string;
    name: string;
    type: string;
    size: number;
    version: number;
  };
  media?: {
    num: number;
    link: string;
    name: string;
    type: string;
    size: number;
    version: number;
  }[];
  createdAt?: string;
  updatedAt?: string;
  publishFrom?: string;
  publishTill?: string;
  meetingId?: string;
  liked: boolean;
};

export type ArticleFullType = ArticleType & {
  categories?: { id: string; name: string }[];
  commentsCnt: number;
  favoriteCnt: number;
  viewsCnt: number;
  likesCnt: number;
};

export type ArticleCommentType = {
  articleId: string;
  comment: string;
  createdAt: string;
  creator: EmployeeType;
  editor: EmployeeType | null;
  id: string;
  updatedAt: string;
  visible: boolean;
};
