import React, { FC, memo } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface NoCertificateModalProps {
  open: boolean;
  handleClose: () => void;
}

const NoCertificateModal: FC<NoCertificateModalProps> = (props) => {
  const { open, handleClose } = props;

  const navigate = useNavigate();

  const navigateDocuments = () => {
    navigate(DOCUMENTS_ROUTE);
  };

  return (
    <ModalDefault open={open} onCancel={handleClose} getContainer="#socialPolicyDetailsView">
      <ModalDefault.Icon name="signature" type="primary" />
      <ModalDefault.Title>{t('documents_certificate_not_found')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_certificate_social_program')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth size="large" rightIcon="arrowRight" onClick={navigateDocuments}>
          {t('common_go_to_section')}
        </Button>
        <Button fullWidth size="large" type="secondary" onClick={navigateDocuments}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default memo(NoCertificateModal);
