import { t } from 'tools/i18n';

import { PersonalDataType } from 'types/employee.types';

export enum PROFILE_PERSONAL_CATEGORIES {
  GENERAL = 'general',
  FAMILY = 'family',
  EDUCATION = 'education',
  DISABILITY = 'disability',
  MILITARY = 'military',
}

export type PersonalDataCategoryType = {
  title: string;
  value: PROFILE_PERSONAL_CATEGORIES;
  visible: boolean;
};

export const getPersonalDataCategories = (
  personalData?: PersonalDataType,
): PersonalDataCategoryType[] => {
  if (!personalData) return [];

  return [
    {
      title: t('my_personal_data_general'),
      value: PROFILE_PERSONAL_CATEGORIES.GENERAL,
      visible: true,
    },
    {
      title: t('my_personal_data_family'),
      value: PROFILE_PERSONAL_CATEGORIES.FAMILY,
      visible: !!personalData?.family?.length,
    },
    {
      title: t('my_personal_data_education'),
      value: PROFILE_PERSONAL_CATEGORIES.EDUCATION,
      visible: !!personalData?.education?.length,
    },
    //TODO: add disability section when BE provides data for this
    {
      title: t('my_personal_data_disability'),
      value: PROFILE_PERSONAL_CATEGORIES.DISABILITY,
      visible: false,
    },
    {
      title: t('my_personal_data_military'),
      value: PROFILE_PERSONAL_CATEGORIES.MILITARY,
      visible: !!personalData?.militaryDuty,
    },
  ];
};
