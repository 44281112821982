import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import buildHeroAvatar from 'assets/icons/buildHeroAvatar.png';

import useBuildHero from '../../../utils/useBuildHero';
import s from './GuideSelected.module.scss';

interface GuideSelectedProps {
  text: string;
}

const GuideSelected: FC<GuideSelectedProps> = (props) => {
  const navigate = useNavigate();

  const { isBuildHeroCurrentSettlement } = useBuildHero();

  return (
    <Card withPadding className={s.container}>
      <div className={s.icon}>
        {isBuildHeroCurrentSettlement ? (
          <img src={buildHeroAvatar} alt="buildHeroAvatar" />
        ) : (
          <Icon name="home" size={30} />
        )}
      </div>
      <div className={s.textContainer}>
        <Typography.Text type="secondary">{t('guide_selected_header')}</Typography.Text>
        <Typography.Text size="16" weight="500">
          {props.text}
        </Typography.Text>
      </div>
      {!isBuildHeroCurrentSettlement && (
        <button
          className={s.edit}
          onClick={() => navigate(GUIDE_MY_TEMPORARY_RESIDENTIAL_CAMP_ROUTE)}>
          <Icon name="edit" />
        </button>
      )}
    </Card>
  );
};

export default GuideSelected;
