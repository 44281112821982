import React from 'react';

import { useNavigate } from 'react-router-dom';
import { getDocumentsStatementRequestRoute } from 'routes/documents/list';
import { PROFILE_ROUTE, PROFILE_VACATION_EMPLOYEES_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetKedoStatementByCodeQuery } from 'services/kedo/kedoApiService';
import {
  useGetLeaderSubdivisionsQuery,
  useGetVacationScheduleQuery,
} from 'services/me/meApiService';

import { Button, Flex, Typography } from 'gazprom-ui-lib';

import Empty from 'components/empty/empty';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import ProfileScheduleEmployee from 'pages/profile/profile-schedule/profile-schedule-employee';

import { VacationType } from 'types/vacation.types';

import s from './Vacation.module.scss';
import SingleVacation from './containers/single-vacation';

export const VACATION_STATEMENT_CODE = 'zayavlenieOperenoseOtpuska';

const Vacation = () => {
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data, isFetching } = useGetVacationScheduleQuery(
    {
      myEmployeeId: selectedEmployee?.id!,
    },
    {
      skip: !selectedEmployee?.id,
    },
  );

  const { data: employeesVacationData, isFetching: isEmployeesVacationDataFetching } =
    useGetLeaderSubdivisionsQuery(
      { myEmployeeId: selectedEmployee?.id! },
      { skip: !selectedEmployee?.id },
    );

  const { data: vacationStatement } = useGetKedoStatementByCodeQuery(
    {
      code: VACATION_STATEMENT_CODE,
      myEmployeeId: selectedEmployee?.id!,
    },
    {
      skip: !selectedEmployee?.id,
    },
  );

  const handleNavigate = () => {
    if (vacationStatement) {
      navigate(getDocumentsStatementRequestRoute(vacationStatement.id));
    }
  };

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('common_vacation_schedule')} />
    </>
  );

  const renderItem = (item: VacationType) => (
    <SingleVacation key={`${item.startDate}_${item.endDate}_${item.type}`} {...item} />
  );

  const showEmployeesVacation = process.env.true?.trim() === 'true';

  const isLoading = isFetching || isEmployeesVacationDataFetching;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <ProfileScheduleEmployee />

          {!!employeesVacationData && showEmployeesVacation && (
            <Card.Navigate to={PROFILE_VACATION_EMPLOYEES_ROUTE}>
              <Card.Default
                title={t('vacation_employees')}
                description={t('vacation_employees_description')}
                icon="groupsFilled"
                iconPadding="8"
              />
            </Card.Navigate>
          )}

          <Card withPadding={false}>
            <WithLoader isLoading={isLoading}>
              <div className={s.title}>
                <Typography.Title level={4}>{t('vacation_upcoming')}</Typography.Title>
              </div>
              {data?.length! > 0 ? (
                <div>
                  {data?.map(renderItem)}
                  {vacationStatement && (
                    <Flex fullWidth align="center" justify="center" className={s.requestButton}>
                      <Button
                        className={s.requestButton}
                        type="secondary"
                        fullWidth
                        size="large"
                        rightIcon="arrowRight"
                        onClick={handleNavigate}>
                        {t('vacation_reschedule')}
                      </Button>
                    </Flex>
                  )}
                </div>
              ) : (
                <div className={s.empty}>
                  <Empty
                    title={'vacation_empty_title'}
                    description={'vacation_empty_description'}
                  />
                </div>
              )}
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default Vacation;
