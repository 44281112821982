import React, { FC } from 'react';

import { FamilyType } from 'types/employee.types';

import PersonalDataDetailsItem from '../personal-data-details-item';
import s from './ProfilePersonalDataDetailsFamily.module.scss';
import { getPersonalDataGeneralItems } from './profilePersonalDataDetailsFamily.utils';

type ProfilePersonalDataDetailsFamilyProps = {
  familyData: FamilyType[];
};

const ProfilePersonalDataDetailsFamily: FC<ProfilePersonalDataDetailsFamilyProps> = (props) => {
  const { familyData } = props;

  const renderFamilyCard = (familyData: FamilyType) => (
    <div className={s.familyCard}>
      {getPersonalDataGeneralItems(familyData).map((familyItem) => (
        <PersonalDataDetailsItem key={familyItem.title} {...familyItem} />
      ))}
    </div>
  );

  return <>{familyData.map(renderFamilyCard)}</>;
};

export default ProfilePersonalDataDetailsFamily;
