import React, { FC } from 'react';

import { Typography } from 'gazprom-ui-lib';

import { AttributeDefaultProps } from '../attribute.utils';
import s from './AttributeHint.module.scss';

export interface AttributeHintProps extends AttributeDefaultProps {}

const AttributeHint: FC<AttributeHintProps> = (props) => {
  const { name } = props;

  return (
    <Typography.Title level={4} className={s.wrapper}>
      {name}
    </Typography.Title>
  );
};

export default AttributeHint;
