import { t } from 'tools/i18n';

import { DELIVER_OPTIONS_VALUE } from 'pages/documents/documents.utils';

export const DELIVER_OPTIONS = [
  { value: DELIVER_OPTIONS_VALUE.PERSONALLY, label: t('documents_certificate_label_personally') },
  { value: DELIVER_OPTIONS_VALUE.EMAIL, label: t('documents_certificate_label_email') },
  { value: DELIVER_OPTIONS_VALUE.ELECTRONIC, label: t('documents_certificate_label_electronic') },
  { value: DELIVER_OPTIONS_VALUE.MAIL, label: t('documents_certificate_label_mail') },
];
