import { LoginActions, LoginTermsAndAgreements } from 'pages/login';
import LoginGosuslugi from 'pages/login/login-gosuslugi';

import { RouteConfig } from '../routes';
import { LOGIN_ACTIONS_ROUTE, LOGIN_GOSUSLUGI_ROUTE, LOGIN_ROUTE } from './list';

export const loginRoutes: RouteConfig[] = [
  {
    path: LOGIN_ROUTE,
    element: <LoginTermsAndAgreements />,
  },
  { path: LOGIN_GOSUSLUGI_ROUTE, element: <LoginGosuslugi /> },
  { path: LOGIN_ACTIONS_ROUTE, element: <LoginActions /> },
];
