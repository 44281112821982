import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Typography, TypographyTitleLevel, TypographyWeight } from 'gazprom-ui-lib';

import s from './StoryTitle.module.scss';

interface StoryTitleProps extends PropsWithChildren {
  level?: TypographyTitleLevel;
  weight?: TypographyWeight;
  className?: string;
}

const StoryTitle: FC<StoryTitleProps> = (props) => {
  const { children, level = 4, className, ...otherProps } = props;

  return (
    <Typography.Title className={cn(s.wrapper, className)} level={level} {...otherProps}>
      {children}
    </Typography.Title>
  );
};

export default StoryTitle;
