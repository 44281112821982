import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import { Badge } from 'gazprom-ui-lib';

import s from './CardDot.module.scss';

interface CardDotProps extends PropsWithChildren {
  className?: string;
}

const CardDot: FC<CardDotProps> = (props) => {
  const { className, children } = props;

  return (
    <Badge dot offset={[-14, 14]} className={cn(s.wrapper, className)}>
      {children}
    </Badge>
  );
};

export default CardDot;
