import { FC, Fragment } from 'react';

import { Link } from 'react-router-dom';
import { GUIDE_MAP_ROUTE } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import Card from 'containers/card';

import { GuideMemoInstructionPhoneType } from 'types/guide.types';

import s from './GuideMemoStage.module.scss';
import { wrapLinksInAnchorTags } from './guideMemoStage.utils';

interface GuideMemoStageProps {
  stageNumber?: string | number;
  title: string;
  schedule: string;
  location: string;
  department: string;
  contacts: GuideMemoInstructionPhoneType[];
  withSteps: boolean;
  withMap: boolean;
  file: {
    link: string | null;
    name: string | null;
  } | null;
}

const GuideMemoStage: FC<GuideMemoStageProps> = (props) => {
  const {
    stageNumber = '',
    title,
    schedule,
    location,
    department,
    contacts,
    withSteps,
    withMap,
    file,
  } = props;

  const wrappedTitle = wrapLinksInAnchorTags(title);
  const wrappedLocation = wrapLinksInAnchorTags(location);
  const wrappedDepartment = wrapLinksInAnchorTags(department);
  const wrappedSchedule = wrapLinksInAnchorTags(schedule);

  const renderPhones = (param: GuideMemoInstructionPhoneType) => {
    const { phone, email, internalPhone } = param;

    return (
      <Fragment key={`${phone}__${email}`}>
        {phone && (
          <a
            href={`tel:${phone}`}
            className={s.phoneLink}
            onClick={(e) => {
              if (!phone) {
                e.preventDefault();
              }
            }}>
            <Card.Default
              title={phone}
              description={internalPhone ? `${t('common_inner_number')} - ${internalPhone}` : ''}
              icon="phoneFilled"
              className={s.card}
            />
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`} className={s.phoneLink}>
            <Card.Default title={email} icon="mail" className={s.cardMail} />
          </a>
        )}
      </Fragment>
    );
  };

  return (
    <div className={s.container}>
      <Flex vertical gap="4">
        {withSteps && (
          <Typography.Text size="14" weight="500" type="primary">
            {t('guide_selected_memo_stage', { number: stageNumber })}
          </Typography.Text>
        )}
        {wrappedTitle && (
          <Typography.Title level={5}>
            <span dangerouslySetInnerHTML={{ __html: wrappedTitle }} />
          </Typography.Title>
        )}
        {wrappedSchedule && (
          <Typography.Text size="12" weight="400" type="secondary">
            <span dangerouslySetInnerHTML={{ __html: wrappedSchedule }} />
          </Typography.Text>
        )}
      </Flex>
      <div className={s.mainContent}>
        {file?.link && <Cover src={file?.link} alt="" className={s.cover} clipped={false} />}
        {wrappedLocation && withMap ? (
          <Link to={GUIDE_MAP_ROUTE} className={s.mapLink}>
            <Card.Default
              title={<span dangerouslySetInnerHTML={{ __html: wrappedLocation }} />}
              description={<span dangerouslySetInnerHTML={{ __html: wrappedDepartment }} />}
              icon="map"
              className={s.card}
            />
          </Link>
        ) : (
          wrappedLocation && (
            <Card.Default
              title={<span dangerouslySetInnerHTML={{ __html: wrappedLocation }} />}
              description={<span dangerouslySetInnerHTML={{ __html: wrappedDepartment }} />}
              icon="map"
              className={s.card}
            />
          )
        )}
        {contacts?.map(renderPhones)}
      </div>
    </div>
  );
};

export default GuideMemoStage;
