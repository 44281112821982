import React from 'react';

import { t } from 'tools/i18n';

import { Radio } from 'gazprom-ui-lib';

export const renderWorkday = (vacation: string) => {
  const labelAndValue = t(`common_enum_work_type_${vacation.toLowerCase()}`);

  return (
    <Radio.Button key={labelAndValue} value={labelAndValue}>
      {labelAndValue}
    </Radio.Button>
  );
};

export enum WORKDAY_TYPE_ENUM {
  REGULAR = 'REGULAR',
  IRREGULAR = 'IRREGULAR',
}
