import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface ExitModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const ExitModal: FC<ExitModalProps> = (props) => {
  const { isOpen, handleClose } = props;

  const navigate = useNavigate();

  const navigateToProfile = () => navigate(PROFILE_ROUTE);

  return (
    <ModalDefault open={isOpen} onCancel={handleClose}>
      <ModalDefault.Icon name="attention" type="warning" />
      <ModalDefault.Title>{t('top_management_exit_modal_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('top_management_exit_modal_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" onClick={handleClose} fullWidth size="large">
          {t('top_management_main_button')}
        </Button>
        <Button type="secondary" onClick={navigateToProfile} fullWidth size="large">
          {t('top_management_secondary_button')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ExitModal;
