import { FC } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { HotContactType } from 'types/guide.types';

import s from './GuideImportantContactsContact.module.scss';

interface GuideImportantContactsContactProps {
  title: string;
  contacts: HotContactType[];
}

const GuideImportantContactsContact: FC<GuideImportantContactsContactProps> = (props) => {
  const { title, contacts } = props;

  const renderContacts = (params: HotContactType, index: number) => {
    const { phone, internalPhone, email } = params;

    return (
      <div className={s.contactsContainer} key={index}>
        {phone && (
          <a href={`tel:${phone}`}>
            <Card.Default
              title={phone}
              description={internalPhone ? `${t('common_inner_number')} - ${internalPhone}` : ''}
              icon="phoneFilled"
              className={s.card}
            />
          </a>
        )}
        {email && (
          <a href={`mailto:${email}`}>
            <Card.Default title={email} description="" icon="mail" className={s.cardMail} />
          </a>
        )}
      </div>
    );
  };

  return (
    <div className={s.container}>
      <Typography.Text size="14" weight="500">
        {title}
      </Typography.Text>
      {contacts.map(renderContacts)}
    </div>
  );
};

export default GuideImportantContactsContact;
