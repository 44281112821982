import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from '../Card.module.scss';

interface CardButtonProps extends PropsWithChildren {
  onClick: () => void;
  className?: string;
  withPadding?: boolean;
}

const CardButton: FC<CardButtonProps> = (props) => {
  const { onClick, children, className, withPadding = true } = props;

  return (
    <button
      onClick={onClick}
      className={cn(s.wrapper, className, { [s.withPadding]: withPadding })}>
      {children}
    </button>
  );
};

export default CardButton;
