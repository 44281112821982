import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { isValidDate } from 'containers/attribute/attribute-date/attributeDate.utils';

import { StatementAttributeValidation } from 'types/documents.types';
import { ProductCalendarType } from 'types/schedule.types';

export const formRules = {
  required: {
    required: true,
    message: t('validation_field_not_empty'),
  },
  validDate: (
    fieldName: string,
    validation: StatementAttributeValidation,
    productCalendar: ProductCalendarType,
  ) => ({
    message: validation.errorText,
    validator: async (_: unknown, value: string) => {
      if (isValidDate(dayjs(value), validation.code, productCalendar)) {
        await Promise.resolve();
      } else {
        await Promise.reject('error');
      }
    },
  }),
};
