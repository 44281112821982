import { createFileDownloadUrl } from 'utils/createMediaUrl.utils';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import KedoFile from 'components/kedo-file';

import { KEDO_COPY_FILE_TYPES, KedoTaskFile } from 'types/documents.types';

import s from './Files.module.scss';

export interface Props {
  files?: KedoTaskFile[];
  id?: string;
  assignmentId?: string;
  type?: KEDO_COPY_FILE_TYPES;
}

const Files = (props: Props) => {
  const { files, id, type, assignmentId } = props;

  const { handleOpenFile, loadingKey: loadingUrlKey } = useOpenFileByUrl();
  const { handleDownload, loadingKey } = useDownloadKedoFile();

  if (!files?.length || !id || !type) {
    return null;
  }

  const handleDownloadByURL = (fileKey: string) => {
    handleOpenFile({
      url: createFileDownloadUrl(fileKey),
      fileKey,
      withAuth: true,
    });
  };

  const renderFile =
    (taskId: string, fileType: KEDO_COPY_FILE_TYPES = KEDO_COPY_FILE_TYPES.KEDO_TASK) =>
    (file: KedoTaskFile) => {
      const { fileKey, fileKeyWithStamp } = file;

      if (!assignmentId) {
        return null;
      }

      const keyToUse = fileKeyWithStamp ?? fileKey;

      const handleDownloadFile = () => {
        if (fileType === KEDO_COPY_FILE_TYPES.KEDO_REVIEW_TASK) {
          handleDownloadByURL(keyToUse);
        } else {
          handleDownload({
            fileKey: keyToUse,
            taskId,
            isArchive: false,
            taskAssignmentId: assignmentId,
            copyFileType: fileType,
          })();
        }
      };

      const isLoading = loadingUrlKey === fileKey || loadingKey === fileKey;

      return (
        <KedoFile file={file} key={fileKey} onClick={handleDownloadFile} loading={isLoading} />
      );
    };

  return <div className={s.wrapper}>{files?.map(renderFile(id, type))}</div>;
};

export default Files;
