import { useEffect } from 'react';

import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetNewsCategoriesQuery } from 'services/category/categoryApiService';
import { useLazyGetEventsQuery } from 'services/me/meApiService';
import { addNews, resetEvents, resetPagination } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { SORT_ENUM } from 'types/sort.types';

interface UseRequestsProps {
  debouncedRequestBody: {
    page: number;
    size: number;
    showMyEvents: boolean;
    activeEventsTags: string[];
  };
  loadMoreRef: { current: boolean };
  isInitialised: boolean;
}

const useRequestsHook = (props: UseRequestsProps) => {
  const { debouncedRequestBody, loadMoreRef, isInitialised } = props;

  const dispatch = useAppDispatch();
  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: categoriesData, isLoading: categoriesIsLoading } = useGetNewsCategoriesQuery({
    page: 1,
    size: 30,
    type: 'meet',
  });

  const [
    getEvents,
    { data: eventsData, isFetching: eventsIsFetching, isLoading: eventsIsLoading, isUninitialized },
  ] = useLazyGetEventsQuery();

  useEffect(() => {
    return () => {
      dispatch(resetEvents());
      dispatch(resetPagination());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedEmployee && isInitialised) {
      getEvents({
        isParticipant: debouncedRequestBody.showMyEvents,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        sort: [{ order: SORT_ENUM.DESC, property: 'ARTICLE.publishFrom' }],
        categoryIds: debouncedRequestBody.activeEventsTags?.length
          ? debouncedRequestBody.activeEventsTags
          : undefined,
        myEmployeeId: selectedEmployee.id,
        gender: selectedEmployee.person.gender,
      })
        .then((res) => {
          if (res?.data && 'items' in res.data) {
            dispatch(addNews(res.data?.items));
          }
        })
        .finally(() => (loadMoreRef.current = true));
    }
  }, [
    selectedEmployee,
    debouncedRequestBody.page,
    debouncedRequestBody.size,
    debouncedRequestBody.activeEventsTags,
    debouncedRequestBody.showMyEvents,
    loadMoreRef,
    getEvents,
    isInitialised,
    dispatch,
  ]);

  const isFetching = eventsIsFetching || eventsIsLoading || categoriesIsLoading || isUninitialized;

  return {
    isLoading: eventsIsLoading,
    isFetching,
    categoriesData,
    eventsData,
  };
};

export default useRequestsHook;
