import React, { FC } from 'react';

import cn from 'classnames';

import { Icon, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import WithLoader from 'containers/wrappers/with-loader';

import { KedoTaskFile } from 'types/documents.types';
import { FileType } from 'types/file.types';

import s from './KedoFile.module.scss';

interface KedoFileProps {
  file: KedoTaskFile | FileType;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  fileName?: string;
  isAttributeFile?: boolean;
}

const KedoFile: FC<KedoFileProps> = (props) => {
  const { file, className, onClick, disabled, loading, fileName, isAttributeFile } = props;

  return (
    <button
      className={cn(s.wrapper, className, isAttributeFile && s.attributeFile)}
      onClick={onClick}
      disabled={disabled}>
      <div>
        {isAttributeFile ? (
          <Icon name="documents" className={s.docIcon} />
        ) : (
          <div className={s.docIconWrapper}>
            <Icon name="documents" />
          </div>
        )}
        <Typography.Text weight="500" size={isAttributeFile ? '16' : '14'}>
          {fileName ?? file?.fileName}
        </Typography.Text>
      </div>
      <WithLoader isLoading={loading} loader={<UniversalLoader size="24" />}>
        <Icon name="download" className={s.downloadIcon} />
      </WithLoader>
    </button>
  );
};

export default KedoFile;
