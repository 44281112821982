import { useMatch, useParams } from 'react-router-dom';
import {
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
  DOCUMENTS_TASKS_DETAILS_ROUTE,
} from 'routes/documents/list';

import { useGetKedoTaskByIdQuery } from 'services/kedo/kedoApiService';

import { TASK_TYPE_ENUM } from 'types/documents.types';

const useTask = () => {
  const params = useParams<{ taskId: string }>();

  const isSocialPolicyRoute = !!useMatch(DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE);
  const isSocialPolicyAttributesRoute = !!useMatch(
    DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
  );
  const isStatementRoute = !!useMatch(DOCUMENTS_STATEMENTS_DETAILS_ROUTE);
  const isStatementAttributesRoute = !!useMatch(DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE);
  const isTaskRoute = !!useMatch(DOCUMENTS_TASKS_DETAILS_ROUTE);

  let type = TASK_TYPE_ENUM.KEDO;

  if (isSocialPolicyRoute || isSocialPolicyAttributesRoute) {
    type = TASK_TYPE_ENUM.SOCIAL;
  } else if (isStatementRoute || isStatementAttributesRoute) {
    type = TASK_TYPE_ENUM.BPMN;
  }

  return useGetKedoTaskByIdQuery(
    {
      taskId: params?.taskId!,
      type,
    },
    {
      skip: !params?.taskId || isTaskRoute,
      refetchOnMountOrArgChange: !!process.env.true?.trim(),
    },
  );
};

export default useTask;
