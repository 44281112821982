import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { FORM_ENUM } from '../../businessTripForm.utils';
import PopoverTitle from '../approvers/containers/popover-title';
import s from './Destination.module.scss';

const renderPopOverContent = (title: string, description: string) => {
  return (
    <Flex vertical gap="4">
      <Typography.Text size="14" weight="500">
        {title}
      </Typography.Text>
      <Typography.Text size="12">{description}</Typography.Text>
    </Flex>
  );
};

const Destination = () => {
  return (
    <Flex vertical gap="24" className={s.container}>
      <Typography.Title level={5}>{t('common_destination')}</Typography.Title>
      <Flex vertical gap="12">
        <PopoverTitle
          popoverTitle={t('common_country')}
          popoverContent={renderPopOverContent(
            t('common_country'),
            t('documents_business_trip_select_countries'),
          )}
        />
        <Form.Item name={FORM_ENUM.COUNTRY} rules={[formRules.required]}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </Flex>
      <Flex vertical gap="12">
        <PopoverTitle
          popoverTitle={t('common_locality')}
          popoverContent={renderPopOverContent(
            t('common_locality'),
            t('documents_business_trip_select_localities'),
          )}
        />
        <Form.Item name={FORM_ENUM.TOWN} rules={[formRules.required]}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </Flex>
      <Flex vertical gap="12">
        <PopoverTitle
          popoverTitle={t('common_arrangement_appointment')}
          popoverContent={renderPopOverContent(
            t('common_arrangement_appointment'),
            t('documents_business_trip_select_arrangement_appointment'),
          )}
        />
        <Form.Item name={FORM_ENUM.DESTINATION_ORG} rules={[formRules.required]}>
          <Input placeholder={t('common_enter_text')} />
        </Form.Item>
      </Flex>
    </Flex>
  );
};

export default Destination;
