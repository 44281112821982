import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Button, Card, Flex, Form, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { ExperienceType } from 'types/topManagement.types';

import Collapse from '../collapse';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import ExperienceCard from './containers/experience-card';
import useWorkExperienceInfoForm from './useWorkExperienceInfoForm.hook';
import { MappedExperienceType } from './workExperienceInfoForm.utils';

const WorkExperienceInfoForm = () => {
  const {
    customSections,
    educationData,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    handleForm,
    addCustomSection,
    removeCustomSection,
  } = useWorkExperienceInfoForm();

  const renderExperienceCards = (data: ExperienceType) => {
    const {
      name,
      activity,
      address,
      dateFinish,
      dateStart,
      functions,
      phone,
      position,
      reasonForDismissal,
    } = data;

    return (
      <Card>
        <Collapse
          noPadding
          items={[
            {
              key: `${name + position}`,
              label: <Typography.Title level={5}>{name}</Typography.Title>,
              children: (
                <>
                  <DataPresenter title={t('common_organization_address')} text={address} />
                  <DataPresenter title={t('common_phone')} text={phone} />
                  <DataPresenter title={t('common_activity_field')} text={activity} />
                  <DataPresenter
                    title={t('top_management_work_experience_start')}
                    text={dayjs(dateStart).format('DD.MM.YYYY')}
                  />
                  <DataPresenter
                    title={t('top_management_work_experience_finish')}
                    text={dayjs(dateFinish).format('DD.MM.YYYY')}
                  />
                  <DataPresenter title={t('common_staff_name')} text={position} />
                  <DataPresenter
                    title={t('top_management_work_experience_functions')}
                    text={functions}
                  />
                  <DataPresenter
                    title={t('top_management_work_experience_reason')}
                    text={reasonForDismissal}
                  />
                </>
              ),
            },
          ]}
        />
      </Card>
    );
  };

  const renderCustomSections = (element: MappedExperienceType) => {
    const { id, ...rest } = element || {};

    return (
      <ExperienceCard
        id={id}
        onClick={removeCustomSection(id)}
        key={id}
        experienceInitialData={rest}
      />
    );
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_work_experience_title')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>
          {educationData?.map(renderExperienceCards)}

          <Flex gap="16" vertical>
            {customSections.map(renderCustomSections)}
          </Flex>

          <Card>
            <Button
              type="secondary"
              fullWidth
              size="large"
              htmlType="button"
              leftIcon="plus"
              onClick={addCustomSection}>
              {t('top_management_work_experience_add_button')}
            </Button>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default WorkExperienceInfoForm;
