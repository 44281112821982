import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Button, Card, Flex, Form, Radio, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { LanguagesType } from 'types/topManagement.types';

import Collapse from '../collapse';
import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import s from './LanguagesForm.module.scss';
import LanguageCard from './containers/language-card';
import { LANGUAGES_FORM_ENUM, MappedLanguagesType, radioOptions } from './languagesForm.utils';
import useLanguageForm from './useLanguageForm.hook';

const LanguageForm = () => {
  const {
    languageData,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    customSections,
    addCustomSection,
    handleForm,
    removeCustomSection,
  } = useLanguageForm();

  const renderEducationCards = (data: LanguagesType, index: number) => {
    const { language, level } = data;
    const key = `${language}_${index}`;

    return (
      <Card key={key}>
        <Collapse
          noPadding
          defaultActiveKey={key}
          items={[
            {
              key,
              label: <Typography.Title level={5}>{language}</Typography.Title>,
              children: (
                <>
                  <Form.Item
                    name={`${LANGUAGES_FORM_ENUM.LANGUAGE}_${index}`}
                    initialValue={language}
                    hidden
                  />
                  <Form.Item
                    name={`${LANGUAGES_FORM_ENUM.LEVEL}_${index}`}
                    label={
                      <Typography.Title level={5}>
                        {t('top_management_languages_level')}
                      </Typography.Title>
                    }
                    initialValue={level}
                    rules={[formRules.required]}>
                    <Radio.Group options={radioOptions} className={s.radioGroup} />
                  </Form.Item>
                </>
              ),
            },
          ]}
        />
      </Card>
    );
  };

  const renderCustomSections = (element: MappedLanguagesType) => {
    const { id, ...rest } = element || {};

    return <LanguageCard id={id} onClick={removeCustomSection(id)} key={id} initialData={rest} />;
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_languages_long')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>
          {languageData?.map(renderEducationCards)}

          <Flex gap="16" vertical>
            {customSections.map(renderCustomSections)}
          </Flex>

          <Card>
            <Button
              type="secondary"
              fullWidth
              size="large"
              htmlType="button"
              leftIcon="plus"
              onClick={addCustomSection}>
              {t('top_management_languages_add')}
            </Button>
          </Card>
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default LanguageForm;
