import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './Story.module.scss';

interface StoryProps extends PropsWithChildren {
  className?: string;
}

const Story: FC<StoryProps> = (props) => {
  const { children, className } = props;

  return <div className={cn(s.wrapper, className)}>{children}</div>;
};

export default Story;
