import { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CancellationModalProps {
  isOpen: boolean;
  onClickHandler: () => void;
  onCancelHandler: () => void;
}

const CancellationModal: FC<CancellationModalProps> = (props) => {
  const { isOpen, onClickHandler, onCancelHandler } = props;

  return (
    <ModalDefault open={isOpen} onCancel={onCancelHandler}>
      <ModalDefault.Icon name="attention" type="warning" />
      <ModalDefault.Title>{t('documents_cancel_order_confirm')}</ModalDefault.Title>
      <ModalDefault.Description>{t('documents_cancel_order_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          rightIcon="arrowRight"
          type="primary"
          size="large"
          fullWidth
          onClick={onClickHandler}>
          {t('common_confirm')}
        </Button>
        <Button ghost type="primary" size="large" fullWidth onClick={onCancelHandler}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CancellationModal;
