import { appSlice } from 'slices/appSlice';
import { eventsSlice } from 'slices/eventsSlice';
import { kedoSlice } from 'slices/kedo';
import { newsSlice } from 'slices/newsSlice';
import { employeeSlice } from 'store/slices/employeeSlice';
import { guideSlice } from 'store/slices/guideSlice';

import { guideSettlementPostersSlice } from './guideSettlementPostersSlice';
import { secureStorageSlice } from './secureStorageSlice';
import { surveySlice } from './surveySlice';

export const slices = {
  [appSlice.name]: appSlice.reducer,
  [employeeSlice.name]: employeeSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
  [newsSlice.name]: newsSlice.reducer,
  [secureStorageSlice.name]: secureStorageSlice.reducer,
  [surveySlice.name]: surveySlice.reducer,
  [guideSlice.name]: guideSlice.reducer,
  [guideSettlementPostersSlice.name]: guideSettlementPostersSlice.reducer,
  [kedoSlice.name]: kedoSlice.reducer,
};
