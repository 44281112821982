import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_CERTIFICATE_ROUTE, DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import DocumentsAssignment from 'containers/document-assignment';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { getFileConfig } from '../documents.utils';
import s from './DocumentsAssignmentSignList.module.scss';
import ModalError from './containers/modal-error';
import useHandleSign from './useHandleSign.hook';

const DocumentsAssignmentSignList = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t('documents_sign_title')} />
      <Header.RightIcon
        iconName={'documents'}
        onClick={() => navigate(DOCUMENTS_CERTIFICATE_ROUTE)}
      />
    </>
  );

  const { isLoading, handleSign } = useHandleSign({ setIsModalVisible });

  if (!state?.assignment) {
    return null;
  }

  const { assignment } = state;
  const fileConfig = getFileConfig(assignment);

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <Card withPadding={false}>
              <div className={s.title}>
                <Typography.Title level={4}>{t('documents_sign_list_title')}</Typography.Title>
              </div>
              <DocumentsAssignment.Files {...fileConfig} />
              <div className={s.button}>
                <Button
                  rightIcon="arrowRight"
                  fullWidth
                  onClick={handleSign}
                  size="large"
                  loading={isLoading}>
                  {t('documents_sign_button')}
                </Button>
              </div>
            </Card>
            <Card className={s.tooltipCard}>
              <Icon name="info" className={s.icon} />
              <Typography.Text size="14">{t('documents_sign_tooltip')}</Typography.Text>
            </Card>
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <ModalError isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
    </>
  );
};

export default DocumentsAssignmentSignList;
