import { useContext } from 'react';

import { t } from 'i18next';

import { Steps, StepsProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { TASK_HISTORY_STATUS_ENUM } from 'types/documents.types';

import { AssignmentContext } from '../../documentsAssignment';
import s from './History.module.scss';

interface Props {
  taskIsLoading?: boolean;
  history?: StepsProps['items'];
}

const History = (props: Props) => {
  const { taskIsLoading, history } = props;

  const assignment = useContext(AssignmentContext);

  const assignmentHistory = assignment?.kedoTask?.history ?? [];
  const status = assignment?.kedoTask?.currentKedoStatus?.status;

  const itemsToShow = history ?? assignmentHistory;

  const showTaskHistory = !!itemsToShow?.length && status !== TASK_HISTORY_STATUS_ENUM.HR_EXECUTED;

  if (!taskIsLoading && !showTaskHistory) {
    return null;
  }

  return (
    <Card>
      <Typography.Title level={5}>{t('documents_coordination_route')}</Typography.Title>
      <Steps className={s.steps} items={itemsToShow} />
    </Card>
  );
};

export default History;
