import React from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { MORE_ROUTE } from 'routes/more/list';
import { t } from 'tools/i18n';

import { useGetArticleByIdQuery } from 'services/article/articleApiService';

import { Typography } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './GuideEducationDescription.module.scss';
import { EDUCATION_SECTIONS, EducationSectionType } from './guideEducationDescription.utils';

const GuideEducationDescription = () => {
  const { isLoading: mainIsLoading, data: mainData } =
    useGetArticleByIdQuery('education-main-text');
  const { isLoading: contactsIsLoading, data: contactsData } =
    useGetArticleByIdQuery('education-contacts-text');

  const headerChildren = (
    <>
      <Header.GoBack to={MORE_ROUTE} />
      <Header.Label label={t('common_education')} />
    </>
  );

  const renderEducationSection = ({ title, link, description, icon }: EducationSectionType) => (
    <Card.Navigate key={title} to={link}>
      <Card.Default
        className={s.educationSectionCard}
        title={title}
        description={description}
        icon={icon}
      />
    </Card.Navigate>
  );

  const isLoading = contactsIsLoading || mainIsLoading;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false}>
              <Cover alt="education cover" src={mainData?.cover?.link} />
              <Typography.Title level={4} className={s.title}>
                {mainData?.title}
              </Typography.Title>
              {mainData?.text && (
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mainData.text) }} />
              )}
            </Card>
            {EDUCATION_SECTIONS.map(renderEducationSection)}
            <Card withPadding={false}>
              <Cover alt="education cover" src={contactsData?.cover?.link} />
              <Typography.Title level={4} className={s.title}>
                {contactsData?.title}
              </Typography.Title>
              {contactsData?.text && (
                <Typography.Text>
                  <span
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contactsData.text) }}
                  />
                </Typography.Text>
              )}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideEducationDescription;
