import { t } from 'tools/i18n';

import { SoftwareSkillsType } from 'types/topManagement.types';

export enum SOFTWARE_LEVELS {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}

export enum SOFTWARE_FORM_ENUM {
  SOFTWARE_NAME = 'softwareName',
  LEVEL = 'level',
  IS_ENTERED_BY_USER = 'isEnteredByUser',
}

export const radioOptions = [
  {
    label: t('top_management_software_beginner'),
    value: SOFTWARE_LEVELS.BEGINNER,
  },
  {
    label: t('top_management_software_middle'),
    value: SOFTWARE_LEVELS.INTERMEDIATE,
  },
  {
    label: t('top_management_software_advanced'),
    value: SOFTWARE_LEVELS.ADVANCED,
  },
];

export type MappedSoftwareSkillsType = Partial<SoftwareSkillsType> & { id: number };
