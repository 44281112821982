import React, { ReactNode } from 'react';

import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';

export type SegmentedValueType = 'sent' | 'draft';

export const SEGMENT_OPTIONS: {
  value: SegmentedValueType;
  label: ReactNode;
}[] = [
  {
    label: <Typography.Title level={5}>{t('common_sent_applications')}</Typography.Title>,
    value: 'sent',
  },
  {
    label: <Typography.Title level={5}>{t('common_drafts')}</Typography.Title>,
    value: 'draft',
  },
];

export const DEFAULT_PAGINATION_CONFIG = {
  page: 1,
  size: 20,
};
export const headerChildren = (
  <>
    <Header.GoBack to={DOCUMENTS_ROUTE} useDefaultGoBack />
    <Header.Label label={t('documents_inquiries')} />
  </>
);
