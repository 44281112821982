import { FC, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import s from './YoutubePlayer.module.scss';
import { getPlayerHeight, getYoutubeVideoId } from './YoutubePlayer.utils';

interface YoutubePlayerProps {
  src: string;
  title?: string;
  className?: string;
}

const YoutubePlayer: FC<YoutubePlayerProps> = (props) => {
  const { src, title, className } = props;

  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  const containerRef = useRef<HTMLDivElement>(null);

  const videoUrl = useMemo(() => getYoutubeVideoId(src), [src]);

  useEffect(() => {
    const container = containerRef?.current;

    if (!container) return;

    const observer = new ResizeObserver(() => {
      const { width } = container.getBoundingClientRect();

      setSizes({ width, height: getPlayerHeight(width) });
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (!videoUrl) {
    return null;
  }

  return (
    <div ref={containerRef} className={cn(s.player, className)}>
      <iframe title={title} src={videoUrl} allowFullScreen {...sizes} />
    </div>
  );
};

export default YoutubePlayer;
