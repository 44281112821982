import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';

import { KedoTaskFile } from 'types/documents.types';
import { SUGGESTION_NEED_EXPENSES_ENUM, SuggestionFileType } from 'types/suggestion.types';

import { getNeedExpensesTextValue } from '../moreSuggestionDecisionDetails.utils';
import useDownloadFile from './useDownloadFile';

interface SuggestionAttributeProps {
  value: number | string | boolean | SuggestionFileType[];
  attributeId: string;
  suggestionId: string;
}

const SuggestionAttribute: FC<SuggestionAttributeProps> = (props) => {
  const { value, attributeId, suggestionId } = props;

  const { handleDownload, loadingKey } = useDownloadFile();

  const renderFile = (file: SuggestionFileType) => {
    const { fileKey, fileName } = file;

    return (
      <KedoFile
        loading={loadingKey === fileKey}
        isAttributeFile
        file={file as KedoTaskFile}
        key={fileKey}
        onClick={handleDownload({ fileKey, suggestionId: suggestionId, fileName: fileName })}
      />
    );
  };

  if (attributeId === 'needExpenses') {
    return (
      <Typography.Title level={5}>
        {getNeedExpensesTextValue(value as SUGGESTION_NEED_EXPENSES_ENUM)}
      </Typography.Title>
    );
  }

  if (attributeId === 'personalParticipation') {
    return (
      <Typography.Title level={5}>{!!value ? t('common_yes') : t('common_no')}</Typography.Title>
    );
  }

  if (attributeId === 'problemFiles' || attributeId === 'suggestionFiles') {
    const files = value ? [...(value as SuggestionFileType[])] : [];
    return files?.length ? (
      <>{files.map(renderFile)}</>
    ) : (
      <Typography.Title level={5}>{'-'}</Typography.Title>
    );
  }

  return <Typography.Title level={5}>{value as string}</Typography.Title>;
};

export default SuggestionAttribute;
