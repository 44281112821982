import React, { FC, ReactElement, useState } from 'react';

import { Flex, Icon, Popover, Typography } from 'gazprom-ui-lib';

import PopoverContent from '../popover-content';
import s from './PopoverTitle.module.scss';

interface PopoverTitleProps {
  popoverTitle: string;
  popoverContent: string | ReactElement;
}

const PopoverTitle: FC<PopoverTitleProps> = (props) => {
  const { popoverContent, popoverTitle } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Popover
      content={<PopoverContent onClose={handleClose}>{popoverContent}</PopoverContent>}
      arrow={false}
      placement="bottomRight"
      onOpenChange={setIsOpened}
      open={isOpened}>
      <Flex align="center" gap="4" className={s.wrapper}>
        <Typography.Text size="16" weight="500">
          {popoverTitle}
        </Typography.Text>
        <Icon name="info" />
      </Flex>
    </Popover>
  );
};

export default PopoverTitle;
