import React, { useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE,
  DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
  getDocumentsInquiriesRequestRoute,
  getDocumentsStatementRequestRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { useGetKedoCategoriesQuery, useGetKedoStatementsQuery } from 'services/kedo/kedoApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import TagButton from 'components/tag-button';
import Card from 'containers/card';
import Header from 'containers/header';
import StatementUnavailableModal from 'containers/statement-unavailable-modal';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import useIsStatementUnavailable from 'pages/documents/useIsStatementUnavailable.hook';

import {
  KedoCategory,
  KedoStatement,
  STATEMENT_CREATOR_TYPE_ENUM,
  TASK_TYPE_ENUM,
} from 'types/documents.types';

import s from './DocumentsCreateChooseCategory.module.scss';

const DocumentsCreateChooseCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeCategory, setActiveCategory] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isStatement = location.pathname === DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE;
  const type = isStatement ? TASK_TYPE_ENUM.BPMN : TASK_TYPE_ENUM.KEDO;

  const { data: categories, isLoading: isCategoriesLoading } = useGetKedoCategoriesQuery({
    type,
  });
  const { data: statements, isLoading: isStatementsLoading } = useGetKedoStatementsQuery({
    type,
    creatorType: STATEMENT_CREATOR_TYPE_ENUM.HUMAN,
  });

  useEffect(() => {
    if (categories) {
      setActiveCategory(categories[0]?.id);
    }
  }, [categories]);

  useEffect(() => {
    if (location?.state?.categoryId) {
      setActiveCategory(location.state.categoryId);
    }
  }, [location]);

  const filteredStatements = useMemo(() => {
    return statements?.filter((statement) => statement.categoryId === activeCategory) ?? [];
  }, [statements, activeCategory]);

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t(isStatement ? 'documents_apply' : 'documents_inquiries_request')} />
    </>
  );

  const renderCategory = (category: KedoCategory) => (
    <TagButton
      key={category.id}
      id={category.id}
      name={category.name}
      isActive={activeCategory === category.id}
      toggleIsActive={(id) => setActiveCategory(id)}
    />
  );

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleNavigateCreate = (id: string) => () => {
    navigate(
      isStatement ? getDocumentsStatementRequestRoute(id) : getDocumentsInquiriesRequestRoute(id),
    );
  };
  const { getIsStatementUnavailable, leadersIsFetching } = useIsStatementUnavailable();
  const renderStatementItem = (statement: KedoStatement) => {
    const { id, name } = statement;

    const statementUnavailable = getIsStatementUnavailable(statement);

    return (
      <button
        className={s.statementItem}
        key={id}
        onClick={statementUnavailable ? handleModalOpenClose : handleNavigateCreate(id)}>
        <Typography.Title level={5}>{name}</Typography.Title>
        <Icon
          name={statementUnavailable ? 'error' : 'forward'}
          color={statementUnavailable ? 'var(--color-error)' : 'var(--color-primary-6)'}
        />
      </button>
    );
  };

  const handleSearch = () => {
    navigate(
      isStatement
        ? DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE
        : DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
    );
  };

  const isLoading = isCategoriesLoading || isStatementsLoading || leadersIsFetching;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <WithLoader isLoading={isLoading}>
              <div className={s.inner}>
                <div className={s.top}>
                  <Typography.Title level={4}>
                    {t(
                      isStatement
                        ? 'documents_available_statement'
                        : 'documents_available_inquiries',
                    )}
                  </Typography.Title>
                  <button onClick={handleSearch}>
                    <Icon name="search" />
                  </button>
                </div>

                <div className={s.categories}>{categories?.map(renderCategory)}</div>
              </div>
              <div className={s.statements}>{filteredStatements.map(renderStatementItem)}</div>
            </WithLoader>
          </Card>
          <StatementUnavailableModal open={isModalOpen} onClose={handleModalOpenClose} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsCreateChooseCategory;
