import { Fragment } from 'react';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  getDocumentsStatementRequestRoute,
  getTopManagementByStageRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetKedoStatementsQuery } from 'services/kedo/kedoApiService';
import {
  useGetRenewEmployeeFormQuery,
  useUpdateRenewEmployeeFormMutation,
} from 'services/top-management/topManagementApiService';

import { Card, Flex, Typography, handleRequest } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { TASK_CODE_ENUM, TASK_TYPE_ENUM } from 'types/documents.types';
import { HistoryChangeFullNameType } from 'types/employee.types';
import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import ApplyStatement from '../apply-statement';
import DataPresenter from '../data-presenter';
import NextStepButton from '../next-step-button';
import Title from '../title';
import usePersonalData from '../verification-form/usePersonalData.hook';
import WithBottomButton from '../with-bottom-button';
import s from './CommonInfoForm.module.scss';
import { DOCUMENT_TYPES } from './CommonInfoForm.utils';

const CommonInfoForm = () => {
  const navigate = useNavigate();
  const { personalData } = usePersonalData();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { data: renewEmployeeData } = useGetRenewEmployeeFormQuery({
    myEmployeeId: selectedEmployee?.id!,
  });
  const [
    requestStatements,
    { data: requestStatementsData, isLoading: requestStatementsDataIsLoading },
  ] = useLazyGetKedoStatementsQuery();
  const [updateFormData, { isLoading: updateFormDataIsLoading }] =
    useUpdateRenewEmployeeFormMutation();

  const {
    firstName,
    lastName,
    patronymic,
    gender,
    historyChangeFullName,
    birthDate,
    birthPlace,
    identityDocs,
    citizenship,
    inn,
    snils,
    registrationAddress,
    residentialAddress,
  } = personalData || {};

  const statementId = requestStatementsData?.[0]?.id;
  const statementUrl = statementId && getDocumentsStatementRequestRoute(statementId);
  const { type, series, number, issueDate, issuingAuthority } = identityDocs?.[0] || {};
  const documentType = DOCUMENT_TYPES[type as keyof typeof DOCUMENT_TYPES];
  const genderName = gender === 'male' ? t('common_male_gender') : t('common_female_gender');

  const requestStatement = () =>
    requestStatements({
      type: TASK_TYPE_ENUM.BPMN,
      code: TASK_CODE_ENUM.ZAYAVLENIE_NA_IZMENENIE_PERSONALNYKH_DANNYKH,
    });

  const handleNextStep = () => {
    updateFormData({
      id: renewEmployeeData?.id!,
      myEmployeeId: selectedEmployee?.id!,
      body: {
        formStep: TOP_MANAGEMENT_STAGE_ENUM.INTERNATIONAL,
      },
    }).then(
      handleRequest({
        onSuccess: () =>
          navigate(getTopManagementByStageRoute(TOP_MANAGEMENT_STAGE_ENUM.INTERNATIONAL)),
      }),
    );
  };

  const renderHistoryName = (data: HistoryChangeFullNameType) => {
    const { firstNameNew, lastNameNew, dateChange } = data;

    return (
      <Fragment key={firstNameNew + lastNameNew + dateChange}>
        {firstNameNew && (
          <Flex gap="8" vertical className={s.additionalField} data-border="true">
            <Typography.Title level={5}>
              {t('top_management_general_first_name_change_title')}
            </Typography.Title>
            <div>
              <DataPresenter title={t('common_new_first_name')} text={firstNameNew} />
              <DataPresenter
                title={t('common_first_name_date_change')}
                text={dayjs(dateChange).format('DD.MM.YYYY')}
              />
            </div>
          </Flex>
        )}
        {lastNameNew && (
          <Flex gap="8" vertical className={s.additionalField} data-border="true">
            <Typography.Title level={5}>
              {t('top_management_general_last_name_change_title')}
            </Typography.Title>
            <div>
              <DataPresenter title={t('common_new_last_name')} text={lastNameNew} />
              <DataPresenter
                title={t('common_last_name_date_change')}
                text={dayjs(dateChange).format('DD.MM.YYYY')}
              />
            </div>
          </Flex>
        )}
      </Fragment>
    );
  };

  return (
    <WithLoader isLoading={!personalData}>
      <WithBottomButton
        button={
          <NextStepButton
            text={t('common_all_ok_continue')}
            onClick={handleNextStep}
            isLoading={updateFormDataIsLoading}
          />
        }>
        <Card className={s.card}>
          <Title
            title={t('my_personal_data_general')}
            subtitle={t('top_management_general_data_subtitle')}
          />
          <DataPresenter title={t('common_last_name')} text={lastName} />
          <DataPresenter title={t('common_first_name')} text={firstName} />
          <DataPresenter title={t('common_patronymic')} text={patronymic} />
          {historyChangeFullName?.map(renderHistoryName)}
          <div className={s.dataContainer}>
            <DataPresenter title={t('my_personal_data_gender')} text={genderName} />
            <DataPresenter
              title={t('common_birthdate')}
              text={dayjs(birthDate).format('DD.MM.YYYY')}
            />
          </div>
          <DataPresenter title={t('common_birthplace')} text={birthPlace} />
          <div className={s.dataContainer}>
            <DataPresenter title={t('my_personal_data_document_type')} text={documentType} />
            <DataPresenter title={t('my_personal_data_citizenship')} text={citizenship} />
          </div>
          <div className={s.dataContainer}>
            <DataPresenter
              title={t('common_passport_serial_and_number')}
              text={`${series} ${number}`}
            />
            <DataPresenter
              title={t('common_issue_date')}
              text={dayjs(issueDate).format('DD.MM.YYYY')}
            />
          </div>

          <DataPresenter title={t('common_issued')} text={issuingAuthority} />

          <div className={s.dataContainer}>
            <DataPresenter title={t('my_personal_data_inn')} text={inn} />
            <DataPresenter title={t('my_personal_data_snils')} text={snils} />
          </div>

          <DataPresenter
            title={t('my_personal_data_registration_address')}
            text={registrationAddress}
          />
          <DataPresenter
            title={t('my_personal_data_residence_address')}
            text={residentialAddress}
          />
        </Card>

        <ApplyStatement
          to={statementUrl ?? ''}
          onModalOpen={requestStatement}
          loading={requestStatementsDataIsLoading}
        />
      </WithBottomButton>
    </WithLoader>
  );
};

export default CommonInfoForm;
