import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Card, Checkbox, Flex, Form, TextArea, Typography } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import { FamilyType } from 'types/topManagement.types';

import NextStepButton from '../next-step-button';
import Title from '../title';
import WithBottomButton from '../with-bottom-button';
import CustomSection from './containers/custom-section';
import FamilyCard from './containers/family-card';
import { FAMILY_FORM_ENUM } from './familyForm.utils';
import useFamilyForm from './useFamilyForm.hook';

const FamilyForm = () => {
  const {
    initialFamily,
    generateFilesIsLoading,
    criminal,
    criminalDetail,
    initialCriminal,
    form,
    renewEmployeeDataIsLoading,
    updateFormDataIsLoading,
    handleForm,
    removeInitialSection,
  } = useFamilyForm();

  const renderFamilyCards = (data: FamilyType & { id: number }) => {
    const { id } = data;
    return (
      <FamilyCard data={data} index={id} key={id + 'initial'} onClick={removeInitialSection(id)} />
    );
  };

  return (
    <WithLoader isLoading={renewEmployeeDataIsLoading}>
      <Form form={form} onFinish={handleForm}>
        <WithBottomButton
          button={
            <NextStepButton
              text={t('common_all_ok_continue')}
              isLoading={updateFormDataIsLoading || generateFilesIsLoading}
            />
          }>
          <Card>
            <Title
              title={t('top_management_family')}
              subtitle={t('top_management_general_data_subtitle')}
            />
          </Card>

          <Card>
            <Flex gap="24" vertical>
              <Form.Item
                name={FAMILY_FORM_ENUM.CRIMINAL}
                valuePropName="checked"
                label={
                  <Typography.Title level={5}>
                    {t('top_management_family_responsibility')}
                  </Typography.Title>
                }
                initialValue={initialCriminal}>
                <Checkbox>{t('common_yes_have')}</Checkbox>
              </Form.Item>
              {criminal && (
                <Form.Item
                  name={FAMILY_FORM_ENUM.CRIMINAL_DETAILS}
                  label={
                    <Typography.Title level={5}>
                      {t('top_management_family_responsibility_detail')}
                    </Typography.Title>
                  }
                  rules={[formRules.required]}
                  initialValue={criminalDetail}>
                  <TextArea size="large" placeholder={t('common_enter_text')} />
                </Form.Item>
              )}
            </Flex>
          </Card>

          {initialFamily?.map(renderFamilyCards)}
          <CustomSection />
        </WithBottomButton>
      </Form>
    </WithLoader>
  );
};

export default FamilyForm;
