import React, { ReactNode, useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Segmented, SegmentedProps } from 'gazprom-ui-lib';

import Card from 'containers/card';
import EventsContent from 'containers/events-content';
import NewsContent from 'containers/news-content';
import WithBackground from 'containers/wrappers/with-background';

import workTogetherWhiteV2 from 'assets/icons/workTogetherWhiteV2.png';

import s from './HomeNewsList.module.scss';
import { SEGMENT_NEWS_EVENT_TYPE, SEGMENT_NEWS_TYPE, SEGMENT_OPTIONS } from './homeNewsList.utils';

const HomeNewsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ newsCode: string }>();

  const [segment, setSegment] = useState<string>('');

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) => {
    const stringifiedSegment = segment.toString();

    setSegment(stringifiedSegment);
    navigate(location.pathname, { state: { ...location?.state, type: stringifiedSegment } });
  };

  const content: Record<string, ReactNode> = {
    [SEGMENT_NEWS_TYPE]: <NewsContent />,
    [SEGMENT_NEWS_EVENT_TYPE]: <EventsContent />,
  };

  useEffect(() => {
    if (location?.state?.type && typeof location?.state?.type === 'string') {
      setSegment(location?.state?.type);
    } else {
      setSegment(SEGMENT_OPTIONS[0].value);
    }
  }, [location?.state?.type]);

  return (
    <WithBackground className={cn(s.relative, { [s.modalOpenedWrapper]: params.newsCode })}>
      <Card className={s.newsHeader} withPadding={false}>
        <img src={workTogetherWhiteV2} alt="news header background" />
      </Card>
      <Card withPadding={false}>
        <div className={cn(s.segmentedWrapper, { [s.segmentedWrapperHide]: params.newsCode })}>
          <Segmented
            options={SEGMENT_OPTIONS}
            value={segment}
            onChange={handleSegmentChange}
            fullWidth
          />
        </div>
        {content[segment]}
      </Card>
    </WithBackground>
  );
};

export default HomeNewsList;
