import { ReactNode } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';

import { TIconsLiteral, TagType } from 'gazprom-ui-lib';

import { FeedbackStatus } from 'types/feedback.types';

export const formatDate = (date: Dayjs) => dayjs(date).format('DD MMMM YYYY');

export const MAX_FEEDBACK_LENGTH = 256;
export const getTagConfig = (
  status?: FeedbackStatus,
): { type: TagType; children: ReactNode; icon: TIconsLiteral } => {
  switch (status) {
    case 'APPROVED': {
      return {
        icon: 'ok',
        children: t('feedback_approved'),
        type: 'success',
      };
    }
    case 'PENDING': {
      return {
        icon: 'time',
        children: t('feedback_pending'),
        type: 'warning',
      };
    }
    case 'DECLINED': {
      return {
        icon: 'deleteFilled',
        children: t('feedback_declined'),
        type: 'danger',
      };
    }
    default:
      return {
        icon: 'deleteFilled',
        children: `${status}`,
        type: 'outline',
      };
  }
};
