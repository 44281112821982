import { SuperiorDivisionType } from 'types/employee.types';

export type EmploymentStructureItemType = Omit<SuperiorDivisionType, 'parent'>;

export const getEmploymentStructureItems = (
  divisionStructure?: SuperiorDivisionType,
  result: EmploymentStructureItemType[] = [],
): EmploymentStructureItemType[] => {
  if (!divisionStructure) return [];

  if (divisionStructure.parent) {
    result.push({
      divisionId: divisionStructure.divisionId,
      name: divisionStructure.name,
    });
    return getEmploymentStructureItems(divisionStructure.parent, result);
  } else {
    result.push({
      divisionId: divisionStructure.divisionId,
      name: divisionStructure.name,
    });
    return result;
  }
};
