import { Dispatch, FC, SetStateAction, useContext } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { TextArea } from 'gazprom-ui-lib';
import { Form } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

import { BusinessTripContext, FORM_ENUM } from '../../../../businessTripForm.utils';

interface SpecifyProps {
  isTextAreaModalVisible: boolean;
  setIsTextAreaModalVisible: Dispatch<SetStateAction<boolean>>;
}

const Specify: FC<SpecifyProps> = (props) => {
  const { setIsTextAreaModalVisible, isTextAreaModalVisible } = props;

  const form = Form.useFormInstance();
  const { type } = useContext(BusinessTripContext);
  let name = FORM_ENUM.PURPOSE_SPECIFY;
  let title = t('common_specify');
  let label = t('common_specify');

  if (type === 'edit') {
    name = FORM_ENUM.PURPOSE_EDIT_SPECIFY;
  }

  if (type === 'cancel') {
    name = FORM_ENUM.PURPOSE_CANCEL_SPECIFY;
    title = t('common_decline_reason');
    label = t('common_decline_reason_other');
  }

  const textValue = Form.useWatch(name);

  const onSubmitHandler = (value: Record<string, string>) => {
    const fieldValue = value[name];
    setIsTextAreaModalVisible(!isTextAreaModalVisible);
    form.setFieldValue(name, fieldValue);
  };

  const showSpecify = Form.useWatch(FORM_ENUM.PURPOSE_SPECIFY_SHOW);

  if (showSpecify) {
    return (
      <>
        <TextAreaModal
          name={name}
          modalHeaderTitle={title}
          title={title}
          maxLength={250}
          defaultValue={textValue}
          isModalOpen={isTextAreaModalVisible}
          onSubmitHandler={onSubmitHandler}
          setIsModalOpen={() => setIsTextAreaModalVisible(false)}
        />
        <Form.Item name={name} rules={[formRules.required]} label={label}>
          <TextArea
            onClick={() => {
              setIsTextAreaModalVisible(true);
            }}
            rows={3}
            placeholder={t('common_enter_text')}
            maxLength={250}
            onFocus={(e) => e.target.blur()}
          />
        </Form.Item>
        <Form.Item name={FORM_ENUM.PURPOSE_SPECIFY_SHOW} noStyle />
      </>
    );
  }

  return (
    <>
      <Form.Item name={FORM_ENUM.PURPOSE_SPECIFY_SHOW} noStyle />
    </>
  );
};

export default Specify;
