import React, { FC } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';

import { Flex, Typography } from 'gazprom-ui-lib';

import { VacationType } from 'types/vacation.types';

import s from './Card.module.scss';

interface VacationCardProps {
  month: dayjs.Dayjs;
  vacations: VacationType[];
}

const isInVacation = (vacation: VacationType, month: dayjs.Dayjs) => {
  const startOfMonth = month.startOf('month');
  const endOfMonth = month.endOf('month');
  const vacationStart = dayjs(vacation.startDate);
  const vacationEnd = dayjs(vacation.endDate);

  return (
    (vacationStart.isBefore(endOfMonth) && vacationEnd.isAfter(startOfMonth)) ||
    vacationStart.isSame(startOfMonth, 'month') ||
    vacationEnd.isSame(endOfMonth, 'month')
  );
};

const VacationCard: FC<VacationCardProps> = (props) => {
  const { month, vacations } = props;

  const renderCell = () => {
    const vacation = vacations.find((vac) => isInVacation(vac, month));

    if (!vacation) {
      return <div className={s.cell} />;
    }

    const isSameMonth = dayjs(vacation.startDate).isSame(dayjs(vacation.endDate));
    const isStartDateSameMonth = dayjs(vacation.startDate).isSame(month, 'month');
    const isEndDateSameMonth = dayjs(vacation.endDate).isSame(month, 'month');
    const intermediateCell = !isStartDateSameMonth && !isEndDateSameMonth;

    if (isSameMonth) {
      return (
        <Flex fullWidth align="center" justify="center" className={cn(s.cell, s.activeCell)}>
          <Typography.Text size="10" weight="500">
            {dayjs(vacation.startDate).format('D')}
          </Typography.Text>
        </Flex>
      );
    }

    const sameMonthVacation =
      dayjs(vacation.startDate).isSame(month, 'month') &&
      dayjs(vacation.endDate).isSame(month, 'month');

    const onlyStartDate =
      dayjs(vacation.startDate).isSame(month, 'month') &&
      !dayjs(vacation.endDate).isSame(month, 'month');

    return (
      <Flex
        fullWidth
        justify={sameMonthVacation ? 'center' : onlyStartDate ? 'flex-start' : 'flex-end'}
        align="center"
        vertical
        className={cn(s.cell, s.activeCell)}>
        {isStartDateSameMonth && (
          <Typography.Text size="10" weight="500">
            {dayjs(vacation.startDate).format('D')}
          </Typography.Text>
        )}
        {isEndDateSameMonth && (
          <Typography.Text size="10" weight="500">
            {dayjs(vacation.endDate).format('D')}
          </Typography.Text>
        )}
        {intermediateCell && <div />}
      </Flex>
    );
  };

  return (
    <Flex className={s.cellWrapper} vertical gap="4" align="center">
      <Typography.Text size="10">{month.format('MMM').slice(0, 3)}</Typography.Text>
      {renderCell()}
    </Flex>
  );
};

export default VacationCard;
