import React from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

interface Props {
  isOpened: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (e?: Record<string, string>) => void;
  form: FormInstance;
}

const CancelConfirmModal = (props: Props) => {
  const { isOpened, isLoading, form, onCancel, onSubmit } = props;

  const comment = Form.useWatch('comment', form);

  const modalActions = (
    <Flex vertical gap="12">
      <Button size="large" fullWidth ghost htmlType="reset" onClick={onCancel}>
        {t('common_back')}
      </Button>
      <Button
        size="large"
        fullWidth
        htmlType="submit"
        rightIcon="arrowRight"
        loading={isLoading}
        disabled={!comment}>
        {t('common_send')}
      </Button>
    </Flex>
  );

  return (
    <TextAreaModal
      actions={modalActions}
      formFromProps={form}
      name="comment"
      title={t('common_decline_reason')}
      modalHeaderTitle={t('documents_statement_cancel')}
      maxLength={360}
      isModalOpen={isOpened}
      setIsModalOpen={onCancel}
      onSubmitHandler={onSubmit}
    />
  );
};

export default CancelConfirmModal;
