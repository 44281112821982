export const CORRECTION_STATUS_CODES = [
  'COMMISSION_1L_REVISION',
  'HEAD_CORRECTION',
  'HEAD_REVISION',
];

export type ModalType =
  | ''
  | 'decline'
  | 'correction'
  | 'declineComment'
  | 'correctionSent'
  | 'declined'
  | 'redirectError'
  | 'redirectSuccess';

export enum DECISION_STATUS_CODE_ENUM {
  WAITING = 'WAITING',
  WAITING_TAKING = 'WAITING_TAKING',
  WAITING_REPORT = 'WAITING_REPORT',
}

export enum DECISION_TYPE_ENUM {
  FIRST_LEADER = 'FIRST_LEADER',
  TAKE_FOR_RELEASE = 'TAKE_FOR_RELEASE',
  WAITING_RELEASE_REPORT = 'WAITING_RELEASE_REPORT',
}
