import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Flex, Form, Icon, Input, InputProps, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import PageModal from 'containers/page-modal';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import { BusinessTripPurposeType } from 'types/businessTrip.types';

import { FORM_ENUM, PURPOSE_OTHER } from '../../../../businessTripForm.utils';
import s from './SelectWithSearch.module.scss';

interface SelectWithSearchProps {
  parentName: string;
  isSelectModalVisible: boolean;
  purposeTypesIsFetching: boolean;
  purposeTypes?: BusinessTripPurposeType[];
  setIsTextAreaModalVisible: Dispatch<SetStateAction<boolean>>;
  setIsSelectModalVisible: Dispatch<SetStateAction<boolean>>;
  title: string;
  manualTitle: string;
}

const SelectWithSearch: FC<SelectWithSearchProps> = (props) => {
  const {
    title,
    manualTitle,
    parentName,
    purposeTypes = [],
    purposeTypesIsFetching,
    isSelectModalVisible,
    setIsTextAreaModalVisible,
    setIsSelectModalVisible,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const form = Form.useFormInstance();

  const handleEnterPurposeClick = () => {
    form.setFieldValue(parentName, PURPOSE_OTHER);
    form.setFieldValue(FORM_ENUM.PURPOSE_SPECIFY_SHOW, true);
    setIsSelectModalVisible(false);
    setIsTextAreaModalVisible(true);
  };

  const handleGoBack = () => {
    setIsSelectModalVisible(false);
  };

  const handleSetPurpose = (purpose: BusinessTripPurposeType) => () => {
    form.setFieldValue(parentName, purpose.code);
    if (purpose.code === PURPOSE_OTHER) {
      handleEnterPurposeClick();
    } else {
      form.setFieldValue(FORM_ENUM.PURPOSE_SPECIFY_SHOW, purpose.commentRequired === 'true');
      handleGoBack();
    }
  };

  const handleInputChange: InputProps['onChange'] = (e) => {
    setSearchValue(e.target.value);
  };

  const renderOption = (purpose: BusinessTripPurposeType) => {
    return (
      <button
        className={s.option}
        type="button"
        onClick={handleSetPurpose(purpose)}
        key={purpose.code}>
        <Flex align="center" justify="space-between">
          <Typography.Title level={5}>{purpose.name}</Typography.Title>
          <Icon name="forward" />
        </Flex>
      </button>
    );
  };

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label label={title} />
    </>
  );

  const filterOptions = (purpose: BusinessTripPurposeType) =>
    purpose.name.trim().toLowerCase().includes(searchValue.trim().toLowerCase());

  const optionsToShow = purposeTypes?.filter(filterOptions);

  return (
    <PageModal open={isSelectModalVisible}>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <Flex vertical gap="16">
              <Flex vertical className={s.padding} gap="16">
                <Typography.Title level={4}>{title}</Typography.Title>
                <Input
                  value={searchValue}
                  onChange={handleInputChange}
                  prefix={<Icon name="search" />}
                  size="large"
                  placeholder={t('common_fast_search')}
                />
              </Flex>
              <WithLoader isLoading={purposeTypesIsFetching}>
                <Flex vertical>
                  {optionsToShow?.length ? (
                    <Flex vertical>{optionsToShow?.map(renderOption)}</Flex>
                  ) : (
                    <Flex align="center" justify="center" className={s.paddingBottom}>
                      <Typography.Text size="14" type="secondary">
                        {t('common_no_results_found')}
                      </Typography.Text>
                    </Flex>
                  )}
                </Flex>{' '}
              </WithLoader>
            </Flex>
          </Card>
          <Card>
            <Flex vertical align="center" justify="center" className={s.description}>
              <Typography.Text size="14" weight="500" type="secondary">
                {t('common_didnt_found_option')}
              </Typography.Text>
              <Button htmlType="button" type="link" size="small" onClick={handleEnterPurposeClick}>
                {manualTitle}
              </Button>
            </Flex>
          </Card>
        </WithBackground>
      </WithNavigation>
    </PageModal>
  );
};

export default SelectWithSearch;
