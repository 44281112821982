import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault, TIconsLiteral } from 'gazprom-ui-lib';

type CodeVerificationCompletedProps = {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  description?: string;
  icon?: TIconsLiteral;
};

const CodeVerificationCompleted: FC<CodeVerificationCompletedProps> = (props) => {
  const { isOpen, handleClose, title, description, icon = 'ok' } = props;

  return (
    <ModalDefault open={isOpen} onCancel={handleClose}>
      <ModalDefault.Icon name={icon} type="success" />
      <ModalDefault.Title>{title}</ModalDefault.Title>
      <ModalDefault.Description>{description}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" onClick={handleClose} fullWidth size="large">
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CodeVerificationCompleted;
