import { FC } from 'react';

import { Collapse as CollapseAntd, CollapseProps as CollapsePropsAntd } from 'gazprom-ui-lib';

import s from './Collapse.module.scss';

interface CollapseProps extends CollapsePropsAntd {}

const Collapse: FC<CollapseProps> = (props) => {
  const { items, ...restProps } = props;
  return <CollapseAntd noPadding className={s.collapse} items={items} {...restProps} />;
};

export default Collapse;
