import { FC } from 'react';

import cn from 'classnames';

import { Typography } from 'gazprom-ui-lib';

import { convertToRub } from '../../mySalary.utils';
import s from './MySalaryDetailed.module.scss';

interface MySalaryDetailedProps {
  type: string;
  text: string;
  value: number;
}

const MySalaryDetailed: FC<MySalaryDetailedProps> = (props) => {
  const { text, value, type } = props;

  return (
    <div className={s.container}>
      <div className={cn(s.dot, s[type])} />
      <Typography.Text>{text}</Typography.Text>
      <Typography.Text type="primary" className={s.value}>
        {convertToRub(value)}
      </Typography.Text>
    </div>
  );
};

export default MySalaryDetailed;
