import React, { useState } from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { getEmployeesSelector } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { Button, Radio, Typography } from 'gazprom-ui-lib';

import Employee from 'components/employee';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { EmployeeType } from 'types/employee.types';

import s from './ProfileCompany.module.scss';

const ProfileCompany = () => {
  const { data } = useAppSelector(getEmployeesSelector);

  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  const [localSelectedEmployee, setLocalSelectedEmployee] = useState(selectedEmployee);

  const handleSelect = () => {
    if (localSelectedEmployee) {
      setSelectedEmployee(localSelectedEmployee.id);
    }
  };

  const renderCompany = (employee: EmployeeType) => {
    const isSelected = localSelectedEmployee?.id === employee.id;

    return (
      <button
        onClick={() => setLocalSelectedEmployee(employee)}
        className={s.button}
        key={employee.id}>
        <Employee employee={employee} isSelected={isSelected} showDivision={false} />
        <Radio checked={isSelected} />
      </button>
    );
  };

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('common_organization')} />
    </>
  );

  const showApply = localSelectedEmployee?.id !== selectedEmployee?.id;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card className={s.wrapper}>
            <Typography.Title level={4}>{t('common_available_for_selection')}</Typography.Title>
            {data?.map(renderCompany)}
            {showApply && (
              <Button rightIcon="arrowRight" onClick={handleSelect}>
                {t('common_apply')}
              </Button>
            )}
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default ProfileCompany;
