import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { userManager } from 'utils/keycloak';
import { theme } from 'utils/theme.utils';

import { ADDITIONAL_COLORS, ConfigProvider, LOCALES } from 'gazprom-ui-lib';

import WithErrorBoundary from 'containers/wrappers/with-error-boundary';

import AppPrepare from './app';
import { initDayJsPlugins, initLanguagesForThirdPartyLibs } from './index.utils';
import { store } from './store';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initLanguagesForThirdPartyLibs();
initDayJsPlugins();

root.render(
  <ConfigProvider
    theme={theme}
    cssVariables={{
      ...ADDITIONAL_COLORS,
      colorGradientPrimary: 'linear-gradient(180deg, #006db6 0%, #3ab5f9 100%)',
    }}
    locale={LOCALES.ru}>
    <AuthProvider userManager={userManager}>
      <Provider store={store}>
        <BrowserRouter>
          <WithErrorBoundary>
            <AppPrepare />
          </WithErrorBoundary>
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  </ConfigProvider>,
);
