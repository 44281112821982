import React from 'react';

import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { activeEmployeeSelector } from 'selectors/employeeSlice.selector';
import { useAppSelector } from 'store';

import { Collapse, Icon, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import companyPlug from 'assets/icons/companyPlug.png';

import s from './RegularArrangement.module.scss';

const RegularArrangement = () => {
  const activeEmployee = useAppSelector(activeEmployeeSelector);

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('common_regular_arrangement')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground className={s.wrapper}>
          <Card>
            <Card.Default
              title={t('common_regular_arrangement')}
              description={t('regular_arrangement_description')}
              icon="schema"
            />
          </Card>
          <Card withPadding={false}>
            <div className={s.company}>
              <img src={companyPlug} alt="company logo" />
              <div>
                <div>
                  <Typography.Title level={4}>{activeEmployee?.person?.firstName}</Typography.Title>
                  <Typography.Text size="10">297</Typography.Text>
                </div>
                <Typography.Text size="12" type="secondary">
                  {t('common_organization')}
                </Typography.Text>
              </div>
            </div>
            <Collapse
              noPadding
              items={Array.from({ length: 6 }).map((_, parentIndex) => ({
                key: `collapse_wrapper_${parentIndex}`,
                label: (
                  <div className={s.itemHeader}>
                    <Icon name="category" />
                    <div>
                      <Typography.Text size="14">Руководство</Typography.Text>
                      <Typography.Text size="10">2</Typography.Text>
                    </div>
                  </div>
                ),
                children: (
                  <div className={s.itemBody}>
                    {Array.from({ length: 2 }).map((_, index) => {
                      return (
                        <button className={s.itemBody} key={`collapse__${index}__${parentIndex}`}>
                          <Bio
                            staffName="Руководитель"
                            firstName="Сергеев"
                            lastName="Сергей"
                            patronymic="Сергеевич"
                          />
                          <Icon name="info" />
                        </button>
                      );
                    })}
                  </div>
                ),
              }))}
            />
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};
export default RegularArrangement;
