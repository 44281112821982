import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';

import { Browser } from '@capacitor/browser';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_CERTIFICATE_ROUTE,
  DOCUMENTS_CREATE_CERTIFICATES_ROUTE,
} from 'routes/documents/list';
import { Trans, t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';
import useCertificate from 'utils/useCertificate.hook';
import useIsSignEnabled from 'utils/useIsSignEnabled.hook';

import initialApiService from 'services/initialApiService';
import { useGetCurrentCertificateQuery } from 'services/me/meApiService';
import { useAppDispatch } from 'store';

import { Button, Flex, Icon, TIconsLiteral, Tag, Typography } from 'gazprom-ui-lib';

import { CONTINUE_CERTIFICATE_CREATION } from 'pages/documents/documents.utils';

import AUDIT_EVENTS from 'constants/auditEvents';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { CertificateRemarkType } from 'types/certificates.types';

import s from './Certificate.module.scss';

interface UseCertificationConfigProps {
  setSmsModalOperationId: Dispatch<SetStateAction<string>>;
}

const useCertificationConfig = (props: UseCertificationConfigProps) => {
  const { setSmsModalOperationId } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { auditSuccess } = useAudit();

  const { data: certificate, isLoading: isCertificateLoading } = useGetCurrentCertificateQuery();

  useEffect(() => {
    if (location.state?.invalidateCertificate) {
      dispatch(initialApiService.util.invalidateTags([SERVICE_TAGS.GET_CERTIFICATES]));
    }
  }, [dispatch, location.state?.invalidateCertificate]);

  let description: ReactNode = (
    <Trans i18nKey="documents_certificate_release_error">
      <Typography.Text type="danger" size="14" weight="500" />
    </Trans>
  );
  let iconName: TIconsLiteral = 'signature';
  let button: ReactNode = (
    <Button
      ghost
      rightIcon="arrowRight"
      fullWidth
      size="large"
      onClick={() => navigate(DOCUMENTS_CREATE_CERTIFICATES_ROUTE)}
      className={s.button}>
      {t('common_repeat_request')}
    </Button>
  );

  const { isActive: isActiveCertificate } = useCertificate();
  const signEnabled = useIsSignEnabled();

  if (!signEnabled) {
    iconName = 'documents';
    description = t('documents_certificate_unavailable');
    button = null;
  } else if (isActiveCertificate) {
    const expireDate = dayjs.utc(certificate?.latestKcr?.validTill);
    const isExpiringSoon = expireDate.diff(dayjs(), 'days') <= 30;
    const prefix = isExpiringSoon ? '' : `${t('common_till')} `;
    description = '';
    button = (
      <button className={s.baseWrapper} onClick={() => navigate(DOCUMENTS_CERTIFICATE_ROUTE)}>
        <Flex vertical gap="4">
          <Typography.Text weight="500" size="14">
            {t('documents_electronic_certificate')}
          </Typography.Text>
          <Flex align="center">
            <Tag type={isExpiringSoon ? 'warning' : 'success'} className={s.tag}>
              <Flex align="center" gap="4">
                <Icon name={isExpiringSoon ? 'time' : 'ok'} size={14} />
                {t(isExpiringSoon ? 'common_expiring' : 'common_active')}
              </Flex>
            </Tag>
            <Typography.Text size="12" type="secondary">
              {`${prefix}${expireDate.format('DD MMMM YYYY')}`}
            </Typography.Text>
          </Flex>
        </Flex>
        <Icon name="forward" size={20} color="var(--color-primary-6)" />
      </button>
    );
  } else {
    const latestIssue = certificate?.latestIssue;
    const status = latestIssue?.statusIssueKontur;
    const confirmationType = latestIssue?.confirmationType;
    const remarks = certificate?.correctionInfo?.remarks ?? [];
    const noCertificateInitially =
      !latestIssue && !certificate?.latestKcr && !certificate?.correctionInfo;

    if (noCertificateInitially || status === 'DECLINED') {
      description = t('documents_no_certificates_description');
      button = (
        <Button
          ghost
          rightIcon="arrowRight"
          fullWidth
          size="large"
          onClick={() => navigate(DOCUMENTS_CREATE_CERTIFICATES_ROUTE)}
          className={s.button}>
          {t('documents_create_certificate')}
        </Button>
      );
    }

    switch (status) {
      case 'CONFIRMATION_REQUESTED': {
        if (confirmationType === 'SMS') {
          const handleOpenSmsModal = () => {
            if (latestIssue) {
              setSmsModalOperationId(latestIssue?.id);
            }
          };

          description = t('documents_certificate_release_sms');
          button = (
            <Button
              ghost
              rightIcon="arrowRight"
              fullWidth
              size="large"
              onClick={handleOpenSmsModal}
              className={s.button}>
              {t('common_enter_sms')}
            </Button>
          );
        }
        break;
      }
      case 'RELEASED_STATEMENT': {
        description = `${t('documents_no_certificates_description')} ${t(
          'documents_certificate_request_registered_and_ready',
        )}`;
        button = (
          <Button
            ghost
            rightIcon="arrowRight"
            fullWidth
            size="large"
            onClick={() =>
              navigate(DOCUMENTS_CREATE_CERTIFICATES_ROUTE, {
                state: { type: CONTINUE_CERTIFICATE_CREATION, certificate },
              })
            }
            className={s.button}>
            {t('common_continue_release')}
          </Button>
        );
        break;
      }
      case 'VALIDATE_SENT': {
        if (confirmationType === 'SMS') {
          description = t('documents_certificate_release_push');
          button = null;
        } else {
          description = t('documents_certificate_request_sent');
          button = (
            <Button
              ghost
              rightIcon="arrowRight"
              fullWidth
              size="large"
              onClick={() => {
                auditSuccess(AUDIT_EVENTS.CLICK.ESIA);
                Browser.open({ url: 'https://esia.gosuslugi.ru/login/' });
              }}
              className={s.button}>
              {t('common_go_to_gosuslugi')}
            </Button>
          );
        }
        break;
      }
      case 'EPGU_SENT': {
        description = t('documents_certificate_releasing');
        button = null;
        break;
      }
      case 'CORRECTION_REQUESTED': {
        if (remarks.length) {
          const hasFatalIssues = remarks.find(findFatalIssue);
          const isIncorrectUser = remarks.find(findIncorrectUser);
          const isTimeoutError = remarks.find(findTimeout);
          const isRejectedRequest = remarks.find(findRejectedRequest);

          if (hasFatalIssues) {
            description = (
              <Trans i18nKey="documents_certificate_release_error_personal_data">
                <Typography.Text type="danger" size="14" weight="500" />
              </Trans>
            );
          } else if (isIncorrectUser) {
            description = (
              <Trans i18nKey="documents_certificate_release_error_user">
                <Typography.Text type="danger" size="14" weight="500" />
              </Trans>
            );
          } else if (isTimeoutError) {
            description = (
              <Trans i18nKey="documents_certificate_release_error_timeout">
                <Typography.Text type="danger" size="14" weight="500" />
              </Trans>
            );
          } else if (isRejectedRequest) {
            description = (
              <Trans i18nKey="documents_certificate_release_error_rejected_request">
                <Typography.Text type="danger" size="14" weight="500" />
              </Trans>
            );
          }
        }
        break;
      }
    }
  }

  return {
    iconName,
    description,
    isLoading: isCertificateLoading,
    button,
  };
};

const findFatalIssue = (remark: CertificateRemarkType) => remark.target === 'FATAL';
const findIncorrectUser = (remark: CertificateRemarkType) => remark.code === 'userNotFoundInEsia';
const findTimeout = (remark: CertificateRemarkType) => remark.code === 'esiaConfirmationTimeout ';
const findRejectedRequest = (remark: CertificateRemarkType) =>
  remark.code === 'esiaConfirmationRejected ';

export default useCertificationConfig;
