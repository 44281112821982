import { MouseEvent, PropsWithChildren } from 'react';

import { Browser } from '@capacitor/browser';

interface InappLinkProps extends PropsWithChildren {
  className?: string;
  href: string;
}

const InappLink = (props: InappLinkProps) => {
  const { className, href, children } = props;

  const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const url = href.includes('http') ? href : `http://${href}`;

    e.preventDefault();
    Browser.open({ url });
  };

  return (
    // Yeah, in rare cases we use ignore
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={className} onClick={onClick}>
      {children}
    </a>
  );
};

export default InappLink;
