import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  GetPayslipRequest,
  GetPayslipResponse,
  RequestPayslipPropsType,
  RequestPayslipResponseType,
} from './mySalaryApiService.types';

const meApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPayslip: builder.query<GetPayslipResponse, GetPayslipRequest>({
      query: (params) => ({
        url: `me/payslip${prepareQueryParams(params)}`,
      }),
    }),
    requestPayslipMe: builder.query<RequestPayslipResponseType, RequestPayslipPropsType>({
      query: ({ myEmployeeId, ...body }) => ({
        method: 'POST',
        url: `kedo/me/payslip?myEmployeeId=${myEmployeeId}`,
        body,
      }),
    }),
  }),
});

export const { useGetPayslipQuery, useLazyRequestPayslipMeQuery } = meApiService;
