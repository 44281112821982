import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { prepareQueryParams } from 'utils/prepareQueryParams';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { handleRequest } from 'gazprom-ui-lib';

const useDownloadKedoArchiveHook = () => {
  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  const downloadKedoFilesArchive = (fileKey: string) => {
    handleOpenFile({
      url: createMediaDownloadUrl(
        `api/v1/kedo-files/download/${fileKey}${prepareQueryParams({
          full: true,
        })}`,
      ),
      withAuth: true,
      fileKey,
    }).then(
      handleRequest({
        onSuccess: () => {
          console.log('success');
        },
        onError: () => {
          console.log('error');
        },
      }),
    );
  };

  return { downloadKedoFilesArchive, isLoading: !!loadingKey };
};

export default useDownloadKedoArchiveHook;
