import { PropsWithChildren, ReactNode, createContext } from 'react';

import { t } from 'i18next';
import { useLocation, useParams } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';

import { useGetAssignedToMeStatementByIdQuery } from 'services/kedo/kedoApiService';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import { WithPullToRefreshProps } from 'containers/wrappers/with-pull-to-refresh/withPullToRefresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { AssignmentType } from 'types/assignment.types';

interface Props extends PropsWithChildren {
  onRefresh?: WithPullToRefreshProps['onRefresh'];
  headerChildren?: ReactNode;
}

const DocumentsAssignment = (props: Props) => {
  const { children, onRefresh, headerChildren } = props;

  const location = useLocation();
  const params = useParams<{ taskId: string }>();

  const { data, refetch: refetchTask } = useGetAssignedToMeStatementByIdQuery(
    {
      taskId: params?.taskId!,
    },
    { skip: !params?.taskId },
  );

  const defaultHeaderChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} state={location.state} useDefaultGoBack />
      <Header.Label label={t('documents_task_details_title')} />
    </>
  );

  const handleRefresh = async () => {
    await refetchTask();
    if (onRefresh) await refetchTask();
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren ?? defaultHeaderChildren}>
        <WithPullToRefresh onRefresh={handleRefresh}>
          <WithBackground>
            <AssignmentContext.Provider value={data}>{children}</AssignmentContext.Provider>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export const AssignmentContext = createContext<AssignmentType | undefined>(undefined);

export default DocumentsAssignment;
