import { FC, useEffect, useMemo, useState } from 'react';

import { Bar } from '@ant-design/charts';
import { t } from 'tools/i18n';

import { Typography } from 'gazprom-ui-lib';

import { PayslipDataType, SalaryYearDataType } from 'types/mySalary.types';

import MySalaryBadge from '../../my-salary-badge';
import { monthMap } from '../../mySalary.utils';
import s from './MySalaryYear.module.scss';
import { createConfig, mapYearData } from './mySalaryYear.utils';

interface MySalaryYearProps {
  data: PayslipDataType[];
  year: number | undefined;
}

const renderBadges = (element: { data: SalaryYearDataType }) => {
  if (element.data.type === 'accruals') {
    return (
      <MySalaryBadge
        text={t('my_salary_paid')}
        number={element.data.value}
        className={element.data.type}
        key={element.data.type}
      />
    );
  }
  if (element.data.type === 'deductions') {
    return (
      <MySalaryBadge
        text={t('my_salary_accrued')}
        number={element.data.sumValue!}
        className={'payouts'}
        key={element.data.type}
      />
    );
  }

  return (
    <>
      <MySalaryBadge
        text={t('my_salary_paid')}
        number={0}
        className={'accruals'}
        key={element.data.type}
      />
      <MySalaryBadge
        text={t('my_salary_accrued')}
        number={0}
        className={'payouts'}
        key={element.data.type}
      />
    </>
  );
};

const MySalaryYear: FC<MySalaryYearProps> = (props) => {
  const { data, year } = props;
  const [onClickData, setOnClickData] = useState<{ data: SalaryYearDataType }[] | null>(null);
  const mappedData = mapYearData(data);
  const { data: salaryYearData, maxValue } = mappedData;
  const config = useMemo(
    () => createConfig(salaryYearData, maxValue.toFixed(), setOnClickData),
    [maxValue, salaryYearData],
  );
  const fullCurrentMonth =
    onClickData &&
    onClickData.length &&
    monthMap[onClickData[0].data.monthKey as keyof typeof monthMap].long;

  useEffect(() => {
    setOnClickData(null);
  }, [data]);

  return (
    <div className={s.container}>
      <Bar {...config} className={s.chart} />
      {!!onClickData?.length && (
        <div className={s.bottomContainer}>
          {fullCurrentMonth && (
            <Typography.Title level={5}>
              {fullCurrentMonth} {year}
            </Typography.Title>
          )}
          <div className={s.badgeContainer}>{onClickData.map(renderBadges)}</div>
        </div>
      )}
    </div>
  );
};

export default MySalaryYear;
