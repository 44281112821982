import { FC } from 'react';

import { Typography } from 'gazprom-ui-lib';

import s from './DataPresenter.module.scss';

interface DataPresenterProps {
  title: string;
  text?: string;
}

const DataPresenter: FC<DataPresenterProps> = (props) => {
  const { text, title } = props;

  if (!text) {
    return null;
  }

  return (
    <div className={s.container}>
      <Typography.Text size="12" type="secondary">
        {title}
      </Typography.Text>
      <Typography.Title level={5}>{text}</Typography.Title>
    </div>
  );
};

export default DataPresenter;
