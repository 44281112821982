import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

import s from './DocumentsTopManagement.module.scss';
import ExitModal from './containers/exit-modal/exitModal';
import VerificationForm from './containers/verification-form/verificationForm';
import { pageParams } from './documentsTopManagement.utils';

const DocumentsTopManagement = () => {
  const navigate = useNavigate();
  const { stage } = useParams<{ stage: string }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const currentForm = pageParams[stage as TOP_MANAGEMENT_STAGE_ENUM] || pageParams['common'];

  const handlePrevStep = () => navigate(currentForm.goBack);
  const handleOpenModal = () => setIsModalOpen((prev) => !prev);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handlePrevStep} />
      <Header.RightIcon iconName="delete" onClick={handleOpenModal} />
      <Header.Label label={currentForm.label} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={false}>
        <WithBackground>
          <div className={s.styleContainer}>{currentForm.component}</div>
          <VerificationForm />
          <ExitModal isOpen={isModalOpen} handleClose={handleOpenModal} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsTopManagement;
