import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  JoinTheMeetingPropsType,
  JoinTheMeetingResponseType,
  LeaveTheMeetingPropsType,
  LeaveTheMeetingResponseType,
} from './meetApiService.types';

import { createMeetUrl } from './utils';

const meetApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    joinTheMeeting: builder.mutation<JoinTheMeetingResponseType, JoinTheMeetingPropsType>({
      query: ({ id, ...other }) => ({
        url: createMeetUrl(`/${id}/join${prepareQueryParams(other)}`),
        method: 'POST',
      }),
    }),
    leaveTheMeeting: builder.mutation<LeaveTheMeetingResponseType, LeaveTheMeetingPropsType>({
      query: ({ id, ...other }) => ({
        url: createMeetUrl(`/${id}/unjoin${prepareQueryParams(other)}`),
        method: 'POST',
      }),
    }),
  }),
});

export const { useJoinTheMeetingMutation, useLeaveTheMeetingMutation } = meetApiService;
