import { Dispatch, SetStateAction, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

interface Props<R> {
  setModalType: Dispatch<SetStateAction<R>>;
}

const useSetModalFromLocationState = <R>(props: Props<R>) => {
  const { setModalType } = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.modalType) {
      setModalType(location.state.modalType);

      navigate(location.pathname, { replace: true });
    }
  }, [location.pathname, location.state, navigate, setModalType]);
};

export default useSetModalFromLocationState;
