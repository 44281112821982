import Actions from './containers/actions';
import Attributes from './containers/attributes';
import CancelComment from './containers/cancel-comment';
import CancelHistory from './containers/cancel-history';
import Content from './containers/content';
import DetailsHeader from './containers/details-header';
import DetailsWrapper from './containers/details-wrapper';
import Employee from './containers/employee';
import Files from './containers/files';
import History from './containers/history';
import InternalComponent from './documentsAssignment';

type CompoundedComponents = typeof InternalComponent & {
  CancelHistory: typeof CancelHistory;
  History: typeof History;
  DetailsWrapper: typeof DetailsWrapper;
  DetailsHeader: typeof DetailsHeader;
  CancelComment: typeof CancelComment;
  Employee: typeof Employee;
  Attributes: typeof Attributes;
  Content: typeof Content;
  Files: typeof Files;
  Actions: typeof Actions;
};

const DocumentsAssignment = InternalComponent as CompoundedComponents;
DocumentsAssignment.CancelHistory = CancelHistory;
DocumentsAssignment.History = History;
DocumentsAssignment.DetailsWrapper = DetailsWrapper;
DocumentsAssignment.DetailsHeader = DetailsHeader;
DocumentsAssignment.CancelComment = CancelComment;
DocumentsAssignment.Employee = Employee;
DocumentsAssignment.Attributes = Attributes;
DocumentsAssignment.Content = Content;
DocumentsAssignment.Files = Files;
DocumentsAssignment.Actions = Actions;

export default DocumentsAssignment;
