import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

const FeedbackCreateNotificationModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    navigate(location.pathname, {
      replace: true,
      state: { ...location.state, showConfirmationModal: false },
    });
  };

  useEffect(() => {
    if (location.state?.showConfirmationModal) {
      setIsOpen(true);
    }
  }, [location.state?.showConfirmationModal]);

  return (
    <ModalDefault open={isOpen} onCancel={handleClose} getContainer={'#feedbackModalMount'}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('common_successfully_sent')}</ModalDefault.Title>
      <ModalDefault.Description>{t('feedback_sent_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button onClick={handleClose} fullWidth size="large">
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default FeedbackCreateNotificationModal;
