import React, { FC } from 'react';

import cn from 'classnames';

import { Icon } from 'gazprom-ui-lib';

import s from './CoverNoData.module.scss';

interface CoverNoDataProps {
  className?: string;
}

const CoverNoData: FC<CoverNoDataProps> = (props) => {
  const { className } = props;

  return (
    <div className={cn(s.wrapper, className)}>
      <Icon name="noImage" />
    </div>
  );
};

export default CoverNoData;
