import { RefObject, TouchEvent, useState } from 'react';

const DEFAULT_DISTANCE_TO_REFRESH = 56;
const MINIMUM_DELAY_TIME = 700;

interface UsePullToRefreshProps {
  innerContentRef: RefObject<HTMLDivElement>;
  onRefresh: () => void | Promise<unknown>;
  distanceToRefresh?: number;
}

export const usePullToRefresh = (props: UsePullToRefreshProps) => {
  const { innerContentRef, distanceToRefresh = DEFAULT_DISTANCE_TO_REFRESH, onRefresh } = props;

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [startY, setStartY] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);

  const setDefaults = () => {
    setIsRefreshing(false);
    setStartY(0);
    setCurrentOffset(0);
  };

  const handleTouchStart = (e: TouchEvent) => {
    if (innerContentRef.current?.scrollTop === 0) {
      setStartY(e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startY === 0 || isRefreshing) return;

    const currentY = e.touches[0].clientY;
    const deltaY = currentY - startY;

    setCurrentOffset(Math.max(0, Math.min(deltaY, distanceToRefresh)));

    const isScrollingStarted = deltaY > 0 && window.scrollY === 0;

    const isRefreshAvailable = !isRefreshing && deltaY >= distanceToRefresh;

    if (isScrollingStarted) {
      if (isRefreshAvailable) {
        setIsRefreshing(true);
        const startTime = Date.now();

        // Using promise all with Set Timeout in order to set minimum delay of 700ms for better UX
        Promise.all([
          onRefresh(),
          new Promise((resolve) => setTimeout(resolve, MINIMUM_DELAY_TIME)),
        ]).finally(() => {
          setTimeout(setDefaults, Math.max(MINIMUM_DELAY_TIME - Date.now() - startTime, 0));
        });
      }
    }
  };

  const handleTouchEnd = () => {
    setStartY(0);
    setCurrentOffset(isRefreshing ? distanceToRefresh : 0);
  };

  return {
    currentOffset,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
};
