import React, { FC, memo, useEffect } from 'react';

import { formRules } from 'utils/formRules.utils';
import { getYearsValues } from 'utils/getYearsValuies';

import { Form, Radio } from 'gazprom-ui-lib';

import AttributeItemWrapper from '../attribute-item-wrapper';
import { AttributeDefaultProps } from '../attribute.utils';

export interface AttributeYearsListProps extends AttributeDefaultProps {}

const AttributeYearsList: FC<AttributeYearsListProps> = (props) => {
  const { id, isMandatory, isReadonly, value } = props;

  const form = Form.useFormInstance();

  useEffect(() => {
    const shouldSetInitialValue = form && id && value;

    if (shouldSetInitialValue) {
      form.setFieldValue(id, Number(value));
    }
  }, [form, id, value]);

  return (
    <AttributeItemWrapper {...props}>
      <Form.Item name={id} rules={isMandatory ? [formRules.required] : []}>
        <Radio.Group direction="vertical" disabled={isReadonly}>
          {getYearsValues().map((year) => (
            <Radio.Button value={year} key={year}>
              {year}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    </AttributeItemWrapper>
  );
};

export default memo(AttributeYearsList);
