import dayjs from 'dayjs';

import { DATE_VALIDATION_ENUM } from 'types/documents.types';
import { ProductCalendarDayType, ProductCalendarType } from 'types/schedule.types';

export type ModalConfigType = Record<'date' | 'month' | 'year', string>;

export const DEFAULT_REQUEST_BODY = {
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().add(1, 'month').format('YYYY-MM-DD'),
};

export const isValidDate = (
  date: dayjs.Dayjs,
  validation: string,
  productionCalendar: ProductCalendarType,
): boolean => {
  if (validation === DATE_VALIDATION_ENUM.NO_LATER_CURRENT) {
    return !date.isAfter(dayjs());
  }

  let targetDate;

  switch (validation) {
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_3_DAYS:
    case DATE_VALIDATION_ENUM.NO_LATER_3_DAYS:
      targetDate = getTargetDate(3, productionCalendar);
      break;
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_5_DAYS:
    case DATE_VALIDATION_ENUM.NO_LATER_5_DAYS:
      targetDate = getTargetDate(5, productionCalendar);
      break;
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_7_DAYS:
    case DATE_VALIDATION_ENUM.NO_LATER_7_DAYS:
      targetDate = getTargetDate(7, productionCalendar);
      break;
    default:
      targetDate = dayjs();
  }

  switch (validation) {
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_3_DAYS:
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_5_DAYS:
    case DATE_VALIDATION_ENUM.NO_LESS_THAN_7_DAYS:
      return date.isSame(targetDate) || date.isAfter(targetDate);
    case DATE_VALIDATION_ENUM.NO_LATER_3_DAYS:
    case DATE_VALIDATION_ENUM.NO_LATER_5_DAYS:
    case DATE_VALIDATION_ENUM.NO_LATER_7_DAYS:
      return date.isBefore(targetDate);
    default:
      return true;
  }
};

const getTargetDate = (days: number, productionCalendar: ProductCalendarType) => {
  const currentDate = dayjs();
  let nextWorkingDay: ProductCalendarDayType | null = null;
  let count = 0;

  for (const day of productionCalendar) {
    const dayDate = dayjs(day.date);

    if (dayDate.isAfter(currentDate, 'day') && day.stateDay === 'workDay') {
      count++;

      if (count === days) {
        nextWorkingDay = day;
        break;
      }
    }
  }

  return dayjs(nextWorkingDay?.date);
};

export const removeDisabled = (e: { disabled?: boolean }) => !e.disabled;
