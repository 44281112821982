import { FC, useEffect, useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { DropDownAnswerType } from 'services/survey/surveyApiService.types';
import { useAppSelector } from 'store';

import { Form, Icon, Modal, Radio, Select, Typography } from 'gazprom-ui-lib';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';

import SurveysQuestionTitle from 'pages/surveys/surveys-question-title';

import { Options } from 'types/surveys.types';

import { QuestionProps } from '../surveysQuestion';
import s from './SurveysQuestionsDropdown.module.scss';
import { mapOptionsDropdown } from './surveysQuestionsDropdown.utils';

export interface SurveysQuestionsDropdownProps extends QuestionProps {
  title: string;
  no: number;
  is_required: boolean;
  options: Options[];
}

const SurveysQuestionsDropdown: FC<SurveysQuestionsDropdownProps> = (props) => {
  const { id, type, title, no, is_required, options } = props;
  const form = Form.useFormInstance();
  const [isActive, setIsActive] = useState(false);
  const { isCompleted, answers } = useAppSelector((store) => store.surveySlice);
  const name = `${type}.${id}`;
  const answerById = answers?.answer.find((answer) => answer.question_id === id) as
    | DropDownAnswerType
    | undefined;
  const answer = answerById?.question_answer.answer?.options.find(
    (option) => option.answer_value === true,
  );

  useEffect(() => {
    if (answer) {
      form.setFieldValue(name, answer?.option_id);
    }
  }, [answer, form, name]);

  const mappedOptions = useMemo(() => mapOptionsDropdown(options), [options]);

  const handleClick = (id: number) => {
    form.setFieldValue(name, id);
  };

  return (
    <>
      <div className={s.container}>
        <SurveysQuestionTitle level={5} number={no} required={is_required}>
          {title}
        </SurveysQuestionTitle>
        <Form.Item name={name}>
          <Select
            open={false}
            placeholder="Выберите значение"
            onClick={() => !isCompleted && setIsActive(true)}
            disabled={isCompleted}
            options={mappedOptions}
            optionLabelProp="label"
            optionFilterProp="name"
          />
        </Form.Item>
      </div>
      <SelectModal
        id={id}
        type={type}
        options={options}
        isModalOpen={isActive}
        handleClick={handleClick}
        setIsModalOpen={setIsActive}
      />
    </>
  );
};

export default SurveysQuestionsDropdown;

interface SelectModalProps {
  id: number;
  type: any;
  options: Options[];
  isModalOpen: boolean;
  handleClick: (value: number) => void;
  setIsModalOpen: (value: boolean) => void;
}

const SelectModal: FC<SelectModalProps> = (props) => {
  const { id, type, isModalOpen, options, handleClick, setIsModalOpen } = props;
  const name = `${type}.${id}`;

  const header = (
    <>
      <Header.GoBackButton onClick={() => setIsModalOpen(!isModalOpen)} />
      <Header.Label label={t('Выберите значение')} />
    </>
  );

  return (
    <Modal
      open={isModalOpen}
      className={s.dropdownModal}
      transitionName=""
      wrapClassName={s.wrapClassName}>
      <WithNavigation headerChildren={header}>
        <WithBackground className={s.background}>
          <div className={s.content}>
            <Form.Item name={name}>
              <Radio.Group
                onChange={(e) => {
                  handleClick(e.target.value);
                  setIsModalOpen(!isModalOpen);
                }}>
                {options.map(({ id, title }) => (
                  <Radio value={id} key={id}>
                    <Typography.Title level={5}>{title}</Typography.Title>
                    <Icon name="forward" size={13} />
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        </WithBackground>
      </WithNavigation>
    </Modal>
  );
};
