import initialApiServiceV2 from 'services/initialApiServiceV2';

import {
  ApproveWorkTourAssignmentPropsType,
  ApproveWorkTourAssignmentResponseType,
  DraftApproveWorkTourAssignmentPropsType,
  DraftApproveWorkTourAssignmentResponseType,
  RejectWorkTourAssignmentPropsType,
  RejectWorkTourAssignmentResponseType,
  RevisionWorkTourAssignmentPropsType,
  RevisionWorkTourAssignmentResponseType,
  SignWorkTourAssignmentPropsType,
  SignWorkTourAssignmentResponseType,
} from './workTourApiService.types';

import { createWorkTourUrl } from './utils';

const workTourApiService = initialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    signWorkTourAssignment: builder.mutation<
      SignWorkTourAssignmentResponseType,
      SignWorkTourAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createWorkTourUrl(`/${assignmentId}/sign`),
        method: 'POST',
        body,
      }),
    }),
    revisionWorkTourAssignment: builder.mutation<
      RevisionWorkTourAssignmentResponseType,
      RevisionWorkTourAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createWorkTourUrl(`/${assignmentId}/revision`),
        method: 'POST',
        body,
      }),
    }),
    rejectWorkTourAssignment: builder.mutation<
      RejectWorkTourAssignmentResponseType,
      RejectWorkTourAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createWorkTourUrl(`/${assignmentId}/reject`),
        method: 'POST',
        body,
      }),
    }),
    approveWorkTourAssignment: builder.mutation<
      ApproveWorkTourAssignmentResponseType,
      ApproveWorkTourAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createWorkTourUrl(`/${assignmentId}/approve`),
        method: 'POST',
        body,
      }),
    }),
    draftApproveWorkTourAssignment: builder.mutation<
      DraftApproveWorkTourAssignmentResponseType,
      DraftApproveWorkTourAssignmentPropsType
    >({
      query: ({ assignmentId, ...body }) => ({
        url: createWorkTourUrl(`/${assignmentId}/draft-approve`),
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const {
  useDraftApproveWorkTourAssignmentMutation,
  useSignWorkTourAssignmentMutation,
  useApproveWorkTourAssignmentMutation,
  useRejectWorkTourAssignmentMutation,
  useRevisionWorkTourAssignmentMutation,
} = workTourApiService;
